import { initializeTimestreamQueryClient } from "../utilities/sessionUtils";
import { QueryCommand } from "@aws-sdk/client-timestream-query";
var queryClient;

export default {
	data() {
		return {
			RFIDData: [],
			error: null,
			query1: `
            SELECT COUNT(*) as count, MAX(time) as latest, CognitoID FROM "onecup-rfid-production"."rfid-reader-status"
            GROUP BY CognitoID
            `,

			query2: `
            SELECT COUNT(*) as count7d, CognitoID FROM "onecup-rfid-production"."rfid-reader-status"
            WHERE time > ago(7d) AND measure_name = 'tag' GROUP BY CognitoID
            `,

			query3: `
            SELECT UID, Hostname, measure_name, time, measure_value::bigint, measure_value::double FROM "onecup-rfid-production"."rfid-reader-status"
            WHERE CognitoID = <-- INSERT ID HERE --> AND measure_name = 'tag'
            `,

			query4: `
			SELECT UID, Hostname, measure_name, time, measure_value::bigint, measure_value::double FROM "onecup-rfid-production"."rfid-reader-status"
			WHERE 
				CognitoID = <-- INSERT ID HERE --> 
				AND measure_name = 'tag'
				AND time BETWEEN from_milliseconds(<-- INSERT FROM HERE -->) AND from_milliseconds(<-- INSERT TO HERE -->)
			`,
		};
	},
	methods: {
		getRFIDData(queries) {
			this.RFIDData = [];

			const g = this.getAllRows;
			initializeTimestreamQueryClient()
				.then((client) => {
					queryClient = client;
					queries.forEach((q) => {
						g(q);
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getAllRows(query, nextToken = undefined) {
			let response;
			var params = {
				QueryString: query,
				NextToken: nextToken,
			};
			var command = new QueryCommand(params);
			try {
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			// console.log(response);
			this.parseRFIDData(response);
			if (response.NextToken) {
				await this.getAllRows(query, response.NextToken);
			} else {
				console.log("success!");
			}
		},
		// extractRFIDData(user_id, from_timestamp, to_timestamp) {
		// 	initializeTimestreamQueryClient().then((client) => {
		// 		queryClient = client;
		// 		var query = this.query4
		// 			.replace("<-- INSERT ID HERE -->", `'${user_id}'`)
		// 			.replace("<-- INSERT FROM HERE -->", from_timestamp)
		// 			.replace("<-- INSERT TO HERE -->", to_timestamp);
		// 		var result = this.getAllRowsByTimeRange(query);
		// 		console.log(result);
		// 	});
		// },
		// async getAllRowsByTimeRange(query, nextToken = undefined) {
		// 	var response = [];
		// 	var params = {
		// 		QueryString: query,
		// 		NextToken: nextToken,
		// 	};
		// 	var command = new QueryCommand(params);
		// 	try {
		// 		response = await queryClient.send(command);
		// 		var result = response.Rows.map(row=>{
		// 			var r = {}
		// 			row.Data.forEach((e, i)=>{
		// 				r[response.ColumnInfo[i].Name] = e.ScalarValue;
		// 			})
		// 			return r;
		// 		});
		// 		if(response.nextToken){
		// 			var next = await this.getAllRowsByTimeRange(query, response.nextToken);
		// 			return [
		// 				...result,
		// 				...next
		// 			];
		// 		}else{
		// 			return result;
		// 		}
		// 	} catch (err) {
		// 		console.error("Error while querying:", err);
		// 		this.error = err.errors[0].message;
		// 		throw err;
		// 	}
		// },
		toCSV(items) {
			if (items.length <= 0) {
				return [];
			}
			const replacer = (key, value) => {
				key;
				if (value === null) {
					return "";
				} else {
					return value;
				}
			}; // specify how you want to handle null values here
			var output = items.map(e=>e);
			const header = Object.keys(output[0]);
			const csv = [
				header.join(","), // header row first
				...output.map((row) =>
					header
						.map((fieldName) => JSON.stringify(row[fieldName], replacer))
						.join(",")
				),
			].join('\r\n');

			return csv;
		},
		/**
     * download the string as file
     * @param {String} filename 
     * @param {String} text 
     */
	download(filename, text) {
		var element = document.createElement("a");
		element.setAttribute(
			"href",
			"data:text/plain;charset=utf-8," + encodeURIComponent(text)
		);
		element.setAttribute("download", filename);
	
		element.style.display = "none";
		document.body.appendChild(element);
	
		element.click();
	
		document.body.removeChild(element);
		},
	},
};
