<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
         <v-col class="pr-0" v-if="assign" cols="2"
          ><v-card class="assignment_main_card"
            >
            <v-card-text>
              <v-card
                class="mb-1"
                v-for="(i, index) in project_names"
                :key="index"
                @dragenter.prevent
                @dragover.prevent
                @drop="assign_correspondence(i.value)"
              >
                <v-card-title
                  v-if="!assigned_projects_ready"
                  class=" pt-0 pb-0 assignment_div_ch"
                  ><div>{{ i.text }}</div></v-card-title
                >
                <v-card-title
                  v-else-if="assigned_projects_ids[0].includes(i.value)"
                  class="delete_from_project pt-0 pb-0 assignment_div_ch"
                  ><div class="assignment_text_div">{{ i.text }}<v-icon small class="assign_icon mt-2">
                      mdi-delete
                    </v-icon></div></v-card-title
                >
                <v-card-title
                  v-else-if="!assigned_projects_ids[0].includes(i.value)"
                  class="add_to_project pt-0 pb-0 assignment_div_ch"
                  ><div class="assignment_text_div">{{ i.text }}<v-icon small class="assign_icon mt-2">
                      mdi-pencil-plus
                    </v-icon></div></v-card-title
                >
              </v-card>
            </v-card-text>
          </v-card></v-col
        >
        <v-col :cols="main_cols" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Contacts</span>

              <v-spacer></v-spacer>
              <v-icon
              v-if="selected_correspondence != null"
                large
                class="mr-4 mt-2"
                @click="remove_corr_filter()"
              >
                mdi-email-remove
              </v-icon>
              <v-btn class="mb-n3 mr-4" @click="editItem('create_new')">
                + Add Contact
              </v-btn>
              <v-btn class="mb-n3 mr-4" @click="delete_items()"> Delete </v-btn>
              <v-btn class="mb-n3 mr-4" @click="assign_projects()">
                Assign
              </v-btn>
              <div class="project_select_div" :v-if="project_names_filter.length > 0">
                <v-select
                  class="mr-4 project_select"
                  v-model="selected_project"
                  :items="project_names_filter"
                ></v-select>
              </div>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text v-if="selected_table == 0" class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_general"
                :items="contacts"
                :items-per-page="100"
                class="elevation-1 main_project_table"
                :expanded.sync="expanded"
                show-expand
                single-expand
                dense
                :search="search"
                hide-default-footer
              >
              <template v-if="assign" v-slot:[`item.contact_given_name`]="{ item }">
                  <span
                    draggable="true"
                    class="test"
                    @dragstart="move_correspondence(item.id)"
                    @dragend="stop_move_correspondence()"
                    >{{ item.contact_given_name + ' ' + item.contact_family_name }}</span
                  >
                </template>
                <template v-else v-slot:[`item.contact_given_name`]="{ item }">
                  <span
                    >{{ item.contact_given_name + ' ' + item.contact_family_name }}</span
                  >
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Communication</v-tab>
                    <v-tab>Notes</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:expanded-item="{}">
                  <!-- <td :colspan="1"></td>
                  <td :colspan="1" v-if="selected_correspondences.length == 0" class="text_align_left">
                    No correspondence with this contact
                  </td>
                  <td
                    :colspan="2"
                    id="sub_td"
                    v-if="selected_correspondences.length > 0"
                  >
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Title</th>
                            <th class="text-left">Mode</th>
                            <th class="text-left">Datetime</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="i in selected_correspondences" :key="i.id">
                            <td class="text-left">
                              {{ i.correspondence_subject }}
                            </td>
                            <td class="text-left">
                              {{ i.correspondence_communication_mode }}
                            </td>
                            <td class="text-left">
                              {{ i.correspondence_datetime }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                  <td :colspan="6"></td> -->
                  <td></td>
                  <td :colspan="3" class="sub_td">
                    <v-simple-table dense fixed-header>
                      
                      <template v-slot:default>
                        <colgroup
                        v-if="
                          selected_sub_table == 1 &&
                            selected_correspondences.length > 0
                        "
                      >
                        <col class="tt_percent" />
                        <col class="st_percent" />
                        <col class="tt_percent" />
                        <col class="st_percent" />
                      </colgroup>
                        <thead>
                          <v-tabs
                            class="table_tabs pt-1"
                            v-model="selected_sub_table"
                          >
                            <v-tab>Projects</v-tab>
                            <v-tab>correspondence</v-tab>
                          </v-tabs>
                          <tr
                            v-if="
                              selected_sub_table == 0 &&
                              projects[0].length > 0
                            "
                          >
                            <th class="text-left">Name</th>
                            <th class="text-left">Description</th>
                            <th class="text-left">Start</th>
                            <th class="text-left">End</th>
                          </tr>
                          <tr
                            v-if="
                              selected_sub_table == 1 &&
                              selected_correspondences.length > 0
                            "
                          >
                            <th class="text-left">Subject</th>
                            <th class="text-left">Mode</th>
                            <th class="text-left">Date</th>
                            <th class="text-left">Att.</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            selected_sub_table == 0 &&
                            projects.length > 0
                          "
                        >
                          <tr v-for="i in projects[0]" :key="i.id">
                            <td class="text-left">
                              {{ i.project_name }}
                            </td>

                            <td class="text-left">
                              {{ i.project_description }}
                            </td>
                            <td class="text-left">
                              {{ i.project_start_date }}
                            </td>
                            <td class="text-left">
                              {{ i.project_end_date }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            selected_sub_table == 0 &&
                            projects[0].length < 1
                          "
                        >
                          <tr class="text_align_left">
                            <span>No projects for this contact</span>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            selected_sub_table == 1 &&
                            selected_correspondences.length > 0
                          "
                        >
                          <tr v-for="i in selected_correspondences" :key="i.id">
                            <td class="text-left">
                              {{ i.correspondence_subject }}
                            </td>
                            <td class="text-left">
                              {{ i.correspondence_communication_mode }}
                            </td>
                            <td class="text-left">
                              {{ i.correspondence_datetime }}
                            </td>
                            <td class="text-left">
                              {{ i.correspondence_has_attachment }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            selected_sub_table == 1 &&
                            selected_correspondences.length < 1
                          "
                        >
                          <tr class="text_align_left">
                            <span>No correspondences for this contact</span>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                  <td :colspan="4"></td>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="action_div">
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_project(item.id)">
                      mdi-clipboard-text
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_correspondence(item.id)">
                      mdi-email-box
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-simple-checkbox
                      class="inline_cb"
                      :ripple="false"
                      v-model="contacts[contacts.indexOf(item)].delete"
                    ></v-simple-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.contact_company_name`]="{ item }">
                  <span
                    v-if="
                      item.contact_company_name != 'null' &&
                      item.contact_company_name != '' &&
                      typeof item.contact_company_name == 'string'
                    "
                    >{{ item.contact_company_name }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_title`]="{ item }">
                  <span
                    v-if="
                      item.contact_title != 'null' &&
                      item.contact_title != '' &&
                      typeof item.contact_title == 'string'
                    "
                    >{{ item.contact_title }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_work_email_domain`]="{ item }">
                  <span
                    v-if="
                      item.contact_work_email_domain != 'null' &&
                      item.contact_work_email_domain != '' &&
                      typeof item.contact_work_email_domain == 'string'
                    "
                    >{{ item.contact_work_email_domain }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span>{{ item.createdAt.split("T")[0] }}</span>
                </template>
                <template v-slot:[`item.contact_type`]="{ item }">
                  <span
                    v-if="
                      item.contact_type != 'null' &&
                      item.contact_type != '' &&
                      typeof item.contact_type == 'string'
                    "
                    >{{ item.contact_type }}</span
                  ><span v-else> - </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text v-if="selected_table == 1" class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_communication"
                :items="contacts"
                :items-per-page="100"
                class="elevation-1"
                dense
                :search="search"
                hide-default-footer
              >
              <template v-slot:[`item.contact_given_name`]="{ item }">
                  <span
                    >{{ item.contact_given_name + ' ' + item.contact_family_name }}</span
                  >
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Communication</v-tab>
                    <v-tab>Notes</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="action_div">
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_project(item.id)">
                      mdi-clipboard-text
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_correspondence(item.id)">
                      mdi-email-box
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-simple-checkbox
                      class="inline_cb"
                      :ripple="false"
                      v-model="contacts[contacts.indexOf(item)].delete"
                    ></v-simple-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.contact_personal_email`]="{ item }">
                  <span
                    v-if="
                      item.contact_personal_email != 'null' &&
                      item.contact_personal_email != '' &&
                      typeof item.contact_personal_email == 'string'
                    "
                    >{{ item.contact_personal_email }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_work_email`]="{ item }">
                  <span
                    v-if="
                      item.contact_work_email != 'null' &&
                      item.contact_work_email != '' &&
                      typeof item.contact_work_email == 'string'
                    "
                    >{{ item.contact_work_email }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_mobile_phone`]="{ item }">
                  <span
                    v-if="
                      item.contact_mobile_phone != 'null' &&
                      item.contact_mobile_phone != '' &&
                      typeof item.contact_mobile_phone == 'string'
                    "
                    >{{ item.contact_mobile_phone }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_home_phone`]="{ item }">
                  <span
                    v-if="
                      item.contact_home_phone != 'null' &&
                      item.contact_home_phone != '' &&
                      typeof item.contact_home_phone == 'string'
                    "
                    >{{ item.contact_home_phone }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.contact_work_phone`]="{ item }">
                  <span
                    v-if="
                      item.contact_work_phone != 'null' &&
                      item.contact_work_phone != '' &&
                      typeof item.contact_work_phone == 'string'
                    "
                    >{{ item.contact_work_phone }}</span
                  ><span v-else> - </span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text v-if="selected_table == 2" class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_notes"
                :items="contacts"
                :items-per-page="100"
                class="elevation-1"
                dense
                :search="search"
                hide-default-footer
              >
              <template v-slot:[`item.contact_given_name`]="{ item }">
                  <span
                    >{{ item.contact_given_name + ' ' + item.contact_family_name }}</span
                  >
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Communication</v-tab>
                    <v-tab>Notes</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="action_div">
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_project(item.id)">
                      mdi-clipboard-text
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_correspondence(item.id)">
                      mdi-email-box
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-simple-checkbox
                      class="inline_cb"
                      :ripple="false"
                      v-model="contacts[contacts.indexOf(item)].delete"
                    ></v-simple-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.contact_notes`]="{ item }">
                  <span
                    v-if="
                      item.contact_notes != 'null' &&
                      item.contact_notes != '' &&
                      typeof item.contact_notes == 'string'
                    "
                    >{{ item.contact_notes }}</span
                  ><span v-else> - </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
      <v-dialog v-model="dialog" max-width="800px" min-width="760px">
        <v-card>
          <v-card-title>
            <span class="text-h5 ml-3" v-if="create_new">Create Contact</span>
            <span class="text-h5 ml-3" v-else>Edit Contact</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_given_name"
                    label="Given Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_family_name"
                    label="Family Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_company_name"
                    label="Company Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_source"
                    label="Source"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_type"
                    label="Type"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_personal_email"
                    label="Personal Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_work_email"
                    label="Work Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_work_email_domain"
                    label="Email Domain"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_mobile_phone"
                    label="Mobile Phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_home_phone"
                    label="Home Phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.contact_work_phone"
                    label="Work Phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  Notes
                  <v-textarea
                    solo
                    v-model="editedItem.contact_notes"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <span class="error_span">{{ error }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Cancel </v-btn>
            <v-btn color="primary" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog></v-container
    >
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  props: ["filter", "id"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v) ||
          "Enter a valid MAC address",
      },
      expanded: [],
      headers_general: [
        {
          text: "Name",
          value: "contact_given_name",
        },
        {
          text: "Company",
          value: "contact_company_name",
        },
        {
          text: "Title",
          value: "contact_title",
        },
        { text: "Email Domain", value: "contact_work_email_domain" },
        { text: "Created", value: "createdAt" },
        { text: "Type", value: "contact_type" },
        { text: "Action", value: "action", sortable: false, width: "1px" },
      ],
      headers_communication: [
        {
          text: "Name",
          value: "contact_given_name",
        },
        { text: "P Email", value: "contact_personal_email" },
        { text: "W Email", value: "contact_work_email", width: "25%" },
        { text: "M Phone", value: "contact_mobile_phone" },
        { text: "H Phone", value: "contact_home_phone" },
        { text: "W Phone", value: "contact_work_phone" },
        { text: "Action", value: "action", sortable: false, width: "1px" },
      ],
      headers_notes: [
        {
          text: "Name",
          value: "contact_given_name",
          width: "18%",
        },
        { text: "Notes", value: "contact_notes" },
        { text: "Action", value: "action", sortable: false, width: "1px" },
      ],
      search: "",
      selected_sub_table: 0,
      staging_production: true,
      loading: true,
      editedItem: { wifi_location: {} },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      selected_project: "All Projects",
      selected_contact: null,
      assign: false,
      main_cols: 12,
      assigned_projects_ids: [[], []],
      projects: [[], []],
      assigned_projects_ready: false,
      selected_correspondence: null
    };
  },
  methods: {
    remove_corr_filter() {
      this.selected_correspondence = null
      this.$store.dispatch("DDB_GET_CONTACTS", {});
    },
    redirect_to_correspondence(id) {
      this.$router.push({
        name: "correspondence",
        params: {
          filter: "contact",
          id: id,
        },
      });
    },
    redirect_to_project(id) {
      this.$router.push({
        name: "projects",
        params: {
          filter: "contact",
          id: id,
        },
      });
    },
    async assign_correspondence(project) {
      if (!this.assigned_projects_ids[0].includes(project)) {
        await API.graphql({
          query: mutations.createProjectContact,
          variables: {
            input: {
              contact_id: this.selected_contact,
              owner: "06d79203-450f-4f44-afd3-04e4a8dfcbaf",
              project_id: project,
            },
          },
        });
      } else {
        await API.graphql({
          query: mutations.deleteProjectContact,
          variables: {
            input: {
              id: this.assigned_projects_ids[1][
                this.assigned_projects_ids[0].indexOf(project)
              ],
            },
          },
        });
      }
    },
    assign_projects() {
      this.assign = !this.assign;
    },
    move_correspondence(id) {
      this.expanded = [];
      this.selected_contact = id;
    },
    stop_move_correspondence() {
      this.assigned_projects_ids = [[], []];
      this.assigned_projects_ready = false
      this.selected_contact = null;
    },
    async delete_items() {
      try {
        for (const i in this.contacts) {
          if (this.contacts[i].delete) {
            this.$store.dispatch(
              "DDB_GET_DELETE_CONTACTS_CORR",
              this.contacts[i].id
            );
            this.$store.dispatch(
              "DDB_GET_DELETE_CONTACTS_PROJECTS",
              this.contacts[i].id
            );
            await API.graphql({
              query: mutations.deleteContact,
              variables: {
                input: { id: this.contacts[i].id },
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
      if (this.selected_project != "All Projects") {
        this.$store.dispatch(
          "DDB_GET_CONTACTS_BY_PROJECT",
          this.$store.getters.getProjectNamesID[this.selected_project]
        );
      } else {
        this.$store.dispatch("DDB_GET_CONTACTS", {});
      }
    },
    editItem(item) {
      if (item == "create_new") {
        this.editedItem = {};
        this.dialog = true;
        this.create_new = true;
      } else {
        this.editedIndex = this.contacts.indexOf(item);
        console.log(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    async save() {
      if (this.create_new) {
        try {
          this.editedItem.owner = "06d79203-450f-4f44-afd3-04e4a8dfcbaf";
          var create_contact = await API.graphql({
            query: mutations.createContact,
            variables: {
              input: this.editedItem,
            },
          });
          this.$store.dispatch("DDB_GET_CONTACTS", {});
          this.dialog = false;
          this.editedItem = {};
          console.log(create_contact);
        } catch (err) {
          this.error = err.errors[0].message;
        }
      } else {
        try {
          delete this.editedItem.delete;
          var update_user = await API.graphql({
            query: mutations.updateContact,
            variables: {
              input: this.editedItem,
            },
          });
          this.$store.dispatch("DDB_GET_CONTACTS", {});
          Object.assign(this.contacts[this.editedIndex], this.editedItem);
          this.dialog = false;
          this.editedItem = {};
          console.log(update_user);
        } catch (err) {
          this.error = err.errors[0].message;
        }
      }
    },
    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer =
          this.$store.getters.getOwnerNameObject[0][this.filter];
      }
    },
  },
  computed: {
    selected_correspondences() {
      return this.$store.getters.getCorrespondences;
    },
    project_names() {
      var project_names = [];
      for (const i in this.$store.getters.getProjectNamesID) {
        let project_name =
          this.$store.getters.getProjectNamesID[i].project_name;
        project_names.push({
          text: project_name,
          value: this.$store.getters.getProjectNamesID[i].id,
        });
      }
      return project_names;
    },
    project_names_filter() {
      var project_names_filter = [...this.project_names];
      project_names_filter.unshift({
        text: "All Projects",
        value: "All Projects",
      });
      return project_names_filter;
    },
    customer_names() {
      return this.$store.getters.getCustomerNames;
    },
    owner_customer_names() {
      return this.$store.getters.getOwnerNameObject[0];
    },
    contacts() {
      return this.$store.getters.getContacts;
    },
  },
  created() {
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(this.owner_filter);
    } else {
      this.owner_filter();
    }
    if (this.$store.getters.getProjectNamesID) {
      this.$store.dispatch("DDB_GET_PROJECT_ID_NAMES");
    }
    if (this.filter == "all") {
      this.$store.dispatch("DDB_GET_CONTACTS", {});
    } else if (this.filter == "project") {
      this.selected_project = this.id;
    } else if (this.filter == "correspondence") {
      this.selected_correspondence = this.id;
    } else {
      this.loading = false;
    }
  },
  watch: {
    selected_contact() {
      if (this.selected_contact != null) {
        this.$store
          .dispatch(
            "DDB_GET_PROJECTS_BY_CONTACT",
            this.selected_contact
          )
          .then(() => {
            this.projects = this.$store.getters.getSelectedProjects;
            for (const i in this.projects[0]) {
              this.assigned_projects_ids[0].push(this.projects[0][i].id);
              this.assigned_projects_ids[1].push(this.projects[1][i]);
            }
            this.assigned_projects_ready = true
          });
      }
    },
    assign() {
      if (this.assign) {
        this.main_cols = 10;
      } else this.main_cols = 12;
    },
    expanded() {
      if (this.expanded.length > 0) {
        this.$store.dispatch(
          "DDB_GET_PROJECTS_BY_CONTACT",
          this.expanded[0].id
        ).then(() => {
            this.projects = this.$store.getters.getSelectedProjects;
          });
        this.$store.dispatch(
          "DDB_GET_CORRESPONDENCES_BY_CONTACT",
          [this.expanded[0].id, false]
        );
      } else {
         this.projects = [[],[]]
      }
    },
    selected_correspondence() {
      if (this.selected_correspondence != null) {
        this.$store.dispatch(
          "DDB_GET_CONTACTS_BY_CORRESPONDENCE",
          [this.selected_correspondence, false]
        );
      }
    },
    selected_project() {
      if (this.selected_project != "All Projects") {
        this.selected_correspondence = null
        this.$store.dispatch(
          "DDB_GET_CONTACTS_BY_PROJECT",
          this.selected_project
        );
      } else {
        this.$store.dispatch("DDB_GET_CONTACTS", {});
      }
    },
    contacts() {
      this.loading = false;
    },
    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.sub_td {
  width: 50%;
}
.sub_table_td {
  float: left;
}
.inline_cb {
  display: inline;
}
</style>