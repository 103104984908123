<script>
import { Bar } from "vue-chartjs";
//import { eventBus } from "../main.js";

// @vuese
// @group Components
export default {
  extends: Bar,
  props: ["labels", "datasets", "timeframe", "title", "not_stacked"],
  data() {
    return {
      options_line: {
        title: {
          display: true,
          text: this.title,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: this.datasets.length > 1,
        },
        scales: {
          xAxes: [
            {
              stacked: !this.not_stacked ? true : false,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: !this.not_stacked ? true : false,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {},
  computed: {
    data() {
      return {
        data_bar: {
          datasets: this.datasets,

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  mounted() {
    if (
      (this.datasets[0].data && this.datasets[0].data.length > 1) ||
      (this.datasets[1] &&
        this.datasets[1].data &&
        this.datasets[1].data.length > 1) || (this.title == "Inferred Media")
    ) {
      //this.options_line.title.text = this.title + ` (${this.timeframe})`
      this.renderChart(this.data.data_bar, this.options_line);
    }
  },
  watch: {
    datasets: {
      handler: function () {
        // if (this.title == "Inferred Media") {
        //     console.log(this.datasets[0])
        //   }
        if (
          (this.datasets[0].data && this.datasets[0].data.length > 1) ||
          (this.datasets[1] &&
            this.datasets[1].data &&
            this.datasets[1].data.length > 1)
        ) {
          //this.options_line.title.text = this.title + ` (${this.timeframe})`
          this.renderChart(this.data.data_bar, this.options_line);
        }
      },
      deep: true,
    },
  },
};
</script>