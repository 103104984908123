<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row no-gutters v-if="videos.length > 0">
      <v-col xl="1" lg="1" class="nav_btn mb_hide_vid" align-self="center"
        ><v-btn
          class="mr-n10"
          :to="
            '/video/' +
            videos[index - 1].video_thumbnail.replaceAll('/', '+') +
            '&' +
            videos[index - 1].video_duration +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page +
            '&' +
            videos[index - 1].camera.camera_name +
            '&' +
            videos[index - 1].video_datetime
          "
          icon
          v-if="index != 0 && !betsy"
        >
          <v-icon class="nav_icon">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="mr-n10"
          :to="
            '/video/' +
            get_url(
              videos[index - 1].video_thumbnail,
              videos[index - 1].camera_id
            ).replaceAll('/', '+') +
            '&' +
            videos[index - 1].video_duration +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page +
            '&' +
            videos[index - 1].camera.camera_name +
            '&' +
            videos[index - 1].video_datetime
          "
          icon
          v-else-if="index != 0 && betsy"
        >
          <v-icon class="nav_icon">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col xl="10" lg="10"
        ><v-container>
          <div>
            <v-row>
              <v-btn
                :to="'/' + prev_page.replaceAll('+', '/').replaceAll('*', '+')"
                large
                class="filter_btn ml-3 mb-3"
                >back
              </v-btn>
              <v-spacer />
              <span class="pt-2 mb_hide_vid">{{ cam_name }}</span>
              <v-spacer />
              <span class="mr-3 pt-2 mb_hide_vid">{{ date.split(" ")[0] }}</span>
            </v-row>
            <v-row class="mb_display_vid">
                <span class="pt-2 ml-3">{{ cam_name }}</span>
                <v-spacer />
                <span class="mr-3 pt-2">{{ date.split(" ")[0] }}</span>
              </v-row>
            <v-row class="mb-2 pl-3">
              <span
                v-for="i in videos[index].appearances.items"
                :key="i.livestock_drop_tag"
              >
                <v-chip
                  @click="select_tag(i.appearance_start[0])"
                  class="mr-3"
                  >{{ i.livestock.items[0].livestock_drop_tag }}</v-chip
                ></span
              >
            </v-row>
            <div>
              <video-player
                id="video"
                class="vjs-big-play-centered"
                :options="{
                  autoplay: true,
                  controls: true,
                  fluid: true,
                }"
              />
            </div>
          </div> </v-container
      ></v-col>
      <v-col xl="1" lg="1" class="nav_btn mb_hide_vid" align-self="center"
        ><v-btn
          class="ml-n10"
          :to="
            '/video/' +
            videos[index + 1].video_thumbnail.replaceAll('/', '+') +
            '&' +
            videos[index + 1].video_duration +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page +
            '&' +
            videos[index + 1].camera.camera_name +
            '&' +
            videos[index + 1].video_datetime
          "
          icon
          v-if="index < videos.length - 1 && !betsy"
        >
          <v-icon class="nav_icon">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="ml-n10"
          :to="
            '/video/' +
            get_url(
              videos[index + 1].video_thumbnail,
              videos[index + 1].camera_id
            ).replaceAll('/', '+') +
            '&' +
            videos[index + 1].video_duration +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page +
            '&' +
            videos[index + 1].camera.camera_name +
            '&' +
            videos[index + 1].video_datetime
          "
          icon
          v-if="index < videos.length - 1 && betsy"
        >
          <v-icon class="nav_icon">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="videos.length > 0" class="mb_display_vid">
        <v-col
          xl="1"
          lg="1"
          class="nav_btn"
          align-self="center"
          v-if="index != undefined"
          ><v-btn
            class="mr-n10"
            :to="
              '/video/' +
              videos[index - 1].video_thumbnail.replaceAll('/', '+') +
              '&' +
              videos[index - 1].video_duration +
              '&' +
              sort_by +
              '&' +
              sort_desc +
              '&' +
              prev_page +
              '&' +
              videos[index - 1].camera.camera_name +
              '&' +
              videos[index - 1].video_datetime
            "
            icon
            v-if="index != 0 && !betsy"
          >
            <v-icon class="nav_icon">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            class="mr-n10"
            :to="
              '/video/' +
              get_url(
                videos[index - 1].video_thumbnail,
                videos[index - 1].camera_id
              ).replaceAll('/', '+') +
              '&' +
              videos[index - 1].video_duration +
              '&' +
              sort_by +
              '&' +
              sort_desc +
              '&' +
              prev_page +
              '&' +
              videos[index - 1].camera.camera_name +
              '&' +
              videos[index - 1].video_datetime
            "
            icon
            v-else-if="index != 0 && betsy"
          >
            <v-icon class="nav_icon">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col xl="1" lg="1" class="nav_btn" align-self="center"
          ><v-btn
            class="ml-n10"
            :to="
              '/video/' +
              videos[index + 1].video_thumbnail.replaceAll('/', '+') +
              '&' +
              videos[index + 1].video_duration +
              '&' +
              sort_by +
              '&' +
              sort_desc +
              '&' +
              prev_page +
              '&' +
              videos[index + 1].camera.camera_name +
              '&' +
              videos[index + 1].video_datetime
            "
            icon
            v-if="index < videos.length - 1 && !betsy"
          >
            <v-icon class="nav_icon">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            class="ml-n10"
            :to="
              '/video/' +
              get_url(
                videos[index + 1].video_thumbnail,
                videos[index + 1].camera_id
              ).replaceAll('/', '+') +
              '&' +
              videos[index + 1].video_duration +
              '&' +
              sort_by +
              '&' +
              sort_desc +
              '&' +
              prev_page +
              '&' +
              videos[index + 1].camera.camera_name +
              '&' +
              videos[index + 1].video_datetime
            "
            icon
            v-if="index < videos.length - 1 && betsy"
          >
            <v-icon class="nav_icon">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row></v-container>
    
  </div>
</template>

<script>
//import moment from "moment";
//import cfsign from 'aws-cloudfront-sign';
import VideoPlayer from "@/components/VideoPlayer.vue";
import videojs from "video.js";
import { eventBus } from "../main.js";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import Vue from "vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    VideoPlayer,
  },
  props: [
    "url",
    "duration",
    "sort_by",
    "sort_desc",
    "prev_page",
    "cam_name",
    "date",
  ],
  data() {
    return {
      downloadURL: "",
    };
  },
  computed: {
    videos() {
      if (this.sort_by == "video_customer" && this.sort_desc == "true") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getVideos],
          "customer.customer_name"
        );
      } else if (
        (this.sort_by == "camera#camera_name" ||
          this.sort_by == "video_camera") &&
        this.sort_desc == "true"
      ) {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "camera.camera_name"
        );
      } else if (this.sort_by == "video_datetime" && this.sort_desc == "true") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getVideos],
          "video_datetime"
        );
      } else if (this.sort_by == "video_duration" && this.sort_desc == "true") {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "video_duration"
        );
      } else if (this.sort_by == "video_size" && this.sort_desc == "true") {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "video_size"
        );
      } else if (this.sort_by == "video_customer" && this.sort_desc != "true") {
        return this.sortByNested(
          [...this.$store.getters.getVideos],
          "customer.customer_name"
        );
      } else if (
        (this.sort_by == "camera#camera_name" ||
          this.sort_by == "video_camera") &&
        this.sort_desc != "true"
      ) {
        return this.sortByNested(
          [...this.$store.getters.getVideos],
          "camera.camera_name"
        );
      } else if (this.sort_by == "video_datetime" && this.sort_desc != "true") {
        return this.sortByKey(
          [...this.$store.getters.getVideos],
          "video_datetime"
        );
      } else if (this.sort_by == "video_duration" && this.sort_desc != "true") {
        return this.sortByKey(
          [...this.$store.getters.getVideos],
          "video_duration"
        );
      } else if (this.sort_by == "video_size" && this.sort_desc != "true") {
        return this.sortByKey([...this.$store.getters.getVideos], "video_size");
      } else if (this.sort_by == "default") {
        return [...this.$store.getters.getVideos];
      } else {
        return this.sortByNestedDESC(
          [...this.$store.getters.getVideos],
          "video_datetime"
        );
      }
    },

    index() {
      const url_equal = (element) =>
        element.video_thumbnail ==
          this.url.replaceAll("+betsy-layer1", "").replaceAll("+", "/") &&
        element.video_duration == this.duration;
      return this.videos.findIndex(url_equal);
    },
    betsy() {
      return this.url.includes("betsy-layer");
    },
  },
  methods: {
    select_tag(duration) {
      var player = videojs(document.querySelector(".video-js"));
      player.currentTime(duration);
    },
    shuffle(array) {
      var currentIndex = 0,
        randomIndex;
      if (this.prev_page == "videolist") {
        currentIndex = this.$randomList.length;
        for (const n in this.$randomList) {
          randomIndex = Math.floor(this.$randomList[n] * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
          ];
        }
      } else {
        currentIndex = this.$randomListBetsy.length;
        for (const n in this.$randomListBetsy) {
          randomIndex = Math.floor(this.$randomListBetsy[n] * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
          ];
        }
      }

      return array;
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByNestedDESC(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    get_url(thumbnail, cam_id) {
      let temp_array = thumbnail.split(cam_id + "/");
      let betsy_thumbnail =
        temp_array[0] + "betsy-layer1/" + cam_id + "/" + temp_array[1];
      return betsy_thumbnail;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByKeyDESC(array, key) {
      return array.sort(function (a, b) {
        var x = b[key];
        var y = a[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    playVideo() {
      var duration = this.duration;
      if (duration.slice(0, 2) == "00" && parseInt(duration.slice(3, 5)) < 15) {
        this.playMP4();
      } else {
        this.playHLS();
      }
    },

    playHLS() {
      var player = videojs(document.querySelector(".video-js"));
      var source =
        process.env.VUE_APP_COOKIE_BUCKET +
        this.url.replaceAll("+", "/") +
        ".m3u8";
      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },

    playMP4() {
      var player = videojs(document.querySelector(".video-js"));
      var source =
        process.env.VUE_APP_COOKIE_BUCKET +
        this.url.replaceAll("+", "/") +
        ".mp4";
      player.src({
        src: source,
        type: "video/mp4",
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },
  },
  created() {
    if (this.$store.getters.getVideos == 0) {
      this.$store.dispatch("DDB_GET_VIDEOS", {});
    }
    eventBus.$on("player_ready", () => {
      this.playVideo();
    });
  },
  mounted() {
    // if(this.$video_start) {
    //   this.select_tag(this.$video_start[0])
    //   Vue.prototype.$video_start = false
    // }
    //this.playMP4();
  },
};
</script>
<style lang="css">
.nav_btn {
  text-align: center;
}
.nav_icon {
  font-size: 60px !important;
}
.mb_display_vid {
  display: none;
}
@media only screen and (max-width: 560px) {
  .mb_hide_vid {
    display: none;
  }
  .mb_display_vid {
    display: flex;
  }
}
</style>


