<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Sites</span>
              <v-spacer></v-spacer>
              <v-switch
                class="mt-0 mr-6 mb-0"
                v-model="editable"
                label="Edit"
                color="primary"
                inset
                hide-details
              ></v-switch>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Site</span>
              </v-tooltip>
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn class="mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn>
              <div style="width: 180px" class="mt-n4 pr-3">
                <v-select
                  :items="[
                    { text: 'None', value: null },
                    { text: 'Customer', value: 'customer.last_name' },
                    { text: 'Operation Type', value: 'operation_type[0]' },
                  ]"
                  v-model="group_by"
                  flat
                  hide-details
                ></v-select>
              </div>
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
              </v-text-field>
              </div>
              <CustomerFilter class="mt-n4" v-model="selected_customer" @change="table_key++" :disabled="editable"></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <!-- :search="search" -->

              <!-- :items="site_list" -->
              <!-- :options.sync="options"
                :server-items-length="site_num"
                :pageCount="site_pages" -->
              <v-data-table
                :loading="loading"
                :headers="displayed_headers"
                :items-per-page="100"
                class="elevation-1 entry_table"
                :multi-sort="multi_sort"
                :group-by="group_by"
                hide-default-footer
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
     
                :key="table_key"
                single-select
                :item-class="item_class"

                :items="sites"
                :search="search"
              >
                <!-- <template v-slot:expanded-item="{ headers }">
                  <td :colspan="headers.length" class="px-0" v-if="expanded[0]">
                    <v-data-table
                      :loading = "note_loading"
                      loading-text="Loading... Please wait"
                      :headers="headers_notes"
                      :items="selected_notes"
                      :items-per-page="5"
                      sort-by="note_datetime"
                      :sort-desc="true"
                      style="border-radius: 0"
                      class="elevation-1 expanded_row"
                      hide-default-footer
                      dense
                    >
                    <template
                        v-slot:top="{ pagination, options, updateOptions }"
                      >
                      <div class="d-flex justify-end">
                        <div class="table_notes_title mt-2"><h3>NOTES</h3></div>
                          <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :items-per-page-options="[5]"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                @click="
                                  open_note
                                "
                                color="primary"
                                >mdi-note-plus-outline</v-icon
                              >
                            </template>
                            <span>Add Note</span>
                          </v-tooltip>
                      </div>
                      </template>
                      <template v-slot:[`item.note_datetime`]="{ item }">
                        {{ item.note_datetime.split("T")[0] }}
                      </template>
                      <template v-slot:[`item.author`]="{ item }">
                        <span v-if="item.author">{{
                          item.author.first_name + " " + item.author.last_name
                        }}</span>
                      </template>
                      <template v-slot:[`item.note_text`]="{ item }">
                        <v-edit-dialog
                          @save="save_notes(item)"
                          @cancel="edit_value_note = null"
                          large
                          @open="edit_value_note = item.note_text"
                        >
                          {{ item.note_text }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="edit_value_note"
                              label="Note"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-data-table>
                  </td>
                </template> -->
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>ID</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{index+1}}</span>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                  <div class="d-flex justify-space-between" style="width: 300px">
                      <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                  </div>
                </template>
                <template v-slot:[`item.data-table-expand`]="{item}">
                  <div style="display: flex">
                    <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="handleExpansion(item, isExpanded)" 
                        dense 
                        :class="'mr-5'" 
                        :color="(hover||isExpanded)?'primary':'gray'"
                        >{{isExpanded?'mdi-note-off-outline':'mdi-note-outline'}}</v-icon>
                        </v-hover>
                    </template>
                    <span>Notes</span>
                  </v-tooltip> -->
                    
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(item)" 
                        dense 
                        :color="hover?'primary':'gray'"
                        >mdi-pencil</v-icon>
                        </v-hover>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <span v-if="item.updated_at">{{ item.updated_at.split("T")[0] }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>

                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'name')"
                    @cancel="cancel_edit"
                    @open="open_field(item.name)"
                    @close="close_edit" large
                    :return-value.sync="item.name"
                  >
                    {{ item.name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.customer`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'customer_id')"
                    @cancel="cancel_edit"
                    @open="open_field(item.customer_id)"
                    @close="close_edit" large
                    :return-value.sync="item.customer_id"
                  >
                    <span v-if="item.customer">{{
                      item.customer.first_name +
                      " " +
                      item.customer.last_name
                    }}</span>
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="edit_value"
                        :items="customer_names"
                        label="Customer"
                        dense
                        outlined
                        class="pt-2"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.assigned_id`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'assigned_id')"
                    @cancel="cancel_edit"
                    @open="open_field(item.assigned_id)"
                    @close="close_edit" large
                    :return-value.sync="item.assigned_id"
                  >
                    {{ item.assigned_id }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Assigned ID"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.site_location`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'site_location')"
                    @cancel="cancel_edit"
                    @open="open_field({latitude: item.coordinates_lat, longitude: item.coordinates_long})"
                    @close="close_edit" large
                    :return-value.sync="item.site_location"
                  >
                    <span v-if="item.coordinates_lat || item.coordinates_long">
                      {{ item.coordinates_lat }},
                        {{ item.coordinates_long }}</span
                    > <span v-else>-</span>
                    <template v-slot:input>
                      <v-text-field
                        v-if="edit_value"
                        v-model="edit_value.latitude"
                        label="Latitude"
                      ></v-text-field>
                      <v-text-field
                        v-if="edit_value"
                        v-model="edit_value.longitude"
                        label="Longitude"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.area`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'area')"
                    @cancel="cancel_edit"
                    @open="open_field(item.area)"
                    @close="close_edit" large
                    :return-value.sync="item.area"
                  >
                    {{ item.area }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Area"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.map_zoom_level`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'map_zoom_level')"
                    @cancel="cancel_edit"
                    @open="open_field(item.map_zoom_level)"
                    @close="close_edit" large
                    :return-value.sync="item.map_zoom_level"
                  >
                    {{ item.map_zoom_level }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Zoom Level"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.operation_type`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'operation_type')"
                    @cancel="cancel_edit"
                    @open="open_field(item.operation_type)"
                    @close="close_edit" large
                    :return-value.sync="item.operation_type"
                  >
                    <span v-if="item.operation_type">{{
                      item.operation_type.join(", ")
                    }}</span>
                    <span v-else />
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Operation Type"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>

     
      <note-card></note-card>
    </v-container>
    <site-card></site-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
//import { eventBus } from "../main.js";/.
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import Vue from "vue";
import { eventBus } from "../main";
import SiteCard from "@/components/SiteCard.vue";
import CopyButton from "../components/CopyButton.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import NoteCard from "../components/NoteCard.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    SiteCard,
    CopyButton,
    CustomerFilter,
    NoteCard
},
  props: ["filter"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
        imei: (v) => /^\d {15}$||null$/.test(v) || "Enter a valid IMEI",
        id: (v) => /^([A-Z0-9]+){16}$/.test(v) || "Enter a valid ID",
      },
      multi_sort: false,
      group_by: null,
      staging_ranches: [],
      production_ranches: [],
      staging_production: true,
      loading: false,
      editedItem: { },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      owner_name: "",
      sort_by: ["updated_at"],
      sort_desc: true,
      delete_dialog: false,
      edit_value: null,
      expanded: [],
      edit_value_note: null,
      selected_notes: [],
      editable: false,
      note_dialog: false,
      note_text: "",
      selected_customer: "All Customers",
      table_key: 0,
      note_loading: false,

      search: "",
      search_old: "",
      site_list: [],
      site_num: 0,
      site_pages: 0,
      options: {},
    };
  },
  methods: {
    refresh(){
      this.fetch_sites_customers();
    },
    fetch_sites_customers(){
      this.loading = true;
      Promise.all([
        this.$store.dispatch("DDB_GET_SITES"), 
        this.$store.dispatch("DDB_GET_CUSTOMERS")
      ]).then(()=>{
        this.loading = false;
      })
    },
    handleExpansion(item, state){
      this.expanded = [];
      if(!state){
        this.expanded.push(item);
      }
    },
    item_class(item){
      return (this.expanded[0]?.id==item.id)?"expanded_row":""
    },
    // open_note(){
    //   eventBus.$emit("open_dialog_note");
    // },
    // async add_note(note) {
    //   this.note_loading = true;
    //   await API.graphql({
    //     query: mutations.createNote,
    //     variables: {
    //       input: {
    //         owner: this.$store.getters.getUser.username,
    //         site_id: this.expanded[0].id,
    //         customer_id: this.expanded[0].customer_id,
    //         author: {
    //           id: this.$store.getters.getUser.username,
    //           first_name: this.$store.getters.getUser.attributes.given_name,
    //           last_name: this.$store.getters.getUser.attributes.family_name,
    //         },
    //         updatedBy: this.$store.getters.getUser.username,
    //         note_datetime: moment().format("YYYY-MM-DDThh:mm:ss.sssZ"),
    //         note_text: note,
    //         typeName: "SITE",
    //       },
    //     },
    //   });
    //   this.$nextTick(() => {
    //     this.$store
    //       .dispatch("DDB_GET_SELECTED_NOTES", {
    //         id: this.expanded[0].id,
    //         type: "site",
    //       })
    //       .then((res) => {
    //         if (res || res == undefined) {
    //           this.selected_notes = this.$store.getters.getSelectedNotes;
    //         }
    //         this.note_loading = false;
    //       });
    //   });
    // },
    // async save_notes(note) {
    //   var edit_note = note;
    //   edit_note.note_text = this.edit_value_note;
    //   edit_note.note_datetime = moment().format("YYYY-MM-DDThh:mm:ss.sssZ");
    //   delete edit_note.author;
    //   edit_note.updatedBy = this.$store.getters.getUser.username;
    //   await API.graphql({
    //     query: mutations.updateNote,
    //     variables: {
    //       input: edit_note,
    //     },
    //   });
    //   this.$nextTick(() => {
    //     this.$store
    //       .dispatch("DDB_GET_SELECTED_NOTES", {
    //         id: this.expanded[0].id,
    //         type: "site",
    //       })
    //       .then((res) => {
    //         if (res || res == undefined) {
    //           this.selected_notes = this.$store.getters.getSelectedNotes;
    //         }
    //       });
    //   });
    // },
    close_edit() {
      //this.edit_value = null;
    },
    async save_record(record, field) {
      this.loading = true;
      var edited_record = {};
      if (field == "site_location") {
        edited_record.coordinates_lat = this.edit_value.latitude;
        edited_record.coordinates_long = this.edit_value.longitude;
      } else {
        edited_record[field] = this.edit_value;
      }
    

      try {
        const response = await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.update_site_by_pk,
          variables: {
            pk_columns: { id: record.id },
            _set: edited_record,
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      if (response.data.errors) {
        throw new Error("GraphQL error: " + response.data.errors[0].message);
      }
        this.edit_value = null;
        this.fetch_sites_customers();
      } catch (err) {
        console.log(err);
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value) {
      this.edit_value = value;
    },

    editItem(item) {
      if (!this.editable) {
          if (item == "create_new") {
          // this.editedItem = { wifi_location: {} };
          // this.dialog = true;
          // this.create_new = true;
          eventBus.$emit("open_dialog_sites", {
            item: { },
            create_new: true,
          });
        } else {
          this.editedItem = Object.assign({}, item);
          this.owner_name = this.editedItem.customer_id;
          this.dialog = true;
          eventBus.$emit("open_dialog_sites", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: this.editedItem.customer_id,

          });
        }
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = { };
      });
    },

  },
  computed: {
    displayed_headers(){
      if(this.selected_table == 0){
        return this.headers_general;
      }else if(this.selected_table == 1){
        return this.headers_id;
      }else{
        return this.headers_general;
      }
    },
    displayed_fields(){
      return this.fields
      // if(this.selected_table == 0){
      //   return this.fields_general;
      // }else if(this.selected_table == 1){
      //   return this.fields_id;
      // }else{
      //   return this.fields_general;
      // }
    },
    fields(){
      return [
        "name",
        "assigned_id",
        "operation_type",
        "area",
        "id"
      ]
    },
    headers_id(){
      return [
      {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px"
        },
        {
          text: "Name",
          value: "name",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer",
          value: "customer",
          cellClass: this.editable ? "non-hl" : "no_edit",
          filter: (value) => {
            if (this.selected_customer == "All Customers" || !this.selected_customer) return true;
            return value?.id == this.selected_customer;
          },
          sort: (a, b) => {
            if ((!a && !!b) || a?.first_name < b?.first_name) {
              return -1;
            }
            if ((!!a && !b) || a?.first_name > b?.first_name) {
              return 1;
            }
            return 0;
          },
        },
        {
          text: "Assigned ID",
          value: "assigned_id",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "ID",
          value: "id",

        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "customer.first_name", align: ' d-none'},
        { text: "", value: "customer.last_name", align: ' d-none'},
      ]
    },
    fields_id(){
      return [
        "name", "assigned_id", "id"
      ]
    },
    headers_notes() {
      return [
      {
          text: "",
          value: "index",
          sortable: false,
          width: "53px"
        },
        {
          text: "Date",
          value: "note_datetime",
          width: "120px",
        },
        {
          text: "Author",
          value: "author",
          width: "220px",
        },
        {
          text: "Note",
          value: "note_text",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
      ];
    },
    headers_general() {
      return [
      {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px"
        },
        {
          text: "Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer",
          value: "customer",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
          filter: (value) => {
            if (this.selected_customer == "All Customers" || !this.selected_customer) return true;
            return value?.id == this.selected_customer;
          },
          sort: (a, b) => {
            if ((!a && !!b) || a?.first_name < b?.first_name) {
              return -1;
            }
            if ((!!a && !b) || a?.first_name > b?.first_name) {
              return 1;
            }
            return 0;
          },
        },
        {
          text: "Assigned ID",
          value: "assigned_id",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Coordinates",
          value: "site_location",
          width: "16%",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Zoom",
          value: "map_zoom_level",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Size",
          value: "area",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Operation Type",
          value: "operation_type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Updated At",
          value: "updated_at",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "customer.first_name", align: ' d-none'},
        { text: "", value: "customer.last_name", align: ' d-none'},
      ];
    },
    fields_general(){
      return [
        "name", "assigned_id", "operation_type", "area"
      ]
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },

    sites() {
      let temp_sites = [];
      if (this.filter == "all") {
        temp_sites = this.$store.getters.getSiteObjects;
      } else {
        for (const z in this.$store.getters.getSiteObjects) {
          if (
            (
              this.$store.getters.getSiteObjects[z].customer
                .first_name +
              "_" +
              this.$store.getters.getSiteObjects[z].customer.last_name
            ).replaceAll(" ", "_") == this.filter
            || this.$store.getters.getSiteObjects[z].id.toLowerCase() == this.filter.toLowerCase()
          ) {
            temp_sites.push(this.$store.getters.getSiteObjects[z]);
          }
        }
      }
      for(const j in temp_sites){
          temp_sites[j].index = Number(j)+1;
        }
        return temp_sites;
    },
  },
  created() {
    if (this.$store.getters.getSiteObjects.length == 0) {
      this.fetch_sites_customers();
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
    // eventBus.$on("add_note", (note)=>{
    //     this.add_note(note);
    //   });
    eventBus.$on('updateSites', ()=>{
      this.fetch_sites_customers();
    });
  },
  mounted() {
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.selected_customer = Vue.prototype.$selectCustomer;
    }
  },
  watch: {

    expanded() {
      if (this.expanded[0]) {
        this.selected_notes = [];
        this.note_loading = true;
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "site",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
              this.note_loading = false;
            }
          });
      }
    },
    dialog() {
      if (this.dialog == false) {
        this.error = "";
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>


<style lang="css" scoped>
  .cam_icon > svg {
  height: 30px !important;
  width: 30px !important;
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
