var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"d-flex"},[(!_vm.alt)?_c('v-select',{class:{
        select_customer: !_vm.alt,
        'elevation-2': _vm.alt,
        select_customer_alt: _vm.alt,
        'mr-1': true,
      },attrs:{"items":_vm.userOptions,"flat":"","dense":_vm.dense,"filled":_vm.alt,"solo":_vm.alt,"full-width":_vm.alt,"allow-overflow":!_vm.alt,"hide-details":"","multiple":"","label":"Select Customer Types","selection-slot":""},scopedSlots:_vm._u([{key:"selection",fn:function({ index }){return [(index === 0 && _vm.usersChecked.length > 0)?_c('span',[_vm._v("Options Chosen "+_vm._s(_vm.usersChecked.length))]):_vm._e()]}}],null,false,2667453722),model:{value:(_vm.usersChecked),callback:function ($$v) {_vm.usersChecked=$$v},expression:"usersChecked"}}):_vm._e(),(_vm.alt)?_c('v-icon',{staticClass:"mr-3 elevation-2",class:_vm.pad ? 'mt-' + _vm.pad : _vm.dense ? '' : 'mt-4',attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.previousCustomer}},[_vm._v("mdi-chevron-left")]):_vm._e(),(_vm.alt)?_c('v-icon',{staticClass:"mr-3 elevation-2",class:_vm.pad ? 'mt-' + _vm.pad : _vm.dense ? '' : 'mt-4',attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.nextCustomer}},[_vm._v("mdi-chevron-right")]):_vm._e(),_c('v-autocomplete',{class:{
        select_customer: !_vm.alt,
        'elevation-2': _vm.alt,
        select_customer_alt: _vm.alt,
      },attrs:{"items":_vm.customers,"flat":"","dense":_vm.dense,"hide-details":"","menu-props":{ offsetOverflow: false },"disabled":_vm.loading || _vm.disabled,"filled":_vm.alt,"solo":_vm.alt,"full-width":_vm.alt,"allow-overflow":!_vm.alt},model:{value:(_vm.customer_select),callback:function ($$v) {_vm.customer_select=$$v},expression:"customer_select"}}),(!_vm.alt)?_c('v-icon',{class:_vm.pad ? 'mt-' + _vm.pad : _vm.dense ? '' : 'mt-4',attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.previousCustomer}},[_vm._v("mdi-chevron-left")]):_vm._e(),(!_vm.alt)?_c('v-icon',{class:_vm.pad ? 'mt-' + _vm.pad : _vm.dense ? '' : 'mt-4',attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.nextCustomer}},[_vm._v("mdi-chevron-right")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }