<template>
  <!-- prepend-inner-icon="mdi-greater-than-or-equal" -->
  <!-- class="px-3 py-2" -->
  <v-text-field
    :label="label"
    v-model="filter_num"
    @change="onInput()"
    dense
    hide-details
    type="number"
  >
    <template v-slot:prepend-inner>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">{{symbol==">="?"mdi-greater-than-or-equal":"mdi-less-than-or-equal"}}</v-icon>
        </template>

        <v-list dense>
          <v-list-item-group v-model="selected_item" color="primary">
            <v-list-item @click="symbol='>='; onInput();">
              <v-icon>mdi-greater-than-or-equal</v-icon>
            </v-list-item>
            <v-list-item @click="symbol='<='; onInput();">
              <v-icon>mdi-less-than-or-equal</v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
// @vuese
// @group Components
// The text field with a comparison sign. Wrapper for v-text-field
export default {
  props: {
      // Label of the v-text-field component
        "label":{
            type: String,
            required: false,

        }, 
        // Text value with comparison sign
        "value": {
            type: String,
            required: false,
        }},
  data() {
    return {
      selected_item: 0,
      filter_text: this.value,
      symbol: this.value?.split(" ")?.[0] || ">=",
      filter_num: this.value?.split(" ")?.[1]
    };
  },
  watch:{
    value(){
        this.filter_text = this.value;
        this.filter_num = this.value?.split(" ")?.[1];
        this.symbol = this.value?.split(" ")?.[0] || ">=";
    }
  },
  methods: {
    onInput(){
        if(!this.filter_num && this.filter_num!==0){
            this.filter_text = null;
        }else{
            this.filter_text = `${this.symbol} ${this.filter_num}`
        }
        // Fire when a new text is inputed
        // @arg The new text value
        this.$emit("input", this.filter_text);
        // Fire when the text value is changed
        // @arg The new text value
        this.$emit("change", this.filter_text)
    }
  }
};
</script>

<style>
</style>