<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row justify="center">
        <v-col xl="10" lg="11" cols="12">
          <v-card class="pl-4 pr-4">
            <v-row>
              <v-tabs class="table_tabs ml-3 mb-3 mt-3">
                <v-tab to="/">Overview</v-tab>
                  <v-tab to="/footageuploads">Footage</v-tab>
                  <v-tab to="/videolist">Videos</v-tab>
                  <v-tab to="/betsylist">Betsy</v-tab>
              </v-tabs>
            </v-row>
            <div id="grafana">
              <v-row>
                <v-row>
                  <v-col cols="6">
                    <!-- 11 -->

                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=22"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe>
                  </v-col>

                  <v-col cols="6">
                    <!-- 12 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=24"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <!-- 41 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&refresh=1m&panelId=4"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                  <v-col cols="6">
                    <!-- 42 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&refresh=1m&panelId=6"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <!-- 51 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=16"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe>
                  </v-col>

                  <v-col cols="6">
                    <!-- 52 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=12"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe
                  ></v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <!-- 61 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=10"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe
                  ></v-col>
                  <v-col cols="6">
                    <!-- 62 -->
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&panelId=8"
                      width="600"
                      height="300"
                      frameborder="0"
                    ></iframe
                  ></v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/gPiJVC57z/camera-count?orgId=1&panelId=2"
                      width="250"
                      height="200"
                      frameborder="0"
                    ></iframe
                  ></v-col>
                  <v-col cols="3">
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/gPiJVC57z/camera-count?orgId=1&panelId=4"
                      width="250"
                      height="200"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                  <v-col cols="3">
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uFq2OC57z/footage-stats?orgId=1&refresh=1m&panelId=2"
                      width="250"
                      height="200"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                  <v-col cols="3">
                    <iframe
                      src="https://grafana.onecup.ai/d-solo/uu_23Lc7z/footage?orgId=1&refresh=5s&panelId=2"
                      width="250"
                      height="200"
                      frameborder="0"
                    ></iframe>
                  </v-col>
                </v-row>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
// import axios from 'axios';
import navbar from '@/components/Navbar.vue';
import sidenav from '@/components/SideNav.vue';

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped>
/* .v-application .pr-4 {
  padding-right: 0px !important;
}
.v-application .pl-4 {
  padding-left: 20px !important;
} */
#grafana {
  text-align: center;
}
</style>

