<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pl-3 pr-3">
            <v-row>
              <v-tabs class="table_tabs ml-3 mt-3">
                <v-tab to="/">Overview</v-tab>
                <v-tab to="/footageuploads">Cameras</v-tab>
                <v-tab to="/inference">Inference</v-tab>
                <v-tab to="/services">Services</v-tab>
                <v-tab to="/system">System</v-tab>
                <v-tab to="/triton">Triton</v-tab>
              </v-tabs>
            </v-row>
            <v-container class="ml-3">
              <v-row no-gutters>
                <v-tabs v-model="selected_view" class="table_tabs">
                  <v-tab>Count</v-tab>
                  <v-tab>Predator</v-tab>
                  <v-tab>FPS</v-tab>
                  <!-- <v-tab>Owner</v-tab> -->
                  <v-tab>Tags</v-tab>
                  <v-tab>Composition</v-tab>
                  <v-tab>Area</v-tab>
                  <v-tab>Confidence</v-tab>
                  <v-tab>Calving</v-tab>
                  <v-tab>Correlation</v-tab>
                </v-tabs>
                <v-col cols="1" class="mr-3">
                  <v-select
                    v-model="source"
                    label="Source"
                    :items="source_list"
                  ></v-select>
                </v-col>
                <v-col cols="1" class="mr-3">
                  <v-select
                    v-model="confidence"
                    label="Confidence Cut Off"
                    :items="confidence_list"
                  ></v-select>
                </v-col>
                <v-col cols="1" class="mr-3">
                  <v-select
                    v-model="limit"
                    label="Limit"
                    :items="limit_list"
                  ></v-select>
                </v-col>
                <v-col cols="1" class="mr-3">
                  <v-text-field
                    label="Frame Area"
                    v-model="frame_area"
                    type="number"
                    prepend-inner-icon="mdi-greater-than-or-equal"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" class="mr-3">
                  <customer-filter
                    v-model="selected_customer"
                  ></customer-filter>
                </v-col>
              </v-row>
              <!-- Count -->
              <v-row v-if="selected_view == 0">
                <v-col cols="4" class="pl-0 pb-0">
                  <grafana-graph
                    from="now-24h"
                    to="now"
                    :database="database"
                    :table="table"
                    panelId="95"
                    height="85"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="4" class="pl-0 pb-0" v-if="!!last_time">
                  <grafana-graph
                    from="now-24h"
                    to="now"
                    :database="database"
                    :table="table"
                    panelId="161"
                    height="85"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :last_time="last_time"
                  ></grafana-graph>
                </v-col>
                <v-col cols="4" class="pl-0 pb-0" v-if="!!last_time">
                  <grafana-graph
                    from="now-24h"
                    to="now"
                    :database="database"
                    :table="table"
                    panelId="162"
                    height="85"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :last_time="last_time"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <!-- Number of Animals -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 141 : isByHour ? 132 : 66"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Frames -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 143 : isByHour ? 134 : 68"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Cameras -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 142 : isByHour ? 133 : 70"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Customers -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 145 : isByHour ? 136 : 72"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Videos -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 144 : isByHour ? 135 : 80"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Images -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="isByDay ? 146 : isByHour ? 137 : 115"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <!-- Number of Bounding Boxes -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="
                      isByDay && confidence == 0
                        ? 155
                        : isByHour && confidence == 0
                        ? 150
                        : 113
                    "
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <div class="d-flex">
                    <v-select
                      v-model="upload_time_from"
                      label="Uploads Start Time"
                      :items="upload_time_ranges"
                      class="mr-3"
                    ></v-select>
                    <v-select
                      v-model="upload_time_to"
                      label="Uploads End Time"
                      :items="upload_time_ranges"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="'now' + upload_time_from"
                    :to="'now' + upload_time_to"
                    :database="database"
                    :table="table"
                    panelId="138"
                    height="400"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Predator -->
              <v-row v-if="selected_view == 1">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="163"
                    height="300"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="170"
                    height="300"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="171"
                    height="300"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Corruption -->
              <v-row v-if="selected_view == 2">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="127"
                    height="500"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="166"
                    height="500"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="167"
                    height="500"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :limit="limit"
                    :source="source"
                    :frame_area="frame_area"
                    panelId="168"
                    height="500"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Owner -->
              <!-- <v-row v-if="selected_view == 2">
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    from="1673633022272"
                    to="1673654622272"
                    :database="database"
                    :table="table"
                    panelId="18"
                    height="400"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
					:frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    from="1673633054779"
                    to="1673654654779"
                    :database="database"
                    :table="table"
                    panelId="34"
                    height="400"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
					:frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    from="1673633082390"
                    to="1673654682390"
                    :database="database"
                    :table="table"
                    panelId="32"
                    height="200"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
					:frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    from="1673633104421"
                    to="1673654704422"
                    :database="database"
                    :table="table"
                    panelId="36"
                    height="200"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
					:frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row> -->
              <!-- Tags -->
              <v-row v-if="selected_view == 3">
                <v-col cols="3" class="pl-0 pb-0">
                  <date-time-menu
										v-model="date_range"
									>
									</date-time-menu>
                </v-col>
                <v-col cols="3" class="pl-0 pb-0">
                  <v-select
                    :disabled="date_range.length>0"
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="pl-0 pb-0">
                  <!-- <span>{{ camera_id }}</span> -->
                  <CameraAutoComplete
                  :all="true"
                  v-model="camera_id"
                  :owner="selected_customer"
                ></CameraAutoComplete>
                  <!-- <v-autocomplete
                    v-model="camera_id"
                    label="Camera"
                    :items="camera_id_list"
                    :loading="camera_id_loading"
                    :disabled="camera_id_loading"
                  ></v-autocomplete> -->
                </v-col>
                <v-col cols="3" class="pl-0 pb-0">
                  <v-text-field
                    label="Tag bounding box area"
                    v-model="bbox_tag_area"
                    type="number"
                    prepend-inner-icon="mdi-greater-than-or-equal"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :time_bucket="time_general_bucket"
                    :database="database"
                    :table="table"
                    panelId="10"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :time_bucket="time_general_bucket"
                    :database="database"
                    :table="table"
                    panelId="148"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :time_bucket="time_general_bucket"
                    :database="database"
                    :table="table"
                    panelId="174"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :time_bucket="time_general_bucket"
                    :database="database"
                    :table="table"
                    panelId="176"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :time_bucket="time_general_bucket"
                    :database="database"
                    :table="table"
                    panelId="177"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="2" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :database="database"
                    :table="table"
                    panelId="6"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="2" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :database="database"
                    :table="table"
                    panelId="8"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="2" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_from"
                    :to="time_to"
                    :database="database"
                    :table="table"
                    panelId="4"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                  :from="time_from"
                    :to="time_to"
                    :database="database"
                    :table="table"
                    panelId="14"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :bbox_tag_area="bbox_tag_area"
                    :camera_id="camera_id"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Composition -->
              <v-row v-if="selected_view == 4">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="12"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="20"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="22"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="24"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="26"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Area -->
              <v-row v-if="selected_view == 5">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="56"
                    height="200"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="4" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="52"
                    height="200"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="4" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="54"
                    height="200"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="28"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="50"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="12" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="48"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Confidence -->
              <v-row v-if="selected_view == 6">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="60"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="62"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="30"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="58"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="169"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Calving -->
              <v-row v-if="selected_view == 7">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <v-text-field
                    v-model="sgie_contraction"
                    label="sgie_contraction: model version"
                    placeholder="Enter a version number to enable filtering"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <v-text-field
                    v-model="sgie_cow_suckling"
                    label="sgie_cow_suckling: model version"
                    placeholder="Enter a version number to enable filtering"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <!-- Contraction Events -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="
                      isByDay && confidence == 0 && !sgie_contraction
                        ? 157
                        : isByHour && confidence == 0 && !sgie_contraction
                        ? 151
                        : 87
                    "
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :sgie_contraction="sgie_contraction"
                  ></grafana-graph>
                </v-col>
                <!-- Suckling Events -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="
                      isByDay && confidence == 0 && !sgie_cow_suckling
                        ? 156
                        : isByHour && confidence == 0 && !sgie_cow_suckling
                        ? 153
                        : 89
                    "
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :sgie_cow_suckling="sgie_cow_suckling"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <v-text-field
                    v-model="sgie_cow_age"
                    label="sgie_cow_age: model version"
                    placeholder="Enter a version number to enable filtering"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0"></v-col>
                <!-- Age Events -->
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :time_bucket="time_settings[time_range].bucket"
                    :database="database"
                    :table="table"
                    :panelId="
                      isByDay && confidence == 0 && !sgie_cow_age
                        ? 158
                        : isByHour && confidence == 0 && !sgie_cow_age
                        ? 154
                        : 91
                    "
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                    :sgie_cow_age="sgie_cow_age"
                  ></grafana-graph>
                </v-col>
              </v-row>
              <!-- Correlation -->
              <v-row v-if="selected_view == 8">
                <v-col cols="12" class="pl-0 pb-0">
                  <v-select
                    v-model="time_range"
                    label="Time Range"
                    :items="time_ranges"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="78"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="99"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="101"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="103"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="105"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="107"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="109"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
                <v-col cols="6" class="pl-0 pb-0">
                  <grafana-graph
                    :from="time_settings[time_range].from"
                    :to="time_settings[time_range].to"
                    :database="database"
                    :table="table"
                    panelId="111"
                    height="300"
                    :limit="limit"
                    :confidence="confidence"
                    :source="source"
                    :frame_area="frame_area"
                  ></grafana-graph>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import navbar from "../components/Navbar.vue";
import sidenav from "../components/SideNav.vue";
import customerFilter from "../components/CustomerFilter.vue";
import GrafanaGraph from "../components/GrafanaGraph.vue";
var queryClient;
import moment from "moment";
import { QueryCommand } from '@aws-sdk/client-timestream-query';
import {initializeTimestreamQueryClient} from '../utilities/sessionUtils';
import DateTimeMenu from "../components/DateTimeMenu.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    customerFilter,
    GrafanaGraph,
    DateTimeMenu,
    CameraAutoComplete,
  },
  data() {
    return {
      database: "production_inference_analytics",
      table: "inference",
      selected_customer: null,
      selected_view: 0,
      limit: "100000",
      confidence: 0,
      source: "source",
      bbox_tag_area: null,
      confidence_list: [
        { text: "none", value: 0 },
        { text: "90%", value: 0.9 },
        { text: "80%", value: 0.8 },
        { text: "70%", value: 0.7 },
        { text: "60%", value: 0.6 },
        { text: "50%", value: 0.5 },
        { text: "40%", value: 0.4 },
        { text: "30%", value: 0.3 },
        { text: "20%", value: 0.2 },
        { text: "10%", value: 0.1 },
      ],
      limit_list: [
        { text: "100,000", value: "100000" },
        { text: "250,000", value: "250000" },
        { text: "500,000", value: "500000" },
        { text: "1 Million", value: "1000000" },
        { text: "2 Million", value: "2000000" },
        { text: "5 Million", value: "5000000" },
        { text: "All", value: "ALL" },
      ],
      source_list: [
        { text: "all", value: "source" },
        { text: "video", value: "'video'" },
        { text: "image", value: "'image'" },
      ],
      update_key: 0,
      time_range: "24h",
      upload_time_ranges: [
        { text: "now", value: "" },
        { text: "1 hour ago", value: "-1h" },
        { text: "2 hour ago", value: "-2h" },
        { text: "3 hour ago", value: "-3h" },
        { text: "4 hour ago", value: "-4h" },
        { text: "5 hour ago", value: "-5h" },
        { text: "6 hour ago", value: "-6h" },
        { text: "7 hour ago", value: "-7h" },
        { text: "8 hour ago", value: "-8h" },
        { text: "9 hour ago", value: "-9h" },
        { text: "10 hour ago", value: "-10h" },
        { text: "11 hour ago", value: "-11h" },
        { text: "12 hour ago", value: "-12h" },
        { text: "13 hour ago", value: "-13h" },
        { text: "14 hour ago", value: "-14h" },
        { text: "15 hour ago", value: "-15h" },
        { text: "16 hour ago", value: "-16h" },
        { text: "17 hour ago", value: "-17h" },
        { text: "18 hour ago", value: "-18h" },
        { text: "19 hour ago", value: "-19h" },
        { text: "20 hour ago", value: "-20h" },
        { text: "21 hour ago", value: "-21h" },
        { text: "22 hour ago", value: "-22h" },
        { text: "23 hour ago", value: "-23h" },
        { text: "24 hour ago", value: "-24h" },
      ],
      upload_time_from: "-1h",
      upload_time_to: "",
      last_time: null,

      // models
      sgie_contraction: "",
      sgie_cow_suckling: "",
      sgie_cow_age: "",

      frame_area: "",

      camera_id: null,
      camera_id_list: [
        {text: "All", value: null}
      ],
      camera_id_sem: 0,
      camera_id_loading: false,

      date_range:[],
    };
  },
  watch: {
    selected_customer() {
      if (
        !this.selected_customer ||
        this.selected_customer == "All Customers"
      ) {
        this.database = "production_inference_analytics";
        this.table = "inference";
      } else {
        this.database = "production_inference_customer";
        this.table = `%22${this.selected_customer}%22`;
      }
      // this.getCameraList();
    },
    // time_range(){
    //   this.getCameraList();
    // },
    // date_range(){
    //   this.getCameraList();
    // }
  },
  computed: {
    time_ranges() {
      return this.$store.getters.getTimeRanges;
    },
    time_settings() {
      return this.$store.getters.getTimeSettings;
    },
    time_from(){
			if(this.date_range.length>0){
				return moment(this.date_range[0] + " " + "00:00:01").valueOf()
			}else{
				return this.time_settings[this.time_range].from
			}
		},
		time_to(){
			if(this.date_range.length>0){
				return moment(this.date_range[this.date_range.length-1] + " " + "23:59:59").valueOf()
			}else{
				return this.time_settings[this.time_range].to
			}
		},
    time_general_bucket(){
      if(this.date_range.length==1){
        return "1hour"
      }else if(this.date_range.length==2){
        return "1day"
      }else{
        return this.time_settings[this.time_range].bucket
      }
    },
    isByHour() {
      return (
        this.time_settings[this.time_range].bucket == "1hour" &&
        (!this.selected_customer ||
          this.selected_customer == "All Customers") &&
        this.source == "source" &&
        !this.frame_area
      );
    },
    isByDay() {
      return (
        this.time_settings[this.time_range].bucket == "1day" &&
        (!this.selected_customer ||
          this.selected_customer == "All Customers") &&
        this.source == "source" &&
        !this.frame_area
      );
    },
  },
  created() {
    this.initializeQueryClient().then(()=>{
      this.getLatestUploadTime();
      // this.getCameraList();
    }).catch(err=>{
      console.log(err);
    })
  },
  methods: {
		initializeQueryClient() {
			return initializeTimestreamQueryClient().then(client=>{
				queryClient = client;
			})
		},
    async getCameraList(){
      if(!queryClient){
        await this.initializeQueryClient();
      }
      this.camera_id_sem ++;
      this.camera_id_loading = true;
      var response = await this.getAllCameras() || [];
      response.unshift({
        text: "All",
        value: null
      });
      if(!response.includes(this.camera_id)){
        this.camera_id = null;
      }
      this.camera_id_list = response;
      this.camera_id_sem--;
      if(this.camera_id_sem==0){
        this.camera_id_loading = false;
      }
    },
    async getAllCameras(nextToken = undefined) {
			let response;
      console.log(`nextToken: ${nextToken}`);
			try {
				var args = this.time_settings[this.time_range].moment_args;
				var date = moment()
				var to = date.valueOf();
				var from = date.subtract(args[0], args[1]).valueOf();
        var table = this.table.replaceAll("%22", "");
				var params = {
							QueryString: `
							SELECT DISTINCT camera_id 
							FROM "${this.database}"."${table}"
							WHERE time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
							`,
							NextToken: nextToken,
						}
				var command = new QueryCommand(params);

				response = await queryClient.send(command);

        console.log(response);
        if (response.NextToken) {
          var next_response = await this.getAllCameras(response.NextToken);
          return [
            ...response.Rows.map((e) => {
              var value = e.Data[0].ScalarValue;
              return value;
            }),
            ...next_response.Rows.map((e) => {
              var value = e.Data[0].ScalarValue;
              return value;
            })
          ];
        }else{
          return response.Rows.map((e) => {
            var value = e.Data[0].ScalarValue;
            return value;
          });
        }
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}
		},
    getLatestUploadTime() {
      this.last_time = null;
      (async () => {
        var response = await this.getLatestUploadTime_1h();
        if (!response) {
          response = await this.getLatestUploadTime_24h();
        }
        if (!response) {
          response = "NULL";
        }
        this.last_time = response;
      })();
    },
    async getLatestUploadTime_1h() {
      let response;
      var params = {
            QueryString: `SELECT MAX(uploaded_at) 
                          FROM "${this.database}"."${this.table}" 
                          WHERE uploaded_at > ago(1h)`,
          }
      var command = new QueryCommand(params);
      try {
        response = await queryClient.send(command);
      } catch (err) {
        console.error("Error while querying:", err);
        throw err;
      }
      if (response.Rows[0]) {
        var date_time = moment
          .utc(response.Rows[0].Data[0].ScalarValue)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        return date_time;
      } else {
        return null;
      }
    },
    async getLatestUploadTime_24h() {
      let response;
      var params = {
            QueryString: `SELECT MAX(uploaded_at) 
                          FROM "${this.database}"."${this.table}" 
                          WHERE uploaded_at > ago(24h)`,
          }
      var command = new QueryCommand(params);
      try {
        response = await queryClient.send(command);
      } catch (err) {
        console.error("Error while querying:", err);
        throw err;
      }
      if (response.Rows[0]) {
        var date_time = moment
          .utc(response.Rows[0].Data[0].ScalarValue)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        return date_time;
      } else {
        return null;
      }
    },
  },
};
</script>
