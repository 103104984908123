<template>
  <div class="fill-height">
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container style="height: 100%">
      <v-row justify="center" style="height: 100%">
        <v-col cols="12">
          <v-card
            :loading="loading"
            scrollable
            style="height: 100%"
            class="grow d-flex flex-column flex-nowrap"
          >
            <v-card-title>
              <v-row class="ml-0 mr-0 pt-3 image_filter_header">
                <span class="gray_color mr-3 mt-3">
                  <h5 style="font-size: 12px; font-weight: 300">Results:</h5>
                  <h4 style="font-size: 18px; font-weight: 400">
                    {{ image_count }}
                  </h4>
                </span>
                <div class="d-flex">
                  <v-icon color="primary" class="mr-2" @click="selectAllIcon">{{
                    check_all_icon
                  }}</v-icon>
                  <v-icon
                    class="mr-2"
                    @click="show_hover = !show_hover"
                    :color="show_hover ? 'primary' : null"
                    :disabled="loading"
                    dense
                  >
                    mdi-cursor-default
                  </v-icon>
                  <v-icon
                    class="mr-2"
                    @click="show_zoom = !show_zoom"
                    :color="show_zoom ? 'primary' : null"
                    :disabled="loading"
                    dense
                  >
                    mdi-magnify-plus
                  </v-icon>
                  <v-icon
                    color="primary"
                    class="mr-2"
                    :disabled="selected_images.length <= 0 || loading"
                    @click="downloadAllFiles('json')"
                    >mdi-download</v-icon
                  >
                  <v-icon
                    color="primary"
                    :disabled="selected_images.length <= 0 || loading"
                    @click="downloadAllFiles('image')"
                    >mdi-image-area-close</v-icon
                  >
                </div>
                <v-col sm="6" md="2">
                  <v-btn block @click="openImageFilters"> Image Filter</v-btn>
                </v-col>
                <v-col sm="6" md="2">
                  <v-menu
                    v-model="datetime_menu"
                    :close-on-content-click="false"
                    open-on-hover
                    offset-overflow
                    :disabled="loading"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="filter_button mr-4"
                        :disabled="loading"
                      >
                        <span v-if="!selected_dates.length">all dates</span>

                        <span v-if="selected_dates.length == 1">{{
                          selected_dates[0]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/")
                        }}</span>
                        <span v-if="selected_dates.length == 2">{{
                          selected_dates[0]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/") +
                          " - " +
                          selected_dates[1]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/")
                        }}</span>
                      </v-btn>
                    </template>

                    <v-card height="380" width="280">
                      <v-row>
                        <v-col>
                          <v-date-picker
                            v-model="date_range"
                            flat
                            range
                            no-title
                            id="video_dp"
                            event-color="#ff9900"
                            :max="todays_date"
                          ></v-date-picker>
                        </v-col>
                      </v-row>
                      <v-row v-if="show_timebar">
                        <v-btn
                          class="filter_button ml-6"
                          color="primary"
                          id="saveButton"
                          @click="saveDTFilterMenu"
                        >
                          Save
                        </v-btn>
                        <v-icon
                          v-if="selected_dates.length"
                          @click="reset_dates"
                          class="ml-4 mt-n2"
                          >mdi-filter-off-outline</v-icon
                        >
                      </v-row>
                    </v-card>
                  </v-menu>

                  <!-- <v-text-field
                  v-model="date_range_text"
                  readonly
                  label="Date Range"
                  dense
                  hide-details
                >
                  <template v-slot:append
                    ><v-menu
                      nudge-top="9"
                      nudge-right="12"
                      :close-on-content-click="false"
                      open-on-click
                      left
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>

                      <v-card>
                        <v-date-picker
                          v-model="date_range"
                          flat
                          no-title
                          range
                          event-color="#ff9900"
                        ></v-date-picker>
                      </v-card> </v-menu
                  ></template>
                </v-text-field> -->
                </v-col>

                <!-- <v-col sm="3" md="1">
                  <v-select
                    v-model="per_row"
                    :items="row_limits"
                    label="Per Row"
                    dense
                    hide-details
                    :disabled="loading"
                  ></v-select>
                </v-col> -->
                <v-spacer></v-spacer>
                <v-col sm="3" md="1">
                  <v-select
                    v-model="per_row"
                    :items="row_limits"
                    label="Per Row"
                    dense
                    hide-details
                    :disabled="loading"
                  ></v-select>
                </v-col>
                <v-col sm="3" md="1">
                  <v-select
                    v-model="per_page"
                    :items="page_limits"
                    label="Per Page"
                    dense
                    hide-details
                    :disabled="loading"
                  ></v-select>
                </v-col>
                <v-col sm="6" md="2" class="mt-n4 mr-n3">
                  <CameraAutoComplete
                    :all="true"
                    v-model="selected_camera"
                    :owner="selected_customer"
                    :disabled="loading"
                  ></CameraAutoComplete>
                </v-col>
                <v-col sm="6" md="2" class="mt-n4">
                  <customer-filter
                    v-model="selected_customer"
                    :disabled="loading"
                  ></customer-filter>
                </v-col>

                <v-col sm="6" md="2" class="d-flex align-center">
                  <v-select
                    v-model="selectedTimeSlot"
                    :items="timeSlots"
                    item-text="text"
                    item-value="value"
                    label="Select Time Slot"
                    dense
                    hide-details
                    @change="parseTimeSlot"
                    :disabled="loading"
                  ></v-select>
                  <v-btn
                    icon
                    :disabled="isFirstTimeSlotSelected || loading"
                    @click="selectPreviousTimeSlot"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    :disabled="isLastTimeSlotSelected || loading"
                    @click="selectNextTimeSlot"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <div class="d-flex mt-n1">
                  <v-icon @click="refresh" :disabled="loading"
                    >mdi-refresh</v-icon
                  >
                  <v-icon
                    @click="show_bbox = !show_bbox"
                    v-if="!show_bbox"
                    :disabled="loading"
                    >mdi-drawing-box</v-icon
                  >
                  <v-icon
                    @click="show_bbox = !show_bbox"
                    v-else
                    color="primary"
                    :disabled="loading"
                    >mdi-drawing-box</v-icon
                  >
                  <v-icon
                    @click="show_bp = !show_bp"
                    v-if="!show_bp"
                    :disabled="loading"
                    >mdi-drawing</v-icon
                  >
                  <v-icon
                    @click="show_bp = !show_bp"
                    v-else
                    color="primary"
                    :disabled="loading"
                    >mdi-drawing</v-icon
                  >
                  <v-icon
                    @click="set_borders(true)"
                    v-if="!bordered"
                    :disabled="loading"
                    >mdi-border-all-variant</v-icon
                  >
                  <v-icon
                    @click="set_borders(true)"
                    v-else
                    color="primary"
                    :disabled="loading"
                    >mdi-border-all-variant</v-icon
                  >
                  <v-icon
                    @click="show_text = !show_text"
                    v-if="!show_text"
                    :disabled="loading"
                    >mdi-format-text</v-icon
                  >
                  <v-icon
                    @click="show_text = !show_text"
                    v-else
                    color="primary"
                    :disabled="loading"
                    >mdi-format-text</v-icon
                  >
                </div>
                <span class="mr-3 mt-5">
                  <h5 style="font-size: 18px; font-weight: 400">
                    Filters: {{ this.filters_enabled }}
                  </h5>
                </span>
              </v-row>
            </v-card-title>
            <!-- image list -->
            <v-card-text
              style="
                height: 90vh;
                overflow-y: auto;
                overflow-x: hidden;
                box-sizing: border-box;
              "
            >
              <v-row v-if="image_paths.length > 0" class="ml-0 mr-0 pb-3">
                <div id="grid-container-lf">
                  <image-thumbnail
                    v-for="(p, index) in image_paths"
                    :key="p.image_path + '_' + index"
                    :img="p"
                    :image_paths_imagePlayer="image_paths_imagePlayer"
                    :img_index="index"
                    :show_text="show_text"
                    :show_zoom="show_zoom"
                    :show_hover="show_hover"
                    :bordered="bordered"
                    :filters="filters"
                    :show_bbox="show_bbox"
                    :show_bp="show_bp"
                    @select="toggle_select"
                  ></image-thumbnail>
                </div>
                <div class="skeleton_container">
                  <v-skeleton-loader
                    v-if="!!image_next_token"
                    v-intersect.quiet="nextPage"
                    type="list-item-three-line"
                  ></v-skeleton-loader>
                </div>
              </v-row>
              <div v-else class="pa-5">
                <v-alert type="primary" dense class=" text-center mt-3">
                  No images found for the selected date range. Try adjusting the
                  dates to view available images.
                </v-alert>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <image-card></image-card>
    <general-filter-card></general-filter-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
// import VueLoadImage from "vue-load-image";
import moment from "moment";
import Vue from "vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import CameraAutoComplete from "../components/CameraAutoCompleteImages.vue";
import ImageCard from "../components/ImageCard.vue";
import ImageThumbnail from "../components/ImageThumbnail.vue";
import { eventBus } from "../main";
import JSZip from "jszip";
import GeneralFilterCard from "../components/GeneralFilterCard.vue";
import { createGeneralFilter } from "../utilities/GeneralFilterTemplate";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    // "vue-load-image": VueLoadImage,
    CustomerFilter,
    ImageCard,
    ImageThumbnail,
    GeneralFilterCard,
    CameraAutoComplete,
  },
  data() {
    return {
      loading: false,
      per_page: 64,
      page_limits: [48, 64, 80, 96, 144, 196, 256, 512, 1000],
      per_row: 6,
      row_limits: [2, 3, 4, 5, 6, 7, 8, 10, 12, 25, 50],
      show_text: false,
      show_bbox: true,
      show_bp: true,
      show_hover: false,
      show_zoom: false,
      all_customer_list: null,
      filters_enabled: "",

      selectedTimeSlot: null,
      timeSlots: [
        { text: "Time slot", value: null },
        ...Array.from({ length: 24 }, (_, i) => ({
          text: `${i}-${(i + 1) % 24}`,
          value: `${i}-${(i + 1) % 24}`,
        })),
      ],
      startTime: "",
      endTime: "",

      selected_customer: Vue.prototype.$selectCustomer,
      bordered: false,
      filters: createGeneralFilter(this.$store.getters),
      Contraction_count: 0,
      Contraction_Classification_count: 0,
      Activity_count: 0,
      Age_count: 0,
      Suckling_count: 0,
      image_next_token: null,
      todays_date: moment().format("YYYY-MM-DD"),

      // model versions
      model_versions: {},

      selected_images: [],
      check_all_icon: "mdi-checkbox-multiple-blank-outline",

      date_range: [],
      datetime_menu: false,
      selected_camera: null,
      inital_fetch: true,
      selected_dates: [],
      show_timebar: false,
    };
  },
  methods: {
    selectPreviousTimeSlot() {
      let currentIndex = this.timeSlots.findIndex(
        (slot) => slot.value === this.selectedTimeSlot
      );
      currentIndex = currentIndex <= 1 ? this.timeSlots.length : currentIndex;
      this.selectedTimeSlot = this.timeSlots[currentIndex - 1].value;
      this.parseTimeSlot(this.selectedTimeSlot);
    },
    selectNextTimeSlot() {
      let currentIndex = this.timeSlots.findIndex(
        (slot) => slot.value === this.selectedTimeSlot
      );
      currentIndex =
        currentIndex === -1 || currentIndex === this.timeSlots.length - 1
          ? 0
          : currentIndex;
      this.selectedTimeSlot = this.timeSlots[currentIndex + 1].value;
      this.parseTimeSlot(this.selectedTimeSlot);
    },
    parseTimeSlot(selectedSlot) {
      if (selectedSlot === null || selectedSlot === "Select a time slot") {
        this.startTime = "";
        this.endTime = "";
        this.filters.Time.hour_start = this.startTime;
        this.filters.Time.hour_end = this.endTime;
        this.fetchImagePaths(true);
        return;
      }
      const times = selectedSlot.split("-");
      this.startTime = times[0].trim();
      this.endTime = times[1].trim();
      this.fetchImagePaths(true);
    },
    reset_dates() {
      this.selected_dates = [];
      this.date_range = [];
      this.fetchImagePaths(true);
    },
    saveDTFilterMenu() {
      this.datetime_menu = false;
      this.selected_dates = this.date_range;
      this.selected_dates.sort();
      this.fetchImagePaths(true);
    },
    openImageFilters() {
      var params = {
        filters: this.filters,
        date_range: this.date_range,
        model_versions: this.model_versions,
      };
      eventBus.$emit("open_dialog_filters", params);
    },
    selectAllIcon() {
      if (this.check_all_icon != "mdi-checkbox-multiple-blank-outline") {
        this.check_all_icon = "mdi-checkbox-multiple-blank-outline";
      } else {
        this.check_all_icon = "mdi-checkbox-multiple-marked";
      }
      var has_selected_all =
        this.selected_images.length == this.image_paths.length;
      eventBus.$emit("select_all_images", has_selected_all);
    },
    async downloadAllFiles(type) {
      this.loading = true;

      var zip = JSZip();
      var zip_promises = [];

      for (let i = 0; i < this.selected_images.length; i++) {
        if (type != "image") {
          zip_promises.push(this.fetchJSON(zip, this.selected_images[i]));
        }
        zip_promises.push(this.fetchImage(zip, this.selected_images[i]));
      }

      await Promise.all(zip_promises);
      zip.generateAsync({ type: "blob" }).then(
        (blob) => {
          this.downloadBlob(blob, "Export.zip");
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    },
    fetchJSON(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    fetchImage(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + "_event.jpg";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.file(image_path.split("/").pop() + "_event.jpg", data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    downloadBlob(blob, filename) {
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toggle_select(image_path) {
      var index = this.selected_images.indexOf(image_path);
      if (index >= 0) {
        this.selected_images.splice(index, 1);
      } else {
        this.selected_images.push(image_path);
      }
    },
    convertTo12HourFormat(timeString) {
      const date = new Date(timeString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      const day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;

      hours = hours ? hours : 12;

      const hoursStr = hours.toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hoursStr}:${minutes}:${seconds} ${ampm}`;
    },
    open_image(index) {
      var params = {
        img: this.image_paths[index],
        image_path: this.image_paths[index].image_path,
        image_time: this.convertTo12HourFormat(
          this.image_paths[index].image_time
        ),
        user_id: this.image_paths[index].user_id,
        camera_id: this.image_paths[index].camera_id,

        selected: !!this.selected_images.find(
          (e) => e == this.image_paths[index].image_path
        ),
      };
      eventBus.$emit("open_dialog_images", params);
    },
    initializeDataRange() {
      var date = moment().utc();
      var now = date.format("YYYY-MM-DD");
      // var past = date.subtract(1, "day").format("YYYY-MM-DD");
      this.date_range = [now];
      this.selected_dates = this.date_range;
    },
    refresh() {
      this.inital_fetch = false;
      this.fetchImagePaths(true);
    },
    buildFilterStrings() {
      let filterStrings = [];

      for (let key in this.filters) {
        let filter = this.filters[key];

        // Handle 'Time' key
        if (key === "Time") {
          for (let subKey in filter) {
            if (filter[subKey] !== "") {
              filterStrings.push(`${subKey}=${filter[subKey]}`);
            }
          }
        }
        // Handle 'conf' key
        else if (key === "conf") {
          for (let subKey in filter) {
            filterStrings.push(`${subKey}${filter[subKey]}`);
          }
        }
        // Handle other keys
        else {
          for (let subKey in filter) {
            if (filter[subKey] === true) {
              filterStrings.push(key + " : " + subKey);
            }
          }
        }
      }

      return filterStrings;
    },

    fetchImagePaths(first_time, imagePlayer = null) {
      first_time;
      if (this.inital_fetch) {
        return;
      }
      this.loading = true;
      if (first_time) {
        this.selected_images = [];
      }
      if (this.startTime && this.endTime) {
        this.filters.Time.hour_start = this.startTime;
        this.filters.Time.hour_end = this.endTime;
      }
      var selected_dates = [...this.selected_dates];
      let filterStrings = this.buildFilterStrings();
      filterStrings.push(selected_dates);
      var params = {
        limit: this.per_page,
        first_time: first_time,
        date_range: selected_dates.sort(),
        filters: this.filters,
        model_versions: this.model_versions,
        imagePlayer: imagePlayer,
        sort_fields: [
          {
            field: "time",
            direction: "DESC",
          },
          {
            field: "camera_id",
            direction: "DESC",
          },
        ],
      };
      if (this.selected_customer && this.selected_customer != "All Customers") {
        params.user_id = this.selected_customer;
        filterStrings.push(this.selected_customer);
      }

      if (this.selected_camera) {
        params.camera_id = this.selected_camera;
        filterStrings.push(this.selected_camera);
      }

      this.filters_enabled = filterStrings.join(", ");

      this.$store.dispatch("DDB_GET_IMAGES", params).then((nextToken) => {
        if (this.$store.getters.getCustomerObjects.length != 0) {
          this.all_customer_list = this.$store.getters.getCustomerObjects;
        }
        this.image_next_token = nextToken;
        this.loading = false;
      });
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
    nextPage(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchImagePaths(false);
      }
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },
    updatePerRow() {
      var container = document.getElementById("grid-container-lf");
      container.style["grid-template-columns"] = `repeat(${this.per_row}, 1fr)`;
    },
    findDuplicates(array) {
      let resultToReturn = false;
      // call some function with callback function as argument
      resultToReturn = array.filter((element, index) => {
        var first_index = array.findIndex(
          (e) => e.image_path == element.image_path
        );
        if (first_index !== index) {
          console.log(`Duplicate Images: ${first_index} and ${index}`);
          return true;
        }

        return false;
      });
      // console.log(resultToReturn);
      if (resultToReturn) {
        console.log("Duplicate elements exist");
      } else {
        console.log("Duplicates don't exist ");
      }
    },
  },
  watch: {
    per_row() {
      this.updatePerRow();
      if ((this.per_row == 10 || this.per_row == 12) && this.per_page < 144) {
        this.per_page = 144;
      } else if (
        (this.per_row == 25 || this.per_row == 50) &&
        this.per_page < 1000
      ) {
        this.per_page = 1000;
      }
    },
    per_page() {
      this.fetchImagePaths(true);
    },
    selected_customer() {
      this.fetchImagePaths(true);
    },
    selected_camera() {
      this.fetchImagePaths(true);
    },
    date_range() {
      if (this.date_range.length > 0) {
        this.show_timebar = true;
      } else {
        this.show_timebar = false;
      }
    },
    // model_versions: {
    //   handler() {
    //     this.fetchImagePaths(true);
    //   },
    //   deep: true,
    // },
    // filters: {
    //   handler() {
    //     console.log(this.filters);
    //     this.fetchImagePaths(true);
    //   },
    //   deep: true,
    // }
  },
  computed: {
    isFirstTimeSlotSelected() {
      const index = this.timeSlots.findIndex(
        (slot) => slot.value === this.selectedTimeSlot
      );
      return index <= 1;
    },
    isLastTimeSlotSelected() {
      const index = this.timeSlots.findIndex(
        (slot) => slot.value === this.selectedTimeSlot
      );
      return index === this.timeSlots.length - 1 || index === -1;
    },
    // date_range_text() {
    //   var temp_arr = [...this.date_range];
    //   return temp_arr.sort().join(" ~ ");
    // },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    image_paths() {
      if (this.$store.getters.getImages[0]) {
        return this.$store.getters.getImages[0];
      } else {
        return [];
      }
    },
    image_paths_imagePlayer() {
      if (this.$store.getters.getImagesImagePlayer[0]) {
        // console.log(this.$store.getters.getImages);
        return this.$store.getters.getImagesImagePlayer[0];
      } else {
        return [];
      }
    },
    image_count() {
      if (this.$store.getters.getImages[2]) {
        return this.$store.getters.getImages[2].toLocaleString();
      } else {
        return 0;
      }
    },
    PGIE_type_list() {
      return this.$store.getters.getPGIETypeList;
    },
    Species_type_list() {
      return this.$store.getters.getSpeciesTypeList;
    },
    SGIE_type_list() {
      return this.$store.getters.getSGIETypeList;
    },
    model_version_list() {
      return this.$store.getters.getModelVersionList;
    },
  },
  created() {
    this.initializeDataRange();
    this.inital_fetch = false;
    this.fetchImagePaths(true);
    eventBus.$on("previousImage", (index) => {
      index = index - 1;
      if (index < 0) {
        index = this.image_paths.length - 1;
      }

      var img = this.image_paths[index];
      let user_id = img.user_id || "";
      let camera_id = img.camera_id || "";
      if (this.all_customer_list) {
        let foundCustomer = this.all_customer_list.find(
          (customer) => customer.id == user_id
        );
        if (foundCustomer) {
          user_id = `${foundCustomer.first_name} ${foundCustomer.last_name} - ${foundCustomer.company_name}`;
        }
        let foundCamera = foundCustomer?.cameras?.items.find(
          (camera) => camera.id == camera_id
        );
        if (foundCamera) {
          camera_id += ` - ${foundCamera.camera_model}`;
        }
      }
      var params = {
        img: img,
        image_path: img.image_path,
        image_time: this.convertTo12HourFormat(img.image_time),
        camera_id: img.camera_id,
        user_id: user_id,
        bbox_list: img.bbox_list,
        body_parts_list: img.body_parts_list,
        image_index: index,
        imagesLength: this.image_paths_imagePlayer.length,
        selected: !!this.selected_images.find((e) => e == img.image_path),
        classificationInfo: img.classificationInfo || [],
      };

      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("nextImage", (index) => {
      index = index + 1;

      if (index === this.image_paths_imagePlayer.length / 2) {
        this.fetchImagePaths(false, "imagePlayer");
      }
      if (index >= this.image_paths_imagePlayer.length) {
        index = 0;
      }
      var img = this.image_paths_imagePlayer[index];
      let user_id = img.user_id || "";
      let camera_id = img.camera_id || "";

      if (this.all_customer_list) {
        let foundCustomer = this.all_customer_list.find(
          (customer) => customer.id == user_id
        );
        if (foundCustomer) {
          user_id = `${foundCustomer.first_name} ${foundCustomer.last_name} - ${foundCustomer.company_name}`;
        }

        let foundCamera = foundCustomer?.cameras?.items.find(
          (camera) => camera.id == camera_id
        );
        if (foundCamera) {
          camera_id += ` - ${foundCamera.camera_model}`;
        }
      }
      var params = {
        img: img,
        image_path: img.image_path,
        image_time: this.convertTo12HourFormat(img.image_time),
        camera_id: camera_id,
        user_id: user_id,
        bbox_list: img.bbox_list,
        body_parts_list: img.body_parts_list,
        image_index: index,
        version: img.version,
        imagesLength: this.image_paths_imagePlayer.length,
        classificationInfo: img.classificationInfo || [],
        selected: !!this.selected_images.find((e) => e == img.image_path),
      };
      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("update_filters", (params) => {
      this.filters = params.filters;
      this.date_range = params.date_range;
      this.model_versions = params.model_versions;
      this.fetchImagePaths(true);
    });
  },
};
</script>
<style lang="css" scoped>
.filter_button {
  font-size: 14px;
  text-transform: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#lf_btns {
  /* width: 75px !important; */
  display: flex;
  float: right;
  margin-right: 6px;
  margin-top: 5px;
}
.image_filter_header {
  line-height: 16px;
}
.skeleton_container {
  width: 100%;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
.grid_container_f {
  position: relative;
  text-align: center;
  /* min-height: 130px; */
}
/* .grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
} */
.grid_container_f:hover > .datetime_grid {
  z-index: 3 !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.darker_bckrnd {
  /* background-color: rgba(0, 0, 0, 0.377); */
  padding-left: 1px;
  padding-right: 1px;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image_filter_menu {
  z-index: 101 !important;
}
</style>
