var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pl-3 pr-3"},[_c('v-row',[_c('v-tabs',{staticClass:"table_tabs ml-3 mb-3 mt-3"},[_c('v-tab',{attrs:{"to":"/"}},[_vm._v("Overview")]),_c('v-tab',{attrs:{"to":"/footageuploads"}},[_vm._v("Cameras")]),_c('v-tab',{attrs:{"to":"/inference"}},[_vm._v("Inference")]),_c('v-tab',{attrs:{"to":"/services"}},[_vm._v("Services")]),_c('v-tab',{attrs:{"to":"/system"}},[_vm._v("System")]),_c('v-tab',{attrs:{"to":"/triton"}},[_vm._v("Triton")])],1),_c('customer-filter',{attrs:{"pad":5},model:{value:(_vm.customer_select),callback:function ($$v) {_vm.customer_select=$$v},expression:"customer_select"}})],1),_c('v-row',[_c('v-col',{staticClass:"max_width pt-0",attrs:{"xl":"8","lg":"8","cols":"12"}},[_c('v-card',{staticClass:"largeCard",attrs:{"elevation":"2"}},[(_vm.customer_select)?_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-1 elevation-1",staticStyle:{"position":"absolute","z-index":"4","bottom":"25px","right":"60px","background-color":"white","border-radius":"2px"}},[_vm._v(" Zoom: "+_vm._s(_vm.zoom)+" ")]),_c('div',{staticClass:"pa-1",staticStyle:{"position":"absolute","z-index":"4","bottom":"-1px","left":"75px","/* background-color":"white","*/\n                      border-radius":"2px","font-weight":"900","font-size":"18px","color":"white","cursor":"pointer","text-stroke":"1.2px black"},on:{"click":function($event){return _vm.openGoogleEarth(53.1304, -110.3468, 10)}}},[_vm._v(" Earth ")]),_c('GmapMap',{staticStyle:{"width":"inherit","height":"390px"},attrs:{"center":{
                      lat: parseFloat(53.1304),
                      lng: parseFloat(-110.3468),
                    },"zoom":5,"map-type-id":"hybrid","options":{
                      mapTypeControl: true,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                    }},on:{"zoom_changed":_vm.get_zoom}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index + m.position,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":m.icon,"title":m.title},on:{"click":function($event){return _vm.select_customer(m)}}})}),1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"max_width pt-0",attrs:{"xl":"4","lg":"4","cols":"12"}},[(_vm.load_ready)?_c('info-card',{attrs:{"customer":_vm.customer_select}}):_vm._e()],1)],1)],1),_c('media_chart_row',{attrs:{"customer":_vm.customer_select}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }