import { render, staticRenderFns } from "./ByCamera.vue?vue&type=template&id=1a026e43&scoped=true"
import script from "./ByCamera.vue?vue&type=script&lang=js"
export * from "./ByCamera.vue?vue&type=script&lang=js"
import style0 from "./ByCamera.vue?vue&type=style&index=0&id=1a026e43&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a026e43",
  null
  
)

export default component.exports