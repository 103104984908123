<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col class="pr-0" v-if="assign" cols="2"
          ><v-card class="assignment_main_card">
            <v-card-text>
              <v-card
                class="mb-1"
                v-for="(i, index) in project_names"
                :key="index"
                @dragenter.prevent
                @dragover.prevent
                @drop="assign_correspondence(i.value)"
              >
                <v-card-title
                  v-if="!assigned_projects_ready"
                  class="pt-0 pb-0 assignment_div_ch"
                  ><div>{{ i.text }}</div></v-card-title
                >
                <v-card-title
                  v-else-if="assigned_projects_ids[0].includes(i.value)"
                  class="delete_from_project pt-0 pb-0 assignment_div_ch"
                  ><div class="assignment_text_div">
                    {{ i.text
                    }}<v-icon small class="assign_icon mt-2">
                      mdi-delete
                    </v-icon>
                  </div></v-card-title
                >
                <v-card-title
                  v-else-if="!assigned_projects_ids[0].includes(i.value)"
                  class="add_to_project pt-0 pb-0 assignment_div_ch"
                  ><div class="assignment_text_div">
                    {{ i.text
                    }}<v-icon small class="assign_icon mt-2">
                      mdi-pencil-plus
                    </v-icon>
                  </div></v-card-title
                >
              </v-card>
            </v-card-text>
          </v-card></v-col
        >
        <v-col :cols="main_cols" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Correspondence</span>

              <v-spacer></v-spacer>
              <v-btn class="mb-n3 mr-4" @click="delete_items()"> Delete </v-btn>
              <v-btn class="mb-n3 mr-4" @click="assign_projects()">
                Assign
              </v-btn>
              <!-- <v-menu
                min-width="240"
                max-width="240"
                :close-on-content-click="false"
                offset-y
                class="white_bckrnd"
                ><template v-slot:activator="{ on, attrs }">
                  <v-btn class="mb-n3 mr-4" v-on="on" v-bind="attrs">
                    Assign
                  </v-btn>
                </template>
                <div class="filter_menu_background">
                  <v-select
                    :menu-props="{
                      top: false,
                      offsetY: true,
                      closeOnClick: true,
                    }"
                    class="mr-4 ml-4"
                    v-model="assigned_projects"
                    :items="project_names"
                    label="Contacts"
                    multiple
                  ></v-select>
                  <div class="filter_conf">
                    <v-btn color="primary" text> Save </v-btn>
                  </div>
                </div>
              </v-menu> -->
              <div
                class="project_select_div"
                :v-if="project_names_filter.lenght > 0"
              >
                <v-select
                  class="mr-4 project_select"
                  v-model="selected_project"
                  :items="project_names_filter"
                ></v-select>
              </div>
              <div class="project_select_div" :v-if="contact_names.lenght > 0">
                <v-select
                  class="mr-4 project_select"
                  v-model="selected_contact"
                  :items="contact_names"
                ></v-select>
              </div>
              <div
                class="project_select_div"
                :v-if="contact_names_sender.lenght > 0"
              >
                <v-select
                  class="mr-4 project_select"
                  v-model="selected_sender"
                  :items="contact_names_sender"
                ></v-select>
              </div>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text v-if="selected_table == 0" class="text-center">
              <v-data-table
           
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_general"
                :items="correspondences"
                :items-per-page="100"
                class="elevation-1 main_project_table"
                single-expand
                show-expand
                :expanded.sync="expanded"
                dense
                :search="search"
                hide-default-footer
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template
                  v-if="assign"
                  v-slot:[`item.correspondence_subject`]="{ item }"
                >
                  <span
                    draggable="true"
                    class="test"
                    @dragstart="move_correspondence(item.id)"
                    @dragend="stop_move_correspondence()"
                    >{{ item.correspondence_subject }}</span
                  >
                </template>
                <template v-slot:[`item.correspondence_recipients`]="{ item }">
                  <div class="too_long_row">
                    <span v-for="i in item.correspondence_recipients" :key="i"
                      >{{ i }}
                    </span>
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="action_div_corr">
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_project(item.id)">
                      mdi-clipboard-text
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="redirect_to_customer(item.id)">
                      mdi-account
                    </v-icon>
                    <v-simple-checkbox
                      class="inline_cb"
                      :ripple="false"
                      v-model="
                        correspondences[correspondences.indexOf(item)].delete
                      "
                    ></v-simple-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.age`]="{ item }">
                  <span>{{ get_age(item.correspondence_datetime) }}</span>
                </template>
                <template v-slot:expanded-item="{}">
                  <td></td>
                  <td :colspan="3" class="sub_td">
                    <v-simple-table dense fixed-header>
                      <template v-slot:default>
                        <thead>
                          <v-tabs
                            class="table_tabs pt-1"
                            v-model="selected_sub_table"
                          >
                            <v-tab>Projects</v-tab>
                            <v-tab>Contacts</v-tab>
                          </v-tabs>
                          <tr
                            v-if="
                              selected_sub_table == 0 && projects[0].length > 0
                            "
                          >
                            <th class="text-left">Name</th>
                            <th class="text-left">Description</th>
                            <th class="text-left">Start</th>
                            <th class="text-left">End</th>
                          </tr>
                          <tr
                            v-if="
                              selected_sub_table == 1 && contacts.length > 0
                            "
                          >
                            <th class="text-left">Name</th>
                            <th class="text-left">Company</th>
                            <th class="text-left">Phone</th>
                            <th class="text-left">Work Email</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            selected_sub_table == 0 && projects[0].length > 0
                          "
                        >
                          <tr v-for="i in projects[0]" :key="i.id">
                            <td class="text-left">
                              {{ i.project_name }}
                            </td>

                            <td class="text-left">
                              {{ i.project_description }}
                            </td>
                            <td class="text-left">
                              {{ i.project_start_date }}
                            </td>
                            <td class="text-left">
                              {{ i.project_end_date }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="
                            selected_sub_table == 0 && projects[0].length < 1
                          "
                        >
                          <tr class="text_align_left">
                            <span>No projects for this correspondence</span>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="selected_sub_table == 1 && contacts.length > 0"
                        >
                          <tr v-for="(i, index) in contacts" :key="index">
                            <td class="text-left" v-if="i != null">
                              {{ i.contact_given_name }}
                              {{ i.contact_family_name }}
                            </td>
                            <td class="text-left" v-if="i != null">
                              {{ i.contact_company_name }}
                            </td>
                            <td class="text-left" v-if="i != null">
                              {{ i.contact_mobile_phone }}
                            </td>
                            <td class="text-left" v-if="i != null">
                              {{ i.contact_work_email }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          v-if="selected_sub_table == 1 && contacts.length < 1"
                        >
                          <tr class="text_align_left">
                            <span>No contacts for this correspondence</span>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                  <td :colspan="4"></td>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import moment from "moment";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  props: ["filter", "id"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v) ||
          "Enter a valid MAC address",
      },
      headers_general: [
        {
          text: "Subject",
          value: "correspondence_subject",
          width: "25%",
        },
        {
          text: "Attachments",
          value: "correspondence_has_attachment",
        },
        // {
        //   text: "Mode",
        //   value: "correspondence_communication_mode",
        // },
        {
          text: "Datetime",
          value: "correspondence_datetime",
        },
        {
          text: "Age (days)",
          value: "age",
        },
        {
          text: "Sender Email",
          value: "correspondence_sender",
          class: "too_long_row",
        },
        {
          text: "Recipient Email",
          value: "correspondence_recipients",
        },
        { text: "Action", value: "action", sortable: false },
      ],
      search: "",
      expanded: [],
      staging_production: true,
      loading: true,
      editedItem: { wifi_location: {} },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      selected_sub_table: 0,
      selected_project: "All Projects",
      selected_sender: "All Senders",
      selected_contact: "All Contacts",
      selected_correspondence: null,
      assign: false,
      main_cols: 12,
      assigned_projects_ids: [[], []],
      projects: [[], []],
      assigned_projects_ready: false,
    };
  },

  methods: {
    redirect_to_project(id) {
      this.$router.push({
        name: "projects",
        params: {
          filter: "correspondence",
          id: id,
        },
      });
    },
    redirect_to_customer(id) {
      this.$router.push({
        name: "contacts",
        params: {
          filter: "correspondence",
          id: id,
        },
      });
    },
    async assign_correspondence(project) {
      if (!this.assigned_projects_ids[0].includes(project)) {
        await API.graphql({
          query: mutations.createProjectCorrespondence,
          variables: {
            input: {
              correspondence_id: this.selected_correspondence,
              owner: "06d79203-450f-4f44-afd3-04e4a8dfcbaf",
              project_id: project,
            },
          },
        });
      } else {
        await API.graphql({
          query: mutations.deleteProjectCorrespondence,
          variables: {
            input: {
              id: this.assigned_projects_ids[1][
                this.assigned_projects_ids[0].indexOf(project)
              ],
            },
          },
        });
      }
    },
    get_age(dt) {
      return moment().diff(moment(dt, "YYYY-MM-DD hh-mm-ss"), "days");
    },
    assign_projects() {
      this.assign = !this.assign;
    },
    move_correspondence(id) {
      this.expanded = [];
      this.selected_correspondence = id;
    },
    stop_move_correspondence() {
      this.assigned_projects_ids = [[], []];
      this.assigned_projects_ready = false;
      this.selected_correspondence = null;
    },
    async delete_items() {
      try {
        for (const i in this.correspondences) {
          if (this.correspondences[i].delete) {
            this.$store.dispatch(
              "DDB_DELETE_CORR_CONTACTS",
              this.correspondences[i].id
            );
            this.$store.dispatch(
              "DDB_DELETE_CORR_PROJECTS",
              this.correspondences[i].id
            );
            await API.graphql({
              query: mutations.deleteCorrespondence,
              variables: {
                input: { id: this.correspondences[i].id },
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
      if (this.selected_project != "All Projects") {
        this.$store.dispatch("DDB_GET_CORRESPONDENCES_BY_PROJECT", [
          this.$store.getters.getProjectNamesID[this.selected_project],
          true,
        ]);
      } else if (this.selected_sender != "All Senders") {
        this.$store.dispatch("DDB_GET_SENDER_CORRESPONDENCES", {
          contact_id: this.selected_sender,
        });
      } else {
        this.$store.dispatch("DDB_GET_ALL_CORRESPONDENCES", {});
      }
    },
  },
  computed: {

    contacts() {
      return this.$store.getters.getSelectedContacts;
    },
    project_names() {
      var project_names = [];
      for (const i in this.$store.getters.getProjectNamesID) {
        let project_name =
          this.$store.getters.getProjectNamesID[i].project_name;
        project_names.push({
          text: project_name,
          value: this.$store.getters.getProjectNamesID[i].id,
        });
      }
      return project_names;
    },
    project_names_filter() {
      var project_names_filter = [...this.project_names];
      project_names_filter.unshift({
        text: "All Projects",
        value: "All Projects",
      });
      return project_names_filter;
    },
    contact_names_sender() {
      var contact_names_sender = [];
      for (const i in this.$store.getters.getContactNames) {
        let contact_name =
          this.$store.getters.getContactNames[i].contact_given_name +
          " " +
          this.$store.getters.getContactNames[i].contact_family_name;
        contact_names_sender.push({
          text: contact_name,
          value: this.$store.getters.getContactNames[i].id,
        });
      }
      contact_names_sender.unshift({
        text: "All Senders",
        value: "All Senders",
      });
      return contact_names_sender;
    },
    contact_names() {
      var contact_names = [];
      for (const i in this.$store.getters.getContactNames) {
        let contact_name =
          this.$store.getters.getContactNames[i].contact_given_name +
          " " +
          this.$store.getters.getContactNames[i].contact_family_name;
        contact_names.push({
          text: contact_name,
          value: this.$store.getters.getContactNames[i].id,
        });
      }
      contact_names.unshift({ text: "All Contacts", value: "All Contacts" });
      return contact_names;
    },
    correspondences() {
      return this.$store.getters.getAllCorrespondence;
    },
    // projects() {
    //   return this.$store.getters.getSelectedProjects;
    // },
  },
  created() {
    if (this.$store.getters.getContactNames.length == 0) {
      this.$store.dispatch("DDB_GET_CONTACT_ID_NAMES");
    }
    if (this.$store.getters.getProjectNamesID) {
      this.$store.dispatch("DDB_GET_PROJECT_ID_NAMES");
    }

    if (this.filter == "all") {
      this.$store.dispatch("DDB_GET_ALL_CORRESPONDENCES", {});
    } else if (this.filter == "project") {
      this.selected_project = this.id;
    } else if (this.filter == "contact") {
      this.selected_contact = this.id;
    } else {
      this.loading = false;
    }
  },
  watch: {
    assign() {
      if (this.assign) {
        this.main_cols = 10;
      } else this.main_cols = 12;
    },
    selected_contact() {
      console.log(this.selected_contact);
      if (this.selected_contact != "All Contacts") {
        if (this.selected_project != "All Projects") {
          this.selected_project = "All Projects";
        }
        if (this.selected_sender != "All Senders") {
          this.selected_sender = "All Senders";
        }
        this.$store.dispatch("DDB_GET_CORRESPONDENCES_BY_CONTACT", [
          this.selected_contact,
          true,
        ]);
      } else {
        if (
          this.selected_project != "All Projects" ||
          this.selected_sender != "All Senders"
        ) {
          return false;
        } else {
          this.$store.dispatch("DDB_GET_ALL_CORRESPONDENCES", {});
        }
      }
    },
    selected_sender() {
      if (this.selected_sender != "All Senders") {
        if (this.selected_project != "All Projects") {
          this.selected_project = "All Projects";
        }
        if (this.selected_contact != "All Contacts") {
          this.selected_contact = "All Contacts";
        }
        this.$store.dispatch("DDB_GET_SENDER_CORRESPONDENCES", {
          contact_id: this.selected_sender,
        });
      } else {
        if (
          this.selected_project != "All Projects" ||
          this.selected_contact != "All Contacts"
        ) {
          return false;
        } else {
          this.$store.dispatch("DDB_GET_ALL_CORRESPONDENCES", {});
        }
      }
    },
    selected_project() {
      if (this.selected_project != "All Projects") {
        if (this.selected_sender != "All Senders") {
          this.selected_sender = "All Senders";
        }
        if (this.selected_contact != "All Contacts") {
          this.selected_contact = "All Contacts";
        }
        this.$store.dispatch("DDB_GET_CORRESPONDENCES_BY_PROJECT", [
          this.selected_project,
          true,
        ]);
      } else {
        if (
          this.selected_sender != "All Senders" ||
          this.selected_contact != "All Contacts"
        ) {
          return false;
        } else {
          this.$store.dispatch("DDB_GET_ALL_CORRESPONDENCES", {});
        }
      }
    },
    expanded() {
      if (this.expanded.length > 0) {
        this.$store
          .dispatch("DDB_GET_PROJECT_BY_CORRESPONDENCE", this.expanded[0].id)
          .then(() => {
            this.projects = this.$store.getters.getSelectedProjects;
          });
        this.$store.dispatch(
          "DDB_GET_CONTACTS_BY_CORRESPONDENCE",
          [this.expanded[0].id, true]
        );
      } else {
        this.projects = [[], []];
      }
    },
    selected_correspondence() {
      if (this.selected_correspondence != null) {
        this.$store
          .dispatch(
            "DDB_GET_PROJECT_BY_CORRESPONDENCE",
            this.selected_correspondence
          )
          .then(() => {
            this.projects = this.$store.getters.getSelectedProjects;
            for (const i in this.projects[0]) {
              this.assigned_projects_ids[0].push(this.projects[0][i].id);
              this.assigned_projects_ids[1].push(this.projects[1][i]);
            }
            this.assigned_projects_ready = true;
          });
      }
    },
    correspondences() {
      this.loading = false;
    },
    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.action_div_corr {
  width: 80px;
}
.too_long_row {
  max-width: 180px !important;
}
.sub_td {
  width: 50%;
}
.inline_cb {
  display: inline;
}
.sub_table_td {
  float: left;
}
.project_select {
  width: 160px;
}
</style>