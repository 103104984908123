<template>
    <v-icon small @click="(e)=>copy(e, text)">mdi-content-copy</v-icon>
</template>

<script>
// @vuese
// @group Components
// The button for copying a given text. Wrapper for v-icon
export default {
    name: "CopyButton",
    props: {
        // The text value to be copied
        "text": {
            type: String,
            required: false,
        }
    },
    methods: {
        // @vuese
        // Copy the text
        // @arg **e**: Copy event <br />
        // @arg **t**: Text value to be copied
        copy(e, t){
            if(e.target.classList.contains("mdi-content-copy")){
                e.target.classList.remove("mdi-content-copy");
                e.target.classList.add("mdi-check");
                setTimeout(()=>{
                if(e.target){
                    e.target.classList.remove("mdi-check");
                    e.target.classList.add("mdi-content-copy");
                }
                }, 2000);
            }
            navigator.clipboard.writeText(t);
        }
    }
}
</script>

<style scoped>
.mdi-check{
  color: green;
}
</style>