<template>
    <div>
        <v-text-field      
        @focus="$emit('focus');"
        @blur="$emit('blur')" 
        ref="input"
        :placeholder="input_mask"
        :value="input_value" 
        @input.native="onInput"
        @input="submitValue"
        outlined
        :dense="!thick"
        :label="label"
        :class="thick?'':'text-validated pl-3 pr-3 pt-1'"
        :rules="rules"
        ></v-text-field >
    </div>
</template>

<script>
// @vuese
// @group Components
// The input field with mask. Wrapper of v-text-field
export default {
    props: {
        // The value to be edited
        value: String, 
        // The label of the v-text-field
        label: String, 
        // Turn on/off the thick styling
        thick: Boolean, 
        // The pattern string of the mask
        mask: String, 
        // Turn on/off the uppercase
        uppercase: Boolean,
        // The valudate rule of v-text-field
        rules: Array
    },
    data(){
        return {
            input_value: "",
            focused: false,
            hover:false,

            input_mask: this.mask || "",
            indexes: [],
        }
    },
    created(){
        this.input_value = this.applyMask(this.value);
    },
    watch: {
        value(){
            var value = this.applyMask(this.value);
            if(this.input_value!=value){
                this.input_value = value;
            }
        },
        mask(){
            this.input_mask = this.mask || "";
        }
    },
    methods: {
        clear(){
            this.input_value = null;
        },

        submitValue(v){
            this.input_value = this.applyMask(v);
            if(this.checkFullyMasked(this.input_value)){
                // console.log('locked');
                this.$refs.input.$el.getElementsByTagName('input')[0].maxLength = this.input_mask.length;
            }else{
                this.$refs.input.$el.getElementsByTagName('input')[0].maxLength = this.input_mask.length+1;
            }
            if(this.input_value==""){
                this.$emit('input', "");
            }else{
                this.$emit('input', this.unmaskedValue(this.input_value));
            }
        },
        checkFullyMasked(v){
            return (v && v.length == this.input_mask.length && v.replace(/[^_]/gm, '').length <= 0);
        },
        getSelectedCharacterLength(){
            if(!this.$refs.input){
                return 0;
            }
            var selectionStart = this.$refs.input.$el.getElementsByTagName('input')[0].selectionStart;
            var value = this.$refs.input.$el.getElementsByTagName('input')[0].value;
            value = value.substring(0, selectionStart).replace(/[^a-zA-Z0-9]/gm, '');
            return value.length;
        },
        applyMask(value){
            if(!value){
                return undefined;
            }
            var maxLength = this.input_mask.replace(/[^_]/gm, '').length;
            value = value.replace(/[^a-zA-Z0-9]/gm, '');
            value = value.substring(0, Math.min(value.length, maxLength));
            var result = ""
            var cursor = 0;
            var selectionLength = this.getSelectedCharacterLength();
            for(let i=0; i<this.input_mask.length; i++){
                if(value.length>0 && selectionLength>0){
                    cursor++;
                }
                if(this.input_mask.charAt(i)== '_' && value.length>0){
                    result += value.charAt(0);
                    value = value.substring(1, value.length);
                    selectionLength--;
                }else{
                    result += this.input_mask.charAt(i);
                }
            }
            if(result == this.input_mask){
                result = "";
                cursor = 0;
            }
            this.$nextTick(()=>{
                this.$refs.input.$el.getElementsByTagName('input')[0].setSelectionRange(cursor,cursor);
            })
            if(this.uppercase){
                result = result.toUpperCase();
            }
            return result;
        },
        onInput(event){
            var maxLength = this.input_mask.length;
            event.target.value = event.target.value.substring(0, Math.min(event.target.value.length, maxLength));
            if(event.inputType == "insertFromPaste"){
                event.target.value = this.applyMask(this.unmaskedValue(event.target.value));
                this.input_value = event.target.value;
                this.$emit('input', this.unmaskedValue(this.input_value));
            }
            this.$forceUpdate();
        },
        unmaskedValue(v){
            if(v== this.input_mask || v=="" || v==null){
                return null;
            }
            var value = `${v.replace(/[^a-zA-Z0-9]/gm, '')}`
            return value;
        },
    }

}
</script>