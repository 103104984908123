<template>
  <div>
    <v-card class="pl-3 pr-3 mt-3">
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-tabs v-model="timeframe_tab" class="table_tabs ml-3 mb-3 mt-3">
            <v-tab>1 Day</v-tab>
            <v-tab>1 Hour</v-tab>
            <v-tab>5 Minutes</v-tab>
          </v-tabs>
          <span class="mr-2" style="width: 60px; display: block; float: right"
            ><v-select v-model="limit" :items="[10, 20, 100]"
          /></span>
        </v-col>
        <v-row class="ma-0 pa-0" v-if="media_summary_data.length">
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="pgie_labels"
              :datasets="total_detected_objects"
              :timeframe="timeframe"
              title="Total Detected Objects"
            />
          </v-col>
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="inferred_media"
              :timeframe="timeframe"
              title="Inferred Media"
            />
          </v-col>
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="pgie_labels"
              :datasets="detected_objects_per_media"
              :timeframe="timeframe"
              title="Detected Objects Per Media"
            />
          </v-col>
          <v-col cols="3" class="pi_col">
            <chart_pi_summary_media
              :labels="['Videos', 'Images']"
              :datasets="inferred_media_pie"
              :timeframe="timeframe"
              title="Inferred Media by Type"
            />
          </v-col>
        </v-row>

        <v-col v-else> No Media Inferred for this customer </v-col>
      </v-row>
    </v-card>
    <v-card class="pl-3 pr-3 mt-3" v-if="media_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_animals_detected"
            :timeframe="timeframe"
            title="Detected Animals"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_people_detected"
            :timeframe="timeframe"
            title="Detected People"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_vehicles_detected"
            :timeframe="timeframe"
            title="Detected Vehicles"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Animals', 'People', 'Vehicles']"
            :datasets="detected_objects_doughnut"
            :timeframe="timeframe"
            title="Detections by Type"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pl-3 pr-3 mt-3" v-if="media_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="pgie_labels"
            :datasets="mean_class_confidence"
            :timeframe="timeframe"
            title="Mean Class Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="pgie_labels"
            :datasets="std_class_confidence"
            :timeframe="timeframe"
            title="Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="pgie_labels"
            :datasets="mean_bbox_area"
            :timeframe="timeframe"
            title="Mean BBox Area"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="pgie_labels"
            :datasets="mean_bbox_ratio"
            :timeframe="timeframe"
            title="Mean BBox Ratio"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="species_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="species_labels"
            :datasets="species_count"
            :timeframe="timeframe"
            title="Species Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="species_labels"
            :datasets="species_mean_confidence"
            :timeframe="timeframe"
            title="Species Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="species_labels"
            :datasets="species_std_confidence"
            :timeframe="timeframe"
            title="Species Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="[
              'Cow',
              'Sheep',
              'Pig',
              'Goat',
              'Canine',
              'Bison',
              'Cat',
              'Donkey',
              'Elk',
              'Horse',
              'Llama',
            ]"
            :datasets="species_pie"
            :timeframe="timeframe"
            title="Detections by Species"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="activity_count"
            :timeframe="timeframe"
            title="Activity Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="activity_mean_confidence"
            :timeframe="timeframe"
            title="Activity Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="activity_std_confidence"
            :timeframe="timeframe"
            title="Activity Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="[
              'Standing/Walking',
              'Swimming',
              'Sleeping',
              'Running',
              'Lying Down',
              'Playing',
              'Digging',
              'Climbing Stairs',
            ]"
            :datasets="activity_pie"
            :timeframe="timeframe"
            title="Detections by Activity"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="age_count"
            :timeframe="timeframe"
            title="Age Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="age_mean_confidence"
            :timeframe="timeframe"
            title="Age Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="age_std_confidence"
            :timeframe="timeframe"
            title="Age Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Adult', 'Calf']"
            :datasets="age_pie"
            :timeframe="timeframe"
            title="Detections by Age"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="gender_count"
            :timeframe="timeframe"
            title="Gender Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="gender_mean_confidence"
            :timeframe="timeframe"
            title="Gender Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="gender_std_confidence"
            :timeframe="timeframe"
            title="Gender Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Cow', 'Bull']"
            :datasets="gender_pie"
            :timeframe="timeframe"
            title="Detections by Gender"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="head_count"
            :timeframe="timeframe"
            title="Head Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="head_mean_confidence"
            :timeframe="timeframe"
            title="Head Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="head_std_confidence"
            :timeframe="timeframe"
            title="Head Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Up', 'Down']"
            :datasets="head_pie"
            :timeframe="timeframe"
            title="Detections by Head"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="suckling_count"
            :timeframe="timeframe"
            title="Suckling Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="suckling_mean_confidence"
            :timeframe="timeframe"
            title="Suckling Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="activity_labels"
            :datasets="suckling_std_confidence"
            :timeframe="timeframe"
            title="Suckling Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Yes', 'No']"
            :datasets="suckling_pie"
            :timeframe="timeframe"
            title="Detections by Suckling"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="vehicle_summary_data.length">
      <v-row>
        <v-col cols="4" class="bar_col">
          <chart_summary_media
            :labels="vehicle_labels"
            :datasets="vehicle_count"
            :timeframe="timeframe"
            title="Vehicle Count"
          />
        </v-col>
        <v-col cols="4" class="bar_col">
          <chart_line_summary_media
            :labels="vehicle_labels"
            :datasets="vehicle_mean_confidence"
            :timeframe="timeframe"
            title="Vehicle Mean Confidence"
          />
        </v-col>
        <v-col cols="4" class="bar_col">
          <chart_line_summary_media
            :labels="vehicle_labels"
            :datasets="vehicle_std_confidence"
            :timeframe="timeframe"
            title="Vehicle Std Deviation Confidence"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="tag_summary_data.length">
      <v-row>
        <v-col cols="12" class="bar_col">
          <chart_summary_media
            :labels="tag_labels"
            :datasets="tag_count"
            :timeframe="timeframe"
            title="Tag Count"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="calving_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="calving_labels"
            :datasets="calving_count"
            :timeframe="timeframe"
            title="Calving Count"
            :not_stacked="true"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="calving_labels"
            :datasets="calving_mean_confidence"
            :timeframe="timeframe"
            title="Calving Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="calving_labels"
            :datasets="calving_std_confidence"
            :timeframe="timeframe"
            title="Calving Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Tail Up', 'Tail Up v2', 'Tail Down', 'Tail Down v2']"
            :datasets="calving_pie"
            :timeframe="timeframe"
            title="Detections by Calving"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="estrus_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="estrus_labels"
            :datasets="mounting_count"
            :timeframe="timeframe"
            title="Mounting Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="estrus_labels"
            :datasets="mounting_mean_confidence"
            :timeframe="timeframe"
            title="Mounting Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="estrus_labels"
            :datasets="mounting_std_confidence"
            :timeframe="timeframe"
            title="Mounting Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Mounting', 'Not Mounting']"
            :datasets="mounting_pie"
            :timeframe="timeframe"
            title="Detections by Mounting"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="estrus_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="estrus_labels"
            :datasets="chin_resting_count"
            :timeframe="timeframe"
            title="Chin Resting Count"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="estrus_labels"
            :datasets="chin_resting_mean_confidence"
            :timeframe="timeframe"
            title="Chin Resting Mean Confidence"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_line_summary_media
            :labels="estrus_labels"
            :datasets="chin_resting_std_confidence"
            :timeframe="timeframe"
            title="Chin Resting Std Deviation Confidence"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Chin Resting', 'Not Chin Resting']"
            :datasets="chin_resting_pie"
            :timeframe="timeframe"
            title="Detections by Chin Resting"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3">
      <v-row class="ma-0 pa-0" v-if="vpc_summary_data.length">
        <v-col cols="6" class="bar_col">
          <chart_summary_media
            :labels="vpc_labels"
            :datasets="inferred_vpc"
            :timeframe="timeframe"
            title="Inferred VPC Media"
          />
        </v-col>

        <v-col cols="6" class="pi_col">
          <chart_pi_summary_media
            :labels="['Videos', 'Images']"
            :datasets="inferred_vpc_pie"
            :timeframe="timeframe"
            title="Inferred VPC Media by Type"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import chart_summary_media from "./summary_media/chart_summary_media.vue";
import chart_pi_summary_media from "./summary_media/chart_pi_summary_media.vue";
import chart_doughnut_summary_media from "./summary_media/chart_doughnut_summary_media.vue";
import chart_line_summary_media from "./summary_media/chart_line_summary_media.vue";
import moment from "moment";

export default {
  components: {
    chart_summary_media,
    chart_pi_summary_media,
    chart_doughnut_summary_media,
    chart_line_summary_media,
  },
  props: {
    customer: String,
  },
  data() {
    return {
      timeframe_tab: 0,
      labels: [],
      pgie_labels: [],
      species_labels: [],
      activity_labels: [],
      calving_labels: [],
      estrus_labels: [],
      vpc_labels: [],
      vehicle_labels: [],
      limit: 10,

      total_detected_objects: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Objects",
        },
      ],
      inferred_media: [
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: [],
          backgroundColor: "#388E3C2",
          borderColor: "#fff",
          label: "Images",
        },
      ],
      inferred_media_pie: [0, 0],

      total_animals_detected: [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Detected Animals",
        },
      ],
      total_people_detected: [
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Detected People",
        },
      ],
      total_vehicles_detected: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Vehicles",
        },
      ],
      detected_objects_doughnut: [0, 0, 0],

      mean_class_confidence: [
        {
          data: [],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: [],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ],
      std_class_confidence: [
        {
          data: [],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: [],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ],
      mean_bbox_area: [
        {
          data: [],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: [],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ],
      mean_bbox_ratio: [
        {
          data: [],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: [],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ],

      species_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
      ],
      species_mean_confidence: [
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Animals",
          fill: false,
        },
      ],
      species_std_confidence: [
        {
          data: [],
          borderColor: "#42A5F5",
          label: "Animals",
          fill: false,
        },
      ],
      species_pie: [0, 0],

      activity_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Walking",
        },
      ],
      activity_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Walking",
        },
      ],
      activity_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Walking",
        },
      ],
      activity_pie: [0, 0],

      age_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
      ],
      age_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
      ],
      age_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
      ],
      age_pie: [0, 0],

      gender_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
      ],
      gender_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
      ],
      gender_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
      ],
      gender_pie: [0, 0],

      head_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Heads Up",
        },
      ],
      head_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Heads Up",
        },
      ],
      head_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Heads Up",
        },
      ],
      head_pie: [0, 0],

      suckling_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Suckling",
        },
      ],
      suckling_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Suckling",
        },
      ],
      suckling_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Suckling",
        },
      ],
      suckling_pie: [0, 0],

      calving_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Tail Up",
        },
      ],
      calving_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Tail Up",
        },
      ],
      calving_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Tail Up",
        },
      ],
      calving_pie: [0, 0],

      mounting_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Mounting",
        },
      ],
      mounting_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Mounting",
        },
      ],
      mounting_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Mounting",
        },
      ],
      mounting_pie: [0, 0],

      chin_resting_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Chin Resting",
        },
      ],
      chin_resting_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Chin Resting",
        },
      ],
      chin_resting_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Chin Resting",
        },
      ],
      chin_resting_pie: [0, 0],

      inferred_vpc: [
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: [],
          backgroundColor: "#388E3C2",
          borderColor: "#fff",
          label: "Images",
        },
      ],

      vehicle_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Vehicles",
        },
      ],
      vehicle_mean_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Vehicles",
        },
      ],
      vehicle_std_confidence: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Vehicles",
        },
      ],

      tag_count: [
        {
          data: [],
          borderColor: "#388E3C",
          label: "Unique",
          fill: false,
        },
        {
          data: [],
          borderColor: "#F57C00",
          label: "1 Char",
          fill: false,
        },
        {
          data: [],
          borderColor: "#42A5F5",
          label: "2 Char",
          fill: false,
        },
        {
          data: [],
          borderColor: "#607D8B",
          label: "3 Char",
          fill: false,
        },
        {
          data: [],
          borderColor: "#FBC02D",
          label: "4 Char",
          fill: false,
        },
        {
          data: [],
          borderColor: "#8D6E63",
          label: "5 Char",
          fill: false,
        },
        {
          data: [],
          borderColor: "#093D72",
          label: "Over 5 Char",
          fill: false,
        },
      ],

      inferred_vpc_pie: [0, 0],
    };
  },

  created() {
    this.fetch_analytics();
  },
  methods: {
    fetch_analytics() {
      this.$store.dispatch("DDB_GET_SUMMARY_MEDIA", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_PGIE", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_ACTIVITY", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_CALVING", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_ESTRUS", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_SPECIES", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_VPC", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_VEHICLE", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_TAG", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer,
      });
    },
  },
  watch: {
    timeframe() {
      this.fetch_analytics();
    },
    limit() {
      this.fetch_analytics();
    },
    customer() {
      this.fetch_analytics();
    },
    media_summary_data() {
      var labels = [];

      var inferred_media = [[], []];
      var inferred_media_pie = [0, 0];

      var already_added = false;

      for (const i in this.media_summary_data) {
        if (
          !labels.includes(
            moment(this.media_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          )
        ) {
          labels.unshift(
            moment(this.media_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          );
        } else {
          already_added = true;
        }

        let index = this.limit - labels.length;

        if (index < 0) {
          labels.pop();
          break;
        }
        if (this.media_summary_data[i].media_type == "VIDEO") {
          inferred_media[0][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[1][index] = 0;
          }

          inferred_media_pie[0] +=
            this.media_summary_data[i].num_frames_inferred;
        }
        if (this.media_summary_data[i].media_type == "IMAGE") {
          inferred_media[1][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[0][index] = 0;
          }

          inferred_media_pie[1] +=
            this.media_summary_data[i].num_frames_inferred;
        }
      }

      this.labels = labels;

      this.inferred_media = [
        {
          data: inferred_media[0],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: inferred_media[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Images",
        },
      ];

      this.inferred_media_pie = inferred_media_pie;
    },
    vpc_summary_data() {
      var labels = [];

      var inferred_vpc = [[], []];
      var inferred_vpc_pie = [0, 0];

      var already_added = false;

      for (const i in this.vpc_summary_data) {
        if (
          !labels.includes(
            moment(this.vpc_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          )
        ) {
          labels.unshift(
            moment(this.vpc_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          );
        } else {
          already_added = true;
        }

        let index = this.limit - labels.length;

        if (index < 0) {
          labels.pop();
          break;
        }
        if (this.vpc_summary_data[i].media_type == "VIDEO_CLIP") {
          inferred_vpc[0][index] = this.vpc_summary_data[i].media_count;

          if (!already_added) {
            inferred_vpc[1][index] = 0;
          }

          inferred_vpc_pie[0] += this.vpc_summary_data[i].media_count;
        }
        if (this.vpc_summary_data[i].media_type == "IMAGE") {
          inferred_vpc[1][index] = this.vpc_summary_data[i].media_count;

          if (!already_added) {
            inferred_vpc[0][index] = 0;
          }

          inferred_vpc_pie[1] += this.vpc_summary_data[i].media_count;
        }
      }

      this.vpc_labels = labels;

      this.inferred_vpc = [
        {
          data: inferred_vpc[0],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: inferred_vpc[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Images",
        },
      ];

      this.inferred_vpc_pie = inferred_vpc_pie;
    },

    pgie_summary_data() {
      var labels = [];

      var total_detected_objects = [];

      var total_animals_detected = [];
      var total_people_detected = [];
      var total_vehicles_detected = [];
      var detected_objects_doughnut = [0, 0, 0];

      var mean_class_confidence = [[], [], []];
      var std_class_confidence = [[], [], []];
      var mean_bbox_area = [[], [], []];
      var mean_bbox_ratio = [[], [], []];

      for (const i in this.pgie_summary_data) {
        labels.unshift(
          moment(this.pgie_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        total_detected_objects.unshift(
          this.pgie_summary_data[i].animal_count +
            this.pgie_summary_data[i].person_count +
            this.pgie_summary_data[i].vehicle_count
        );

        total_animals_detected.unshift(this.pgie_summary_data[i].animal_count);
        total_people_detected.unshift(this.pgie_summary_data[i].person_count);
        total_vehicles_detected.unshift(
          this.pgie_summary_data[i].vehicle_count
        );

        detected_objects_doughnut[0] += this.pgie_summary_data[i].animal_count;
        detected_objects_doughnut[1] += this.pgie_summary_data[i].person_count;
        detected_objects_doughnut[2] += this.pgie_summary_data[i].vehicle_count;

        mean_class_confidence[0].unshift(
          this.pgie_summary_data[i].animal_class_conf_mean
        );
        mean_class_confidence[1].unshift(
          this.pgie_summary_data[i].person_class_conf_mean
        );
        mean_class_confidence[2].unshift(
          this.pgie_summary_data[i].vehicle_class_conf_mean
        );

        std_class_confidence[0].unshift(
          this.pgie_summary_data[i].animal_class_conf_stddev
        );
        std_class_confidence[1].unshift(
          this.pgie_summary_data[i].person_class_conf_stddev
        );
        std_class_confidence[2].unshift(
          this.pgie_summary_data[i].vehicle_class_conf_stddev
        );

        mean_bbox_area[0].unshift(
          this.pgie_summary_data[i].animal_bbox_area_mean
        );
        mean_bbox_area[1].unshift(
          this.pgie_summary_data[i].person_bbox_area_mean
        );
        mean_bbox_area[2].unshift(
          this.pgie_summary_data[i].vehicle_bbox_area_mean
        );

        mean_bbox_ratio[0].unshift(
          this.pgie_summary_data[i].animal_bbox_ratio_mean
        );
        mean_bbox_ratio[1].unshift(
          this.pgie_summary_data[i].person_bbox_ratio_mean
        );
        mean_bbox_ratio[2].unshift(
          this.pgie_summary_data[i].vehicle_bbox_ratio_mean
        );
      }

      this.pgie_labels = labels;

      this.total_detected_objects = [
        {
          data: total_detected_objects,
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Objects",
        },
      ];

      (this.total_animals_detected = [
        {
          data: total_animals_detected,
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Animals",
        },
      ]),
        (this.total_people_detected = [
          {
            data: total_people_detected,
            backgroundColor: "#F57C00",
            borderColor: "#fff",
            label: "People",
          },
        ]),
        (this.total_vehicles_detected = [
          {
            data: total_vehicles_detected,
            backgroundColor: "#42A5F5",
            borderColor: "#fff",
            label: "Vehicles",
          },
        ]);

      this.detected_objects_doughnut = detected_objects_doughnut;

      this.mean_class_confidence = [
        {
          data: mean_class_confidence[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_class_confidence[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_class_confidence[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.std_class_confidence = [
        {
          data: std_class_confidence[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: std_class_confidence[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: std_class_confidence[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.mean_bbox_area = [
        {
          data: mean_bbox_area[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_bbox_area[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_bbox_area[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.mean_bbox_ratio = [
        {
          data: mean_bbox_ratio[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_bbox_ratio[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_bbox_ratio[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];
    },

    species_summary_data() {
      var labels = [];

      var species_count = [[], [], [], [], []];
      var species_mean_confidence = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      var species_std_confidence = [[], [], [], [], [], [], [], [], [], [], []];
      var species_pie = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (const i in this.species_summary_data) {
        labels.unshift(
          moment(this.species_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        species_count[0].unshift(this.species_summary_data[i].cow_count);
        species_count[1].unshift(this.species_summary_data[i].sheep_count);
        species_count[2].unshift(this.species_summary_data[i].pig_count);
        species_count[3].unshift(this.species_summary_data[i].goat_count);
        species_count[4].unshift(this.species_summary_data[i].canine_count);

        species_mean_confidence[0].unshift(
          this.species_summary_data[i].cow_conf_mean
        );
        species_mean_confidence[1].unshift(
          this.species_summary_data[i].sheep_conf_mean
        );
        species_mean_confidence[2].unshift(
          this.species_summary_data[i].pig_conf_mean
        );
        species_mean_confidence[3].unshift(
          this.species_summary_data[i].goat_conf_mean
        );
        species_mean_confidence[4].unshift(
          this.species_summary_data[i].canine_conf_mean
        );
        species_mean_confidence[5].unshift(
          this.species_summary_data[i].bison_conf_mean
        );
        species_mean_confidence[6].unshift(
          this.species_summary_data[i].cat_conf_mean
        );
        species_mean_confidence[7].unshift(
          this.species_summary_data[i].donkey_conf_mean
        );
        species_mean_confidence[8].unshift(
          this.species_summary_data[i].elk_conf_mean
        );
        species_mean_confidence[9].unshift(
          this.species_summary_data[i].horse_conf_mean
        );
        species_mean_confidence[10].unshift(
          this.species_summary_data[i].llama_conf_mean
        );

        species_std_confidence[0].unshift(
          this.species_summary_data[i].cow_conf_stddev
        );
        species_std_confidence[1].unshift(
          this.species_summary_data[i].sheep_conf_stddev
        );
        species_std_confidence[2].unshift(
          this.species_summary_data[i].pig_conf_stddev
        );
        species_std_confidence[3].unshift(
          this.species_summary_data[i].goat_conf_stddev
        );
        species_std_confidence[4].unshift(
          this.species_summary_data[i].canine_conf_stddev
        );
        species_std_confidence[5].unshift(
          this.species_summary_data[i].bison_conf_stddev
        );
        species_std_confidence[6].unshift(
          this.species_summary_data[i].cat_conf_stddev
        );
        species_std_confidence[7].unshift(
          this.species_summary_data[i].donkey_conf_stddev
        );
        species_std_confidence[8].unshift(
          this.species_summary_data[i].elk_conf_stddev
        );
        species_std_confidence[9].unshift(
          this.species_summary_data[i].horse_conf_stddev
        );
        species_std_confidence[10].unshift(
          this.species_summary_data[i].llama_conf_stddev
        );

        species_pie[0] += this.species_summary_data[i].cow_count;
        species_pie[1] += this.species_summary_data[i].sheep_count;
        species_pie[2] += this.species_summary_data[i].pig_count;
        species_pie[3] += this.species_summary_data[i].goat_count;
        species_pie[4] += this.species_summary_data[i].canine_count;
        species_pie[5] += this.species_summary_data[i].bison_count;
        species_pie[6] += this.species_summary_data[i].cat_count;
        species_pie[7] += this.species_summary_data[i].donkey_count;
        species_pie[8] += this.species_summary_data[i].elk_count;
        species_pie[9] += this.species_summary_data[i].horse_count;
        species_pie[10] += this.species_summary_data[i].llama_count;
      }

      this.species_labels = labels;

      this.species_count = [
        {
          data: species_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
        {
          data: species_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Sheep",
        },
        {
          data: species_count[2],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Pig",
        },
        {
          data: species_count[3],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Goat",
        },
        {
          data: species_count[4],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Canine",
        },
      ];
      this.species_mean_confidence = [
        {
          data: species_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Cow",
        },
        {
          data: species_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Sheep",
        },
        {
          data: species_mean_confidence[2],
          borderColor: "#F57C00",
          fill: false,
          label: "Pig",
        },
        {
          data: species_mean_confidence[3],
          borderColor: "#FBC02D",
          fill: false,
          label: "Goat",
        },
        {
          data: species_mean_confidence[4],
          borderColor: "#ae4773",
          fill: false,
          label: "Canine",
        },
        {
          data: species_mean_confidence[5],
          borderColor: "#093D72",
          fill: false,
          label: "Bison",
        },
        {
          data: species_mean_confidence[6],
          borderColor: "#607D8B",
          fill: false,
          label: "Cat",
        },
        {
          data: species_mean_confidence[7],
          borderColor: "#F06292",
          fill: false,
          label: "Donkey",
        },
        {
          data: species_mean_confidence[8],
          borderColor: "#8D6E63",
          fill: false,
          label: "Elk",
        },
        {
          data: species_mean_confidence[9],
          borderColor: "#EF5350",
          fill: false,
          label: "Horse",
        },
        {
          data: species_mean_confidence[10],
          borderColor: "#BDBDBD",
          fill: false,
          label: "Llama",
        },
      ];
      this.species_std_confidence = [
        {
          data: species_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Cow",
        },
        {
          data: species_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Sheep",
        },
        {
          data: species_std_confidence[2],
          borderColor: "#F57C00",
          fill: false,
          label: "Pig",
        },
        {
          data: species_std_confidence[3],
          borderColor: "#FBC02D",
          fill: false,
          label: "Goat",
        },
        {
          data: species_std_confidence[4],
          borderColor: "#ae4773",
          fill: false,
          label: "Canine",
        },
        {
          data: species_std_confidence[5],
          borderColor: "#093D72",
          fill: false,
          label: "Bison",
        },
        {
          data: species_std_confidence[6],
          borderColor: "#607D8B",
          fill: false,
          label: "Cat",
        },
        {
          data: species_std_confidence[7],
          borderColor: "#F06292",
          fill: false,
          label: "Donkey",
        },
        {
          data: species_std_confidence[8],
          borderColor: "#8D6E63",
          fill: false,
          label: "Elk",
        },
        {
          data: species_std_confidence[9],
          borderColor: "#EF5350",
          fill: false,
          label: "Horse",
        },
        {
          data: species_std_confidence[10],
          borderColor: "#BDBDBD",
          fill: false,
          label: "Llama",
        },
      ];
      this.species_pie = species_pie;
    },

    activity_summary_data() {
      var labels = [];

      // var activity_count = [[], [], [], [], [], [], [], []];
      // var activity_mean_confidence = [[], [], [], [], [], [], [], []];
      // var activity_std_confidence = [[], [], [], [], [], [], [], []];
      var activity_count = [[], []];
      var activity_mean_confidence = [[], []];
      var activity_std_confidence = [[], []];
      var activity_pie = [0, 0, 0, 0, 0, 0, 0, 0, 0];

      var age_count = [[], []];
      var age_mean_confidence = [[], []];
      var age_std_confidence = [[], []];
      var age_pie = [0, 0];

      var gender_count = [[], []];
      var gender_mean_confidence = [[], []];
      var gender_std_confidence = [[], []];
      var gender_pie = [0, 0];

      var head_count = [[], []];
      var head_mean_confidence = [[], []];
      var head_std_confidence = [[], []];
      var head_pie = [0, 0];

      var suckling_count = [[], []];
      var suckling_mean_confidence = [[], []];
      var suckling_std_confidence = [[], []];
      var suckling_pie = [0, 0];

      for (const i in this.activity_summary_data) {
        labels.unshift(
          moment(this.activity_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        activity_count[0].unshift(
          this.activity_summary_data[i].standing_walking_count
        );
        activity_count[1].unshift(
          this.activity_summary_data[i].lying_down_count
        );

        activity_mean_confidence[0].unshift(
          this.activity_summary_data[i].standing_walking_conf_mean
        );
        activity_mean_confidence[1].unshift(
          this.activity_summary_data[i].lying_down_conf_mean
        );

        activity_std_confidence[0].unshift(
          this.activity_summary_data[i].standing_walking_conf_stddev
        );
        activity_std_confidence[1].unshift(
          this.activity_summary_data[i].lying_down_conf_stddev
        );

        activity_pie[0] += this.activity_summary_data[i].standing_walking_count;
        activity_pie[1] += this.activity_summary_data[i].swimming_count;
        activity_pie[2] += this.activity_summary_data[i].sleeping_count;
        activity_pie[3] += this.activity_summary_data[i].running_count;
        activity_pie[4] += this.activity_summary_data[i].lying_down_count;
        activity_pie[5] += this.activity_summary_data[i].playing_count;
        activity_pie[6] += this.activity_summary_data[i].digging_count;
        activity_pie[7] += this.activity_summary_data[i].climbing_stairs_count;

        age_count[0].unshift(this.activity_summary_data[i].adult_count);
        age_count[1].unshift(this.activity_summary_data[i].calf_count);

        age_mean_confidence[0].unshift(
          this.activity_summary_data[i].adult_conf_mean
        );
        age_mean_confidence[1].unshift(
          this.activity_summary_data[i].calf_conf_mean
        );

        age_std_confidence[0].unshift(
          this.activity_summary_data[i].adult_conf_stddev
        );
        age_std_confidence[1].unshift(
          this.activity_summary_data[i].calf_conf_stddev
        );

        age_pie[0] += this.activity_summary_data[i].adult_count;
        age_pie[1] += this.activity_summary_data[i].calf_count;

        gender_count[0].unshift(this.activity_summary_data[i].cow_count);
        gender_count[1].unshift(this.activity_summary_data[i].bull_count);

        gender_mean_confidence[0].unshift(
          this.activity_summary_data[i].cow_conf_mean
        );
        gender_mean_confidence[1].unshift(
          this.activity_summary_data[i].bull_conf_mean
        );

        gender_std_confidence[0].unshift(
          this.activity_summary_data[i].cow_conf_stddev
        );
        gender_std_confidence[1].unshift(
          this.activity_summary_data[i].bull_conf_stddev
        );

        gender_pie[0] += this.activity_summary_data[i].cow_count;
        gender_pie[1] += this.activity_summary_data[i].bull_count;

        head_count[0].unshift(this.activity_summary_data[i].head_up_count);
        head_count[1].unshift(this.activity_summary_data[i].head_down_count);

        head_mean_confidence[0].unshift(
          this.activity_summary_data[i].head_up_conf_mean
        );
        head_mean_confidence[1].unshift(
          this.activity_summary_data[i].head_down_conf_mean
        );

        head_std_confidence[0].unshift(
          this.activity_summary_data[i].head_up_conf_stddev
        );
        head_std_confidence[1].unshift(
          this.activity_summary_data[i].head_down_conf_stddev
        );

        head_pie[0] += this.activity_summary_data[i].head_up_count;
        head_pie[1] += this.activity_summary_data[i].head_down_count;

        suckling_count[0].unshift(this.activity_summary_data[i].suckling_count);
        suckling_count[1].unshift(
          this.activity_summary_data[i].not_suckling_count
        );

        suckling_mean_confidence[0].unshift(
          this.activity_summary_data[i].suckling_conf_mean
        );
        suckling_mean_confidence[1].unshift(
          this.activity_summary_data[i].not_suckling_conf_mean
        );

        suckling_std_confidence[0].unshift(
          this.activity_summary_data[i].suckling_conf_stddev
        );
        suckling_std_confidence[1].unshift(
          this.activity_summary_data[i].not_suckling_conf_stddev
        );

        suckling_pie[0] += this.activity_summary_data[i].suckling_count;
        suckling_pie[1] += this.activity_summary_data[i].not_suckling_count;
      }

      this.activity_labels = labels;

      this.activity_count = [
        {
          data: activity_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Standing/Walking",
        },
        {
          data: activity_count[1],
          backgroundColor: "#093D72",
          borderColor: "#fff",
          label: "Lying Down",
        },
      ];
      this.activity_mean_confidence = [
        {
          data: activity_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Standing/Walking",
        },
        {
          data: activity_mean_confidence[1],
          borderColor: "#093D72",
          fill: false,
          label: "Lying Down",
        },
      ];
      this.activity_std_confidence = [
        {
          data: activity_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Standing/Walking",
        },
        {
          data: activity_std_confidence[1],
          borderColor: "#093D72",
          fill: false,
          label: "Lying Down",
        },
      ];
      this.activity_pie = activity_pie;

      this.age_count = [
        {
          data: age_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
        {
          data: age_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Calf",
        },
      ];
      this.age_mean_confidence = [
        {
          data: age_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Adult",
        },
        {
          data: age_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Calf",
        },
      ];
      this.age_std_confidence = [
        {
          data: age_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Adult",
        },
        {
          data: age_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Calf",
        },
      ];
      this.age_pie = age_pie;

      this.gender_count = [
        {
          data: gender_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Cow",
        },
        {
          data: gender_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Bull",
        },
      ];
      this.gender_mean_confidence = [
        {
          data: gender_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Cow",
        },
        {
          data: gender_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Bull",
        },
      ];
      this.gender_std_confidence = [
        {
          data: gender_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Cow",
        },
        {
          data: gender_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Bull",
        },
      ];
      this.gender_pie = gender_pie;

      this.head_count = [
        {
          data: head_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Head Up",
        },
        {
          data: head_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Head Down",
        },
      ];
      this.head_mean_confidence = [
        {
          data: head_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Head Up",
        },
        {
          data: head_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Head Down",
        },
      ];
      this.head_std_confidence = [
        {
          data: head_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Head Up",
        },
        {
          data: head_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Head Down",
        },
      ];
      this.head_pie = head_pie;

      this.suckling_count = [
        {
          data: suckling_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Suckling",
        },
        {
          data: suckling_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Not Suckling",
        },
      ];
      this.suckling_mean_confidence = [
        {
          data: suckling_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Suckling",
        },
        {
          data: suckling_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Suckling",
        },
      ];
      this.suckling_std_confidence = [
        {
          data: suckling_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Suckling",
        },
        {
          data: suckling_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Suckling",
        },
      ];
      this.suckling_pie = suckling_pie;
    },

    calving_summary_data() {
      var labels = [];

      var calving_count = [[], [], [], []];
      var calving_mean_confidence = [[], [], [], []];
      var calving_std_confidence = [[], [], [], []];
      var calving_pie = [0, 0, 0, 0];

      for (const i in this.calving_summary_data) {
        labels.unshift(
          moment(this.calving_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        calving_count[0].unshift(this.calving_summary_data[i].tail_up_count);
        calving_count[1].unshift(this.calving_summary_data[i].tail_up2_count);
        calving_count[2].unshift(this.calving_summary_data[i].tail_down_count);
        calving_count[3].unshift(this.calving_summary_data[i].tail_down2_count);

        calving_mean_confidence[0].unshift(
          this.calving_summary_data[i].tail_up_conf_mean
        );
        calving_mean_confidence[1].unshift(
          this.calving_summary_data[i].tail_up2_conf_mean
        );
        calving_mean_confidence[2].unshift(
          this.calving_summary_data[i].tail_down_conf_mean
        );
        calving_mean_confidence[3].unshift(
          this.calving_summary_data[i].tail_down2_conf_mean
        );

        calving_std_confidence[0].unshift(
          this.calving_summary_data[i].tail_up_conf_stddev
        );
        calving_std_confidence[1].unshift(
          this.calving_summary_data[i].tail_up2_conf_stddev
        );
        calving_std_confidence[2].unshift(
          this.calving_summary_data[i].tail_down_conf_stddev
        );
        calving_std_confidence[3].unshift(
          this.calving_summary_data[i].tail_down2_conf_stddev
        );

        calving_pie[0] += this.calving_summary_data[i].tail_up_count;
        calving_pie[1] += this.calving_summary_data[i].tail_up2_count;
        calving_pie[2] += this.calving_summary_data[i].tail_down_count;
        calving_pie[3] += this.calving_summary_data[i].tail_down2_count;
      }

      this.calving_labels = labels;

      this.calving_count = [
        {
          data: calving_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Tail Up",
        },
        {
          data: calving_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Tail Up v2",
        },
        {
          data: calving_count[2],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Tail Down",
        },
        {
          data: calving_count[3],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Tail Down v2",
        },
      ];
      this.calving_mean_confidence = [
        {
          data: calving_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Tail Up",
        },
        {
          data: calving_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Tail Up v2",
        },
        {
          data: calving_mean_confidence[2],
          borderColor: "#F57C00",
          fill: false,
          label: "Tail Down",
        },
        {
          data: calving_mean_confidence[3],
          borderColor: "#FBC02D",
          fill: false,
          label: "Tail Down v2",
        },
      ];
      this.calving_std_confidence = [
        {
          data: calving_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Tail Up",
        },
        {
          data: calving_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Tail Up v2",
        },
        {
          data: calving_std_confidence[2],
          borderColor: "#F57C00",
          fill: false,
          label: "Tail Down",
        },
        {
          data: calving_std_confidence[3],
          borderColor: "#FBC02D",
          fill: false,
          label: "Tail Down v2",
        },
      ];
      this.calving_pie = calving_pie;
    },

    estrus_summary_data() {
      var labels = [];

      var mounting_count = [[], []];
      var mounting_mean_confidence = [[], []];
      var mounting_std_confidence = [[], []];
      var mounting_pie = [0, 0];

      var chin_resting_count = [[], []];
      var chin_resting_mean_confidence = [[], []];
      var chin_resting_std_confidence = [[], []];
      var chin_resting_pie = [0, 0];

      for (const i in this.estrus_summary_data) {
        labels.unshift(
          moment(this.estrus_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        mounting_count[0].unshift(this.estrus_summary_data[i].mounting_count);
        mounting_count[1].unshift(
          this.estrus_summary_data[i].not_mounting_count
        );

        mounting_mean_confidence[0].unshift(
          this.estrus_summary_data[i].mounting_conf_mean
        );
        mounting_mean_confidence[1].unshift(
          this.estrus_summary_data[i].not_mounting_conf_mean
        );

        mounting_std_confidence[0].unshift(
          this.estrus_summary_data[i].mounting_conf_stddev
        );
        mounting_std_confidence[1].unshift(
          this.estrus_summary_data[i].not_mounting_conf_stddev
        );

        mounting_pie[0] += this.estrus_summary_data[i].mounting_count;
        mounting_pie[1] += this.estrus_summary_data[i].not_mounting_count;

        chin_resting_count[0].unshift(
          this.estrus_summary_data[i].resting_chin_count
        );
        chin_resting_count[1].unshift(
          this.estrus_summary_data[i].not_resting_chin_count
        );

        chin_resting_mean_confidence[0].unshift(
          this.estrus_summary_data[i].resting_chin_conf_mean
        );
        chin_resting_mean_confidence[1].unshift(
          this.estrus_summary_data[i].not_resting_chin_conf_mean
        );

        chin_resting_std_confidence[0].unshift(
          this.estrus_summary_data[i].resting_chin_conf_stddev
        );
        chin_resting_std_confidence[1].unshift(
          this.estrus_summary_data[i].not_resting_chin_conf_stddev
        );

        chin_resting_pie[0] += this.estrus_summary_data[i].resting_chin_count;
        chin_resting_pie[1] +=
          this.estrus_summary_data[i].not_resting_chin_count;
      }

      this.estrus_labels = labels;

      this.mounting_count = [
        {
          data: mounting_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Mounting",
        },
        {
          data: mounting_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Not Mounting",
        },
      ];
      this.mounting_mean_confidence = [
        {
          data: mounting_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Mounting",
        },
        {
          data: mounting_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Mounting",
        },
      ];
      this.mounting_std_confidence = [
        {
          data: mounting_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Mounting",
        },
        {
          data: mounting_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Mounting",
        },
      ];
      this.mounting_pie = mounting_pie;

      this.chin_resting_count = [
        {
          data: chin_resting_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Chin Resting",
        },
        {
          data: chin_resting_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Not Chin Resting",
        },
      ];
      this.chin_resting_mean_confidence = [
        {
          data: chin_resting_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Chin Resting",
        },
        {
          data: chin_resting_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Chin Resting",
        },
      ];
      this.chin_resting_std_confidence = [
        {
          data: chin_resting_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Chin Resting",
        },
        {
          data: chin_resting_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Not Chin Resting",
        },
      ];
      this.chin_resting_pie = chin_resting_pie;
    },

    vehicle_summary_data() {
      var labels = [];

      var vehicle_count = [[]];
      var vehicle_mean_confidence = [[]];
      var vehicle_std_confidence = [[]];

      for (const i in this.vehicle_summary_data) {
        labels.unshift(
          moment(this.vehicle_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        vehicle_count[0].unshift(
          this.vehicle_summary_data[i].license_plate_count
        );

        vehicle_mean_confidence[0].unshift(
          this.vehicle_summary_data[i].license_plate_class_conf_mean
        );

        vehicle_std_confidence[0].unshift(
          this.vehicle_summary_data[i].license_plate_class_conf_stddev
        );
      }

      this.vehicle_labels = labels;

      this.vehicle_count = [
        {
          data: vehicle_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Vehicles",
        },
      ];
      this.vehicle_mean_confidence = [
        {
          data: vehicle_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Vehicles",
        },
      ];
      this.vehicle_std_confidence = [
        {
          data: vehicle_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Vehicles",
        },
      ];
    },

    tag_summary_data() {
      var labels = [];

      var tag_count = [[], [], [], [], [], [], []];

      for (const i in this.tag_summary_data) {
        labels.unshift(
          moment(this.tag_summary_data[i].media_timestamp).format("MM-DD HH:mm")
        );

        tag_count[0].unshift(this.tag_summary_data[i].unique_tag_count);
        tag_count[1].unshift(this.tag_summary_data[i].tag_count_1_char);
        tag_count[2].unshift(this.tag_summary_data[i].tag_count_2_chars);
        tag_count[3].unshift(this.tag_summary_data[i].tag_count_3_chars);
        tag_count[4].unshift(this.tag_summary_data[i].tag_count_4_chars);
        tag_count[5].unshift(this.tag_summary_data[i].tag_count_5_chars);
        tag_count[6].unshift(this.tag_summary_data[i].tag_count_over_5_chars);
      }

      this.tag_labels = labels;

      this.tag_count = [
        {
          data: tag_count[0],
          backgroundColor: "#388E3C",
          label: "Unique",
          borderColor: "#fff",
        },
        {
          data: tag_count[1],
          backgroundColor: "#F57C00",
          label: "1 Char",
          borderColor: "#fff",
        },
        {
          data: tag_count[2],
          backgroundColor: "#42A5F5",
          label: "2 Char",
          borderColor: "#fff",
        },
        {
          data: tag_count[3],
          backgroundColor: "#607D8B",
          label: "3 Char",
          borderColor: "#fff",
        },
        {
          data: tag_count[4],
          backgroundColor: "#FBC02D",
          label: "4 Char",
          borderColor: "#fff",
        },
        {
          data: tag_count[5],
          backgroundColor: "#8D6E63",
          label: "5 Char",
          borderColor: "#fff",
        },
        {
          data: tag_count[6],
          backgroundColor: "#093D72",
          label: "Over 5 Char",
          borderColor: "#fff",
        },
      ];
    },
  },
  computed: {
    timeframe() {
      if (this.timeframe_tab == 0) {
        return "1day";
      } else if (this.timeframe_tab == 1) {
        return "1hour";
      }
      if (this.timeframe_tab == 2) {
        return "5min";
      }
      return "1day";
    },
    media_summary_data() {
      return this.$store.getters.getSummaryMedia;
    },
    pgie_summary_data() {
      return this.$store.getters.getSummaryPGIE;
    },
    activity_summary_data() {
      return this.$store.getters.getSummaryActivity;
    },
    calving_summary_data() {
      return this.$store.getters.getSummaryCalving;
    },
    estrus_summary_data() {
      return this.$store.getters.getSummaryEstrus;
    },
    species_summary_data() {
      return this.$store.getters.getSummarySpecies;
    },
    vpc_summary_data() {
      return this.$store.getters.getSummaryVPC;
    },
    vehicle_summary_data() {
      return this.$store.getters.getSummaryVehicle;
    },
    tag_summary_data() {
      return this.$store.getters.getSummaryTag;
    },

    detected_objects_per_media() {
      var objects_per_media = [];
      if (
        this.total_detected_objects[0].data.length &&
        this.inferred_media[0].data.length
      ) {
        for (const i in this.total_detected_objects[0].data) {
          objects_per_media[i] =
            this.total_detected_objects[0].data[i] /
            (this.inferred_media[0].data[i] + this.inferred_media[1].data[i]);
        }
      }

      return [
        {
          data: objects_per_media,
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Objects",
        },
      ];
    },
  },
};
</script>
<style scoped>
.pi_col > div {
  height: calc(100% - 14px);
  position: relative;
}
.bar_col > div {
  height: 300px;
  position: relative;
}
</style>