import { render, staticRenderFns } from "./grafana_beta.vue?vue&type=template&id=52126460&scoped=true"
import script from "./grafana_beta.vue?vue&type=script&lang=js"
export * from "./grafana_beta.vue?vue&type=script&lang=js"
import style0 from "./grafana_beta.vue?vue&type=style&index=0&id=52126460&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52126460",
  null
  
)

export default component.exports