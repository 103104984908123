<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Wi-Fi</span>
              <v-spacer></v-spacer>
              <v-switch
                class="mt-0 mr-6 mb-0"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                hide-details
              ></v-switch>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Wi-Fi</span>
              </v-tooltip>
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn class="mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn>
              <div style="width: 180px" class="mt-n4 pr-3">
                <v-select
                  :items="[
                    { text: 'None', value: null },
                    { text: 'Customer', value: 'customer.last_name' },
                    { text: 'Model', value: 'model' },
                    { text: 'Shipped', value: 'shipped' },
                    { text: 'Status', value: 'status' },
                    { text: 'Type', value: 'device_type' },
                    { text: 'Manufacturer', value: 'manufacturer' },
                  ]"
                  v-model="group_by"
                  flat
                  hide-details
                ></v-select>
              </div>
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                @change="table_key++"
                :disabled="!!editable"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="displayed_headers"
                :items-per-page="100"
                class="elevation-1 entry_table"
                :multi-sort="multi_sort"
                :group-by="group_by"
                hide-default-footer
                :sort-by="sort_by"
                :sort-desc="sort_desc"
                single-expand
                show-expand
                :expanded.sync="expanded"
                :key="table_key"
                :item-class="item_class"
                :items="wifi"
                :search="search"
              >
                <template v-slot:expanded-item="{ headers }">
                  <td :colspan="headers.length" class="px-0" v-if="expanded[0]">
                    <v-data-table
                      :loading="note_loading"
                      loading-text="Loading... Please wait"
                      :headers="headers_notes"
                      :items="selected_notes"
                      sort-by="datetime"
                      :sort-desc="true"
                      :items-per-page="5"
                      style="border-radius: 0"
                      class="elevation-1 expanded_row"
                      hide-default-footer
                      dense
                    >
                      <template
                        v-slot:top="{ pagination, options, updateOptions }"
                      >
                        <div class="d-flex justify-end">
                          <div class="table_notes_title mt-2">
                            <h3>NOTES</h3>
                          </div>
                          <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :items-per-page-options="[5]"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          />
                          <!-- class="table_pagination_notes" -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                @click="open_note"
                                color="primary"
                                >mdi-note-plus-outline</v-icon
                              >
                            </template>
                            <span>Add Note</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-slot:[`item.datetime`]="{ item }">
                        {{ item.datetime.split("T")[0] }}
                      </template>
                      <template v-slot:[`item.author`]="{ item }">
                        <span>{{ item.author }}</span>
                      </template>
                      <template v-slot:[`item.comment`]="{ item }">
                        <v-edit-dialog
                          @save="save_notes(item)"
                          @cancel="edit_value_note = null"
                          large
                          @open="edit_value_note = item.comment"
                        >
                          {{ item.comment }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="edit_value_note"
                              label="Note"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden" v-if="selected_table == 0">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      v-if="group_by != 'manufacturer'"
                      class="select_width align_left"
                    >
                      <v-select
                        class="mt-n1"
                        :items="['All', ...$store.getters.getWifiManufacturers]"
                        v-model="selected_manufacturer"
                        dense
                        flat
                        hide-details
                      ></v-select>
                    </td>
                    <td v-if="group_by != 'device_type'"></td>
                    <td
                      v-if="group_by != 'status'"
                      class="select_width align_left"
                    >
                      <v-select
                        class="mt-n1"
                        :items="['All', ...$store.getters.getWifiOnlineStatus]"
                        v-model="status"
                        dense
                        flat
                        hide-details
                      ></v-select>
                    </td>

                    <td
                      v-if="group_by != 'model'"
                      class="select_width align_left"
                    >
                      <v-select
                        class="mt-n1"
                        :items="models"
                        v-model="selected_model"
                        v-if="models"
                        dense
                        flat
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>
                  </tr>
                  <tr class="mb_hidden" v-if="selected_table == 1">
                    <td></td>
                    <td v-if="group_by != 'device_type'"></td>
                    <td
                      v-if="group_by != 'shipped'"
                      class="select_width align_left"
                    >
                      <v-select
                        class="mt-n1"
                        :items="[
                          'All',
                          ...$store.getters.getWifiShippingStatus,
                        ]"
                        v-model="shipped"
                        dense
                        flat
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Shipping</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.inventory_number`]="{ item }">
                  <span>{{
                    item.inventory_number ? item.inventory_number : "-"
                  }}</span>
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                >
                  <div style="display: flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="handleExpansion(item, isExpanded)"
                            dense
                            :class="'mr-5'"
                            :color="hover || isExpanded ? 'primary' : 'gray'"
                            >{{
                              isExpanded
                                ? "mdi-note-off-outline"
                                : "mdi-note-outline"
                            }}</v-icon
                          >
                        </v-hover>
                      </template>
                      <span>Notes</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                            dense
                            :color="hover ? 'primary' : 'gray'"
                            >mdi-pencil</v-icon
                          >
                        </v-hover>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.site.site_name`]="{ item }">
                  <span v-if="item.site.site_name.includes(' - ')">{{
                    item.site.site_name.split([" - "])[1]
                  }}</span>
                  <span v-else>{{ item.site.site_name }}</span>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <span v-if="item.updated_at">{{
                    item.updated_at.split("T")[0] +
                    " " +
                    item.updated_at.split("T")[1].split(".")[0] +
                    " UTC"
                  }}</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>

                <template v-slot:[`item.customer`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'customer_id')"
                    @cancel="cancel_edit"
                    @open="open_field(item.customer_id)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.customer_id"
                  >
                    <span v-if="item.customer">{{
                      item.customer.first_name + " " + item.customer.last_name
                    }}</span>
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="edit_value"
                        :items="customer_names"
                        label="Customer"
                        dense
                        outlined
                        class="pt-2"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.manufacturer`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'manufacturer')"
                    @cancel="cancel_edit"
                    @open="open_field(item.manufacturer)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.manufacturer"
                  >
                    {{ item.manufacturer }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="$store.getters.getWifiManufacturers"
                        label="Manufacturer"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.device_type`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'device_type')"
                    @cancel="cancel_edit"
                    @open="open_field(item.device_type)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.device_type"
                  >
                    {{ item.device_type }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="['NODE', 'ROUTER']"
                        label="Type"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'status')"
                    @cancel="cancel_edit"
                    @open="open_field(item.status)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.status"
                  >
                    <span v-if="item.status == 'ONLINE'" class="green_color">{{
                      item.status
                    }}</span>
                    <span v-if="item.status == 'OFFLINE'" class="gray_color">{{
                      item.status
                    }}</span>
                    <span
                      v-if="item.status == 'WARNING'"
                      class="orange_color"
                      >{{ item.status }}</span
                    >
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="$store.getters.getWifiOnlineStatus"
                        label="Status"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.shipped`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'shipped')"
                    @cancel="cancel_edit"
                    @open="open_field(item.shipped)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.shipped"
                  >
                    {{ item.shipped }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="$store.getters.getWifiShippingStatus"
                        label="Shipping"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.model`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'model')"
                    @cancel="cancel_edit"
                    @open="open_field(item.model)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.model"
                  >
                    {{ item.model }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="models"
                        label="Model"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>

      <note-card></note-card>
    </v-container>
    <wifi-card> </wifi-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import WifiCard from "@/components/WifiCard.vue";

import moment from "moment";
import Vue from "vue";
import { eventBus } from "../main";
import CustomerFilter from "../components/CustomerFilter.vue";
import NoteCard from "../components/NoteCard.vue";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";
// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    WifiCard,
    CustomerFilter,
    NoteCard,
  },
  props: ["filter"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
      },
      multi_sort: false,
      group_by: null,
      staging_production: true,
      loading: false,
      editedItem: {},
      dialog: false,
      error: "",
      create_new: false,
      owner_name: "",
      site_name: "",
      selected_site: "All",
      selected_customer: "All Customers",
      selected_manufacturer: "All",
      selected_model: "All",
      selected_table: 0,
      sort_by: ["updated_at"],
      sort_desc: [true],
      delete_dialog: false,
      status_list: ["All", "ONLINE", "WARNING", "OFFLINE"],
      status: "All",
      shipped: "All",
      edit_value: null,
      expanded: [],
      edit_value_note: null,
      selected_notes: [],
      editable: false,
      note_dialog: false,
      note_text: "",
      table_key: 0,
      note_loading: false,

      search: "",
      search_old: "",
      wifi_list: [],
      wifi_num: 0,
      wifi_pages: 0,
      options: {},
    };
  },
  methods: {
    refresh() {
      this.fetch_wifis();
    },
    fetch_wifis() {
      this.loading = true;
      this.$store.dispatch("DDB_GET_WIFI").then(() => {
        this.loading = false;
      });
    },

    handleExpansion(item, state) {
      this.expanded = [];
      if (!state) {
        this.expanded.push(item);
      }
    },
    item_class(item) {
      return this.expanded[0]?.id == item.id ? "expanded_row" : "";
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (x == null) {
          x = "zzz";
        }
        if (y == null) {
          y = "zzz";
        }
        if (key == "camera_latest_upload_source") {
          if (x == y) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    open_note() {
      eventBus.$emit("open_dialog_note");
    },
    async add_note(note) {
      this.note_loading = true;
      if (!this.expanded[0]) {
        return false;
      }
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.insert_admin_note_one,
          variables: {
            object1: {
              datetime: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
              id: uuidv4(),

              comment: note,
              wifi_id: this.expanded[0].id,
              customer_id: this.expanded[0].customer_id,
              author: this.$store.getters.getUser.username,
            },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "wifi",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
            this.note_loading = false;
          });
      });
    },
    async save_notes(note) {
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.update_admin_note_by_pk,
          variables: {
            pk_columns: { id: note.id },
            _set: {comment: this.edit_value_note},
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      var _this = this
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: _this.expanded[0].id,
            type: "wifi",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
          });
      });
    },
    close_edit() {
      //this.edit_value = null;
    },
    async save_record(record, field) {
      this.loading = true;
      var edited_record = {};

      edited_record[field] = this.edit_value;
      try {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_wifi_by_pk,
            variables: {
              pk_columns: { id: record.id },
              _set: edited_record,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        console.log(response)
        if (response.data.errors) {
          throw new Error("GraphQL error: " + response.data.errors[0].message);
        }
        this.edit_value = null;

        this.fetch_wifis();
      } catch (err) {
        console.log(err);
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value) {
      this.edit_value = value;
    },

    editItem(item) {
      if (!this.editable) {
        if (item == "create_new") {
          eventBus.$emit("open_dialog_wifi", {
            item: {},
            create_new: true,
          });
        } else {
          this.editedItem = Object.assign({}, item);
          this.owner_name = this.editedItem.customer_id;
          this.site_name = this.editedItem.site_id;
          this.dialog = true;
          eventBus.$emit("open_dialog_wifi", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: this.editedItem.customer_id,
            site_name: this.editedItem.site_id,
          });
        }
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },

    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer = this.filter;
      }
    },
  },
  computed: {
    displayed_headers() {
      if (this.selected_table == 0) {
        return this.headers_general;
      } else if (this.selected_table == 1) {
        return this.headers_shipping;
      } else {
        return this.headers_general;
      }
    },
    displayed_fields() {
      return this.fields;
      // if (this.selected_table == 0) {
      //   return this.fields_general;
      // } else if (this.selected_table == 1) {
      //   return this.fields_shipping;
      // } else {
      //   return this.fields_general;
      // }
    },
    fields() {
      return [
        "manufacturer",
        "device_type",
        "status",
        "model",
        "inventory_number",
        "shipped",
      ];
    },
    headers_notes() {
      return [
        { text: "#", value: "index", sortable: false },
        {
          text: "Date",
          value: "datetime",
          width: "120px",
        },
        {
          text: "Author",
          value: "author",
          width: "420px",
        },
        {
          text: "Note",
          value: "comment",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "customer.first_name", align: " d-none" },
        { text: "", value: "customer.last_name", align: " d-none" },
      ];
    },
    headers_general() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,
          filter: (value) => {
            if (this.selected_customer == "All Customers" || !value)
              return true;
            return value.id == this.selected_customer;
          },
          sort: (a, b) => {
            if ((!a && !!b) || a?.first_name < b?.first_name) {
              return -1;
            }
            if ((!!a && !b) || a?.first_name > b?.first_name) {
              return 1;
            }
            return 0;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "250px",
        },

        { text: "Updated At", value: "updated_at", width: "250px" },
        {
          text: "Manufacturer",
          value: "manufacturer",

          filter: (value) => {
            if (this.selected_manufacturer == "All") return true;
            return value == this.selected_manufacturer;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Type",
          value: "device_type",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Status",
          value: "status",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
          filter: (value) => {
            if (this.status == "All") return true;
            return value == this.status;
          },
        },

        {
          text: "Model",
          value: "model",
          // sortable: false,
          filter: (value) => {
            if (this.selected_model == "All") return true;
            return value == this.selected_model;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        { text: "", value: "data-table-expand" },
        { text: "", value: "customer.first_name", align: " d-none" },
        { text: "", value: "customer.last_name", align: " d-none" },
      ];
    },
    fields_general() {
      return ["manufacturer", "device_type", "status", "model"];
    },
    headers_shipping() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,
          filter: (value) => {
            if (this.selected_customer == "All Customers" || !value)
              return true;
            return value.id == this.selected_customer;
          },
          sort: (a, b) => {
            if ((!a && !!b) || a?.first_name < b?.first_name) {
              return -1;
            }
            if ((!!a && !b) || a?.first_name > b?.first_name) {
              return 1;
            }
            return 0;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "250px",
        },
        {
          text: "Shipping Status",
          value: "shipped",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
          filter: (value) => {
            if (this.shipped == "All") return true;
            return value == this.shipped;
          },
        },
        { text: "Inventory No.", value: "inventory_number" },
        { text: "", value: "data-table-expand" },
        { text: "", value: "customer.first_name", align: " d-none" },
        { text: "", value: "customer.last_name", align: " d-none" },
      ];
    },
    fields_shipping() {
      return ["inventory_number", "shipped"];
    },
    site_names() {
      if (this.$store.getters.getCustomerNames[1]) {
        return this.$store.getters.getCustomerNames[1][this.owner_name];
      } else {
        return [];
      }
    },
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return {
                text: item.first_name + " " + item.last_name,
                value: item.id,
              };
            } catch {
              return "null";
            }
          })
        ),
      ];

      customers = this.sortByKey([...customers], "text");
      customers.unshift({ text: "All", value: "All" });

      return customers;
    },
    upswift_group() {
      var upswift_group = [
        ...new Set(this.wifi.map((item) => item.jetson_upswift_group)),
      ];
      upswift_group.unshift("All");

      return upswift_group;
    },
    manufacturers() {
      var upswift_group = [
        ...new Set(this.wifi.map((item) => item.manufacturer)),
      ];
      upswift_group.unshift("All");

      return upswift_group;
    },
    models() {
      var upswift_group = this.$store.getters.getWifiModels;
      upswift_group.sort();
      upswift_group.unshift("All");

      return upswift_group;
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },
    wifi() {
      return this.$store.getters.getWifiObjects;
    },
  },
  created() {
    if (this.$store.getters.getWifiObjects.length == 0) {
      this.fetch_wifis();
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(this.owner_filter);
    } else {
      this.owner_filter();
    }
    if (this.$store.getters.getSiteNames.length == 0) {
      this.$store.dispatch("DDB_GET_SITES");
    }
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }

    eventBus.$on("add_note", (note) => {
      this.add_note(note);
    });
    eventBus.$on("updateWifis", () => {
      this.fetch_wifis();
    });
  },
  mounted() {
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.selected_customer = Vue.prototype.$selectCustomer;
    }
  },
  watch: {
    expanded() {
      if (this.expanded[0]) {
        this.selected_notes = [];
        this.note_loading = true;
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "wifi",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
              this.note_loading = false;
            }
          });
      }
    },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
</style>