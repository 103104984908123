import { render, staticRenderFns } from "./VideosHeat.vue?vue&type=template&id=202061f1&scoped=true"
import script from "./VideosHeat.vue?vue&type=script&lang=js"
export * from "./VideosHeat.vue?vue&type=script&lang=js"
import style0 from "./VideosHeat.vue?vue&type=style&index=0&id=202061f1&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202061f1",
  null
  
)

export default component.exports