<template>
  <!-- v-for="(p, index) in image_paths" -->
  <div
    class="pa-0 grid_container_f"
    :class="age(img.image_time)[1]"
    ref="image_container"
  >
    <div
      class="image_overlay_area"
      ref="star_div"
      @click="open_image()"
      @mouseenter="onEnterImg()"
      @mouseleave="onLeaveImg()"
    >
      <v-icon
        :disabled="disabled"
        @click="toggle_select"
        color="primary"
        class="image_star"
        ref="star"
        >{{ star_icon }}</v-icon
      >
      <span class="datetime_grid" v-if="show_text">
        <span class="darker_bckrnd">{{ img.image_time }}</span>
      </span>
      <span class="cam_id_grid" v-if="show_text">
        <span class="darker_bckrnd">{{ img.camera_id }}</span>
      </span>
      <span class="user_id_grid" v-if="show_text"
        ><span class="darker_bckrnd" v-if="show_text">{{
          img.user_id
        }}</span></span
      >
      <span class="ago_grid">
        <span class="darker_bckrnd" v-if="show_text">{{
          age(img.image_time)[0]
        }}</span>
      </span>
    </div>
    <div class="image_thumbnail_annotation_area" ref="mainCanvas_div">
      <canvas class="image_thumbnail_main_canvas" ref="mainCanvas"></canvas>
    </div>
    <vue-load-image class="vue_load_image" @onLoad="startCanvas()">
      <img
        slot="image"
        alt=""
        :src="`${cookie_bucket + img.image_path + '_event.jpg'}`"
        class="grid_img_f"
        ref="image"
      />
      <img slot="preloader" class="grid_img_f" src="@/assets/loader.png" />
      <img
        slot="error"
        ref="image"
        class="grid_img_f"
        src="https://onecup-assets-public.s3.us-west-2.amazonaws.com/images/placeholder/no-image_480x270.jpg"
      />
    </vue-load-image>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";
import { eventBus } from "../main";
import moment from "moment";

// @vuese
// @group Components
// The clickable thumbnail for images
export default {
  props: {
    // The img object
    img: Object,
    // The filter object
    filters: Object,
    // Turn on/off the text overlay
    show_text: Boolean,
    // Turn on/off the border
    bordered: Boolean,
    // The index of the image in the Images page
    img_index: Number,
    // Turn on/off the bounding boxes
    show_bbox: Boolean,

    show_bp: Boolean,
    // Turn on/off the hover effect
    show_hover: Boolean,
    // Disable the select functionality
    disabled: Boolean,
    //
    image_paths_imagePlayer: Array,
    show_zoom: Boolean,
  },
  components: {
    "vue-load-image": VueLoadImage,
  },
  data() {
    return {
      star_icon: "mdi-checkbox-blank-outline",
      selected: false,
    };
  },
  watch: {
    show_bbox() {
      this.updateCanvas();
    },
    show_bp() {
      this.updateCanvas();
    },
    show_zoom() {
      if (this.show_zoom) {
        this.handleImageZoom();
      } else {
        this.$refs.image.style.visibility = "";
        this.updateCanvas();
      }
    },
  },
  methods: {
    convertTo12HourFormat(timeString) {
      const date = new Date(timeString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      const day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;

      hours = hours ? hours : 12;

      const hoursStr = hours.toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hoursStr}:${minutes}:${seconds} ${ampm}`;
    },
    toggle_select(e) {
      e?.stopPropagation();
      this.selected = !this.selected;
      if (this.selected) {
        this.star_icon = "mdi-checkbox-marked";
      } else {
        this.star_icon = "mdi-checkbox-blank-outline";
      }
      // select the image
      // @arg image path
      this.$emit("select", this.img.image_path);
    },
    open_image() {
      let all_customer_list = this.$store.getters.getCustomerObjects;
      let camera_id = this.img.camera_id;
      let user_id = this.img.user_id;

      if (all_customer_list) {
        let foundCustomer = all_customer_list.find(
          (customer) => customer.id == user_id
        );

        if (foundCustomer) {
          user_id = `${foundCustomer.first_name} ${foundCustomer.last_name} - ${foundCustomer.company_name}`;
        }

        let foundCamera = foundCustomer?.cameras?.items.find(
          (camera) => camera.id == camera_id
        );
        if (foundCamera) {
          camera_id += ` - ${foundCamera.camera_model}`;
        }
      }
      var params = {
        img: this.img,
        image_path: this.img.image_path,
        image_time: this.convertTo12HourFormat(this.img.image_time),
        camera_id: camera_id,
        user_id: user_id,
        version: this.img.version,
        image_index: this.img_index,
        bbox_list: this.img.bbox_list,
        body_parts_list: this.img.body_parts_list,
        selected: this.selected,
        imagesLength: this.img.length,
        classificationInfo: this.img.classificationInfo || []
      };
      // open the image card
      eventBus.$emit("open_dialog_images", params);
    },

    async handleImageZoom() {
      let bbox;
      let minX = Infinity,
        minY = Infinity;
      let maxX = 0,
        maxY = 0;
      this.img.bbox_list.forEach((bbox) => {
        let x2 = bbox.x1 + bbox.w;
        let y2 = bbox.y1 + bbox.h;
        minX = Math.min(minX, bbox.x1);
        minY = Math.min(minY, bbox.y1);
        maxX = Math.max(maxX, x2);
        maxY = Math.max(maxY, y2);
      });
      if (minX === Infinity || minY === Infinity || maxX === 0 || maxY === 0) {
        return;
      } else {
        bbox = { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
      }

      const image = this.$refs.image;
      image.style.visibility = "hidden";

      const canvas = this.$refs.mainCanvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let { x, y, w: width, h: height } = bbox;

      const expandArea = (increment, x, y, width, height) => {
        let newWidth = width + increment * 2;
        let newHeight = height + increment * 2;
        let newX = x - increment;
        let newY = y - increment;
        let newAspectRatio = newWidth / newHeight;
        let canvasAspectRatio = canvas.width / canvas.height;
        let aspectRatioDiff = Math.abs(newAspectRatio - canvasAspectRatio);
        let fits =
          aspectRatioDiff < 0.1 ||
          (newWidth >= canvas.width && newHeight >= canvas.height);
        return { newX, newY, newWidth, newHeight, fits };
      };

      let increment = 50;
      let fitsCanvas = false;
      let iterationCount = 0;
      const maxIterations = 100;

      if (width >= canvas.width * 0.9 || height >= canvas.height * 0.9) {
        increment = Math.min(30, increment);
      }

      while (!fitsCanvas && iterationCount < maxIterations) {
        const result = expandArea(increment, x, y, width, height);
        x = result.newX;
        y = result.newY;
        width = result.newWidth;
        height = result.newHeight;
        fitsCanvas = result.fits;

        if (!fitsCanvas) {
          let proximity = Math.min(
            (image.naturalWidth - width) / image.naturalWidth,
            (image.naturalHeight - height) / image.naturalHeight,
            (canvas.width - width) / canvas.width,
            (canvas.height - height) / canvas.height
          );
          increment = Math.max(5, increment * Math.max(proximity, 0.5));
        }

        iterationCount++;

        if (
          width > image.naturalWidth ||
          height > image.naturalHeight ||
          width > canvas.width ||
          height > canvas.height
        ) {
          break;
        }
      }
      // if (iterationCount >= maxIterations) {
      //   console.log("Maximum iterations reached, terminating expansion.");
      // }

      x = Math.max(0, x);
      y = Math.max(0, y);
      width = Math.min(image.naturalWidth - x, width);
      height = Math.min(image.naturalHeight - y, height);

      ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const scaleX = canvas.width / width;
      const scaleY = canvas.height / height;

      for (let i = 0; i < this.img.bbox_list.length; i++) {
        let newBBOX = {
          x: this.img.bbox_list[i].x1,
          y: this.img.bbox_list[i].y1,
          w: this.img.bbox_list[i].w,
          h: this.img.bbox_list[i].h,
          type: this.img.bbox_list[i].type,
        };
        const scaledX = (newBBOX.x - x) * scaleX;
        const scaledY = (newBBOX.y - y) * scaleY;
        const scaledW = newBBOX.w * scaleX;
        const scaledH = newBBOX.h * scaleY;
        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[newBBOX.type]?.strokeStyle ||
          "rgba(60, 179, 113)";
        ctx.lineWidth = 4;
        ctx.strokeRect(scaledX, scaledY, scaledW, scaledH);
      }

      for (let i = 0; i < this.img.body_parts_list.length; i++) {
        let label = this.img.body_parts_list[i].label;
        bbox = {
          x: this.img.body_parts_list[i].bbox.x,
          y: this.img.body_parts_list[i].bbox.y,
          w: this.img.body_parts_list[i].bbox.w,
          h: this.img.body_parts_list[i].bbox.h,
        };
        ctx.lineWidth = 2;

        switch (label) {
          case "tag":
            ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
            break;
          case "head":
            ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
            break;
          case "knee":
            ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
            break;
          case "hoof":
            ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
            break;
          case "tail":
            ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
            break;
          default:
            ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        }

        let bodyPartScaledX = (bbox.x - x) * scaleX;
        let bodyPartScaledY = (bbox.y - y) * scaleY;
        let bodyPartScaledW = bbox.w * scaleX;
        let bodyPartScaledH = bbox.h * scaleY;
        ctx.strokeRect(
          bodyPartScaledX,
          bodyPartScaledY,
          bodyPartScaledW,
          bodyPartScaledH
        );
      }
    },
    startCanvas() {
      this.$nextTick(() => {
        const resizeObserver = new ResizeObserver(() => {
          this.updateCanvas();
        });

        resizeObserver.observe(this.$refs.image);
      });
    },
    onEnterImg() {
      if (!this.show_hover) {
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var star = this.$refs.star?.$el;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if (image_container) {
        image_container.style.zIndex = "99";
      }

      if (image) {
        image.style.transform = "scale(1.3)";
        image.style.boxShadow = "0 0 10px black";
        image.style.zIndex = "99";
      }

      if (canvas && canvas_div) {
        canvas_div.style.zIndex = "100";
        canvas.style.zIndex = "100";

        canvas.style.transform = "scale(1.3)";
      }

      if (star_div && star) {
        star_div.style.zIndex = "101";

        star_div.style.transform = "scale(1.3)";
      }
    },
    onLeaveImg() {
      if (!this.show_hover) {
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var star = this.$refs.star?.$el;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if (image_container) {
        image_container.style.zIndex = null;
      }

      if (image) {
        image.style.transform = null;
        image.style.boxShadow = null;
        image.style.zIndex = null;
      }

      if (canvas && canvas_div) {
        canvas_div.style.zIndex = "1";
        canvas.style.zIndex = null;

        canvas.style.transform = null;
      }

      if (star_div && star) {
        star_div.style.zIndex = "1";

        star_div.style.transform = null;
      }
    },
    async fetchImageMetadata(path) {
      const url = `${this.cookie_bucket + path + "_event.jpg"}`;
      try {
        const img = new Image();
        const loadedImage = await new Promise((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
        return loadedImage.naturalWidth;
      } catch (error) {
        console.error("Error fetching image metadata:", error);
        return null;
      }
    },
    async updateCanvas() {
      var canvas = this.$refs.mainCanvas;
      const image = this.$refs.image;

      if (!canvas) {
        return;
      }
      canvas.style.width = this.$refs.image.clientWidth + "px";
      canvas.style.height = this.$refs.image.clientHeight + "px";
      canvas.width = this.$refs.image.clientWidth;
      canvas.height = this.$refs.image.clientHeight;

      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const imageWidth = await this.fetchImageMetadata(this.img.image_path);

      if (this.show_zoom) {
        this.handleImageZoom();
      } else {
        image.style.visibility = "";
        if (!this.show_bbox && !this.show_bp) {
          return;
        }

        if (this.show_bbox) {
          ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
          this.drawBBOX(ctx, this.img.bbox_list, imageWidth);
        }
        if (this.show_bp) {
          this.drawBP(ctx, this.img.body_parts_list, imageWidth);
        }
      }
    },
    scale(coord, x) {
      var canvas_width = this.$refs.mainCanvas?.width;
      return Math.round(coord * (canvas_width / x));
    },
    async drawBP(ctx, arr, imageWidth) {

      let estrusFilterApplied = this.img?.estrusFilterApplied || false;
      
      let annotationsSource = [];
      if(estrusFilterApplied) {
          return;
      } else {
        annotationsSource = arr;
      }
      for (let i = 0; i < arr.length; i++) {
        var x1 = this.scale(annotationsSource[i].bbox.x, imageWidth);
        var y1 = this.scale(annotationsSource[i].bbox.y, imageWidth);
        var w = this.scale(annotationsSource[i].bbox.w, imageWidth);
        var h = this.scale(annotationsSource[i].bbox.h, imageWidth);

        let label = annotationsSource[i].label;
        ctx.fillStyle = "rgba(0, 154, 255, 0.3)";
        if (label == "tag") {
          ctx.fillStyle = "rgba(55, 255, 255, 0.4)";
        } else if (label == "head") {
          ctx.fillStyle = "rgba(0, 154, 255, 0.4)";
        } else if (label == "knee") {
          ctx.fillStyle = "rgba(254, 255, 0, 0.4)";
        } else if (label == "hoof") {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        } else if (label == "tail") {
          ctx.fillStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        }

        ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 1;
        if (label == "tag") {
          ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
        } else if (label == "head") {
          ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
        } else if (label == "knee") {
          ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
        } else if (label == "hoof") {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        } else if (label == "tail") {
          ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        }
        ctx.strokeRect(x1, y1, w, h);
      }
    },
    drawBBOX(ctx, arr, imageWidth) {
      let estrusFilterApplied = this.img?.estrusFilterApplied || false;
      let annotationsSource = [];
      if (estrusFilterApplied) {
        annotationsSource = arr.slice(0, 2);
      } else {
        annotationsSource = arr;
      }
      if (annotationsSource.length > 0) {
        for (let i = 0; i < annotationsSource.length; i++) {
          var x1 = this.scale(annotationsSource[i].x1, imageWidth);
          var y1 = this.scale(annotationsSource[i].y1, imageWidth);
          var w = this.scale(annotationsSource[i].w, imageWidth);
          var h = this.scale(annotationsSource[i].h, imageWidth);

          ctx.fillStyle =
            this.$store.getters.getBBOXColorCode[annotationsSource[i].type]
              ?.fillStyle || "rgba(60, 179, 113, 0.4)";
          ctx.fillRect(x1, y1, w, h);
          // border modifications
          ctx.lineWidth = 1;
          ctx.strokeStyle =
            this.$store.getters.getBBOXColorCode[annotationsSource[i].type]
              ?.strokeStyle || "rgba(60, 179, 113)";
          ctx.strokeRect(x1, y1, w, h);
        }
      }

      if (estrusFilterApplied && arr[2] && arr[2].x1 !== undefined && arr[2].y1 !== undefined && arr[2].w !== undefined && arr[2].h !== undefined) {
          x1 = this.scale(arr[2].x1, imageWidth);
           y1 = this.scale(arr[2].y1, imageWidth);
           w = this.scale(arr[2].w, imageWidth);
           h = this.scale(arr[2].h, imageWidth);
          ctx.lineWidth = 1;
          ctx.setLineDash([2, 2]);
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x1 + w, y1); // Top side
          ctx.lineTo(x1 + w, y1 + h); // Right side
          ctx.lineTo(x1, y1 + h); // Bottom side
          ctx.lineTo(x1, y1);
          ctx.stroke();
          ctx.setLineDash([]);
      }

    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
  },
  mounted() {
    // this.updateCanvas();
  },
  created() {
    eventBus.$on("select_all_images", (has_selected_all) => {
      if (this.selected && !has_selected_all) {
        return;
      }
      this.toggle_select();
    });
    eventBus.$on("select_image", (image_path) => {
      if (image_path == this.img.image_path) {
        this.toggle_select();
      }
    });
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
  },
};
</script>

<style scoped>
.grid_container_f {
  position: relative;
  text-align: center;
  /* min-height: 130px; */
  background: black;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.1s;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}

.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}

.image_thumbnail_annotation_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.image_thumbnail_main_canvas {
  pointer-events: none;
  transition-duration: 0.1s;
}

.image_overlay_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: 0.1s;
}

.image_star {
  float: right;
}

.no-image {
  min-height: 130px;
}

.vue_load_image {
  margin-bottom: -7px !important;
}
</style>
