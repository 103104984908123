var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isCustomerSelected)?_c('div',{staticClass:"mx-1 text-center py-5"},[_c('v-alert',{staticClass:"mx-auto mt-10",attrs:{"type":"primary","dense":"","max-width":"500"}},[_vm._v(" Please select a customer to view camera analytics. ")])],1):_c('div',{staticClass:"mx-1"},[(_vm.loading)?_c('v-row',{staticClass:"py-5",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"50"}})],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"mr-2 hidden_when_small",staticStyle:{"width":"100px","float":"right"}},[_c('v-select',{staticClass:"assign_select mt-2",attrs:{"items":[10, 20, 50, 100],"outlined":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1),_c('span',{staticClass:"mr-2 hidden_when_small",staticStyle:{"width":"140px","float":"right"}},[_c('v-select',{staticClass:"assign_select mt-2",attrs:{"items":[
              { text: '1 Day', value: '1day' },
              { text: '1 Hour', value: '1hour' },
              { text: '5 Minutes', value: '5min' },
            ],"outlined":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.timeframe),callback:function ($$v) {_vm.timeframe=$$v},expression:"timeframe"}})],1)])],1),_vm._l((_vm.sorted_cameras),function(camera,index){return _c('v-row',{key:camera.id,staticClass:"mt-2"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('span',{staticClass:"thumbnail_span"},[_c('span',{staticClass:"cam_name_span"},[_c('span',{staticClass:"darker_bckrnd"},[_vm._v(_vm._s(camera.name))]),_c('span',{staticClass:"darker_bckrnd",staticStyle:{"float":"right","margin-left":"-100%"}},[_vm._v(_vm._s(index + 1))])]),_c('span',{staticClass:"cam_upload_age_span"},[_c('span',{staticClass:"darker_bckrnd"},[_vm._v(_vm._s(_vm.latest_upload_age(camera.latest_upload)))])]),_c('vue-load-image',{staticStyle:{"width":"100%","height":"100%","position":"absolute","top":"0px"}},[_c('img',{staticClass:"grid_img_f",attrs:{"slot":"image","alt":"","src":`${
                  _vm.cookie_bucket +
                  _vm.customer_id +
                  '/thumbnails/' +
                  camera.id +
                  '_latest.jpg'
                }`},slot:"image"}),_c('img',{staticClass:"grid_img_f",attrs:{"slot":"preloader","src":require("@/assets/loader.png")},slot:"preloader"}),_c('img',{staticClass:"grid_img_f",attrs:{"slot":"error","src":require("@/assets/no_media_light.png")},slot:"error"})])],1)]),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_summary_media',{attrs:{"labels":_vm.labels,"datasets":_vm.by_camera_data[index]?.media_dataset,"timeframe":_vm.timeframe,"title":"Inferred Media"}})],1),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_summary_media',{attrs:{"labels":_vm.labels,"datasets":_vm.by_camera_data[index].total_detected_objects,"timeframe":_vm.timeframe,"title":"Detected Animals"}})],1),_c('v-col',{staticClass:"py-0 bar_col",attrs:{"xl":"3","lg":"3","cols":"12"}},[_c('chart_summary_media',{attrs:{"labels":_vm.labels,"datasets":_vm.by_camera_data[index]?.objects_per_media,"timeframe":_vm.timeframe,"title":"Detected Animals by Image"}})],1)],1)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }