
export const getCustomer = /* GraphQL */ `
query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
      id
      owner 
      customer_first_name
      customer_last_name
      customer_company_name
      customer_type
      customer_status
      customer_preferred_name
      customer_company_website
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_twitter
      customer_social_youtube
      customer_social_other
      customer_stripe_id
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_is_collaborator {
        allowed_operations
        approved
        email
        id
      }
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      createdAt
      updatedAt
      sites {
        items {
          id
          owner
          customer_id
          createdAt
          updatedAt

          site_name

          site_assigned_id
          site_area

          site_community_pasture_used
          site_location {
            latitude
            longitude
          }
          site_map_zoom_level
          site_operation_type
          site_rfid_reader
          site_timezone
          site_weight_scale
          customer {
            id
            owner
            customer_last_name
            customer_first_name

            customer_mailing_address {
              address_1
              address_2
              city
              province
              postal_code
              country
            }
            createdAt
            updatedAt
          }
          jetsons {
            nextToken
          }
          wifi {
            nextToken
          }
          cameras {
            nextToken
          }
        }
        
      }
      jetsons {
        items {
          id
          owner
          customer_id
          site_id
          jetson_thing_name
          jetson_shipped
          jetson_manufacturer
          jetson_type

          jetson_ram_usage
          jetson_status
          jetson_location {
            latitude
            longitude
          }

          jetson_jetpack_version
          jetson_cpu_power

          jetson_cpu_usage
          jetson_disk_usage
          createdAt
          updatedAt
          customer {
            id
            owner
            customer_last_name
            customer_first_name

            customer_mailing_address {
              address_1
              address_2
              city
              province
              postal_code
              country
            }
            createdAt
            updatedAt
          }
          site {
            id
            owner
            customer_id
            site_name

            site_operation_type
            site_assigned_id
            site_area

            site_internet_provider
            site_public_ip
            createdAt
            updatedAt
          }
        }
      }
      wifi {
        items {
          id
          owner
          customer_id
          site_id
          wifi_manufacturer
          wifi_model
          wifi_device_type
          wifi_upload_speed_mbps
          wifi_download_speed_mbps
          wifi_email
          wifi_shipped
          wifi_serial_number
          wifi_ip_lan
          wifi_location {
            latitude
            longitude
          }
          wifi_ip_wan
          wifi_mac
          wifi_status

          wifi_ping_time
          createdAt
          updatedAt
          customer {
            id
            owner
            customer_last_name
            customer_first_name

            customer_mailing_address {
              address_1
              address_2
              city
              province
              postal_code
              country
            }
            createdAt
            updatedAt
          }
          site {
            id
            owner
            customer_id
            site_name

            site_operation_type
            site_assigned_id
            site_area
            site_internet_provider
            site_public_ip
            createdAt
            updatedAt
          }
        }
      }
      cameras {
        items {
      
          camera_s3_uri
          camera_s3_url

          id
          owner
          customer_id
          site_id
          camera_location {
            latitude
            longitude
          }
          camera_hardware_no
          camera_firmware_version
          camera_ip

          camera_name
          camera_model
          camera_broken
          camera_footage_thumbnail_url
          camera_broken_reason
          camera_shipped
          camera_status
          camera_last_video_upload
          camera_last_status_update
          camera_imei
          camera_mac
          camera_map_icon_color
          camera_cellular_enabled
          camera_ftp_enabled
          camera_reolinkcloud_enabled
          camera_manufacturer
          camera_sd_card_size_gb

          camera_latest_upload
          camera_latest_upload_resolution
          camera_latest_upload_corrupted
          camera_latest_upload_fps
          camera_latest_upload_bitrate_kbps
          camera_latest_upload_source

          camera_stream_clear {
            bitrate_kbps
            fps
            resolution
          }
          camera_inventory_number
          camera_stream_fluent {
            bitrate_kbps
            fps
            resolution
          }

          videos(limit: 1) {
            items {
              camera_id
              video_datetime
              owner
              video_duration
              video_size
              video_path
            }
          }
          createdAt
          updatedAt
          customer {
            id
            owner
            customer_last_name
            customer_first_name

            customer_mailing_address {
              address_1
              address_2
              city
              province
              postal_code
              country
            }
            createdAt
            updatedAt
          }
          site {
            id
            owner
            customer_id
            site_name

            site_operation_type
            site_assigned_id
            site_area

            site_internet_provider
            site_public_ip
            createdAt
            updatedAt
          }
        }
      }

      animals {
        items {
          animal_rfid_current
          animal_sex
          animal_classification
        }
      }
      treatments {
        items {
          treatment_date
          treatment_type
          treatment_reason
        }
      }
      measurements {
        items {
          animal {
            animal_rfid_current
          }
          measurement_date
          measurement_type
          measurement_value
          measurement_unit
        }
      }
      phenotypes {
        items {
          animal {
            animal_rfid_current
          }
          phenotype_date
          phenotype_type
          phenotype_score
        }
      }

    }
}`;

export const getCustomerCollaborators = /* GraphQL */`
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customer_collaborators {
        allowed_operations
        approved
        email
        id
      }
    }
  }
`

export const getCustomerIsCollaborators = /* GraphQL */`
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customer_is_collaborator {
        allowed_operations
        approved
        email
        id
      }
    }
  }
`

export const getCustomerTree = /* GraphQL */ `
query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    

      id
      owner
      customer_first_name
      customer_last_name
      customer_company_name
      customer_type
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      createdAt
      updatedAt
    sites {
      
      items {
        

      id
      owner
      customer_id
      createdAt
      updatedAt

      site_name

      site_assigned_id
      site_area

      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_rfid_reader
      site_timezone
      site_weight_scale
        customer {
          id
          customer_last_name
          customer_first_name
        }
        cameras(limit: 400) {
      items {
        

        camera_s3_uri
      camera_s3_url

      id
      owner
      customer_id
      site_id
      camera_location {
        latitude
        longitude
      }
      camera_hardware_no
      camera_firmware_version
      camera_ip

      camera_name
      camera_model
      camera_broken
      camera_footage_thumbnail_url
      camera_broken_reason
      camera_shipped
      camera_status
      camera_last_video_upload
      camera_last_status_update
      camera_imei
      camera_mac

      camera_cellular_enabled
      camera_ftp_enabled
      camera_reolinkcloud_enabled
      camera_manufacturer
      camera_sd_card_size_gb

      camera_latest_upload
      camera_latest_upload_resolution
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_source

      camera_stream_clear {
        bitrate_kbps
        fps
        resolution
      }
      camera_inventory_number
      camera_stream_fluent {
        bitrate_kbps
        fps
        resolution
      }

      videos(limit: 1) {
        items {
          camera_id
          video_datetime
          owner
          video_duration
          video_size
          video_path
        }
      }
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
      nextToken
    }
    wifi {
      items {
        

      id
      owner
      customer_id
      site_id
      wifi_manufacturer
      wifi_model
      wifi_device_type
      wifi_upload_speed_mbps
      wifi_download_speed_mbps
      wifi_email
      wifi_shipped
      wifi_serial_number
      wifi_ip_lan
      wifi_location {
        latitude
        longitude
      }
      wifi_ip_wan
      wifi_mac
      wifi_status

      wifi_ping_time
      createdAt
      updatedAt
        site {
          id
          site_name
        }
        customer {
          id
          customer_last_name
          customer_first_name
        }
      }
    }
    jetsons {
      items {
        

      id
      owner
      customer_id
      site_id
      jetson_thing_name
      jetson_shipped
      jetson_manufacturer
      jetson_type

      jetson_ram_usage
      jetson_status
      jetson_location {
        latitude
        longitude
      }

      jetson_jetpack_version
      jetson_cpu_power

      jetson_cpu_usage
      jetson_disk_usage
      createdAt
      updatedAt
        site {
          id
          site_name
        }
        customer {
          id
          customer_last_name
          customer_first_name
        }
      }
    }
      }
      nextToken
    }
    cameras(limit: 400) {
      items {
        

      id
      owner
      customer_id
      site_id
      camera_location {
        latitude
        longitude
      }
      camera_hardware_no
      camera_firmware_version
      camera_ip

      camera_name
      camera_model
      camera_broken
      camera_footage_thumbnail_url
      camera_broken_reason
      camera_shipped
      camera_status
      camera_last_video_upload
      camera_last_status_update
      camera_imei
      camera_mac

      camera_cellular_enabled
      camera_ftp_enabled
      camera_reolinkcloud_enabled
      camera_manufacturer
      camera_sd_card_size_gb

      camera_latest_upload
      camera_latest_upload_resolution
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_source

        site {
          id
          site_name
        }
        customer {
          id
          customer_last_name
          customer_first_name
        }
      }
      nextToken
    }
    wifi {
      items {
        

      id
      owner
      customer_id
      site_id
      wifi_manufacturer
      wifi_model
      wifi_device_type
      wifi_upload_speed_mbps
      wifi_download_speed_mbps
      wifi_email
      wifi_shipped
      wifi_serial_number
      wifi_ip_lan
      wifi_location {
        latitude
        longitude
      }
      wifi_ip_wan
      wifi_mac
      wifi_status

      wifi_ping_time
      createdAt
      updatedAt
        site {
          id
          site_name
        }
        customer {
          id
          customer_last_name
          customer_first_name
        }
      }
    }
    jetsons {
      items {
        

      id
      owner
      customer_id
      site_id
      jetson_thing_name
      jetson_shipped
      jetson_manufacturer
      jetson_type

      jetson_ram_usage
      jetson_status
      jetson_location {
        latitude
        longitude
      }

      jetson_jetpack_version
      jetson_cpu_power

      jetson_cpu_usage
      jetson_disk_usage
      createdAt
      updatedAt
        site {
          id
          site_name
        }
        customer {
          id
          customer_last_name
          customer_first_name
        }
      }
    }

  }
}
`;

export const listCustomers = /* GraphQL */ `
query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      customer_first_name
      customer_last_name
      customer_company_name
      customer_type
      customer_status
      customer_preferred_name
      customer_company_website
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_twitter
      customer_social_youtube
      customer_social_other
      customer_notification_preference {
        alert {
          call_frequency
          email_frequency
          sms_frequency
        }
        info {
          call_frequency
          email_frequency
          sms_frequency
        }
        warning {
          call_frequency
          email_frequency
          sms_frequency
        }
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        allowed_operations
        approved
        email
        id
      }
      createdAt
      updatedAt
      sites {
        items {
          id
          site_name
        }
      }
      jetsons {
        items {
          id
        }
      }
      wifi {
        items {
          id
          wifi_location {
            latitude
            longitude
          }
          wifi_manufacturer
        }
      }
      cameras {
        items {
          camera_status
          id
          camera_footage_thumbnail_url
          camera_latest_upload
          camera_latest_upload_source
          camera_latest_upload_corrupted
          camera_latest_upload_resolution
          camera_latest_upload_fps
          camera_latest_upload_bitrate_kbps
          camera_model
          camera_location {
            latitude
            longitude
          }
        }
      }
      zones {
        items {
          id
        }
      }
    }
    nextToken
  }
}
`;

export const searchCustomers = /* GraphQL */ `
query searchCustomers(
  $filter: SearchableCustomerFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableCustomerSortInput]
) {
  searchCustomers(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    items {
      id
      owner
      customer_first_name
      customer_last_name
      customer_company_name
      customer_type
      customer_status
      customer_preferred_name
      customer_company_website
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_twitter
      customer_social_youtube
      customer_social_other
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        allowed_operations
        approved
        email
        id
      }
      createdAt
      updatedAt
      sites {
        items {
          id
          site_name
        }
      }
      jetsons {
        items {
          id
        }
      }
      wifi {
        items {
          id
          wifi_location {
            latitude
            longitude
          }
          wifi_manufacturer
        }
      }
      cameras {
        items {
          camera_status
          id
          camera_footage_thumbnail_url
          camera_latest_upload
          camera_latest_upload_source
          camera_latest_upload_corrupted
          camera_latest_upload_resolution
          camera_latest_upload_fps
          camera_latest_upload_bitrate_kbps
          camera_model
          camera_location {
            latitude
            longitude
          }
        }
      }
      zones {
        items {
          id
        }
      }
    }
    nextToken
  }
}
`;

export const searchCustomerTotal = /* GraphQL */ `
query searchCustomersTotal(
  $filter: SearchableCustomerFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableCustomerSortInput]
) {
  searchCustomers(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const getCustomerTypes = /* GraphQL */ `
query getCustomerTypes {
  __type(name:"UserType"){
    name
    enumValues{
      name
    }
  }
}
`

export const listAnimals = /* GraphQL */ `
query ListAnimals(
  $filter: ModelAnimalFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnimals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      
      animal_birth_type
      animal_brand
      animal_breed
      animal_classification
      animal_breeding_date
      animal_color
      animal_dam_status
      animal_disposal_code
      animal_dob
      animal_docility
      animal_image_body
      animal_image_head
      animal_keywords
      animal_last_seen
      animal_name
      animal_nurse_info
      animal_ownership_status
      animal_pedigree {
        tattoo
        registration_number
        registration_doc
        registration_date
        registration_association
        registered_name
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        end_date
        production_type
      }
      animal_purpose
      animal_rfid_current
      animal_sex
      animal_site_doa
      animal_species
      animal_status
      animal_tag_current {
        color
        current
        date
        letter
        name
        number
      }
      animal_tag_history {
        color
        letter
        name
        number
        date
        current
      }
      author
      createdAt
      customer {
        customer_first_name
        customer_last_name
      }
      customer_id
      dam_id
      herd_id
      id
      owner
      sire_id
      site_id
      dam {
        animal_tag_current {
          color
          current
          date
          letter
          name
          number
        }
      }
      sire {
        animal_tag_current {
          color
          current
          date
          letter
          name
          number
        }
      }
    }
    nextToken
  }
}
`;

export const listAnimalsForTags = /* GraphQL */ `
query ListAnimals(
  $filter: ModelAnimalFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnimals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      animal_tag_current {
        color
        current
        date
        letter
        name
        number
      }
      animal_tag_history {
        color
        current
        date
        letter
        name
        number
      }
      animal_dob
      animal_site_doa
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const searchAnimals = /* GraphQL */ `
query searchAnimals(
  $filter: SearchableAnimalFilterInput
  $limit: Int
  $nextToken: String
){
  searchAnimals(filter: $filter, limit: $limit, nextToken: $nextToken){
    total
    nextToken
  }
}
`;

export const searchPhenotypes = /* GraphQL */ `
query searchPhenotypes(
  $filter: SearchablePhenotypeFilterInput
  $limit: Int
  $nextToken: String
){
  searchPhenotypes(filter: $filter, limit: $limit, nextToken: $nextToken){
    total
    nextToken
  }
}
`;

export const searchTreatments = /* GraphQL */ `
query searchTreatments(
  $filter: SearchableTreatmentFilterInput
  $limit: Int
  $nextToken: String
){
  searchTreatments(filter: $filter, limit: $limit, nextToken: $nextToken){
    total
    nextToken
  }
}
`;

export const searchMeasurements = /* GraphQL */`
query searchMeasurements(
  $filter: SearchableMeasurementFilterInput
  $limit: Int
  $nextToken: String
){
  searchMeasurements(filter: $filter, limit: $limit, nextToken: $nextToken){
    total
    nextToken
  }
}
`;

export const searchNotes = /* GraphQL */`
query searchNotes(
  $filter: SearchableNoteFilterInput
  $limit: Int
  $nextToken: String
){
  searchNotes(filter: $filter, limit: $limit, nextToken: $nextToken){
    items {
      note_datetime
      note_text
      id
      author {
        first_name
        id
        last_name
      }
    }
    nextToken
  }
}
`;

export const searchCameraModels = /* GraphQL */`
query SearchCameraModels{
  searchCameras(aggregates: {field: camera_model, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const searchCameraResolutions = /* GraphQL */`
query searchCameraResolutions{
  searchCameras(aggregates: {field: camera_latest_upload_resolution, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const searchCameraSources = /* GraphQL */`
query searchCameraSources {
  searchCameras(aggregates: {field: camera_latest_upload_source, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
            doc_count
          }
        }
      }
    }
  }
}
`;

export const searchCameraShippingStatus = /* GraphQL */`
query searchCameraShippingStatus{
  searchCameras(aggregates: {field: camera_shipped, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const searchCameraOnlineStatus = /* GraphQL */`
query searchCameraOnlineStatus{
  searchCameras(aggregates: {field: camera_status, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const searchWifiShippingStatus = /* GraphQL */`
query SearchWifiShippingStatus {
  searchWifis(aggregates: {field: wifi_shipped, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
    total
  }
}
`;

export const searchWifiOnlineStatus = /* GraphQL */`
query SearchWifiOnlineStatus {
  searchWifis(aggregates: {field: wifi_status, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
    total
  }
}
`;

export const searchWifiManufacturer = /* GraphQL */`
query searchWifiManufacturer {
  searchWifis(aggregates: {field: wifi_manufacturer, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
    total
  }
}
`;

export const searchJetsonOnlineStatus = /* GraphQL */`
query SearchJetsonOnlineStatus {
  searchJetsons(aggregates: {field: jetson_status, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const searchJetsonShippingStatus = /* GraphQL */`
query SearchJetsonShippingStatus {
  searchJetsons(aggregates: {field: jetson_shipped, name: "model", type: terms}) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            key
          }
        }
      }
    }
  }
}
`;

export const getVideoNumberByCustomer = /* GraphQL */`
query getVideoNumberByCustomer(
  $filter: SearchableVideoFilterInput
){
  searchVideos(filter: $filter){
    total
  }
}
`

export const getVideoNumberByCustomerNof = /* GraphQL */`
query getVideoNumberByCustomer{
  searchVideos{
    total
  }
}
`

export const getVideoSizeByFilter = /* GraphQL */`
query getVideoSizeByFilter(
  $filter: SearchableVideoFilterInput
){
  searchVideos(aggregates: {type: sum, name: "video_size_total", field: video_size_mb}, filter: $filter) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
      }
    }
  }
}
`

export const getVideoSizeFloatByFilter = /* GraphQL */`
query getVideoSizeFloatByFilter(
  $filter: SearchableVideoFilterInput
){
  searchVideos(aggregates: {type: sum, name: "video_size_total", field: video_size_float}, filter: $filter) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
      }
    }
  }
}
`

export const getVideoLengthByCustomer = /* GraphQL */`
query getVideoLengthByCustomer(
  $filter: SearchableVideoFilterInput
){
  searchVideos(aggregates: {type: sum, name: "video_length_total", field: video_duration_seconds}, filter: $filter) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
      }
    }
  }
}
`

export const getSite = /* GraphQL */ `
query GetSite($id: ID!) {
  getSite(id: $id) {
    id
    owner
    customer_id

    createdAt
    updatedAt

    site_name

    site_assigned_id
    site_area

    site_community_pasture_used
    site_location {
      latitude
      longitude
    }
    site_map_zoom_level
    site_operation_type
    site_rfid_reader
    site_timezone
    site_weight_scale
    customer {
      id
      owner
      customer_last_name
      customer_first_name

      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      createdAt
      updatedAt
    }
    jetsons {
      items {
        id
        owner
        customer_id
        site_id
        jetson_thing_name
        jetson_shipped
        jetson_manufacturer
        jetson_type

        jetson_shipped

        jetson_jetpack_version
        jetson_cpu_power

        createdAt
        updatedAt
      }
      nextToken
    }
    wifi {
      items {
        id
        owner
        customer_id
        site_id
        wifi_manufacturer
        wifi_model
        wifi_device_type
        wifi_email
        wifi_shipped
        wifi_serial_number
        wifi_status
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_ping_time

        createdAt
        updatedAt
      }
      nextToken
    }
    cameras {
      items {
        id
        owner
        customer_id
        site_id
        camera_ip

        camera_name

        camera_status
        camera_last_video_upload
        camera_last_status_update
        camera_imei
        camera_mac

        camera_cellular_enabled
        camera_ftp_enabled
        camera_hardware_no
        camera_shipped
        camera_firmware_version
        camera_reolinkcloud_enabled
        camera_manufacturer
        camera_sd_card_size_gb

        # camera_video_count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const listSites = /* GraphQL */ `
query ListSites(
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      customer_id
      createdAt
      updatedAt

      site_name

      site_assigned_id
      site_area

      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_rfid_reader
      site_timezone
      site_weight_scale
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
    nextToken
  }
}
`;

export const searchSites = /* GraphQL */ `
query searchSites(
  $filter: SearchableSiteFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableSiteSortInput]
) {
  searchSites(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    items {
      id
      owner
      customer_id
      createdAt
      updatedAt

      site_name

      site_assigned_id
      site_area

      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_rfid_reader
      site_timezone
      site_weight_scale
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
    nextToken
  }
}
`;

export const searchSiteTotal = /* GraphQL */ `
query searchSitesTotal(
  $filter: SearchableSiteFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableSiteSortInput]
) {
  searchSites(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const getJetson = /* GraphQL */ `
query GetJetson($id: ID!) {
  getJetson(id: $id) {
    id
    owner
    customer_id
    site_id
    jetson_thing_name
    jetson_shipped
    jetson_manufacturer
    jetson_type
    
    
    jetson_shipped
    jetson_location {
      latitude
      longitude
    }
    
    
    jetson_jetpack_version
    jetson_cpu_power
    
    
    createdAt
    updatedAt
    customer {
      id
      owner
      customer_last_name
      customer_first_name
      
                customer_mailing_address {

address_1
address_2
city
province
postal_code
country
}
      createdAt
      updatedAt
      sites {
        nextToken
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
    site {
      id
      owner
      customer_id
      site_name
      
       {
        name
      }
      site_operation_type
      site_assigned_id
       {
        key
        value
      }
      site_location {
        latitude
        longitude
      }
      site_area
      site_perimeter {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
      site_out_building_locations {
        latitude
        longitude
      }
      
      site_pen_locations {
        latitude
        longitude
      }
      
      site_natural_water_source_locations {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
      
      site_internet_provider
      site_public_ip
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name
        
                  customer_mailing_address {

address_1
address_2
city
province
postal_code
country
}
        createdAt
        updatedAt
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
  }
}
`;
export const listFeedbacks = /* GraphQL */ `
query ListFeedbacks($filter: ModelFeedbackFilterInput, $nextToken: String) {
  listFeedbacks(filter: $filter, limit: 9999, nextToken: $nextToken) {
    items {
      customer {
        id
        customer_first_name
        customer_last_name
      }
      feedback_body
      customer_id
      feedback_type
      owner
      id
      createdAt

    }
    nextToken
  }
}
`;
export const listNotes = /* GraphQL */ `
query ListNotes($filter: ModelNoteFilterInput, $nextToken: String) {
  listNotes(filter: $filter, limit: 9999, nextToken: $nextToken) {
    items {
      

      id
      owner
      customer_id
      site_id
      animal_id
      author {
        id
        first_name
        last_name
      }
      typeName
      note_text
      note_datetime
      site_id
      site {
        id
        site_name
      }
      customer {
        id
        customer_first_name
        customer_last_name
      }
      animal {
        animal_tag_current {
          color
          name
        }
      }
    }
    nextToken
  }
}
`;
export const listJetsons = /* GraphQL */ `
query ListJetsons($filter: ModelJetsonFilterInput, $nextToken: String) {
  listJetsons(filter: $filter, limit: 9999, nextToken: $nextToken) {
    items {
      id
      owner
      customer_id
      site_id
      jetson_thing_name
      jetson_shipped
      jetson_manufacturer
      jetson_type

      jetson_ram_usage
      jetson_status
      jetson_location {
        latitude
        longitude
      }

      jetson_jetpack_version
      jetson_cpu_power

      jetson_cpu_usage
      jetson_disk_usage
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchJetsons = /* GraphQL */ `
query searchJetsons(
  $filter: SearchableJetsonFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableJetsonSortInput]
) {
  searchJetsons(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    items {
      id
      owner
      customer_id
      site_id
      jetson_thing_name
      jetson_shipped
      jetson_manufacturer
      jetson_type

      jetson_ram_usage
      jetson_status
      jetson_location {
        latitude
        longitude
      }

      jetson_jetpack_version
      jetson_cpu_power

      jetson_cpu_usage
      jetson_disk_usage
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchJetsonTotal = /* GraphQL */ `
query searchJetsonTotal(
  $filter: SearchableJetsonFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableJetsonSortInput]
) {
  searchJetsons(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const listCameraIDs = /* GraphQL */ `
query listCameraIDs(
  $filter: ModelCameraFilterInput
  $limit: Int
  $nextToken: String
) {
  listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      camera_name
    }
    nextToken
  }
}
`;
export const listFootages = /* GraphQL */ `
query listFootages(
  $filter: ModelFootageFilterInput
  $limit: Int
  $nextToken: String
) {
  listFootages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      footage_aspect_ratio
      footage_type
      footage_source
      footage_size
      footage_s3_uri
      camera {
        camera_name
      }
      footage_resolution {
        height
        width
      }
      footage_profile
      footage_pixel_format
      footage_infrared
      footage_fps
      footage_encoder
      footage_duration
      footage_datetime
      footage_creation_time
      footage_corrupted
      camera_id
      footage_bitrate
    }
    nextToken
  }
}
`;
export const listFootageByCamera = /* GraphQL */ `
query listFootageByCamera(
  $filter: ModelFootageFilterInput
  $limit: Int
  $sortDirection: ModelSortDirection
  $nextToken: String
  $camera_id: ID
  $footage_datetime: ModelStringKeyConditionInput
) {
  listFootageByCamera(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    camera_id: $camera_id
    footage_datetime: $footage_datetime
  ) {
    items {
      footage_aspect_ratio
      footage_type
      footage_source
      footage_size
      footage_s3_uri
      camera {
        camera_name
      }
      footage_resolution {
        height
        width
      }
      footage_profile
      footage_pixel_format
      footage_infrared
      footage_fps
      footage_encoder
      footage_duration
      footage_datetime
      footage_creation_time
      footage_corrupted
      camera_id
      footage_bitrate
    }
    nextToken
  }
}
`;
export const listLastFootageDateByCamera = /* GraphQL */ `
query listCameras(
  $filter: ModelCameraFilterInput
  $limit: Int
  $nextToken: String
) {
  listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      camera_name
      camera_footage_thumbnail_url
      camera_latest_upload
      camera_latest_upload_corrupted
      camera_latest_upload_resolution
      camera_latest_upload_fps
      camera_latest_upload_source
      camera_latest_upload_bitrate_kbps
          camera_model
      customer {
        customer_last_name
        customer_first_name
        id
      }
      videos(sortDirection: DESC, limit: 1) {
        items {
          customer_id
          camera_id
          video_datetime
          owner
          video_bookmarked
          video_duration
          video_size
          video_path
        }
      }
    }
    nextToken
  }
}
`;

export const searchLastFootageByCamera = /* GraphQL */ `
query searchCameras(
  $filter: SearchableCameraFilterInput
  $limit: Int
  $nextToken: String
  $sort: [SearchableCameraSortInput]
) {
  searchCameras(filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort) {
    items {
      id
      camera_name
      camera_footage_thumbnail_url
      camera_latest_upload
      camera_latest_upload_corrupted
      camera_latest_upload_resolution
      camera_latest_upload_fps
      camera_latest_upload_source
      camera_latest_upload_bitrate_kbps
      camera_model
      customer {
        customer_last_name
        customer_first_name
        id
      }
      videos(sortDirection: DESC, limit: 1) {
        items {
          customer_id
          camera_id
          video_datetime
          owner
          video_bookmarked
          video_duration
          video_size
          video_path
        }
      }
    }
    nextToken
  }
}
`

export const listContacts = /* GraphQL */ `
query listContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contact_company_name
      contact_family_name
      contact_given_name
      contact_home_phone
      contact_mobile_phone
      contact_personal_email
      contact_source
      contact_type
      contact_work_email
      contact_work_email_domain
      contact_work_phone

      createdAt
    }
    nextToken
  }
}
`;
export const getProject = /* GraphQL */ `
query getProject($id: ID!) {
  getProject(id: $id) {
    project_name
    id
    project_contact {
      items {
        contact {
          contact_given_name
          contact_family_name
          contact_company_name
          contact_mobile_phone
          contact_work_phone
          id
        }
      }
    }
  }
}
`;
export const listContactsByProjectID = /* GraphQL */ `
query listContactsByProjectID(
  $project_id: ID
  $limit: Int
  $nextToken: String
) {
  listContactsByProjectID(
    project_id: $project_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      contact {
        contact_company_name
        contact_home_phone
        contact_given_name
        contact_family_name
        contact_mobile_phone

        contact_personal_email
        contact_source
        contact_title
        contact_type
        contact_work_email_domain
        contact_work_email
        createdAt
        contact_work_phone
        id
      }
    }
    nextToken
  }
}
`;
export const listCorrespondenceByContactID = /* GraphQL */ `
query listCorrespondenceByContactID(
  $contact_id: ID
  $limit: Int
  $nextToken: String
) {
  listCorrespondenceByContactID(
    contact_id: $contact_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      correspondence {
        correspondence_bcc
        correspondence_cc
        correspondence_has_attachment
        correspondence_communication_mode
        correspondence_datetime
        correspondence_recipients
        correspondence_sender
        correspondence_subject
        correspondence_url
        id
        owner
      }
    }
    nextToken
  }
}
`;
export const listCorrespondenceBySender = /* GraphQL */ `
query listCorrespondenceBySender(
  $correspondence_sender: ID
  $limit: Int
  $nextToken: String
) {
  listCorrespondenceBySender(
    correspondence_sender: $correspondence_sender
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      correspondence
      correspondence_bcc
      correspondence_cc
      correspondence_has_attachment
      correspondence_communication_mode
      correspondence_datetime
      correspondence_recipients
      correspondence_sender
      correspondence_subject
      correspondence_url
      id
      owner
    }
    nextToken
  }
}
`;
export const listCorrespondenceByProjectID = /* GraphQL */ `
query listCorrespondenceByProjectID(
  $project_id: ID
  $limit: Int
  $nextToken: String
) {
  listCorrespondenceByProjectID(
    project_id: $project_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      correspondence {
        correspondence_bcc
        correspondence_cc
        correspondence_has_attachment
        correspondence_communication_mode
        correspondence_datetime
        correspondence_recipients
        correspondence_sender
        correspondence_subject
        correspondence_url
        id
        owner
      }
      id
    }
    nextToken
  }
}
`;

export const listContactByCorrespondenceSender = /* GraphQL */ `
query listContactByCorrespondenceSender(
  $contact_id: ID
  $limit: Int
  $nextToken: String
  $contact_sender_receiver: ModelStringKeyConditionInput
) {
  listContactByCorrespondenceSender(
    contact_id: $contact_id
    contact_sender_receiver: $contact_sender_receiver
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      correspondence {
        correspondence_bcc
        correspondence_cc
        correspondence_has_attachment
        correspondence_communication_mode
        correspondence_datetime
        correspondence_recipients
        correspondence_sender
        correspondence_subject
        correspondence_url
        id
        owner
      }
    }
    nextToken
  }
}
`;

export const listProjectContactsByProjectID = /* GraphQL */ `
query listContactsByProjectID(
  $project_id: ID
  $limit: Int
  $nextToken: String
) {
  listContactsByProjectID(
    project_id: $project_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const listProjectsCorrByProjectID = /* GraphQL */ `
query listCorrespondenceByProjectID(
  $project_id: ID
  $limit: Int
  $nextToken: String
) {
  listCorrespondenceByProjectID(
    project_id: $project_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;

export const listContactsCorrByContactID = /* GraphQL */ `
query listCorrespondenceByContactID(
  $contact_id: ID
  $limit: Int
  $nextToken: String
) {
  listCorrespondenceByContactID(
    contact_id: $contact_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const listProjectsContactByContactID = /* GraphQL */ `
query listProjectsByContactID(
  $contact_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByContactID(
    contact_id: $contact_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;

export const listContactsCorrByCorrespondenceID = /* GraphQL */ `
query listContactByCorrespondenceID(
  $correspondence_id: ID
  $limit: Int
  $nextToken: String
) {
  listContactByCorrespondenceID(
    correspondence_id: $correspondence_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const listProjectsCorrByCorrespondenceID = /* GraphQL */ `
query listProjectsByCorrespondenceID(
  $correspondence_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByCorrespondenceID(
    correspondence_id: $correspondence_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;

export const listContactByCorrespondenceID = /* GraphQL */ `
query listContactByCorrespondenceID(
  $correspondence_id: ID
  $limit: Int
  $nextToken: String
) {
  listContactByCorrespondenceID(
    correspondence_id: $correspondence_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      contact {
        contact_company_name
        contact_home_phone
        contact_given_name
        contact_family_name
        contact_mobile_phone

        contact_personal_email
        contact_source
        contact_title
        contact_type
        contact_work_email_domain
        contact_work_email
        createdAt
        contact_work_phone
        id
      }
    }
    nextToken
  }
}
`;
export const listProjectsByCorrespondenceID = /* GraphQL */ `
query listProjectsByCorrespondenceID(
  $correspondence_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByCorrespondenceID(
    correspondence_id: $correspondence_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      project {
        project_start_date
        project_staff
        project_name
        project_end_date
        project_description
        id
        owner
      }
      id
    }
    nextToken
  }
}
`;
export const listProjectsByCorrespondenceID2 = /* GraphQL */ `
query listProjectsByCorrespondenceID(
  $correspondence_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByCorrespondenceID(
    correspondence_id: $correspondence_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      project {
        tasks {
          items {
            task_status
            task_sequence
            task_due_date
            task_description
            project_id
            contact_id
            id
            task_contact {
              contact_family_name
              contact_given_name
            }
          }
        }
        project_contact {
          items {
            id
            contact {
              contact_given_name
              contact_family_name
              contact_company_name
              contact_mobile_phone
              contact_work_phone
              id
            }
          }
        }
        project_start_date
        project_staff
        project_name
        project_end_date
        project_description
        id
        owner
      }
      id
    }
    nextToken
  }
}
`;
export const listProjectsByContactID = /* GraphQL */ `
query listProjectsByContactID(
  $contact_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByContactID(
    contact_id: $contact_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      project {
        project_start_date
        project_staff
        project_name
        project_end_date
        project_description
        id
        owner
      }
      id
    }
    nextToken
  }
}
`;
export const listProjectsByContactID2 = /* GraphQL */ `
query listProjectsByContactID(
  $contact_id: ID
  $limit: Int
  $nextToken: String
) {
  listProjectsByContactID(
    contact_id: $contact_id
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      project {
        tasks {
          items {
            task_status
            task_sequence
            task_due_date
            task_description
            project_id
            contact_id
            id
            task_contact {
              contact_family_name
              contact_given_name
            }
          }
        }
        project_contact {
          items {
            id
            contact {
              contact_given_name
              contact_family_name
              contact_company_name
              contact_mobile_phone
              contact_work_phone
              id
            }
          }
        }
        project_start_date
        project_staff
        project_name
        project_end_date
        project_description
        id
        owner
      }
      id
    }
    nextToken
  }
}
`;
export const listCorrespondences = /* GraphQL */ `
query listCorrespondences(
  $filter: ModelCorrespondenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listCorrespondences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      correspondence_bcc
      correspondence_cc
      correspondence_has_attachment
      correspondence_communication_mode
      correspondence_datetime
      correspondence_recipients
      correspondence_sender
      correspondence_subject
      correspondence_url
      id
      owner
    }
    nextToken
  }
}
`;
export const listProjectNames = /* GraphQL */ `
query listProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      project_name
    }
    nextToken
  }
}
`;
export const listContactNames = /* GraphQL */ `
query listContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contact_given_name
      contact_family_name
    }
    nextToken
  }
}
`;
export const listProjects = /* GraphQL */ `
query listProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      project_start_date
      project_staff
      project_name
      project_end_date
      project_description
      id
      owner
      tasks {
        items {
          task_status
          task_sequence
          task_due_date
          task_description
          project_id
          contact_id
          id
          task_contact {
            contact_family_name
            contact_given_name
          }
        }
      }
      project_contact {
        items {
          id
          contact {
            contact_given_name
            contact_family_name
            contact_company_name
            contact_mobile_phone
            contact_work_phone
            id
          }
        }
      }
    }
    nextToken
  }
}
`;
export const getWifi = /* GraphQL */ `
query GetWifi($id: ID!) {
  getWifi(id: $id) {
    id
    owner
    customer_id
    site_id
    wifi_manufacturer
    wifi_model
    wifi_device_type
    wifi_email
    wifi_shipped
    wifi_serial_number
    wifi_status
    wifi_ip_lan
    wifi_ip_wan
    wifi_mac
    wifi_ping_time
    
    createdAt
    updatedAt
    customer {
      id
      owner
      customer_last_name
      customer_first_name
      
                customer_mailing_address {

address_1
address_2
city
province
postal_code
country
}
      createdAt
      updatedAt
      sites {
        nextToken
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
    site {
      id
      owner
      customer_id
      site_name
      
       {
        name
      }
      site_operation_type
      site_assigned_id
       {
        key
        value
      }
      site_location {
        latitude
        longitude
      }
      site_area
      site_perimeter {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
      site_out_building_locations {
        latitude
        longitude
      }
      
      site_pen_locations {
        latitude
        longitude
      }
      
      site_natural_water_source_locations {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }
      
       {
        latitude
        longitude
      }

      site_internet_provider
      site_public_ip
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name
        
                  customer_mailing_address {

address_1
address_2
city
province
postal_code
country
}
        createdAt
        updatedAt
      }
      jetsons {
        nextToken
      }
      wifi {
        nextToken
      }
      cameras {
        nextToken
      }
    }
  }
}
`;

export const listWifis = /* GraphQL */ `
query ListWifis($filter: ModelWifiFilterInput, $nextToken: String) {
  listWifis(filter: $filter, limit: 9999, nextToken: $nextToken) {
    items {
      id
      owner
      customer_id
      site_id
      wifi_manufacturer
      wifi_inventory_number
      wifi_model
      wifi_device_type
      wifi_upload_speed_mbps
      wifi_download_speed_mbps
      wifi_email
      wifi_shipped
      wifi_serial_number
      wifi_ip_lan
      wifi_location {
        latitude
        longitude
      }
      wifi_ip_wan
      wifi_mac
      wifi_status

      wifi_ping_time
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area
        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchWifis = /* GraphQL */ `
query searchWifis(
  $filter: SearchableWifiFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableWifiSortInput]
) {
  searchWifis(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    items {
      id
      owner
      customer_id
      site_id
      wifi_manufacturer
      wifi_inventory_number
      wifi_model
      wifi_device_type
      wifi_upload_speed_mbps
      wifi_download_speed_mbps
      wifi_email
      wifi_shipped
      wifi_serial_number
      wifi_ip_lan
      wifi_location {
        latitude
        longitude
      }
      wifi_ip_wan
      wifi_mac
      wifi_status

      wifi_ping_time
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area
        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchWifiTotal = /* GraphQL */ `
query searchWifiTotal(
  $filter: SearchableWifiFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableWifiSortInput]
) {
  searchWifis(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const listWifiModels = /* GraphQL */ `
query listWifiModels {
  __type(name:"WifiModel"){
    name
    enumValues{
      name
    }
  }
}
`;

export const getCamera = /* GraphQL */ `
query GetCamera($id: ID!) {
  getCamera(id: $id) {
    id
    owner
    customer_id
    site_id
    camera_location {
      latitude
      longitude
    }
    camera_ip

    camera_name

    camera_status
    camera_last_video_upload
    camera_last_status_update
    camera_imei
    camera_mac

    camera_cellular_enabled
    camera_ftp_enabled
    camera_reolinkcloud_enabled
    camera_hardware_no
    camera_shipped
    camera_firmware_version
    camera_manufacturer
    camera_sd_card_size_gb

    # camera_video_count
    # createdAt
    # updatedAt
    customer {
      id
      owner
      customer_last_name
      customer_first_name
    }
    site {
      id
      owner
      site_name
      site_timezone
    }
  }
}
`;

// camera_last_video_upload
// camera_last_status_update
export const listCameras = /* GraphQL */ `
query ListCameras(
  $filter: ModelCameraFilterInput
  $limit: Int
  $nextToken: String
) {
  listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      customer_id
      site_id
      camera_location {
        latitude
        longitude
      }
      camera_hardware_no
      camera_firmware_version
      camera_ip

      camera_name
      camera_model
      camera_broken
      camera_footage_thumbnail_url
      camera_broken_reason
      camera_shipped
      camera_status
      camera_imei
      camera_mac

      camera_cellular_enabled
      camera_ftp_enabled
      camera_reolinkcloud_enabled
      camera_manufacturer
      camera_sd_card_size_gb

      camera_latest_upload
      camera_latest_upload_resolution
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_source

      camera_stream_clear {
        bitrate_kbps
        fps
        resolution
      }
      camera_inventory_number
      camera_stream_fluent {
        bitrate_kbps
        fps
        resolution
      }

      videos(limit: 1) {
        items {
          camera_id
          video_datetime
          owner
          video_duration
          video_size
          video_path
        }
      }
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_timezone
        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchCameras = /* GraphQL */ `
query searchCameras(
  $filter: SearchableCameraFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableCameraSortInput],
  $nextToken: String
) {
  searchCameras(filter: $filter, from: $from, limit: $limit, sort: $sort, nextToken: $nextToken) {
    items {
      id
      owner
      customer_id
      site_id
      camera_location {
        latitude
        longitude
      }
      camera_hardware_no
      camera_firmware_version
      camera_ip

      camera_name
      camera_model
      camera_broken
      camera_footage_thumbnail_url
      camera_broken_reason
      camera_shipped
      camera_status
      camera_imei
      camera_mac

      camera_cellular_enabled
      camera_ftp_enabled
      camera_reolinkcloud_enabled
      camera_manufacturer
      camera_sd_card_size_gb

      camera_latest_upload
      camera_latest_upload_resolution
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_source

      camera_stream_clear {
        bitrate_kbps
        fps
        resolution
      }
      camera_inventory_number
      camera_stream_fluent {
        bitrate_kbps
        fps
        resolution
      }

      videos(limit: 1) {
        items {
          camera_id
          video_datetime
          owner
          video_duration
          video_size
          video_path
        }
      }
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchCameraTotal = /* GraphQL */ `
query searchCameraTotal(
  $filter: SearchableCameraFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableCameraSortInput]
) {
  searchCameras(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;


export const searchVideoTotal = /* GraphQL */ `
query searchVideoTotal(
  $filter: SearchableVideoFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableVideoSortInput]
) {
  searchVideos(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const searchCameraByModel = /* GraphQL */ `
query searchCameraTotal(
  $filter: SearchableCameraFilterInput,
  $limit: Int, 
) {
  searchCameras(filter: $filter, limit: $limit) {
    items {
      id
    }
    nextToken
  }
}
`;

export const getVideo = /* GraphQL */ `
query GetVideo($owner: ID!, $video_datetime: String!, $camera_id: ID!) {
  getVideo(
    owner: $owner
    video_datetime: $video_datetime
    camera_id: $camera_id
  ) {
    camera_id
    video_datetime
    owner
    video_duration
    video_size
    video_source
    video_fps
    createdAt
    updatedAt
    camera {
      id
      owner
      customer_id
      site_id
      camera_location {
        latitude
        longitude
      }
      camera_ip

      camera_name

      camera_status
      camera_last_video_upload
      camera_last_status_update
      camera_imei
      camera_mac

      camera_cellular_enabled
      camera_ftp_enabled
      camera_reolinkcloud_enabled
      camera_hardware_no
      camera_firmware_version
      camera_manufacturer
      camera_sd_card_size_gb

      # camera_video_count
      createdAt
      updatedAt
      customer {
        id
        owner
        customer_last_name
        customer_first_name

        customer_mailing_address {
          address_1
          address_2
          city
          province
          postal_code
          country
        }
        createdAt
        updatedAt
      }
      site {
        id
        owner
        customer_id
        site_name

        site_operation_type
        site_assigned_id
        site_area

        site_internet_provider
        site_public_ip
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const listVideos = /* GraphQL */ `
query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      camera_id
      video_datetime

      video_duration
      video_size
      video_source
      video_path
      video_fps
      createdAt
      updatedAt
      # appearances(limit: 99999) {
      #   items {
      #     appearance_start
      #     livestock_id
      #     livestock {
      #       items {
      #         livestock_drop_tag
      #       }
      #     }
      #   }
      # }
      customer {
        customer_last_name
        customer_first_name
      }
      camera {
        #   id
        #   owner
        #   customer_id
        #   site_id
        #   camera_ip
        camera_name
        camera_model
        #   camera_status
        #   camera_last_video_upload
        #   camera_last_status_update
        #   camera_imei
        #   camera_mac
        #
        #   camera_cellular_enabled
        #   camera_ftp_enabled
        #   camera_reolinkcloud_enabled
        #   camera_manufacturer
        #   camera_sd_card_size_gb
        #
        #
        #
        #
        #   # camera_video_count
        #   createdAt
        #   updatedAt
      }
    }
    nextToken
  }
}
`;

export const searchVideoCameraID = /* GraphQL */ `
query searchVideos(
  $filter: SearchableVideoFilterInput,
  $from: Int, 
  $limit: Int, 
  $nextToken: String,
  $sort: [SearchableVideoSortInput]
) {
  searchVideos(from: $from, filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort) {
    items {
      camera_id
    }
    nextToken
  }
}
`

export const searchVideos = /* GraphQL */ `
query searchVideos(
  $filter: SearchableVideoFilterInput,
  $from: Int, 
  $limit: Int, 
  $nextToken: String,
  $sort: [SearchableVideoSortInput]
) {
  searchVideos(from: $from, filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort) {
    items {
      id
      camera_id
      video_datetime
      owner
      video_duration
      video_size
      video_source
      video_path
      video_fps
      video_resolution
      video_bitrate
      video_height
      video_width
      video_processing_start_datetime
      video_processing_end_datetime
      video_processing_duration_seconds
      video_processing_server
      video_bookmarked
      createdAt
      updatedAt
      # appearances(limit: 99999) {
      #   items {
      #     appearance_start
      #     livestock_id
      #     livestock {
      #       items {
      #         livestock_drop_tag
      #       }
      #     }
      #   }
      # }
      customer {
        customer_last_name
        customer_first_name
      }
      camera {
        id
        #   owner
        #   customer_id
        #   site_id
        #   camera_ip
        camera_name
        camera_model
        #   camera_status
        #   camera_last_video_upload
        #   camera_last_status_update
        #   camera_imei
        #   camera_mac
        #
        #   camera_cellular_enabled
        #   camera_ftp_enabled
        #   camera_reolinkcloud_enabled
        #   camera_manufacturer
        #   camera_sd_card_size_gb
        #
        #
        #
        #
        #   # camera_video_count
        #   createdAt
        #   updatedAt
      }
    }
    nextToken
  }
}
`

export const listVideosByCustomerCameraDate = /* GraphQL */ `
query ListVideosByCustomerCameraDate(
  $customer_id: ID!
  $camera_idVideo_datetime: ModelVideoVideosByCustomerCameraDateCompositeKeyConditionInput
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVideosByCustomerCameraDate(
    customer_id: $customer_id
    camera_idVideo_datetime: $camera_idVideo_datetime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      camera {
        camera_name
        camera_model
      }
      # appearances(limit: 9999) {
      # items {
      #   appearance_start
      #   livestock_id
      #   }
      # }
      customer_id
      camera_id
      video_datetime
      video_bookmarked
      video_duration
      video_size
      video_path
      video_fps
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listVideosByCustomerDateCamera = /* GraphQL */ `
query ListVideosByCustomerCameraDate(
  $customer_id: ID!
  $video_datetimeCamera_id: ModelVideoVideosByCustomerDateCameraCompositeKeyConditionInput
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVideosByCustomerDateCamera(
    customer_id: $customer_id
    video_datetimeCamera_id: $video_datetimeCamera_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      camera {
        camera_name
        camera_model
      }
      # appearances(limit: 9999) {
      # items {
      #   appearance_start
      #   livestock_id
      #   }
      # }
      customer_id
      camera_id
      video_datetime
      video_bookmarked
      video_duration
      video_size
      video_path
      video_fps
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listVideosByCamera = /* GraphQL */ `
query ListVideosByCamera(
  $camera_id: ID!
  $video_datetime: ModelStringKeyConditionInput
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVideosByCamera(
    camera_id: $camera_id
    video_datetime: $video_datetime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      camera {
        camera_name
        camera_model
      }
      # appearances(limit: 9999) {
      # items {
      #   appearance_start
      #   livestock_id
      #   }
      # }
      customer_id
      camera_id
      video_datetime
      owner
      video_bookmarked
      video_duration
      video_size
      video_path
      video_fps
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listEvents = /* GraphQL */ `
query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      updatedAt
      owner
      event_kind
      event_description
    }
    nextToken
  }
}
`;
export const listLivestocks = /* GraphQL */ `
query ListLivestocks(
  $owner: ID
  $livestock_drop_tagLivestock_dob: ModelLivestockPrimaryCompositeKeyConditionInput
  $filter: ModelLivestockFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLivestocks(
    owner: $owner
    livestock_drop_tagLivestock_dob: $livestock_drop_tagLivestock_dob
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      customer_id
      site_id
      livestock_drop_tag
      livestock_rfid_tag
      livestock_dob
      livestock_farm_doa
      livestock_head_img
      livestock_body_img
      livestock_heatmap_img
      livestock_ocr_id
      livestock_face_id
      livestock_ocr_id_confidence_score
      livestock_face_id_confidence_score
      appearances(limit: 99999) {
        items {
          video_id
          owner
          appearance_start
          appearance_end
          video {
            video_duration
            video_path
            camera {
              camera_name
            }
          }
        }
      }
      livestock_weight {
        measurement
        unit
        date
      }
      livestock_species
      livestock_breed {
        name
        calving_dates {
          start
          end
        }
        breeding_dates {
          start
          end
        }
        branding_dates {
          start
          end
        }
        weaning_dates {
          start
          end
        }
      }
      customer {
        customer_last_name
        customer_first_name
      }
      site {
        site_name
      }
      livestock_gender
      livestock_pregnant
      livestock_status
      livestock_last_seen
      livestock_herd
      livestock_dam
      livestock_sire
      livestock_calving_date
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listHerds = /* GraphQL */ `
query ListHerds(
  $owner: ID
  $herd_date: ModelStringKeyConditionInput
  $filter: ModelHerdFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHerds(
    owner: $owner
    herd_date: $herd_date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      owner
      customer_id
      herd_date
      herd_ocr_id
      herd_ocr_id_count
      createdAt
      updatedAt
      customer {
        id
        customer_last_name
        customer_first_name
      }
    }
    nextToken
  }
}
`;

export const listNotesByCustomer = /* GraphQL */ `
query ListNotesByCustomer($customer_id: ID!) {
  listNotesByCustomer(customer_id: $customer_id) {
    items {
      

      id
      note_text
      author {
        id
        first_name
        last_name
      }
      note_datetime

    }
  }
}
`;
export const listNotesBySite = /* GraphQL */ `
query ListNotesBySite($site_id: ID!) {
  listNotesBySite(site_id: $site_id) {
    items {
      

      id
      note_text
     
      note_datetime
      author {
        id
        first_name
        last_name
      }
    }
  }
}
`;
export const listNotesByCamera = /* GraphQL */ `
query ListNotesByCamera($camera_id: ID!) {
  listNotesByCamera(camera_id: $camera_id) {
    items {
      

      id
      note_text
      
      note_datetime
      author {
        id
        first_name
        last_name
      }
    }
  }
}
`;
export const listNotesByJetson = /* GraphQL */ `
query ListNotesByJetson($jetson_id: ID!) {
  listNotesByJetson(jetson_id: $jetson_id) {
    items {
      

      id
      note_text
      
      note_datetime
      author {
        id
        first_name
        last_name
      }
    }
  }
}
`;
export const listNotesByWifi = /* GraphQL */ `
query ListNotesByWifi($wifi_id: ID!) {
  listNotesByWifi(wifi_id: $wifi_id) {
    items {
      

      id
      note_text
      note_datetime
      
      author {
        id
        first_name
        last_name
      }
    }
  }
}
`;
export const listNotesByAnimal = /* GraphQL */ `
query ListNotesByAnimal($animal_id: ID!) {
  listNotesByAnimal(animal_id: $animal_id) {
    items {
      

      id
      note_text
      note_datetime
      author {
        id
        first_name
        last_name
      }
    }
  }
}
`;

export const searchAnimalIdentifications = /* GraphQL */ `
  query searchAnimalIdentifications(
    $filter: SearchableAnimalIdentificationFilterInput,
  $from: Int, 
  $limit: Int, 
  $nextToken: String,
  $sort: [SearchableAnimalIdentificationSortInput]
) {
  searchAnimalIdentifications(from: $from, filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort)  {
      items {
        id
        customer_id
        tag_id
        animal_identification_review_status
        animal_identification_datetime
        inferences(filter: {inference_model_type: {eq: TAG_ID}}) {
          items {
            camera_id
            inference_value
            inference_confidence
          }
        }
        image{
          camera_id
        }
        customer {
          customer_first_name
          customer_last_name
        }
      }
      nextToken
    }
  }
`;

export const searchAnimalIdentificationTotal = /* GraphQL */ `
query searchAnimalIdentificationTotal(
  $filter: SearchableAnimalIdentificationFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableAnimalIdentificationSortInput]
) {
  searchAnimalIdentifications(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const searchNotifications = /* GraphQL */ `
  query searchNotifications(
    $filter: SearchableNotificationFilterInput,
  $from: Int, 
  $limit: Int, 
  $nextToken: String,
  $sort: [SearchableNotificationSortInput]
) {
  searchNotifications(from: $from, filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort)  {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id

        camera {
          camera_name
        }

        customer {
          customer_first_name
          customer_last_name
        }

        notification_datetime
        notification_level
        notification_title
        notification_type
        notification_description
        notification_read
        notification_value
        notification_media_url

        # notification_twilio_sid
        notification_sent_datetime_twilio
        # notification_sendgrid_id
        notification_sent_datetime_sendgrid

        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const searchNotificationTotal = /* GraphQL */ `
query searchNotificationTotal(
  $filter: SearchableNotificationFilterInput,
  $from: Int, 
  $limit: Int, 
  $sort: [SearchableNotificationSortInput]
) {
  searchNotifications(filter: $filter, from: $from, limit: $limit, sort: $sort) {
    total
    nextToken
  }
}
`;

export const getNotificationLevels = /* GraphQL */ `
query getNotificationTypes {
  __type(name:"NotificationLevel"){
    name
    enumValues{
      name
    }
  }
}
`

export const getNotificationFrequency = /* GraphQL */ `
query getNotificationFrequency {
  __type(name:"NotificationFrequency"){
    name
    enumValues{
      name
    }
  }
}
`

export const searchHistoryImagesByTag = /* GraphQL */ `
query searchHistoryImagesByTag($nextToken: String, $filter: SearchableAnimalTagFilterInput,  $filter_id: ModelAnimalIdentificationFilterInput) {
  searchAnimalTags(filter: $filter) {
    items {
      animal_tag_text
      animal_identifications(nextToken: $nextToken, filter: $filter_id) {
        nextToken
        items {
          customer_id
          animal_identification_datetime
          tag_id
          image {
            camera_id
          }
        }
      }
    }
  }
}
`

export const searchAnimalTags = /* GraphQL */ `
  query searchAnimalTags(
    $filter: SearchableAnimalTagFilterInput,
  $from: Int, 
  $limit: Int, 
  $nextToken: String,
  $sort: [SearchableAnimalTagSortInput]
) {
  searchAnimalTags(from: $from, filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort)  {
      items {
        id
      }
      nextToken
    }
  }
`;