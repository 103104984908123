<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row justify="center">
        <v-col xl="11" lg="12" md="12" sm="12" cols="12">
          <v-card elevation="2">
            <v-card-title>
              <span>Map</span><v-spacer/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                        @click="update_default_site_location"
                        x-small
                        :disabled="!default_coords"
                        :color="default_coords ? 'primary' : '#e0e0e0'"
                        elevation="0"
                      >
                        <v-icon>mdi-map-marker-star</v-icon></v-btn
                      >
                    </template>
                    <span>Update Default Site Location</span>
                  </v-tooltip>
                  <customerFilter v-model="customer_select" :dense="true" :noall="true"></customerFilter>
            </v-card-title>
            <v-card-text class="text-center pb-2">
              <v-card>
                <v-card-text
                  ><v-row v-if="cameras" class="pl-3">
                    <v-list
                      elevation="2"
                      class="map_side_bar pa-0"
                      max-width="244"
                      max-height="600"
                      v-if="site_map_info && (cameras.length || (wifis && wifis.length) || (jetsons && jetsons.length))"
                    >
                      <v-list-group v-if="cameras.length" value="true">
                        <template v-slot:activator>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-cctv</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="group_title"
                            >Cameras</v-list-item-title
                          >
                        </template>

                        <v-list-item v-for="(camera, i) in cameras" :key="i">
                          <v-list-item-icon>
                            <v-menu
                              offset-y
                              v-if="
                                camera.camera_location &&
                                !(
                                  camera.camera_location.latitude == null ||
                                  camera.camera_location.latitude == '' ||
                                  camera.camera_location.latitude == 'null'
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="
                                    get_color(camera.camera_map_icon_color)
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="select_color(camera, 'camera')"
                                  >mdi-cctv</v-icon
                                ></template
                              >
                              <v-card
                                ><v-color-picker
                                  show-swatches
                                  :swatches="swatches"
                                  class="map_icon_color_picker"
                                  v-model="selected_color"
                                >
                                </v-color-picker>

                                <v-btn
                                  class="mb-3 ml-3 mt-n2"
                                  depressed
                                  @click="
                                    remove_map_marker(
                                      camera.id,
                                      'camera'
                                    )
                                  "
                                  ><v-icon color="primary"
                                    >mdi-map-marker-remove</v-icon
                                  ></v-btn
                                >
                              </v-card>
                            </v-menu>
                            <v-icon
                              color="gray"
                              class="non_placed_hardware"
                              v-if="
                                !camera.camera_location ||
                                camera.camera_location.latitude == null ||
                                camera.camera_location.latitude == '' ||
                                camera.camera_location.latitude == 'null'
                              "
                              >mdi-cctv</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            :draggable="
                              !camera.camera_location ||
                              camera.camera_location.latitude == null ||
                              camera.camera_location.latitude == '' ||
                              camera.camera_location.latitude == 'null'
                            "
                            style="text-align: left"
                            :class="
                              'map_item_content_' +
                              String(
                                !camera.camera_location ||
                                  camera.camera_location.latitude == null ||
                                  camera.camera_location.latitude == '' ||
                                  camera.camera_location.latitude == 'null'
                              )
                            "
                            @dragend="select_hardware(camera, 'camera')"
                          >
                            <v-list-item-title
                              v-if="
                                camera.camera_location &&
                                !(
                                  camera.camera_location.latitude == null ||
                                  camera.camera_location.latitude == '' ||
                                  camera.camera_location.latitude == 'null'
                                )
                              "
                              @mouseenter="set_marker(camera.camera_name)"
                              @mouseleave="set_marker(false)"
                              class="map_sidebar_item_name"
                              v-text="camera.camera_name"
                            ></v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="map_sidebar_item_name"
                              v-text="camera.camera_name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>

                      <v-list-group v-if="wifis && wifis.length">
                        <template v-slot:activator>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-wifi</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="group_title"
                            >Wifi</v-list-item-title
                          >
                        </template>

                        <v-list-item v-for="(wifi, i) in wifis" :key="i">
                          <v-list-item-icon>
                            <v-menu
                              offset-y
                              v-if="
                                wifi.wifi_location &&
                                !(
                                  wifi.wifi_location.latitude == null ||
                                  wifi.wifi_location.latitude == '' ||
                                  wifi.wifi_location.latitude == 'null'
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="get_color(wifi.wifi_map_icon_color)"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="select_color(wifi, 'wifi')"
                                  >mdi-wifi</v-icon
                                ></template
                              >
                              <v-card
                                ><v-color-picker
                                  show-swatches
                                  :swatches="swatches"
                                  class="map_icon_color_picker"
                                  v-model="selected_color"
                                >
                                </v-color-picker>

                                <v-btn
                                  class="mb-3 ml-3 mt-n2"
                                  depressed
                                  @click="
                                    remove_map_marker(
                                      wifi.id,
                                      'wifi'
                                    )
                                  "
                                  ><v-icon color="primary"
                                    >mdi-map-marker-remove</v-icon
                                  ></v-btn
                                >
                              </v-card>
                            </v-menu>
                            <v-icon
                              color="gray"
                              class="non_placed_hardware"
                              v-if="
                                !(
                                  wifi.wifi_location &&
                                  !(
                                    wifi.wifi_location.latitude == null ||
                                    wifi.wifi_location.latitude == '' ||
                                    wifi.wifi_location.latitude == 'null'
                                  )
                                )
                              "
                              >mdi-wifi</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            :draggable="
                              !wifi.wifi_location ||
                              wifi.wifi_location.latitude == null ||
                              wifi.wifi_location.latitude == '' ||
                              wifi.wifi_location.latitude == 'null'
                            "
                            :class="
                              'map_item_content_' +
                              String(
                                !wifi.wifi_location ||
                                  wifi.wifi_location.latitude == null ||
                                  wifi.wifi_location.latitude == '' ||
                                  wifi.wifi_location.latitude == 'null'
                              )
                            "
                            @dragend="select_hardware(wifi, 'wifi')"
                          >
                            <v-list-item-title
                              v-if="
                                wifi.wifi_location &&
                                !(
                                  wifi.wifi_location.latitude == null ||
                                  wifi.wifi_location.latitude == '' ||
                                  wifi.wifi_location.latitude == 'null'
                                )
                              "
                              @mouseenter="set_marker('wifi ' + (i + 1))"
                              @mouseleave="set_marker(false)"
                              class="map_sidebar_item_name"
                              v-text="'wifi ' + (i + 1)"
                            ></v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="map_sidebar_item_name"
                              v-text="'wifi ' + (i + 1)"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>

                      <v-list-group v-if="jetsons && jetsons.length">
                        <template v-slot:activator>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-router-wireless</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="group_title"
                            >Jetsons</v-list-item-title
                          >
                        </template>

                        <v-list-item v-for="(jetson, i) in jetsons" :key="i">
                          <v-list-item-icon>
                            <v-menu
                              offset-y
                              v-if="
                                jetson.jetson_location &&
                                !(
                                  jetson.jetson_location.latitude == null ||
                                  jetson.jetson_location.latitude == '' ||
                                  jetson.jetson_location.latitude == 'null'
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="
                                    get_color(jetson.jetson_map_icon_color)
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="select_color(jetson, 'jetson')"
                                  >mdi-router-wireless</v-icon
                                ></template
                              >
                              <v-card
                                ><v-color-picker
                                  show-swatches
                                  :swatches="swatches"
                                  class="map_icon_color_picker"
                                  v-model="selected_color"
                                >
                                </v-color-picker>

                                <v-btn
                                  class="mb-3 ml-3 mt-n2"
                                  depressed
                                  @click="
                                    remove_map_marker(
                                      jetson.id,
                                      'jetson'
                                    )
                                  "
                                  ><v-icon color="primary"
                                    >mdi-map-marker-remove</v-icon
                                  ></v-btn
                                >
                              </v-card>
                            </v-menu>
                            <v-icon
                              color="gray"
                              class="non_placed_hardware"
                              v-if="
                                !(
                                  jetson.jetson_location &&
                                  !(
                                    jetson.jetson_location.latitude == null ||
                                    jetson.jetson_location.latitude == '' ||
                                    jetson.jetson_location.latitude == 'null'
                                  )
                                )
                              "
                              >mdi-router-wireless</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            :draggable="
                              !jetson.jetson_location ||
                              jetson.jetson_location.latitude == null ||
                              jetson.jetson_location.latitude == '' ||
                              jetson.jetson_location.latitude == 'null'
                            "
                            :class="
                              'map_item_content_' +
                              String(
                                !jetson.jetson_location ||
                                  jetson.jetson_location.latitude == null ||
                                  jetson.jetson_location.latitude == '' ||
                                  jetson.jetson_location.latitude == 'null'
                              )
                            "
                            @dragend="select_hardware(jetson, 'jetson')"
                          >
                            <v-list-item-title
                              v-if="
                                jetson.jetson_location &&
                                !(
                                  jetson.jetson_location.latitude == null ||
                                  jetson.jetson_location.latitude == '' ||
                                  jetson.jetson_location.latitude == 'null'
                                )
                              "
                              @mouseenter="set_marker('jetson ' + (i + 1))"
                              @mouseleave="set_marker(false)"
                              class="map_sidebar_item_name"
                              v-text="'jetson ' + (i + 1)"
                            ></v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="map_sidebar_item_name"
                              v-text="'jetson ' + (i + 1)"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                    <v-col class="pt-0" v-if="site_map_info"
                      >
                      <div
                    style="
                        position: absolute;
                        z-index: 4;
                        bottom: 52px;
                        right: 72px;
                        background-color: white;
                        border-radius: 2px;
                      " class="pa-1 elevation-1">Zoom: {{zoom}}</div>
                    <div
                      style="
                        width: 200px;
                        position: absolute;
                        z-index: 4;
                        top: 25px;
                        right: 72px;
                        background-color: white;
                        border-radius: 25px;
                      "
                    >
                      <v-select
                        hide-details
                        dense
                        class="assign_select font_400"
                        outlined
                        rounded
                        height="32"
                        style=""
                        full-width
                        v-if="site_selection"
                        v-model="selected_site"
                        :items="site_selection"
                        label="Site"
                      >
                      </v-select>
                    </div>
                      <GmapMap
                        class="settings_map"
                        :center="{
                          lat: parseFloat(site_map_info.location.latitude) || 0,
                          lng: parseFloat(site_map_info.location.longitude) || 0,
                        }"
                        :zoom="site_map_info.zoom"
                        map-type-id="hybrid"
                        style="height: 600px"
                        @mouseover="mark($event)"
                        @mouseout="mark('unmark')"
                        @zoom_changed="get_zoom"
                        @center_changed="get_center"
                        :options="{
                          mapTypeControl: true,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                        }"
                        ref="map"
                      >
                        <GmapMarker
                          :key="index + m[0].position"
                          v-for="(m, index) in hardware_markers"
                          :position="m[0].position"
                          :icon="m[0].icon"
                          :draggable="map_distances.length == 0"
                          @dragend="
                            update_camera_location(
                              m[1],
                              $event.latLng,
                              m[2]
                            )
                          "
                          @click="get_distances(m[0], m[2])"
                          @mouseover="set_marker(m[0].title)"
                          @mouseout="set_marker(false)"
                          ><GmapInfoWindow
                            :opened="
                              open_marker == m[0].title &&
                              map_distances.length == 0
                            "
                            ><h3>{{ m[0].title }}</h3>
                            <span v-if="m[2] == 'camera'">{{
                              m[0].model
                            }}</span> </GmapInfoWindow
                          ><GmapInfoWindow
                            :opened="m[2] == 'wifi' && map_distances.length > 0"
                          >
                            <span class="distance_list_close"
                              ><h3>{{ m[0].title }}</h3>
                              <v-icon @click="map_distances = []"
                                >mdi-close</v-icon
                              ></span
                            >
                            <v-list
                              v-if="map_distances"
                              width="280"
                              max-height="300"
                            >
                              <v-list-item
                                v-for="(camera, i) in map_distances"
                                :key="i"
                                class="pa-1 pb-0 pt-0 distance_list_item"
                              >
                                <v-list-item-icon class="mt-0 pt-1 mb-0 mr-4">
                                  <v-icon :color="get_color(camera[0].color)"
                                    >mdi-cctv</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content
                                  class="pt-0 pb-4 distance_list_content"
                                >
                                  <v-list-item-title
                                    class="map_sidebar_item_name"
                                  >
                                    <span class="distance_list_title">{{
                                      camera[0].title
                                    }}</span>
                                    <span class="nav_arrows_f">
                                      {{ camera[1] + " m" }}</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </GmapInfoWindow>
                        </GmapMarker>
                      </GmapMap></v-col
                    >
                    <v-col class="pt-0" v-else>
                      <div style="height: 600px; text-align: center; line-height: 600px; font-size: xx-large">No Site Information</div>
                    </v-col>
                  </v-row>
                  <div v-else><v-autocomplete
                      class="select_customer mr-3"
                      :items="customers"
                      v-model="customer_select"
                      dense
                      flat
                      hide-details
                      :menu-props="{ offsetOverflow: false }"
                    ></v-autocomplete
                  ></div>
                  </v-card-text
                ></v-card
              >
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <!-- <events></events> -->
  </div>
</template>

<script>
// import firebase from '../firebaseConfig';

// connect to firestore using firebase config
// const db = firebase.firestore();
//import events from "@/components/Events.vue";

//var AWSCognito = require("amazon-cognito-identity-js");
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import Vue from "vue";
import { eventBus } from '../main';
import customerFilter from '../components/CustomerFilter.vue';

// @vuese
// @group Views
export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
    customerFilter
    //events,
  },
  props: ["setting"],
  data() {
    return {
      map_distances: [],
      open_marker: false,
      color_device: null,
      color_device_type: null,
      swatches: [
        ["#007AFF", "#5856D6", "#FF3A30"],
        ["#00C7BE", "#AF52DE", "#FF9500"],
        ["#34C759", "#FF2D54", "#FFCC00"],
      ],
      selected_color: "#007AFF",
      mark_lat: null,
      mark_long: null,
      selected_site: 0,
      dialog: false,
      inf_map: 2,
      site_operation_type_comp: null,
      site_community_pasture_comp: null,
      site_community_pasture_names_comp: null,
      markers_comp: null,

      old_password: "",
      new_password: "",
      confirm_password: "",
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        spec_char: (v) =>
          /^(?=.*[!@#$%^&_*]).{8,}$/.test(v) ||
          "Your password must contain at least one special character.",
        cap_let: (v) =>
          /^(?=.*[A-Z]).{8,}$/.test(v) ||
          "Your password must contain at least one capital letter.",
        number: (v) =>
          /^(?=.*[0-9]).{8,}$/.test(v) ||
          "Your password must contain at least one number.",
      },
      customer_select: "All Customers", 
      first_load_customer: true,
      map_customer_prev: "",
      error: "",

      default_coords: false,
      current_zoom_coords: {},
      zoom: 0
    };
  },
  computed: {
      customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return {
                text: item.customer_first_name + " " + item.customer_last_name,
                value: item.id,
              };
            } catch {
              return "null";
            }
          })
        ),
      ];

      customers = this.sortByKey([...customers], "text");
      customers.unshift({ text: "All Customers", value: "All Customers" });

      return customers;
    },
    sites() {
        if (!this.$store.getters.getCustomer.sites) return false;
      return JSON.parse(
        JSON.stringify(this.$store.getters.getCustomer.sites.items)
      );
    },
    site_selection(){
      return this.$store.getters.getCustomer.sites.items.map((e, i)=>{
        return {
          text: e.site_name,
          value: i
        }
      })
    },
    site_map_info() {
      var zoom = 17;
      if (this.sites.length>0) {
        if (this.sites[this.selected_site].site_map_zoom_level) {
          zoom = this.sites[this.selected_site].site_map_zoom_level;
        }
        if(
          this.sites[this.selected_site].site_location &&
          this.sites[this.selected_site].site_location.latitude &&
          !isNaN(this.sites[this.selected_site].site_location.latitude) &&
          this.sites[this.selected_site].site_location.longitude &&
          !isNaN(this.sites[this.selected_site].site_location.longitude)
        ){
          return {
            location: this.sites[this.selected_site].site_location,
            zoom: zoom,
          };
        }else{
          return {
            location: {
              latitude: 0,
              longitude: 0
            },
            zoom: 1
          };
        }
      } else {
        return {
            location: {
              latitude: 0,
              longitude: 0
            },
            zoom: 1
          };
      }
    },
    cameras() {
      if (!this.$store.getters.getCustomer.cameras) return false;
       var cameras = this.sortByNested([...this.$store.getters.getCustomer.cameras.items], "camera_name");
      return cameras;
    },
    wifis() {
        if (!this.$store.getters.getCustomer.wifi) return false;
      return this.$store.getters.getCustomer.wifi.items;
    },
    jetsons() {
        if (!this.$store.getters.getCustomer.jetsons) return false;
      return this.$store.getters.getCustomer.jetsons.items;
    },

    match_pass_old() {
      return () =>
        this.new_password !== this.old_password ||
        "New password is identical to old.";
    },
    match_pass() {
      return () =>
        this.confirm_password === this.new_password ||
        "Confirmation passwords doesn't match.";
    },
    hardware_markers: {
      get: function () {
        if (this.markers_comp != null) {
          return this.markers_comp;
        }
        var markers = [];
        if (this.$store.getters.getCustomer.sites.items) {
          for (const i in this.$store.getters.getCustomer.cameras.items) {
            if (
              this.$store.getters.getCustomer.cameras.items[i]
                .camera_location &&
              this.$store.getters.getCustomer.cameras.items[i].camera_location
                .latitude != "null" &&
              this.$store.getters.getCustomer.cameras.items[i].camera_location
                .latitude &&
              !isNaN(this.$store.getters.getCustomer.cameras.items[i].camera_location
                .latitude) &&
              this.$store.getters.getCustomer.cameras.items[i].camera_location
                .longitude != "null" &&
              this.$store.getters.getCustomer.cameras.items[i].camera_location
                .longitude &&
              !isNaN(this.$store.getters.getCustomer.cameras.items[i].camera_location
                .longitude)
            ) {
              var camera_icon = "ORANGE";
              if (
                this.$store.getters.getCustomer.cameras.items[i]
                  .camera_map_icon_color
              ) {
                camera_icon =
                  this.$store.getters.getCustomer.cameras.items[i]
                    .camera_map_icon_color;
              }
              markers.push([
                {
                  position: {
                    lat: parseFloat(
                      this.$store.getters.getCustomer.cameras.items[i]
                        .camera_location.latitude
                    ) ?? 0,
                    lng: parseFloat(
                      this.$store.getters.getCustomer.cameras.items[i]
                        .camera_location.longitude
                    ) ?? 0,
                  },
                  icon:
                    "https://map-icons-onecup.s3.us-west-2.amazonaws.com/camera_" +
                    camera_icon.toLowerCase() +
                    ".png",
                  title:
                    this.$store.getters.getCustomer.cameras.items[i]
                      .camera_name,
                  thumbnail:
                    this.$store.getters.getCustomer.cameras.items[i]
                      .id+'_lastest',
                  color:
                    this.$store.getters.getCustomer.cameras.items[i]
                      .camera_map_icon_color,
                },
                this.$store.getters.getCustomer.cameras.items[i].id,
                "camera",
              ]);
            }
          }
          for (const i in this.$store.getters.getCustomer.wifi.items) {
            if (
              this.$store.getters.getCustomer.wifi.items[i].wifi_location &&
              this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .latitude != "null" &&
              this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .latitude &&
              !isNaN(this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .latitude) &&
              this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .longitude != "null" &&
              this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .longitude &&
              !isNaN(this.$store.getters.getCustomer.wifi.items[i].wifi_location
                .longitude)
            ) {
              var wifi_icon = "ORANGE";
              if (
                this.$store.getters.getCustomer.wifi.items[i]
                  .wifi_map_icon_color
              ) {
                wifi_icon =
                  this.$store.getters.getCustomer.wifi.items[i]
                    .wifi_map_icon_color;
              }
              markers.push([
                {
                  position: {
                    lat: parseFloat(
                      this.$store.getters.getCustomer.wifi.items[i]
                        .wifi_location.latitude
                    ) ?? 0,
                    lng: parseFloat(
                      this.$store.getters.getCustomer.wifi.items[i]
                        .wifi_location.longitude
                    ) ?? 0,
                  },
                  icon:
                    "https://map-icons-onecup.s3.us-west-2.amazonaws.com/wifi_" +
                    wifi_icon.toLowerCase() +
                    ".png",
                  title: "wifi " + String(parseInt(i) + parseInt(1)),
                },
                this.$store.getters.getCustomer.wifi.items[i].id,
                "wifi",

              ]);
            }
          }
          for (const i in this.$store.getters.getCustomer.jetsons.items) {
            if (
              this.$store.getters.getCustomer.jetsons.items[i]
                .jetson_location &&
              this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .latitude != "null" &&
              this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .latitude &&
              !isNaN(this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .latitude) &&
              this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .longitude != "null" &&
              this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .longitude &&
              !isNaN(this.$store.getters.getCustomer.jetsons.items[i].jetson_location
                .longitude)
            ) {
              var jetson_icon = "ORANGE";
              if (
                this.$store.getters.getCustomer.jetsons.items[i]
                  .jetson_map_icon_color
              ) {
                jetson_icon =
                  this.$store.getters.getCustomer.jetsons.items[i]
                    .jetson_map_icon_color;
              }
              markers.push([
                {
                  position: {
                    lat: parseFloat(
                      this.$store.getters.getCustomer.jetsons.items[i]
                        .jetson_location.latitude
                    ) ?? 0,
                    lng: parseFloat(
                      this.$store.getters.getCustomer.jetsons.items[i]
                        .jetson_location.longitude
                    ) ?? 0,
                  },
                  icon:
                    "https://map-icons-onecup.s3.us-west-2.amazonaws.com/betsy_" +
                    jetson_icon.toLowerCase() +
                    ".png",
                  title: "jetson " + String(parseInt(i) + parseInt(1)),
                },
                this.$store.getters.getCustomer.jetsons.items[i].id,
                "jetson",
              ]);
            }
          }
          // var site_map_zoom_level = 18;
          // if (this.$store.getters.getSite[0].site_map_zoom_level) {
          //   site_map_zoom_level =
          //     this.$store.getters.getSite[0].site_map_zoom_level;
          // }
          markers = this.sortByNested([...markers], "0.position.lng");

          return markers;
        } else {
          return false;
        }
      },
      set: function (value) {
        if (this.markers_comp == null) {
          this.markers_comp = this.hardware_markers;
        }
        // var marker = [{position: {lat: parseFloat(this.mark_lat), long: parseFloat(this.mark_long), icon: "https://map-icons-onecup.s3.us-west-2.amazonaws.com/camera_" +
        //             value[1] +
        //             ".png", title}}]
        // this.markers_comp.push(value)
        console.log(value);
      },
    },
  },
  methods: {
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    async update_default_site_location() {
      console.log(this.default_coords)
      await API.graphql({
        query: mutations.updateSite,
        variables: {
          input: this.default_coords,
        }
      });
   
      this.default_coords = false;
      this.$store.dispatch("DDB_GET_SITES");
    },
    get_center(center) {
      if(this.map_customer_prev!=this.customer_select){
        this.map_customer_prev = this.customer_select;
        return;
      }
      this.current_zoom_coords.coords = { latitude: center.lat(), longitude: center.lng() }
      // console.log(this.customer_select);
      this.default_coords = {
        site_map_zoom_level: this.current_zoom_coords.zoom,
        site_location: { latitude: center.lat(), longitude: center.lng() },
        id: this.sites[this.selected_site].id
      };
    },
    
    get_zoom(zoom) {
      if(this.map_customer_prev!=this.customer_select){
        this.map_customer_prev = this.customer_select;
        return;
      }

      this.zoom = zoom;
      this.current_zoom_coords.zoom = zoom
      // console.log(this.customer_select);
      this.default_coords = {
        site_map_zoom_level: zoom,
        site_location: this.current_zoom_coords.coords,
        id: this.sites[this.selected_site].id
      };
    },
    zoomout() {
      try {
        var bounds = this.$refs.map.$mapObject.getBounds();
        for (const i in this.hardware_markers) {
          bounds.extend(this.hardware_markers[i][0].position);
        }
        this.$refs.map.fitBounds(bounds);
      } catch (error) {
        console.log(error);
      }
    },
    get_single_distance(w, c) {
      var p = 0.017453292519943295; // Math.PI / 180
      var mcos = Math.cos;
      var a =
        0.5 -
        mcos((c.lat - w.lat) * p) / 2 +
        (mcos(w.lat * p) * mcos(c.lat * p) * (1 - mcos((c.lat - w.lat) * p))) /
          2;

      return parseInt(12742000 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    },
    get_distances(obj, dt) {
      if (dt == "wifi") {
        var distance_array = [];
        for (const i in this.hardware_markers) {
          if (this.hardware_markers[i][2] == "camera") {
            distance_array.push([
              this.hardware_markers[i][0],
              this.get_single_distance(
                obj.position,
                this.hardware_markers[i][0].position
              ),
            ]);
          }
        }
        distance_array.sort(
          (function (index) {
            return function (b, a) {
              return a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1;
            };
          })(1)
        );
        this.map_distances = distance_array;
      }
    },
    set_marker(marker) {
      if (marker) {
        this.open_marker = marker;
      } else {
        this.open_marker = false;
      }
    },
    async remove_map_marker(id, device_type) {
      try {
        if (device_type == "camera") {
          var cam_object = {
            id: id,
            camera_location: {
              latitude: null,
              longitude: null,
            },
            updatedBy: this.$store.getters.getUser.username,

          };
          await API.graphql({
            query: mutations.updateCamera,
            variables: {
              input: cam_object,
            },
          });
        } else if (device_type == "wifi") {
          var wifi_object = {
            id: id,
            wifi_location: {
              latitude: null,
              longitude: null,
            },
            updatedBy: this.$store.getters.getUser.username,

          };
          await API.graphql({
            query: mutations.updateWifi,
            variables: {
              input: wifi_object,
            },
          });
        } else if (device_type == "jetson") {
          var jetson_object = {
            id: id,
            jetson_location: {
              latitude: null,
              longitude: null,
            },
            updatedBy: this.$store.getters.getUser.username,
          };
          await API.graphql({
            query: mutations.updateJetson,
            variables: {
              input: jetson_object,
            },
          });
        }
        this.$store.dispatch(
          "DDB_GET_SELECTED_CUSTOMER",
          Vue.prototype.$selectCustomer
        );
      } catch (err) {
        this.error = err.errors[0].message;
      }
    },
    select_color(device, device_type) {
      this.color_device = device;
      this.color_device_type = device_type;
      console.log(device);
      let col_object = {
        BLUE: "#007AFF",
        MINT: "#00C7BE",
        GREEN: "#34C759",
        INDIGO: "#5856D6",
        PURPLE: "#AF52DE",
        PINK: "#FF2D54",
        RED: "#FF3A30",
        ORANGE: "#FF9500",
        YELLOW: "#FFCC00",
      };
      if (device_type == "camera") {
        if (device.camera_map_icon_color) {
          this.selected_color = col_object[device.camera_map_icon_color];
        } else {
          this.selected_color = col_object["ORANGE"];
        }
      } else if (device_type == "wifi") {
        if (device.wifi_map_icon_color) {
          this.selected_color = col_object[device.wifi_map_icon_color];
        } else {
          this.selected_color = col_object["ORANGE"];
        }
      } else if (device_type == "jetson") {
        if (device.jetson_map_icon_color) {
          this.selected_color = col_object[device.jetson_map_icon_color];
        } else {
          this.selected_color = col_object["ORANGE"];
        }
      }
    },
    select_hardware(device, device_type) {
      let _this = this;
      setTimeout(function () {
        _this.update_camera_location(
          device.id,
          null,
          device_type,

        );
      }, 100);
    },
    async mark(event) {
      if (event == "unmark") {
        this.mark_lat = null;
        this.mark_long = null;
      } else {
        this.mark_lat = event.latLng.lat();
        this.mark_long = event.latLng.lng();
      }
    },
    async update_camera_location(id, location, device) {
      if (device == "camera") {
        var cam_object = {};
        if (location != null) {
          cam_object = {
            id: id,
            camera_location: {
              latitude: location.lat(),
              longitude: location.lng(),
            },
            updatedBy: this.$store.getters.getUser.username,

          };
        } else {
          if (this.mark_lat != null && this.mark_long != null) {
            cam_object = {
              id: id,
              camera_location: {
                latitude: this.mark_lat,
                longitude: this.mark_long,
              },
              updatedBy: this.$store.getters.getUser.username,

            };
            this.mark_lat = null;
            this.mark_long = null;
          }
        }
        if (cam_object.id) {
          try {
            await API.graphql({
              query: mutations.updateCamera,
              variables: {
                input: cam_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors);
          }
        }
      } else if (device == "wifi") {
        var wifi_object = {};
        if (location != null) {
          wifi_object = {
            id: id,
            wifi_location: {
              latitude: location.lat(),
              longitude: location.lng(),
            },
            updatedBy: this.$store.getters.getUser.username,

          };
        } else {
          if (this.mark_lat != null && this.mark_long != null) {
            wifi_object = {
              id: id,
              wifi_location: {
                latitude: this.mark_lat,
                longitude: this.mark_long,
              },
              updatedBy: this.$store.getters.getUser.username,

            };
            this.mark_lat = null;
            this.mark_long = null;
          }
        }
        if (wifi_object.id) {
          try {
            await API.graphql({
              query: mutations.updateWifi,
              variables: {
                input: wifi_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors[0].message);
          }
        }
      } else if (device == "jetson") {
        var jetson_object = {};
        if (location != null) {
          jetson_object = {
            id: id,
            jetson_location: {
              latitude: location.lat(),
              longitude: location.lng(),
            },
            updatedBy: this.$store.getters.getUser.username,

          };
        } else {
          if (this.mark_lat != null && this.mark_long != null) {
            jetson_object = {
              id: id,
              jetson_location: {
                latitude: this.mark_lat,
                longitude: this.mark_long,
              },
              updatedBy: this.$store.getters.getUser.username,

            };
            this.mark_lat = null;
            this.mark_long = null;
          }
        }
        if (jetson_object.id) {
          try {
            await API.graphql({
              query: mutations.updateJetson,
              variables: {
                input: jetson_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors[0].message);
          }
        }
      }
    },
    get_color(color) {
      if (color) {
        if (color == "MINT") {
          return "#00C7BE";
        }
        return color.toLowerCase();
      }
      return "orange";
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });

      return array;
    },
  },
  watch: {
      customer_select() {
      if (this.customer_select != "All Customers") {
        this.$store.dispatch("DDB_GET_SELECTED_CUSTOMER", this.customer_select);
      }
      Vue.prototype.$selectCustomer = this.customer_select
      if (!(this.customer_select == 'All Customers') && !(this.customer_select == undefined)) {
        eventBus.$emit("customer_selected", true)
      } else {
        eventBus.$emit("customer_selected", false)
      }
      if(this.first_load_customer){
        this.map_customer_prev = this.customer_select;
        this.first_load_customer = false;
      }
      this.default_coords = false;
    },
    inf_map() {
      this.error = "";
      // if (this.inf_map == 2) {
      //   setTimeout(this.zoomout, 1000);
      // }
    },
    async selected_color() {
      let col_object = {
        "#007AFF": "BLUE",
        "#00C7BE": "MINT",
        "#34C759": "GREEN",
        "#5856D6": "INDIGO",
        "#AF52DE": "PURPLE",
        "#FF2D54": "PINK",
        "#FF3A30": "RED",
        "#FF9500": "ORANGE",
        "#FFCC00": "YELLOW",
      };
      if (this.color_device_type == "camera") {
        if (
          this.color_device.camera_map_icon_color !=
          col_object[this.selected_color]
        ) {
          try {
            var cam_object = {
              id: this.color_device.id,
              camera_map_icon_color: col_object[this.selected_color],
              updatedBy: this.$store.getters.getUser.username,

            };
            await API.graphql({
              query: mutations.updateCamera,
              variables: {
                input: cam_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors[0].message);
          }
        }
      } else if (this.color_device_type == "wifi") {
        if (
          this.color_device.wifi_map_icon_color !=
          col_object[this.selected_color]
        ) {
          try {
            var wifi_object = {
              id: this.color_device.id,
              wifi_map_icon_color: col_object[this.selected_color],
              updatedBy: this.$store.getters.getUser.username,

            };

            await API.graphql({
              query: mutations.updateWifi,
              variables: {
                input: wifi_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors[0].message);
          }
        }
      } else if (this.color_device_type == "jetson") {
        if (
          this.color_device.jetson_map_icon_color !=
          col_object[this.selected_color]
        ) {
          try {
            var jetson_object = {
              id: this.color_device.id,
              jetson_map_icon_color: col_object[this.selected_color],
              updatedBy: this.$store.getters.getUser.username,
            };
            await API.graphql({
              query: mutations.updateJetson,
              variables: {
                input: jetson_object,
              },
            });
            this.$store.dispatch(
              "DDB_GET_SELECTED_CUSTOMER",
              Vue.prototype.$selectCustomer
            );
          } catch (err) {
            console.log(err.errors[0].message);
          }
        }
      }
    },
    site_map_info: {
      handler(){
      this.zoom = this.site_map_info.zoom;
      },
      deep: true
    }
  },
  mounted() {
    if (this.setting == "user") {
      this.inf_map = 0;
    } else if (this.setting == "sites") {
      this.inf_map = 1;
    } else if (this.setting == "hardware") {
      this.inf_map = 2;
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    //setTimeout(this.zoomout, 2000);
  },
};
</script>

<style lang="css">
.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}
.settings_map {
  pointer-events: inherit !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
