<template>
  <!-- v-for="(p, index) in image_paths" -->
  <div class="pa-0 grid_container_f" :class="age(vid.video_time)[1]" ref="image_container">
    <div class="image_overlay_area" 
    ref="star_div"
    @click="open_image()"
        @mouseenter="onEnterImg()"
        @mouseleave="onLeaveImg()"
    >
      <v-icon :disabled="disabled" @click="toggle_select" color="primary" class="image_star" ref="star">{{ star_icon }}</v-icon>
    </div>
    <div class="video_thumbnail_annotation_area" ref="mainCanvas_div">
      <canvas class="video_thumbnail_main_canvas" ref="mainCanvas"></canvas>
    </div>
    <vue-load-image class="vue_load_image" @onLoad="startCanvas()">
      <img
        slot="image"
        alt=""
        :src="video_thumbnail"
        class="grid_img_f"
        ref="image"
        @error="had_error = true"
      />
      <img slot="preloader" class="grid_img_f" src="@/assets/loader.png" />
      <img slot="error" ref="image" class="grid_img_f" src="https://onecup-assets-public.s3.us-west-2.amazonaws.com/images/placeholder/no-image_480x270.jpg" />
    </vue-load-image>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";
import { eventBus } from "../main";
import moment from "moment";

// @vuese
// @group Components
// The clickable thumbnail for videos
export default {
  props: {
    // The video object
    vid: Object,
    // The filter object
    filters: Object,
    // Turn on/off the border
    bordered: Boolean,
    // The index of the video in the Videos page
    vid_index: Number,
    // Turn on/off the bounding boxes
    show_bbox: Boolean,
    // Turn on/off the hover effect
    show_hover: Boolean,
    // Disable the select functionality
    disabled: Boolean
  },
  components: {
    "vue-load-image": VueLoadImage,
  },
  data(){
    return {
      star_icon: "mdi-checkbox-blank-outline",
      selected: false,
      had_error: false
    }
  },
  watch:{
    show_bbox(){
      this.updateCanvas();
    }
  },
  methods: {
    alternatePath(p){
      if(p.includes("ftp")){
          return p;
      }
      var arr = p.split('/')
      arr.splice(1, 0, 'ftp')
      return arr.join('/');
    },
    toggle_select(e){
      e?.stopPropagation();
      this.selected = !this.selected;
      if(this.selected){
        this.star_icon = "mdi-checkbox-marked";
      }else{
        this.star_icon = "mdi-checkbox-blank-outline";
      }
      this.$emit("select", this.vid.image_path);
    },
    open_image() {
      eventBus.$emit("open_dialog", this.vid_index);
    },
    startCanvas(){
        this.$nextTick(()=>{
            const resizeObserver = new ResizeObserver(() => {
                //this.updateCanvas();
            });

            resizeObserver.observe(this.$refs.image);
        });
    },
    onEnterImg(){
      if(!this.show_hover){
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var star = this.$refs.star?.$el;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if(image_container){
        image_container.style.zIndex = "99"
      }

      if(image){
        image.style.transform = "scale(1.3)";
        image.style.boxShadow = "0 0 10px black";
        image.style.zIndex = "99";
      }

      if(canvas&&canvas_div){
        canvas_div.style.zIndex = "100"
        canvas.style.zIndex = "100"

        canvas.style.transform = "scale(1.3)";
      }

      if(star_div && star){
        star_div.style.zIndex = "101";

        star_div.style.transform = "scale(1.3)";
      }
    },
    onLeaveImg(){
      if(!this.show_hover){
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var star = this.$refs.star?.$el;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if(image_container){
        image_container.style.zIndex = null
      }

      if(image){
        image.style.transform = null;
        image.style.boxShadow = null;
        image.style.zIndex = null;
      }

      if(canvas&&canvas_div){
        canvas_div.style.zIndex = "1";
        canvas.style.zIndex = null;

        canvas.style.transform = null;
      }

      if(star_div && star){
        star_div.style.zIndex = "1";

        star_div.style.transform = null;
      }
    },
    updateCanvas() {
        var canvas = this.$refs.mainCanvas;
        if(!canvas){
            return;
        }
        canvas.style.width = this.$refs.image.clientWidth + 'px';
        canvas.style.height = this.$refs.image.clientHeight + 'px';
        canvas.width = this.$refs.image.clientWidth;
        canvas.height = this.$refs.image.clientHeight;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if(!this.show_bbox){
          return;
        }
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        this.drawBBOX(ctx, this.vid.bbox_list);
    },
    scale(coord) {
      var json_width = 2560;
      var canvas_width = this.$refs.mainCanvas.width;
      return Math.round(coord * (canvas_width / json_width));
    },
    drawBBOX(ctx, arr) {
      for (let i = 0; i < arr.length; i++) {
     
        var x1 = this.scale(arr[i].x1);
        var y1 = this.scale(arr[i].y1);
        var w = this.scale(arr[i].w);
        var h = this.scale(arr[i].h);
        ctx.fillStyle = this.$store.getters.getBBOXColorCode[arr[i].type]?.fillStyle || "rgba(60, 179, 113, 0.4)";
        ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 1;
        ctx.strokeStyle = this.$store.getters.getBBOXColorCode[arr[i].type]?.strokeStyle ||"rgba(60, 179, 113)";
        ctx.strokeRect(x1, y1, w, h);
      }
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
  },
  mounted() {
    // this.updateCanvas();
  },
  created(){
    eventBus.$on("select_all_videos", (has_selected_all)=>{
      if(this.selected && !has_selected_all){
        return;
      }
      this.toggle_select();
    });
    eventBus.$on("select_videoe", (video_path)=>{
      if(video_path == this.vid.video_path){
        this.toggle_select();
      }
    })
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    video_thumbnail(){
      // const previewImgNumber = Math.max(
      //   1,
      //   Math.floor(this.vid.num_frames/this.vid.fps / 10)
      // );
      // var arr = this.vid.video_path.split('/');
      // arr.splice(5, 0, 'hls')
    //  var video_path = arr.join('/');
    
      // const video_path = this.vid.video_path;
      // const baseTransform = video_path + "0.jpg";
      // const lastSlashIndex = baseTransform.lastIndexOf('/');
      // const thumbnailPath = baseTransform.substring(0, lastSlashIndex + 1) + "hls/" + baseTransform.substring(lastSlashIndex + 1);
      // return this.cookie_bucket + thumbnailPath

      if(!this.had_error){
        return this.cookie_bucket + this.vid.video_path + '_480x270.jpg'
       
      }else{
        return this.cookie_bucket + this.alternatePath(this.vid.video_path) + '_480x270.jpg'
      }
    },
  },
};
</script>

<style scoped>
.grid_container_f {
  position: relative;
  text-align: center;
  /* min-height: 130px; */
  background: black;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.1s;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}

.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}

.video_thumbnail_annotation_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.video_thumbnail_main_canvas {
  pointer-events: none;
  transition-duration: 0.1s;
}

.image_overlay_area{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: 0.1s;
}

.image_star{
  float: right;
}

.no-image{
  min-height: 130px;
}

.vue_load_image{
  margin-bottom: -7px !important
}
</style>