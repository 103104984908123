<template>
	<div>
		<navbar
			@toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
		></navbar>
		<sidenav ref="drawer"></sidenav>
		<v-container>
			<v-row justify="center">
				<v-col cols="12">
					<v-card class="pl-3 pr-3">
						<v-row>
							<v-tabs class="table_tabs ml-3 mt-3">
								<v-tab to="/">Overview</v-tab>
								<v-tab to="/footageuploads">Cameras</v-tab>
								<v-tab to="/inference">Inference</v-tab>
								<v-tab to="/services">Services</v-tab>
								<v-tab to="/system">System</v-tab>
								<v-tab to="/triton">Triton</v-tab>
							</v-tabs>
						</v-row>
						<v-container class="ml-3">
							<v-row no-gutters>
								<v-tabs v-model="selected_view" class="table_tabs">
									<v-tab>Overview - System</v-tab>
									<v-tab>Overview - Network</v-tab>
									<v-tab>Overview - Disk</v-tab>
									<v-tab>Details</v-tab>
								</v-tabs>
								<v-col cols="2" class="mr-3">
									<v-select
										v-model="time_range"
										label="Time Range"
										:items="time_ranges"
									></v-select>
								</v-col>
								<!-- <v-col cols="2" class="mr-3" v-if="selected_view==1">
									<v-select
										v-model="server"
										label="Server"
										:items="server_selection_list"
										:loading="server_loading"
									></v-select>
								</v-col> -->

							</v-row>
							<v-row no-gutters v-if="selected_view==3">
								<v-tabs v-model="selected_server" class="table_tabs" show-arrows>
									<v-tab v-for="s in server_list" :key="s+'_tab'">
										{{ s.replace('onecup', '') }}
									</v-tab>
								</v-tabs>
								<div class="mt-3">
								<v-icon @click="previousServer">mdi-chevron-left</v-icon>
								<v-icon @click="nextServer" class="mr-3">mdi-chevron-right</v-icon>
								</div>
							</v-row>
							<v-container v-if="selected_view==0">
								<v-row v-for="(s, index) in server_list" :key="s+'_row'">
									<v-col cols="12" class="pl-0 pb-0" :class="{'pt-0': index==0}">
										<h2>{{ s.replace('onecup', '').toUpperCase() }}</h2>
										<v-divider></v-divider>
									</v-col>
									<v-col cols="12" class="pl-0 pb-0">
										<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+s+'\''"
										panelId="2"
										height="300"
										></grafana-graph>
									</v-col>
								</v-row>
							</v-container>
							<v-container v-if="selected_view==1">
								<v-row v-for="(s, index) in server_list" :key="s+'_row'">
									<v-col cols="12" class="pl-0 pb-0" :class="{'pt-0': index==0}">
										<h2>{{ s.replace('onecup', '').toUpperCase() }}</h2>
										<v-divider></v-divider>
									</v-col>
									<v-col cols="12" class="pl-0 pb-0">
										<grafana-graph
											:from="time_settings[time_range].from"
											:to="time_settings[time_range].to"
											:database="database"
											:table="table"
											:dashboard="dashboard"
											:server="'\''+s+'\''"
											panelId="13"
											height="300"
										></grafana-graph>
									</v-col>
								</v-row>
							</v-container>
							<v-container v-if="selected_view==2">
								<v-row v-for="(s, index) in server_list" :key="s+'_row'">
									<v-col cols="12" class="pl-0 pb-0" :class="{'pt-0': index==0}">
										<h2>{{ s.replace('onecup', '').toUpperCase() }}</h2>
										<v-divider></v-divider>
									</v-col>
									<v-col cols="12" class="pl-0 pb-0">
										<grafana-graph
											:from="time_settings[time_range].from"
											:to="time_settings[time_range].to"
											:database="database"
											:table="table"
											:dashboard="dashboard"
											:server="'\''+s+'\''"
											panelId="21"
											height="300"
										></grafana-graph>
									</v-col>
								</v-row>
							</v-container>
							<v-row v-if="!!server && selected_view == 3">
								<v-col cols="2" class="pl-0 pb-0" v-if="server == 'server'">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="19"
										height="150"
									></grafana-graph>
								</v-col>
								<!-- <v-col cols="2" class="pl-0 pb-0" v-if="server != 'server'">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="16"
										height="150"
									></grafana-graph>
								</v-col> -->
								<v-col cols="2" class="pl-0 pb-0" v-if="server != 'server'">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="17"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="2" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="4"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="2" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="6"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="2" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="8"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="2" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="9"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="2" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="22"
										height="150"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="2"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="13"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="14"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:server="'\''+server_list[selected_server]+'\''"
										panelId="21"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import navbar from "../components/Navbar.vue";
import sidenav from "../components/SideNav.vue";
import GrafanaGraph from "../components/GrafanaGraph.vue";
import {initializeTimestreamQueryClient} from '../utilities/sessionUtils';
import { QueryCommand } from "@aws-sdk/client-timestream-query";
import moment from "moment";
var queryClient;

// @vuese
// @group Views
export default {
	components: {
		navbar,
		sidenav,
		GrafanaGraph,
	},
	data() {
		return {
			selected_view: 0,
			database: "pipeline_metrics",
			table: "server_metrics",
			dashboard: "pipeline-metrics",
			time_range: "1h",
			server: "",
			server_list: [],
			server_selection_list: [],
			server_loading: false,
			selected_server: 0
		};
	},
	created() {
		this.getAllServers();
	},
	watch: {
		time_range() {
			this.getAllServers();
		},
	},
	mounted() {
		window.addEventListener("keyup", e=>{
            if(
                !this.$store.getters.getDialogStatus
				&& this.selected_view == 3
            ){
                if(e.code=="ArrowLeft"){
                    this.previousServer();
                }

                if(e.code=="ArrowRight"){
                    this.nextServer();
                }
            }
        })
	},
	methods: {
		sortByKey(array, key) {
			return array.sort(function (a, b) {
			var x = a[key];
			var y = b[key];
			return x < y ? -1 : x > y ? 1 : 0;
			});
		},
		nextServer(){
			// var i = this.server_selection_list.findIndex(e=>e.value == this.server)
			// if(i == this.server_selection_list.length-1){
			// 	this.server = this.server_selection_list[0].value;
			// }else{
			// 	this.server = this.server_selection_list[i+1].value;
			// }
			if(this.selected_server == this.server_list.length-1){
				this.selected_server = 0
			}else{
				this.selected_server++;
			}
		},
		previousServer(){
			// var i = this.server_selection_list.findIndex(e=>e.value == this.server)
			// if(i == 0){
			// 	this.server = this.server_selection_list[this.server_selection_list.length-1].value;
			// }else{
			// 	this.server = this.server_selection_list[i-1].value;
			// }
			if(this.selected_server == 0){
				this.selected_server = this.server_list.length-1
			}else{
				this.selected_server--;
			}
		},
		getAllServers() {
			this.server_list = [];
			this.server_selection_list = [];
			this.server_loading = true;
			initializeTimestreamQueryClient().then(client=>{
				queryClient = client;
				(async () => {
					await this.getServers();
					this.sortByKey(this.server_selection_list, 'text');
					this.server_list.sort();
					// this.server_selection_list.unshift({ text: "All", value: "server" });
					this.server = this.server_selection_list[0]?.value;
					this.server_loading = false;
				})();
			}).catch(err=>{
				console.log(err);
			})
		},
		async getServers(nextToken = undefined) {
			let response;
			var args = this.time_settings[this.time_range].moment_args;
			var date = moment();
			var to = date.valueOf();
			var from = date.subtract(args[0], args[1]).valueOf();
			var params = {
							QueryString: `
								SELECT DISTINCT server 
								FROM "${this.database}"."${this.table}"
								WHERE time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
							`,
							NextToken: nextToken,
						};
			var command = new QueryCommand(params);
			try {
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.server_selection_list = [
				...this.server_selection_list,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return { text: value, value: `'${value}'` };
				}),
			];
			this.server_list = [
				...this.server_list,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value
				}),
			];
			if (response.NextToken) {
				await this.getServers(response.NextToken);
			}
		},
	},
	computed: {
		time_ranges(){
			return this.$store.getters.getTimeRanges;
		},
		time_settings(){
			return this.$store.getters.getTimeSettings;
		},
	}
};
</script>
