<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Animal Identifications</span>
              <v-spacer></v-spacer>
              <!-- <v-switch
                v-model="show_thumbnail"
                label="Thumbnail"
                dense
                hide-details
                class="mr-4"
              >
              </v-switch> -->
              <!-- <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn> -->

              <v-menu
                v-model="datetime_menu"
                :close-on-content-click="false"
                open-on-hover
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" class="filter_button mr-4">
                    <span v-on="on" v-if="!selected_date">all dates</span>

                    <span v-else
                      ><span v-on="on"
                        >{{
                          selected_date
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/")
                        }}
                      </span>
                      <v-icon @click="selected_date = null"
                        >mdi-close</v-icon
                      ></span
                    >
                  </v-btn>
                </template>

                <v-card width="280">
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="selected_date"
                        flat
                        no-title
                        event-color="#ff9900"
                        :max="todays_date"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                :disabled="loading"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                data-cy="table_notes"
                :headers="headers_general"
                :items="selected_table == 1 || selected_table == 2 ? [] : selected_images"
                :server-items-length="images_count"
                :items-per-page="100"
                style="width: 100%"
                :class="`livestock_table ${
                  selected_table == 1 || selected_table == 2 ? 'empty_table' : 'elevation-1'
                }`"
                hide-default-footer
                item-key="id"
                :loading="loading"
                no-data-text="No Animal Identifications"
                :options.sync="options"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div
                    class="oc_table_header"
                    :style="
                      selected_table == 1 || selected_table == 2 ? 'height: 52px; display: block;' : ''
                    "
                  >
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >
                      <v-tab>General</v-tab>
                      <v-tab>Analytics</v-tab>
                      <v-tab>User DB</v-tab>
                    </v-tabs>
                    <v-data-footer
                      v-if="selected_table != 1 && selected_table != 2"
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                    <v-menu
                      v-else
                      v-model="analytics_datetime_menu"
                      :close-on-content-click="false"
                      open-on-hover
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="width: 180px; float: right; margin-top: 12px"
                          class=""
                        >
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            append-icon="mdi-calendar"
                            class="mr-2 assign_select assign_select_v font_400"
                            single-line
                            hide-details
                            dense
                            height="32"
                            full-width
                            outlined
                            readonly
                            rounded
                            v-model="analytics_start_date"
                            :label="
                              analytics_start_date
                                ? analytics_start_date
                                : 'Date'
                            "
                          >
                          </v-text-field>
                        </div>
                      </template>

                      <v-card height="316" width="280">
                        <v-row>
                          <v-col>
                            <v-date-picker
                              v-model="analytics_start_date"
                              flat
                              no-title
                              id="video_dp"
                              event-color="#ff9900"
                              :max="todays_date"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <template v-slot:[`item.body_img`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'body')"
                  >
                    <Zoom_images :img_src="cropped_url(item, 'body')" />
                  </v-img>
                </template>
                <template v-slot:[`item.head_img`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'head')"
                  >
                    <Zoom_images :img_src="cropped_url(item, 'head')" />
                  </v-img>
                </template>
                <template v-slot:[`item.tag_image`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'tag')"
                  >
                    <v-overlay
                      opacity="0"
                      absolute
                      style="
                        justify-content: start;
                        align-items: start;
                        pointer-events: none;
                      "
                    >
                    </v-overlay>
                  </v-img>
                </template>
                <template v-slot:[`item.age`]="{ item }">
                  <span>{{ num_days(item.datetime) }}</span>
                </template>
                <template v-slot:[`item.detected_tag_label`]="{ item }">
                  <v-chip
                    style="
                      height: 24px;
                      margin-bottom: -2px;
                      margin-top: -3px;
                      padding-top: 2px;
                    "
                    :color="
                      item.detected_tag_color &&
                      item.detected_tag_color != 'UNDEFINED'
                        ? 'oc_' +
                          item.detected_tag_color.toLowerCase() +
                          ' darken-1'
                        : 'grey darken-1'
                    "
                  >
                    <span
                      style="color: white; font-weight: 500; font-size: 12px"
                      >{{ item.detected_tag_label }}</span
                    ></v-chip
                  >
                </template>
                <template v-slot:[`item.customer`]="{ item }">
                  <span
                    >{{ item.customer.first_name }}
                    {{ item.customer.last_name }} ({{ item.customer_id }})</span
                  >
                </template>
              </v-data-table>

              <v-row v-if="selected_table == 1">
                <v-col v-if="loading" cols="12"
                  ><v-progress-linear indeterminate></v-progress-linear
                ></v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_type"
                    :timeframe="'1day'"
                    title="By Type"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_customer"
                    :timeframe="'1day'"
                    title="By Customer"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_unique"
                    :timeframe="'1day'"
                    title="Unique"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_unique_label"
                    :timeframe="'1day'"
                    title="Unique Label"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_unique_color"
                    :timeframe="'1day'"
                    title="Unique Color"
                  />
                </v-col>
              </v-row>

              <v-row v-if="selected_table == 2">
                <v-col v-if="loading" cols="12"
                  ><v-progress-linear indeterminate></v-progress-linear
                ></v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_userdb"
                    :labels="analytics_userdb.labels"
                    :datasets="analytics_userdb.datasets_total"
                    :timeframe="'1day'"
                    title="Total"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_userdb"
                    :labels="analytics_userdb.labels"
                    :datasets="analytics_userdb.datasets_pending"
                    :timeframe="'1day'"
                    title="Pending"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_userdb"
                    :labels="analytics_userdb.labels"
                    :datasets="analytics_userdb.datasets_approved"
                    :timeframe="'1day'"
                    title="Approved"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_userdb"
                    :labels="analytics_userdb.labels"
                    :datasets="analytics_userdb.datasets_denied"
                    :timeframe="'1day'"
                    title="Denied"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validateNotEmpty } from "@/utilities/ValidationRules";
import moment from "moment-timezone";
import Zoom_images from "@/components/zoom_images.vue";

import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import Vue from "vue";
import chart_summary_media from "../components/summary_media/chart_summary_media.vue";

export default {
  components: {
    Zoom_images,
    navbar,
    sidenav,
    CustomerFilter,
    chart_summary_media,
  },
  name: "AnimalIdentification",
  data() {
    return {
      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      selected_images: [],
      search: "",
      datetime_menu: false,
      add_index: null,

      selected_date: null,
      loading: true,

      selected_customer: Vue.prototype.$selectCustomer,

      options: {
        sortBy: ["media_timestamp"],
        sortDesc: [true],
        limit: 10,
        offset: 0,
      },

      selected_table: 0,

      analytics_datetime_menu: false,
      analytics_start_date: moment().subtract(21, "day").format("YYYY-MM-DD"),
    };
  },
  methods: {
    validateNotEmpty,
    cropped_url(item, type) {
      let ymd = moment(item.media_timestamp).format("YYYY/MM/DD");
      let total_date = moment(item.media_timestamp).format(
        "YYYY-MM-DD_HH-mm-ss"
      );
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${item.customer_id}/animals/images/${ymd}/${item.id}_${total_date}_${type}.jpg`;
    },

    num_days(date) {
      let days = moment().diff(moment(date), "days");
      return days + " day" + (days > 1 ? "s" : "");
    },
    tag_image_src(id, date_time, owner, type) {
      return id, date_time, owner, type;
    },
  },

  computed: {
    analytics_data() {
      let type = this.$store.getters.getAnimalIDCharts.type;
      let customer = this.$store.getters.getAnimalIDCharts.customer;
      let unique = this.$store.getters.getAnimalIDUniqueCharts.combo;
      let unique_label = this.$store.getters.getAnimalIDUniqueCharts.label;
      let unique_color = this.$store.getters.getAnimalIDUniqueCharts.color;
      var labels = [];
      var datasets_type = [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Image",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Video",
        },
      ];
      var datasets_customer = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "",
        },
      ];
      var datasets_unique = [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      var datasets_unique_label = [
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      var datasets_unique_color = [
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      if (type.length) {
        for (const i in type) {
          labels.push(type[i].date);
          datasets_type[0].data.push(type[i].image);
          datasets_type[1].data.push(type[i].video);

          if (customer[i]) {
            datasets_customer[0].data.push(customer[i].customer1_count);
            datasets_customer[1].data.push(customer[i].customer2_count);
            datasets_customer[2].data.push(customer[i].customer3_count);
            datasets_customer[3].data.push(customer[i].customer4_count);
            datasets_customer[4].data.push(customer[i].customer5_count);
            datasets_customer[0].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer1_name
              ][0].text;
            datasets_customer[1].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer2_name
              ][0].text;
            datasets_customer[2].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer3_name
              ][0].text;
            datasets_customer[3].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer4_name
              ][0].text;
            datasets_customer[4].label =
              this.$store.getters.getCustomerNames[1][
                customer[i].customer5_name
              ][0].text;
          }

          datasets_unique[0].data.push(unique[i].count);
          datasets_unique_label[0].data.push(unique_label[i].count);
          datasets_unique_color[0].data.push(unique_color[i].count);
        }

        return {
          datasets_type: datasets_type,
          datasets_customer: datasets_customer,
          datasets_unique: datasets_unique,
          datasets_unique_label: datasets_unique_label,
          datasets_unique_color: datasets_unique_color,
          labels: labels,
        };
      }
      return false;
    },

    analytics_userdb() {
    
      let total = this.$store.getters.getAnimalIDUserDB.total;
      let pending = this.$store.getters.getAnimalIDUserDB.pending;
      let approved = this.$store.getters.getAnimalIDUserDB.approved;
      let denied = this.$store.getters.getAnimalIDUserDB.denied;

      var labels = [];

      var datasets_total = [
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      var datasets_pending = [
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      var datasets_approved = [
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Tags",
        },
      ];
      var datasets_denied = [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Tags",
        },
      ];

      if (total.length) {
        for (const i in total) {
          labels.push(total[i].date);

          datasets_total[0].data.push(total[i].count);
          datasets_pending[0].data.push(pending[i].count);
          datasets_approved[0].data.push(approved[i].count);
          datasets_denied[0].data.push(denied[i].count);

        }

        return {
          datasets_total: datasets_total,
          datasets_pending: datasets_pending,
          datasets_approved: datasets_approved,
          datasets_denied: datasets_denied,
          labels: labels,
        };
      }
      return false;
    },

    images_count() {
      return this.$store.getters.getImageCount;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      return {
        sort: "media_timestamp",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        search: this.search,
        date: this.selected_date,
        customer_id: this.selected_customer,
      };
    },
    url_animal_id_status() {
      return "pending";
    },

    headers_general() {
      var headers = [
        {
          text: "Body",
          value: "body_img",
          sortable: false,
          width: "110px",
        },
        {
          text: "Head",
          value: "head_img",
          sortable: false,
          width: "110px",
        },
        {
          text: "Tag",
          value: "tag_image",
          sortable: false,
          width: "110px",
        },
        {
          text: "Datetime",
          value: "media_timestamp",
          width: "180px",
        },
        {
          text: "Age",
          value: "age",
          sortable: false,
          width: "250px",
        },
        {
          text: "Label",
          value: "detected_tag_label",
          sortable: false,
          width: "150px",
        },

        {
          text: "Customer",
          value: "customer",
          sortable: false,
        },
      ];

      return headers;
    },

    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
  },
  created() {
    this.$store.dispatch("DDB_GET_ANIMAL_ID_CHARTS", {
      date_start: this.analytics_start_date,
      date_end: this.todays_date,
      customer: null,
    });
    this.$store.dispatch("DDB_GET_ANIMAL_ID_USERDB", {
      date_start: this.analytics_start_date,
      customer: null,
    });
    // this.$store
    //   .dispatch("DDB_GET_ANIMAL_IDENTIFICATIONS", this.query_params)
    //   .then(() => {
    //     this.selected_identifications = [
    //       ...this.$store.getters.getAnimalIdendifications,
    //     ];
    //     this.loading = false;
    //   });
  },
  watch: {
    analytics_start_date() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_ANIMAL_ID_CHARTS", {
          date_start: this.analytics_start_date,
          date_end: this.todays_date,
          customer:
            this.selected_customer != "All Customers"
              ? this.selected_customer
              : null,
        })
        .then(() => {
          this.loading = false;
        });
      this.$store
        .dispatch("DDB_GET_ANIMAL_ID_USERDB", {
          date_start: this.analytics_start_date,
          customer:
            this.selected_customer != "All Customers"
              ? this.selected_customer
              : null,
        })
        .then(() => {
          this.loading = false;
        });
    },
    selected_customer() {
      this.loading = true;

      this.$store
        .dispatch("DDB_GET_ANIMAL_ID_CHARTS", {
          date_start: this.analytics_start_date,
          date_end: this.todays_date,
          customer:
            this.selected_customer != "All Customers"
              ? this.selected_customer
              : null,
        })
        .then(() => {
          this.loading = false;
        });

        this.$store
        .dispatch("DDB_GET_ANIMAL_ID_USERDB", {
          date_start: this.analytics_start_date,
          customer:
            this.selected_customer != "All Customers"
              ? this.selected_customer
              : null,
        })
        .then(() => {
          this.loading = false;
        });
    },
    query_params() {
      if (this.query_params) {
        this.$store
          .dispatch("DDB_GET_ANIMAL_IMAGES", this.query_params)
          .then(() => {
            this.selected_images = [...this.$store.getters.getAnimalImages];
            this.loading = false;
          });
      }
    },
  },
};
</script>
  <style lang="css">
</style>
  