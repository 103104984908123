<template>
    <v-icon small :color="avail_color">mdi-circle</v-icon>
</template>

<script>
import axios from 'axios';
// @vuese
// @group Components
// The indicator of JSON file existence
export default {
    props: {
        // The JSON URL
        url: String
    },
    data(){
        return {
            avail_color: "grey"
        }
    },
    methods:{
        async checkJSONAvailability(url){
            var json_file_source =
                process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";
                
            // await fetch(json_file_source, { method: 'HEAD' })
            // .then(response => {
            //     if (response.status === 200) {
            //         this.avail_color = "green"
            //     } else {
            //         this.avail_color = "red"
            //     }
            // })
            // .catch(error => {
            //     console.error("Error checking file existence:", error);
            // });
            axios.head(json_file_source, { withCredentials: true })
            .then(() => {
                this.avail_color = "green"
            })
            .catch(error => {
                if (error.response?.status === 200) {
                    this.avail_color = "green"
                }else{
                    this.avail_color = "grey"
                }
            })
        },
    },
    mounted(){
        this.checkJSONAvailability(this.url);
    },
    watch: {
        url(){
            this.checkJSONAvailability(this.url);
        }
    }
}
</script>
