<template>
  <v-app-bar height="48px" flat clipped-right app :scroll-off-screen="false"
    :elevate-on-scroll="false"
    :hide-on-scroll="false"
    fixed id=navbar_drawer>
    <v-app-bar-nav-icon icon @click="miniSideNav"> </v-app-bar-nav-icon>

    <v-spacer></v-spacer>
    <!-- notifications -->
    <!-- <v-badge
        :content="notification_items.length"
        :value="notification_items"
        color="primary"
        overlap
        id="notifications"
        v-model="badge"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              large
              color="red"
              @click="badge = false"
              >mdi-bell</v-icon
            >
          </template>
          <v-list>
            <v-list-item       
              v-for="(item, index) in notification_items"
              :key="index"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <br />
              <router-link :to="{ name: item.link }">go</router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-badge> -->
    <!-- <v-tabs
      class="page_tabs"
      v-model="tab"
      fixed-tabs
      centered
    >
      <v-tab key="staging">Staging </v-tab>
      <v-tab key="production">Production </v-tab>
    </v-tabs> -->
<h3 class="mb_hidden">
  OneCup Administrator Portal
</h3>
    <v-spacer></v-spacer>
    <v-tooltip v-if="!hide_add_user" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="openUserDialog()"
          large
          color="primary"
          >mdi-plus-circle</v-icon>
      </template>
      <span>Add User</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
        v-bind="attrs"
          v-on="on"
        icon href="https://grafana.onecup.ai/login/google" target="_blank"
        class="mr-n2">
          <v-icon
          large
          color="primary"
          >mdi-alpha-g-circle</v-icon
        >
        </v-btn>
      </template>
      <span>Grafana</span>
    </v-tooltip>
    <v-btn icon class="notification_btn mr-n2" @click="turn_notifications"
      ><v-icon large color="primary" dark>mdi-bell-circle</v-icon></v-btn
    >
    <v-btn icon class="account">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large color="primary" dark v-bind="attrs" v-on="on"
            >mdi-account-circle</v-icon
          >
        </template>
        <v-list>
          <v-list-item to="/account">
            <v-list-item-icon>
              <v-icon>mdi-account-cowboy-hat</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-if="user">My Account 
            <!-- {{user.attributes.email}} -->
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-if="user" to="/settings">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item> -->
          <v-list-item v-if="user" @click="logOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <user-card v-if="!hide_add_user"></user-card>
  </v-app-bar>
</template>

<script>
import { eventBus } from "../main";
import UserCard from "../components/UserCard.vue";

// @vuese
// @group Components
// The top navigation bar
export default {
  props: {
    // Hide the add user button
    hide_add_user: Boolean
  },
  components:{
    UserCard
  },
  data() {
    return {
      // date: new Date(),
      tab: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    turn_notifications() {
      eventBus.$emit("turn_onoff");
    },
    openUserDialog() {
        eventBus.$emit("open_dialog_users", {
          item: {},
          create_new: true
        })
    },
    openCustomerDialog(){
        eventBus.$emit("open_dialog_customers", {
          item:{
          customer_mailing_address: {},
          customer_physical_address: {},
        },
          create_new: true,
        });
    },
    async logOut() {
      await this.$store.dispatch("logout");
      this.$store.state.button = true;
      //console.clear();
      localStorage.clear();
      this.$router.push("/login");
      //this.$router.go();
    },
    miniSideNav() {
      eventBus.$emit("mini_sidenav");
    },
  },
  mounted() {
    //this.interval = setInterval(() => this.GET_WEATHER_DATA(), 1000);
  },
  created() {

  },
  watch: {
    tab() {
      if (this.tab == 0) {
        eventBus.$emit("switch_staging")
      }else if (this.tab == 1) {
        eventBus.$emit("switch_production")
      }
    },
  },
  // search() {
  //   // Items have already been loaded
  //   if (this.items.length > 0) return;

  //   // Items have already been requested
  //   if (this.isLoading) return;

  //   this.isLoading = true;

  //   db.once('value')
  //     .then((res) => res.json())
  //     .then((res) => {
  //       const { cattle } = res;
  //       this.cattle = cattle;
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => (this.isLoading = false));
  // },
};
</script>

<style lang="css">
#max_width {
  width: 100%;
  height: 48px;
}

.page_tabs {
  width: 260px;
}

/* @media only screen and (max-width: 600px) {
  #notification_btn {
    margin-right: 12px;
  }
} */
</style>
