<template>
  <v-autocomplete
    v-model="selected_item"
    :label="label"
    :items="list"
    :filled="form"
    :solo="form"
    :full-width="form"
    :dense="form"
    :loading="loading"
    :search-input.sync="search"
    :rules="rules"
    :menu-props="{ maxHeight: 500 }"
    :disabled="disabled"
    class="camera_auto_complete"
    hide-details="auto"
  >
    <template v-slot:append-item>
      <div v-intersect="onIntersect" v-if="nextToken" class="ml-4">
        {{ "Loading..." }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { API } from "aws-amplify";
import * as queries from "../graphql/queries.js";
import * as hasura_queries from "../graphql_hasura/queries";
import axios from "axios";
  
// @vuese
// @group Components
// Dynamic camera list that loads more cameras on scrolling. Wrapper of v-autocomplete.
export default {
  props: {
    // Label of the v-autocomplete component
    label: {
      type: String,
      required: false,
    },
    // Camera id
    value: {
      type: String,
      required: false,
    },
    // Turn on/off the specific sytling in editing forms
    form: {
      type: Boolean,
      required: false,
    },
    // Turn on/off the option for all cameras
    all: {
      type: Boolean,
      required: false,
    },
    // The validation rule for the v-autocomplete component
    rules: {
      type: Array,
      required: false,
    },
    // The owner id of the cameras
    owner: {
      type: String,
      required: false,
    },
    // The disable status of the v-autocomplete component
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selected_item: this.value,
      list: [],
      loading: false,
      all_customers_list : null,
      search: "",
      per_page: 24,
      current_title: "",
      nextToken: null,
      hasura_params: {
        url: "https://onecup-writer.hasura.app/v1/graphql",
        headers: {
          "x-hasura-admin-secret":
            "ehWn3QFN9qIk6KGRMzzZBQrCqmuFClZziZfM54sBbVcMrhQI5YeBMrmIYAiBiFMI",
          "Content-Type": "application/json",
        },
      },
    };
  },
  methods: {
    // @vuese
    // Update the camera list
    // @arg **newSearch**: Reset the list or not <br />
    // @arg **ignoreSearch**: Force including the current item in the list or not
    async updateData(newSearch, ignoreSearch) {
   
      if (!newSearch && !this.nextToken) {
        return;
      }

      if (newSearch) {
        if (this.all) {
          this.list = [
            {
              text: "All Cameras",
              value: null,
            },
          ];
        } else {
          this.list = [];
        }
        this.nextToken = null;
      }

      this.loading = true;
      var limit = this.per_page;
      var list = [];
      var filter = {};
      var sort = {
        direction: "asc",
        field: "camera_name",
      };
      if (this.owner && this.owner != "All Customers") {
        filter.owner = { eq: this.owner };
      }
      if (
        this.search &&
        !ignoreSearch &&
        this.search != "All Cameras" &&
        this.search != this.current_title
      ) {
        filter.or = [
          { camera_name: { wildcard: `*${this.search}*` } },
          { camera_name: { match: `${this.search}` } },
          { id: { wildcard: `*${this.search}*` } },
          { id: { match: `${this.search}` } },
        ];
        // filter.title = {matchPhrase: `${this.search}`};
      }

      if (Object.keys(filter).length <= 0) {
        filter = null;
      }

      var query_variables = { filter: filter, limit: limit, sort: sort };
      if (this.nextToken) {
        query_variables.nextToken = this.nextToken;
      }
      let camera_id_hasura = (
        await axios.post(
          this.hasura_params.url,
          { query: hasura_queries.getHeatCameras },
          { headers: this.hasura_params.headers }
        )
      ).data.data.custom_video_classifications

    //   var data = await API.graphql({
    //     query: queries.searchCameras,
    //     variables: query_variables,
    //   });
    //   console.log(data.data.searchCameras.items)

      list = [
        ...this.list,
        ...camera_id_hasura.map((e) => {
          return {
            text: `${e.camera_id}`,
            value: e.camera_id,
          };
        }),
      ];
     // this.nextToken = data.data.searchCameras.nextToken;
      // console.log(this.nextToken);

      if (!list.some((e) => e.text == "All Cameras") && this.all) {
        list.unshift({
          text: "All Cameras",
          value: null,
        });
      }

      if (
        !list.some((e) => e.value == this.selected_item) &&
        this.selected_item
      ) {
        var insert = await API.graphql({
          query: queries.getCamera,
          variables: { id: this.selected_item || "" },
        });
        if (!insert.data.getCamera) {
          this.selected_item = null;
        } else {
          list.unshift({
            text: `${insert.data.getCamera.camera_name} - ${insert.data.getCamera.id}`,
            value: insert.data.getCamera.id,
          });
        }
      }

      this.list = list;

      this.loading = false;
    },
    onIntersect() {
      if (!this.loading) {
        // console.log("=====Start Updating Camereas=====");
        this.updateData();
        // console.log(this.list);
      }
    },
  },
  watch: {
    selected_item() {
      this.$nextTick(() => {
        this.current_title = this.search;
        console.log("triggered");
        this.updateData(true, true);
      });
      if (this.value != this.selected_item) {
        // Fire when a new camera is selected
        // @arg The new camera id
        this.$emit("input", this.selected_item);
      }
    },
    value() {
      if (this.value != this.selected_item) {
        this.selected_item = this.value;
      }
    },
    search() {
      var temp = this.search;
      if (this.search != this.current_title) {
        this.loading = true;
        setTimeout(() => {
          if (this.search == temp) {
            // console.log("triggered");
            this.updateData(true);
          }
        }, 1000);
      }
    },
    owner() {
      this.selected_item = null;
      this.updateData(true);
    },
  },
  mounted() {
    this.updateData(true);
  },
};
</script>

<style scoped>
.camera_auto_complete .v-input__control {
  min-height: 40px !important;
}
</style>
