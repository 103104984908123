<script>
import { Bar } from "vue-chartjs";
//import { eventBus } from "../main.js";
import moment from "moment";
// @vuese
// @group Components
export default {
  extends: Bar,
  props: ["customer"],
  data() {
    return {
      videos_by_users: [],
      labels: [],
      options_line: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    populateChart() {
      var temp_list = [];
      let latest_date = "2021-06-01";
      this.labels = [];
      do {
        this.labels.push(moment(latest_date, "YYYY-MM-DD").format("YYYY-MMM-DD"));
        temp_list.push(0);
        latest_date = moment(latest_date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } while (latest_date != moment().add(1, "days").format("YYYY-MM-DD"));

      for (const x in this.$store.getters.getHerds) {
        for (const y in this.labels) {
          if (
            this.$store.getters.getHerds[x].herd_date ==
            moment(this.labels[y], "YYYY-MMM-DD").format("YYYY-MM-DD")
          ) {
            temp_list[y] += this.$store.getters.getHerds[x].herd_ocr_id_count;
            break;
          }
        }
      }

      this.videos_by_users = temp_list;
    },
  },
  computed: {
    cameras() {
      return {
        data_pie: {
          datasets: [
            {
              data: this.videos_by_users,
              backgroundColor: 
                "#AE4773",
              label: "UPLOADS",
              borderColor: "#fff",
            },
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  created() {
    this.populateChart();
  },

  watch: {
    customer() {
      this.populateChart();
    },
    videos_by_users() {
      this.renderChart(this.cameras.data_pie, this.options_line);
    },
  },
};
</script>

<style lang="css" scoped>
</style>



