<template>
  <div>
      <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
      ></navbar>
      <sidenav ref="drawer"></sidenav>
      <v-container
    ><v-row align-content="center" justify="center">
      <v-col cols="12" class="main_card_col">
        <v-card elevation="2">
          <v-card-title>
            <span class="ml-2">Images</span>
            <v-spacer></v-spacer>
            <div style="width: 250px">
              <v-slider
                label="Thumbnail"
                class="mb-n7 mr-4"
                v-model="thumbnail_size"
                max="1000"
                min="0"
                dense
                thumb-color="primary"
                :thumb-label="true"
              ></v-slider>
            </div>
            <v-btn class="mr-4" :disabled="selected_items.length<=0 || loading" @click="downloadAllLinks()">
              <v-icon>mdi-table-arrow-down</v-icon>
            </v-btn>
            <v-btn class="mr-4" :disabled="selected_items.length<=0 || loading" @click="downloadAllFiles('json')">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn class="mr-4" :disabled="selected_items.length<=0 || loading" @click="downloadAllFiles('image')">
               <v-icon>mdi-image-area-close</v-icon>
            </v-btn>
            <v-btn @click="refresh" class="mr-4">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn class="mr-4" @click="multi_sort = !multi_sort">
              <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
              <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
            </v-btn>
            <v-btn class="mr-4" @click="openImageFilters"> Image Filter</v-btn>
            <v-menu
                v-model="datetime_menu"
                :close-on-content-click="false"
                open-on-hover
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="filter_button mr-4"
                  >
                    <span v-if="!selected_dates.length">all dates</span>

                    <span v-if="selected_dates.length == 1">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                    <span v-if="selected_dates.length == 2">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/") +
                      " - " +
                      selected_dates[1]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                  </v-btn>
                </template>

                <v-card height="380" width="280">
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="date_range"
                        flat
                        range
                        no-title
                        id="video_dp"
                        event-color="#ff9900"
                        :max="todays_date"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                  <v-row v-if="show_timebar">
                    <v-btn
                      class="filter_button"
                      color="primary"
                      id="saveButton"
                      @click="saveDTFilterMenu"
                    >
                      Save
                    </v-btn>
                    <v-icon
                      v-if="selected_dates.length"
                      @click="reset_dates"
                      class="ml-4 mt-n2"
                      >mdi-filter-off-outline</v-icon
                    >
                  </v-row>
                </v-card>
              </v-menu>
            <div class="mt-n4 ml-2 table_search_field">
              <CameraAutoComplete
                :all="true"
                v-model="selected_camera"
                :owner="selected_customer"
              ></CameraAutoComplete>
            </div>
            <div class="mt-n4" style="width: 250px;">
              <CustomerFilter v-model="selected_customer" :disabled="loading"></CustomerFilter>
            </div>
          </v-card-title>
          <v-card-text class="text-center">
            <v-data-table
              :headers="displayed_headers"
              class="elevation-1 entry_table"
              :multi-sort="multi_sort"
              hide-default-footer

              :items="image_paths"
              :items-per-page="per_page"

              :options.sync="options"
              :sort-by.sync="sort_by"
              :sort-desc.sync="sort_desc"
              :server-items-length="image_count"
              :pageCount="image_pages"
              :loading="loading"

              show-select
              item-key="image_path"
              v-model="selected_items"
            >
              <template v-slot:[`item.index`]="{ index }">
                <span>{{index+1}}</span>
              </template>

              <template v-slot:[`item.thumbnail`]="{ item, index}" v-if="show_thumbnail">
                <v-img
                  class="thumbnail_image"
                  :width="thumbnail_size"
                    :src="`${
                      cookie_bucket +
                      item.image_path + '_event.jpg'
                    }`"
                  @click="open_image(item, index)"
                  />
              </template>
              <template v-slot:[`item.last_upload_time`]="{ item }">
                <span :class="item.last_upload_color">{{ item.last_upload_text }}</span>
              </template>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-tabs class="table_tabs pt-1" v-model="selected_table">
                  <v-tab>Inference</v-tab>
                  <!-- <v-tab>VPRC</v-tab>
                  <v-tab>AWS</v-tab> -->
                </v-tabs>
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  class="table_pagination"
                  @update:options="updateOptions"
                  :items-per-page-options="[50, 100, 200, 500, 1000, 2000, 5000]"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
              </template>
              

            </v-data-table>
            <div class="mt-4">
              <v-btn class="mr-4" :disabled="selected_items.length<=0 || loading" @click="downloadAllFiles('json')">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn class="mr-4" :disabled="selected_items.length<=0 || loading" @click="downloadAllFiles('image')">
                <v-icon>mdi-image-area-close</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <image-card></image-card>
  <general-filter-card></general-filter-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import Vue from "vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
import {createGeneralFilter} from '../utilities/GeneralFilterTemplate';
import GeneralFilterCard from '../components/GeneralFilterCard.vue';
import ImageCard from "../components/ImageCard.vue";
import { eventBus } from "../main";
import JSZip from 'jszip';

// @vuese
// @group Views
export default {
  components: {
      navbar,
      sidenav,
      CustomerFilter,
      CameraAutoComplete,
      GeneralFilterCard,
      ImageCard
  },
  data(){
      return {
          loading: false,
          selected_table: 0,
          all_customer_list: null,
          multi_sort: false,

          page: 1,
          fetched_pages: 0,
          per_page: 100,

          options: {},

          selected_customer: Vue.prototype.$selectCustomer,
          show_timebar: false,
          sort_by: [],
          sort_desc: true,

          date_range: [],
          selected_dates: [],
          datetime_menu: false,
          all_cameras: null,
          todays_date: moment().format("YYYY-MM-DD"),
          selected_index: null,

          dialog: false,
          json_file_not_present: false,
          json_data: "",
          is_json: false,
          json_loading: false,
          navigation_button_change: false,
          portal: "admin",
          camera_model: "",
          selected_camera: null,
          show_thumbnail: true,

          filters: createGeneralFilter(this.$store.getters),
          // model versions
          model_versions: {},

          selected_items: [],
          thumbnail_size: 100
      }
  },
  created(){
    this.initializeDataRange();
    eventBus.$on("update_filters", (params)=>{
      this.filters = params.filters;
      this.date_range = params.date_range;
      this.model_versions = params.model_versions;
      this.updateImages(true);
    })
    eventBus.$on("previousImage", (index)=>{
      index = index - 1;
      if (index < 0) {
        index = this.image_paths.length - 1;
      }
      var img = this.image_paths[index];
      let user_id = img.user_id || '' ;
     let camera_id = img.camera_id || '';
     if (this.all_customer_list) {
      let foundCustomer = this.all_customer_list.find(
        (customer) => customer.id == user_id
      );
      if (foundCustomer) {
        user_id = `${foundCustomer.first_name} ${foundCustomer.last_name} - ${foundCustomer.company_name}`;
      }
      let foundCamera = foundCustomer?.cameras?.items.find(
        (camera) => camera.id == camera_id
      );
      if (foundCamera) {
        camera_id += ` - ${foundCamera.camera_model}`;
      }
    }
      var params = {
        img: img,
        image_path: img.image_path,
        image_time:this.convertTo12HourFormat(img.image_time),
        camera_id: img.camera_id,
        user_id: user_id,
        bbox_list: img.bbox_list,
        body_parts_list: img.body_parts_list,
        imagesLength : this.image_paths_imagePlayer.length,
        image_index: index,
        classificationInfo: img.classificationInfo || [],
        selected: !!this.selected_items.find(e=>e.image_path==img.image_path)
      };

      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("nextImage", (index)=>{
      index = index + 1;
      if(index === ((this.image_paths_imagePlayer.length)/2)) {
        this.updateImages(false,"imagePlayer")
    }
    if (index >= this.image_paths_imagePlayer.length) {
      index = 0;
    }
    var img = this.image_paths_imagePlayer[index];
    let user_id = img.user_id || '' ;
    let camera_id = img.camera_id || '';
    if (this.all_customer_list) {
      let foundCustomer = this.all_customer_list.find(
        (customer) => customer.id == user_id
      );
      if (foundCustomer) {
        user_id = `${foundCustomer.first_name} ${foundCustomer.last_name} - ${foundCustomer.company_name}`;
      }

      let foundCamera = foundCustomer?.cameras?.items.find(
        (camera) => camera.id == camera_id
      );
      if (foundCamera) {
        camera_id += ` - ${foundCamera.camera_model}`;
      }
    }
      var params = {
        img: img,
      image_path: img.image_path,
      image_time: this.convertTo12HourFormat(img.image_time),
      camera_id: img.camera_id,
      user_id: user_id,
      bbox_list: img.bbox_list,
      body_parts_list: img.body_parts_list,
      image_index: index,
      imagesLength : this.image_paths_imagePlayer.length,
      selected: !!this.selected_items.find(e=>e.image_path==img.image_path),
      classificationInfo: img.classificationInfo || []
        
      };
      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("select_image", (image_path)=>{
        var index = this.selected_items.findIndex(e=>e?.image_path == image_path);
        if(index>=0){
          this.selected_items.splice(index, 1);
        }else{
          var img = this.image_paths.find(e=>e.image_path == image_path);
          if(img){
            this.selected_items.push(img);
          }
        }
    })
  },
  methods: {
    async downloadAllLinks(){
      var arr = this.selected_items.map(item=>{
        return `${item.image_path}_event.jpg`;
      });

      var str = `[${'"' + arr.join('","') + '"'}]`.replaceAll(",", ",\n");

      this.download("path_list.txt", str);
    },
    convertTo12HourFormat(timeString) {
    const date = new Date(timeString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;

    hours = hours ? hours : 12;

    const hoursStr = hours.toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hoursStr}:${minutes}:${seconds} ${ampm}`;
  },
    async downloadAllFiles(type){
      this.loading = true;
      
      var zip = JSZip();
      var zip_promises = [];

      for(let i = 0; i<this.selected_items.length; i++){
        if(type!="image"){
          zip_promises.push(this.fetchJSON(zip, this.selected_items[i].image_path));
        }
        zip_promises.push(this.fetchImage(zip, this.selected_items[i].image_path));
      }

      await Promise.all(zip_promises);
      zip.generateAsync({type:"blob"}).then((blob)=>{ 
        this.downloadBlob(blob, "Export.zip");
        this.loading = false;
      }, (err)=>{
          console.log(err);
          this.loading = false;
      });
    },
    download(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },
    fetchJSON(zip, image_path){
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve)=>{
        fetch(url, { credentials: "include" }).then((res)=>{
            return res.blob();
        }).then((data) => {
            return zip.loadAsync(data);
        }).then((zip) => {
          resolve(zip);
        });
      });
    },
    fetchImage(zip, image_path){
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + "_event.jpg";
      return new Promise((resolve)=>{
        fetch(url, { credentials: "include" }).then((res)=>{
            return res.blob();
        }).then((data) => {
            return zip.file(image_path.split("/").pop() + "_event.jpg", data);
        }).then((zip) => {
          resolve(zip);
        });
      });
    },
    downloadBlob(blob, filename){
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a"); 
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    refresh(){
          this.updateImages(true);
      },
    reset_dates() {
      this.selected_dates = [];
      this.date_range = [];
      this.updateImages(true);
    },
    saveDTFilterMenu() {
      this.datetime_menu = false;
      this.selected_dates = this.date_range;
      this.selected_dates.sort();
      this.updateImages(true);
    },
    age(date){
      var unit = 'seconds'
      var result = moment().diff(date, unit);
      var color = "green_color";
      if(result>60){
        unit = 'minutes'
        result = moment().diff(date, unit);
        if(result>60){
          unit = 'hours'
          result = moment().diff(date, unit);
          if(result>24){
            unit = 'days'
            result = moment().diff(date, unit);
            color = "orange_color";
            if(result>7){
              unit = 'weeks'
              result = moment().diff(date, unit);
              if(result>4){
                unit = 'months'
                result = moment().diff(date, unit);
                if(result>12){
                  unit = 'years'
                  result = moment().diff(date, unit);
                  color = "red_color";
                }
              }
            }
          }
        }
      }

      return {result: result, unit: unit, color: color};
    },
    openImageFilters(){
      var params = {
        filters: this.filters,
        date_range: this.date_range,
        model_versions: this.model_versions
      }
      eventBus.$emit("open_dialog_filters", params);
    },
    open_image(img, index) {
      var params = {
        img: this.image_paths[index],
        image_path: img.image_path,
        image_time:this.convertTo12HourFormat(img.image_time),
        camera_id: img.camera_id,
        image_index: index,
        version: img.version,
        bbox_list: img.bbox_list,
        selected: !!this.selected_items.find(e=>e.image_path==img.image_path)
      };
      eventBus.$emit("open_dialog_images", params);
    },
    initializeDataRange() {
      var date = moment().utc();
      var now = date.format("YYYY-MM-DD");
      // var past = date.subtract(1, "day").format("YYYY-MM-DD");
      this.date_range = [now];
      this.selected_dates = this.date_range;
    },
    async updateImages(first_time, imagePlayer = null){
      if(this.page == this.options.page){
        first_time = true;
      }
      if(this.options.page <= this.fetched_pages && !first_time){
        this.page = this.options.page;
        return;
      }

      this.loading = true;
      if(first_time){
        this.page = 1;
        this.fetched_pages = 0;
      }
      var selected_dates = [...this.selected_dates];
      var params = {
        limit: this.options.itemsPerPage,
        first_time: first_time,
        date_range: selected_dates.sort(),
        filters: this.filters,
        model_versions: this.model_versions,
        imagePlayer: imagePlayer,
      };
      if (this.selected_customer && this.selected_customer != "All Customers") {
        params.user_id = this.selected_customer;
      }

      if (this.selected_camera) {
        params.camera_id = this.selected_camera;
      }

      var sort = [];
      this.options.sortBy.forEach((e, i)=>{
        var field;
        if(e=="image_time"){
          field = "time";
        }else{
          field = e;
        }
        sort.push({
          field: field,
          direction: this.options.sortDesc[i]?"DESC":"ASC"
        });
      });
      params.sort_fields = sort;


      this.page = this.options.page;
      if(this.options.page > this.fetched_pages){
        this.fetched_pages = this.options.page;
      }
      
      this.$store.dispatch("DDB_GET_IMAGES", params).then((nextToken) => {
        if (this.$store.getters.getCustomerObjects.length != 0) {
        this.all_customer_list = this.$store.getters.getCustomerObjects;
      }
        this.image_next_token = nextToken;
        this.loading = false;
      });
  },
  },
  watch:{
    options: {
      handler() {
        this.updateImages();
      },
      deep: true
    },
    date_range() {
      if (this.date_range.length > 0) {
        this.show_timebar = true;
      } else {
        this.show_timebar = false;
      }
    },
    selected_customer(){
      this.updateImages();
    },
    selected_camera(){
      this.updateImages();
    },
    selected_items(){
      console.log(this.selected_items);
    }
  },
  computed: {
    PGIE_type_list(){
      return this.$store.getters.getPGIETypeList;
    },
    Species_type_list(){
      return this.$store.getters.getSpeciesTypeList;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    image_paths() {
      if (this.$store.getters.getImages[0]) {
        
      let paths =  this.$store.getters.getImages[0].slice((this.options.page-1)*this.options.itemsPerPage, this.options.page*this.options.itemsPerPage);
      let allCustomers = this.$store.getters.getCustomerObjects;
      for(let i = 0; i < paths.length ; i++) {
        let foundCustomerObject = allCustomers.find(customer => customer.id ===  paths[i].user_id);
        if(foundCustomerObject) {
          paths[i].customer_name = `${foundCustomerObject.first_name} ${foundCustomerObject.last_name}`
        }
      }
      return paths
      } else {
        return [];
      }
    },
    image_paths_imagePlayer() {
    if (this.$store.getters.getImagesImagePlayer[0]) {
      return this.$store.getters.getImagesImagePlayer[0];
    } else {
      return [];
    }
  },
    image_count(){
      if(this.$store.getters.getImages[2]){
        return this.$store.getters.getImages[2];
      } else {
        return 0;
      }
    },
    image_pages(){
      if(this.$store.getters.getImages[2]){
        return Math.ceil(this.$store.getters.getImages[2]/this.options.itemsPerPage);
      } else {
        return 0;
      }
    },
    displayed_headers(){
      if(this.selected_table == 0){
        return this.headers_general;
      }else if(this.selected_table == 1){
        return this.headers_vprc;
      }else if(this.selected_table == 2){
        return this.headers_aws;
      }else{
        return this.headers_general;
      }
    },
    headers_general(){
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "thumbnail", sortable: false},
        { text: "Customer name", value: "customer_name" },
        { text: "Camera ID", value: "camera_id" },
        { text: "Image time", value: "image_time"}
      ]
    },
  }
}
</script>

<style scoped>
.filter_button {
font-size: 14px;
text-transform: none;
}

#saveButton {
margin-bottom: 10px;
margin-left: 30px;
}

.thumbnail_image{
cursor: pointer;
}
</style>