<script>
import { Line } from "vue-chartjs";
//import { eventBus } from "../main.js";

// @vuese
// @group Components
export default {
  extends: Line,
  props: ["labels", "datasets", "timeframe", "title"],
  data() {
    return {
      options_line: {
        title: {
          display: true,
          text: this.title,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: this.datasets.length > 1,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {},
  computed: {
    data() {
      return {
        data_bar: {
          datasets: this.datasets,

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  mounted() {
    if (this.datasets[0].data && this.datasets[0].data.length > 1) {
          //this.options_line.title.text = this.title + ` (${this.timeframe})`

          this.renderChart(this.data.data_bar, this.options_line);
        }
  },
  watch: {
    notification_pref: {
      handler: function () {
        this.notification_object_equal();
      },
      deep: true,
    },
    datasets: {
      handler: function () {
        if (this.datasets[0].data && this.datasets[0].data.length > 1) {
          //this.options_line.title.text = this.title + ` (${this.timeframe})`

          this.renderChart(this.data.data_bar, this.options_line);
        }
      },
      deep: true,
    },
  },
};
</script>