<template>
  <v-overlay
    absolute
    z-index="6"
    style="justify-content: end; align-items: start"
    opacity="0"
  >
    <v-tooltip
      left
      nudge-right="38"
      content-class="zoom_image_tooltip"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="mt-1 mr-1" v-bind="attrs" v-on="on"
          >mdi-magnify-plus-outline</v-icon
        >
      </template>
      <v-img
        style="
          border-radius: 4px;
          display: block;
          opacity: 100%;
  max-width:500px;
  max-height:500px;
  min-width:120px;
  min-height:120px;
  width: auto;
  height: auto;
        "
        :src="img_src"
      >
      </v-img>
    </v-tooltip>
  </v-overlay>
</template>

<script>
export default {
  props: ["img_src"],
  components: {},
  name: "ZoomImages",
  data() {
    return {};
  },
  methods: {},

  computed: {},
  created() {},
  watch: {},
};
</script>
<style lang="css">
</style>