<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-form
      ref="form"
      v-model="valid"
      :disabled="loading"
      @submit="(e) => e.preventDefault()"
    >
      <v-container>
        <v-card v-if="!editedItem.id">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="8">
                <span>Select a customer: </span>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="editedItem.id"
                  :items="customer_names"
                  hide-details
                  dense
                  :disabled="loading || customer_list_loading"
                  :loading="customer_list_loading"
                  @change="updateItem(editedItem.id)"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-card :loading="loading" class="overflow-hidden" v-if="editedItem.id">
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" sm="8">
                <v-tabs
                  v-model="selected_table"
                  class="customer_tabs"
                  show-arrows
                >
                  <v-tab>Overview</v-tab>
                  <v-tab>Customer</v-tab>
                  <v-tab>Property</v-tab>

                  <v-tab>Notes</v-tab>
                  <v-tab>Collaborators</v-tab>
                </v-tabs>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="3">
                <v-autocomplete
                  class="mt-3"
                  v-model="editedItem.id"
                  :items="customer_names"
                  hide-details
                  dense
                  :disabled="loading || customer_list_loading"
                  :loading="customer_list_loading"
                  @change="updateItem(editedItem.id)"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <!-- overview -->
            <v-card v-if="selected_table == 0" flat>
              <v-card-title class="pa-0"></v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3" fill-height>
                    <!-- user info -->
                    <v-card id="user_info_card">
                      <v-row class="my-0 py-0">
                        <v-col cols="12" sm="8" class="my-0 py-0">
                          <v-card-title class="mb-0 pb-0">
                            <span>{{
                              formulateNames(
                                editedItem.first_name,
                                editedItem.last_name
                              )
                            }}</span>
                          </v-card-title>
                          <v-card-text>
                            <div>
                              <v-btn
                                icon
                                :href="editedItem.social_instagram"
                                :disabled="!editedItem.social_instagram"
                                target="_blank"
                                small
                              >
                                <v-icon color="rgb(227, 48, 101)"
                                  >mdi-instagram</v-icon
                                >
                              </v-btn>
                              <v-btn
                                icon
                                :href="editedItem.social_facebook"
                                :disabled="!editedItem.social_facebook"
                                target="_blank"
                                small
                              >
                                <v-icon color="blue">mdi-facebook</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                :href="editedItem.social_linkedin"
                                :disabled="!editedItem.social_linkedin"
                                target="_blank"
                                small
                              >
                                <v-icon color="rgb(0, 118, 180)"
                                  >mdi-linkedin</v-icon
                                >
                              </v-btn>
                              <v-btn
                                icon
                                :href="editedItem.social_x"
                                :disabled="!editedItem.social_x"
                                target="_blank"
                                small
                              >
                                <v-icon color="blue">mdi-twitter</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                :href="editedItem.social_youtube"
                                :disabled="!editedItem.social_youtube"
                                target="_blank"
                                small
                              >
                                <v-icon color="red">mdi-youtube</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                :href="editedItem.social_other"
                                :disabled="!editedItem.social_other"
                                target="_blank"
                                small
                              >
                                <v-icon color="blue"
                                  >mdi-link-box-variant</v-icon
                                >
                              </v-btn>
                            </div>
                            <span>Company: {{ editedItem.company_name }}</span>
                            <br />
                            <span
                              >Owner:
                              {{
                                (owner_user.given_name
                                  ? owner_user.given_name + " "
                                  : "") + owner_user.family_name
                              }}</span
                            >
                            <br />
                            <span :class="last_login_color"
                              >Last Login:
                              {{
                                last_login_time.result +
                                " " +
                                last_login_time.unit
                              }}
                              <span v-if="last_login_time.unit">ago</span></span
                            >
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-img
                            contain
                            class="mt-4 mr-4"
                            :src="`https://cdn.onecup.ai/${editedItem.id}/company/images/${editedItem.id}_logo.jpg`"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- device count -->
                    <v-card class="mt-3" fill-height>
                      <v-card-text>
                        <v-row no-gutters :class="device_spacing">
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span
                                  :class="{
                                    red_color:
                                      !editedItem.sites ||
                                      editedItem.sites.length <= 0,
                                  }"
                                  >{{
                                    editedItem.sites
                                      ? editedItem.sites.length
                                      : 0
                                  }}</span
                                >
                              </h2>
                              <h4>Sites</h4>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div style="text-align: center">
                              <h2 class="pb-1">
                                <span class="green_color">{{
                                  editedItem.cam_count_online
                                }}</span>
                                /
                                <span class="gray_color">{{
                                  editedItem.cam_count
                                }}</span>
                              </h2>
                              <h4>Cameras</h4>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row no-gutters :class="device_spacing">
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span class="green_color">{{
                                  editedItem.wifi_count_online
                                }}</span>
                                /
                                <span class="gray_color">{{
                                  editedItem.wifi_count
                                }}</span>
                              </h2>
                              <h4>Wi-Fi</h4>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row no-gutters :class="device_spacing">
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span class="gray_color">{{ animals }}</span>
                              </h2>
                              <h4>Animals</h4>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div style="text-align: center">
                              <h2 class="pb-1">
                                <span class="gray_color">{{ phenotypes }}</span>
                              </h2>
                              <h4>Phenotypes</h4>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row no-gutters :class="device_spacing">
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span class="gray_color">{{ treatments }}</span>
                              </h2>
                              <h4>Treatments</h4>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div style="text-align: center">
                              <h2 class="pb-1">
                                <span class="gray_color">{{
                                  measurements
                                }}</span>
                              </h2>
                              <h4>Measurements</h4>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row no-gutters :class="device_spacing">
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span class="gray_color">{{
                                  notes.length
                                }}</span>
                              </h2>
                              <h4>Notes</h4>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div style="text-align: center">
                              <h2 class="pb-1">
                                <span class="gray_color">{{
                                  video_data.total_number
                                }}</span>
                              </h2>
                              <h4>Videos</h4>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" sm="6">
                            <div
                              style="
                                text-align: center;
                                border-right: 1px solid gray;
                              "
                            >
                              <h2 class="pb-1">
                                <span class="gray_color">{{
                                  video_data.total_size
                                }}</span>
                              </h2>
                              <h4>Video Size</h4>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div style="text-align: center">
                              <h2 class="pb-1">
                                <span class="gray_color">{{
                                  all_collaborators.length
                                }}</span>
                              </h2>
                              <h4>Collaborators</h4>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-row no-gutters class="mb-3">
                      <v-col cols="12">
                        <v-card>
                          <grafana-graph
                            :from="time_settings[time_range].from"
                            :to="time_settings[time_range].to"
                            :time_bucket="time_settings[time_range].bucket"
                            :database="database"
                            :table="'&quot;' + editedItem.id + '&quot;'"
                            :panelId="66"
                            height="225"
                          ></grafana-graph>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                      <v-col cols="12">
                        <v-card>
                          <grafana-graph
                            :from="time_settings[time_range].from"
                            :to="time_settings[time_range].to"
                            :time_bucket="time_settings[time_range].bucket"
                            :database="database"
                            :table="'&quot;' + editedItem.id + '&quot;'"
                            :panelId="68"
                            height="225"
                          ></grafana-graph>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-card class="pa-3">
                          <v-data-table
                            :search="camera_search"
                            :headers="header_camera"
                            :items="editedItem.cameras"
                            :items-per-page="5"
                            :footer-props="{
                              'items-per-page-options': [table_length],
                              'disable-items-per-page': true,
                            }"
                            :height="200"
                            single-expand
                            show-expand
                          >
                            <template
                              v-slot:[`item.data-table-expand`]="{ item }"
                            >
                              <v-icon
                                @click="editCamera(item)"
                                small
                                class="mr-2"
                                >mdi-pencil</v-icon
                              >
                            </template>
                            <template v-slot:[`item.icon`]="{ item }">
                              <v-icon
                                class="cam_icon"
                                v-if="
                                  item.model == 'RLC-811A' ||
                                  item.model.includes('Duo')
                                "
                                >$rlc_511</v-icon
                              >
                              <v-icon
                                class="cam_icon"
                                v-else-if="
                                  item.model == 'E1 Zoom' ||
                                  item.model == 'E1 Zoom Outdoor'
                                "
                                >$zoom_e1</v-icon
                              ><v-icon
                                class="cam_icon"
                                v-else-if="item.model == 'E1 Outdoor'"
                                >$outdoor</v-icon
                              ><v-icon
                                class="cam_icon"
                                v-else-if="
                                  item.model == 'Argus PT' ||
                                  item.model == 'Argus PT 6'
                                "
                                >$argus_go</v-icon
                              >
                              <v-icon
                                class="cam_icon"
                                v-else-if="item.model == 'Reolink Go PT'"
                                >$reolink_go</v-icon
                              >
                              <v-icon
                                class="cam_icon"
                                v-else-if="
                                  item.model == 'ReoLink Cloud' ||
                                  item.model == 'RLC-511W' ||
                                  item.model == 'RLC-511WA'
                                "
                                >$r511w</v-icon
                              >
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                              <div
                                class="d-flex justify-space-between"
                                style="width: 160px"
                              >
                                <span>{{ item.id }}</span
                                ><copy-button :text="item.id"></copy-button>
                              </div>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                              <span
                                v-if="item.status == 'ONLINE'"
                                class="green_color"
                                >{{ item.status }}</span
                              >
                              <span
                                v-if="item.status == 'OFFLINE'"
                                class="gray_color"
                                >{{ item.status }}</span
                              >
                              <span
                                v-if="item.status == 'WARNING'"
                                class="orange_color"
                                >{{ item.status }}</span
                              >
                            </template>
                            <template v-slot:[`item.latest_upload`]="{ item }">
                              <span>{{
                                get_date_diff(item.latest_upload)
                              }}</span>
                            </template>
                            <template v-slot:[`footer.prepend`]>
                              <v-btn :to="'/cameras/' + editedItem.id"
                                >Details</v-btn
                              >
                              <v-spacer />
                              <v-text-field
                                v-model="camera_search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                dense
                              ></v-text-field>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- customer -->
            <v-container v-if="selected_table == 1" class="ma-0 pa-0">
              <v-row class="mt-0 pt-0">
                <v-col cols="12" sm="6">
                  <v-card class="mb-5">
                    <v-card-title>General</v-card-title>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.first_name)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('first_name', editedItem.first_name)
                            "
                            v-model="editedItem.first_name"
                            label="First Name *"
                            outlined
                            dense
                            :rules="[rules.required, rules.name]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.last_name)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('last_name', editedItem.last_name)
                            "
                            v-model="editedItem.last_name"
                            label="Last Name *"
                            outlined
                            dense
                            :rules="[rules.required, rules.name]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.preferred_name)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'preferred_name',
                                editedItem.preferred_name
                              )
                            "
                            v-model="editedItem.preferred_name"
                            label="Preferred Name"
                            outlined
                            dense
                            :rules="[rules.name]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            @focus="onFieldFocus(editedItem.type)"
                            @blur="onFieldBlur()"
                            @input="onFieldInput('type', editedItem.type)"
                            v-model="editedItem.type"
                            label="Type *"
                            :items="$store.getters.getCustomerTypes"
                            outlined
                            dense
                            :rules="[rules.required]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            @focus="onFieldFocus(editedItem.status)"
                            @blur="onFieldBlur()"
                            @input="onFieldInput('status', editedItem.status)"
                            v-model="editedItem.status"
                            label="Status"
                            :items="$store.getters.getCustomerStatus"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.company_name)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'company_name',
                                editedItem.company_name
                              )
                            "
                            v-model="editedItem.company_name"
                            label="Company Name"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(owner_user.email)"
                            @blur="onFieldBlur()"
                            @input="
                              owner_user.email = owner_user.email.toLowerCase();
                              onFieldInput(
                                'owner_user_email',
                                owner_user.email
                              );
                            "
                            v-model="owner_user.email"
                            label="Email"
                            disabled
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.stripe_id)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('stripe_id', editedItem.stripe_id)
                            "
                            v-model="editedItem.stripe_id"
                            label="Stripe ID"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            value="UNDEFINED"
                            disabled
                            label="Subscription Status"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.company_occupation)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'company_occupation',
                                editedItem.company_occupation
                              )
                            "
                            v-model="editedItem.company_occupation"
                            label="Company Occupation"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.company_website)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'company_website',
                                editedItem.company_website
                              )
                            "
                            v-model="editedItem.company_website"
                            label="Company Website"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <phone-masked-field
                            @focus="onFieldFocus(editedItem.phone_cell)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('phone_cell', editedItem.phone_cell)
                            "
                            v-model="editedItem.phone_cell"
                            label="Cell Phone"
                          ></phone-masked-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <phone-masked-field
                            @focus="onFieldFocus(editedItem.phone_home)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('phone_home', editedItem.phone_home)
                            "
                            v-model="editedItem.phone_home"
                            label="Home Phone"
                          ></phone-masked-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <phone-masked-field
                            @focus="onFieldFocus(editedItem.phone_fax)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('phone_fax', editedItem.phone_fax)
                            "
                            v-model="editedItem.phone_fax"
                            label="Fax"
                          ></phone-masked-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card>
                    <v-card-title>Social</v-card-title>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_instagram)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'social_instagram',
                                editedItem.social_instagram
                              )
                            "
                            v-model="editedItem.social_instagram"
                            label="Instagram"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_facebook)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'social_facebook',
                                editedItem.social_facebook
                              )
                            "
                            v-model="editedItem.social_facebook"
                            label="Facebook"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_linkedin)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'social_linkedin',
                                editedItem.social_linkedin
                              )
                            "
                            v-model="editedItem.social_linkedin"
                            label="Linkedin"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_x)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput('social_x', editedItem.social_x)
                            "
                            v-model="editedItem.social_x"
                            label="Twitter"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_youtube)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'social_youtube',
                                editedItem.social_youtube
                              )
                            "
                            v-model="editedItem.social_youtube"
                            label="Youtube"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.social_other)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'social_other',
                                editedItem.social_other
                              )
                            "
                            v-model="editedItem.social_other"
                            label="Other"
                            outlined
                            dense
                            :rules="[rules.url]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mb-5">
                    <v-card-title>Address</v-card-title>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="
                              onFieldFocus(editedItem.addresses[0].line_1)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].line_1',
                                editedItem.addresses[0].line_1
                              )
                            "
                            v-model="editedItem.addresses[0].line_1"
                            label="Address 1 *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="
                              onFieldFocus(editedItem.addresses[0].line_2)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].line_2',
                                editedItem.addresses[0].line_2
                              )
                            "
                            v-model="editedItem.addresses[0].line_2"
                            label="Address 2"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.addresses[0].city)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].city',
                                editedItem.addresses[0].city
                              )
                            "
                            v-model="editedItem.addresses[0].city"
                            label="City *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-if="editedItem.addresses[0].country == 'Canada'"
                            @focus="
                              onFieldFocus(editedItem.addresses[0].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].province',
                                editedItem.addresses[0].province
                              )
                            "
                            v-model="editedItem.addresses[0].province"
                            :items="$store.getters.getProvinces"
                            label="Province *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                          <v-select
                            v-else-if="
                              editedItem.addresses[0].country == 'United States'
                            "
                            @focus="
                              onFieldFocus(editedItem.addresses[0].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].province',
                                editedItem.addresses[0].province
                              )
                            "
                            v-model="editedItem.addresses[0].province"
                            :items="$store.getters.getStates"
                            label="State *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                          <v-text-field
                            v-else
                            @focus="
                              onFieldFocus(editedItem.addresses[0].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].province',
                                editedItem.addresses[0].province
                              )
                            "
                            v-model="editedItem.addresses[0].province"
                            label="Province *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <masked-field
                            v-if="editedItem.addresses[0].country == 'Canada'"
                            @focus="
                              onFieldFocus(editedItem.addresses[0].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].postal_code',
                                editedItem.addresses[0].postal_code
                              )
                            "
                            v-model="editedItem.addresses[0].postal_code"
                            label="Postal Code *"
                            :rules="[
                              rules.required,
                              rules.address,
                              rules.CAZipCode,
                            ]"
                            mask="___ ___"
                            :uppercase="true"
                          >
                          </masked-field>

                          <v-text-field
                            v-else-if="
                              editedItem.addresses[0].country == 'United States'
                            "
                            @focus="
                              onFieldFocus(editedItem.addresses[0].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].postal_code',
                                editedItem.addresses[0].postal_code
                              )
                            "
                            v-model="editedItem.addresses[0].postal_code"
                            label="Postal Code *"
                            outlined
                            dense
                            :rules="[
                              rules.required,
                              rules.address,
                              rules.USZipCode,
                            ]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            @focus="
                              onFieldFocus(editedItem.addresses[0].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].postal_code',
                                editedItem.addresses[0].postal_code
                              )
                            "
                            v-model="editedItem.addresses[0].postal_code"
                            label="Postal Code *"
                            outlined
                            dense
                            :rules="[rules.required, rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            @focus="
                              onFieldFocus(editedItem.addresses[0].country)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[0].country',
                                editedItem.addresses[0].country
                              )
                            "
                            v-model="editedItem.addresses[0].country"
                            label="Country *"
                            :items="countries"
                            :rules="[rules.required]"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-checkbox
                        v-model="showPhysicalAddress"
                        label="Physical Address Differs From Mailing Address"
                        class="ma-0 pa-0"
                        dense
                        hide-details
                        v-if="!showPhysicalAddress"
                      ></v-checkbox>
                      <TextDivider v-if="showPhysicalAddress"
                        ><v-checkbox
                          v-model="showPhysicalAddress"
                          label="Physical Address Differs From Mailing Address"
                          class="small-checkbox ma-0 pa-0"
                          dense
                          hide-details
                        ></v-checkbox
                      ></TextDivider>
                      <v-row no-gutters v-if="showPhysicalAddress">
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="
                              onFieldFocus(editedItem.addresses[1].line_1)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].line_1',
                                editedItem.addresses[1].line_1
                              )
                            "
                            v-model="editedItem.addresses[1].line_1"
                            label="Address 1"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="
                              onFieldFocus(editedItem.addresses[1].line_2)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].line_2',
                                editedItem.addresses[1].line_2
                              )
                            "
                            v-model="editedItem.addresses[1].line_2"
                            label="Address 2"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            @focus="onFieldFocus(editedItem.addresses[1].city)"
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].city',
                                editedItem.addresses[1].city
                              )
                            "
                            v-model="editedItem.addresses[1].city"
                            label="City"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-if="editedItem.addresses[1].country == 'Canada'"
                            @focus="
                              onFieldFocus(editedItem.addresses[1].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].province',
                                editedItem.addresses[1].province
                              )
                            "
                            v-model="editedItem.addresses[1].province"
                            :items="$store.getters.getProvinces"
                            label="Province"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                          <v-select
                            v-else-if="
                              editedItem.addresses[1].country == 'United States'
                            "
                            @focus="
                              onFieldFocus(editedItem.addresses[1].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].province',
                                editedItem.addresses[1].province
                              )
                            "
                            v-model="editedItem.addresses[1].province"
                            :items="$store.getters.getStates"
                            label="State"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                          <v-text-field
                            v-else
                            @focus="
                              onFieldFocus(editedItem.addresses[1].province)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].province',
                                editedItem.addresses[1].province
                              )
                            "
                            v-model="editedItem.addresses[1].province"
                            label="Province"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <masked-field
                            v-if="editedItem.addresses[1].country == 'Canada'"
                            @focus="
                              onFieldFocus(editedItem.addresses[1].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].postal_code',
                                editedItem.addresses[1].postal_code
                              )
                            "
                            v-model="editedItem.addresses[1].postal_code"
                            label="Postal Code"
                            :rules="[rules.address, rules.CAZipCode]"
                            mask="___ ___"
                            :uppercase="true"
                          ></masked-field>

                          <v-text-field
                            v-else-if="
                              editedItem.addresses[1].country == 'United States'
                            "
                            @focus="
                              onFieldFocus(editedItem.addresses[1].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].postal_code',
                                editedItem.addresses[1].postal_code
                              )
                            "
                            v-model="editedItem.addresses[1].postal_code"
                            label="Postal Code"
                            outlined
                            dense
                            :rules="[rules.address, rules.USZipCode]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            @focus="
                              onFieldFocus(editedItem.addresses[1].postal_code)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].postal_code',
                                editedItem.addresses[1].postal_code
                              )
                            "
                            v-model="editedItem.addresses[1].postal_code"
                            label="Postal Code"
                            outlined
                            dense
                            :rules="[rules.address]"
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-combobox
                            @focus="
                              onFieldFocus(editedItem.addresses[1].country)
                            "
                            @blur="onFieldBlur()"
                            @input="
                              onFieldInput(
                                'addresses[1].country',
                                editedItem.addresses[1].country
                              )
                            "
                            v-model="editedItem.addresses[1].country"
                            label="Country"
                            :items="countries"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card>
                    <v-card-title>Notification</v-card-title>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          v-for="name in notification_fields"
                          :key="name.value"
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            @focus="
                              onFieldFocus(getByString(editedItem, name.value))
                            "
                            @blur="onFieldBlur()"
                            @input="
                              (e) => {
                                onFieldInput(name.value, e);
                                setByString(editedItem, name.value, e);
                              }
                            "
                            :value="getByString(editedItem, name.value)"
                            :items="$store.getters.getNotificationFrequency"
                            :label="name.text"
                            outlined
                            dense
                            class="text-validated pl-3 pr-3 pt-1"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <!-- property -->
            <v-container v-if="selected_table == 2" class="ma-0 pa-0">
              <v-row class="mt-0 pt-0">
                <v-col cols="12" sm="6">
                  <v-card class="mb-5">
                    <v-card-title>Site</v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="site_search"
                        :headers="header_site"
                        :items="editedItem.sites"
                        :items-per-page="5"
                        :footer-props="{
                          'items-per-page-options': [table_length],
                          'disable-items-per-page': true,
                        }"
                        :height="300"
                        single-expand
                        show-expand
                      >
                        <!-- :height="card_height - 68" -->
                        <template v-slot:[`item.data-table-expand`]="{ item }">
                          <v-icon @click="editSite(item)" small class="mr-2"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <template v-slot:[`item.id`]="{ item }">
                          <div
                            class="d-flex justify-space-between"
                            style="width: 300px"
                          >
                            <span>{{ item.id }}</span
                            ><copy-button :text="item.id"></copy-button>
                          </div>
                        </template>
                        <template v-slot:[`item.site_location`]="{ item }">
                          <span v-if="item.site_location"
                            ><span v-if="item.site_location.latitude"
                              >{{ item.site_location.latitude }},
                              {{ item.site_location.longitude }}</span
                            ></span
                          >
                          <span v-else></span>
                        </template>
                        <template v-slot:[`footer.prepend`]>
                          <v-btn
                            :to="
                              '/sites/' +
                              (
                                editedItem.first_name +
                                '_' +
                                editedItem.last_name
                              ).replaceAll(' ', '_')
                            "
                            >Details</v-btn
                          >
                          <v-spacer />
                          <v-text-field
                            v-model="site_search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            dense
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                  <v-card class="mb-5">
                    <v-card-title>Wifi</v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="wifi_search"
                        :headers="header_wifi"
                        :items="editedItem.wifis"
                        :items-per-page="5"
                        :footer-props="{
                          'items-per-page-options': [table_length],
                          'disable-items-per-page': true,
                        }"
                        :height="300"
                        single-expand
                        show-expand
                      >
                        <!-- :height="card_height - 68" -->
                        <template v-slot:[`item.data-table-expand`]="{ item }">
                          <v-icon @click="editWifi(item)" small class="mr-2"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <span
                            v-if="item.status == 'ONLINE'"
                            class="green_color"
                            >{{ item.status }}</span
                          >
                          <span
                            v-if="item.status == 'OFFLINE'"
                            class="gray_color"
                            >{{ item.status }}</span
                          >
                          <span
                            v-if="item.status == 'WARNING'"
                            class="orange_color"
                            >{{ item.status }}</span
                          >
                        </template>
                        <template v-slot:[`footer.prepend`]>
                          <v-btn :to="'/wifi/' + editedItem.id">Details</v-btn>
                          <v-spacer />
                          <v-text-field
                            v-model="wifi_search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            dense
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-title>Camera</v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="camera_search"
                        :headers="header_camera"
                        :items="editedItem.cameras"
                        :items-per-page="5"
                        :footer-props="{
                          'items-per-page-options': [23],
                          'disable-items-per-page': true,
                        }"
                        :height="1172"
                        single-expand
                        show-expand
                      >
                        <template v-slot:[`item.data-table-expand`]="{ item }">
                          <v-icon @click="editCamera(item)" small class="mr-2"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <template v-slot:[`item.latest_upload`]="{ item }">
                          <span>{{ get_date_diff(item.latest_upload) }}</span>
                        </template>
                        <template v-slot:[`item.icon`]="{ item }">
                          <v-icon
                            class="cam_icon"
                            v-if="
                              item.model == 'RLC-811A' ||
                              item.model.includes('Duo')
                            "
                            >$rlc_511</v-icon
                          >
                          <v-icon
                            class="cam_icon"
                            v-else-if="
                              item.model == 'E1 Zoom' ||
                              item.model == 'E1 Zoom Outdoor'
                            "
                            >$zoom_e1</v-icon
                          ><v-icon
                            class="cam_icon"
                            v-else-if="item.model == 'E1 Outdoor'"
                            >$outdoor</v-icon
                          ><v-icon
                            class="cam_icon"
                            v-else-if="
                              item.model == 'Argus PT' ||
                              item.model == 'Argus PT 6'
                            "
                            >$argus_go</v-icon
                          >
                          <v-icon
                            class="cam_icon"
                            v-else-if="item.model == 'Reolink Go PT'"
                            >$reolink_go</v-icon
                          >
                          <v-icon
                            class="cam_icon"
                            v-else-if="
                              item.model == 'ReoLink Cloud' ||
                              item.model == 'RLC-511W' ||
                              item.model == 'RLC-511WA'
                            "
                            >$r511w</v-icon
                          >
                        </template>
                        <template v-slot:[`item.id`]="{ item }">
                          <div
                            class="d-flex justify-space-between"
                            style="width: 160px"
                          >
                            <span>{{ item.id }}</span
                            ><copy-button :text="item.id"></copy-button>
                          </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <span
                            v-if="item.status == 'ONLINE'"
                            class="green_color"
                            >{{ item.status }}</span
                          >
                          <span
                            v-if="item.status == 'OFFLINE'"
                            class="gray_color"
                            >{{ item.status }}</span
                          >
                          <span
                            v-if="item.status == 'WARNING'"
                            class="orange_color"
                            >{{ item.status }}</span
                          >
                        </template>
                        <template v-slot:[`footer.prepend`]>
                          <v-btn :to="'/cameras/' + editedItem.id"
                            >Details</v-btn
                          >
                          <v-spacer />
                          <v-text-field
                            v-model="camera_search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            dense
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-card v-if="selected_table == 3">
              <v-card-text>
                <v-data-table
                  :headers="header_overview_notes"
                  :items="notes"
                  :items-per-page="5"
                  :footer-props="{
                    'items-per-page-options': [table_length],
                    'disable-items-per-page': true,
                  }"
                  :height="card_height - 68"
                >
                  <template v-slot:[`item.datetime`]="{ item }">
                    {{ item.datetime.split("T")[0] }}
                  </template>

                  <template v-slot:[`footer.prepend`]>
                    <v-spacer />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- collaborators -->
            <v-card v-if="selected_table == 4">
              <v-card-text>
                <v-data-table
                  :headers="header_collaborator"
                  :items="
                    selected_colab ? editedItem.shares : editedItem.shares_with
                  "
                  :items-per-page="5"
                  :footer-props="{
                    'items-per-page-options': [table_length],
                    'disable-items-per-page': true,
                  }"
                  :height="card_height - 116"
                  show-expand
                  :key="collab_update_key"
                >
                  <template v-slot:top="{}">
                    <div class="d-flex">
                      <v-tabs
                        class="customer_tabs collab_tabs"
                        v-model="selected_colab"
                      >
                        <v-tab>Shared With</v-tab>
                        <v-tab>Shared To Me</v-tab>
                      </v-tabs>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="editCollaboration('create_new')"
                            large
                            color="primary"
                            >mdi-plus-circle</v-icon
                          >
                        </template>
                        <span>Add Collaborator</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:[`item.data-table-expand`]="{ item }">
                    <div class="d-flex">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="editCollaboration(item)"
                              :color="hover ? 'primary' : 'gray'"
                              class="mr-2"
                              >mdi-pencil</v-icon
                            >
                          </v-hover>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                selected_colab
                                  ? (cancel_colaboration = false)
                                  : (cancel_colaboration = true);
                                delete_item = item;
                                delete_collab_dialog = true;
                              "
                              :color="hover ? 'primary' : 'gray'"
                              >mdi-delete</v-icon
                            >
                          </v-hover>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:[`item.id`]="{ item }">
                    <div
                      class="d-flex justify-space-between"
                      style="width: 300px"
                    >
                      <span>{{ item.id }}</span
                      ><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.approved`]="{ item }">
                    <v-chip
                      v-if="item.approved"
                      text-color="white"
                      small
                      class="mr-1"
                      :color="'oc_green'"
                      ><b>Approved</b></v-chip
                    >
                    <v-chip
                      v-if="!item.approved && !selected_colab"
                      text-color="white"
                      small
                      class="mr-1"
                      :color="'oc_grey'"
                      ><b>Pending</b></v-chip
                    >
                    <div class="d-flex" v-if="!item.approved && selected_colab">
                      <v-btn
                        @click="
                          approve_colaboration = true;
                          approve_item = item;
                          approve_collab_dialog = true;
                        "
                        text-color="white"
                        small
                        class="mr-3"
                        :color="'primary'"
                        ><b>Approve</b></v-btn
                      >
                      <v-btn
                        @click="
                          approve_colaboration = false;
                          approve_item = item;
                          approve_collab_dialog = true;
                        "
                        text-color="white"
                        small
                        :color="'primary'"
                        ><b>Decline</b></v-btn
                      >
                    </div>
                  </template>

                  <template v-slot:[`item.permission`]="{ item }">
                    <v-chip text-color="white" small color="oc_green"
                      ><b>{{ item.permission }}</b></v-chip
                    >
                  </template>

                  <template v-slot:[`item.email`]="{ item }">
                    <span v-if="selected_colab">
                      {{ item.collaborator_email }}
                    </span>
                    <span v-else> {{ item.owner_email }} </span>
                  </template>
                  <!-- <template v-slot:[`item.approved`]="{item}">
                  <span v-if="item.approved" class="green_color">Yes</span>
                  <span v-else class="gray_color">No</span>
                </template> -->
                  <template v-slot:[`footer.prepend`]>
                    <v-spacer />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- RFID -->
          </v-card-text>

          <v-card-actions class="pr-6" v-if="selected_table == 1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn :disabled="loading" @click="delete_dialog = true">
                  <v-icon class="pl-1" style="float: left" color="primary"
                    >mdi-delete</v-icon
                  >
                </v-btn>
                <div style="float: right">
                  <v-btn
                    color="primary"
                    class="ml-4"
                    @click="apply"
                    :disabled="loading || !has_unsaved_changes"
                  >
                    Apply
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" v-if="error"
                ><span class="error_span">{{ error }}</span></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
      <v-dialog v-model="delete_dialog" max-width="550px" min-width="260px">
        <v-card>
          <v-card-title
            >Delete Customer
            {{ formulateCustomerNames(editedItem) }}?</v-card-title
          >
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn
                color="#dd3f5b"
                @click="deleteItem(editedItem.id)"
                class="mr-3 white--text"
              >
                Delete
              </v-btn>
              <v-btn color="primary" @click="delete_dialog = false">
                Cancel
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="delete_collab_dialog"
        max-width="550px"
        mid-width="260px"
      >
        <v-card>
          <v-card-title
            >Delete Collaboration with {{ delete_item.email }}?</v-card-title
          >
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn
                color="#dd3f5b"
                @click="delete_collaboration"
                class="mr-3 white--text"
              >
                Delete
              </v-btn>
              <v-btn color="primary" @click="delete_collab_dialog = false">
                Cancel
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="approve_collab_dialog"
        max-width="500px"
        mid-width="260px"
      >
        <v-card>
          <v-card-title>{{
            approve_colaboration
              ? "Approve Collaboration"
              : "Decline Collaboration"
          }}</v-card-title>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn
                color="primary"
                @click="approve_collab_dialog = false"
                class="mr-3"
              >
                Cancel
              </v-btn>
              <v-btn
                color="#dd3f5b"
                @click="confirm_collaboration(approve_colaboration)"
              >
                Confirm
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <CameraCard></CameraCard>
    <SiteCard></SiteCard>
    <WifiCard></WifiCard>
    <CollaborationCard></CollaborationCard>
  </div>
</template>

<script lang="js">
import { eventBus } from "../main";
import TextDivider from "../components/TextDivider.vue";
import countryData from "../data/country-by-name.json";
import rulesMixin from "../mixins/rulesMixin";
import CopyButton from "../components/CopyButton.vue";
import CameraCard from "../components/CameraCard.vue";
import SiteCard from "../components/SiteCard.vue";
import WifiCard from "../components/WifiCard.vue";
import CollaborationCard from "../components/CollaborationCard.vue";

import moment from "moment";
import RFIDMixin from "../mixins/RFID_TM_Mixin";
import nameMixin from "../mixins/nameMixin";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { initializeLambdaClient, initializeCognitoIdentityProviderClient } from "../utilities/sessionUtils";
import { InvokeCommand } from "@aws-sdk/client-lambda";
import { AdminGetUserCommand, AdminListUserAuthEventsCommand } from "@aws-sdk/client-cognito-identity-provider";

import PhoneMaskedField from "../components/PhoneMaskedField.vue";
import MaskedField from "../components/MaskedField.vue";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import GrafanaGraph from "../components/GrafanaGraph.vue";

var cognitoidentityserviceprovider;


export default {
    components: { 
      TextDivider, 
      CopyButton, 
      CameraCard, 
      SiteCard, 
      WifiCard, 
      CollaborationCard, 
      PhoneMaskedField,
      MaskedField,
      navbar,
      sidenav,
      GrafanaGraph,
    },
    data() {
        return {
            editedItem: { addresses: [{}, {}] },
            delete_dialog: false,
            dialog: false,
            owner_name: "",
            error: "",
            site_name: "",
            selected_table: 1,
            valid: false,
            owner_user: {
              email: "",
              given_name: "",
              family_name: ""
            },
            last_login_time: null,

            selected_source: "ALL",
            selected_time_col1: "Days",
            selected_range1: [0, 7],

            showPhysicalAddress: false,
            card_height: 758,
            table_length: 13,
            loading: false,
            customer_list_loading: true,
            animals: [],
            phenotypes: [],
            treatments: [],
            measurements: [],
            notes: [],
            videos: [],
            video_data: {
              total_size: 0,
              total_number: 0
            },

            animal_search: "",
            treatment_search: "",
            measurement_search: "",
            phenotype_search: "",
            site_search: "",
            camera_search: "",
            wifi_search: "",

            label_sort_by_name: false,
            label_sort_by_data: true,

            all_collaborators: [],
            collaborators: [],
            collaborators_original: [],
      

            is_name_wrapped: false,
            device_spacing: "mb-8",

            last_login_color: "",
            label_length: 0,
            lambda: null,

            selected_colab: 0,

            cancel_colaboration: false,
            delete_collab_dialog: false,
            delete_item: {},

            approve_colaboration: false,
            approve_collab_dialog: false,
            approve_item: {},
            collab_update_key: 0,

            original_values: {},
            isFieldActive: false,
            edit_value: null,
            has_unsaved_changes: false,
            pressed_apply: false,
            next_event: "",

            RFID_date_range: [],

            database: "production_inference_customer",
            time_range: "24h",
        };
    },
    created() {

        eventBus.$on("update_edited_customer", (params)=>{
          this.updateItem(params)
        });

        this.customer_list_loading = true;
        this.$store.dispatch("DDB_GET_CUSTOMERS").then(()=>{
            this.customer_list_loading = false;
        })
        if (this.$store.getters.getCognitoUsers.length == 0) {
          this.$store.dispatch("DDB_GET_COGNITO_USERS");
        }
        window.addEventListener('beforeunload', (event)=>{
          if(this.has_unsaved_changes){
            event.preventDefault()
            event.returnValue = true
          }
        });
        if (this.$route.query.id) {
            this.editedItem.id = this.$route.query.id;
            this.updateItem(this.editedItem.id);
        }

    },
    methods: {
      getByString(o, s){
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (o && k in o) {
                o = o[k];
            } else {
                return null;
            }
        }
        return o;
      },
      setByString(o, s, v){
        // console.log(v);
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length-1; i < n; ++i) {
            var k = a[i];
            if (o && k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        o[a[a.length-1]] = v;
      },
      onFieldFocus(value){
        this.isFieldActive = true;
        this.edit_value = value;
        if(this.edit_value == undefined || this.edit_value == null){
          this.edit_value = "";
        }
      },
      onFieldBlur(){
        this.isFieldActive = false;
      },
      onFieldInput(key, value){
        if(!Object.prototype.hasOwnProperty.call(this.original_values, key)){
          if (this.edit_value === "" || this.edit_value === false){
            this.original_values[key] = null;
          }else{
            this.original_values[key] = this.edit_value;
          }
          this.has_unsaved_changes = true;
        }
        if (value === "" || value === false) {
          value = null;
        }
        // console.log(`key: ${key}, original: ${this.original_values[key]}, current: ${value}`)
        if (this.original_values[key] == value) {
          delete this.original_values[key];
          if(Object.keys(this.original_values).length <=0){
            this.has_unsaved_changes = false;
          }
        }
      },

      confirm_collaboration(approved) {
        this.loading = true;
        initializeLambdaClient().then(client=>{
          this.lambda = client;
          var params = {
            FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
            InvocationType: "Event",
            Payload: JSON.stringify({
              auth_token: this.$store.getters.getJwtToken,
              operation_type: "APPROVAL",
              approved: approved,
              collaborator_email: this.owner_user.email,
              main_user_email: this.approve_item.email,
              suppress_notifications: false
            }),
          };
          var command = new InvokeCommand(params);
          this.lambda.send(command);
        }).then(()=>{
          setTimeout(() => {
            this.approve_collab_dialog = false;
            this.updateItem();
          }, 2500);
        }).catch(err=>{
          this.error = err.stack;
          this.loading = false;
        })
      },
        async delete_collaboration(){
          this.loading = true;
          initializeLambdaClient().then(client=>{
            this.lambda = client;

            var operation = this.cancel_colaboration
              ? "DELETE_COLLABORATOR"
              : "DELETE_MAIN_USER";
            var params = {
              FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
              InvocationType: "Event",
              Payload: JSON.stringify({
                auth_token: this.$store.getters.getJwtToken,
                operation_type: operation,
                collaborator_email: this.cancel_colaboration
                  ? this.delete_item.email
                  : this.owner_user.email,
                main_user_email: this.cancel_colaboration
                  ? this.owner_user.email
                  : this.delete_item.email,
                suppress_notifications: false
              }),
            };
            var command = new InvokeCommand(params);
            this.lambda.send(command);
          }).then(()=>{
              setTimeout(() => {
                this.delete_collab_dialog = false;
                this.updateItem();
              }, 2500);
            }).catch(err=>{
              this.error = err.stack;
              this.loading = false;
              this.delete_dialog = false;
            })
        },
        sortByKey(array, key) {
            return array.sort(function (a, b) {
              var x = a[key];
              var y = b[key];
              return x < y ? 1 : x > y ? -1 : 0;
            });
        },
        convertInfoUnit(num){
          var unit = "MB";
          // MB to GB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "GB";
          }

          // GB to TB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "TB";
          }

          // TB to PB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "PB";
          }

          // PB to EB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "EB";
          }

          num = Math.round(num*10)/10;
          return num+unit;
        },

        close() {
            if(!this.loading){
              this.has_unsaved_changes = false;
              this.original_values = {};
              this.dialog = false;
              // eventBus.$emit("updateCustomers");
              // this.$store.dispatch("DDB_GET_CUSTOMERS");
            }
        },
        age(date){
          var unit = 'seconds'
          var result = moment().diff(date, unit);
          this.last_login_color = "green_color";
          if(result>60){
            unit = 'minutes'
            result = moment().diff(date, unit);
            if(result>60){
              unit = 'hours'
              result = moment().diff(date, unit);
              if(result>24){
                unit = 'days'
                result = moment().diff(date, unit);
                this.last_login_color = "orange_color";
                if(result>7){
                  unit = 'weeks'
                  result = moment().diff(date, unit);
                  if(result>4){
                    unit = 'months'
                    result = moment().diff(date, unit);
                    if(result>12){
                      unit = 'years'
                      result = moment().diff(date, unit);
                      this.last_login_color = "red_color";
                    }
                  }
                }
              }
            }
          }

          return {result: result, unit: unit};
        },
       
        apply(){
          this.loading=true;
          this.selected_table = 1;
          this.uploadItem().then(()=>{
            this.$store.dispatch("DDB_GET_CUSTOMERS").then(()=>{
              this.pressed_apply = true;
              this.original_values = {};
              this.has_unsaved_changes = false;
              this.updateItem();
            })
          }).catch(()=>{
            this.loading = false;
          });
        },
        save() {
          this.loading=true;
          this.selected_table = 1;
          this.uploadItem().then(()=>{
            this.$store.dispatch("DDB_GET_CUSTOMERS").then(()=>{
              this.loading = false;
              this.dialog = false;
              this.original_values = {};
              this.has_unsaved_changes = false;
              this.editedItem = { addresses: [{}, {}] };
            })
            // setTimeout(() => {
            //   eventBus.$emit("updateCustomers");
            //   this.loading = false;
            //   this.dialog = false;
            //   this.editedItem = {
            //       customer_mailing_address: {},
            //       customer_physical_address: {},
            //   };
            // }, this.$store.getters.getWaitInterval);
          }).catch(()=>{
            this.loading = false;
          });
        },
        uploadItem(){
          return new Promise((resolve, reject)=>{
            (async()=>{
              this.$refs.form.validate();
            if(!this.valid){
              reject();
            }
            delete this.editedItem.wifi_count;
            delete this.editedItem.wifis;
            delete this.editedItem.updated_at;
            delete this.editedItem.sites;
            delete this.editedItem.site_count;
            delete this.editedItem.events;
            delete this.editedItem.cameras;
            delete this.editedItem.cam_count;
            delete this.editedItem.createdAt;
            delete this.editedItem._lastChangedAt;
            delete this.editedItem.cam_count_online;
            delete this.editedItem.wifi_count_online;
            delete this.editedItem.index;
            delete this.editedItem.zones;
            delete this.editedItem.customer_collaborators;
            delete this.editedItem.customer_is_collaborator;

            delete this.editedItem.animals;
            delete this.editedItem.treatments;
            delete this.editedItem.measurements;
            delete this.editedItem.phenotypes;

            delete this.editedItem.shares;
            delete this.editedItem.shares_with;

            delete this.editedItem.customer_email_address;

            var temp_addresses = this.editedItem.addresses

            if(!this.showPhysicalAddress){
              temp_addresses[1] = Object.assign({}, temp_addresses[0]);
            }

            for (const n in this.editedItem) {
                if (typeof this.editedItem[n] === "string" || this.editedItem[n] instanceof String) {
                    this.editedItem[n] = this.editedItem[n].trim();
                }
                if(!this.editedItem[n]){
                  this.editedItem[n] = null;
                }
            }


     
                try {
                  var temp_customer= {...this.editedItem}
                  delete temp_customer.id
                  delete temp_customer.addresses;
                  var temp_notification_preferences = {...this.editedItem.notification_preferences}
                  temp_notification_preferences.customer_id = this.editedItem.id
                  delete temp_customer.notification_preferences;
                  const response_c = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations["update_customer_by_pk"],
            variables: {
              pk_columns: {id: this.editedItem.id },
              _set: temp_customer,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response_c.data.errors) {
          throw new Error("GraphQL error: " + response_c.data.errors[0].message);
        }

        for(const a in temp_addresses) {
          const response_a = await axios.post(
                      this.$store.getters.getHasuraParamsWrite.url,
                      {
                         query: hasura_mutations.insert_address_one,
                         variables: {
                         object1: temp_addresses[a],
                         update_columns: [ 'province','postal_code','line_2','line_1','country','city']
                      },
                    },
                    {
                      headers: this.$store.getters.getHasuraParamsWrite.headers,
                    }
                  );
                  if (response_a.data.errors) {
         throw new Error("GraphQL error: " + response_a.data.errors[0].message);
         }
        }


        const response_np = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations["update_notification_preference_by_pk"],
            variables: {
              pk_columns: {customer_id: this.editedItem.id },
              _set: temp_notification_preferences,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response_np.data.errors) {
          throw new Error("GraphQL error: " + response_np.data.errors[0].message);
        }
        
                  
                    resolve();
                }
                catch (err) {
                    console.log(err);
                    this.error = err.errors[0].message;
                    reject();
                }
            
            })();
          })
        },
        async deleteItem(id) {
          console.log(id)
          //   this.selected_table = 1;
          //   this.loading = true;
          //   this.delete_dialog = false;
          //   try{
          //   await API.graphql({
          //       query: mutations.deleteCustomer,
          //       variables: {
          //           input: { id: id },
          //       },
          //   });
          //   this.$nextTick(() => {
          //     setTimeout(() => {
          //         eventBus.$emit("updateCustomers");
          //         this.dialog = false;
          //         this.loading = false;
          //         this.editedItem = {
          //             customer_mailing_address: {},
          //             customer_physical_address: {},
          //         };
          //       }, this.$store.getters.getWaitInterval);
          //   });
          // }catch(err){
          //   console.log(err);
          //   this.error = err.errors[0].message;
          //   this.loading = false;
          // }
        },
        updateItem(id){

          if(id){
            this.editedItem.id = id
          }
          if(!this.editedItem.id){
            return;
          }
          this.loading = true;
          if(this.$route.query.id!=this.editedItem.id){
            this.$router.replace({query: { id: this.editedItem.id }});
          }

          this.getNotes();
          

          this.getRFIDData([this.query3.replace("<-- INSERT ID HERE -->", `'${this.editedItem.id}'`)]);

          this.$store.dispatch("DDB_GET_EDITED_CUSTOMER", this.editedItem.id).then(async ()=>{
            var temp_edited_item = {}
              temp_edited_item =  JSON.parse(JSON.stringify(this.$store.getters.getEditedCustomer))
              if (!temp_edited_item.addresses || !temp_edited_item.addresses[0]) {
                temp_edited_item.addresses = [{},{}]
              }
              this.editedItem = JSON.parse(JSON.stringify(temp_edited_item))
              this.editedItem.cam_count = this.editedItem.cameras.length;
              this.editedItem.wifi_count = this.editedItem.wifis.length;

              this.all_collaborators = [];

              this.collab_update_key++;

              if(JSON.stringify(this.editedItem.customer_mailing_address) === JSON.stringify(this.editedItem.customer_physical_address)){
              this.showPhysicalAddress = false;
              }else{
                this.showPhysicalAddress = true;
              }

              if (
                this.editedItem.cam_count > 0
              ) {
                this.editedItem.cam_count_online =
                this.editedItem.cameras.reduce(
                    function (n, camera) {
                      return n + (camera.camera_status == "ONLINE");
                    },
                    0
                  );
              } else {
                this.editedItem.cam_count_online = 0;
              }

              if (
                this.editedItem.wifi_count > 0
              ) {
                this.editedItem.wifi_count_online =
                this.editedItem.wifis.reduce(
                    function (n, wifi) {
                      return n + (wifi.status == "ONLINE");
                    },
                    0
                  );
              } else {
                this.editedItem.wifi_count_online = 0;
              }


              async function getUser(t) {
                var params = {
                    UserPoolId: process.env.VUE_APP_CLIENT_USER_POOL_ID,
                    Username: t.editedItem.id
                  };
                var command;
                initializeCognitoIdentityProviderClient().then(client=>{
                  cognitoidentityserviceprovider = client;
                  command = new AdminGetUserCommand(params);
                  return cognitoidentityserviceprovider.send(command);
                }).then(data=>{
                  var user = {};
                  user.updated_at = data.UserLastModifiedDate;
                  user.username = data.Username;
                  for (const n in data.UserAttributes) {
                    user[data.UserAttributes[n].Name] =
                    data.UserAttributes[n].Value;
                  }
                  t.owner_user = user;

                  command = new AdminListUserAuthEventsCommand(params);
                  return cognitoidentityserviceprovider.send(command);
                }).then(data=>{
                  if(data.AuthEvents.length>0){
                    var date = data.AuthEvents[0].CreationDate;
                    t.last_login_time = t.age(date);
                  }else{
                    t.last_login_time = {result: "unavailable", unit: ""}
                    t.last_login_color = "";
                  }
                  t.loading = false;
                }).catch(err=>{
                  console.log(err);
                  t.owner_user= {
                        email: "",
                        given_name: "",
                        family_name: ""
                      }
                  t.last_login_time = {result: "unavailable", unit: ""}
                  t.last_login_color = "";
                  t.error = err;
                  t.loading = false;
                });
              }

              getUser(this);
            }
          );
          
        },
        parseRFIDData(data){
          this.RFIDData = [
          ...this.RFIDData,
          ...data.Rows.map(e=>{
            var result = {};
            data.ColumnInfo.forEach((item, index)=>{
              result[item.Name] = e.Data[index].ScalarValue;
            });
            return result;
          })];
        },
        extractRFIDData(){
          var extract = "";
          if(this.RFID_date_range.length<=0){
            return;
          }else if(this.RFID_date_range.length == 1){
            extract = this.cropRFIDData(this.RFID_date_range[0], this.RFID_date_range[0]);
          }else{
            extract = this.cropRFIDData(this.RFID_date_range[0], this.RFID_date_range[1]);
          }

          this.download("Extracted.csv", extract);
        },
        cropRFIDData(start_datetime, end_datetime){
          var start_moment = moment(start_datetime).startOf('day');
          var end_moment = moment(end_datetime).endOf('day');

          var result = this.RFIDData.filter(e=>{
            return moment(e.time).isBetween(start_moment, end_moment);
          });

          var csv = this.toCSV(result);
          console.log(csv)
          return csv;
        },
     
      
        async getNotes(){
          this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.editedItem.id,
            type: "customer",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.notes = this.$store.getters.getSelectedNotes;
            }
          });
          this.sortByKey(this.notes, "datetime");
        },
     
        editCamera(item) {

              var editedItem = Object.assign({}, item);
              var temp_item = Object.assign({}, item);
              eventBus.$emit("open_dialog_cameras", {
                item: temp_item,
                create_new: false,
                owner_name: this.editedItem.id,
                site_name: this.editedItem.sites && this.editedItem.sites.length ? this.editedItem.sites[0].id : null,
                camera_broken_bool: editedItem.camera_broken == "YES",
              });
            
        },
          get_date_diff(date) {

            return moment(date).format("YYYY-MM-DD HH:mm:ss");
          },
        editSite(item) {

            eventBus.$emit("open_dialog_sites", {
              item: Object.assign({}, item),
              create_new: false,
              owner_name: this.editedItem.id,
            });
          
        },
        editWifi(item) {
            eventBus.$emit("open_dialog_wifi", {
              item: Object.assign({}, item),
              create_new: false,
              owner_name: this.editedItem.id,
              site_name: this.editedItem.sites && this.editedItem.sites.length ? this.editedItem.sites[0].id : null,
            });
          
        },

        editCollaboration(item) {
          if (item == "create_new") {
            eventBus.$emit("open_dialog_collaboration", {
              item: this.selected_colab ? {owner_email: this.owner_user.email} : {collaborator_email: this.owner_user.email},
              create_new: true,
              user: this.owner_user,
              selected_colab: this.selected_colab
            });
          } else {
            console.log(item)
            eventBus.$emit("open_dialog_collaboration", {
              item: {
                ...item
              },
              create_new: false,
              user: this.owner_user,
              selected_colab: this.selected_colab
            });
          }
        },
        handle_spacing(height) {
          this.$nextTick(()=>{
            if (height>180){
              this.device_spacing = "mb-5";
            }else if(height>158){
              this.device_spacing = "mb-7";
            }else{
              this.device_spacing = "mb-8";
            }
          })
        }
    },
    watch: {
        dialog() {
            if (!this.dialog) {
                this.owner_name = "";
                this.site_name = "";
            }
            this.$store.commit('SET_CUSTOMER_CARD_DIALOG', this.dialog);
        },
        // "editedItem.id"(){
        //   this.updateItem(this.editedItem.id);
        // },
        label_sort_by_name(){
          if(this.label_sort_by_name){
            this.label_sort_by_data = false;
          }
        },
        label_sort_by_data(){
          if(this.label_sort_by_data){
            this.label_sort_by_name = false;
          }
        },
        // selected_table(){
        //   if(this.selected_table == 0){
        //     const h = this.handle_spacing;

        //     this.$nextTick(()=>{

        //     const resizeObserver = new ResizeObserver(function() {
        //         console.log("Size changed");
        //         console.log(document.getElementById('user_info_card').offsetHeight);
        //         var height = document.getElementById('user_info_card').offsetHeight || 0;
        //         h(height);
        //       });
        //         resizeObserver.observe(document.getElementById('user_info_card'));
        //   });
        //   }
        // }
    },
    computed: {
      notification_fields(){
        return this.$store.getters.getNotificationFields;
      },
      time_ranges() {
        return this.$store.getters.getTimeRanges;
      },
      time_settings() {
        return this.$store.getters.getTimeSettings;
      },
      header_animal(){
        return [
          {
            text: 'RFID',
            value: 'animal_rfid_current'
          },
          {
            text: 'Sex',
            value: 'animal_sex'
          },
          {
            text: 'Classification',
            value: 'animal_classification'
          },
        ];
      },
      header_treatment(){
        return [
          {
            text: 'Date',
            value: 'treatment_date'
          },
          {
            text: 'Type',
            value: 'treatment_type'
          },
          {
            text: 'Reason',
            value: 'treatment_reason'
          },
        ];
      },
      header_measurement(){
        return [
          {
            text: 'RFID',
            value: 'animal.animal_rfid_current'
          },
          {
            text: 'Date',
            value: 'measurement_date'
          },
          {
            text: 'Type',
            value: 'measurement_type'
          },
          {
            text: 'Value',
            value: 'measurement_value'
          },
        ];
      },
      header_phenotype(){
        return [
          {
            text: 'RFID',
            value: 'animal.animal_rfid_current'
          },
          {
            text: 'Date',
            value: 'phenotype_date'
          },
          {
            text: 'Type',
            value: 'phenotype_type'
          },
          {
            text: 'Score',
            value: 'phenotype_score'
          },
        ];
      },
      header_wifi(){
        return [
          {
            text: 'Man.',
            value: 'manufacturer',
          },
          {
            text: 'Type',
            value: 'device_type'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Shipping',
            value: 'shipped'
          },
          {
            text: 'Model',
            value: 'model'
          },
          { text: "", value: "data-table-expand" },
        ];
      },
      header_camera(){
        return [
          { text: '',
            value: 'icon',
            width: "1%"
        },
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Last Upload',
            value: 'latest_upload'
          },

          { text: "", value: "data-table-expand" },
        ];
      },
      header_site(){
        return [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Name',
            value: 'site_name'
          },
          {
            text: 'Assigned ID',
            value: 'site_assigned_id'
          },
          {
            text: 'Coordinates',
            value: 'site_location'
          },
          { text: "", value: "data-table-expand" },
        ];
      },
      header_overview_notes(){
        return [
          {
            text: 'Date',
            value: 'datetime',
            width: '120px',
          },
          {
            text: 'Author',
            value: 'author',
            width: '220px'
          },
          {
            text: 'Note',
            value: 'comment'
          }
        ];
      },
      header_collaborator(){
        return [
          // {
          //   text: 'ID',
          //   value: 'id'
          // },
          {
            text: 'Collaborator',
            value: 'email'
          },
          {
            text: 'Approval',
            value: 'approved'
          },
          {
            text: 'Permission',
            value: 'permission',
            sortable: false,
          },
          { text: "", value: "data-table-expand" },
          // {
          //   text: 'Identity',
          //   value: 'identity'
          // }
        ];
      },
      header_RFID(){
        return [
          {text: "ID", value: "UID"},
          {text: "Hostname", value: "Hostname"},
          {text: "Measure Name", value: "measure_name"},
          {text: "Measure Value", value: "measure_value"},
          {text: "Upload Time", value: "time"}
        ];
      },
      countries(){
        var c = [];
        for(var i in countryData){
          c.push(countryData[i].country);
        }
        return c;
      },
      owner_customer_names() {
          return this.$store.getters.getOwnerNameObject[0];
      },
      name_owner_names() {
          return this.$store.getters.getOwnerNameObject[1];
      },
      site_names() {
          if (this.$store.getters.getCustomerNames[1]) {
              return this.$store.getters.getCustomerNames[1][this.owner_name];
          }
          else {
              return [];
          }
      },
      customer_names() {
        var customers = [
          ...new Set(
            this.$store.getters.getCustomerObjects.map((item) => {
              try {
                return {
                  text: (item.company_name?(item.company_name+" - "):"") + this.formulateNames(item.first_name, item.last_name) + " - " + item.id,
                  value: item.id,
                };
              } catch {
                return "null";
              }
            })
          ),
        ];

        customers = this.sortByKey([...customers], "text").reverse();

        return customers;
        // return this.$store.getters.getCustomerNames[0];
      },


    },
    mixins: [rulesMixin, RFIDMixin, nameMixin]
};
</script>

<style scoped>
.tall_cart > div {
  height: var(--cam-count);
}
.collab_tabs {
  float: left;
  width: 400px;
}
</style>