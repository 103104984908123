<template>
  <div class="bg">
    <v-container fill-height fluid>
      <v-row align="center">
        <v-col>
          <v-card
            class="mx-auto"
            max-width="1000"
            min-height="565"
            align="right"
            :id="'main_login_image' + selected_image"
          >
            <v-card
              max-width="350"
              min-height="565"
              align="center"
              class="rounded-l-0"
              flat
            >
              <v-container>
                <v-img
                  class="mt-1"
                  v-if="$vuetify.theme.dark"
                  src="@/assets/LogoV2-White_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
                <v-img
                  class="mt-1"
                  v-if="!$vuetify.theme.dark"
                  src="@/assets/LogoV2-Black_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
              </v-container>
              <v-container v-if="loading"
                ><v-progress-circular
                class="mt-4"
                  v-if="loading"
                  indeterminate
                  size=86
                  color="primary"
                ></v-progress-circular
              ></v-container>
              <v-container v-else-if="TOTP_setup">
                <span
                  style="font-size: 22px"
                  class="d-flex justify-center primary_blue--text mt-n2 side_title"
                >
                  MFA Setup
                </span>
                <v-stepper v-model="TOTP_setup_stepper" class="elevation-0">
                  <v-stepper-header class="mt-n5 elevation-0">
                    <v-stepper-step
                    :complete="TOTP_setup_stepper > 1"
                    step="1"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    step="2"
                    color="primary_blue"
                  >
                  </v-stepper-step>
                  </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content class="pt-0" step="1">
                    <div>Scan/Input the code below in authenticator APP</div>
                    <VueQrcode :value="TOTP_qrcode" :options="{ width: 175 }"></VueQrcode>
                    <div class="mb-5 font-weight-bold primary_blue--text">{{ TOTP_secret_code }}</div>
                    <!-- <div class="d-flex justify-space-between"> -->
                      <!-- <v-btn color="primary_blue" @click="TOTP_setup = false" dark>Cancel</v-btn> -->
                      <v-btn color="primary_blue" @click="TOTP_setup_stepper = 2" dark>Next</v-btn>
                    <!-- </div> -->
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <div class="mb-5">Enter an MFA code to complete MFA setup</div>
                    <v-form v-model="setup_valid" @submit="complete_TOTP_setup">
                      <v-text-field
                      v-model="mfa_code"
                      label="MFA Code"
                      outlined
                      clearable
                      :rules="[rules.required, rules.mfa]"
                      >
                      </v-text-field>
                    <div class="d-flex justify-space-between">
                      <v-btn
                      color="primary_blue"
                      @click="TOTP_setup_stepper = 1"
                      dark
                      >
                        Back
                      </v-btn>
                      <v-btn
                      color="primary_blue"
                      type="submit"
                      :dark="setup_valid"
                      :disabled="!setup_valid"
                      >
                        Submit
                      </v-btn>
                    </div>
                  </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              </v-container>
              <v-container v-else-if="TOTP_verification">
                <span
                  style="font-size: 22px"
                  class="d-flex justify-center primary_blue--text mb-10 mt-n2 side_title"
                >
                  MFA Verification
                </span>
                <div class="mb-5">Enter an MFA code to complete sign-in</div>
                <v-form v-model="signin_valid" @submit="complete_TOTP_verification">
                  <v-text-field
                  v-model="mfa_code"
                  label="MFA Code"
                  class="mx-2"
                  outlined
                  clearable
                  :rules="[rules.required, rules.mfa]"
                  >
                  </v-text-field>
                <div class="mx-2 d-flex justify-space-between">
                      <v-btn
                      color="primary_blue"
                      @click="TOTP_verification = false"
                      dark
                      >
                        Back
                      </v-btn>
                      <v-btn
                      color="primary_blue"
                      type="submit"
                      :dark="signin_valid"
                      :disabled="!signin_valid"
                      >
                        Submit
                      </v-btn>
                    </div>
                  </v-form>
              </v-container>
              <v-container v-else-if="login_rp && !loading">
                <span
                  style="font-size: 22px"
                  class="d-flex justify-center primary_blue--text mb-10 mt-n2"
                  data-cy="text_customer_portal"
                >
                  Admin Portal
                </span>

                <p data-cy="text_login_to_your_account">Login to your account</p>

                <v-form
                  ref="form-login"
                  @submit.prevent.stop="user_login()"
                  v-model="isLoginFormValid"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    class="mx-2"
                    outlined
                    type="email"
                    clearable
                    color="primary_blue"
                    data-cy="field_email"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="password"
                    label="Password"
                    class="mx-2"
                    outlined
                    color="primary_blue"
                    clearable
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (visible = !visible)"
                    :type="visible ? 'text' : 'password'"
                    :rules="[validateNotEmpty]"
                    data-cy="field_password"
                  >
                  </v-text-field>

                  <div align="right">
                    <p
                    style="color: #003f51; cursor: pointer; display: inline-block;"
                    @click="login_rp = false"
                    data-cy="href_forgot_password"
                  >
                    Forgot password?
                  </p>
                  </div>

                  <v-btn
                    elevation="4"
                    large
                    color="primary_blue"
                    type="submit"
                    :disabled="!isLoginFormValid"
                    :dark="isLoginFormValid"
                    data-cy="btn_login"
                  >
                    Login
                  </v-btn>
                </v-form>
              </v-container>
              <v-stepper v-else v-model="stepperCount" class="elevation-0">
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :complete="stepperCount > 1"
                    step="1"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepperCount > 2"
                    step="2"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" color="primary_blue">
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <!-- Enter email form -->
                  <v-stepper-content step="1" class="my-12">
                    <p data-cy="text_enter_your_email">Enter your email</p>
                    <v-form v-model="isFormOneValid">
                      <v-text-field
                        label="Email"
                        v-model="email_pr"
                        outlined
                        type="email"
                        clearable
                        :rules="[validateEmail]"
                        color="primary_blue"
                        data-cy="field_email"
                      >
                      </v-text-field>
                    </v-form>
                    <v-btn
                      color="primary_blue"
                      @click="onSendCode"
                      large
                      elevation="4"
                      :dark="isFormOneValid"
                      :disabled="!isFormOneValid"
                      data-cy="btn_send_code"
                    >
                      Send Code
                    </v-btn>
                    <br />
                      <div
                      class="mt-4"
                      style="color: #003f51; cursor: pointer; display: inline-block;"
                      @click="login_rp = true"
                      data-cy="href_login_instead"
                    >
                      Login instead
                    </div>
                  </v-stepper-content>
                  <!-- Email confirmation code form -->
                  <v-stepper-content step="2">
                    <p data-cy="text_reset_password">Reset your password</p>
                    <p data-cy="text_verification_code_sent">
                      We've sent you a verification code to your email account.
                    </p>
                    <v-form v-model="isFormTwoValid">
                      <v-text-field
                        label="Verifcation code"
                        v-model="verificationCode"
                        color="primary_blue"
                        outlined
                        clearable
                        :rules="[validateIsNumber]"
                        data-cy="field_varification_code"
                      >
                      </v-text-field>
                    </v-form>

                    <v-btn
                      text
                      @click="stepperCount = 1"
                      large
                      elevation="4"
                      class="ma-2"
                      data-cy="btn_back"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary_blue"
                      @click="stepperCount = 3"
                      large
                      :disabled="!isFormTwoValid"
                      elevation="4"
                      :dark="isFormTwoValid"
                      data-cy="btn_next"
                    >
                      Next
                    </v-btn>
                    <br />
                    <v-btn @click="onSendCode" class="my-5" text small bottom data-cy="btn_resend_code">
                      Resend code
                    </v-btn>
                  </v-stepper-content>
                  <!-- Reset password form -->
                  <v-stepper-content step="3" class="my-4">
                    <p class="">Enter your new password</p>
                    <v-form v-model="isFormThreeValid">
                      <v-text-field
                        v-model="newPassword"
                        label="New Password"
                        :rules="[validatePassword]"
                        outlined
                        clearable
                        color="primary_blue"
                        :append-icon="
                          newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                        "
                        @click:append="
                          () => (newPasswordVisible = !newPasswordVisible)
                        "
                        :type="newPasswordVisible ? 'text' : 'password'"
                        data-cy="field_new_password"
                      ></v-text-field>
                      <v-text-field
                        v-model="confirmPassword"
                        color="primary_blue"
                        label="Confirm Password"
                        :rules="[
                          validateSamePassword(
                            this.newPassword,
                            this.confirmPassword
                          ),
                        ]"
                        outlined
                        clearable
                        :append-icon="
                          confirmNewPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                        "
                        @click:append="
                          () =>
                            (confirmNewPasswordVisible =
                              !confirmNewPasswordVisible)
                        "
                        :type="confirmNewPasswordVisible ? 'text' : 'password'"
                        data-cy="field_confirm_password"
                      ></v-text-field>
                      <v-btn
                        text
                        @click="stepperCount = 2"
                        large
                        elevation="2"
                        class="ma-2"
                        data-cy="btn_back"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        color="primary_blue"
                        @click="confirmForgotPassword"
                        large
                        :dark="isFormThreeValid"
                        :disabled="!isFormThreeValid"
                        elevation="2"
                        data-cy="btn_reset"
                      >
                        Reset
                      </v-btn>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="error_snackbar" :timeout="3000" color="primary_red">
      {{ error }}
    </v-snackbar>
    <v-snackbar v-model="info_snackbar" :timeout="3000" color="primary_blue">
      {{ info }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  validateIsNumber,
  validateSamePassword,
  validateEmail,
  validatePassword,
  validateNotEmpty,
} from "@/utilities/ValidationRules";
import { CognitoIdentityProviderClient, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
var cognitoidentityserviceprovider = new CognitoIdentityProviderClient({region: process.env.VUE_APP_AWS_REGION});
import VueQrcode from '@chenfengyuan/vue-qrcode';
import rulesMixin from "../mixins/rulesMixin";

// @vuese
// @group Views
export default {
  name: "Login",
  components: {
    VueQrcode
  },
  mixins: [rulesMixin],
  data() {
    return {
      selected_image: null,
      //
      error: null,
      error_snackbar: false,
      //
      email: "",
      visible: false,
      password: "",
      isLoginFormValid: false,
      login_rp: true,
      //
      stepperCount: 1,
      email_pr: "",
      verificationCode: "",
      newPassword: "",
      confirmPassword: "",
      newPasswordVisible: false,
      confirmNewPasswordVisible: false,
      isFormOneValid: false,
      isFormTwoValid: false,
      isFormThreeValid: false,
      loading: false,

      // TOTP
      TOTP_qrcode: "https://www.onecup.ai/",
      TOTP_secret_code: "ABCDEFG",
      TOTP_setup: false,
      TOTP_setup_stepper: 1,
      TOTP_verification: false,
      mfa_code: null,
      setup_valid: false,
      signin_valid: false,

      // INFO
      info_snackbar: false,
      info: "MFA setup completed sucessfully. Please sign in again"
    };
  },
  methods: {
    validateIsNumber,
    validateSamePassword,
    validateEmail,
    validatePassword,
    validateNotEmpty,
    complete_TOTP_verification(){
      this.loading = true;
      this.$store.dispatch("complete_TOTP_verification", this.mfa_code).then(()=>{
        const redirectPath = this.$route.query.redirect || "/";
        this.$router.push(redirectPath);
        this.TOTP_verification = false;
        this.mfa_code = null;
        this.loading = false;
      }).catch(error=>{
        this.error = error;
        this.error_snackbar = true;
        this.loading = false;
      })
    },
    complete_TOTP_setup(){
      this.loading = true;
      this.$store.dispatch("complete_TOTP_setup", this.mfa_code).then(()=>{
        this.TOTP_setup = false;
        this.mfa_code = null;
        this.loading = false;
        this.info = "MFA setup completed sucessfully. Please sign in again";
        this.info_snackbar = true;
      }).catch(error=>{
        this.error = error;
        this.error_snackbar = true;
        this.loading = false;
      })
    },
    async user_login() {
      if (!this.$refs["form-login"]) return;
      this.$store.state.button = false;
      this.loading = true;
      await this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((challengeName) => {
          if (this.$store.state.loginError) {
            this.error = this.$store.state.loginError;
            this.error_snackbar = true;
            this.loading = false;
          }
          if(challengeName === 'MFA_SETUP'){
            this.$store.dispatch("start_TOTP_setup").then(data=>{
              this.mfa_code = null;
              this.TOTP_qrcode = data.url;
              this.TOTP_secret_code = data.code;
              this.TOTP_setup = true;
              this.loading = false;
            }).catch(error=>{
              this.error = error;
              this.error_snackbar = true;
              this.loading = false;
            })
          }else if(challengeName === 'SOFTWARE_TOKEN_MFA'){
            this.mfa_code = null;
            this.TOTP_verification = true;
            this.loading = false;
          }
          // if (
          //   typeof this.$store.state.loginError != "string" ||
          //   this.$store.state.loginError == ""
          // ) {
          //   const redirectPath = this.$route.query.redirect || "/";

          //   this.$router.push(redirectPath);
          // }
        });
      // this.loading = false;
      return false;
    },

    validate_email(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    onSendCode() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        Username: this.email_pr /* required */,
      };
      this.signUp_status = "error";
      var command = new ForgotPasswordCommand(params);
      cognitoidentityserviceprovider.send(command).then(()=>{
        if (this.validate_email(this.email_pr)) {
          this.stepperCount = 2;
        }
      }).catch(err=>{
        this.error = err
        this.error_snackbar = true;
      });
    },
    confirmForgotPassword() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        ConfirmationCode: this.verificationCode /* required */,
        Password: this.newPassword /* required */,
        Username: this.email_pr /* required */,
      };
      this.signUp_status = "error";
      var command = new ConfirmForgotPasswordCommand(params);
      cognitoidentityserviceprovider.send(command).then(data=>{
        console.log("success");
        console.log(data);
        this.login_rp = true;
      }).catch(()=>{
        this.error = this.$store.state.loginError;
        this.error_snackbar = true;
      });
    },
  },
  created() {
    this.selected_image = Math.floor(Math.random() * 12);
  },
  watch: {
    login_rp() {
      this.selected_image = Math.floor(Math.random() * 12);
      if (this.login_rp) {
        (this.stepperCount = 1), (this.email_pr = "");
        this.verificationCode = "";
        this.newPassword = "";
        this.confirmPassword = "";
      } else {
        this.email = "";
        this.visible = false;
        this.password = "";
      }
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: #310972;
  height: 100%;
}
#main_login_image1 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image01.png")
    repeat scroll 0% 0%;
}
#main_login_image2 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image02.png")
    repeat scroll 0% 0%;
}
#main_login_image3 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image03.png")
    repeat scroll 0% 0%;
}
#main_login_image4 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image04.png")
    repeat scroll 0% 0%;
}
#main_login_image5 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image05.png")
    repeat scroll 0% 0%;
}
#main_login_image6 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image06.png")
    repeat scroll 0% 0%;
}
#main_login_image7 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image07.png")
    repeat scroll 0% 0%;
}
#main_login_image8 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image08.png")
    repeat scroll 0% 0%;
}
#main_login_image9 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image09.png")
    repeat scroll 0% 0%;
}
#main_login_image10 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image10.png")
    repeat scroll 0% 0%;
}
#main_login_image11 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image11.png")
    repeat scroll 0% 0%;
}
#main_login_image12 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image12.png")
    repeat scroll 0% 0%;
}
#main_login_image13 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image13.png")
    repeat scroll 0% 0%;
}
#main_login_image14 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image14.png")
    repeat scroll 0% 0%;
}
</style>