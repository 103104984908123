<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card :loading="loading" class="pl-2 pr-2">
            <v-row>
              <v-col class="pa-0" cols="12"
                ><v-tabs class="table_tabs ml-3 mt-3 mr-3">
                  <v-tab to="/">Overview</v-tab>
                  <v-tab to="/footageuploads">Cameras</v-tab>
                  <v-tab to="/inference">Inference</v-tab>
                  <v-tab to="/services">Services</v-tab>
                  <v-tab to="/system">System</v-tab>
                  <v-tab to="/triton">Triton</v-tab>
                </v-tabs>
                <div id="lf_btns">
                  <customer-filter
                    v-model="customer"
                    :pad="5"
                    :disabled="dialog || loading"
                  ></customer-filter>
                  <v-icon class="mt-5" @click="refresh">mdi-refresh</v-icon>
                  <v-icon
                    class="mt-5"
                    @click="set_borders(true)"
                    v-if="!bordered"
                    >mdi-border-all-variant</v-icon
                  >
                  <v-icon
                    class="mt-5"
                    @click="set_borders(true)"
                    v-else
                    color="primary"
                    >mdi-border-all-variant</v-icon
                  >
                  <v-icon
                    class="mt-5"
                    @click="show_text = !show_text"
                    v-if="!show_text"
                    >mdi-format-text</v-icon
                  >
                  <v-icon
                    class="mt-5"
                    @click="show_text = !show_text"
                    v-else
                    color="primary"
                    >mdi-format-text</v-icon
                  >
                </div>
              </v-col>

              <v-col xl="2" lg="2" md="3" sm="4" cols="6" class="main_col_vids">
                <v-row no-gutters>
                  <v-col xl="9" cols="10"
                    ><v-select
                      class="select_filter direction_sort mt-2"
                      :items="sort_item_list"
                      v-model="sort_field"
                      dense
                      flat
                      hide-details
                    ></v-select
                  ></v-col>
                  <v-col xl="3"
                    ><v-icon
                      v-if="sort_direction"
                      id="sort_icon"
                      @click="changeSortDir"
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon
                      v-if="!sort_direction"
                      id="sort_icon"
                      @click="changeSortDir"
                      >mdi-arrow-up</v-icon
                    ></v-col
                  >
                </v-row>
              </v-col>
              <v-col xl="1" md="1" sm="2" class="mb_hidden">
                <v-select
                  class="select_filter select_grid mt-2"
                  :items="[3, 4, 5, 6, 8, 10, 12]"
                  v-model="grid"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
              <v-col xl="2" md="2" sm="3" cols="6"
                ><v-select
                  class="select_filter mt-2"
                  :items="sources"
                  v-model="source"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>

              <v-col
                xl="3"
                md="3"
                sm="4"
                cols="6"
                v-if="!customers.includes(undefined)"
              >
                <v-autocomplete
                  class="select_filter mt-2"
                  :items="customers"
                  v-model="customer"
                  dense
                  flat
                  hide-details
                ></v-autocomplete
              ></v-col>

              <v-col xl="2" md="2" sm="3" cols="6">
                <v-select
                  class="select_filter mt-2"
                  :items="[
                    'All Status',
                    'Non Corrupted',
                    'Corrupted',
                    'Unknown',
                    'Has Data',
                    'No Data',
                  ]"
                  v-model="corruption"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
              <v-col xl="1" md="2" sm="3" cols="6"
                ><v-select
                  class="select_filter mt-2"
                  :items="resolutions"
                  v-model="resolution"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
            </v-row>
            <v-row class="ml-0 mr-0 pb-3" v-if="last_footage_by_camera && load">
              <div id="grid-container-lf">
                <div
                  class="pa-0 grid_container_f"
                  v-for="(c, index) in last_footage_by_camera"
                  :key="c.id"
                  @click="select_camera(index)"
                  :class="age(c.camera_latest_upload)[1]"
                >
                  <vue-load-image style="height: 100%">
                    <img
                      slot="image"
                      v-if="c.customer"
                      alt=""
                      :src="`${
                        cookie_bucket +
                        c.customer.id +
                        '/thumbnails/' +
                        c.id +
                        '_latest' +
                        '.jpg' +
                        dummy_string()
                      }`"
                      class="grid_img_f"
                    />
                    <img
                      slot="preloader"
                      class="grid_img_f"
                      src="@/assets/loader.png"
                    />
                    <div slot="error" class="no-image">
                      <div>No<br />Image</div>
                    </div>
                  </vue-load-image>
                  <span class="cam_name_grid" v-if="show_text"
                    ><span class="darker_bckrnd">{{
                      c.camera_name
                    }}</span></span
                  >
                  <span class="cam_id_grid" v-if="show_text"
                    ><span class="darker_bckrnd">{{ c.id }}</span></span
                  >
                  <span class="customer_name_grid" v-if="show_text"
                    ><span class="darker_bckrnd" v-if="c.customer">{{
                      c.customer.customer_first_name +
                      " " +
                      c.customer.customer_last_name
                    }}</span></span
                  >
                  <span class="customer_id_grid" v-if="show_text"
                    ><span class="darker_bckrnd" v-if="c.customer">{{
                      c.customer.id
                    }}</span></span>
                  <span
                    class="source_name_grid"
                    v-if="c.camera_latest_upload_source && show_text"
                  >
                    <span class="darker_bckrnd">{{
                      c.camera_latest_upload_source
                    }}</span></span
                  >
                  <span class="cam_date_grid"
                    ><span
                      class="darker_bckrnd"
                      v-if="c.camera_latest_upload != '' && show_text"
                      >{{ age(c.camera_latest_upload)[0] }}</span
                    ></span
                  >
                  <span class="cam_index_grid" v-if="!show_text">
                    <span class="darker_bckrnd">{{ index + 1 }}</span></span
                  >
                  <span
                    class="cam_model_grid"
                    v-if="c.camera_model && show_text"
                  >
                    <span class="darker_bckrnd">{{ c.camera_model }}</span>
                  </span>
                  <span
                    class="cam_bitrate_grid"
                    v-if="c.camera_latest_upload_bitrate_kbps && show_text"
                  >
                    <span class="darker_bckrnd">{{
                      c.camera_latest_upload_bitrate_kbps + " kbps"
                    }}</span>
                  </span>
                  <span
                    class="cam_fps_grid"
                    v-if="c.camera_latest_upload_fps && show_text"
                  >
                    <span class="darker_bckrnd">{{
                      c.camera_latest_upload_fps + " fps"
                    }}</span>
                  </span>
                  <span
                    class="cam_resolution_grid"
                    v-if="c.camera_latest_upload_resolution && show_text"
                  >
                    <span class="darker_bckrnd">{{
                      c.camera_latest_upload_resolution
                    }}</span>
                  </span>
                </div>
              </div>
              <div class="skeleton_container">
                <v-skeleton-loader
                  v-if="footage_next_token"
                  v-intersect.quiet="nextPage"
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      :max-width="is_json ? `1400px` : '1000px'"
      min-width="450px"
      v-if="
        last_footage_by_camera.length > 0 &&
        last_footage_by_camera[selected_index] != undefined
      "
    >
      <v-card>
        <v-card-title>
          <div id="full_size_ct_div">
            <b>{{ last_footage_by_camera[selected_index].camera_name }}</b>
            -
            <b
              class="footage_link"
              @click="
                camera_settings(last_footage_by_camera[selected_index].id)
              "
            >
              {{
                last_footage_by_camera[selected_index].customer
                  .customer_first_name +
                " " +
                last_footage_by_camera[selected_index].customer
                  .customer_last_name +
                " (" +
                last_footage_by_camera[selected_index]
                  .camera_latest_upload_source +
                ")"
              }}</b
            >
            - <b>{{ last_footage_by_camera[selected_index].camera_model }}</b>
            <v-btn
              class="footage_link ml-1"
              style="color: gray; font-size: medium"
              text
              depressed
              @click="copy(last_footage_by_camera[selected_index].id)"
              small
            >
              COPY ID
              <v-icon small>{{ is_copied ? "mdi-check" : "" }}</v-icon></v-btn
            >
            <span class="nav_arrows_fl">
              <v-icon
                large
                class="mr-3"
                @click="
                  redirect_to_video(last_footage_by_camera[selected_index].id)
                "
                >mdi-video-box</v-icon
              >
              <v-tooltip color="black" bottom v-if="json_file_not_present">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      v-model="is_json"
                      label="BETSY"
                      hide-details
                      inset
                      :disabled="json_file_not_present"
                      class="mt-1"
                    >
                    </v-switch>
                  </div>
                </template>
                <div>Sorry! Betsy Vision not available for this video.</div>
              </v-tooltip>
              <v-switch
                v-if="!json_file_not_present"
                v-model="is_json"
                label="BETSY"
                hide-details
                inset
                :disabled="json_file_not_present"
                :loading="json_loading ? 'false' : 'primary'"
                class="mt-1"
              >
              </v-switch>
              <v-icon
                large
                class="mt-n1"
                @click="selected_index -= 1"
                v-if="selected_index > 0"
                >mdi-chevron-left</v-icon
              ><span v-else class="mr-9"></span>
              <v-icon
                large
                class="mt-n1"
                @click="selected_index += 1"
                v-if="selected_index < last_footage_by_camera.length - 1"
                >mdi-chevron-right</v-icon
              ><span v-else class="mr-9"></span
            ></span>
          </div>
        </v-card-title>

        <v-card-text>
          <!-- <img
            alt=""
            :src="`${
              cookie_bucket +
              last_footage_by_camera[selected_index].customer.id +
              '/' +
              last_footage_by_camera[selected_index]
                .camera_footage_thumbnail_url +
              '.jpg' +
              dummy_string()
            }`"
            class="grid_img_f"
          />
          <span
            class="age_large_img"
            v-if="
              last_footage_by_camera[selected_index].camera_latest_upload != ''
            "
          >
            <span class="darker_bckrnd">{{
              age(
                last_footage_by_camera[selected_index].camera_latest_upload
              )[0]
            }}</span></span
          >
          <span
            class="resolution_large_img"
            v-if="
              last_footage_by_camera[selected_index]
                .camera_latest_upload_resolution
            "
          >
            <span class="darker_bckrnd">{{
              last_footage_by_camera[selected_index]
                .camera_latest_upload_resolution
            }}</span></span
          > -->
          <video-player
              preload
              class="vjs-big-play-centered"
              :is_json="is_json"
              :json_data="json_data"
              :json_file_not_present="json_file_not_present"
              :videoFps="videoFps"
              :camera_model="camera_model"
              :portal="portal"
              :json_loading="json_loading"
              :navigation_button_change="navigation_button_change"
              :options="{
                autoplay: true,
                controls: false,
                fluid: true,
              }"
            />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_cam"
      max-width="1200px"
      min-width="450px"
      v-if="
        last_footage_by_camera.length > 0 &&
        last_footage_by_camera[selected_index] != undefined
      "
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 ml-3"
            >Edit Camera - {{ selected_camera.id }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  readonly
                  v-if="selected_camera.site"
                  v-model="selected_camera.site.site_name"
                  label="Site *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  readonly
                  v-if="selected_camera.customer"
                  v-model="selected_camera.customer.customer_first_name"
                  label="Owner *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.id"
                  label="ID *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_name"
                  label="Name *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_nickname"
                  label="Nickname *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_type"
                  label="Type *"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="selected_camera.camera_status"
                  :items="$store.getters.getCameraOnlineStatus"
                  label="Status"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-if="selected_camera.camera_location"
                  v-model="selected_camera.camera_location.latitude"
                  label="Latitude"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-if="selected_camera.camera_location"
                  v-model="selected_camera.camera_location.longitude"
                  label="Longitude"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_manufacturer"
                  label="Manufacturer"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_sd_card_size_gb"
                  label="SD Card Size (GB)"
                  :rules="[rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_clear_resolution"
                  label="Clear Resolution"
                  :rules="[rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_clear_bitrate_kbps"
                  label="Clear Bitrate"
                  :rules="[rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_fluent_resolution"
                  label="Fluent Resolution"
                  :rules="[rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_fluent_bitrate_kbps"
                  label="Fluent Bitrate"
                  :rules="[rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_ip"
                  label="IP Address"
                  :rules="[rules.ip]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_mac"
                  label="MAC Address"
                  :rules="[rules.mac]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="selected_camera.camera_imei"
                  label="IMEI"
                  :rules="[rules.imei]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-row class="ml-1">
                  <div class="edit_item_cb">
                    <span class="cb_label">Broken</span>
                    <v-checkbox
                      hide-details
                      class="mr-5"
                      v-model="selected_camera_broken_bool"
                    ></v-checkbox>
                  </div>

                  <span
                    v-if="selected_camera_broken_bool == true"
                    class="cond_tf"
                    ><v-text-field
                      class="mt-0"
                      v-model="selected_camera.camera_broken_reason"
                      label="Broken Reason"
                    ></v-text-field
                  ></span> </v-row
              ></v-col>
              <v-col cols="12" class="pt-0">
                <v-row class="ml-1">
                  <div class="edit_item_cb">
                    <span class="cb_label">Cellular Enabled</span>
                    <v-checkbox
                      hide-details
                      class="mr-5"
                      v-model="selected_camera_cellular_enabled_bool"
                    ></v-checkbox>
                  </div>
                  <div class="edit_item_cb">
                    <span class="cb_label">FTP Enabled</span>
                    <v-checkbox
                      hide-details
                      class="mr-5"
                      v-model="selected_camera_ftp_enabled_bool"
                    ></v-checkbox>
                  </div>
                  <div class="edit_item_cb">
                    <span class="cb_label">Reolink Cloud Enabled</span>
                    <v-checkbox
                      hide-details
                      class="mr-5"
                      v-model="selected_camera_reolinkcloud_enabled_bool"
                    ></v-checkbox>
                  </div> </v-row
              ></v-col>
              <v-col cols="12">
                Notes
                <v-textarea
                  solo
                  v-model="selected_camera.camera_notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span class="error_span">{{ error }}</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close_selected_cam">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="save_selected_cam"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import JSZip from "jszip";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
import videojs from "video.js";
import { eventBus } from "../main.js";
import Vue from "vue";
import VueLoadImage from "vue-load-image";
// import chartUserHerdSize from "@/components/chart_user_herd_size.vue";
// import chartInstalledCams from "@/components/chart_installed_cams.vue";
// import chartTotalAnimals from "@/components/chart_total_animals.vue";
// import { eventBus } from "../main.js";
import CustomerFilter from "../components/CustomerFilter.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    VideoPlayer,
    "vue-load-image": VueLoadImage,
    CustomerFilter,
    // chartUserHerdSize,
    // chartInstalledCams,
    // chartTotalAnimals,
  },
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
        imei: (v) => /^\d {15}$||null$/.test(v) || "Enter a valid IMEI",
      },
      customer: "All Customers",
      filter_params: {},
      sort_direction: true,
      source: "All Sources",
      corruption: "All Status",
      selected_index: 0,
      dialog: false,
      dialog_cam: false,
      sort_field: "Date",
      grid: 6,
      holder_footage: [],
      bordered: true,
      resolution: "Res",
      show_text: true,
      load: false,
      selected_camera: {},
      selected_camera_broken_bool: false,
      selected_camera_cellular_enabled_bool: false,
      selected_camera_ftp_enabled_bool: false,
      selected_camera_reolinkcloud_enabled_bool: false,
      error: "",
      first_load: true,
      loaded_images: [],

      is_copied: false,
      loading: false,

      is_json: false,
      json_data: "",
      json_file_not_present: false,
      json_loading: false,
      videoFps: 0,
      portal: "admin",
      navigation_button_change: false,
      camera_model: "",
      per_load: 48,
    };
  },
  methods: {
    nextPage(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchFootage(false);
      }
    },
    /**
     *
     * @param {Boolean} first_time First time?
     */
    fetchFootage(first_time) {
      return this.$store.dispatch("DDB_GET_LAST_FOOTAGE", {
        limit: this.per_load,
        filter: this.filter_params.filter,
        // sort: {
        //   direction: this.sort_direction?"asc":"desc",
        //   field: this.sort_field
        // },
        nextToken: this.$store.getters.getLastFootageUploads[1],
        first_time: first_time,
      });
    },
    async fetchJSON() {
      const url = this.last_footage_by_camera[this.selected_index].videos.items[0]
          .video_path;
      this.json_file_not_present = false;
      var zip = new JSZip();

      var json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      await fetch(json_file_source, { credentials: "include" }).then((res) => {
        this.is_json = false;
        if (res.status != 200) {
          this.json_file_not_present = true;
          //this.is_json = false;
          this.json_data = "";
        } else {
          this.json_loading = false;
          res.blob().then((data) => {
            zip.loadAsync(data).then((zip) => {
              zip.forEach((relativePath, zipEntry) => {
                zipEntry.async("blob").then((content) => {
                  content.text().then((data) => {
                    var dataParse = data;
                    this.json_data = dataParse;
                    eventBus.$emit("jsonFetched", this.json_data);
                    this.json_loading = true;
                    this.is_json = true;
                  });
                });
              });
            });
          });
        }
      });
    },
    copy(t) {
      if (!this.is_copied) {
        this.is_copied = true;
        setTimeout(() => {
          this.is_copied = false;
        }, 2000);
      }
      navigator.clipboard.writeText(t);
    },
    formulateNames(first_name, last_name) {
      return (
        (first_name ? first_name + " " : "") +
        (first_name == last_name ? "" : last_name)
      );
    },
    test(index) {
      this.loaded_images[index] = true;
    },
    select_camera(index) {
      this.selected_index = index;
      this.dialog = true;
      this.navigation_button_change = !this.navigation_button_change;
      try {
        this.fetchJSON();
        this.playVideo();
      } catch (e) {
        console.log(e);
      }
    },

    playVideo() {
      this.playHLS();
    },

    async playHLS() {
      var player = videojs(document.querySelector(".video-js"));
      const url =
        this.last_footage_by_camera[this.selected_index].videos.items[0]
          .video_path;
      this.video_fps =
        this.last_footage_by_camera[this.selected_index].videos.items[0][
          "video_fps"
        ];
        this.videoFps = this.last_footage_by_camera[this.selected_index].videos.items[0][
          "video_fps"
        ];
        this.camera_model = this.last_footage_by_camera[this.selected_index].videos.items[0][
          "camera"
        ]["camera_model"];
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";
      eventBus.$emit("sourceChanged", source);
      const res = await fetch(source, { credentials: "include" });
      const tn = await res.text();
      var tn_t = tn.replace(/#EXT-X-ENDLIST/, "");
      const tn_arr = tn_t.split(/#EXTINF:/);
      tn_arr.shift();
      // tn_arr.pop();
      const duration_split =
        this.last_footage_by_camera[
          this.selected_index
        ].videos.items[0].video_duration.split(":");
      const duration_sec =
        (parseInt(duration_split[0]) * 60 + parseInt(duration_split[1])) /
        tn_arr.length;

      var v_tn = {};
      for (const i in tn_arr) {
        v_tn[
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec
        ] = {
          src:
            process.env.VUE_APP_COOKIE_BUCKET +
            url.replaceAll("+", "/").slice(0, -36) +
            "hls/" +
            tn_arr[i]
              .slice(10, 50)
              .replace(".", "")
              .replace("ts", "")
              .replace("t", "")
              .replace("s", "") +
            ".jpg",
        };
      }
      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        Vue.prototype.$video_start = false;
      }
    },

    playMP4() {
      var player = videojs(document.querySelector(".video-js"));
      const url =
        this.last_footage_by_camera[this.selected_index].videos.items[0]
          .video_path;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".mp4";
      player.src({
        src: source,
        type: "video/mp4",
      });
      if (this.$video_start) {
        Vue.prototype.$video_start = false;
      }
    },

    camera_settings(id) {
      this.$store.dispatch("DDB_GET_CAMERA", id).then(() => {
        this.selected_camera = this.$store.getters.getSelectedCamera;
        this.selected_camera_broken_bool =
          this.selected_camera.camera_broken == "YES";
        this.selected_camera_cellular_enabled_bool =
          this.selected_camera.camera_cellular_enabled == "YES";
        this.selected_camera_ftp_enabled_bool =
          this.selected_camera.camera_ftp_enabled == "YES";
        this.selected_camera_reolinkcloud_enabled_bool =
          this.selected_camera.camera_reolinkcloud_enabled == "YES";
        this.dialog_cam = true;
      });
    },
    async save_selected_cam() {
      try {
        delete this.selected_camera.createdAt;
        delete this.selected_camera.customer;
        delete this.selected_camera.updatedAt;
        delete this.selected_camera.site;
        delete this.selected_camera.videos;
        this.selected_camera.camera_broken =
          this.selected_camera_broken_bool == true ? "YES" : "NO";
        this.selected_camera.camera_cellular_enabled =
          this.selected_camera_cellular_enabled_bool == true ? "YES" : "NO";
        this.selected_camera.camera_ftp_enabled =
          this.selected_camera_ftp_enabled_bool == true ? "YES" : "NO";
        this.selected_camera.camera_reolinkcloud_enabled =
          this.selected_camera_reolinkcloud_enabled_bool == true ? "YES" : "NO";
        await API.graphql({
          query: mutations.updateCamera,
          variables: {
            input: this.selected_camera,
          },
        });
      } catch (err) {
        this.error = err.errors[0].message;
      }
    },
    close_selected_cam() {
      this.dialog_cam = false;
      this.error = "";

      this.$nextTick(() => {
        this.selected_camera = {};
      });
    },
    redirect_to_footage(cam_id) {
      this.$router.push({
        name: "footage",
        params: {
          filter: cam_id,
        },
      });
    },
    redirect_to_video(cam_id) {
      this.$router.push({
        name: "videos",
        params: {
          filter: cam_id,
        },
      });
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },
    filters() {
      var temp_sources = {
        ftp: 0,
        iphone: 0,
        jetson: 0,
        rc: 0,
        sdcard: 0,
      };
      var count_data = [];
      if (this.customer == "All Customers") {
        count_data = this.holder_footage;
      } else {
        count_data = JSON.parse(
          JSON.stringify(
            this.$store.getters.getCustomerObjects.find(
              (e) => e.id == this.customer
            ).cameras.items
          )
        );
      }
      for (const x in count_data) {
        if (
          count_data[x].camera_latest_upload_source == "FTP" &&
          ((this.corruption == "Non Corrupted" &&
            count_data[x].camera_latest_upload_corrupted == "NO") ||
            (this.corruption == "Corrupted" &&
              count_data[x].camera_latest_upload_corrupted == "YES") ||
            (this.corruption == "Unknown" &&
              count_data[x].camera_latest_upload_corrupted == "UNDEFINED") ||
            (this.corruption == "Has Data" &&
              count_data[x].camera_latest_upload.length > 0) ||
            (this.corruption == "No Data" &&
              count_data[x].camera_latest_upload.length <= 0) ||
            this.corruption == "All Status") &&
          (count_data[x].camera_latest_upload_resolution == this.resolution ||
            this.resolution == "Res")
        ) {
          temp_sources.ftp += 1;
        } else if (
          count_data[x].camera_latest_upload_source == "IPHONE" &&
          ((this.corruption == "Non Corrupted" &&
            count_data[x].camera_latest_upload_corrupted == "NO") ||
            (this.corruption == "Corrupted" &&
              count_data[x].camera_latest_upload_corrupted == "YES") ||
            (this.corruption == "Unknown" &&
              count_data[x].camera_latest_upload_corrupted == "UNDEFINED") ||
            (this.corruption == "Has Data" &&
              count_data[x].camera_latest_upload.length > 0) ||
            (this.corruption == "No Data" &&
              count_data[x].camera_latest_upload.length <= 0) ||
            this.corruption == "All Status") &&
          (count_data[x].camera_latest_upload_resolution == this.resolution ||
            this.resolution == "Res")
        ) {
          temp_sources.iphone += 1;
        } else if (
          count_data[x].camera_latest_upload_source == "JETSON" &&
          ((this.corruption == "Non Corrupted" &&
            count_data[x].camera_latest_upload_corrupted == "NO") ||
            (this.corruption == "Corrupted" &&
              count_data[x].camera_latest_upload_corrupted == "YES") ||
            (this.corruption == "Unknown" &&
              count_data[x].camera_latest_upload_corrupted == "UNDEFINED") ||
            (this.corruption == "Has Data" &&
              count_data[x].camera_latest_upload.length > 0) ||
            (this.corruption == "No Data" &&
              count_data[x].camera_latest_upload.length <= 0) ||
            this.corruption == "All Status") &&
          (count_data[x].camera_latest_upload_resolution == this.resolution ||
            this.resolution == "Res")
        ) {
          temp_sources.jetson += 1;
        } else if (
          count_data[x].camera_latest_upload_source == "REOLINK_CLOUD" &&
          ((this.corruption == "Non Corrupted" &&
            count_data[x].camera_latest_upload_corrupted == "NO") ||
            (this.corruption == "Corrupted" &&
              count_data[x].camera_latest_upload_corrupted == "YES") ||
            (this.corruption == "Unknown" &&
              count_data[x].camera_latest_upload_corrupted == "UNDEFINED") ||
            (this.corruption == "Has Data" &&
              count_data[x].camera_latest_upload.length > 0) ||
            (this.corruption == "No Data" &&
              count_data[x].camera_latest_upload.length <= 0) ||
            this.corruption == "All Status") &&
          (count_data[x].camera_latest_upload_resolution == this.resolution ||
            this.resolution == "Res")
        ) {
          temp_sources.rc += 1;
        } else if (
          count_data[x].camera_latest_upload_source == "SD_CARD" &&
          ((this.corruption == "Non Corrupted" &&
            count_data[x].camera_latest_upload_corrupted == "NO") ||
            (this.corruption == "Corrupted" &&
              count_data[x].camera_latest_upload_corrupted == "YES") ||
            (this.corruption == "Unknown" &&
              count_data[x].camera_latest_upload_corrupted == "UNDEFINED") ||
            (this.corruption == "Has Data" &&
              count_data[x].camera_latest_upload.length > 0) ||
            (this.corruption == "No Data" &&
              count_data[x].camera_latest_upload.length <= 0) ||
            this.corruption == "All Status") &&
          (count_data[x].camera_latest_upload_resolution == this.resolution ||
            this.resolution == "Res")
        ) {
          temp_sources.sdcard += 1;
        }
      }
    },
    dummy_string() {
      return "?dummy=" + String(Math.floor(Math.random() * 9999999));
    },
    refresh() {
      this.loading = true;
      // this.$store
      //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
      //   .then(() => {
      //     this.loading = false;
      //     this.filters();
      //     this.set_borders(false);
      //   });
      this.fetchFootage(true).then(() => {
        this.loading = false;
        this.filters();
        this.set_borders(false);
      });
    },

    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          "lf_green_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          "lf_blue_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          "lf_yellow_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          "lf_orange_active",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          "lf_orange_active",
        ];
      } else {
        return [false, false];
      }
    },
    changeSortDir() {
      this.sort_direction = !this.sort_direction;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (x == null) {
          x = "zzz";
        }
        if (y == null) {
          y = "zzz";
        }
        if (key == "camera_latest_upload_source") {
          if (x == y) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByKeyDESC(array, key) {
      return array.sort(function (a, b) {
        var x = b[key];
        var y = a[key];
        if (x == null) {
          x = "zzz";
        }
        if (y == null) {
          y = "zzz";
        }
        if (key == "camera_latest_upload_source") {
          if (x == y) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByNested(array, key) {
      key = key.split(".");

      array.sort(function (a, b) {
        if (key[1] == "customer_first_name") {
          if (a[key[0]][key[1]] == b[key[0]][key[1]]) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }

        if (a[key[0]][key[1]] < b[key[0]][key[1]]) {
          return -1;
        } else if (a[key[0]][key[1]] > b[key[0]][key[1]]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByNestedDESC(array, key) {
      key = key.split(".");

      array.sort(function (a, b) {
        if (key[1] == "customer_first_name") {
          if (a[key[0]][key[1]] == b[key[0]][key[1]]) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }
        if (a[key[0]][key[1]] > b[key[0]][key[1]]) {
          return -1;
        } else if (a[key[0]][key[1]] < b[key[0]][key[1]]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
  },
  watch: {
    selected_index() {
      if (this.dialog) {
        try {
          this.fetchJSON();
          this.playVideo();
        } catch {
          console.log("err");
        }
      }
    },
    grid() {
      document.getElementById("grid-container-lf").style.gridTemplateColumns =
        "repeat(" + String(this.grid) + ", 1fr)";
    },
    corruption() {
      if (!this.first_load) {
        this.loading = true;
        if (this.corruption == "All Status") {
          if (this.filter_params.filter != undefined) {
            if (
              this.filter_params.filter.camera_latest_upload_corrupted !=
              undefined
            ) {
              delete this.filter_params.filter.camera_latest_upload_corrupted;
            }
            if (this.filter_params.filter.camera_latest_upload != undefined) {
              delete this.filter_params.filter.camera_latest_upload;
            }
          }

          if (
            this.customer == "All Customers" &&
            this.source == "All Sources" &&
            this.resolution == "Res"
          ) {
            delete this.filter_params.filter;
          }
        } else if (this.corruption == "Non Corrupted") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          } else {
            if (this.filter_params.filter.camera_latest_upload != undefined) {
              delete this.filter_params.filter.camera_latest_upload;
            }
          }
          this.filter_params.filter.camera_latest_upload_corrupted = {
            eq: "NO",
          };
        } else if (this.corruption == "Corrupted") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          } else {
            if (this.filter_params.filter.camera_latest_upload != undefined) {
              delete this.filter_params.filter.camera_latest_upload;
            }
          }
          this.filter_params.filter.camera_latest_upload_corrupted = {
            eq: "YES",
          };
        } else if (this.corruption == "Unknown") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          } else {
            if (this.filter_params.filter.camera_latest_upload != undefined) {
              delete this.filter_params.filter.camera_latest_upload;
            }
          }
          this.filter_params.filter.camera_latest_upload_corrupted = {
            eq: "UNDEFINED",
          };
        } else if (this.corruption == "Has Data") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          } else {
            if (
              this.filter_params.filter.camera_latest_upload_corrupted !=
              undefined
            ) {
              delete this.filter_params.filter.camera_latest_upload_corrupted;
            }
          }
          this.filter_params.filter.camera_latest_upload = {
            ne: "",
          };
        } else if (this.corruption == "No Data") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          } else {
            if (
              this.filter_params.filter.camera_latest_upload_corrupted !=
              undefined
            ) {
              delete this.filter_params.filter.camera_latest_upload_corrupted;
            }
          }
          this.filter_params.filter.camera_latest_upload = {
            eq: "",
          };
        }
        localStorage.setItem(
          "footage_filter",
          JSON.stringify({
            filter: this.filter_params,
            customer: this.customer,
            corruption: this.corruption,
            source: this.source,
            res: this.resolution,
          })
        );
        // this.$store
        //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
        //   .then(() => {
        //     this.loading = false;
        //     this.filters();
        //     this.set_borders(false);
        //     this.load = true;
        //   });
        this.fetchFootage(true).then(() => {
          this.loading = false;
          this.filters();
          this.set_borders(false);
          this.load = true;
        });
      }
    },
    resolution() {
      if (!this.first_load) {
        this.loading = true;
        if (this.resolution != "Res") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          }
          this.filter_params.filter.camera_latest_upload_resolution = {
            eq: this.resolution,
          };
        } else {
          delete this.filter_params.filter.camera_latest_upload_resolution;
          if (
            this.customer == "All Customers" &&
            this.corruption == "All Status" &&
            this.source == "All Sources"
          ) {
            delete this.filter_params.filter;
          }
        }
        localStorage.setItem(
          "footage_filter",
          JSON.stringify({
            filter: this.filter_params,
            customer: this.customer,
            corruption: this.corruption,
            source: this.source,
            res: this.resolution,
          })
        );
        // this.$store
        //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
        //   .then(() => {
        //     this.loading = false;
        //     this.filters();
        //     this.set_borders(false);
        //   });
        this.fetchFootage(true).then(() => {
          this.loading = false;
          this.filters();
          this.set_borders(false);
        });
      }
    },
    source() {
      if (!this.first_load) {
        this.loading = true;
        if (this.source != "All Sources") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          }
          this.filter_params.filter.camera_latest_upload_source = {
            eq: this.source,
          };
        } else {
          delete this.filter_params.filter.camera_latest_upload_source;
          if (
            this.customer == "All Customers" &&
            this.corruption == "All Status" &&
            this.resolution == "Res"
          ) {
            delete this.filter_params.filter;
          }
        }
        localStorage.setItem(
          "footage_filter",
          JSON.stringify({
            filter: this.filter_params,
            customer: this.customer,
            corruption: this.corruption,
            source: this.source,
            res: this.resolution,
          })
        );
        // this.$store
        //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
        //   .then(() => {
        //     this.loading = false;
        //     this.set_borders(false);
        //   });
        this.fetchFootage(true).then(() => {
          this.loading = false;
          this.set_borders(false);
        });
      }
    },
    customer() {
      if (!this.first_load) {
        this.loading = true;
        if (this.customer != "All Customers") {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          }
          this.filter_params.filter.owner = {
            eq: this.customer,
          };
        } else {
          delete this.filter_params.filter.owner;
          if (
            this.source == "All Sources" &&
            this.corruption == "All Status" &&
            this.resolution == "Res"
          ) {
            delete this.filter_params.filter;
          }
        }
        localStorage.setItem(
          "footage_filter",
          JSON.stringify({
            filter: this.filter_params,
            customer: this.customer,
            corruption: this.corruption,
            source: this.source,
            res: this.resolution,
          })
        );
        // this.$store
        //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
        //   .then(() => {
        //     this.loading = false;
        //     this.filters();
        //     this.set_borders(false);
        //     this.load = true;
        //   });
        this.fetchFootage(true).then(() => {
          this.loading = false;
          this.filters();
          this.set_borders(false);
          this.load = true;
        });
      }
    },
  },
  computed: {
    sources() {
      return [
        { text: "All Sources", value: "All Sources" },
        {
          text: "FTP - " + this.$store.getters.getCameraSources["FTP"],
          value: "FTP",
        },
        {
          text: "iPhone - " + this.$store.getters.getCameraSources["IPHONE"],
          value: "IPHONE",
        },
        {
          text: "Jetson - " + this.$store.getters.getCameraSources["JETSON"],
          value: "JETSON",
        },
        {
          text:
            "Reolink Cloud - " +
            this.$store.getters.getCameraSources["REOLINK_CLOUD"],
          value: "REOLINK_CLOUD",
        },
        {
          text: "SD Card - " + this.$store.getters.getCameraSources["SD_CARD"],
          value: "SD_CARD",
        },
      ];
    },
    sort_item_list() {
      return ["Date", "Camera", "Customer", "Source"];
      // return [
      //   {text:"Date", value: "camera_latest_upload"},
      //   {text:"Camera", value: "camera_name"},
      // ]
    },
    last_footage_by_camera() {
      var footage_list = []
      //var footage_list = this.$store.getters.getLastFootageUploads[0];
      if (!footage_list) {
        return [];
      }
      // else{
      //   return [...footage_list];
      // }
      if (!this.sort_direction && this.sort_field == "Date") {
        return this.sortByKey([...footage_list], "camera_latest_upload");
      } else if (this.sort_direction && this.sort_field == "Date") {
        return this.sortByKeyDESC([...footage_list], "camera_latest_upload");
      } else if (this.sort_direction && this.sort_field == "Camera") {
        return this.sortByKey([...footage_list], "camera_name");
      } else if (!this.sort_direction && this.sort_field == "Camera") {
        return this.sortByKeyDESC([...footage_list], "camera_name");
      } else if (this.sort_direction && this.sort_field == "Customer") {
        return this.sortByNested(
          [...footage_list],
          "customer.customer_first_name"
        );
      } else if (!this.sort_direction && this.sort_field == "Customer") {
        return this.sortByNestedDESC(
          [...footage_list],
          "customer.customer_first_name"
        );
      } else if (this.sort_direction && this.sort_field == "Source") {
        return this.sortByKey([...footage_list], "camera_latest_upload_source");
      } else if (!this.sort_direction && this.sort_field == "Source") {
        return this.sortByKeyDESC(
          [...footage_list],
          "camera_latest_upload_source"
        );
      } else {
        return [];
      }
    },
    footage_next_token() {
      return this.$store.getters.getLastFootageUploads[1];
    },
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              // let count = 0;
              // if (item.cameras.items.length > 0) {
              //   for (const n in item.cameras.items) {
              //     if (
              //       (this.corruption == "All Status" ||
              //         (this.corruption == "Non Corrupted" &&
              //           item.cameras.items[n].camera_latest_upload_corrupted ==
              //             "NO") ||
              //         (this.corruption == "Corrupted" &&
              //           item.cameras.items[n].camera_latest_upload_corrupted ==
              //             "YES") ||
              //         (this.corruption == "Unknown" &&
              //           item.cameras.items[n].camera_latest_upload_corrupted ==
              //             "UNDEFINED") ||
              //         (this.corruption == "Has Data" &&
              //           item.cameras.items[n].camera_latest_upload.length >
              //             0) ||
              //         (this.corruption == "No Data" &&
              //           item.cameras.items[n].camera_latest_upload.length >
              //             0)) &&
              //       (item.cameras.items[n].camera_latest_upload_source ==
              //         this.source ||
              //         this.source == "All Sources") &&
              //       (item.cameras.items[n].camera_latest_upload_resolution ==
              //         this.resolution ||
              //         this.resolution == "Res")
              //     ) {
              //       count += 1;
              //     }
              //   }
              // }
              return {
                text:
                  (item.customer_company_name
                    ? item.customer_company_name + " - "
                    : "") +
                  this.formulateNames(
                    item.customer_first_name,
                    item.customer_last_name
                  ),
                // " - " +
                // String(count),
                value: item.id,
              };
            } catch {
              console.log("");
            }
          })
        ),
      ];

      if (customers.indexOf(undefined)) {
        customers.splice(customers.indexOf(undefined), 90);
      }
      customers = customers.filter(function (x) {
        return x != undefined;
      });
      const sortAlphaNumCams = (a, b) =>
        a.text.localeCompare(b.text, "en", { numeric: true });
      customers.sort(sortAlphaNumCams);
      customers.unshift("All Customers");
      return customers;
    },
    resolutions() {
      // var res = [
      //   ...new Set(
      //     this.holder_footage.map((item) => {
      //       if (
      //         item.camera_latest_upload_resolution &&
      //         item.camera_latest_upload_resolution !== undefined
      //       ) {
      //         return item.camera_latest_upload_resolution;
      //       }
      //     })
      //   ),
      // ];
      var res = [
        ...this.$store.getters.getCameraResolutions.map((item) => {
          if (item && item !== undefined) {
            return item;
          }
        }),
      ];
      const sortAlphaNumCams = (a, b) =>
        a.localeCompare(b, "en", { numeric: true });
      res.sort(sortAlphaNumCams);
      while (res.indexOf(undefined) != -1) {
        res.splice(res.indexOf(undefined), 1);
      }
      res.unshift("Res");
      return res;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
  },
  created() {
    this.loading = true;
    eventBus.$on("player_ready", () => {
      this.fetchJSON();
      this.playVideo();
    });
    window.addEventListener("keydown", (e) => {
      if (this.dialog) {
        if (
          e.key == "ArrowRight" &&
          this.selected_index < this.last_footage_by_camera.length - 1
        ) {
          this.selected_index += 1;
        } else if (e.key == "ArrowLeft" && this.selected_index > 0) {
          this.selected_index -= 1;
        }
      }
    });
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getCameraOnlineStatus.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERA_ONLINE_STATUS");
    }
    if (this.$store.getters.getCameraResolutions.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERA_RESOLUTIONS");
    }
    if (Object.keys(this.$store.getters.getCameraSources).length == 0) {
      this.$store.dispatch("DDB_GET_CAMERA_SOURCES");
    }
    // if (!localStorage.getItem("footage_filter")) {

    this.customer = Vue.prototype.$selectCustomer;
    if (this.customer != "All Customers" && this.customer) {
      if (this.filter_params.filter == undefined) {
        this.filter_params.filter = {};
      }
      this.filter_params.filter.owner = {
        eq: this.customer,
      };
    }
    localStorage.setItem(
      "footage_filter",
      JSON.stringify({
        filter: this.filter_params,
        customer: this.customer,
        corruption: this.corruption,
        source: this.source,
        res: this.resolution,
      })
    );
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      // this.$store
      //   .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", this.filter_params)
      //   .then(() => {
      //     this.loading = false;
      //     this.holder_footage = [...this.$store.getters.getLastFootageUploads];
      //     this.filters();
      //     this.first_load = false;
      //     this.load = true;
      //   });
      this.fetchFootage(true).then(() => {
        this.loading = false;
        this.holder_footage = [...this.$store.getters.getLastFootageUploads[0]];
        this.filters();
        this.first_load = false;
        this.load = true;
      });
    } else {
      // this.$store.dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", {}).then(() => {
      //   this.loading = false;
      //   this.holder_footage = [...this.$store.getters.getLastFootageUploads];
      //   this.filters();
      //   this.first_load = false;
      //   this.corruption = "Has Data";
      // });
      this.fetchFootage(true).then(() => {
        this.loading = false;
        this.holder_footage = [...this.$store.getters.getLastFootageUploads[0]];
        this.filters();
        this.first_load = false;
        this.corruption = "Has Data";
      });
    }

    // } else {
    //   const local_store_filter = JSON.parse(
    //     localStorage.getItem("footage_filter")
    //   );
    //   this.$store.dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", {}).then(() => {
    //     this.holder_footage = [...this.$store.getters.getLastFootageUploads];
    //     this.filters();

    //     this.$store
    //       .dispatch("DDB_GET_LAST_FOOTAGE_BY_CAMERA", local_store_filter.filter)
    //       .then(() => {
    //         this.filter_params = local_store_filter.filter;
    //         this.customer = local_store_filter.customer;
    //         this.source = local_store_filter.source;
    //         this.corruption = local_store_filter.corruption;
    //         this.resolution = local_store_filter.res;
    //         this.filters();
    //         this.load = true;
    //         const _this = this;
    //         setTimeout(function () {
    //           _this.first_load = false;
    //         }, 1000);
    //       });
    //   });
    // }
  },
};
</script>
<style lang="css" scoped>
#lf_btns {
  /* width: 75px !important; */
  display: flex;
  float: right;
  margin-right: 6px;
  margin-top: 5px;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
.footage_link {
  cursor: pointer;
}
#full_size_ct_div {
  width: 100%;
}
#date_sort {
  color: gray;
  float: left;
  padding-top: 2px;
  margin-right: 10px;
  font-size: 14px;
}
#sort_icon {
  height: 24px;
  top: 30%;
}
#icon_info {
  font-size: 10px;
  margin-top: -4px;
  height: 20px;
  padding-left: 6px;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.resolution_large_img {
  text-align: right;
  position: absolute;
  bottom: 36px;
  right: 36px;
  transform: translate(0%, 0%);
  color: #ffffff;
  font-size: 20px;
  width: 80%;
  user-select: none;
}
.age_large_img {
  text-align: left;
  position: absolute;
  bottom: 36px;
  left: 36px;
  transform: translate(0%, 0%);
  color: #ffffff;
  font-size: 20px;
  width: 80%;
  user-select: none;
}
.cam_name_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.customer_name_grid {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.customer_id_grid {
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.source_name_grid {
  position: absolute;
  top: 62px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cam_date_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.cam_fps_grid {
  text-align: right;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
}
.cam_bitrate_grid {
  text-align: right;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
}
.cam_model_grid {
  text-align: right;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
}
.cam_resolution_grid {
  text-align: right;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
}
.cam_index_grid {
  text-align: right;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 14px;
  width: 95%;
  user-select: none;
}
.grid_container_f {
  position: relative;
  text-align: center;
  min-height: 130px;
}

.filter_conf {
  text-align: right;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
.lu_tabs {
  float: none !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
}
.grid_container_f:hover > .cam_name_grid {
  z-index: 3 !important;
}

.select_grid {
  float: left;
  width: 55px;
}
.nav_arrows_fl {
  float: right;
  display: flex;
}
.skeleton_container {
  width: 100%;
}
</style>
