<template>
<div>
  <v-dialog v-model="note_dialog" max-width="800px" min-width="760px" persistent no-click-animation @click:outside="attemptClose">
    <v-card>
      <v-card-title>
        <span class="" style="color: #0090a4">Add Note</span>
      </v-card-title>

      <v-card-text>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    autofocus
                  v-model="note_text"
                  label="Note"
                  outlined
                ></v-text-field>
              </v-col> </v-row
          ></v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="pr-6">
        <v-spacer></v-spacer>
        <v-btn @click="attemptClose"> Cancel </v-btn>
        <v-btn color="primary" @click="add_note"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="warn_dialog" max-width="500px" persistent no-click-animation>
    <v-card>
      <v-card-title>Discard unsaved changes?</v-card-title>
      <v-card-actions>
        <div class="d-flex justify-center" style="width: 100%">
        <v-btn
          color="#dd3f5b"
          @click="close"
          class="mr-3 white--text"
        >
          Discard
        </v-btn>
        <v-btn color="primary" @click="warn_dialog=false">
          Cancel
        </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { eventBus } from "../main.js";

// @vuese
// @group Components
// The editing card of notes
export default {
  data() {
    return {
      note_text: "",
      note_dialog: false,
      warn_dialog: false,
    };
  },
  methods: {
    /**
     * open the card
     */
    open() {
      this.note_text = "";
      this.note_dialog = true;
    },
    /**
     * Attempt to close
     */
    attemptClose(){
      if(this.note_text){
          this.warn_dialog = true;
      }else{
          this.close();
      }
    },
    /**
     * close the card
     */
    close() {
      this.warn_dialog = false;
      this.note_dialog = false;
      this.note_text = "";
    },
    add_note(){
        eventBus.$emit("add_note", this.note_text);
        this.close();
    }
  },
  created(){
    eventBus.$on("open_dialog_note", () => {
        this.open();
    });
    window.addEventListener('beforeunload', (event)=>{
      if(this.note_text){
        event.preventDefault()
        event.returnValue = true
      }
    });
  },
  watch: {
    note_dialog() {
        this.$store.commit('SET_NOTE_CARD_DIALOG', this.note_dialog);
    },
  },
};
</script>