<template>
  <div>
    <div v-if="!isCustomerSelected" class="mx-1 text-center py-5">
      <v-alert type="primary" dense class="mx-auto mt-10" max-width="500">
        Please select a customer to view camera analytics.
      </v-alert>
    </div>
    <div v-else class="mx-1">
      <v-row v-if="loading" justify="center" class="py-5">
        <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
      </v-row>
      <div v-else>
        <v-row
        ><v-col cols="12" class="py-0">
          <span
            class="mr-2 hidden_when_small"
            style="width: 100px; float: right"
            ><v-select
              v-model="limit"
              :items="[10, 20, 50, 100]"
              outlined
              rounded
              dense
              hide-details
              class="assign_select mt-2" /></span
          ><span
            class="mr-2 hidden_when_small"
            style="width: 140px; float: right"
            ><v-select
              v-model="timeframe"
              :items="[
                { text: '1 Day', value: '1day' },
                { text: '1 Hour', value: '1hour' },
                { text: '5 Minutes', value: '5min' },
              ]"
              outlined
              rounded
              dense
              hide-details
              class="assign_select mt-2"
          /></span> </v-col
      ></v-row>
        <v-row
          v-for="(camera, index) in sorted_cameras"
          :key="camera.id"
           class="mt-2"
        >
          <v-col xl="3" lg="3" cols="12" class="pa-0 ma-0">
            <span class="thumbnail_span">
              <span class="cam_name_span"
                ><span class="darker_bckrnd">{{ camera.name }}</span>
                <span class="darker_bckrnd" style="float: right; margin-left: -100%;">{{ index + 1 }}</span>
              </span>
              <span class="cam_upload_age_span"
              ><span class="darker_bckrnd">{{
                latest_upload_age(camera.latest_upload)
              }}</span>
            </span>
            <vue-load-image
              style="width: 100%; height: 100%; position: absolute; top: 0px"
            >
                <img
                  slot="image"
                  alt=""
                  :src="`${
                    cookie_bucket +
                    customer_id +
                    '/thumbnails/' +
                    camera.id +
                    '_latest.jpg'
                  }`"
                  class="grid_img_f"
                />
                <img
                  slot="preloader"
                  class="grid_img_f"
                  src="@/assets/loader.png"
                />
                <img
                  slot="error"
                  class="grid_img_f"
                  src="@/assets/no_media_light.png"
                />
              </vue-load-image>
            </span>
          </v-col>
          <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="by_camera_data[index]?.media_dataset"
              :timeframe="timeframe"
              title="Inferred Media"
            />
          </v-col>
          <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
            <chart_summary_media
            :labels="labels"
            :datasets="by_camera_data[index].total_detected_objects"
            :timeframe="timeframe"
            title="Detected Animals"
          />
          </v-col>
          <v-col xl="3" lg="3" cols="12" class="py-0 bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="by_camera_data[index]?.objects_per_media"
              :timeframe="timeframe"
              title="Detected Animals by Image"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";
import moment from "moment-timezone";
import chart_summary_media from "@/components/summary_media/chart_summary_media.vue";
//import chart_pi_summary_media from "@/components/summary_media/chart_pi_summary_media.vue";
// import chart_doughnut_summary_media from "@/components/summary_media/chart_doughnut_summary_media.vue";
export default {
  name: "ByCamera",
  props: ["cameras", "customer_id", "isCustomerSelected"],

  components: {
    "vue-load-image": VueLoadImage,
    chart_summary_media,
    //chart_pi_summary_media,
    //chart_doughnut_summary_media,
  },
  data() {
    return {
      limit: 10,
      timeframe: "1day",
      loading: true,
    };
  },
  methods: {
    latest_upload_age(latest_upload) {
      let timezone_adjusted_dt = moment.tz(
        latest_upload,
        "YYYY-MM-DDTHH:mm:ss",
        this.timezeone_offset
      );
      return (
        timezone_adjusted_dt.format("YYYY-MM-DD HH:mm") +
        " - " +
        timezone_adjusted_dt.utc().fromNow()
      );
    },
  },
  watch: {
    limit() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
          timezeone_offset: this.timezeone_offset,
        })
        .then(() => {
          this.loading = false;
        });
    },
    sorted_cameras() {
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
          timezeone_offset: this.timezeone_offset,
        })
        .then(() => {
          this.loading = false;
        });
    },
    timeframe() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
          timezeone_offset: this.timezeone_offset,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    labels() {
      let labels = [];
      let i = 0;
      while (i < this.limit) {
        if (this.timeframe == "1day") {
          labels.unshift(moment().subtract(i, "days").format("MM-DD"));
        } else if (this.timeframe == "1hour") {
          labels.unshift(moment().subtract(i, "hours").format("DD HH"));
        } else if (this.timeframe == "5min") {
          let start = moment();
          let remainder = 5 - (start.minute() % 5);
          let date_time = moment(start)
            .add(remainder, "minutes")
            .subtract(5, "minutes");
          labels.unshift(date_time.subtract(i * 5, "minutes").format("HH:mm"));
        }
        i++;
      }
      return labels;
    },
    timezeone_offset() {
      if (this.$store.getters.getSites) {
        return this.$store.getters.getSites[0].site_timezone;
      }
      return "UTC";
    },
    chart_time_format() {
      let time_format = "MM-DD HH:mm";
      if (this.timeframe_tab == 0) {
        time_format = "MM-DD";
      }
      return time_format;
    },
    sorted_cameras() {
      let cameras = [...this.cameras];
      return cameras.sort((a, b) => {
        return (
          moment(b.latest_upload, "YYYY-MM-DDTHH:mm:ss") >
          moment(a.latest_upload, "YYYY-MM-DDTHH:mm:ss")
        );
      });
    },

    by_camera_data() {
        return this.$store.getters.getByCameraData;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
  },
  mounted() {
    if (this.sorted_cameras.length > 0) {
      this.$store
        .dispatch("DDB_GET_BY_CAMERA_ANALYTICS", {
          timeframe: this.timeframe,
          limit: this.limit,
          cameras: this.sorted_cameras,
          timezeone_offset: this.timezeone_offset,
        })
        .then(() => {
          this.loading = false;
        });
    }
  },

  created() {},
};
</script>
<style lang="css" scoped>
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bar_col > div {
  position: relative;
  height: 320px;
  min-height: 272px;
}
.cam_name_span {
  position: relative;
  color: #ffffff;
  font-weight: bolder;
  font-size: 22px;
  width: 100%;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  z-index: 2;
}


.cam_upload_age_span {
  position: relative;
  color: #ffffff;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  top: calc(100% - 48px);
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  z-index: 2;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 4px;
  padding-right: 4px;
}
.thumbnail_span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
  