<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      min-width="760px"
      persistent
      no-click-animation
      @click:outside="attemptToClose"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="" style="color: #0090a4" v-if="create_new"
            >Add Site</span
          >
          <span class="" style="color: #0090a4" v-else>Edit Site</span>
          <v-chip class="ml-2" small v-if="has_unsaved_changes">{{
            Object.keys(original_values).length + " unsaved changes"
          }}</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="attemptToClose" :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid" @submit="save">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="owner_name"
                      :items="customer_names"
                      label="Customer *"
                      :menu-props="{ offsetOverflow: false }"
                      outlined
                      dense
                      @focus="edit_value = owner_name"
                      @input="on_field_change('owner_name', $event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Site Name *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.name"
                      @input="on_field_change('name', $event)"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col v-if="create_new" cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.customer_id"
                    label="Customer ID *"
                  ></v-text-field>
                </v-col> -->
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.coordinates_lat"
                      label="Latitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.coordinates_lat"
                      @input="on_field_change('latitude', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.coordinates_long"
                      label="Longitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.coordinates_long"
                      @input="on_field_change('longitude', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.map_zoom_level"
                      label="Map Zoom Level"
                      outlined
                      dense
                      @focus="edit_value = editedItem.map_zoom_level"
                      @input="on_field_change('map_zoom_level', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                      v-model="editedItem.timezone"
                      label="Timezone *"
                      :items="timezones"
                      outlined
                      dense
                      @focus="edit_value = editedItem.timezone"
                      @input="on_field_change('timezone', $event)"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.operation_type"
                      :items="[
                        { text: 'Beef', value: `'Beef'` },
                        { text: 'Bison', value: `'Bison'` },
                        { text: 'Chicken', value: `'Chicken'` },
                        { text: 'Cow/Calf', value: `'Cow/Calf'` },
                        { text: 'Dairy', value: `'Dairy'` },
                        { text: 'Goat', value: `'Goat'` },
                        { text: 'Feed Lot', value: `'Feed Lot'` },
                        { text: 'Swine', value: `'Swine'` },
                        { text: 'Other', value: `'Other'` },
                      ]"
                      label="Operation Type"
                      outlined
                      dense
                      multiple
                      @focus="edit_value = editedItem.operation_type"
                      @input="on_field_change('operation_type', $event)"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.area"
                      label="Area"
                      outlined
                      dense
                      @focus="edit_value = editedItem.area"
                      @input="on_field_change('area', $event)"
                    ></v-text-field>
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-form>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-row>
            <v-col cols="12" class="py-0"
              ><v-icon
                class="pl-1"
                style="float: left"
                v-if="!create_new"
                color="primary"
                @click="delete_dialog = true"
                :disabled="loading"
                >mdi-delete</v-icon
              >
              <div style="float: right">
                <v-btn
                  color="primary"
                  @click="apply"
                  :disabled="!valid || loading || !has_unsaved_changes"
                >
                  Apply
                </v-btn>
                <v-btn class="ml-4" @click="attemptToClose" :disabled="loading">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class="ml-4"
                  :disabled="
                    !valid ||
                    loading ||
                    (!has_unsaved_changes && !pressed_apply)
                  "
                  @click="save"
                >
                  Save
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-if="error"
              ><span class="error_span">{{ error }}</span></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_dialog" max-width="550px" min-width="260px">
      <v-card>
        <v-card-title>Delete Site {{ editedItem.name }}?</v-card-title>
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
            <v-btn
              color="#dd3f5b"
              @click="deleteItem(editedItem.id)"
              class="mr-3 white--text"
            >
              Delete
            </v-btn>
            <v-btn color="primary" @click="delete_dialog = false">
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dirty_dialog" max-width="550px" min-width="260px">
      <v-card>
        <v-card-title
          >Discard unsaved changes for {{ editedItem.name }}?</v-card-title
        >
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
            <v-btn color="#dd3f5b" @click="close()" class="mr-3 white--text">
              Discard
            </v-btn>
            <v-btn color="primary" @click="dirty_dialog = false">
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../main";
import timezones from "../data/timezones.json";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import { v4 as uuidv4 } from "uuid";
// @vuese
// @group Components
// The editing card for site
export default {
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
        imei: (v) => /^\d {15}$||null$/.test(v) || "Enter a valid IMEI",
        id: (v) => /^([A-Z0-9]+){16}$/.test(v) || "Enter a valid ID",
      },
      editedItem: {},
      delete_dialog: false,
      dialog: false,
      owner_name: "",
      error: "",
      site_name: "",
      create_new: false,

      loading: false,
      valid: false,

      original_values: {},
      edit_value: null,
      has_unsaved_changes: false,
      pressed_apply: false,
      dirty_dialog: false,
    };
  },

  created() {
    eventBus.$on("open_dialog_sites", (params) => {
      this.open(params);
    });
    window.addEventListener("beforeunload", (event) => {
      if (this.has_unsaved_changes) {
        event.preventDefault();
        event.returnValue = true;
      }
    });
  },
  methods: {
    // @vuese
    // Check for unsaved changes before closing the site card
    attemptToClose() {
      if (this.has_unsaved_changes) {
        this.dirty_dialog = true;
      } else {
        this.close();
      }
    },
    on_field_change(key, value) {
      if (!Object.prototype.hasOwnProperty.call(this.original_values, key)) {
        if (this.edit_value === "" || this.edit_value === false) {
          this.original_values[key] = null;
        } else if (Array.isArray(this.edit_value)) {
          this.original_values[key] = [...this.edit_value];
        } else {
          this.original_values[key] = this.edit_value;
        }
        this.has_unsaved_changes = true;
      }
      if (value === "" || value === false) {
        value = null;
      }
      // console.log(`original: ${this.original_values[key]}, current: ${value}`)
      if (
        this.original_values[key] == value ||
        (Array.isArray(value) &&
          Array.isArray(this.original_values[key]) &&
          value.toSorted().toString() ===
            this.original_values[key].toSorted().toString())
      ) {
        delete this.original_values[key];
        if (Object.keys(this.original_values).length <= 0) {
          this.has_unsaved_changes = false;
        }
      }
    },
    // @vuese
    // Open the site card
    // @arg The paramters for opening the card
    open(params) {
      this.editedItem = params.item;
      this.create_new = params.create_new;
      this.has_unsaved_changes = false;
      this.pressed_apply = false;
      this.original_values = {};
      this.error = "";
      this.dialog = true;
      if (!params.create_new) {
        this.owner_name = params.owner_name;
      }
    },
    // @vuese
    // Close the site card
    close() {
      if (!this.loading) {
        this.dialog = false;
        this.dirty_dialog = false;
        this.has_unsaved_changes = false;
        this.original_values = {};
      }
    },
    // @vuese
    // Apply the changes
    apply() {
      this.loading = true;
      this.uploadItem()
        .then(() => {
          setTimeout(() => {
            eventBus.$emit("updateSites");
            this.loading = false;
            this.pressed_apply = true;
            this.original_values = {};
            this.has_unsaved_changes = false;
            if (this.$store.getters.getEditedCustomer) {
              eventBus.$emit("update_edited_customer");
            }
            if (this.create_new) {
              this.editedItem = {};
              this.owner_name = "";
            }
          }, this.$store.getters.getWaitInterval);
        })
        .catch((err) => {
          console.log(err);
          this.error = err.errors[0].message;
          this.loading = false;
        });
    },
    // @vuese
    // Apply the changes and close the card
    save() {
      this.loading = true;
      this.uploadItem()
        .then(() => {
          setTimeout(() => {
            eventBus.$emit("updateSites");
            this.loading = false;
            this.dialog = false;
            this.editedItem = {};
            this.original_values = {};
            this.has_unsaved_changes = false;
            if (this.$store.getters.getEditedCustomer) {
              eventBus.$emit("update_edited_customer");
            }
          }, this.$store.getters.getWaitInterval);
        })
        .catch((err) => {
          console.log(err);
          this.error = err.errors[0].message;
          this.loading = false;
        });
    },
    // @vuese
    // Upload the unsaved changes to the database
    async uploadItem() {
      delete this.editedItem.updated_at;
      delete this.editedItem.customer;
      delete this.editedItem.index;

      if (this.owner_name) {
        this.editedItem.customer_id = this.owner_name;
      }

      for (const n in this.editedItem) {
        if (
          typeof this.editedItem[n] === "string" ||
          this.editedItem[n] instanceof String
        ) {
          this.editedItem[n] = this.editedItem[n].trim();
        }
      }
      // var temp_site = { ...this.empty_site };
      // for (const i in this.editedItem) {
      //   if (this.editedItem[i]) {
      //     temp_site[i] = this.editedItem[i];
      //   }
      // }
      if (this.create_new) {
        this.editedItem.id = uuidv4();
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_site_one,
            variables: {
              object1: this.editedItem,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        return response;
      } else {
        let edited_record = { ...this.editedItem };
        delete edited_record.id;
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_site_by_pk,
            variables: {
              pk_columns: { id: this.editedItem.id },
              _set: edited_record,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );

        return response;
      }
    },
    // @vuese
    // Delete the selected site
    async deleteItem(id) {
      this.loading = true;
      this.delete_dialog = false;
      try {
        console.log(id)
        this.$nextTick(() => {
          setTimeout(() => {
            eventBus.$emit("updateSites");
            this.loading = false;
            this.dialog = false;
            this.editedItem = {};
          }, this.$store.getters.getWaitInterval);
        });
      } catch (err) {
        this.error = err.errors[0].message;
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.owner_name = "";
        this.site_name = "";
      }
      this.$store.commit("SET_SITE_CARD_DIALOG", this.dialog);
    },
  },
  computed: {
    site_names() {
      if (this.$store.getters.getCustomerNames[1]) {
        return this.$store.getters.getCustomerNames[1][this.owner_name];
      } else {
        return [];
      }
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },
    timezones() {
      return timezones.data;
    },
  },
};
</script>