<template>
	<div>
		<navbar
			@toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
		></navbar>
		<sidenav ref="drawer"></sidenav>
		<v-container>
			<v-row justify="center">
				<v-col cols="12">
					<v-card class="pl-3 pr-3">
						<v-row>
							<v-tabs class="table_tabs ml-3 mt-3">
								<v-tab to="/">Overview</v-tab>
								<v-tab to="/footageuploads">Cameras</v-tab>
								<v-tab to="/inference">Inference</v-tab>
								<v-tab to="/services">Services</v-tab>
								<v-tab to="/system">System</v-tab>
								<v-tab to="/triton">Triton</v-tab>
							</v-tabs>
						</v-row>
						<v-container class="ml-3">
							<v-row no-gutters>
								<v-tabs v-model="selected_view" class="table_tabs">
									<v-tab>FTP</v-tab>
									<v-tab>FOOTAGE</v-tab>
									<v-tab>VPC</v-tab>
									<v-tab>PIPELINE</v-tab>
								</v-tabs>
								<v-col cols="1" class="mr-3">
									<v-select
										v-model="limit"
										label="Limit"
										:items="limit_list"
									></v-select>
								</v-col>
								<v-col cols="2" class="mr-3">
									<v-select
										:disabled="
											date_range.length > 0 && this.on_date_page
										"
										v-model="time_range"
										label="Time Range"
										:items="time_ranges"
									></v-select>
								</v-col>
								<v-col
									cols="2"
									class="mr-3"
									v-if="selected_view == 0 || selected_view == 3"
								>
									<v-autocomplete
										v-model="client"
										label="Client"
										:items="clients"
										:loading="client_loading"
									></v-autocomplete>
								</v-col>
								<v-col
									cols="2"
									class="mr-3"
									v-if="selected_view == 1 || selected_view == 2"
								>
									<customer-filter
										v-model="selected_customer"
									></customer-filter>
								</v-col>
							</v-row>
							<!-- FTP -->
							<v-row no-gutters v-if="selected_view == 0">
								<v-tabs v-model="selected_ftp" class="table_tabs">
									<v-tab>General</v-tab>
									<v-tab>Videos</v-tab>
									<v-tab>Images</v-tab>
									<v-tab>Zero Size</v-tab>
									<v-tab>Zero Speed</v-tab>
									<v-tab>No Image</v-tab>
									<v-tab>No Video</v-tab>
									<v-tab>Uploads</v-tab>
								</v-tabs>
								<v-col
									cols="1"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="selected_view == 0 && selected_ftp == 1"
								>
									<v-select
										class="mr-3"
										v-model="video_size_count_panel"
										:items="video_size_count_panel_list"
									></v-select>
								</v-col>
								<v-col
									cols="1"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="selected_view == 0 && selected_ftp == 2"
								>
									<v-select
										class="mr-3"
										v-model="image_size_count_panel"
										:items="image_size_count_panel_list"
									></v-select>
								</v-col>
								<v-col
									cols="2"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="
										selected_view == 0 &&
										(selected_ftp == 1 || selected_ftp == 2)
									"
								>
									<date-time-menu v-model="date_range"> </date-time-menu>
								</v-col>
								<v-col
									cols="1"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="
										selected_view == 0 &&
										(selected_ftp == 1 || selected_ftp == 2)
									"
								>
									<v-select
										v-model="camera_model"
										:items="[
											{ text: 'All', value: null },
											...$store.getters.getCameraModels,
										]"
										label="Model"
									></v-select>
								</v-col>
								<!-- <v-col
									cols="2"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="
										selected_view == 0 &&
										(selected_ftp == 3 ||
											selected_ftp == 4 ||
											selected_ftp == 5 ||
											selected_ftp == 6)
									"
								>
									<CameraAutoComplete
										:all="true"
										v-model="camera_id"
										:owner="selected_customer"
									></CameraAutoComplete>
								</v-col> -->
							</v-row>
							<!-- General -->
							<v-row v-if="selected_view == 0 && selected_ftp == 0">
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="10"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="22"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="25"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="35"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="36"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="37"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="40"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="14"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="18"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="2"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="12"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="24"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="4"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="6"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="8"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Videos -->
							<v-row v-if="selected_view == 0 && selected_ftp == 1">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										v-if="video_camera_length > 0"
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:panelId="video_size_count_panel"
										:height="100 + 20 * video_camera_length"
										:camera_model="camera_model"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Images -->
							<v-row v-if="selected_view == 0 && selected_ftp == 2">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										v-if="image_camera_length > 0"
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:panelId="image_size_count_panel"
										:height="100 + 20 * image_camera_length"
										:camera_model="camera_model"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Zero Size -->
							<v-row v-if="selected_view == 0 && selected_ftp == 3">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:camera_id="camera_id"
										:panelId="ftp_zero_size_graph_video"
										height="500"
									></grafana-graph>
								</v-col>
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:camera_id="camera_id"
										:panelId="ftp_zero_size_graph_image"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Zero Speed -->
							<v-row v-if="selected_view == 0 && selected_ftp == 4">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:camera_id="camera_id"
										:panelId="ftp_zero_speed_graph"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- No Image -->
							<v-row v-if="selected_view == 0 && selected_ftp == 5">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:camera_id="camera_id"
										:panelId="93"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- No Video -->
							<v-row v-if="selected_view == 0 && selected_ftp == 6">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										:camera_id="camera_id"
										:panelId="118"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Uploads -->
							<v-row v-if="selected_view == 0 && selected_ftp == 7">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="42"
										height="500"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="48"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_ftp"
										:dashboard="dashboard_ftp"
										:limit="limit"
										:client="client"
										panelId="49"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>

							<!-- FOOTAGE -->
							<v-row no-gutters v-if="selected_view == 1">
								<v-tabs v-model="selected_footage" class="table_tabs">
									<v-tab>General</v-tab>
									<v-tab>Videos</v-tab>
									<v-tab>Images</v-tab>
								</v-tabs>
								<v-col
									cols="1"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="selected_view == 1 && selected_footage == 1"
								>
									<v-select
										class="mr-3"
										v-model="footage_video_size_count_panel"
										:items="footage_video_size_count_panel_list"
									></v-select>
								</v-col>
								<v-col
									cols="1"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="selected_view == 1 && selected_footage == 2"
								>
									<v-select
										class="mr-3"
										v-model="footage_image_size_count_panel"
										:items="footage_image_size_count_panel_list"
									></v-select>
								</v-col>
								<v-col
									cols="2"
									class="mt-n2 mr-3 pl-0 pb-0"
									v-if="
										selected_view == 1 && 
										(selected_footage == 1 || selected_footage == 2)
									"
								>
									<date-time-menu v-model="date_range"> </date-time-menu>
								</v-col>
							</v-row>
							<!-- General -->
							<v-row v-if="selected_view == 1 && selected_footage == 0">
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="2"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="4"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="6"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="12"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="14"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="20"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="18"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="22"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="24"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="26"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="32"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="30"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="28"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										panelId="34"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Videos -->
							<v-row v-if="selected_view == 1 && selected_footage == 1">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										v-if="footage_video_camera_length > 0"
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										:panelId="footage_video_size_count_panel"
										:height="100 + 20 * footage_video_camera_length"
										:camera_model="camera_model"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- Images -->
							<v-row v-if="selected_view == 1 && selected_footage == 2">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										v-if="footage_image_camera_length > 0"
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_footage"
										:dashboard="dashboard_footage"
										:limit="limit"
										:client="user_id"
										:panelId="footage_image_size_count_panel"
										:height="100 + 20 * footage_image_camera_length"
										:camera_model="camera_model"
									></grafana-graph>
								</v-col>
							</v-row>

							<!-- VRPC -->
							<v-row no-gutters v-if="selected_view == 2">
								<v-tabs v-model="selected_vpc" class="table_tabs">
									<v-tab>General</v-tab>
									<v-tab>FPS</v-tab>
									<v-tab>Zero Size</v-tab>
									<v-tab>Uploads</v-tab>
								</v-tabs>
							</v-row>
							<v-row v-if="selected_view == 2 && selected_vpc == 0">
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="12"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="14"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="4" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="16"
										height="100"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="22"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="4"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="20"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="10"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="6"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="18"
										height="300"
									></grafana-graph>
								</v-col>
								<v-col cols="6" class="pl-0 pb-0">
									<grafana-graph
										from="now-24h"
										to="now"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="2"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>
							<v-row v-if="selected_view == 2 && selected_vpc == 1">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="30"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<v-row v-if="selected_view == 2 && selected_vpc == 2">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										:panelId="vpc_zero_size_graph"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>
							<v-row v-if="selected_view == 2 && selected_vpc == 3">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:time_bucket="time_settings[time_range].bucket"
										:database="database"
										:table="table_video"
										:dashboard="dashboard_video"
										:limit="limit"
										:client="user_id"
										panelId="26"
										height="500"
									></grafana-graph>
								</v-col>
							</v-row>

							<!-- Pipeline -->
							<v-row no-gutters v-if="selected_view == 3">
								<v-tabs v-model="selected_pipeline" class="table_tabs">
									<v-tab>Throughput (Size)</v-tab>
									<v-tab>Throughput (Count)</v-tab>
								</v-tabs>
							</v-row>
							<v-row v-if="selected_view == 3 && selected_pipeline == 1">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:dashboard="dashboard_pipeline"
										:client="client"
										panelId="2"
										height="600"
									></grafana-graph>
								</v-col>
							</v-row>
							<v-row v-if="selected_view == 3 && selected_pipeline == 0">
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_from"
										:to="time_to"
										:dashboard="dashboard_pipeline"
										:client="client"
										panelId="4"
										height="600"
									></grafana-graph>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import navbar from "../components/Navbar.vue";
import sidenav from "../components/SideNav.vue";
import GrafanaGraph from "../components/GrafanaGraph.vue";
import customerFilter from "../components/CustomerFilter.vue";
import DateTimeMenu from "../components/DateTimeMenu.vue";
// import CameraAutoComplete from "../components/CameraAutoComplete.vue";
import { initializeTimestreamQueryClient } from "../utilities/sessionUtils";
import { QueryCommand } from "@aws-sdk/client-timestream-query";
var queryClient;
import moment from "moment";

// @vuese
// @group Views
export default {
	components: {
		navbar,
		sidenav,
		GrafanaGraph,
		customerFilter,
		DateTimeMenu,
		// CameraAutoComplete,
	},
	data() {
		return {
			selected_view: 0,
			selected_ftp: 0,
			selected_vpc: 0,
			selected_footage: 0,
			selected_pipeline: 0,
			database: "pipeline_metrics",
			table_ftp: "ftp_ingress",
			table_footage: "footage_metrics",
			table_video: "video_metrics",
			dashboard_ftp: "vsftpd",
			dashboard_footage: "footage_metrics",
			dashboard_video: "vprc",
			dashboard_pipeline: "miscellaneous",
			time_range: "24h",
			limit: "1000000",
			limit_list: [
				{ text: "100,000", value: "100000" },
				{ text: "250,000", value: "250000" },
				{ text: "500,000", value: "500000" },
				{ text: "1 Million", value: "1000000" },
				{ text: "2 Million", value: "2000000" },
				{ text: "5 Million", value: "5000000" },
				{ text: "All", value: "ALL" },
			],
			client: "user",
			clients: [],
			client_loading: false,

			user_id: "user_id",
			selected_customer: null,

			video_cameras: [],
			image_cameras: [],
			footage_video_cameras: [],
			footage_image_cameras: [],

			video_camera_length: 0,
			image_camera_length: 0,
			footage_video_camera_length: 0,
			footage_image_camera_length: 0,

			video_size_count_panel: 27,
			video_size_count_panel_list: [
				{ text: "Total Count", value: 27 },
				{ text: "Total Size", value: 96 },
				{ text: "Average Size", value: 98 },
			],
			image_size_count_panel: 28,
			image_size_count_panel_list: [
				{ text: "Total Count", value: 28 },
				{ text: "Total Size", value: 97 },
				{ text: "Average Size", value: 99 },
			],

			footage_video_size_count_panel: 38,
			footage_video_size_count_panel_list: [
				{ text: "Total Count", value: 38 },
				{ text: "Total Size", value: 39 },
				{ text: "Average Size", value: 40 },
			],

			footage_image_size_count_panel: 44,
			footage_image_size_count_panel_list: [
				{ text: "Total Count", value: 44 },
				{ text: "Total Size", value: 46 },
				{ text: "Average Size", value: 48 },
			],

			date_range: [],
			camera_loading: false,
			camera_model: null,

			camera_id: null,
		};
	},
	created() {
		// if (this.$store.getters.getCameraObjects.length == 0) {
		// 	this.camera_loading = true;
		// 	this.$store.dispatch("DDB_GET_CAMERAS").then(()=>{
		// 		this.camera_loading = false;
		// 	});
		// }
		this.initializeQueryClient()
			.then(() => {
				this.getAllClients();
				this.getAllVideoCameras();
				this.getAllImageCameras();
				this.getAllFootageVideoCameras();
				this.getAllFootageImageCameras();
			})
			.catch((err) => {
				console.log(err);
			});
	},
	methods: {
		sortByKey(array, key) {
			return array.sort(function (a, b) {
				var x = a[key];
				var y = b[key];
				return x < y ? -1 : x > y ? 1 : 0;
			});
		},
		initializeQueryClient() {
			return initializeTimestreamQueryClient().then((client) => {
				queryClient = client;
			});
		},
		getAllClients() {
			this.clients = [];
			this.client_loading = true;
			(async () => {
				await this.getClients();
				this.sortByKey(this.clients, "text");
				this.clients.unshift({ text: "All", value: "user" });
				this.client_loading = false;
			})();
		},
		getAllFootageVideoCameras() {
			this.footage_video_cameras = [];
			(async () => {
				await this.getFootageVideoCameras();
				this.footage_video_camera_length = this.footage_video_cameras.length;
			})();
		},
		getAllFootageImageCameras() {
			this.footage_image_cameras = [];
			(async () => {
				await this.getFootageImageCameras();
				this.footage_image_camera_length = this.footage_image_cameras.length;
			})();
		},
		getAllVideoCameras() {
			this.video_cameras = [];
			(async () => {
				await this.getVideoCameras();
				this.video_camera_length = this.video_cameras.length;
			})();
		},
		getAllImageCameras() {
			this.image_cameras = [];
			(async () => {
				await this.getImageCameras();
				this.image_camera_length = this.image_cameras.length;
			})();
		},
		async getClients(nextToken = undefined) {
			let response;
			var params = {
				QueryString: `SELECT DISTINCT user FROM "${this.database}"."${this.table_ftp}"`,
				NextToken: nextToken,
			};
			var command = new QueryCommand(params);
			try {
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.clients = [
				...this.clients,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return { text: value, value: `'${value}'` };
				}),
			];
			if (response.NextToken) {
				await this.getClients(response.NextToken);
			}
		},
		async getFootageVideoCameras(nextToken = undefined) {
			let response;
			try {
				var args = this.time_settings[this.time_range].moment_args;
				var date = moment();
				var to = date.valueOf();
				var from = date.subtract(args[0], args[1]).valueOf();
				var client = this.user_id;
				var params = {
					QueryString: `
							SELECT DISTINCT camera_id 
							FROM "${this.database}"."${this.table_footage}"
							WHERE 
								type = 'VIDEO' 
								AND time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
								AND user_id = ${client}
							`,
					NextToken: nextToken,
				};
				var command = new QueryCommand(params);
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.footage_video_cameras = [
				...this.footage_video_cameras,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value;
				}),
			];
			if (response.NextToken) {
				await this.getFootageVideoCameras(response.NextToken);
			}
		},
		async getFootageImageCameras(nextToken = undefined) {
			let response;
			try {
				var args = this.time_settings[this.time_range].moment_args;
				var date = moment();
				var to = date.valueOf();
				var from = date.subtract(args[0], args[1]).valueOf();
				var client = this.user_id;
				var params = {
					QueryString: `
							SELECT DISTINCT camera_id 
							FROM "${this.database}"."${this.table_footage}"
							WHERE 
								type = 'IMAGE' 
								AND time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
								AND user_id = ${client}
							`,
					NextToken: nextToken,
				};
				var command = new QueryCommand(params);
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.footage_image_cameras = [
				...this.footage_image_cameras,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value;
				}),
			];
			if (response.NextToken) {
				await this.getFootageImageCameras(response.NextToken);
			}
		},
		async getVideoCameras(nextToken = undefined) {
			let response;
			try {
				var args = this.time_settings[this.time_range].moment_args;
				var date = moment();
				var to = date.valueOf();
				var from = date.subtract(args[0], args[1]).valueOf();
				var client = this.client;
				var camera_model_condition = "";
				if (this.camera_model) {
					camera_model_condition = `AND model = '${this.camera_model}'`;
				}
				var params = {
					QueryString: `
							SELECT DISTINCT camera_id 
							FROM "${this.database}"."${this.table_ftp}"
							WHERE 
								type = 'VIDEO' 
								AND time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
								${camera_model_condition}
								AND user = ${client}
							`,
					NextToken: nextToken,
				};
				var command = new QueryCommand(params);
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.video_cameras = [
				...this.video_cameras,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value;
				}),
			];
			if (response.NextToken) {
				await this.getVideoCameras(response.NextToken);
			}
		},
		async getImageCameras(nextToken = undefined) {
			let response;
			try {
				var args = this.time_settings[this.time_range].moment_args;
				var date = moment();
				var to = date.valueOf();
				var from = date.subtract(args[0], args[1]).valueOf();
				var client = this.client;
				var camera_model_condition = "";
				if (this.camera_model) {
					camera_model_condition = `AND model = '${this.camera_model}'`;
				}
				var params = {
					QueryString: `
							SELECT DISTINCT camera_id 
							FROM "${this.database}"."${this.table_ftp}"
							WHERE 
								type = 'IMAGE' 
								AND time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
								${camera_model_condition}
								AND user = ${client}
							`,
					NextToken: nextToken,
				};
				var command = new QueryCommand(params);

				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.image_cameras = [
				...this.image_cameras,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value;
				}),
			];
			if (response.NextToken) {
				await this.getImageCameras(response.NextToken);
			}
		},
	},
	watch: {
		selected_customer() {
			if (
				!this.selected_customer ||
				this.selected_customer == "All Customers"
			) {
				this.user_id = "user_id";
			} else {
				this.user_id = `'${this.selected_customer}'`;
			}
		},
		client() {
			this.getAllVideoCameras();
			this.getAllImageCameras();
		},
		user_id() {
			this.getAllFootageVideoCameras();
			this.getAllFootageImageCameras();
		},
		time_range() {
			this.getAllVideoCameras();
			this.getAllImageCameras();
			this.getAllFootageVideoCameras();
			this.getAllFootageImageCameras();
		},
		camera_model() {
			this.getAllVideoCameras();
			this.getAllImageCameras();
		},
	},
	computed: {
		time_ranges() {
			return this.$store.getters.getTimeRanges;
		},
		time_from() {
			if (
				this.date_range.length > 0 && this.on_date_page
			) {
				return moment(this.date_range[0] + " " + "00:00:01").valueOf();
			} else {
				return this.time_settings[this.time_range].from;
			}
		},
		time_to() {
			if (
				this.date_range.length > 0 && this.on_date_page
			) {
				return moment(
					this.date_range[this.date_range.length - 1] + " " + "23:59:59"
				).valueOf();
			} else {
				return this.time_settings[this.time_range].to;
			}
		},
		time_settings() {
			return this.$store.getters.getTimeSettings;
		},
		on_date_page(){
			return (this.selected_view == 0 &&
				(this.selected_ftp == 1 || this.selected_ftp == 2)) 
				|| (this.selected_view == 1 && 				
				(this.selected_footage == 1 || this.selected_footage == 2));
		},
		// camera_model_list(){
		// 	return this.$store.getters.getCameraObjects.map(e=>`'${e.camera_model}'`).join(",");
		// },
		// camera_id_list(){
		// 	return this.$store.getters.getCameraObjects.map(e=>`'${e.id}'`).join(",");
		// },
		// ftp_zero_size_graph(){
		// 	switch(this.time_range){
		// 		case "1h":
		// 			return 54;
		// 		case "3h":
		// 			return 55;
		// 		case "6h":
		// 			return 56;
		// 		case "12h":
		// 			return 57;
		// 		case "24h":
		// 			return 58;
		// 		case "3d":
		// 			return 59;
		// 		case "7d":
		// 			return 60;
		// 		default:
		// 			return 58
		// 	}
		// },
		ftp_zero_size_graph_video() {
			switch (this.time_range) {
				case "5m":
					return 109;
				case "10m":
					return 108;
				case "15m":
					return 107;
				case "30m":
					return 106;
				case "1h":
					return 74;
				case "3h":
					return 75;
				case "6h":
					return 76;
				case "12h":
					return 77;
				case "24h":
					return 78;
				case "3d":
					return 79;
				case "7d":
					return 80;
				case "14d":
					return 110;
				case "28d":
					return 111;
				default:
					return 74;
			}
		},
		ftp_zero_size_graph_image() {
			switch (this.time_range) {
				case "5m":
					return 115;
				case "10m":
					return 114;
				case "15m":
					return 113;
				case "30m":
					return 112;
				case "1h":
					return 81;
				case "3h":
					return 82;
				case "6h":
					return 83;
				case "12h":
					return 84;
				case "24h":
					return 85;
				case "3d":
					return 86;
				case "7d":
					return 87;
				case "14d":
					return 116;
				case "28d":
					return 117;
				default:
					return 81;
			}
		},
		vpc_zero_size_graph() {
			switch (this.time_range) {
				case "5m":
					return 50;
				case "10m":
					return 49;
				case "15m":
					return 48;
				case "30m":
					return 47;
				case "1h":
					return 34;
				case "3h":
					return 36;
				case "6h":
					return 38;
				case "12h":
					return 40;
				case "24h":
					return 42;
				case "3d":
					return 44;
				case "7d":
					return 46;
				case "14d":
					return 51;
				case "28d":
					return 52;
				default:
					return 34;
			}
		},
		ftp_zero_speed_graph() {
			switch (this.time_range) {
				case "5m":
					return 105;
				case "10m":
					return 104;
				case "15m":
					return 103;
				case "30m":
					return 102;
				case "1h":
					return 63;
				case "3h":
					return 64;
				case "6h":
					return 65;
				case "12h":
					return 66;
				case "24h":
					return 67;
				case "3d":
					return 68;
				case "7d":
					return 69;
				case "14d":
					return 100;
				case "28d":
					return 101;
				default:
					return 63;
			}
		},
	},
};
</script>
