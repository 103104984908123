import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomePage.vue'),
    },
    {
      path: '/videolist',
      name: 'videolist',
      component: () => import('../views/VideoList.vue'),
    },
    {
      path: '/inference',
      name: 'inference',
      component: () => import('../views/Inference.vue'),
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('../views/Services.vue'),
    },
    {
      path: '/system',
      name: 'system',
      component: () => import('../views/System.vue'),
    },
    {
      path: '/triton',
      name: 'triton',
      component: () => import('../views/Triton.vue'),
    },
    {
      path: '/footageuploads',
      name: 'footageuploads',
      component: () => import('../views/FootageUploads.vue'),
    },
    {
      path: '/betsylist',
      name: 'betsylist',
      component: () => import('../views/BetsyList.vue'),
    },
    {
      path: '/images',
      name: 'images',
      component: () => import('../views/Images.vue'),
    },
    {
      path: '/videoboard',
      name: 'videoboard',
      component: () => import('../views/VideoBoard.vue'),
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('../views/Users.vue'),

    },
    {
      path: '/cameras/:filter',
      name: 'cameras',
      component: () => import('../views/Cameras.vue'),
      props: true,
    },
    {
      path: '/sites/:filter',
      name: 'sites',
      component: () => import('../views/Sites.vue'),
      props: true,
    },

    {
      path: '/customers',
      name: 'customers',
      component: () => import('../views/Customers.vue'),
      props: true,
    },
    {
      path: '/customer',
      name: 'customer',
      component: () => import('../views/CustomerPage.vue'),
      props: true,
    },
    {
      path: '/wifi/:filter',
      name: 'wifi',
      component: () => import('../views/Wifi.vue'),
      props: true,
    },
    {
      path: '/animals',
      name: 'animals',
      component: () => import('../views/Animals.vue'),
      props: true,
    },
    {
      path: '/notes',
      name: 'notes',
      component: () => import('../views/Notes.vue'),
      props: true,
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('../views/Reports.vue'),
      props: true,
    },
    {
      path: '/treeview',
      name: 'treeview',
      component: () => import('../views/Treeview.vue'),
      props: true,
    },
    {
      path: '/feedbacks',
      name: 'feedbacks',
      component: () => import('../views/Feedbacks.vue'),
      props: true,
    },
    {
      path: '/customermap',
      name: 'customermap',
      component: () => import('../views/CustomerMap.vue'),
      props: true,
    },
    {
      path: '/videos/heat',
      name: 'videos-heat',
      component: () => import('../views/VideosHeat.vue'),
      props: true,
    },
    {
      path: '/videos/:filter',
      name: 'videos',
      component: () => import('../views/Videos.vue'),
      props: true,
    },
    {
      path: '/herds/',
      name: 'herds',
      component: () => import('../views/Herds.vue'),
      props: true,
    },
    {
      path: '/betsy/:filter',
      name: 'betsy',
      component: () => import('../views/BetsyVideos.vue'),
      props: true,
    },
    {
      path: '/video/:url&:duration&:sort_by&:sort_desc&:prev_page&:cam_name&:date',
      name: 'video',
      component: () => import('../views/VideoPlayback.vue'),
      props: true,
    },
    {
      path: '/animal/:id&:sort_by&:sort_desc&:prev_page',
      name: 'animal',
      component: () => import('../views/SelectedLivestock.vue'),
      props: true,
    },
    {
      path: '/herd/:id&:sort_by&:sort_desc&:prev_page',
      name: 'herd',
      component: () => import('../views/SelectedHerd.vue'),
      props: true,
    },
    {
      path: '/events/:filter',
      name: 'events',
      component: () => import('../views/Events.vue'),
      props: true,
    },
    {
      path: '/livestock/:filter',
      name: 'livestock',
      component: () => import('../views/Animals.vue'),
      props: true,
    },
    {
      path: '/projects/:filter/:id',
      name: 'projects',
      component: () => import('../views/Projects.vue'),
      props: true,
    },
    {
      path: '/contacts/:filter/:id',
      name: 'contacts',
      component: () => import('../views/Contacts.vue'),
      props: true,
    },
    {
      path: '/correspondence/:filter/:id',
      name: 'correspondence',
      component: () => import('../views/Correspondence.vue'),
      props: true,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue'),

    },
    {
      path: '/grafana_beta',
      name: 'grafana_beta',
      component: () => import('../views/grafana_beta.vue'),

    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/TestField.vue'),
    },
    {
      path: '/videotable',
      name: 'videotable',
      component: () => import('../views/VideoTable.vue'),
    },
    {
      path: '/imagetable',
      name: 'imagetable',
      component: () => import('../views/ImageTable.vue'),
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('../views/Notifications.vue'),
    },
    {
      path: '/calving-analytics',
      name: 'calving-analytics',
      component: () => import('../views/CalvingAnalytics.vue'),
    },
    {
      path: '/estrus-analytics',
      name: 'estrus-analytics',
      component: () => import('../views/EstrusAnalytics.vue'),
    },
    {
      path: '/predator-analytics',
      name: 'predator-analytics',
      component: () => import('../views/PredatorAnalytics.vue'),
    },
    {
      path: '/animalidentifications',
      name: 'animalidentifications',
      component: () => import('../views/AnimalIdentifications.vue'),
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/UserSettings.vue'),
    },
    {
      path: '/upload-analytics',
      name: 'upload-analytics',
      component: () => import('../views/UploadAnalytics.vue'),
    },
    {
      path: '*',
      name: 'not found',
      component: () => import('../views/404.vue'),
    },

  ],
});




// router.beforeResolve((to, from, next) => {
//   const authService = store.state.user;
//   console.log(authService)
//   if (to.name !== 'Login' && authService == null) next({ name: 'Login', query: { redirect: to.fullPath } })
//   else next()

// });

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && !store.state.user) {
    try {
      await store.dispatch('fetchUser');
      console.log(store.state.user)
      if (store.state.user) {
        next(); // Proceed to the intended route
      } else {
        next({ name: 'Login', query: { redirect: to.fullPath } });
      }
    } catch (error) {
      next({ name: 'Login', query: { redirect: to.fullPath } }); // Redirect to login
    }
  } else {
    next(); // User is already authenticated or is navigating to Login
  }
});

export default router;
