<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" min-width="760px" persistent no-click-animation @click:outside="attemptToClose">
      <v-card :loading="loading">
        <v-card-title>
          <span class="" style="color: #0090a4" v-if="create_new"
            >Add Jetson</span
          >
          <span class="" style="color: #0090a4" v-else>Edit Jetson</span>
          <v-chip class="ml-2" small v-if="has_unsaved_changes">{{ Object.keys(original_values).length +" unsaved changes"}}</v-chip>
          <v-spacer></v-spacer>
            <v-btn icon @click="attemptToClose" :disabled="loading">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid" @submit="save">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col v-if="create_new" cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.id"
                      label="ID *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.id"
                      @input="on_field_change('id', $event)"
                    ></v-text-field> </v-col
                  ><v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="owner_name"
                      :items="customer_names"
                      :menu-props="{ offsetOverflow: false }"
                      label="Customer *"
                      outlined
                      dense
                      @focus="edit_value = owner_name"
                      @input="on_field_change('owner_name', $event)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="site_name"
                      :items="site_names"
                      :menu-props="{ offsetOverflow: false }"
                      label="Site *"
                      outlined
                      dense
                      @focus="edit_value = site_name"
                      @input="on_field_change('site_name', $event)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.jetson_manufacturer"
                      label="Manufacturer *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_manufacturer"
                      @input="on_field_change('jetson_manufacturer', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.jetson_thing_name"
                      label="Thing Name *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_thing_name"
                      @input="on_field_change('jetson_thing_name', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.jetson_status"
                      :items="$store.getters.getOnlineStatus"
                      label="Status *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_status"
                      @input="on_field_change('jetson_status', $event)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.jetson_shipped"
                      :items="$store.getters.getShippingStatus"
                      label="Shipping Status *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_shipped"
                      @input="on_field_change('jetson_shipped', $event)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.jetson_type"
                      label="Type *"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_type"
                      @input="on_field_change('jetson_type', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.jetson_location.latitude"
                      label="Latitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_location.latitude"
                      @input="on_field_change('latitude', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.jetson_location.longitude"
                      label="Longitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.jetson_location.longitude"
                      @input="on_field_change('longitude', $event)"
                    ></v-text-field>
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-form>
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-row>
            <v-col cols="12" class="py-0"
              ><v-icon
                class="pl-1"
                style="float: left"
                v-if="!create_new"
                color="primary"
                @click="delete_dialog = true"
                :disabled="loading"
                >mdi-delete</v-icon
              >
              <div style="float: right">
                <v-btn
                  color="primary"
                  @click="apply"
                  :disabled="!valid || loading || !has_unsaved_changes"
                >
                  Apply
                </v-btn>
                <v-btn class="ml-4" @click="attemptToClose" :disabled="loading"> Cancel </v-btn>
                <v-btn 
                  color="primary" 
                  class="ml-4" 
                  @click="save" 
                  :disabled="!valid || loading || (!has_unsaved_changes && !pressed_apply)"
                > 
                  Save 
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-if="error"
              ><span class="error_span">{{ error }}</span></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_dialog" max-width="550px" min-width="260px">
      <v-card>
        <v-card-title>Delete Jetson for {{formulateCustomerNames(editedItem.customer)}}?</v-card-title>
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
            <v-btn
              color="#dd3f5b"
              @click="deleteItem(editedItem.id)"
              class="mr-3 white--text"
            >
            Delete
            </v-btn>
            <v-btn color="primary" @click="delete_dialog = false">
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dirty_dialog" max-width="550px" min-width="260px">
      <v-card>
        <v-card-title>Discard unsaved changes?</v-card-title>
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
          <v-btn
            color="#dd3f5b"
            @click="close()"
            class="mr-3 white--text"
          >
            Discard
          </v-btn>
          <v-btn color="primary" @click="dirty_dialog = false">
            Cancel
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import { eventBus } from "../main";
import nameMixin from "../mixins/nameMixin";

// @vuese
// @group Components
// The editing card for jetson
export default {
  mixins: [nameMixin],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
      },
      editedItem: { jetson_location: {} },
      delete_dialog: false,
      dialog: false,
      owner_name: "",
      error: "",
      site_name: "",
      create_new: false,

      loading: false,
      valid: false,

      original_values: {},
      edit_value: null,
      has_unsaved_changes: false,
      pressed_apply: false,
      dirty_dialog: false
    };
  },

  created() {
    eventBus.$on("open_dialog_jetson", (params) => {
      this.open(params);
    });
    // if (this.$store.getters.getJetsonOnlineStatus.length == 0) {
    //   this.$store.dispatch("DDB_GET_JETSON_ONLINE_STATUS");
    // }
    // if (this.$store.getters.getJetsonShippingStatus.length == 0) {
    //   this.$store.dispatch("DDB_GET_JETSON_SHIPPING_STATUS");
    // }
    window.addEventListener('beforeunload', (event)=>{
      if(this.has_unsaved_changes){
        event.preventDefault()
        event.returnValue = true
      }
    });
  },
  methods: {
    // @vuese
    // Check for unsaved changes before closing the jetson card
    attemptToClose(){
      if(this.has_unsaved_changes){
        this.dirty_dialog = true;
      }else{
        this.close();
      }
    },
    on_field_change(key, value) {
      if (!Object.prototype.hasOwnProperty.call(this.original_values, key)) {
        if (this.edit_value === "" || this.edit_value === false){
          this.original_values[key] = null;
        }else if(Array.isArray(this.edit_value)){
          this.original_values[key] = [...this.edit_value];
        }else{
          this.original_values[key] = this.edit_value;
        }
        this.has_unsaved_changes = true;
      }
      if (value === "" || value === false) {
        value = null;
      }
      // console.log(`original: ${this.original_values[key]}, current: ${value}`)
      if (this.original_values[key] == value
      ||(
        Array.isArray(value) 
        && Array.isArray(this.original_values[key]) 
        && value.toSorted().toString() === this.original_values[key].toSorted().toString())) {
        delete this.original_values[key];
        if(Object.keys(this.original_values).length <=0){
          this.has_unsaved_changes = false;
        }
      }
    },
    // @vuese
    // Open the jetson card
    // @arg The paramters for opening the card
    open(params) {
      this.editedItem = params.item;
      this.create_new = params.create_new;
      this.has_unsaved_changes = false;
      this.pressed_apply = false;
      this.original_values = {};
      this.error="";
      this.dialog = true;
      if (!params.create_new) {
        this.owner_name = params.owner_name;
        this.site_name = params.site_name;
      }
    },
    // @vuese
    // Close the jetson card
    close() {
      if(!this.loading){
        this.dialog = false;
        this.dirty_dialog = false;
        this.has_unsaved_changes = false;
        this.original_values = {};
      }
    },
    // @vuese
    // Apply the changes
    apply(){
      this.loading = true;
      this.uploadItem().then(()=>{
        setTimeout(() => {
          eventBus.$emit("updateJetsons");
          this.loading = false;
          this.pressed_apply = true;
          this.original_values = {};
          this.has_unsaved_changes = false;
          if(this.$store.getters.getEditedCustomer){
            eventBus.$emit("update_edited_customer");
          }
          if(this.create_new){
            this.editedItem = { jetson_location: {} };
            this.owner_name = "";
            this.site_name = "";
          }
        }, this.$store.getters.getWaitInterval);
      }).catch(err=>{
        console.log(err);
        this.error = err.errors[0].message;
        this.loading = false;
      })
    },
    // @vuese
    // Apply the changes and close the card
    save() {
      this.loading = true;
      this.uploadItem().then(()=>{
        setTimeout(() => {
          eventBus.$emit("updateJetsons");
          this.loading = false;
          this.dialog = false;
          this.editedItem = { jetson_location: {} };
          this.original_values = {};
          this.has_unsaved_changes = false;
          if(this.$store.getters.getEditedCustomer){
            eventBus.$emit("update_edited_customer");
          }
        }, this.$store.getters.getWaitInterval);
      }).catch(err=>{
        console.log(err);
        this.error = err.errors[0].message;
        this.loading = false;
      })
    },
    // @vuese
    // Upload the unsaved changes to the database
    uploadItem() {
      delete this.editedItem.createdAt;
      delete this.editedItem.customer;
      delete this.editedItem.updatedAt;
      delete this.editedItem.events;
      delete this.editedItem.site;
      delete this.editedItem._lastChangedAt;
      delete this.editedItem.index;
      this.editedItem.updatedBy = this.$store.getters.getUser.username;
      if (this.owner_name) {
        this.editedItem.owner = this.owner_name;
        this.editedItem.customer_id = this.owner_name;
        this.editedItem.site_id = this.site_name;
      }
      for (const n in this.editedItem) {
        if (
          typeof this.editedItem[n] === "string" ||
          this.editedItem[n] instanceof String
        ) {
          this.editedItem[n] = this.editedItem[n].trim();
        }
      }
      if (this.create_new) {
        this.editedItem.author = {
          id: this.$store.getters.getUser.username,
          first_name: this.$store.getters.getUser.attributes.given_name,
          last_name: this.$store.getters.getUser.attributes.family_name,
        };

        return API.graphql({
          query: mutations.createJetson,
          variables: {
            input: this.editedItem,
          },
        });
      } else {
        this.editedItem.owner = this.owner_name;
        return API.graphql({
          query: mutations.updateJetson,
          variables: {
            input: this.editedItem,
          },
        });
      }
    },
    // @vuese
    // Delete the selected jetson
    async deleteItem(id) {
      this.delete_dialog = false;
      this.loading = true;
      try{
        await API.graphql({
          query: mutations.deleteJetson,
          variables: {
            input: { id: id},
          },
        });
        this.$nextTick(() => {
          setTimeout(()=>{
            eventBus.$emit("updateJetsons");
            this.loading = false;
            this.dialog = false;
            this.editedItem = { jetson_location: {} };
          }, this.$store.getters.getWaitInterval)
        });
      } catch (err) {
        this.error = err.errors[0].message;
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.owner_name = "";
        this.site_name = "";
      }
      this.$store.commit('SET_JETSON_CARD_DIALOG', this.dialog);
    },
  },
  computed: {
    site_names() {
      if (this.$store.getters.getCustomerNames[1]) {
        return this.$store.getters.getCustomerNames[1][this.owner_name];
      } else {
        return [];
      }
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },
  },
};
</script>