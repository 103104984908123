<template>
  <v-text-field
    v-model="date_range_text"
    readonly
    label="Date Range"
  >
    <template v-slot:append>
      <v-menu
        v-model="datetime_menu"
        nudge-top="9"
        nudge-right="12"
        :close-on-content-click="false"
        open-on-click
        left
        offset-overflow
        @input="onMenuInput"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
        </template>

        <v-card height="380" width="280">
          <v-row>
            <v-col>
              <v-date-picker
                v-model="date_range"
                flat
                range
                no-title
                id="video_dp"
                event-color="#ff9900"
                :max="todays_date"
              ></v-date-picker>
            </v-col>
          </v-row>
          <v-row v-if="show_timebar">
            <v-btn
              class="filter_button"
              color="primary"
              id="saveButton"
              @click="saveDTFilterMenu"
            >
              Save
            </v-btn>
            <v-icon
              v-if="selected_dates.length"
              @click="reset_dates"
              class="ml-4 mt-n2"
              >mdi-filter-off-outline</v-icon
            >
          </v-row>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import moment from "moment-timezone";

// @vuese
// @group Components
// The menu for choosing datetime.
export default {
  props: {
    // The array of dates [from, to] (YYYY-MM-DD)
    'value': {
        type: Array,
        required: false,
    }},
    data(){
        return {
            date_range: this.value,
            datetime_menu: false,
            todays_date: moment().format("YYYY-MM-DD"),
            show_timebar: false,
            selected_dates: this.value,
            real_dates: []
        }
    },
    watch: {
      value(){
        this.date_range = this.value;
        this.selected_dates = this.value;
      },
      date_range() {
        if (this.date_range.length > 0) {
          this.show_timebar = true;
        } else {
          this.show_timebar = false;
        }
      },
    },
    methods:{
      onMenuInput(e){
        if(e){
          this.real_dates = [...this.selected_dates];
        }else{
          this.selected_dates = [...this.real_dates];
          this.date_range = [...this.real_dates];
        }
      },
      saveDTFilterMenu() {
        this.datetime_menu = false;
        if (this.date_range.length == 2) {
          if (
            moment(this.date_range[0], "YYYY-MM-DD").isAfter(
              moment(this.date_range[1], "YYYY-MM-DD")
            )
          ) {
            this.selected_dates = [
              this.date_range[1],
              this.date_range[0]
            ];
          } else {
            this.selected_dates = [
              this.date_range[0],
              this.date_range[1]
            ];
          }
        } else if (this.date_range.length == 1) {
          this.selected_dates = [
            this.date_range[0]
          ];
        } else {
          this.selected_dates = [];
        }
        this.$emit('input', this.selected_dates);
        this.real_dates = this.selected_dates;
        this.$emit('changed', this.selected_dates);
      },
      reset_dates() {
        this.selected_dates = [];
        this.date_range = [];
        this.$emit('input', this.selected_dates);
        this.$emit('changed', this.selected_dates);
      },
    },
    computed: {
      date_range_text() {
        var temp_arr = [...this.date_range];
        return temp_arr.sort().map(e=>moment(e).format("YYYY-MM-DD")).join(" ~ ");
      },
    }
};
</script>

<style>
.filter_button {
  font-size: 14px;
  text-transform: none;
}

#saveButton {
  margin-bottom: 10px;
  margin-left: 30px;
}
</style>