<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-row no-gutters v-if="livestock.length > 0">
      <v-col xl="1" lg="1" class="nav_btn" align-self="center"
        ><v-btn
          class="mr-n10"
          :to="
            '/animal/' +
            livestock[index - 1].id +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page
          "
          icon
          v-if="index != 0"
        >
          <v-icon class="nav_icon">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10"
        ><v-container>
          <div>
            <v-row>
              <v-btn
                :to="'/' + prev_page.replaceAll('+', '/')"
                large
                class="filter_btn ml-3 mb-3"
                >back
              </v-btn>
              <v-spacer />
            </v-row>
            <div>
              <v-card>
                <v-card-text class="pt-0 pb-0 pl-3 pr-3">
                  <v-row justify="space-around">
                    <v-tabs
                      class="table_tabs_hp float_left ml-3 mt-1"
                      v-model="selected_tab"
                      show-arrows
                    >
                      <v-tab>Main</v-tab>
                      <v-tab>ID</v-tab>
                      <v-tab>Appearances</v-tab>
                      <v-tab>Activity</v-tab>
                      <v-tab>Health</v-tab>
                      <v-tab>Phenotypes</v-tab>
                      <v-tab>Growth</v-tab>
                      <v-tab>Lineage</v-tab>
                    </v-tabs>

                    <v-select
                      class="select_tag mr-3 pt-3"
                      :items="livestock_tags"
                      v-model="selected_tag"
                      dense
                      flat
                      hide-details
                    ></v-select>
                  </v-row>
                  <v-row v-if="selected_tab == 0">
                    <v-col xl="2" lg="3" md="4" sm="4" cols="4">
                      <v-card>
                        <v-card-text
                          ><v-row class="mb-2 mt-n2 ml-n2 mr-n2"
                            ><v-img
                              height="195"
                              :src="`${
                                cookie_bucket +
                                livestock[index].livestock_head_img
                              }`"
                          /></v-row>
                          <v-row class="mb-2 ml-n2 mr-n2"
                            ><v-img
                              height="195"
                              :src="`${
                                cookie_bucket +
                                livestock[index].livestock_heatmap_img
                              }`"
                          /></v-row>
                          <v-row class="mb-n2 ml-n2 mr-n2"
                            ><v-img
                              height="195"
                              :src="`${
                                cookie_bucket +
                                livestock[index].livestock_body_img
                              }`"
                          /></v-row>
                        </v-card-text> </v-card
                    ></v-col>
                    <v-col>
                      <v-row class="mt-n3"
                        ><v-col xl="6" lg="6" md="6" sm="6">
                          <v-card
                            ><v-card-text
                              ><v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    ID
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1">{{
                                    livestock[index].livestock_drop_tag
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    RFID
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1">{{
                                    livestock[index].livestock_rfid_tag
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    OCR ID
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1"
                                    >{{ livestock[index].livestock_ocr_id }} -
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score <
                                        70
                                      "
                                      class="red_color"
                                      >{{
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score
                                      }}%</span
                                    >
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score >=
                                          70 &&
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score <
                                          90
                                      "
                                      class="orange_color"
                                      >{{
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score
                                      }}%</span
                                    >
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score >=
                                        90
                                      "
                                      class="green_color"
                                      >{{
                                        livestock[index]
                                          .livestock_ocr_id_confidence_score
                                      }}%</span
                                    ></v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Face ID
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1"
                                    >{{ livestock[index].livestock_face_id }} -
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_face_id_confidence_score <
                                        70
                                      "
                                      class="red_color"
                                      >{{
                                        livestock[index]
                                          .livestock_face_id_confidence_score
                                      }}%</span
                                    >
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_face_id_confidence_score >=
                                          70 &&
                                        livestock[index]
                                          .livestock_face_id_confidence_score <
                                          90
                                      "
                                      class="orange_color"
                                      >{{
                                        livestock[index]
                                          .livestock_face_id_confidence_score
                                      }}%</span
                                    >
                                    <span
                                      v-if="
                                        livestock[index]
                                          .livestock_face_id_confidence_score >=
                                        90
                                      "
                                      class="green_color"
                                      >{{
                                        livestock[index]
                                          .livestock_face_id_confidence_score
                                      }}%</span
                                    ></v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item></v-card-text
                            ></v-card
                          >
                        </v-col>
                        <v-col xl="6" lg="6" md="6" sm="6">
                          <v-card
                            ><v-card-text
                              ><v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Customer
                                  </v-list-item-subtitle>
                                  <v-list-item-title
                                    v-if="livestock[index].customer"
                                    class="pb-1"
                                    >{{
                                      livestock[index].customer.customer_name
                                    }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Site
                                  </v-list-item-subtitle>
                                  <v-list-item-title
                                    class="pb-1"
                                    v-if="livestock[index].site"
                                    >{{
                                      livestock[index].site.site_name
                                    }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Gender
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1">{{
                                    livestock[index].livestock_gender
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Date of Birth
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1">{{
                                    livestock[index].livestock_dob
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item></v-card-text
                            ></v-card
                          >
                        </v-col>
                        <v-col xl="6" lg="6" md="6" sm="6">
                          <v-card
                            ><v-card-text
                              ><v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Breed
                                  </v-list-item-subtitle>
                                  <v-list-item-title class="pb-1">{{
                                    livestock[index].livestock_breed.name
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Branding
                                  </v-list-item-subtitle>
                                  <v-list-item-title
                                    class="pb-1"
                                    v-if="
                                      livestock[index].livestock_breed
                                        .branding_dates
                                    "
                                    >{{
                                      livestock[index].livestock_breed
                                        .branding_dates[0].start
                                    }}
                                    &nbsp;&nbsp;
                                    <span class="unit_label"
                                      >{{
                                        age_calc(
                                          livestock[index].livestock_breed
                                            .branding_dates[0].start
                                        )
                                      }}
                                      months</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Breeding
                                  </v-list-item-subtitle>
                                  <v-list-item-title
                                    class="pb-1"
                                    v-if="
                                      livestock[index].livestock_breed
                                        .breeding_dates
                                    "
                                  >
                                    {{
                                      livestock[index].livestock_breed
                                        .breeding_dates[0].start
                                    }}
                                    &nbsp;&nbsp;
                                    <span class="unit_label"
                                      >{{
                                        age_calc(
                                          livestock[index].livestock_breed
                                            .breeding_dates[0].start
                                        )
                                      }}
                                      months</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Weaning
                                  </v-list-item-subtitle>
                                  <v-list-item-title
                                    class="pb-1"
                                    v-if="
                                      livestock[index].livestock_breed
                                        .weaning_dates
                                    "
                                  >
                                    {{
                                      livestock[index].livestock_breed
                                        .weaning_dates[0].start
                                    }}
                                    &nbsp;&nbsp;
                                    <span class="unit_label"
                                      >{{
                                        age_calc(
                                          livestock[index].livestock_breed
                                            .weaning_dates[0].start
                                        )
                                      }}
                                      months</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item></v-card-text
                            ></v-card
                          >
                        </v-col>
                        <v-col xl="6" lg="6" md="6" sm="6">
                          <v-card
                            ><v-card-text
                              ><v-list-item>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    Weight
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <div>
                                <animalWeightByDay
                                  id="graph_div"
                                  v-if="livestock[index]"
                                  :weight="livestock[index].livestock_weight"
                                />
                              </div> </v-card-text
                          ></v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="selected_tab == 2">
                    <v-col>
                      <v-card>
                        <v-card-text>
                          <v-data-table
                            loading-text="Loading... Please wait"
                            :headers="headers"
                            :items="appearances"
                            :items-per-page="100"
                            class="elevation-1"
                            dense
                            hide-default-footer
                          >
                            <template
                              v-slot:top="{
                                pagination,
                                options,
                                updateOptions,
                              }"
                            >
                              <v-data-footer
                                :pagination="pagination"
                                :options="options"
                                @update:options="updateOptions"
                                :items-per-page-options="[50, 100, 200]"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                              />
                            </template>
                            <template v-slot:[`item.thumbnail`]="{ item }">
                              <v-list-item class="table_list_item" @click="redirect_to_video(item.thumbnail, item.video_duration, item.camera_name, item.date, item.start)"
                                ><v-img
                                  height="54"
                                  width="90"
                                  :src="`${
                                    cookie_bucket +
                                    item.thumbnail +
                                    '_480x270.jpg'
                                  }`"
                              /></v-list-item>
                            </template>
                          </v-data-table>
                        </v-card-text> </v-card
                    ></v-col>
                  </v-row>
                  <v-row v-if="selected_tab != 0 && selected_tab != 2">
                    <v-col>
                      <v-card height="585">
                        <v-card-text>
                          <h2 id="uc_label">Under Construction</h2>
                        </v-card-text>
                      </v-card></v-col
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-container></v-col
      >
      <v-col xl="1" lg="1" class="nav_btn" align-self="center"
        ><v-btn
          class="ml-n10"
          :to="
            '/animal/' +
            livestock[index + 1].id +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page
          "
          icon
          v-if="index < livestock.length - 1"
        >
          <v-icon class="nav_icon">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          v-show="false"
          :to="
            '/animal/' +
            selected_tag +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page
          "
          icon
        >
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import animalWeightByDay from "@/components/animal_weight_by_day.vue";
import moment from "moment";
import Vue from "vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    animalWeightByDay,
  },
  props: ["id", "sort_by", "sort_desc", "prev_page"],
  data() {
    return {
      downloadURL: "",
      selected_tag: "",
      selected_tab: "",
      vid_url: "",
      vid_start: "",
      vid_end: "",
      headers: [
        {
          text: "Thumbnail",
          value: "thumbnail",
          width: "10%",
          sortable: false,
        },
        { text: "Camera Name", value: "camera_name" },
        { text: "Date", value: "date" },
        { text: "Start", value: "start" },
        { text: "End", value: "end" },
      ],
    };
  },
  computed: {
    appearances() {
      let temp_appearances = [];
      for (const i in this.livestock[this.index].appearances.items) {
        for (const n in this.livestock[this.index].appearances.items[i]
          .appearance_start) {
          temp_appearances.push({
            thumbnail:
              this.livestock[this.index].appearances.items[i].video
                .video_thumbnail,
            camera_name:
              this.livestock[this.index].appearances.items[i].video.camera
                .camera_name,
            date: this.livestock[this.index].appearances.items[
              i
            ].video_id.split(" ")[0],
            video_duration:
              this.livestock[this.index].appearances.items[i].video
                .video_duration,
            start:
              this.livestock[this.index].appearances.items[i].appearance_start[
                n
              ],
            end: this.livestock[this.index].appearances.items[i].appearance_end[
              n
            ],
          });
        }
      }
      return temp_appearances;
    },
    livestock_tags() {
      var livestock = [
        ...new Set(
          this.$store.getters.getAnimals.map((item) => item.livestock_drop_tag)
        ),
      ];

      return livestock;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    livestock() {
      if (this.sort_desc == "true" && this.sort_by == "customer") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getAnimals],
          "customer.customer_name"
        );
      } else if (this.sort_desc == "false" && this.sort_by == "customer") {
        return this.sortByNested(
          [...this.$store.getters.getAnimals],
          "customer.customer_name"
        );
      } else if (this.sort_desc == "true" && this.sort_by == "site") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getAnimals],
          "site.site_name"
        );
      } else if (this.sort_desc == "false" && this.sort_by == "site") {
        return this.sortByNested(
          [...this.$store.getters.getAnimals],
          "site.site_name"
        );
      } else if (this.sort_desc == "true") {
        return this.sortByKeyDESC(
          [...this.$store.getters.getAnimals],
          this.sort_by
        );
      } else if (this.sort_desc == "false") {
        return this.sortByKey(
          [...this.$store.getters.getAnimals],
          this.sort_by
        );
      } else {
        return this.$store.getters.getAnimals;
      }
    },

    index() {
      const url_equal = (element) => element.id == this.id;
      return this.livestock.findIndex(url_equal);
    },
    id_index() {
      const url_equal = (element) =>
        element.livestock_drop_tag == this.selected_tag;
      return this.livestock.findIndex(url_equal);
    },
  },
  methods: {
    redirect_to_video(url, duration, camera_name, date_time, start) {
      Vue.prototype.$video_start = start;
      this.$router.push({
        name: "video",
        params: {
          url: url,
          duration: duration,
          sort_by: "video_datetime",
          sort_desc: "true",
          prev_page:
            "animal" +
            "/" +
            this.id +
            "&" +
            this.sort_by +
            "&" +
            this.sort_desc +
            "&" +
            this.prev_page.replace('+','*'),
          cam_name: camera_name,
          date: date_time,
        },
      });
    },
    age_calc(date) {
      return moment().diff(moment(date, "YYYY-MM-DD"), "months");
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByNestedDESC(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByKeyDESC(array, key) {
      return array.sort(function (a, b) {
        var x = b[key];
        var y = a[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },

  watch: {
    index() {
      this.selected_tag = this.livestock[this.index].livestock_drop_tag;
    },
    selected_tag() {
      if (this.selected_tag != this.livestock[this.index].livestock_drop_tag) {
        this.$router.push({
          name: "animal",
          params: {
            id: this.livestock[this.id_index].id,
            sort_by: this.sort_by,
            sort_desc: this.sort_desc,
            prev_page: this.prev_page,
          },
        });
      }
    },
  },
  created() {
    if (typeof this.index == "number" && this.livestock.length != 0) {
      this.selected_tag = this.livestock[this.index].livestock_drop_tag;
    }
    if (this.$store.getters.getAnimals.length == 0) {
      this.$store.dispatch("DDB_GET_ANIMALS", {});
    }
  },
};
</script>
<style lang="css">
.nav_btn {
  text-align: center;
}
.nav_icon {
  font-size: 60px !important;
}
#graph_div {
  position: relative;
  height: 215px;
}
.select_tag {
  max-width: 75px !important;
}
.hidden_btn {
  visibility: hidden;
}
#uc_label {
  margin-top: 180px;
  text-align: center;
}

.unit_label {
  color: gray;
}
.select_tag {
  color: gray;
}
.select_tag > div > div > div > div > div {
  color: gray;
}
</style>