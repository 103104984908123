<template>
  <div>
    <navbar
      :hide_add_user="true"
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Login</span>
              <v-spacer></v-spacer>
               <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mb-n5 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add User</span>
              </v-tooltip>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <CustomerFilter v-model="selected_customer"></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              
              <v-data-table
                v-if="staging_production"
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="display_headers"
                :items="users"
                :items-per-page="100"
                class="elevation-1 entry_table"
                :search="search"
                hide-default-footer
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                single-expand
                show-expand
                :key="update_key"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Details</v-tab>
                    <!-- <v-tab>Groups</v-tab> -->
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{index+1}}</span>
                </template>
                <template v-slot:[`item.username`]="{ item }">
                  <div class="d-flex justify-space-between" style="width: 300px">
                    <span>{{item.username}}</span><copy-button :text="item.username"></copy-button>
                  </div>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <span>{{ item.updated_at.toString().slice(0, 15) }}</span>
                </template>
                <template v-slot:[`item.last_login_time`]="{ item }">
                  <span :class="item.last_login_color">{{ item.last_login_text }}</span>
                </template>
                <template v-slot:[`item.data-table-expand`]="{item}" >
                  <v-icon @click="editItem(item)" small>mdi-pencil</v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
                <template v-slot:[`item.isAdmin`]="{ item }">
                  <v-icon color="primary" v-if="item.isAdmin">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else>mdi-close-circle</v-icon>
                </template>
                <template v-slot:[`item.isClient`]="{ item }">
                  <v-icon color="primary" v-if="item.isClient">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else>mdi-close-circle</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
            <!-- <v-card-text v-if="selected_table == 1" class="text-center">
              <v-data-table
                v-if="staging_production"
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_details"
                :items="users"
                :items-per-page="100"
                class="elevation-1"
                :search="search"
                hide-default-footer
                single-expand
                show-expand
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Details</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{index+1}}</span>
                </template>
                <template v-slot:[`item.data-table-expand`]="{item}" >
                  <v-icon @click="editItem(item)" small>mdi-pencil</v-icon>
                </template>
                <template v-slot:[`item.username`]="{ item }">
                  <div class="d-flex justify-space-between" style="width: 300px">
                    <span>{{item.username}}</span><copy-button :text="item.username"></copy-button>
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <span>{{ item.given_name + " " + item.family_name }}</span>
                </template>
                <template v-slot:[`item.email_verified`]="{ item }">
                  <span>{{ item.email_verified }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </v-card-text> -->
          </v-card></v-col
        >
      </v-row></v-container
    >
    <UserCard></UserCard>
  </div>
</template>

<script>
// import Vue from "vue";
// import chartjspie from '@/components/chartjs-pie.vue';
// import chartjsdoughnut from '@/components/chartjs-doughnut.vue';
// import chartjsline from '@/components/chartjs-line.vue';
// import chartjsbar from '@/components/chartjs-bar.vue';
// var cognitoidentityserviceprovider;
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import CopyButton from "../components/CopyButton.vue";
import UserCard from "../components/UserCard.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import { eventBus } from "../main.js";
import { initializeCognitoIdentityProviderClient } from '../utilities/sessionUtils';
import moment from "moment";
import { AdminListUserAuthEventsCommand } from "@aws-sdk/client-cognito-identity-provider";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CopyButton,
    UserCard,
    CustomerFilter
},
  data() {
    return {
      headers: [
        { text: "#", value: "index", sortable: false},
        { text: "ID", value: "username",
          filter: (value)=>{
            if(this.selected_customer&&this.selected_customer!="All Customers"){
              return value == this.selected_customer;
            }else{
              return true;
            }
          }
        },
        { text: "Last Name", value: "family_name" },
        { text: "First Name", value: "given_name" },
        { text: "Email", value: "email" },
        { text: "Verified", value: "email_verified" },
        { text: "Last Login", value: "last_login_time"},
        { text: "Updated At", value: "updated_at" },
        { text: "", value: "data-table-expand" },
      ],
      headers_details: [
        { text: "#", value: "index", sortable: false},
        { text: "ID", value: "username",
          filter: (value)=>{
            if(this.selected_customer&&this.selected_customer!="All Customers"){
              return value == this.selected_customer;
            }else{
              return true;
            }
          }
        },
        { text: "Last Name", value: "family_name" },
        { text: "First Name", value: "given_name" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Address", value: "address" },
        { text: "Zone Info", value: "zoneinfo" },
        { text: "", value: "data-table-expand" },
      ],
      headers_groups: [
        { text: "#", value: "index", sortable: false},
        { text: "ID", value: "username",
          filter: (value)=>{
            if(this.selected_customer&&this.selected_customer!="All Customers"){
              return value == this.selected_customer;
            }else{
              return true;
            }
          }
        },
        { text: "Last Name", value: "family_name" },
        { text: "First Name", value: "given_name" },
        { text: "Admin", value: "isAdmin" },
        { text: "Client", value: "isClient" },
        { text: "", value: "data-table-expand" },
      ],
      update_key: 0,
      search: "",
      staging_data: [],
      production_data: [],
      staging_production: true,
      loading: true,
      editedItem: {},
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      sort_by: ["updated_at"],
      sort_desc: true,
      user_group: 'Client',
      selected_customer: '',

      last_login_promises: {},
      users: []
    };
  },
  methods: {
    editItem(item) {
      if (item == "create_new") {
        // this.editedItem = {};
        // this.dialog = true;
        // this.create_new = true;
        eventBus.$emit("open_dialog_users", {
          item: {},
          create_new: true
        })
      } else {
        eventBus.$emit("open_dialog_users", {
          item: Object.assign({}, item),
          create_new: false
        })
        // this.editedIndex = this.users.indexOf(item);
        // this.editedItem = Object.assign({}, item);
        // this.dialog = true;
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    fetchUsers(){
      var users = [];
      var unpr_users = this.$store.getters.getCognitoUsers;
      for (const i in unpr_users) {
        var user = {};
        user.updated_at = unpr_users[i].UserLastModifiedDate;
        user.username = unpr_users[i].Username;
        for (const n in unpr_users[i].Attributes) {
          user[unpr_users[i].Attributes[n].Name] =
            unpr_users[i].Attributes[n].Value;
        }

        user.isAdmin = unpr_users[i].Groups?.some(e=>e.GroupName=='Admin');
        user.isClient = unpr_users[i].Groups?.some(e=>e.GroupName=='Client');
        users.push(user);
      }
      this.users = users;
      this.updateLastLoginTime();
    },
    updateLastLoginTime(){
      initializeCognitoIdentityProviderClient().then(client=>{
        this.users.forEach(user=>{
          var params = {
            UserPoolId: process.env.VUE_APP_CLIENT_USER_POOL_ID,
            Username: user.sub
          };
          if(!this.last_login_promises[user.sub]){
            var command = new AdminListUserAuthEventsCommand(params);
            this.last_login_promises[user.sub] = client.send(command).then(data=>{
              // delete this.last_login_promises[user.sub];
              var current_index = this.users.findIndex(e=>e.sub == user.sub);
              if(current_index == -1){
                return;
              }
              var current_user = this.users[current_index];
              if(data.AuthEvents.length>0){
                var date = data.AuthEvents[0].CreationDate;
                var age = this.age(date);
                current_user.last_login_time = date;
                current_user.last_login_text = age.result + " " +age.unit +" "+ (age.unit?"ago":"");
                current_user.last_login_color = age.color;
              }else{
                current_user.last_login_time = null;
                current_user.last_login_text = "unavailable";
                current_user.last_login_color = "gray_color";
              }
              this.users.splice(current_index, 1, current_user);
            });
          }
        });
      })
    },
    age(date){
      var unit = 'seconds'
      var result = moment().diff(date, unit);
      var color = "green_color";
      if(result>60){
        unit = 'minutes'
        result = moment().diff(date, unit);
        if(result>60){
          unit = 'hours'
          result = moment().diff(date, unit);
          if(result>24){
            unit = 'days'
            result = moment().diff(date, unit);
            color = "orange_color";
            if(result>7){
              unit = 'weeks'
              result = moment().diff(date, unit);
              if(result>4){
                unit = 'months'
                result = moment().diff(date, unit);
                if(result>12){
                  unit = 'years'
                  result = moment().diff(date, unit);
                  color = "red_color";
                }
              }
            }
          }
        }
      }

      return {result: result, unit: unit, color: color};
    },
  },
  computed: {
    display_headers() {
      if(this.selected_table == 0){
        return this.headers;
      }else if(this.selected_table == 1){
        return this.headers_details;
      }else if(this.selected_table == 2){
        return this.headers_groups;
      }else{
        return this.headers
      }
    },
  },
  created() {
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS").then(()=>{
        this.fetchUsers();
        this.loading = false;
      })
    } else {
      this.fetchUsers();
      this.loading = false;
    }

    eventBus.$on("get_cognito_users", (() => {
      var _this = this
      this.$store.dispatch("DDB_GET_COGNITO_USERS").then(()=>{
        _this.fetchUsers();
        _this.loading = false;
      })
    }))

  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
</style>