var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xl":"11","lg":"12","md":"12","sm":"12","cols":"12"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("Map")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","x-small":"","disabled":!_vm.default_coords,"color":_vm.default_coords ? 'primary' : '#e0e0e0',"elevation":"0"},on:{"click":_vm.update_default_site_location}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-marker-star")])],1)]}}])},[_c('span',[_vm._v("Update Default Site Location")])]),_c('customerFilter',{attrs:{"dense":true,"noall":true},model:{value:(_vm.customer_select),callback:function ($$v) {_vm.customer_select=$$v},expression:"customer_select"}})],1),_c('v-card-text',{staticClass:"text-center pb-2"},[_c('v-card',[_c('v-card-text',[(_vm.cameras)?_c('v-row',{staticClass:"pl-3"},[(_vm.site_map_info && (_vm.cameras.length || (_vm.wifis && _vm.wifis.length) || (_vm.jetsons && _vm.jetsons.length)))?_c('v-list',{staticClass:"map_side_bar pa-0",attrs:{"elevation":"2","max-width":"244","max-height":"600"}},[(_vm.cameras.length)?_c('v-list-group',{attrs:{"value":"true"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cctv")])],1),_c('v-list-item-title',{staticClass:"group_title"},[_vm._v("Cameras")])]},proxy:true}],null,false,2150819671)},_vm._l((_vm.cameras),function(camera,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[(
                              camera.camera_location &&
                              !(
                                camera.camera_location.latitude == null ||
                                camera.camera_location.latitude == '' ||
                                camera.camera_location.latitude == 'null'
                              )
                            )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.get_color(camera.camera_map_icon_color)},on:{"click":function($event){return _vm.select_color(camera, 'camera')}}},'v-icon',attrs,false),on),[_vm._v("mdi-cctv")])]}}],null,true)},[_c('v-card',[_c('v-color-picker',{staticClass:"map_icon_color_picker",attrs:{"show-swatches":"","swatches":_vm.swatches},model:{value:(_vm.selected_color),callback:function ($$v) {_vm.selected_color=$$v},expression:"selected_color"}}),_c('v-btn',{staticClass:"mb-3 ml-3 mt-n2",attrs:{"depressed":""},on:{"click":function($event){return _vm.remove_map_marker(
                                    camera.id,
                                    'camera'
                                  )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-map-marker-remove")])],1)],1)],1):_vm._e(),(
                              !camera.camera_location ||
                              camera.camera_location.latitude == null ||
                              camera.camera_location.latitude == '' ||
                              camera.camera_location.latitude == 'null'
                            )?_c('v-icon',{staticClass:"non_placed_hardware",attrs:{"color":"gray"}},[_vm._v("mdi-cctv")]):_vm._e()],1),_c('v-list-item-content',{class:'map_item_content_' +
                            String(
                              !camera.camera_location ||
                                camera.camera_location.latitude == null ||
                                camera.camera_location.latitude == '' ||
                                camera.camera_location.latitude == 'null'
                            ),staticStyle:{"text-align":"left"},attrs:{"draggable":!camera.camera_location ||
                            camera.camera_location.latitude == null ||
                            camera.camera_location.latitude == '' ||
                            camera.camera_location.latitude == 'null'},on:{"dragend":function($event){return _vm.select_hardware(camera, 'camera')}}},[(
                              camera.camera_location &&
                              !(
                                camera.camera_location.latitude == null ||
                                camera.camera_location.latitude == '' ||
                                camera.camera_location.latitude == 'null'
                              )
                            )?_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s(camera.camera_name)},on:{"mouseenter":function($event){return _vm.set_marker(camera.camera_name)},"mouseleave":function($event){return _vm.set_marker(false)}}}):_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s(camera.camera_name)}})],1)],1)}),1):_vm._e(),(_vm.wifis && _vm.wifis.length)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-wifi")])],1),_c('v-list-item-title',{staticClass:"group_title"},[_vm._v("Wifi")])]},proxy:true}],null,false,3519143679)},_vm._l((_vm.wifis),function(wifi,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[(
                              wifi.wifi_location &&
                              !(
                                wifi.wifi_location.latitude == null ||
                                wifi.wifi_location.latitude == '' ||
                                wifi.wifi_location.latitude == 'null'
                              )
                            )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.get_color(wifi.wifi_map_icon_color)},on:{"click":function($event){return _vm.select_color(wifi, 'wifi')}}},'v-icon',attrs,false),on),[_vm._v("mdi-wifi")])]}}],null,true)},[_c('v-card',[_c('v-color-picker',{staticClass:"map_icon_color_picker",attrs:{"show-swatches":"","swatches":_vm.swatches},model:{value:(_vm.selected_color),callback:function ($$v) {_vm.selected_color=$$v},expression:"selected_color"}}),_c('v-btn',{staticClass:"mb-3 ml-3 mt-n2",attrs:{"depressed":""},on:{"click":function($event){return _vm.remove_map_marker(
                                    wifi.id,
                                    'wifi'
                                  )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-map-marker-remove")])],1)],1)],1):_vm._e(),(
                              !(
                                wifi.wifi_location &&
                                !(
                                  wifi.wifi_location.latitude == null ||
                                  wifi.wifi_location.latitude == '' ||
                                  wifi.wifi_location.latitude == 'null'
                                )
                              )
                            )?_c('v-icon',{staticClass:"non_placed_hardware",attrs:{"color":"gray"}},[_vm._v("mdi-wifi")]):_vm._e()],1),_c('v-list-item-content',{class:'map_item_content_' +
                            String(
                              !wifi.wifi_location ||
                                wifi.wifi_location.latitude == null ||
                                wifi.wifi_location.latitude == '' ||
                                wifi.wifi_location.latitude == 'null'
                            ),attrs:{"draggable":!wifi.wifi_location ||
                            wifi.wifi_location.latitude == null ||
                            wifi.wifi_location.latitude == '' ||
                            wifi.wifi_location.latitude == 'null'},on:{"dragend":function($event){return _vm.select_hardware(wifi, 'wifi')}}},[(
                              wifi.wifi_location &&
                              !(
                                wifi.wifi_location.latitude == null ||
                                wifi.wifi_location.latitude == '' ||
                                wifi.wifi_location.latitude == 'null'
                              )
                            )?_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s('wifi ' + (i + 1))},on:{"mouseenter":function($event){_vm.set_marker('wifi ' + (i + 1))},"mouseleave":function($event){return _vm.set_marker(false)}}}):_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s('wifi ' + (i + 1))}})],1)],1)}),1):_vm._e(),(_vm.jetsons && _vm.jetsons.length)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-router-wireless")])],1),_c('v-list-item-title',{staticClass:"group_title"},[_vm._v("Jetsons")])]},proxy:true}],null,false,3683150499)},_vm._l((_vm.jetsons),function(jetson,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[(
                              jetson.jetson_location &&
                              !(
                                jetson.jetson_location.latitude == null ||
                                jetson.jetson_location.latitude == '' ||
                                jetson.jetson_location.latitude == 'null'
                              )
                            )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.get_color(jetson.jetson_map_icon_color)},on:{"click":function($event){return _vm.select_color(jetson, 'jetson')}}},'v-icon',attrs,false),on),[_vm._v("mdi-router-wireless")])]}}],null,true)},[_c('v-card',[_c('v-color-picker',{staticClass:"map_icon_color_picker",attrs:{"show-swatches":"","swatches":_vm.swatches},model:{value:(_vm.selected_color),callback:function ($$v) {_vm.selected_color=$$v},expression:"selected_color"}}),_c('v-btn',{staticClass:"mb-3 ml-3 mt-n2",attrs:{"depressed":""},on:{"click":function($event){return _vm.remove_map_marker(
                                    jetson.id,
                                    'jetson'
                                  )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-map-marker-remove")])],1)],1)],1):_vm._e(),(
                              !(
                                jetson.jetson_location &&
                                !(
                                  jetson.jetson_location.latitude == null ||
                                  jetson.jetson_location.latitude == '' ||
                                  jetson.jetson_location.latitude == 'null'
                                )
                              )
                            )?_c('v-icon',{staticClass:"non_placed_hardware",attrs:{"color":"gray"}},[_vm._v("mdi-router-wireless")]):_vm._e()],1),_c('v-list-item-content',{class:'map_item_content_' +
                            String(
                              !jetson.jetson_location ||
                                jetson.jetson_location.latitude == null ||
                                jetson.jetson_location.latitude == '' ||
                                jetson.jetson_location.latitude == 'null'
                            ),attrs:{"draggable":!jetson.jetson_location ||
                            jetson.jetson_location.latitude == null ||
                            jetson.jetson_location.latitude == '' ||
                            jetson.jetson_location.latitude == 'null'},on:{"dragend":function($event){return _vm.select_hardware(jetson, 'jetson')}}},[(
                              jetson.jetson_location &&
                              !(
                                jetson.jetson_location.latitude == null ||
                                jetson.jetson_location.latitude == '' ||
                                jetson.jetson_location.latitude == 'null'
                              )
                            )?_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s('jetson ' + (i + 1))},on:{"mouseenter":function($event){_vm.set_marker('jetson ' + (i + 1))},"mouseleave":function($event){return _vm.set_marker(false)}}}):_c('v-list-item-title',{staticClass:"map_sidebar_item_name",domProps:{"textContent":_vm._s('jetson ' + (i + 1))}})],1)],1)}),1):_vm._e()],1):_vm._e(),(_vm.site_map_info)?_c('v-col',{staticClass:"pt-0"},[_c('div',{staticClass:"pa-1 elevation-1",staticStyle:{"position":"absolute","z-index":"4","bottom":"52px","right":"72px","background-color":"white","border-radius":"2px"}},[_vm._v("Zoom: "+_vm._s(_vm.zoom))]),_c('div',{staticStyle:{"width":"200px","position":"absolute","z-index":"4","top":"25px","right":"72px","background-color":"white","border-radius":"25px"}},[(_vm.site_selection)?_c('v-select',{staticClass:"assign_select font_400",attrs:{"hide-details":"","dense":"","outlined":"","rounded":"","height":"32","full-width":"","items":_vm.site_selection,"label":"Site"},model:{value:(_vm.selected_site),callback:function ($$v) {_vm.selected_site=$$v},expression:"selected_site"}}):_vm._e()],1),_c('GmapMap',{ref:"map",staticClass:"settings_map",staticStyle:{"height":"600px"},attrs:{"center":{
                        lat: parseFloat(_vm.site_map_info.location.latitude) || 0,
                        lng: parseFloat(_vm.site_map_info.location.longitude) || 0,
                      },"zoom":_vm.site_map_info.zoom,"map-type-id":"hybrid","options":{
                        mapTypeControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                      }},on:{"mouseover":function($event){return _vm.mark($event)},"mouseout":function($event){return _vm.mark('unmark')},"zoom_changed":_vm.get_zoom,"center_changed":_vm.get_center}},_vm._l((_vm.hardware_markers),function(m,index){return _c('GmapMarker',{key:index + m[0].position,attrs:{"position":m[0].position,"icon":m[0].icon,"draggable":_vm.map_distances.length == 0},on:{"dragend":function($event){return _vm.update_camera_location(
                            m[1],
                            $event.latLng,
                            m[2]
                          )},"click":function($event){return _vm.get_distances(m[0], m[2])},"mouseover":function($event){return _vm.set_marker(m[0].title)},"mouseout":function($event){return _vm.set_marker(false)}}},[_c('GmapInfoWindow',{attrs:{"opened":_vm.open_marker == m[0].title &&
                            _vm.map_distances.length == 0}},[_c('h3',[_vm._v(_vm._s(m[0].title))]),(m[2] == 'camera')?_c('span',[_vm._v(_vm._s(m[0].model))]):_vm._e()]),_c('GmapInfoWindow',{attrs:{"opened":m[2] == 'wifi' && _vm.map_distances.length > 0}},[_c('span',{staticClass:"distance_list_close"},[_c('h3',[_vm._v(_vm._s(m[0].title))]),_c('v-icon',{on:{"click":function($event){_vm.map_distances = []}}},[_vm._v("mdi-close")])],1),(_vm.map_distances)?_c('v-list',{attrs:{"width":"280","max-height":"300"}},_vm._l((_vm.map_distances),function(camera,i){return _c('v-list-item',{key:i,staticClass:"pa-1 pb-0 pt-0 distance_list_item"},[_c('v-list-item-icon',{staticClass:"mt-0 pt-1 mb-0 mr-4"},[_c('v-icon',{attrs:{"color":_vm.get_color(camera[0].color)}},[_vm._v("mdi-cctv")])],1),_c('v-list-item-content',{staticClass:"pt-0 pb-4 distance_list_content"},[_c('v-list-item-title',{staticClass:"map_sidebar_item_name"},[_c('span',{staticClass:"distance_list_title"},[_vm._v(_vm._s(camera[0].title))]),_c('span',{staticClass:"nav_arrows_f"},[_vm._v(" "+_vm._s(camera[1] + " m"))])])],1)],1)}),1):_vm._e()],1)],1)}),1)],1):_c('v-col',{staticClass:"pt-0"},[_c('div',{staticStyle:{"height":"600px","text-align":"center","line-height":"600px","font-size":"xx-large"}},[_vm._v("No Site Information")])])],1):_c('div',[_c('v-autocomplete',{staticClass:"select_customer mr-3",attrs:{"items":_vm.customers,"dense":"","flat":"","hide-details":"","menu-props":{ offsetOverflow: false }},model:{value:(_vm.customer_select),callback:function ($$v) {_vm.customer_select=$$v},expression:"customer_select"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }