<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Reports</span>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-card-title class="pa-0 ma-0">
                  <!-- <v-tabs class="table_tabs" v-model="selected_table" show-arrows="">
                    <v-tab>RFID</v-tab>
                    <v-tab>Customers Without Sites</v-tab>
                    <v-tab>Customers Without Cameras</v-tab>
                    <v-tab>Customers Without Wifi</v-tab>
                    <v-tab>Users without Customers</v-tab>
                    <v-tab>Customers without Zones</v-tab>
                    <v-tab>Customers with Unloacted Devices</v-tab>
                  </v-tabs> -->
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-btn-toggle
                    mandatory
                    >
                    <v-btn @click="selected_table = 0" text :class="{'selected': selected_table==0}">RFID ({{RFIDData_with_customer.length}}) </v-btn>
                    <v-menu offset-y tile class="pa-0 ma-0" :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        text
                        v-bind="attrs"
                        v-on="on"
                        :class="{'selected': selected_table==1||selected_table==2||selected_table==3||selected_table==4||selected_table==5}"
                        >
                        Customers 
                        ({{customers_without_sites.length+customers_without_cameras.length
                        +customers_without_wifi.length
                        +customers_without_zones.length
                        +customers_with_unlocated_device.length}})
                        
                        </v-btn>
                      </template>
                      <v-list class="pa-0 ma-0">
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 1" dense plain text :class="{'selected': selected_table==1}">Without Sites ({{customers_without_sites.length}})</v-btn>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 2" dense plain text :class="{'selected': selected_table==2}">Without Cameras ({{customers_without_cameras.length}})</v-btn>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 3" dense plain text :class="{'selected': selected_table==3}">Without Wi-Fi ({{customers_without_wifi.length}})</v-btn>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 4" dense plain text :class="{'selected': selected_table==4}">Without Zones ({{customers_without_zones.length}})</v-btn>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 5" dense plain text :class="{'selected': selected_table==5}">With Unset Device ({{customers_with_unlocated_device.length}})</v-btn>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 8" dense plain text :class="{'selected': selected_table==8}">With Google/Ubiqitui ({{customers_with_google_ubiqitui.length}})</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu tile offset-y class="pa-0 ma-0" :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        text
                        v-bind="attrs"
                        v-on="on"
                        :class="{'selected': selected_table==6}"
                        >
                        Users ({{users_without_customers.length}})
                        </v-btn>
                      </template>
                      <v-list class="pa-0 ma-0">
                        <v-list-item class="pa-0 ma-0">
                          <v-btn @click="selected_table = 6" dense plain text :class="{'selected': selected_table==6}">Without Customers ({{users_without_customers.length}})</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn @click="selected_table = 7" text :class="{'selected': selected_table==7}">Sharing ({{customers_with_collaboration.length}})</v-btn>
                    </v-btn-toggle>
                  </v-row>
                  <v-row>
                    <!-- <v-col cols="12" sm="2">
                    <v-list>
                      <v-list-item-group v-model="selected_table" mandatory>
                        <v-list-item :class="{'selected': selected_table==0}">
                          <v-list-item-title>
                            <span>RFID ({{RFIDData_with_customer.length}})</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-group
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-item-title>Customers ({{customers_without_sites.length+customers_without_cameras.length+customers_without_wifi.length+customers_without_zones.length+customers_with_unlocated_device.length}})</v-list-item-title>
                          </template>
                          <v-list-item :class="{'selected': selected_table==4}" class="pl-10">
                            <v-list-item-title>Without Sites ({{customers_without_sites.length}})</v-list-item-title>
                          </v-list-item>
                          <v-list-item :class="{'selected': selected_table==5}" class="pl-10">
                            <v-list-item-title>Without Cameras ({{customers_without_cameras.length}})</v-list-item-title>
                          </v-list-item>
                          <v-list-item :class="{'selected': selected_table==6}" class="pl-10">
                            <v-list-item-title>Without Wi-Fi ({{customers_without_wifi.length}})</v-list-item-title>
                          </v-list-item>
                          <v-list-item :class="{'selected': selected_table==7}" class="pl-10">
                            <v-list-item-title>Without Zones ({{customers_without_zones.length}})</v-list-item-title>
                          </v-list-item>
                          <v-list-item :class="{'selected': selected_table==8}" class="pl-10">
                            <v-list-item-title>With Unset Device ({{customers_with_unlocated_device.length}})</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                        <v-list-group
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-item-title>Users ({{users_without_customers.length}})</v-list-item-title>
                          </template>
                          <v-list-item :class="{'selected': selected_table==9}" class="pl-10">
                            <v-list-item-title>Without Customers ({{users_without_customers.length}})</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                        <v-list-item :class="{'selected': selected_table==3}">
                          <v-list-item-title>
                            <span>Sharing ({{customers_with_collaboration.length}})</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    </v-col> -->
                    <v-col cols="12" sm="12">
                      <!-- RFID -->
                  <v-data-table
                  :headers="header_RFID"
                  :items="RFIDData_with_customer"
                  v-if="selected_table == 0"
                  >
                  <template v-slot:[`item.customer_first_name`]="{ item }">
                        <span>{{item.customer_first_name?item.customer_first_name:"-"}}</span>
                  </template>
                  <template v-slot:[`item.customer_last_name`]="{ item }">
                        <span>{{item.customer_last_name?item.customer_last_name:"-"}}</span>
                  </template>
                  <template v-slot:[`item.CognitoID`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.CognitoID?item.CognitoID:"-"}}</span><copy-button :text="item.CognitoID"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.count`]="{item}">
                    <span>{{item.count?item.count:0}}</span>
                  </template>
                  <template v-slot:[`item.count7d`]="{item}">
                    <span>{{item.count7d?item.count7d:0}}</span>
                  </template>
                  <template v-slot:[`item.latest`]="{item}">
                    <span>{{item.latest?item.latest.split(" ")[0]:0}}</span>
                  </template>
                  <template v-slot:[`item.latest_ago`]="{item}">
                    <span :class="age(item.latest).color">{{(age(item.latest).result+" "+age(item.latest).unit)}}</span>
                  </template>
                  </v-data-table>

                  <!-- customers without sites -->
                  <v-data-table
                    :headers="header_site"
                    :items="customers_without_sites"
                    v-if="selected_table==1"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.site_count`]>
                    <div class="red_color ml-2">0</div>
                  </template>
                  </v-data-table>

                  <!-- customers without cameras -->
                  <v-data-table
                    :headers="header_camera"
                    :items="customers_without_cameras"
                    v-if="selected_table==2"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.cam_count`]>
                    <div class="red_color ml-5">0</div>
                  </template>
                  </v-data-table>

                  <!-- customers without wifi -->
                  <v-data-table
                    :headers="header_wifi"
                    :items="customers_without_wifi"
                    v-if="selected_table==3"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.wifi_count`]>
                    <div class="red_color ml-2">0</div>
                  </template>
                  </v-data-table>

                  <!-- customers without zones -->
                  <v-data-table
                    :headers="header_zones"
                    :items="customers_without_zones"
                    v-if="selected_table==4"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.zone_count`]>
                    <div class="red_color ml-3">0</div>
                  </template>
                  </v-data-table>
                  <!-- customers with unlocated devices -->
                  <v-data-table
                    :headers="header_unset_device"
                    :items="customers_with_unlocated_device"
                    v-if="selected_table==5"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.cam_unset_count`]="{ item }">
                    <div class="ml-3"><span class="red_color">{{item.cam_unset_count}}</span>/{{item.cameras.items.length}}</div>
                  </template>
                  <template v-slot:[`item.wifi_unset_count`]="{ item }">
                    <div class="ml-3"><span class="red_color">{{item.wifi_unset_count}}</span>/{{item.wifi.items.length}}</div>
                  </template>
                  </v-data-table>

                  <!-- customers with google and ubiqitui -->
                  <v-data-table
                    :headers="header_google_ubiqitui"
                    :items="customers_with_google_ubiqitui"
                    v-if="selected_table==8"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  </v-data-table>

                  <!-- users without customers -->
                  <v-data-table
                    :headers="header_user"
                    :items="users_without_customers"
                    v-if="selected_table==6"
                  >
                  <template v-slot:[`item.username`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.username}}</span><copy-button :text="item.username"></copy-button>
                    </div>
                  </template>
                  <template v-slot:[`item.customer_count`]>
                    <div class="red_color ml-5">0</div>
                  </template>
                  </v-data-table>
                    
                  <!-- sharing -->
                  <v-data-table
                    :headers="header_collaboration"
                    :items="customers_with_collaboration"
                    v-if="selected_table==7"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                    <div class="d-flex justify-space-between" style="width: 300px">
                        <span>{{item.id}}</span><copy-button :text="item.id"></copy-button>
                    </div>
                  </template>
                  </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="js">
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import CopyButton from "../components/CopyButton.vue";
import RFIDMixin from "../mixins/RFID_TM_Mixin";
import Vue from 'vue';
import moment from "moment";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CopyButton
  },
  mixins: [RFIDMixin],
  props: [],
  data() {
    return {
      panels: [0],
      selected_table: 0,
      selected_table_prev: 0
    };
  },
  methods: {
    age(raw_date){
          var date = new Date(raw_date);
          var unit = 'seconds'
          var result = moment().diff(date, unit);
          var color = "green_color";
          if(result <0){
            unit = "";
            result = "Just Now"
          }

          if(result>60){
            unit = 'minutes';
            result = moment().diff(date, unit);
            if(result>60){
              unit = 'hours';
              result = moment().diff(date, unit);
              if(result>24){
                unit = 'days';
                result = moment().diff(date, unit);
                color = "orange_color";
                if(result>7){
                  unit = 'weeks';
                  result = moment().diff(date, unit);
                  if(result>4){
                    unit = 'months';
                    result = moment().diff(date, unit);
                    if(result>12){
                      unit = 'years';
                      result = moment().diff(date, unit);
                      color = "red_color";
                    }
                  }
                }
              }
            }
          }

          return {result: result, unit: unit, color: color};
        },
    parseRFIDData(data){
      var arr = data.Rows.map(e=>{
        var result = {};
        data.ColumnInfo.forEach((item, index)=>{
          result[item.Name] = e.Data[index].ScalarValue;
        });
        return result;
      });
      for(var i = 0; i<arr.length; i++){
        var f = false;
        for(var j = 0; j<this.RFIDData.length; j++){
          if(this.RFIDData[j].CognitoID == arr[i].CognitoID){
            Vue.set(this.RFIDData, j, Object.assign(this.RFIDData[j], arr[i]));
            // this.RFIDData[j] = Object.assign(this.RFIDData[j], arr[i]);
            f = true;
          }
        }
        if(!f){
          this.RFIDData.push(arr[i]);
        }
        f = false;
      }
    }
  },
  computed: {
    header_customer_gen(){
      return [
        {text: "ID", value: "id", width: "360px"},
        {text: "First Name",
          value: "customer_first_name", width: "200px"},
        {text: "Last Name",
          value: "customer_last_name", width: "200px"},
        {text: "Company Name",
          value: "customer_company_name", width: "200px"},
      ]
    },
    header_site(){
      return [
        ... this.header_customer_gen,
        {text: "Sites",
          value: "site_count",
        },
        {text: "", value: ""}
      ]
    },
    header_camera(){
      return [
      ... this.header_customer_gen,
        {text: "Cameras",
          value: "cam_count",
        },
        {text: "", value: ""}
      ]
    },
    header_wifi(){
      return [
      ... this.header_customer_gen,
        {text: "Wi-Fi",
          value: "wifi_count",
        },
        {text: "", value: ""}
      ]
    },
    header_user(){
      return [
        {text: "ID", value: "username", width: "360px"},
        {text: "First Name",
          value: "family_name", width: "200px"},
        {text: "Last Name",
          value: "given_name", width: "200px"},
        {text: "Company Name",
          value: "customer_company_name", width: "200px"},
        // {text: "Group", value: "groupname"},
        {text: "Customers",
          value: "customer_count",
        },
        {text: "", value: ""}
      ]
    },
    header_zones(){
      return [
      ... this.header_customer_gen,
      {text: "Zones", value: "zone_count"},
      {text: "", value: ""}
      ]
    },
    header_RFID(){
      return [
        {text: "ID", value: "CognitoID", width: "360px"},
        {text: "First Name",
          value: "customer_first_name", width: "150px"},
        {text: "Last Name",
          value: "customer_last_name", width: "150px"},
        {text: "Company Name",
          value: "customer_company_name", width: "200px"},
        {text: "Total Uploads", value: "count"},
        {text: "Recent Tag Uploads (7 Days)", value: "count7d"},
        {text: "Last Updated At", value: "latest"},
        {text: "Last Updated Time", value: "latest_ago"}
      ];
    },
    header_unset_device(){
      return [
        ... this.header_customer_gen,
        {text: "Cameras", value: "cam_unset_count"},
        {text: "Wi-Fi",  value: "wifi_unset_count"},
        {text: "", value: ""}
      ]
    },
    header_google_ubiqitui(){
      return [
        ... this.header_customer_gen,
        {text: "Google", value: "google_count"},
        {text: "Ubiqitui",  value: "ubiqitui_count"},
        {text: "", value: ""}
      ]
    },
    header_collaboration(){
      return [
        ... this.header_customer_gen,
        {text: "Collaborators", value: "collab_count"},
        {text: "Main Users", value: "is_collab_count"},
      ]
    },
    customers(){
      return this.$store.getters.getCustomerObjects;
    },
    RFIDData_with_customer(){
      return this.RFIDData.map(e=>{
          var customer = this.customers.find(i=>e.CognitoID==i.id);
          if(customer){
            return {
              ...e,
              customer_first_name: customer.customer_first_name,
              customer_last_name: customer.customer_last_name,
              customer_company_name: customer.customer_company_name,
            }
          }else{
            return e;
          }
      });
    },
    customers_without_sites(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>e.sites.items.length <= 0);
      return c;
    },
    customers_without_cameras(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>e.cameras.items.length <= 0);
      return c;
    },
    customers_without_wifi(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>e.wifi.items.length <= 0);
      return c;
    },
    customers_without_zones(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>e.zones.items.length <= 0);
      return c;
    },
    customers_with_unlocated_device(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>{
        var flag = false;
        e.cam_unset_count = e.cameras.items.length;
        e.wifi_unset_count = e.wifi.items.length;
        for(let i = 0; i<e.cameras.items.length; i++){
          var cam = e.cameras.items[i];
          if(!(cam.camera_location&&cam.camera_location.latitude&&cam.camera_location.longitude)){
            e.cam_unset_count--;
            flag = true;
          }
        }
        for(let i = 0; i<e.wifi.items.length; i++){
          var wifi = e.wifi.items[i];
          if(!(wifi.wifi_location&&wifi.wifi_location.latitude&&wifi.wifi_location.longitude)){
            e.wifi_unset_count--;
            flag=true;
          }
        }

        return flag;

      });
      return c;
    },
    customers_with_collaboration(){
      var c = this.$store.getters.getCustomerObjects.filter(e=>(e.customer_collaborators?.length > 0)||(e.customer_is_collaborator?.length >0));
      c.forEach(e => {
        e.collab_count = e.customer_collaborators?.length;
        e.is_collab_count = e.customer_is_collaborator?.length;
      });
      return c;
    },
    customers_with_google_ubiqitui(){
      return this.$store.getters.getCustomerObjects.filter(e=>{
        return e.wifi.items.some(i=>i.wifi_manufacturer=="Google") || e.wifi.items.some(i=>i.wifi_manufacturer=="Ubiqitui")
      }).map(e=>{
        return {
          ...e,
          google_count: e.wifi.items.filter(i=>i.wifi_manufacturer=="Google").length,
          ubiqitui_count: e.wifi.items.filter(i=>i.wifi_manufacturer=="Ubiqitui").length,
        }
      });
    },
    users_without_customers(){
      var users = [];
      var unpr_users = this.$store.getters.getCognitoUsers;
      for (const i in unpr_users) {
        var user = {};
        user.updated_at = unpr_users[i].UserLastModifiedDate;
        user.username = unpr_users[i].Username;
        for (const n in unpr_users[i].Attributes) {
          user[unpr_users[i].Attributes[n].Name] =
            unpr_users[i].Attributes[n].Value;
        }
        users.push(user);
      }
      var users_without_customers = users.filter(e=>(this.$store.getters.getCustomerObjects.map(e=>e.owner).indexOf(e.username)<0));
      users_without_customers.forEach(e=>{
        var customer = this.customers.find(i=>e.username==i.id);
        if(customer){
          e.customer_company_name = customer.customer_company_name;
        }
        e.groupname = e.Groups?.[0]?.GroupName;
      })

      return users_without_customers;
    }
  },
  mounted() {},
  created() {
    if (this.$store.getters.getCustomerObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(()=>{
      });
    }
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS").then(()=>{
      });
    }
    this.getRFIDData([this.query1,this.query2]);
  },
  watch: {
    // selected_table(){
    //   if(this.selected_table==1||this.selected_table==2){
    //     this.selected_table = this.selected_table_prev;
    //   }
    //   this.selected_table_prev = this.selected_table;
    //   console.log("selected_table: "+this.selected_table)
    // }
  }
};
</script>
<style lang="css" scoped>
  .link_cell > span {
  display: initial;
}
.selected{
  color: #0090a4 !important;
  /* border-left: 5px solid #424242 */
}
</style>