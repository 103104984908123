<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      min-width="760px"
      persistent
      no-click-animation
      @click:outside="attemptToClose"
    >
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <span style="color: #0090a4" v-if="create_new">Add User</span>
          <span style="color: #0090a4" v-else>Edit User</span>
          <v-chip class="ml-2" small v-if="has_unsaved_changes">{{ Object.keys(original_values).length +" unsaved changes"}}</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="attemptToClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
   
                <v-form
                  ref="form_user"
                  v-model="validation.User"
                  :disabled="loading"
                  lazy-validation
                >
                  <v-container>
                    <v-row class="mb-3">
                      <div style="width: 100%; text-align: center">
                        <h2>Enter Email and Password</h2>
                      </div>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.given_name"
                          label="First Name *"
                          outlined
                          dense
                          :rules="[rules.required, rules.name]"
                          class="text-validated pl-3 pr-3 pt-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.family_name"
                          label="Last Name *"
                          outlined
                          dense
                          :rules="[rules.required, rules.name]"
                          class="text-validated pl-3 pr-3 pt-1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email *"
                          outlined
                          dense
                          class="text-validated pl-3 pr-3 pt-1"
                          @input="editedItem.email = editedItem.email.toLowerCase()"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.password"
                          label="Password *"
                          outlined
                          dense
                          class="text-validated pl-3 pr-3 pt-1"
                          :rules="[rules.required, rules.password]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="save()">Save</v-btn>
                    </v-row>
                  </v-container>
                </v-form>
  
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dirty_dialog" max-width="550px" min-width="260px">
        <v-card>
          <v-card-title>Discard unsaved changes?</v-card-title>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
            <v-btn
              color="#dd3f5b"
              @click="close()"
              class="mr-3 white--text"
            >
              Discard
            </v-btn>
            <v-btn color="primary" @click="dirty_dialog = false">
              Cancel
            </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script lang="js">
import { eventBus } from "../main.js";
import rulesMixin from "../mixins/rulesMixin";
import countryData from "../data/country-by-name.json";
import timezones from "../data/timezones.json";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations.js";
import * as queries from '../graphql/queries';
import { 
    AdminUpdateUserAttributesCommand,
    AdminSetUserPasswordCommand,
    AdminCreateUserCommand,
    AdminAddUserToGroupCommand
 } from "@aws-sdk/client-cognito-identity-provider";
import { initializeCognitoIdentityProviderClient } from '../utilities/sessionUtils';
var cognitoidentityserviceprovider;

// @vuese
// @group Components
// The editing card for adding user
export default {
    components: {
    },
    mixins: [rulesMixin],
    data(){
        return {
            dialog: false,
            editedItem: {},
            create_new: false,
            step: 1,
            newCustomer: {
                customer_mailing_address: {
                    country: "Canada"
                },
                customer_physical_address: {},
                customer_type: "CUSTOMER"
            },
            collaborators: [],
            is_collaborators: [],
            showPhysicalAddress: false,
            sites: [],
            site_count: 0,
            cameras: [],
            cam_count: 0,
            wifis: [],
            wifi_count: 0,
            loading: false,
            inventory_customers: [
                "ad4ba1d2-602b-45a6-92e5-1352dbbb0aad",
                "518dfac2-9176-4dc9-8361-64aed6d333a3",
                "a0841b8f-81a3-4ae9-910d-f7256b345783",
                "06d79203-450f-4f44-afd3-04e4a8dfcbaf",
                "34b9a8a1-3b41-42ab-9c64-5304dcbe939f",
                
                "05c2dcac-8961-43bc-929a-d0d5a2db0de0",
                "ba7707ed-7067-4d59-ae79-796bf2ea407f",
                "8ea777bc-de26-46f7-a927-ea24acfd5459"
            ],

            validation: {
                "User": true,
                "Customer_Basic": true,
                "Customer_Additional": true,
                "Sites": true,
                "Cameras": true,
                "Wifi": true
            },
            valid_edit: true,
            validation_key: "User",

            original_values: {},
            edit_value: null,
            has_unsaved_changes: false,
            pressed_apply: false,
            dirty_dialog: false
        }
    },
    created() {
        eventBus.$on("open_dialog_users", (params) => {
            this.open(params);
        });


        window.addEventListener('beforeunload', (event)=>{
          if(this.has_unsaved_changes){
            event.preventDefault();
            event.returnValue = true;
          }
        });
    },
    methods: {
      on_field_change(key, value) {
      if (!Object.prototype.hasOwnProperty.call(this.original_values, key)) {
        if (this.edit_value === "" || this.edit_value === false){
          this.original_values[key] = null;
        }else{
          this.original_values[key] = this.edit_value;
        }
        this.has_unsaved_changes = true;
      }
      if (value === "" || value === false) {
        value = null;
      }
      // console.log(`original: ${this.original_values[key]}, current: ${value}`)
      if (this.original_values[key] == value) {
        delete this.original_values[key];
        if(Object.keys(this.original_values).length <=0){
          this.has_unsaved_changes = false;
        }
      }
    },
    // @vuese
    // Check for unsaved changes before closing the user card
      attemptToClose(){
          if(this.has_unsaved_changes){
            this.dirty_dialog = true;
          }else{
            this.close();
          }
        },
        sortByKey(array, key) {
            return array.sort(function (a, b) {
                var x = String(a[key]);
                var y = String(b[key]);
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },
        formulateNames(first_name, last_name){
          return (first_name?(first_name+" "):"")+(first_name==last_name?"":last_name);
        },
        addSite(){
            this.site_count++;
            this.sites.push({index:this.site_count, site_location: {}, valid: true, site_map_zoom_level: 17});
            this.sites[this.sites.length-1].active = true;
        },
        addCam(){
            this.cam_count++;
            this.cameras.push({index: this.cam_count});
        },
        addWifi(){
            this.wifi_count++;
            this.wifis.push({index: this.wifi_count});
        },
        async bindCollaborators(ID, CID, type){
            var edited_customer;
            edited_customer = await API.graphql({
              query: queries.getCustomerCollaborators,
              variables: { id: ID }
            })
            edited_customer = edited_customer.data.getCustomer;
            edited_customer.customer_collaborators = edited_customer.customer_collaborators.filter(n=>n);
            edited_customer.customer_collaborators = edited_customer.customer_collaborators.filter((n, i, arr)=>n.id!=arr[i-1]?.id);
            if(type=="remove"){
              for(let i = 0; i<edited_customer.customer_collaborators.length; i++){
                if(edited_customer.customer_collaborators[i].id == CID){
                  edited_customer.customer_collaborators.splice(i,1);
                  break;
                }
              }
            }else if(type=="add"){
              
              edited_customer.customer_collaborators.push({
                id: CID,
                email: this.editedItem.email,
                approved: true,
                allowed_operations: []
              });
            }
            await API.graphql({
                query: mutations.updateCustomer,
                variables: {
                    input: edited_customer,
                },
            });
            
        },

        async bindIsCollaborators(ID, CID, type){
            var edited_customer;
            edited_customer = await API.graphql({
              query: queries.getCustomerIsCollaborators,
              variables: { id: ID }
            })
            edited_customer = edited_customer.data.getCustomer;
            edited_customer.customer_is_collaborator = edited_customer.customer_is_collaborator.filter(n=>n);
            edited_customer.customer_is_collaborator = edited_customer.customer_is_collaborator.filter((n, i, arr)=>n.id!=arr[i-1]?.id);
            if(type=="remove"){
              for(let i = 0; i<edited_customer.customer_is_collaborator.length; i++){
                if(edited_customer.customer_is_collaborator[i].id == CID){
                  edited_customer.customer_is_collaborator.splice(i,1);
                  break;
                }
              }
            }else if(type=="add"){
              
              edited_customer.customer_is_collaborator.push({
                id: CID,
                email: this.editedItem.email,
                approved: true,
                allowed_operations: []
              });
            }
            await API.graphql({
                query: mutations.updateCustomer,
                variables: {
                    input: edited_customer,
                },
            });
            
        },
  
        async saveSites(owner){
            var sites = {};
            for(var i =0; i<this.sites.length; i++){
                var site = this.sites[i];
                var temp_index = site.index;
                delete site.index;
                delete site.active;
                delete site.valid;
                site.owner = owner;
                site.customer_id = owner;
                for (const n in site) {
                    if (typeof site[n] === "string" || site[n] instanceof String) {
                        site[n] = site[n].trim();
                    }
                }

                site.author = {
                    id: owner,
                    first_name: this.newCustomer.customer_first_name,
                    last_name: this.newCustomer.customer_last_name
                }
                console.log("site started");
                var new_site = await API.graphql({
                    query: mutations.createSite,
                    variables: {
                        input: site,
                    },
                });
                console.log("site ended");
                sites["Site_"+temp_index] = new_site.data.createSite.id;
            }

            await this.saveCameras(owner, sites);
            await this.saveWifi(owner, sites);
        },
        async saveCameras(owner, sites){
            for(var i = 0; i<this.cameras.length; i++){
                var cam = this.cameras[i];
                var input = {
                    id: cam.id,
                    customer_id: owner,
                    owner: owner,
                    site_id: sites[cam.site]
                }
                console.log("camera started: "+cam.id);
                await API.graphql({
                    query: mutations.updateCamera,
                    variables: {
                        input: input,
                    },
                });
                console.log("camera ended: "+cam.id);

            }
        },
        async saveWifi(owner, sites){
            for(var i = 0; i<this.wifis.length; i++){
                var wifi = this.wifis[i];
                var input = {
                    id: wifi.id,
                    customer_id: owner,
                    owner: owner,
                    site_id: sites[wifi.site]
                }

                console.log("wifi started: "+wifi.id);
                await API.graphql({
                    query: mutations.updateWifi,
                    variables: {
                        input: input,
                    },
                });
                console.log("wifi ended: "+wifi.id);

            }
        },
        // @vuese
        // Open the user card
        // @arg The paramters for opening the card
        open(params){
            this.dialog = true;
            this.editedItem = params.item
            this.create_new = params.create_new;
            this.has_unsaved_changes = false;
            this.pressed_apply = false;
            this.original_values = {};
            this.newCustomer = {
                customer_mailing_address: {
                    country: "Canada"
                },
                customer_physical_address: {},
                customer_type: "CUSTOMER"
            };
            this.collaborators= [];
            this.is_collaborators= [];
            this.sites = [];
            this.site_count = 0;
            this.addSite();
            this.sites[0].active = true;
            this.cameras = [];
            this.wifis = [];
            this.step = 1;
            this.validation= {
                "User": true,
                "Customer_Basic": true,
                "Customer_Additional": true,
                "Sites": true,
                "Cameras": true,
                "Wifi": true
            };
            this.validation_key= "User";
        },
        // @vuese
        // Close the user card
        close() {
            if(!this.loading){
                this.dialog = false;
                this.create_new = false;
                this.dirty_dialog = false;
                this.has_unsaved_changes = false;
                this.original_values = {};
                // this.$nextTick(() => {
                //     this.editedItem = {};
                // });
            }
        },
        validate(key){
            try{
                if(key){
                    if(key=="User"){
                        this.$refs.form_user.validate();
                    }else if(key=="Customer_Basic"){
                        this.$refs.form_customer_basic.validate();
                    }else if(key=="Customer_Additional"){
                        this.$refs.form_customer_additional.validate();
                    }else if(key=="Sites"){
                        let result = true;
                        for(let i = 0; i<this.sites.length; i++){
                            if(!this.sites[i].valid){
                                result = false;
                                break;
                            }
                        }
                        this.validation.Sites = result;
                    }else if(key=="Cameras"){
                        this.$refs.form_cameras.validate();
                    }else if(key=="Wifi"){
                        this.$refs.form_wifi.validate();
                    }
                    return this.validation[key];
                }else{
                    this.$refs.form_user.validate();
                    this.$refs.form_customer_basic.validate();
                    this.$refs.form_customer_additional.validate();
                    let result = true;
                        for(let i = 0; i<this.sites.length; i++){
                            if(!this.sites[i].valid){
                                result = false;
                                break;
                            }
                        }
                    this.validation.Sites = result;
                    this.$refs.form_cameras.validate();
                    this.$refs.form_wifi.validate();
                    return this.valid;
                }
            }catch{
                return false;
            }
            
        },
        // @vuese
        // Apply the changes
        apply(){
          if(this.create_new&&!this.validate()){
              return;
          }
          this.loading = true;
          var endFunc = async ()=>{
              //await this.$store.dispatch("DDB_GET_CAMERAS");
              await this.$store.dispatch("DDB_GET_WIFI");
              await this.$store.dispatch("DDB_GET_SITES");
              await this.$store.dispatch("DDB_GET_COGNITO_USERS");
              await this.$store.dispatch("DDB_GET_CUSTOMERS");
              this.pressed_apply = true;
              this.loading = false;
              this.original_values = {};
              this.has_unsaved_changes = false;
              eventBus.$emit("67");
              console.log("IT FINALLY WORKS 😀");
          }
          this.uploadItem().then(()=>{
            endFunc();
          }).catch(err=>{
              console.log(err);
              this.loading = false;
          });
        },
        // @vuese
        // Apply the changes and close the card
        save() {
            // if(this.create_new&&!this.validate()){
            //     return;
            // }
            this.loading = true;
            var endFunc = async ()=>{

              eventBus.$emit("get_cognito_users")
                await this.$store.dispatch("DDB_GET_CUSTOMERS");
                this.dialog = false;
                this.loading = false;
                this.editedItem = {};
                this.original_values = {};
                this.has_unsaved_changes = false;
                eventBus.$emit("on_update_users");
                console.log("IT FINALLY WORKS 😀");
            }
            this.uploadItem().then(()=>{
              endFunc();
            }).catch(err=>{
                console.log(err);
                this.loading = false;
            });
        },
        // @vuese
        // Upload the unsaved changes to the database
        uploadItem() {
            return initializeCognitoIdentityProviderClient().then(client=>{
                cognitoidentityserviceprovider = client;
                var user_attributes = [];
                if (this.editedItem.given_name)
                user_attributes.push({
                    Name: "given_name",
                    Value: this.editedItem.given_name.trim(),
                });
                else {
                user_attributes.push({
                    Name: "given_name",
                    Value: "",
                });
                }
                if (this.editedItem.family_name)
                user_attributes.push({
                    Name: "family_name",
                    Value: this.editedItem.family_name.trim(),
                });
                else {
                user_attributes.push({
                    Name: "family_name",
                    Value: "",
                });
                }
                // if (this.editedItem.phone_number)
                // user_attributes.push({
                //     Name: "phone_number",
                //     Value: this.editedItem.phone_number,
                // });
                // else {
                // user_attributes.push({
                //     Name: "phone_number",
                //     Value: "",
                // });
                // }
                // if (this.editedItem.address)
                // user_attributes.push({
                //     Name: "address",
                //     Value: this.editedItem.address,
                // });
                // else {
                // user_attributes.push({
                //     Name: "address",
                //     Value: "",
                // });
                // }
                // if (this.editedItem.zoneinfo)
                // user_attributes.push({
                //     Name: "zoneinfo",
                //     Value: this.editedItem.zoneinfo,
                // });
                // else {
                // user_attributes.push({
                //     Name: "zoneinfo",
                //     Value: "",
                // });
                // }
                var params = {
                    UserAttributes: user_attributes,
                    UserPoolId: process.env.VUE_APP_CLIENT_USER_POOL_ID /* required */,
                    Username: this.editedItem.username /* required */,
                };
                var command;
                if (!this.create_new) {
                    command = new AdminUpdateUserAttributesCommand(params); 
                    return cognitoidentityserviceprovider.send(command).then(()=>{
                        if (this.editedItem.password) {
                            var params2 = {
                                Password: this.editedItem.password /* required */,
                                UserPoolId:
                                process.env.VUE_APP_CLIENT_USER_POOL_ID /* required */,
                                Username: this.editedItem.username /* required */,
                                Permanent: true,
                            };
                            var command2 = new AdminSetUserPasswordCommand(params2);
                            return cognitoidentityserviceprovider.send(command2);
                        } else {
                            return Promise.resolve();
                        }
                    });
                } else {
                    this.editedItem.email = this.editedItem.email.trim();
                    params.Username = this.editedItem.email;
                    params.TemporaryPassword = this.editedItem.password;
                    params.UserAttributes.push({
                        Name: "email",
                        Value: this.editedItem.email
                    });
                    params.UserAttributes.push({
                        Name: "email_verified",
                        Value: "True"
                    });
                    params.MessageAction = "SUPPRESS";
                    var new_username = null;
                    command = new AdminCreateUserCommand(params);
                    return cognitoidentityserviceprovider.send(command)
                    .then((data)=>{
                      new_username = data.User.Username;
                        var group_params = {
                            GroupName: 'Client', /* required */
                            UserPoolId: process.env.VUE_APP_CLIENT_USER_POOL_ID, /* required */
                            Username: new_username /* required*/
                        };
                        var group_command = new AdminAddUserToGroupCommand(group_params);
                        return cognitoidentityserviceprovider.send(group_command);
                    }).then(()=>{
                        var password_params = {
                            Password: this.editedItem.password /* required */,
                            UserPoolId: process.env.VUE_APP_CLIENT_USER_POOL_ID /* required */,
                            Username: new_username /* required */,
                            Permanent: true,
                        };
                        var password_command = new AdminSetUserPasswordCommand(password_params);
                        return cognitoidentityserviceprovider.send(password_command)
                    });
                }
                // this.$store.dispatch("DDB_GET_COGNITO_USERS");
            });
        }
    },
    watch: {
        step(){
            this.validate(this.validation_key);

            if(this.step==1){
                this.validation_key = "User";
            }else if(this.step==2){
                this.validation_key = "Customer_Basic";
            }else if(this.step==3){
                this.validation_key = "Customer_Additional";
            }else if(this.step==4){
                this.validation_key = "Sites";
            }else if(this.step==5){
                this.validation_key = "Cameras";
            }else if(this.step==6){
                this.validation_key = "Wifi";
            }
        },
        dialog() {
            this.$store.commit('SET_USER_CARD_DIALOG', this.dialog);
        }
    },
    computed: {
        valid(){
            var final_result = true;
            for(var n in this.validation){
                if(!this.validation[n]){
                    final_result = false;
                    break;
                }
            }
            return final_result;
        },
        users(){
          var users = [];
          var unpr_users = this.$store.getters.getCognitoUsers;
        //   console.log(unpr_users);
          for (const i in unpr_users) {
            var user = {};
            user.updated_at = unpr_users[i].UserLastModifiedDate;
            user.username = unpr_users[i].Username;
            for (const n in unpr_users[i].Attributes) {
              user[unpr_users[i].Attributes[n].Name] =
                unpr_users[i].Attributes[n].Value;
            }
            users.push(user);
          }
          users = users.map((element)=>{
            return {
              text: this.formulateNames(element.given_name, element.family_name),
              value: {
                id: element.username,
                email: element.email
              }
            }
          })
          console.log(unpr_users);
          return users;
        },
        users_collaborators(){
          return this.users.filter(e=>{
            for(var i = 0; i<this.is_collaborators.length; i++){
              if(e.value.id==this.is_collaborators[i].id){
                return false;
              }
            }
            return true;
          });
        },
        users_is_collaborators(){
          return this.users.filter(e=>{
            for(var i = 0; i<this.collaborators.length; i++){
              if(e.value.id==this.collaborators[i].id){
                return false;
              }
            }
            return true;
          });
        },
        countries(){
          var c = [];
          for(var i in countryData){
            c.push(countryData[i].country);
          }
          return c;
        },
        camera_objects() {
            var arr = this.$store.getters.getCameraObjects.filter(e=>this.inventory_customers.find(i=>i==e.owner));
            this.sortByKey(arr, "camera_inventory_number");
            console.log(arr);
            return arr.map(e=>{
                            return {
                                    text: e.id+" - "+e.camera_inventory_number+" - "+this.formulateNames(e.customer.customer_first_name, e.customer.customer_last_name),
                                    value: e.id
                                }
                            });

        },
        wifi_objects(){
            var arr = this.$store.getters.getWifiObjects.filter(e=>this.inventory_customers.find(i=>i==e.owner));
            this.sortByKey(arr, "wifi_inventory_number");
            return arr.map(e=>{
                            return {
                                text: e.id+" - "+e.wifi_inventory_number+" - "+this.formulateNames(e.customer.customer_first_name, e.customer.customer_last_name),
                                value: e.id
                            }
                        })
        },
        selectable_sites(){
            return this.sites.map(e=>{
                return {
                    text: e.site_name?e.site_name:("Site "+e.index),
                    value: "Site_"+e.index
                }
            })
        },
        timezones(){
            return timezones.data;
        }
    }
}
</script>

<style scoped>
.x-row {
  height: 100%;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
}

.the-mask:focus{
  border: 1px solid gray;
}
</style>