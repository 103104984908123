<template>
  <div>
    <v-form ref="form" v-model="valid" :disabled="loading">
      <v-dialog
        v-model="dialog"
        max-width="1000px"
        min-width="760px"
        persistent
        no-click-animation
        @click:outside="attemptToClose"
      >
        <v-card :loading="loading" class="overflow-hidden">
          <v-card-title>
            <v-row>
              <span class="" style="color: #0090a4">Add Customer</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="attemptToClose" :disabled="loading">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
            <!-- <span class="" style="color: #0090a4" v-else
              >Edit Customer</span
            > -->
          </v-card-title>

          <v-card-text>
            <!-- overview -->

            <!-- customer -->
            <v-card :min-height="card_height">
              <v-card-text id="customer_general_card">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="owner_name"
                      :items="owner_customer_names"
                      label="Owner *"
                      :menu-props="{ offsetOverflow: false }"
                      outlined
                      dense
                      :rules="[rules.required]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.first_name"
                      label="First Name *"
                      outlined
                      dense
                      :rules="[rules.required, rules.name]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.last_name"
                      label="Last Name *"
                      outlined
                      dense
                      :rules="[rules.required, rules.name]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.preferred_name"
                      label="Preferred Name *"
                      outlined
                      dense
                      :rules="[rules.name]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.type"
                      label="Type *"
                      :items="$store.getters.getCustomerTypes"
                      outlined
                      dense
                      :rules="[rules.required]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.status"
                      label="Status"
                      :items="$store.getters.getCustomerStatus"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.company_name"
                      label="Company Name *"
                      outlined
                      dense
                      :rules="[rules.required]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.stripe_id"
                      label="Stripe ID"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.company_occupation"
                      label="Company Occupation"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.company_website"
                      label="Company Website"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <phone-masked-field
                      v-model="editedItem.phone_cell"
                      label="Cell Phone"
                    ></phone-masked-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <phone-masked-field
                      v-model="editedItem.phone_home"
                      label="Home Phone"
                    ></phone-masked-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <phone-masked-field
                      v-model="editedItem.phone_fax"
                      label="Fax"
                    ></phone-masked-field>
                  </v-col>
                </v-row>
                <TextDivider text="Mailing Address"></TextDivider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.mailing_address.line_1"
                      label="Address 1 *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.mailing_address.line_2"
                      label="Address 2"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.mailing_address.city"
                      label="City *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-if="editedItem.mailing_address.country == 'Canada'"
                      v-model="editedItem.mailing_address.province"
                      :items="$store.getters.getProvinces"
                      label="Province *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                    <v-select
                      v-else-if="
                        editedItem.mailing_address.country == 'United States'
                      "
                      v-model="editedItem.mailing_address.province"
                      :items="$store.getters.getStates"
                      label="State *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                    <v-text-field
                      v-else
                      v-model="editedItem.mailing_address.province"
                      label="Province *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <masked-field
                      v-if="editedItem.mailing_address.country == 'Canada'"
                      v-model="editedItem.mailing_address.postal_code"
                      label="Postal Code *"
                      :rules="[rules.required, rules.address, rules.CAZipCode]"
                      mask="___ ___"
                      :uppercase="true"
                    >
                    </masked-field>

                    <v-text-field
                      v-else-if="
                        editedItem.mailing_address.country == 'United States'
                      "
                      v-model="editedItem.mailing_address.postal_code"
                      label="Postal Code *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address, rules.USZipCode]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="editedItem.mailing_address.postal_code"
                      label="Postal Code *"
                      outlined
                      dense
                      :rules="[rules.required, rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                      v-model="editedItem.mailing_address.country"
                      label="Country *"
                      :items="countries"
                      :rules="[rules.required]"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-checkbox
                  v-model="showPhysicalAddress"
                  label="Physical Address Differs From Mailing Address"
                  class="ma-0 pa-0"
                  dense
                  hide-details
                  v-if="!showPhysicalAddress"
                ></v-checkbox>
                <TextDivider v-if="showPhysicalAddress"
                  ><v-checkbox
                    v-model="showPhysicalAddress"
                    label="Physical Address Differs From Mailing Address"
                    class="small-checkbox ma-0 pa-0"
                    dense
                    hide-details
                  ></v-checkbox
                ></TextDivider>
                <v-row no-gutters v-if="showPhysicalAddress">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.physical_address.line_1"
                      label="Address 1"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.physical_address.line_2"
                      label="Address 2"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.physical_address.city"
                      label="City"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-if="editedItem.physical_address.country == 'Canada'"
                      v-model="editedItem.physical_address.province"
                      :items="$store.getters.getProvinces"
                      label="Province"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                    <v-select
                      v-else-if="
                        editedItem.physical_address.country == 'United States'
                      "
                      v-model="editedItem.physical_address.province"
                      :items="$store.getters.getStates"
                      label="State"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                    <v-text-field
                      v-else
                      v-model="editedItem.physical_address.province"
                      label="Province"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <masked-field
                      v-if="editedItem.physical_address.country == 'Canada'"
                      v-model="editedItem.physical_address.postal_code"
                      label="Postal Code"
                      :rules="[rules.address, rules.CAZipCode]"
                      mask="___ ___"
                      :uppercase="true"
                    ></masked-field>

                    <v-text-field
                      v-else-if="
                        editedItem.physical_address.country == 'United States'
                      "
                      v-model="editedItem.physical_address.postal_code"
                      label="Postal Code"
                      outlined
                      dense
                      :rules="[rules.address, rules.USZipCode]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      v-model="editedItem.physical_address.postal_code"
                      label="Postal Code"
                      outlined
                      dense
                      :rules="[rules.address]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                      v-model="editedItem.physical_address.country"
                      label="Country"
                      :items="countries"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <TextDivider text="Social"></TextDivider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_instagram"
                      label="Instagram"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_facebook"
                      label="Facebook"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_linkedin"
                      label="Linkedin"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_twitter"
                      label="Twitter"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_youtube"
                      label="Youtube"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.social_other"
                      label="Other"
                      outlined
                      dense
                      :rules="[rules.url]"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <TextDivider text="Notification"></TextDivider>
                <v-row no-gutters>
                  <v-col
                    v-for="name in notification_fields"
                    :key="name.value"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-select
                      v-model="
                        editedItem.notification_preference[
                          name.value.split('.')[1]
                        ]
                      "
                      :value="getByString(editedItem, name.value)"
                      :items="$store.getters.getNotificationFrequency"
                      :label="name.text"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="pr-6">
            <v-row>
              <v-col cols="12" class="py-0">
                <div style="float: right">
                  <v-btn
                    @click="attemptToClose"
                    class="ml-4"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="primary" class="ml-4" @click="save">
                    Save
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" v-if="error"
                ><span class="error_span">{{ error }}</span></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dirty_dialog" max-width="550px" min-width="260px">
        <v-card>
          <v-card-title
            >Discard unsaved changes for
            {{ formulateNames(editedItem.first_name, editedItem.last_name) }}
            ?</v-card-title
          >
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn
                color="#dd3f5b"
                @click="discardChanges()"
                class="mr-3 white--text"
              >
                Discard
              </v-btn>
              <v-btn color="primary" @click="dirty_dialog = false">
                Cancel
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script lang="js">

import { eventBus } from "../main";
import TextDivider from "./TextDivider.vue";
import countryData from "../data/country-by-name.json";
import rulesMixin from "../mixins/rulesMixin";
import RFIDMixin from "../mixins/RFID_TM_Mixin";
import nameMixin from "../mixins/nameMixin";

import PhoneMaskedField from "./PhoneMaskedField.vue";
import MaskedField from "./MaskedField.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
// @vuese
// @group Components
// The editing and information card for customer
export default {
    components: { 
      TextDivider, 
    
      PhoneMaskedField,
      MaskedField
    },
    data() {
        return {
            editedItem: { mailing_address: {}, physical_address: {}, notification_preference: {} },
            delete_dialog: false,
            dialog: false,
            owner_name: "",
            error: "",
            site_name: "",
            valid: false,
            owner_user: {
              email: "",
              given_name: "",
              family_name: ""
            },
            last_login_time: null,

            selected_source: "ALL",
            selected_time_col1: "Days",
            selected_range1: [0, 7],

            showPhysicalAddress: false,
            card_height: 658,
            table_length: 11,
            loading: false,
            animals: [],
            phenotypes: [],
            treatments: [],
            measurements: [],
            notes: [],
            videos: [],
            video_data: {
              total_size: 0,
              total_number: 0
            },

            site_search: "",
            camera_search: "",
            wifi_search: "",
            jetson_search: "",
            all_collaborators: [],

            label_sort_by_name: false,
            label_sort_by_data: true,

            collaborators: [],
            collaborators_original: [],
            is_collaborators: [],
            is_collaborators_original: [],

            is_name_wrapped: false,
            device_spacing: "mb-8",

            last_login_color: "",
            label_length: 0,
            lambda: null,

            selected_colab: 0,

            cancel_colaboration: false,
            delete_collab_dialog: false,
            delete_item: {},

            approve_colaboration: false,
            approve_collab_dialog: false,
            approve_item: {},
            collab_update_key: 0,

            // dirtyFields: {},
            // isFieldActive: false,
            // hasDirtyFields: false,
            // edit_value: null,
            // dirty_dialog: false,
            // next_event: ""

            original_values: {},
            isFieldActive: false,
            edit_value: null,
            has_unsaved_changes: false,
            pressed_apply: false,
            dirty_dialog: false,
            next_event: "",

            RFID_date_range: []
        };
    },
    created() {
        eventBus.$on("open_dialog_customers", (params) => {
            this.open(params);
        });
        eventBus.$on("update_edited_customer", (params)=>{
          this.updateItem(params)
        });

        eventBus.$on("CustomerCard_close", this.close);
        if (this.$store.getters.getCognitoUsers.length == 0) {
          this.$store.dispatch("DDB_GET_COGNITO_USERS");
        }
        if (this.$store.getters.getCustomerTypes.length <= 0){
          this.$store.dispatch("DDB_GET_CUSTOMER_TYPES");
        }
        if (this.$store.getters.getNotificationFrequency.length <= 0){
          this.$store.dispatch("DDB_GET_NOTIFICATION_FREQUENCY");
        }
        window.addEventListener('beforeunload', (event)=>{
          if(this.has_unsaved_changes){
            event.preventDefault()
            event.returnValue = true
          }
        });
    },
    mounted(){
      window.addEventListener("keyup", e=>{
          if(
              this.$store.getters.getCustomerCardDialog
              &&!this.$store.getters.getSiteCardDialog
              &&!this.$store.getters.getCameraCardDialog
              &&!this.$store.getters.getWifiCardDialog
              &&!this.$store.getters.getJetsonCardDialog
              &&!this.$store.getters.getCollaborationCardDialog
              &&!this.delete_collab_dialog
              &&!this.approve_collab_dialog
              &&!this.delete_dialog
              &&!this.loading
              &&!this.isFieldActive
          ){
              if(e.code=="ArrowLeft"){
                this.attemptPreviousCustomer();
              }

              if(e.code=="ArrowRight"){
                this.attemptNextCustomer();
              }
          }
      })
    },
    methods: {
      getByString(o, s){
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (o && k in o) {
                o = o[k];
            } else {
                return null;
            }
        }
        return o;
      },
      setByString(o, s, v){
        // console.log(v);
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length-1; i < n; ++i) {
            var k = a[i];
            if (o && k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        o[a[a.length-1]] = v;
      },
      onFieldFocus(value){
        this.isFieldActive = true;
        this.edit_value = value;
        if(this.edit_value == undefined || this.edit_value == null){
          this.edit_value = "";
        }
      },
      onFieldBlur(){
        this.isFieldActive = false;
      },

      discardChanges(){
        this.original_values = {};
        this.has_unsaved_changes = false;
        this.dirty_dialog = false;
        eventBus.$emit(this.next_event);
      },

        sortByKey(array, key) {
            return array.sort(function (a, b) {
              var x = a[key];
              var y = b[key];
              return x < y ? 1 : x > y ? -1 : 0;
            });
        },
        convertInfoUnit(num){
          var unit = "MB";
          // MB to GB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "GB";
          }

          // GB to TB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "TB";
          }

          // TB to PB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "PB";
          }

          // PB to EB
          if(num>=1024){
            num = Math.round(num/1024);
            unit = "EB";
          }

          num = Math.round(num*10)/10;
          return num+unit;
        },
        // @vuese
        // Open the customer card
        // @arg The paramters for opening the card
        open() {
            this.error = "";
            this.dialog = true;
            this.collaborators = [];
            this.collaborators_original = [];
            this.is_collaborators = [];
            this.is_collaborators_original = [];
            this.has_unsaved_changes = false;
            this.original_values = {};
            this.pressed_apply = false;
            this.isFieldActive = false;
       
            if(JSON.stringify(this.editedItem.mailing_address) === JSON.stringify(this.editedItem.physical_address)){
              this.showPhysicalAddress = false;
            }else{
              this.showPhysicalAddress = true;
            }
      
              this.editedItem.mailing_address.country = "Canada";
            
            this.$nextTick(()=>{
              if(this.$refs.form){
                this.$refs.form.resetValidation();
              }
            });
        },
        // @vuese
        // Check for unsaved changes before closing the customer card
        attemptToClose(){
          if(this.has_unsaved_changes){
            this.next_event="CustomerCard_close"
            this.dirty_dialog = true;
          }else{
            this.close();
          }
        },
        // @vuese
        // Close the customer card
        close() {
            if(!this.loading){
              this.has_unsaved_changes = false;
              this.original_values = {};
              this.dialog = false;
              // eventBus.$emit("updateCustomers");
            }
        },

        save() {
          this.loading=true;
          this.uploadItem().then(()=>{
            this.$store.dispatch("DDB_GET_CUSTOMERS").then(()=>{
              this.loading = false;
              this.dialog = false;
              this.original_values = {};
              this.has_unsaved_changes = false;
              this.editedItem = {
                  mailing_address: {},
                  physical_address: {},
                  notification_preference: {}
              };
            })

          }).catch(()=>{
            this.loading = false;
          });
        },
        // @vuese
        // Upload the unsaved changes to the database
        uploadItem(){
          return new Promise((resolve, reject)=>{
            (async()=>{
              this.$refs.form.validate();
            if(!this.valid){
              reject();
            }
 
            if(!this.showPhysicalAddress){
              this.editedItem.physical_address = Object.assign({}, this.editedItem.mailing_address);
            }
            for (const n in this.editedItem) {
                if (typeof this.editedItem[n] === "string" || this.editedItem[n] instanceof String) {
                    this.editedItem[n] = this.editedItem[n].trim();
                }
                if(!this.editedItem[n]){
                  this.editedItem[n] = null;
                }
            }

                try {
                    this.editedItem.id = this.owner_name;

                    let temp_m_address = JSON.parse(JSON.stringify(this.editedItem.mailing_address))
                    let temp_p_address = JSON.parse(JSON.stringify(this.editedItem.physical_address))
                    let temp_notification_preference = JSON.parse(JSON.stringify(this.editedItem.notification_preference))

                    delete this.editedItem.mailing_address
                    delete this.editedItem.physical_address
                    delete this.editedItem.notification_preference

                    temp_m_address.customer_id = this.editedItem.id
                    temp_m_address.type = "MAILING"
                    temp_p_address.customer_id = this.editedItem.id
                    temp_p_address.type = "PHYSICAL"
                    temp_notification_preference.customer_id = this.editedItem.id
             

                  await axios.post(
                      this.$store.getters.getHasuraParamsWrite.url,
                      {
                         query: hasura_mutations.insert_customer_one,
                         variables: {
                         object1: this.editedItem,
                      },
                    },
                    {
                      headers: this.$store.getters.getHasuraParamsWrite.headers,
                    }
                  );
                  await axios.post(
                      this.$store.getters.getHasuraParamsWrite.url,
                      {
                         query: hasura_mutations.insert_address_one,
                         variables: {
                         object1: temp_m_address,
                      },
                    },
                    {
                      headers: this.$store.getters.getHasuraParamsWrite.headers,
                    }
                  );

                   await axios.post(
                      this.$store.getters.getHasuraParamsWrite.url,
                      {
                         query: hasura_mutations.insert_address_one,
                         variables: {
                         object1: temp_p_address,
                      },
                    },
                    {
                      headers: this.$store.getters.getHasuraParamsWrite.headers,
                    }
                  );
                    for (const i in this.notification_fields) {
                      if (!temp_notification_preference[this.notification_fields[i]]) {
                        temp_notification_preference[this.notification_fields[i]] = "NEVER"
                      }
                      
                    }
                  await axios.post(
                      this.$store.getters.getHasuraParamsWrite.url,
                      {
                         query: hasura_mutations.insert_notification_preference_one,
                         variables: {
                         object1: temp_notification_preference,
                      },
                    },
                    {
                      headers: this.$store.getters.getHasuraParamsWrite.headers,
                    }
                  );

                    resolve();
                }
                catch (err) {
                    console.log(err);
                    this.error = err;
                    reject();
                }
          
            })();
          })
        },
        // @vuese
        // Delete the selected customer

        handle_spacing(height) {
          this.$nextTick(()=>{
            if (height>180){
              this.device_spacing = "mb-5";
            }else if(height>158){
              this.device_spacing = "mb-7";
            }else{
              this.device_spacing = "mb-8";
            }
          })
        }
    },
    watch: {
        dialog() {
            if (!this.dialog) {
                this.owner_name = "";
                this.site_name = "";
            }
            this.$store.commit('SET_CUSTOMER_CARD_DIALOG', this.dialog);
        },
        'editedItem.id'(){
          this.updateItem();
        },
        label_sort_by_name(){
          if(this.label_sort_by_name){
            this.label_sort_by_data = false;
          }
        },
        label_sort_by_data(){
          if(this.label_sort_by_data){
            this.label_sort_by_name = false;
          }
        },

    },
    computed: {
      notification_fields(){
      return this.$store.getters.getNotificationFields;
    },

        countries(){
          var c = [];
          for(var i in countryData){
            c.push(countryData[i].country);
          }
          return c;
        },
        owner_customer_names() {
            return this.$store.getters.getOwnerNameObject[0];
        },
        name_owner_names() {
            return this.$store.getters.getOwnerNameObject[1];
        },
        site_names() {
            if (this.$store.getters.getCustomerNames[1]) {
                return this.$store.getters.getCustomerNames[1][this.owner_name];
            }
            else {
                return [];
            }
        },
        customer_names() {
          var customers = [
            ...new Set(
              this.$store.getters.getCustomerObjects.map((item) => {
                try {
                  return {
                    text: (item.company_name?(item.company_name+" - "):"") + this.formulateNames(item.first_name, item.last_name),
                    value: item.id,
                  };
                } catch {
                  return "null";
                }
              })
            ),
          ];

          customers = this.sortByKey([...customers], "text").reverse();

          return customers;
          // return this.$store.getters.getCustomerNames[0];
        },
        customers(){
          var customers = [
            ...new Set(
              this.$store.getters.getCustomerObjects.map((item) => {
                try {
                  return {
                    text: (item.first_name? (item.first_name + " ") : "") + ((item.last_name==item.first_name)?"":item.last_name),
                    value: item.id,
                  };
                } catch {
                  return "null";
                }
              })
            ),
          ];

          customers = this.sortByKey([...customers], "text").reverse();

          return customers;
        },
        users(){
          var users = [];
          var unpr_users = this.$store.getters.getCognitoUsers;
          for (const i in unpr_users) {
            var user = {};
            user.updated_at = unpr_users[i].UserLastModifiedDate;
            user.username = unpr_users[i].Username;
            for (const n in unpr_users[i].Attributes) {
              user[unpr_users[i].Attributes[n].Name] =
                unpr_users[i].Attributes[n].Value;
            }
            user.isAdmin = unpr_users[i].Groups?.some(e=>e.GroupName=='Admin');
            user.isClient = unpr_users[i].Groups?.some(e=>e.GroupName=='Client');
            users.push(user);
          }
          return users;
        },
        user(){
          return this.users.find(e=>e.sub==this.owner_name);
        }
        // users_collaborators(){
        //   return this.users.filter(e=>{
        //     for(var i = 0; i<this.is_collaborators.length; i++){
        //       if(e.value.id==this.is_collaborators[i].id){
        //         return false;
        //       }
        //     }
        //     return true;
        //   });
        // },
        // users_is_collaborators(){
        //   return this.users.filter(e=>{
        //     for(var i = 0; i<this.collaborators.length; i++){
        //       if(e.value.id==this.collaborators[i].id){
        //         return false;
        //       }
        //     }
        //     return true;
        //   });
        // }
    },
    mixins: [rulesMixin, RFIDMixin, nameMixin]
};
</script>

<style scoped>
.tall_cart > div {
  height: var(--cam-count);
}
.collab_tabs {
  float: left;
  width: 400px;
}
</style>