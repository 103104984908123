<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Notifications</span>
              <v-spacer></v-spacer>
              <!-- <v-switch
                v-model="show_thumbnail"
                label="Thumbnail"
                dense
                hide-details
                class="mr-4"
              >
              </v-switch> -->
              <v-switch
                class="mt-0 pt-0 mr-6 ml-1"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                hide-details
                data-cy="btn_edit_treatment"
              ></v-switch>
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <!-- <v-menu
                v-model="datetime_menu"
                :close-on-content-click="false"
                open-on-hover
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="filter_button mr-4">
                    <span v-if="!selected_dates.length">all dates</span>

                    <span v-if="selected_dates.length == 1">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                    <span v-if="selected_dates.length == 2">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/") +
                      " - " +
                      selected_dates[1]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                  </v-btn>
                </template>

                <v-card height="380" width="280">
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="date_range"
                        flat
                        range
                        no-title
                        event-color="#ff9900"
                        :max="todays_date"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                  <v-row v-if="show_timebar">
                    <v-btn
                      class="filter_button"
                      color="primary"
                      id="saveButton"
                      @click="saveDTFilterMenu"
                    >
                      Save
                    </v-btn>
                    <v-icon
                      v-if="selected_dates.length"
                      @click="reset_dates"
                      class="ml-4 mt-n2"
                      >mdi-filter-off-outline</v-icon
                    >
                  </v-row>
                </v-card>
              </v-menu> -->

              <!-- @keydown.enter="()=>{if(search_old!=search) updateCameras();}" -->
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                  <!-- <template v-slot:[`append`]>
                  <v-icon :color="search_old!=search?'primary':''" @click="()=>{if(search_old!=search) updateCameras();}">{{search_old!=search?'mdi-arrow-right':'mdi-magnify'}}</v-icon>
                </template> -->
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                :disabled="loading"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :server-items-length="notification_count"
                :headers="selected_headers"
                :items="selected_table == 3 ? [] : notifications"
                :items-per-page="100"
                :class="`livestock_table ${
                  selected_table == 3 ? 'empty_table' : 'elevation-1'
                }`"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                :sort-desc="true"
                no-data-text="No data available"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header" :style="selected_table == 3 ? 'height: 52px; display: block;' : ''">
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >
                      <!-- <v-tab>All</v-tab>
                      <v-tab>
                        <v-icon class="ml-4" color="oc_green"
                          >mdi-information-outline</v-icon
                        ></v-tab
                      >
                      <v-tab>
                        <v-icon class="ml-4" color="oc_orange"
                          >mdi-shield-alert-outline</v-icon
                        ></v-tab
                      >
                      <v-tab>
                        <v-icon class="ml-4" color="oc_red"
                          >mdi-alert-outline</v-icon
                        ></v-tab
                      > -->

                      <v-tab>General</v-tab>
                      <v-tab>Internal Feedback</v-tab>
                      <v-tab>User Feedback</v-tab>
                      <v-tab>Analytics</v-tab>
                    </v-tabs>
                    <v-data-footer
                    v-if="selected_table != 3"
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                    <v-menu
                    v-else
                        v-model="analytics_datetime_menu"
                        :close-on-content-click="false"
                        open-on-hover
                        offset-overflow
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div style="width: 180px; float: right; margin-top: 12px"  class="">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-calendar"
                              class="mr-2 assign_select assign_select_v font_400"
                              single-line
                              hide-details
                              dense
                              height="32"
                              full-width
                              outlined
                              readonly
                              rounded
                              v-model="analytics_start_date"
                              :label="analytics_start_date ? analytics_start_date : 'Date'"
                            >
                            </v-text-field>
                          </div>
                        </template>

                        <v-card height="316" width="280">
                          <v-row>
                            <v-col>
                              <v-date-picker
                                v-model="analytics_start_date"
                                flat
                                no-title
                                id="video_dp"
                                event-color="#ff9900"
                                :max="todays_date"
                              ></v-date-picker>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                  </div>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden">
                    <td>
                      <v-select
                        hide-details
                        outlined
                        v-model="level_filter"
                        :items="level_items"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>

                    <td></td>
                    <td
                      class="select_width align_left pl-2"
                      v-if="selected_table == 0"
                    >
                      <v-menu
                        v-model="datetime_menu"
                        :close-on-content-click="false"
                        open-on-hover
                        offset-overflow
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div style="width: 180px" class="">
                            <v-text-field
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-calendar"
                              class="mr-2 assign_select assign_select_v font_400"
                              single-line
                              hide-details
                              dense
                              height="32"
                              full-width
                              outlined
                              readonly
                              rounded
                              v-model="date"
                              :label="date ? date : 'Date'"
                            >
                            </v-text-field>
                          </div>
                        </template>

                        <v-card height="316" width="280">
                          <v-row>
                            <v-col>
                              <v-date-picker
                                v-model="date"
                                flat
                                no-title
                                id="video_dp"
                                event-color="#ff9900"
                                :max="todays_date"
                              ></v-date-picker>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </td>
                    <td
                      class="select_width align_left pl-2"
                      v-if="selected_table == 0"
                    >
                      <CameraAutoComplete
                        :table="true"
                        :all="true"
                        v-model="selected_camera"
                        :owner="selected_customer"
                        :value="selected_camera"
                      ></CameraAutoComplete>
                    </td>
                    <td></td>

                    <td v-if="selected_table == 2">
                      <v-select
                        hide-details
                        outlined
                        v-model="feedback_received_filter"
                        :items="['All', true, false]"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td v-if="selected_table == 2">
                      <v-select
                        hide-details
                        outlined
                        v-model="feedback_positive_filter"
                        :items="['All', true, false]"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="selected_table == 0">
                      <v-select
                        hide-details
                        outlined
                        v-model="email_filter"
                        :items="['All', true, false]"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td v-if="selected_table == 0">
                      <v-select
                        hide-details
                        outlined
                        v-model="txt_filter"
                        :items="['All', true, false]"
                        class="mr-2 assign_select assign_select_v font_400"
                        dense
                        rounded
                      />
                    </td>
                    <td></td>
                    <td v-if="selected_table == 1"></td>
                  </tr>
                </template>

                <template v-slot:[`item.level`]="{ item }">
                  <v-icon
                    v-if="item.level == 'NOTICE'"
                    class="ml-4"
                    color="oc_green"
                    >mdi-information-outline</v-icon
                  >
                  <v-icon
                    v-else-if="item.level == 'WARNING'"
                    class="ml-4"
                    color="oc_orange"
                    >mdi-shield-alert-outline</v-icon
                  >
                  <v-icon
                    v-else-if="item.level == 'ALERT'"
                    class="ml-4"
                    color="oc_red"
                    >mdi-alert-outline</v-icon
                  >
                </template>
                <template v-slot:[`item.image`]="{ item, index }">
                  <v-img
                    class="ma-1 notification_img"
                    style="border-radius: 12px"
                    aspect-ratio="1.7"
                    :src="cookie_bucket + item.media_url"
                    @click="open_image(item, index)"
                  />
                </template>
                <template v-slot:[`item.customer_id`]="{ item }">
                  <span v-if="item.customer">{{
                    (item.customer.first_name
                      ? item.customer.first_name + " "
                      : "") + item.customer.last_name
                  }}</span>
                </template>
                <template v-slot:[`item.camera_id`]="{ item }">
                  <span>{{ item.camera.name }}</span>
                </template>
                <template v-slot:[`item.datetime`]="{ item }">
                  <span
                    >{{
                      item.datetime
                        .substring(0, item.datetime.length - 3)
                        .replace("T", " ")
                    }}, {{ num_days(item.datetime) }}</span
                  >
                </template>
                <template v-slot:[`item.sent_email`]="{ item }">
                  <v-icon v-if="item.sent_datetime_sendgrid" color="oc_green"
                    >mdi-check-circle-outline</v-icon
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.sent_text`]="{ item }">
                  <v-icon v-if="item.sent_datetime_twilio" color="oc_green"
                    >mdi-check-circle-outline</v-icon
                  >
                  <span v-else>-</span>
                </template>

                <template v-slot:[`item.internal_feedback_datetime`]="{ item }">
                  <span v-if="item.internal_feedback_datetime"
                    >{{
                      item.internal_feedback_datetime
                        .substring(
                          0,
                          item.internal_feedback_datetime.length - 3
                        )
                        .replace("T", " ")
                    }}, {{ num_days(item.internal_feedback_datetime) }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.internal_feedback_verdict`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'internal_feedback_verdict')"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.internal_feedback_verdict)"
                  >
                    {{ item.internal_feedback_verdict }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="[{ text: 'Correct', value: null }, 'Wrong']"
                        class="mt-4"
                        outlined
                        dense
                        label="Verdict"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.internal_feedback_reason`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'internal_feedback_reason')"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.internal_feedback_reason)"
                  >
                    {{ item.internal_feedback_reason }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :items="[
                          'Wrong PGIE',
                          'Wrong body part',
                          'Wrong tail classification',
                          'Bathroom break',
                        ]"
                        class="mt-4"
                        outlined
                        dense
                        label="Reason"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.internal_feedback_comments`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item.id, 'internal_feedback_comments')"
                    @cancel="cancel_edit"
                    large
                    @open="open_field(item.internal_feedback_comments)"
                  >
                    {{ item.internal_feedback_comments }}
                    <template v-slot:input>
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="edit_value"
                        label="Comment"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
              <v-row v-if="selected_table == 3">
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_type"
                    :timeframe="'1day'"
                    title="By Type"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_customer"
                    :timeframe="'1day'"
                    title="By Customer"
                  />
                </v-col>
                <v-col cols="12">
                  <chart_summary_media
                    v-if="analytics_data"
                    :labels="analytics_data.labels"
                    :datasets="analytics_data.datasets_sent"
                    :timeframe="'1day'"
                    title="By Send Type"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <image-card :noFilter="true"></image-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import CustomerFilter from "../components/CustomerFilter.vue";
import ImageCard from "../components/ImageCard.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
import { eventBus } from "../main";
import chart_summary_media from "../components/summary_media/chart_summary_media.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerFilter,
    ImageCard,
    CameraAutoComplete,
    chart_summary_media,
  },
  data() {
    return {
      search: "",
      loading: true,
      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      datetime_menu: false,

      selected_date: false,
      selected_camera: null,

      selected_table: 0,
      level_filter: null,
      level_items: [
        { value: null, text: "All" },
        { value: "INFO", text: "Info" },
        { value: "WARNING", text: "Warning" },
        { value: "ALERT", text: "Alert" },
      ],

      options: { sortBy: ["datetime"], sortDesc: [true], limit: 10, offset: 0 },

      level: [null, "INFO", "WARNING", "ALERT"],

      fullscreen_img: false,

      selected_image_index: false,
      selected_customer: "All Customers",
      email_filter: "All",
      txt_filter: "All",

      feedback_received_filter: "All",
      feedback_positive_filter: "All",
      editable: false,

      edit_value: null,

      analytics_datetime_menu: false,
      analytics_start_date: moment().subtract(21, "day").format("YYYY-MM-DD"),
    };
  },
  created() {
    this.$store.dispatch("DDB_GET_NOTIFICATION_ANALYTICS", {
      date_start: this.analytics_start_date,
      date_end: this.todays_date,
      customer: null,
    });
    eventBus.$on("nextImageNotifications", (index) => {
      index = index + 1;
      if (index >= this.notifications.length) {
        index = 0;
      }
      let item = this.notifications[index];
      var params = {
        isAnalytics: true,
        imagesLength: this.notifications.length,
        sourceComponent: "Notifications",
        image_path: item.media_url
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        // image_time: moment(item.datetime).format("YYYY-MM-DD HH:mm:ss"),
        image_time: this.convertTo12HourFormat(item.datetime),
        camera_id: item.camera_id,
        image_index: index,
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        bbox_list: item.bbox
          ? // item.species_label !== undefined &&
            // item.species_conf !== undefined
            [
              {
                x1: item.bbox?.x,
                y1: item.bbox?.y,
                w: item.bbox?.w,
                h: item.bbox?.h,
                // type: item.species_label,
                // conf: item.species_conf,
              },
            ]
          : undefined,
        body_parts_list: [
          ...(item.tail_bbox ? [{ bbox: item.tail_bbox, label: "tail" }] : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    });
    eventBus.$on("previousImageNotifications", (index) => {
      index = index - 1;
      if (index < 0) {
        index = this.notifications.length - 1;
      }
      let item = this.notifications[index];
      var params = {
        isAnalytics: true,
        imagesLength: this.notifications.length,
        sourceComponent: "Notifications",
        image_path: item.media_url
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        image_time: this.convertTo12HourFormat(item.datetime),
        // image_time: moment(item.datetime).format("YYYY-MM-DD HH:mm:ss"),
        camera_id: item.camera_id,
        image_index: index,
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        bbox_list: item.bbox
          ? [
              {
                x1: item.bbox?.x,
                y1: item.bbox?.y,
                w: item.bbox?.w,
                h: item.bbox?.h,
                // type: item.species_label,
                // conf: item.species_conf,
              },
            ]
          : undefined,
        body_parts_list: [
          ...(item.tail_bbox ? [{ bbox: item.tail_bbox, label: "tail" }] : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    });
  },
  methods: {
    num_days(date) {
      let days = moment().diff(moment(date), "days");
      return days + " day" + (days != 1 ? "s" : "");
    },
    async save_record(id, field) {
      try {
        var set_params = {};

        if (field == "internal_feedback_reason") {
          set_params.internal_feedback_verdict = "Wrong";
        } else if (field == "internal_feedback_verdict" && !this.edit_value) {
          set_params.internal_feedback_reason = "";
        }

        set_params[field] = this.edit_value;
        set_params.internal_feedback_datetime = moment().format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        set_params.internal_feedback_user_id =
          this.$store.getters.getUser.username;
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_notification_by_pk,
            variables: {
              pk_columns: { id: id },
              _set: set_params,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.data.errors) {
          throw new Error("GraphQL error: " + response.data.errors[0]);
        }

        this.refresh();
      } catch (err) {
        console.log(err);
      }
    },
    open_field(value) {
      this.edit_value = value;
    },
    cancel_edit() {
      this.edit_value = null;
    },
    convertTo12HourFormat(timeString) {
      const date = new Date(timeString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      const day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;

      hours = hours ? hours : 12;

      const hoursStr = hours.toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hoursStr}:${minutes}:${seconds} ${ampm}`;
    },
    open_image(item, index) {
      var params = {
        isAnalytics: true,
        imagesLength: this.notifications.length,
        sourceComponent: "Notifications",
        image_path: item.media_url
          .replace("_event.jpg", "")
          .replace("_annotated", ""),
        // image_time: moment(item.datetime).format("YYYY-MM-DD HH:mm:ss"),
        image_time: this.convertTo12HourFormat(item.datetime),
        camera_id: item.camera_id,
        image_index: index,
        customer_information:
          item.customer.first_name + " " + item.customer.last_name,
        bbox_list: item.bbox
          ? //&& item.species_label !== undefined &&
            // item.species_conf !== undefined
            [
              {
                x1: item.bbox?.x,
                y1: item.bbox?.y,
                w: item.bbox?.w,
                h: item.bbox?.h,
                // type: item.species_label,
                // conf: item.species_conf,
              },
            ]
          : undefined,
        body_parts_list: [
          ...(item.tail_bbox ? [{ bbox: item.tail_bbox, label: "tail" }] : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ],

        classificationInfo:
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
            ? [
                {
                  label1: item.contraction_label,
                  conf1: item.contraction_conf,
                  label2: item.contraction_label_2,
                  conf2: item.contraction_conf_2,
                },
              ]
            : undefined,
      };
      eventBus.$emit("open_dialog_images", params);
    },
    nav_to_table(device_id) {
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id,
        },
      });
    },

    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    refresh() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_NOTIFICATIONS", this.query_params)
        .then(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    analytics_data() {
      let type = this.$store.getters.getNotificationAnalytics.type;
      let customer = this.$store.getters.getNotificationAnalytics.customer;
      let sent = this.$store.getters.getNotificationAnalytics.sent;
      var labels = [];
      var datasets_type = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "General",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Calving",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Chin Resting",
        },
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "In Heat",
        },
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Mounting",
        },
        {
          data: [],
          backgroundColor: "#093D72",
          borderColor: "#fff",
          label: "Predator",
        },
      ];
      var datasets_customer = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "",
        },
        {
          data: [],
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "",
        },
      ];
      var datasets_sent = [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Twilio",
        },
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Sengrid",
        },
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Both",
        },
      ];
      if (type.length) {
        for (const i in type) {
          labels.push(type[i].date);
          datasets_type[0].data.push(type[i].general);
          datasets_type[1].data.push(type[i].calving);
          datasets_type[2].data.push(type[i].chin_resting);
          datasets_type[3].data.push(type[i].in_heat);
          datasets_type[4].data.push(type[i].mounting);
          datasets_type[5].data.push(type[i].predator);

          datasets_customer[0].data.push(customer[i].customer1_count);
          datasets_customer[1].data.push(customer[i].customer2_count);
          datasets_customer[2].data.push(customer[i].customer3_count);
          datasets_customer[3].data.push(customer[i].customer4_count);
          datasets_customer[4].data.push(customer[i].customer5_count);
          datasets_customer[0].label = customer[i].customer1_name;
          datasets_customer[1].label = customer[i].customer2_name;
          datasets_customer[2].label = customer[i].customer3_name;
          datasets_customer[3].label = customer[i].customer4_name;
          datasets_customer[4].label = customer[i].customer5_name;

          datasets_sent[0].data.push(sent[i].twilio_count);
          datasets_sent[1].data.push(sent[i].sendgrid_count);
          datasets_sent[2].data.push(sent[i].both_count);
        }

        return {
          datasets_type: datasets_type,
          datasets_customer: datasets_customer,
          datasets_sent: datasets_sent,
          labels: labels,
        };
      }
      return false;
    },
    selected_headers() {
      if (this.selected_table == 2) {
        return this.headers_user_feedback;
      } else if (this.selected_table == 1) {
        return this.headers_internal_feedback;
      }
      return this.headers_general;
    },
    notification_count() {
      return this.$store.getters.getNotificationCount;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      return {
        sort: this.options.sortBy ? this.options.sortBy[0] : "datetime",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        search: this.search,
        date: this.selected_date,
        filter: {
          level: this.level_filter,
          camera: this.selected_camera,
          customer_id: this.selected_customer,
          email_filter: this.email_filter,
          txt_filter: this.txt_filter,
          feedback_received_filter: this.feedback_received_filter,
          feedback_positive_filter: this.feedback_positive_filter,
        },
      };
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },

    headers_general() {
      return [
        {
          text: "Level",
          value: "level",
          width: "80px",
          sortable: false,
        },
        { text: "", value: "image", width: "200px" },
        {
          text: "Time",
          value: "datetime",
          width: "200px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "240px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Customer",
          value: "customer_id",
          width: "200px",
        },
        {
          text: "Title",
          value: "title",
          width: "180px",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Email",
          value: "sent_email",
          width: "90px",
          sortable: false,
        },
        {
          text: "TXT",
          value: "sent_text",
          width: "90px",
          sortable: false,
        },
      ];
    },

    headers_user_feedback() {
      return [
        {
          text: "Level",
          value: "level",
          width: "80px",
          sortable: false,
        },
        { text: "", value: "image", width: "200px" },
        {
          text: "Description",
          value: "description",
          width: "600px",
        },
        {
          text: "Received",
          value: "feedback_received",
          width: "80px",
          sortable: false,
        },
        {
          text: "Positive",
          value: "feedback_positive",
          width: "80px",
          sortable: false,
        },
        {
          text: "Reason",
          value: "internal_feedback_reason",
          width: "180px",
          sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Datetime",
          value: "feedback_datetime",
          width: "180px",
          sortable: false,
        },
        {
          text: "Comment",
          value: "feedback_comments",
          sortable: false,
        },
      ];
    },

    headers_internal_feedback() {
      return [
        {
          text: "Level",
          value: "level",
          width: "80px",
          sortable: false,
        },
        { text: "", value: "image", width: "200px" },
        {
          text: "Datetime",
          value: "internal_feedback_datetime",
          width: "200px",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",
          width: "600px",
        },
        {
          text: "Verdict",
          value: "internal_feedback_verdict",
          width: "140px",
          sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reason",
          value: "internal_feedback_reason",
          width: "180px",
          sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Comment",
          value: "internal_feedback_comments",
          sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
      ];
    },

    notifications() {
      return this.$store.getters.getNotifications
        ? this.$store.getters.getNotifications
        : [];
    },
  },

  watch: {
    analytics_start_date() {
      this.$store.dispatch("DDB_GET_NOTIFICATION_ANALYTICS", {
        date_start: this.analytics_start_date,
        date_end: this.todays_date,
        customer:
          this.selected_customer != "All Customers"
            ? this.selected_customer
            : null,
      });
    },
    selected_customer() {
      this.$store.dispatch("DDB_GET_NOTIFICATION_ANALYTICS", {
        date_start: this.analytics_start_date,
        date_end: this.todays_date,
        customer:
          this.selected_customer != "All Customers"
            ? this.selected_customer
            : null,
      });
    },
    date() {
      var selected_date = moment(this.date, "YYYY-MM-DDTHH").format(
        "YYYY-MM-DDTHH"
      );
      if (selected_date.slice(8, 10) != this.date.slice(8, 10)) {
        selected_date = moment(selected_date, "YYYY-MM-DDTHH")
          .add(1, "days")
          .format("YYYY-MM-DDTHH");
      }
      this.selected_date = selected_date;
    },

    query_params() {
      if (this.query_params) {
        this.loading = true;
        this.$store
          .dispatch("DDB_GET_NOTIFICATIONS", this.query_params)
          .then(() => {
            this.loading = false;
          });
      }
    },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.notification_img:hover {
  transform: scale(1.4);
  z-index: 500;
}
</style>
