var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),_c('v-container',[(_vm.videos.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"nav_btn mb_hide_vid",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index != 0 && !_vm.betsy)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/video/' +
          _vm.videos[_vm.index - 1].video_thumbnail.replaceAll('/', '+') +
          '&' +
          _vm.videos[_vm.index - 1].video_duration +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page +
          '&' +
          _vm.videos[_vm.index - 1].camera.camera_name +
          '&' +
          _vm.videos[_vm.index - 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):(_vm.index != 0 && _vm.betsy)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/video/' +
          _vm.get_url(
            _vm.videos[_vm.index - 1].video_thumbnail,
            _vm.videos[_vm.index - 1].camera_id
          ).replaceAll('/', '+') +
          '&' +
          _vm.videos[_vm.index - 1].video_duration +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page +
          '&' +
          _vm.videos[_vm.index - 1].camera.camera_name +
          '&' +
          _vm.videos[_vm.index - 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-col',{attrs:{"xl":"10","lg":"10"}},[_c('v-container',[_c('div',[_c('v-row',[_c('v-btn',{staticClass:"filter_btn ml-3 mb-3",attrs:{"to":'/' + _vm.prev_page.replaceAll('+', '/').replaceAll('*', '+'),"large":""}},[_vm._v("back ")]),_c('v-spacer'),_c('span',{staticClass:"pt-2 mb_hide_vid"},[_vm._v(_vm._s(_vm.cam_name))]),_c('v-spacer'),_c('span',{staticClass:"mr-3 pt-2 mb_hide_vid"},[_vm._v(_vm._s(_vm.date.split(" ")[0]))])],1),_c('v-row',{staticClass:"mb_display_vid"},[_c('span',{staticClass:"pt-2 ml-3"},[_vm._v(_vm._s(_vm.cam_name))]),_c('v-spacer'),_c('span',{staticClass:"mr-3 pt-2"},[_vm._v(_vm._s(_vm.date.split(" ")[0]))])],1),_c('v-row',{staticClass:"mb-2 pl-3"},_vm._l((_vm.videos[_vm.index].appearances.items),function(i){return _c('span',{key:i.livestock_drop_tag},[_c('v-chip',{staticClass:"mr-3",on:{"click":function($event){return _vm.select_tag(i.appearance_start[0])}}},[_vm._v(_vm._s(i.livestock.items[0].livestock_drop_tag))])],1)}),0),_c('div',[_c('video-player',{staticClass:"vjs-big-play-centered",attrs:{"id":"video","options":{
                autoplay: true,
                controls: true,
                fluid: true,
              }}})],1)],1)])],1),_c('v-col',{staticClass:"nav_btn mb_hide_vid",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index < _vm.videos.length - 1 && !_vm.betsy)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/video/' +
          _vm.videos[_vm.index + 1].video_thumbnail.replaceAll('/', '+') +
          '&' +
          _vm.videos[_vm.index + 1].video_duration +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page +
          '&' +
          _vm.videos[_vm.index + 1].camera.camera_name +
          '&' +
          _vm.videos[_vm.index + 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.index < _vm.videos.length - 1 && _vm.betsy)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/video/' +
          _vm.get_url(
            _vm.videos[_vm.index + 1].video_thumbnail,
            _vm.videos[_vm.index + 1].camera_id
          ).replaceAll('/', '+') +
          '&' +
          _vm.videos[_vm.index + 1].video_duration +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page +
          '&' +
          _vm.videos[_vm.index + 1].camera.camera_name +
          '&' +
          _vm.videos[_vm.index + 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1):_vm._e(),(_vm.videos.length > 0)?_c('v-row',{staticClass:"mb_display_vid",attrs:{"no-gutters":""}},[(_vm.index != undefined)?_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index != 0 && !_vm.betsy)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/video/' +
            _vm.videos[_vm.index - 1].video_thumbnail.replaceAll('/', '+') +
            '&' +
            _vm.videos[_vm.index - 1].video_duration +
            '&' +
            _vm.sort_by +
            '&' +
            _vm.sort_desc +
            '&' +
            _vm.prev_page +
            '&' +
            _vm.videos[_vm.index - 1].camera.camera_name +
            '&' +
            _vm.videos[_vm.index - 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):(_vm.index != 0 && _vm.betsy)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/video/' +
            _vm.get_url(
              _vm.videos[_vm.index - 1].video_thumbnail,
              _vm.videos[_vm.index - 1].camera_id
            ).replaceAll('/', '+') +
            '&' +
            _vm.videos[_vm.index - 1].video_duration +
            '&' +
            _vm.sort_by +
            '&' +
            _vm.sort_desc +
            '&' +
            _vm.prev_page +
            '&' +
            _vm.videos[_vm.index - 1].camera.camera_name +
            '&' +
            _vm.videos[_vm.index - 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index < _vm.videos.length - 1 && !_vm.betsy)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/video/' +
            _vm.videos[_vm.index + 1].video_thumbnail.replaceAll('/', '+') +
            '&' +
            _vm.videos[_vm.index + 1].video_duration +
            '&' +
            _vm.sort_by +
            '&' +
            _vm.sort_desc +
            '&' +
            _vm.prev_page +
            '&' +
            _vm.videos[_vm.index + 1].camera.camera_name +
            '&' +
            _vm.videos[_vm.index + 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.index < _vm.videos.length - 1 && _vm.betsy)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/video/' +
            _vm.get_url(
              _vm.videos[_vm.index + 1].video_thumbnail,
              _vm.videos[_vm.index + 1].camera_id
            ).replaceAll('/', '+') +
            '&' +
            _vm.videos[_vm.index + 1].video_duration +
            '&' +
            _vm.sort_by +
            '&' +
            _vm.sort_desc +
            '&' +
            _vm.prev_page +
            '&' +
            _vm.videos[_vm.index + 1].camera.camera_name +
            '&' +
            _vm.videos[_vm.index + 1].video_datetime,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }