var AWSCognito = require("amazon-cognito-identity-js");
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { TimestreamQueryClient } from "@aws-sdk/client-timestream-query";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { LambdaClient } from "@aws-sdk/client-lambda";
import { S3Client } from "@aws-sdk/client-s3";

/**
 * Initialize cognito user session
 * @returns {Promise} Promise that resolves login information for clients
 */
export function initializeSession() {
	var data = {
		UserPoolId: process.env.VUE_APP_USER_POOL_ID,
		ClientId: process.env.VUE_APP_WEB_CLIENT_ID,
	};
	var userPool = new AWSCognito.CognitoUserPool(data);
	var cognitoUser = userPool.getCurrentUser();
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.getSession(function (err, session) {
				if (err) {
					reject(err);
				} else {
					var session_key = `cognito-idp.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_USER_POOL_ID}`;
					resolve({ [session_key]: session.getIdToken().getJwtToken() });
				}
			});
		} else {
			reject("Current user is null when initializing session");
		}
	});
}

export function initializeCognitoIdentityProviderClient() {
	return initializeSession().then((logins) => {
		var client = new CognitoIdentityProviderClient({
			region: process.env.VUE_APP_AWS_REGION,
			credentials: fromCognitoIdentityPool({
				identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
				logins: logins,
				clientConfig: { region: process.env.VUE_APP_AWS_REGION },
			}),
		});
		return client;
	});
}

export function initializeTimestreamQueryClient() {
	return initializeSession().then((logins) => {
		var client = new TimestreamQueryClient({
			region: process.env.VUE_APP_AWS_REGION,
			credentials: fromCognitoIdentityPool({
				identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
				logins: logins,
				clientConfig: { region: process.env.VUE_APP_AWS_REGION },
			}),
		});
		return client;
	});
}

export function initializeLambdaClient() {
	return initializeSession().then((logins) => {
		var client = new LambdaClient({
			region: process.env.VUE_APP_AWS_REGION,
			credentials: fromCognitoIdentityPool({
				identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
				logins: logins,
				clientConfig: { region: process.env.VUE_APP_AWS_REGION },
			}),
		});
		return client;
	});
}

export function initializeS3Client() {
	return initializeSession().then((logins) => {
		var client = new S3Client({
			region: process.env.VUE_APP_AWS_REGION,
			credentials: fromCognitoIdentityPool({
				identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
				logins: logins,
				clientConfig: { region: process.env.VUE_APP_AWS_REGION },
			}),
		});
		return client;
	});
}