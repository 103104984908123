<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    min-width="750px"
    persistent
    no-click-animation
    @click:outside="close"
  >
    <v-card min-height="780">
      <v-card-title>
        <span>Filters </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-tabs v-model="selected_table" class="customer_tabs" show-arrows>
            <v-tab>PGIE</v-tab>
            <v-tab>Classifications</v-tab>
            <v-tab>Calving</v-tab>
            <v-tab>Estrus</v-tab>
            <v-tab>TAG</v-tab>
            <v-tab>Bodyparts</v-tab>
          </v-tabs>
        </v-row>
        <template v-if="selected_table == 0">
          <text-divider text="Time"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="filters.Time.hour_start"
                label="From"
                dense
                hide-details
                class="px-3 py-2"
                type="text"
                placeholder="HH"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="filters.Time.hour_end"
                label="To"
                dense
                hide-details
                class="px-3 py-2"
                type="text"
                placeholder="HH"
              ></v-text-field>
            </v-col>
          </v-row>
          <text-divider text="PGIE"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="all"
                v-model="class_label_all"
                @change="selectAll('class_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              v-for="classification in PGIE_type_list"
              :key="'PGIE_' + classification"
            >
              <v-checkbox
                v-model="filters.class_label[classification]"
                @change="select_filter('class_label', classification)"
                dense
                hide-details
                class="px-3 py-1"
              >
                <template v-slot:label>
                  <span
                    :style="
                      $store.getters.getBBOXColorCode[classification]
                        ? 'color: ' +
                          $store.getters.getBBOXColorCode[classification]
                            .strokeStyle
                        : ''
                    "
                    >{{ classification }}</span
                  >
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <comparison-field
                label="pgie confidence"
                v-model="filters.conf.class_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Species"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="all"
                v-model="species_label_all"
                @change="selectAll('species_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              v-for="classification in Species_type_list"
              :key="'Species_' + classification"
            >
              <v-checkbox
                v-model="filters.species_label[classification]"
                @change="select_filter('species_label', classification)"
                dense
                hide-details
                class="px-3 py-1"
              >
                <template v-slot:label>
                  <span
                    :style="
                      $store.getters.getBBOXColorCode[classification]
                        ? 'color: ' +
                          $store.getters.getBBOXColorCode[classification]
                            .strokeStyle
                        : ''
                    "
                    >{{ classification }}</span
                  >
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <comparison-field
                label="species confidence"
                v-model="filters.conf.species_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Filtering by Ratio"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <comparison-field
                label="bbox ratio"
                v-model="filters.conf.bbox_ratio"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
            <v-col cols="12" sm="3">
              <comparison-field
                label="tail ratio"
                v-model="filters.conf.tail_ratio"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Predator"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Predator - Coyote"
                v-model="filters.breed_label['coyote']"
                @change="change_predator('canine')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- <text-divider text="Vehicle Type"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="all"
                v-model="Vehicle_Type_all"
                @change="selectAll('Vehicle_Type')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              v-for="classification in Vehicle_type_list"
              :key="'Vehicle_Type_' + classification"
            >
              <v-checkbox
                v-model="filters.Vehicle_Type[classification]"
                @change="select_filter('Vehicle_Type', classification)"
                dense
                hide-details
                class="px-3 py-1"
              >
                <template v-slot:label>
                  <span
                    >{{ classification }}</span
                  >
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <text-divider text="Vehicle Make"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="all"
                v-model="Vehicle_Make_all"
                @change="selectAll('Vehicle_Make')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              v-for="classification in Vehicle_make_list"
              :key="'Vehicle_Make_' + classification"
            >
              <v-checkbox
                v-model="filters.Vehicle_Make[classification]"
                @change="select_filter('Vehicle_Make', classification)"
                dense
                hide-details
                class="px-3 py-1"
              >
                <template v-slot:label>
                  <span
                    >{{ classification }}</span
                  >
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <text-divider text="Human Pose"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="all"
                v-model="Human_Pose_all"
                @change="selectAll('Human_Pose')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              v-for="classification in Human_pose_list"
              :key="'Human_Pose_' + classification"
            >
              <v-checkbox
                v-model="filters.Human_Pose[classification]"
                @change="select_filter('Human_Pose', classification)"
                dense
                hide-details
                class="px-3 py-1"
              >
                <template v-slot:label>
                  <span
                    >{{ classification }}</span
                  >
                </template>
              </v-checkbox>
            </v-col>
          </v-row> -->
        </template>
        <template v-if="selected_table == 1">
          <text-divider text="Activity"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Climbing Stairs"
                v-model="filters.activity_label['climbing_stairs']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Digging"
                v-model="filters.activity_label['digging']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Lying Down"
                v-model="filters.activity_label['lying_down']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Playing"
                v-model="filters.activity_label['playing']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Running"
                v-model="filters.activity_label['running']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Sitting"
                v-model="filters.activity_label['sitting']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Sleeping"
                v-model="filters.activity_label['sleeping']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Standing/Walking"
                v-model="filters.activity_label['standing_walking']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Swimming"
                v-model="filters.activity_label['swimming']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Walking"
                v-model="filters.activity_label['walking']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="Null"
                v-model="filters.activity_label['null']"
                @change="select_filter('activity_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <comparison-field
                label="activity confidence"
                v-model="filters.conf.activity_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Age"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Adult"
                v-model="filters.age_label['adult']"
                @change="select_filter('age_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Calf"
                v-model="filters.age_label['calf']"
                @change="select_filter('age_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.age_label['null']"
                @change="select_filter('age_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="age confidence"
                v-model="filters.conf.age_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="selected_table == 2">
          <text-divider text="Calving"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Calving"
                v-model="filters.calving_label.calving"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not Calving"
                v-model="filters.calving_label.not_calving"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="calving confidence"
                v-model="filters.conf.calving_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Contraction"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Tail Down"
                v-model="filters.contraction_label.tail_down"
                @change="select_filter('contraction_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Tail Up"
                v-model="filters.contraction_label.tail_up"
                @change="select_filter('contraction_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.contraction_label.null"
                @change="select_filter('contraction_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="contraction classification confidence"
                v-model="filters.conf.contraction_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Contraction_v2 "></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Tail Down"
                v-model="filters.contraction_label_2.tail_down"
                @change="select_filter('contraction_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Tail Up"
                v-model="filters.contraction_label_2.tail_up"
                @change="select_filter('contraction_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.contraction_label_2.null"
                @change="select_filter('contraction_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="contraction classification confidence"
                v-model="filters.conf.contraction_conf_2"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Suckling"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Suckling"
                v-model="filters.suckling_label.suckling"
                @change="select_filter('suckling_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not Suckling"
                v-model="filters.suckling_label.not_suckling"
                @change="select_filter('suckling_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.suckling_label.null"
                @change="select_filter('suckling_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="suckling confidence"
                v-model="filters.conf.suckling_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Delivery"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Delivery"
                v-model="filters.delivery_label.delivery"
                @change="select_filter('delivery_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not Delivery"
                v-model="filters.delivery_label.not_delivering"
                @change="select_filter('delivery_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.delivery_label.null"
                @change="select_filter('delivery_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="delivery confidence"
                v-model="filters.conf.delivery_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="selected_table == 3">
          <text-divider text="Estrus Analytics"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Mounting"
                v-model="filters.estrus.mounting"
                @change="select_filter('estrus')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Chin Resting"
                v-model="filters.estrus.resting_chin"
                @change="select_filter('estrus')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="In Heat"
                v-model="filters.estrus.in_heat"
                @change="select_filter('estrus')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="estrus confidence"
                v-model="filters.estrus.class_conf"
                @change="select_filter('estrus')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Mounting"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Mounting"
                v-model="filters.mounting_label.mounting"
                @change="select_filter('mounting_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not Mounting"
                v-model="filters.mounting_label.not_mounting"
                @change="select_filter('mounting_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.mounting_label.null"
                @change="select_filter('mounting_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="mounting confidence"
                v-model="filters.conf.mounting_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Mounting_v2"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Mounting"
                v-model="filters.mounting_label_2.mounting"
                @change="select_filter('mounting_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not Mounting"
                v-model="filters.mounting_label_2.not_mounting"
                @change="select_filter('mounting_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.mounting_label_2.null"
                @change="select_filter('mounting_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="mounting confidence"
                v-model="filters.conf.mounting_conf_2"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Chin Resting"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Chin Resting"
                v-model="filters.chinrest_label.resting_chin"
                @change="select_filter('chinrest_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not CR"
                v-model="filters.chinrest_label.not_resting_chin"
                @change="select_filter('chinrest_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.chinrest_label.null"
                @change="select_filter('chinrest_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="chin resting confidence"
                v-model="filters.conf.chinrest_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Chin Resting_v2"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Chin Resting"
                v-model="filters.chinrest_label_2.resting_chin"
                @change="select_filter('chinrest_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not CR"
                v-model="filters.chinrest_label_2.not_resting_chin"
                @change="select_filter('chinrest_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.chinrest_label_2.null"
                @change="select_filter('chinrest_label_2')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="chin resting confidence"
                v-model="filters.conf.chinrest_conf_2"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Standing Heat"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="In Heat"
                v-model="filters.heat_detection_label.in_heat"
                @change="select_filter('heat_detection_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Not In Heat"
                v-model="filters.heat_detection_label.not_in_heat"
                @change="select_filter('heat_detection_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.heat_detection_label.null"
                @change="select_filter('heat_detection_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="standing heat confidence"
                v-model="filters.conf.heat_detection_conf"
                @change="select_filter('conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="selected_table == 4">
          <text-divider text="TAG"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="TAG 1"
                v-model="filters.body_parts['tag_1_label']"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                label="TAG 2"
                v-model="filters.body_parts['tag_2_label']"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <text-divider text="Search TAG1"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="filters.Tag1.tag_1_id_label"
                label="TAG 1"
                dense
                hide-details
                class="px-3 py-2"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <comparison-field
                label="TAG 1 confidence"
                v-model="filters.Tag1.tag_1_id_conf"
                @change="select_filter('Tag1')"
                class="px-3 py-2"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Search TAG2"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="filters.Tag2.tag_2_id_label"
                label="TAG 2"
                dense
                hide-details
                class="px-3 py-2"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <comparison-field
                label="TAG 2 confidence"
                v-model="filters.Tag2.tag_2_id_conf"
                @change="select_filter('Tag2')"
                class="px-3 py-2"
              >
              </comparison-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="selected_table == 5">
          <text-divider text="Head"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Head"
                v-model="filters.body_parts.head_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="head confidence"
                v-model="filters.body_parts_conf.head_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Tail"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Tail"
                v-model="filters.body_parts.tail_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="tail confidence"
                v-model="filters.body_parts_conf.tail_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>

          <text-divider text="Foot 1"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Foot 1"
                v-model="filters.body_parts.foot_1_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="foot 1 confidence"
                v-model="filters.body_parts_conf.foot_1_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Foot 2"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Foot 2"
                v-model="filters.body_parts.foot_2_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="foot 2 confidence"
                v-model="filters.body_parts_conf.foot_2_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Foot 3"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Foot 3"
                v-model="filters.body_parts.foot_3_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="foot 3 confidence"
                v-model="filters.body_parts_conf.foot_3_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Foot 4"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Foot 4"
                v-model="filters.body_parts.foot_4_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="foot 4 confidence"
                v-model="filters.body_parts_conf.foot_4_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Joint 1"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Joint 1"
                v-model="filters.body_parts.joint_1_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="joint 1 confidence"
                v-model="filters.body_parts_conf.joint_1_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Joint 2"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Joint 2"
                v-model="filters.body_parts.joint_2_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="joint 2 confidence"
                v-model="filters.body_parts_conf.joint_2_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Joint 3"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Joint 3"
                v-model="filters.body_parts.joint_3_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('calving_label')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="joint 3 confidence"
                v-model="filters.body_parts_conf.joint_3_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
          <text-divider text="Joint 4"></text-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="Joint 4"
                v-model="filters.body_parts.joint_4_label"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2">
              <v-checkbox
                label="Null"
                v-model="filters.calving_label.null"
                @change="select_filter('body_parts')"
                dense
                hide-details
                class="px-3 py-1"
              >
              </v-checkbox>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <comparison-field
                label="joint 4 confidence"
                v-model="filters.body_parts_conf.joint_4_conf"
                @change="select_filter('body_parts_conf')"
                class="px-3 py-1"
              >
              </comparison-field>
            </v-col>
          </v-row>
        </template>

        <!-- <template v-if="selected_table == 2">
          <text-divider text="Model Version"></text-divider>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
              v-for="model in model_version_list"
              :key="'Model_' + model"
            >
              <v-text-field
                v-model="model_versions[model]"
                :label="model"
                dense
                hide-details
                class="px-3 py-2"
                type="Number"
              ></v-text-field>
            </v-col>
          </v-row>
        </template> -->
      </v-card-text>
      <v-card-actions class="actions">
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../main";
import TextDivider from "./TextDivider.vue";
import ComparisonField from "./ComparisonField.vue";
import { createGeneralFilter } from "../utilities/GeneralFilterTemplate";

// @vuese
// @group Components
// The Timestream filter card for both images and videos
export default {
  components: {
    TextDivider,
    ComparisonField,
  },
  data() {
    return {
      dialog: false,
      filters: createGeneralFilter(this.$store.getters),
      date_range: [],
      model_versions: {},
      selected_table: 0,
      species_label_all: false,
      class_label_all: false,
      Vehicle_Type_all: false,
      Vehicle_Make_all: false,
      Human_Pose_all: false,
    };
  },
  watch: {
    dialog() {
      this.$store.commit("SET_GENERAL_FILTER_DIALOG", this.dialog);
    },
  },
  methods: {
    /**
     *
     * @param {Object} params
     * @param {String} params.filters The image filter object
     * @param {String} params.date_range The date range object
     * @param {String} params.model_versions The model version object
     */
    async open(params) {
      this.filters = JSON.parse(JSON.stringify(params.filters));
      this.date_range = params.date_range;
      this.model_versions = JSON.parse(JSON.stringify(params.model_versions));
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      var params = {
        filters: this.filters,
        date_range: this.date_range,
        model_versions: this.model_versions,
      };
      eventBus.$emit("update_filters", params);
      this.close();
    },
    selectAll(type) {
      for (let key in this.filters[type]) {
        this.filters[type][key] = this[`${type}_all`];
      }
    },
    change_predator(model) {
      if (this.filters.breed_label["coyote"]) {
        this.filters.species_label[model] = true;
      } else {
        this.filters.species_label[model] = false;
      }
    },
    select_filter(model) {
      if (model === "estrus") {
        if (!this.filters.estrus.mounting) {
          delete this.filters.estrus.mounting;
        }
        if (!this.filters.estrus.resting_chin) {
          delete this.filters.estrus.resting_chin;
        }
        if (!this.filters.estrus.in_heat) {
          delete this.filters.estrus.in_heat;
        }
        if (
          !this.filters.estrus.class_conf ||
          this.filters.estrus.class_conf == null || this.filters.estrus.class_conf === ""
        ) {
          delete this.filters.estrus.class_conf;
        }
      }

      var models = [
        "species_label",
        "class_label",
        "Vehicle_Type",
        "Vehicle_Make",
        "Human_Pose",
      ];
      if (models.includes(model)) {
        this[`${model}_all`] = true;
        for (let key in this.filters[model]) {
          if (!this.filters[model][key]) {
            this[`${model}_all`] = false;
          }
        }
      }
    },
  },
  computed: {
    date_range_text() {
      var temp_arr = [...this.date_range];
      return temp_arr.sort().join(" ~ ");
    },
    PGIE_type_list() {
      return this.$store.getters.getPGIETypeList;
    },
    Species_type_list() {
      return this.$store.getters.getSpeciesTypeList;
    },
    SGIE_type_list() {
      return this.$store.getters.getSGIETypeList;
    },
    Vehicle_type_list() {
      return this.$store.getters.getVehicleTypeList;
    },
    Vehicle_make_list() {
      return this.$store.getters.getVehicleMakeList;
    },
    Human_pose_list() {
      return this.$store.getters.getHumanPoseList;
    },
    model_version_list() {
      return this.$store.getters.getModelVersionList;
    },
  },
  created() {
    eventBus.$on("open_dialog_filters", (params) => {
      this.open(params);
    });
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (this.dialog) {
        if (e.code == "ArrowLeft") {
          this.previousImage();
        }

        if (e.code == "ArrowRight") {
          this.nextImage();
        }
      }
    });
  },
};
</script>

<style scoped>
.actions {
  position: absolute;
  right: 0;
  bottom: 5px;
}
</style>
