<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
      <v-col cols="12" class="main_card_col">
        <v-card elevation="2">
          <v-card-title>
            <span class="ml-2">Herds</span>
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
          </v-card-title>
          <v-card-text class="text-center" v-if="selected_table == 0">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="herds"
              :items-per-page="100"
              class="elevation-1"
              dense
              hide-default-footer
              :search="search"
              :sort-by.sync="sort_by"
              :sort-desc.sync="sort_desc"
            >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-tabs class="table_tabs pt-1" v-model="selected_table">
                  <v-tab>General</v-tab>
                  <v-tab>Graphs</v-tab>
                </v-tabs>
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  class="table_pagination"
                  @update:options="updateOptions"
                  :items-per-page-options="[50, 100, 200]"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
              </template>
              <template v-slot:[`body.prepend`]>
                <tr class="mb_hidden">
                  <td class="select_width align_left">
                    <v-select
                      class="mt-n1"
                      :items="customers[1]"
                      v-model="customer"
                      dense
                      flat
                      hide-details
                    ></v-select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td></tr
              ></template>
              <template v-slot:[`item.herd_ocr_id`]="{ item }">
                <span v-for="id in item.herd_ocr_id" :key="id"
                  ><span
                  class="tag_link"
                    v-if="drop_tags2[id.toUpperCase()] != undefined"
                    @click="
                      redirect_to_video(
                        drop_tags2[id.toUpperCase()][item.herd_date].video,
                        item.herd_date,
                        drop_tags2[id.toUpperCase()][item.herd_date]
                          .appearance_start
                      )
                    "
                    >{{ id.toUpperCase() }}&nbsp; </span
                  ><span v-else>{{ id.toUpperCase() }}&nbsp; </span>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-center" v-if="selected_table == 1">
            <v-row>
              <v-tabs class="table_tabs pt-1" v-model="selected_table">
                <v-tab>General</v-tab>
                <v-tab>Graphs</v-tab>
              </v-tabs>
            </v-row>
            <v-card-text class="pt-0 pb-0 pl-3 pr-3">
              <v-row>
                <v-col cols="12">
                  <v-card
                    ><v-card-text
                      ><v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            Unique IDs Histogram
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div>
                        <herds-histogram v-if="herds" />
                      </div> </v-card-text
                  ></v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="grid_card"
                    ><v-card-text v-if="drop_tags2"
                      ><v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            ID Occurences
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div
                        v-for="(value, name) in drop_tags2"
                        :key="name"
                        class="id_grid_row"
                      >
                        <span>{{ name }}</span>
                        <div class="grid-container-70">
                          <div class="non_appeared">&nbsp;</div>
                          <div v-for="date in dates" :key="date">
                            <v-btn
                              v-if="value[date]"
                              class="appeared"
                              color="#ff9900"
                              @click="
                                redirect_to_video(
                                  value[date].video,
                                  date,
                                  value[date].appearance_start
                                )
                              "
                            >
                              &nbsp;
                            </v-btn>
                            <div v-else class="non_appeared">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                      <div class="id_grid_row">
                        <div class="grid-container-70">
                          <div class="vertical_text non_appeared">&nbsp;</div>
                          <div
                            v-for="date in dates"
                            :key="date"
                            class="vertical_text non_appeared"
                          >
                            {{ date }}
                          </div>
                        </div>
                      </div>
                    </v-card-text></v-card
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card-text>
        </v-card></v-col
      >
    </v-row></v-container>
    
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
//import { eventBus } from "../main.js";
import moment from "moment";
import Vue from "vue";
import herdsHistogram from "../components/herds_histogram.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    herdsHistogram,
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "customer.customer_name", width: "12%" },
        { text: "Date", value: "herd_date" },
        { text: "Unique IDs", value: "herd_ocr_id_count" },
        { text: "IDs", value: "herd_ocr_id", sortable: false },
      ],
      search: "",
      staging_data: [],
      production_data: [],
      loading: false,
      customer: "All",
      filter_params: {},
      camera: "All",
      video_duration: null,
      video_url: null,
      page: 1,
      sort_by: ["herd_date"],
      sort_desc: true,
      selected_table: 0,
    };
  },
  methods: {
    redirect_to_video(video, date_time, start) {
      Vue.prototype.$video_start = start;
      this.$router.push({
        name: "video",
        params: {
          url: video.video_thumbnail,
          duration: video.video_duration,
          sort_by: "video_datetime",
          sort_desc: "true",
          prev_page: "herds",
          cam_name: video.camera.camera_name,
          date: date_time,
        },
      });
    },
    sort_by_fix(sort_by) {
      if (sort_by.length != 0) {
        return sort_by[0];
      }
      return "default";
    },
  },
  computed: {
    // drop_tags() {
    //   if (this.dates) {
    //     let temp_tags = {};
    //     for (const i in this.$store.getters.getAnimals) {
    //       temp_tags[this.$store.getters.getAnimals[i].livestock_drop_tag] = {};
    //       for (const x in this.dates) {
    //         temp_tags[this.$store.getters.getAnimals[i].livestock_drop_tag][
    //           this.dates[x]
    //         ] = false;
    //         for (const y in this.herds) {
    //           if (
    //             this.herds[y].herd_date == this.dates[x] &&
    //             this.herds[y].herd_ocr_id.includes(
    //               this.$store.getters.getAnimals[
    //                 i
    //               ].livestock_drop_tag.toLowerCase()
    //             )
    //           ) {
    //             temp_tags[this.$store.getters.getAnimals[i].livestock_drop_tag][
    //               this.dates[x]
    //             ] = true;
    //           }
    //         }
    //       }
    //     }
    //     return temp_tags;
    //   } else {
    //     return false;
    //   }
    // },

    drop_tags2() {
      if (this.dates) {
        let temp_tags = {};
        for (const i in this.$store.getters.getAnimals) {
          temp_tags[this.$store.getters.getAnimals[i].livestock_drop_tag] = {};
          for (const n in this.$store.getters.getAnimals[i].appearances.items) {
            if (this.$store.getters.getAnimals[i].appearances.items[n]) {
              temp_tags[this.$store.getters.getAnimals[i].livestock_drop_tag][
                this.$store.getters.getAnimals[i].appearances.items[
                  n
                ].video_id.split(" ")[0]
              ] = this.$store.getters.getAnimals[i].appearances.items[n];
            }
          }
        }
        return temp_tags;
      } else {
        return false;
      }
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    // cameras() {
    //   if (this.$store.getters.getCameraObjects.length > 0) {
    //     var cameras = [{}, []];
    //     if (this.customer == "All") {
    //       for (const i in this.$store.getters.getCameraObjects) {
    //         cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
    //           this.$store.getters.getCameraObjects[i].id;
    //         cameras[1].push(
    //           this.$store.getters.getCameraObjects[i].camera_name +
    //             "  -  " +
    //             this.$store.getters.getCameraObjects[i].videos.items.length
    //         );
    //       }
    //     } else {
    //       for (const i in this.$store.getters.getCameraObjects) {
    //         if (
    //           this.$store.getters.getCameraObjects[i].owner ==
    //           this.$store.getters.getNameOwnerObject[
    //             this.customer.split("  -  ")[0]
    //           ]
    //         ) {
    //           cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
    //             this.$store.getters.getCameraObjects[i].id;
    //           cameras[1].push(
    //             this.$store.getters.getCameraObjects[i].camera_name +
    //               "  -  " +
    //               this.$store.getters.getCameraObjects[i].videos.items.length
    //           );
    //         }
    //       }
    //     }

    //     const sortAlphaNumCams = (a, b) =>
    //       a.localeCompare(b, "en", { numeric: true });
    //     cameras[1].sort(sortAlphaNumCams);
    //     cameras[1].unshift("All");

    //     return cameras;
    //   } else {
    //     return false;
    //   }
    // },
    dates() {
      let temp_dates = [];
      let min_tade = moment().subtract(68, "days");
      for (let i = 0; i < 69; i++) {
        let temp_date = min_tade.add(1, "days").format("YYYY-MM-DD");
        temp_dates.push(temp_date);
      }
      return temp_dates;
    },
    customers() {
      // var customers = [
      //   ...new Set(
      //     this.camera_objects.map((item) => item.customer.customer_name)
      //   ),
      // ];
      if (this.$store.getters.getCustomerObjects.length > 0) {
        var customers = [{}, []];
        for (const i in this.$store.getters.getCustomerObjects) {
          customers[0][
            this.$store.getters.getCustomerObjects[i].customer_name
          ] = this.$store.getters.getCustomerObjects[i].id;
          customers[1].push(
            this.$store.getters.getCustomerObjects[i].customer_name
          );
        }
        customers[1].unshift("All");

        return customers;
      } else {
        return false;
      }
    },
    users() {
      var owner_email_name = {};
      for (const i in this.$store.getters.getCustomerObjects) {
        owner_email_name[this.$store.getters.getCustomerObjects[i].owner] =
          this.$store.getters.getCustomerObjects[i].customer_name;
      }
      return owner_email_name;
    },
    herds() {
      return this.$store.getters.getHerds;
    },
  },
  created() {
    // eventBus.$on("show_all_videos", () => {
    //   this.show_one_video = false;
    // });
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    this.$store.dispatch("DDB_GET_ANIMALS", {});
    this.$store.dispatch("DDB_GET_HERDS", {});
  },
  watch: {
    sort_desc() {
      console.log(this.sort_by, this.sort_desc);
    },
    herds() {
      this.loading = false;
    },
    customer() {
      if (this.customer != "All") {
        //console.log(this.$store.getters.getNameOwnerObject)
        this.$store.dispatch("DDB_GET_HERDS", {
          filter: {
            customer_id: {
              eq: this.$store.getters.getNameOwnerObject[this.customer],
            },
          },
        });
      }
    },
    dialog() {
      if (this.dialog == false) {
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
.nav_btn {
  text-align: center;
}
.nav_icon {
  font-size: 60px !important;
}
#graph_div {
  position: relative;
  height: 215px;
}
.select_tag {
  max-width: 75px;
}
.hidden_btn {
  visibility: hidden;
}
#uc_label {
  margin-top: 180px;
  text-align: center;
}
.float_left > div > .v-slide-group__prev {
  display: none !important;
}
.unit_label {
  color: gray;
}
.select_tag {
  color: gray;
}
.select_tag > div > div > div > div > div {
  color: gray;
}
.grid-container-70 {
  display: grid;
  grid-template-columns: repeat(70, 1fr);
  width: 96%;
  float: right;
  background-color: gainsboro;
  grid-gap: 1px;
  min-width: 912px !important;
}
.grid-container-70 > div {
}
.appeared {
  background-color: #ff9900;
  height: 95% !important;
  width: 100% !important;
  box-shadow: none !important;
  min-width: 0 !important;
  min-height: 0 !important;
  vertical-align: top !important;
  border-radius: 0px;
  padding: 0px !important;
}
.non_appeared {
  background-color: rgb(255, 255, 255);
  height: 95%;
}
.vertical_text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 10px;
  height: 90px !important;
  width: 100%;
  line-height: 0.6rem !important;
}
.grid_card {
 padding-bottom: 20px;
  overflow-x: scroll;
}
.id_grid_row {
  text-align: left;
  min-width: 954px !important;
}

</style>