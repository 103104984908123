<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import { eventBus } from '../main.js';

// @vuese
// @group Components
// Old video player (deprecated)
export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        eventBus.$emit("player_ready")
        //console.log('onPlayerReady', this);
        //console.log('player source is:', this.currentSource().src);
      }
    );
 
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style src='../../node_modules/video.js/dist/video-js.css' lang="css" >
</style>
<style  lang="css" >
@import "../../node_modules/video.js/dist/video-js.css";

@import "../../node_modules/videojs-overlay/dist/videojs-overlay.css";

.vjs-watermark {
  position: absolute;
  display: inline;
  z-index: 2000;
}

.box {
  width: 100px;
  height: 50px;
  background: red;
  left: 0;
  top: 0;
}

.video-js .vjs-overlay-background {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0px;
  padding: 0px;
  width: 168px;
}

.video-js .vjs-overlay-bottom-right {
  right: 1em;
  z-index: 10;
}

/* a wrapper element that tracks the mouse vertically */
.vjs-thumbnail-holder {
  position: absolute;
  left: -1000px;
}

/* the thumbnail image itself */
.vjs-thumbnail {
  position: absolute;
  left: -141px !important;
  bottom: 1.3em;
  opacity: 0;
  transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -mz-transition: opacity 0.2s ease;
}

/* fade in the thumbnail when hovering over the progress bar */
/* .fake-active is needed for Android only. It's removed on touchend/touchecancel */
.vjs-progress-control:hover .vjs-thumbnail,
.vjs-progress-control.fake-active .vjs-thumbnail,
.vjs-progress-control:active .vjs-thumbnail {
  opacity: 1;
}

/* ... but hide the thumbnail when hovering directly over it */
.vjs-progress-control:hover .vjs-thumbnail:hover,
.vjs-progress-control:active .vjs-thumbnail:active {
  opacity: 0;
}
.video-js .vjs-big-play-button {
  font-size: 8em;
  line-height: 1.5em;
  color: #ff9900;
  height: 1.63332em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: none;
  background-color: unset;
  transition: all 0.4s;
}
.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -0.81666em;
  margin-left: -1.5em;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border: 0;
  background-color: unset;
  transition: none;
}

.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button,
.vjs-error .vjs-big-play-button {
  display: none;
}

.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause
  .vjs-big-play-button {
  display: block;
}
.video-js {
  /* font-size: 14px; */
  /* font-family: Merriweather; */
}
</style>

