<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row justify="center">
        <v-col xl="6" lg="8" md="10" sm="11" cols="12">
          <v-card elevation="2">
            <v-card-text v-if="user" class="text-center">
              <v-row
                ><v-col cols="12" class="text_field_col"
                  ><h2 data-cy="text_myAccount" style="color: #0090a4">
                    My Account
                  </h2>
                </v-col></v-row
              >
              <v-card>
                <v-card-text>
                  <v-row
                    ><v-tabs class="table_tabs" v-model="selected_tab">
                      <v-tab data-cy="btn_myAccount_userSettings"
                        >General</v-tab
                      >
                      <v-tab>Address</v-tab>
                      <v-tab>Social Media</v-tab>
                      <v-tab data-cy="btn_myAccount_password">Password</v-tab>
                      <v-tab>Notifications</v-tab>
                    </v-tabs></v-row
                  >
                  <v-form
                    v-model="isFormValid_general"
                    v-if="selected_tab == 0"
                  >
                    <v-row class="mt-1">
                      <v-col cols="6">
                        <v-row justify="center" class="mt-2 mb-2">
                          <v-avatar
                            size="150"
                            color="oc-grey"
                            class="image-border elevation-4"
                          >
                            <v-img
                              :src="customer_picture + '?dummy=' + dummy_nums"
                              @click="return_url"
                            >
                              <template v-slot:placeholder>
                                <!-- <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey darken-5"
                                ></v-progress-circular>
                              </v-row> -->
                                <v-avatar
                                  v-if="loading_image"
                                  size="150"
                                  color="primary"
                                  class="white--text text-h3 elevation-4"
                                >
                                  {{ userInitial }}
                                </v-avatar>
                                <v-progress-circular
                                  v-else
                                  style="
                                    height: 40px;
                                    mwidth: 40px;
                                    top: calc(50% - 20px);
                                  "
                                  indeterminate
                                  size="40"
                                  color="primary"
                                ></v-progress-circular>
                              </template>
                            </v-img>
                          </v-avatar>
                          <!-- <v-avatar
                          v-else
                          size="150"
                          color="primary"
                          class="white--text text-h3 elevation-4"
                        >
                          {{ userInitial }}
                        </v-avatar> -->

                          <v-dialog v-model="add_image_dialog" width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                small
                                class="ml-n12"
                                color="grey"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-camera</v-icon>
                              </v-btn>
                            </template>
                            <v-card class="text-center">
                              <v-card-text class="mt-4 pb-2 px-4">
                                <v-row>
                                  <v-toolbar elevation="0"
                                    ><h2 style="color: #0090a4">Add Image</h2>

                                    <v-spacer></v-spacer>
                                    <v-btn
                                      icon
                                      @click="add_image_dialog = false"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn></v-toolbar
                                  ></v-row
                                >
                                <v-file-input
                                  label="Upload image here"
                                  outlined
                                  prepend-icon="mdi-camera"
                                  v-model="image"
                                ></v-file-input>
                              </v-card-text>
                              <v-card-actions class="pt-0 pb-4">
                                <div style="width: 100%">
                                  <div style="float: right">
                                    <!-- <v-btn
                                    class="mx-1"
                                    data-cy="btn_image_delete"
                                  >
                                    Delete
                                  </v-btn> -->
                                    <v-btn
                                      class="ml-2"
                                      :disabled="!image"
                                      color="primary"
                                      st
                                      @click="save_image"
                                      data-cy="btn_image_save"
                                    >
                                      Save
                                    </v-btn>
                                  </div>
                                </div>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-row>

                        <span class="ml-2 mb-6">{{ user.email }}</span>
                      </v-col>
                      <v-col cols="6">
                        <v-row justify="center" class="mt-2 mb-2">
                          <v-avatar
                            size="150"
                            color="oc-grey"
                            class="image-border elevation-4"
                          >
                            <v-img
                              :src="company_image + '?dummy=' + dummy_nums_logo"
                              @click="return_url"
                            >
                              <template v-slot:placeholder>
                                <v-avatar
                                  v-if="!loading_company_image"
                                  size="150"
                                  color="primary"
                                  class="white--text elevation-4"
                                >
                                  Company Logo
                                </v-avatar>

                                <v-progress-circular
                                  v-else
                                  style="
                                    height: 40px;
                                    width: 40px;
                                    top: calc(50% - 20px);
                                  "
                                  indeterminate
                                  size="40"
                                  color="primary"
                                ></v-progress-circular>
                              </template>
                            </v-img>
                          </v-avatar>

                          <v-dialog v-model="add_logo_dialog" width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                small
                                class="ml-n12"
                                color="grey"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-camera</v-icon>
                              </v-btn>
                            </template>
                            <v-card class="text-center">
                              <v-card-text class="mt-4 pb-2 px-4">
                                <v-row>
                                  <v-toolbar elevation="0"
                                    ><h2 style="color: #0090a4">Add Image</h2>

                                    <v-spacer></v-spacer>
                                    <v-btn
                                      icon
                                      @click="add_logo_dialog = false"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn></v-toolbar
                                  ></v-row
                                >
                                <v-file-input
                                  label="Upload image here"
                                  outlined
                                  prepend-icon="mdi-camera"
                                  v-model="company_logo_image"
                                ></v-file-input>
                              </v-card-text>
                              <v-card-actions class="pt-0 pb-4">
                                <div style="width: 100%">
                                  <div style="float: right">
                                    <!-- <v-btn
                                    class="mx-1"
                                    data-cy="btn_image_delete"
                                  >
                                    Delete
                                  </v-btn> -->
                                    <v-btn
                                      class="ml-2"
                                      :disabled="!company_logo_image"
                                      color="primary"
                                      @click="save_company_image"
                                      data-cy="btn_image_save"
                                    >
                                      Save
                                    </v-btn>
                                  </div>
                                </div>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-row>

                        <span class="ml-2 mb-6"
                          >{{ customer.customer_company_name }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="First Name"
                              v-model="customer.customer_first_name"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Family Name*"
                              v-model="customer.customer_last_name"
                              outlined
                            >
                            </v-text-field>
                          </v-col> </v-row
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <PhoneMaskedField
                              label="Cell Phone"
                              :thick="true"
                              v-model="customer.customer_phone_cell"
                            ></PhoneMaskedField>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <PhoneMaskedField
                              label="Home Phone"
                              :thick="true"
                              v-model="customer.customer_phone_home"
                            ></PhoneMaskedField>
                          </v-col> </v-row
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Company Name"
                              v-model="customer.customer_company_name"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Occupation"
                              v-model="customer.customer_company_occupation"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Company Website"
                              v-model="customer.customer_company_website"
                              outlined
                            ></v-text-field>
                          </v-col> </v-row
                      ></v-col>
                    </v-row>
                  </v-form>
                  <v-form
                    v-model="isFormValid_address"
                    v-else-if="selected_tab == 1"
                  >
                    <v-row class="mt-1">
                      <v-col cols="12">
                        <h4 style="text-align: left" class="mb-6">
                          Mailing Address
                        </h4>
                        <v-row class="mb-n5">
                          <v-col cols="12" class="text_field_col">
                            <v-text-field
                              label="Address 1"
                              v-model="
                                customer.customer_mailing_address.address_1
                              "
                              outlined
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" class="text_field_col">
                            <v-text-field
                              label="Address 2"
                              v-model="
                                customer.customer_mailing_address.address_2
                              "
                              outlined
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            xl="4"
                            lg="4"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="City"
                              v-model="customer.customer_mailing_address.city"
                              outlined
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            xl="2"
                            lg="2"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-select
                              v-if="
                                customer.customer_mailing_address.country ==
                                'Canada'
                              "
                              v-model="
                                customer.customer_mailing_address.province
                              "
                              :items="$store.getters.getProvinces"
                              label="Province *"
                              outlined
                              :rules="[rules.required, rules.address]"
                            ></v-select>
                            <v-select
                              v-else-if="
                                customer.customer_mailing_address.country ==
                                'United States'
                              "
                              v-model="
                                customer.customer_mailing_address.province
                              "
                              :items="$store.getters.getStates"
                              label="State *"
                              outlined
                              :rules="[rules.required, rules.address]"
                            ></v-select>
                            <v-text-field
                              v-else
                              v-model="
                                customer.customer_mailing_address.province
                              "
                              label="Province *"
                              outlined
                              :rules="[rules.required, rules.address]"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="4"
                            lg="4"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-combobox
                              v-model="
                                customer.customer_mailing_address.country
                              "
                              label="Country *"
                              :items="countries"
                              :rules="[rules.required]"
                              outlined
                            ></v-combobox>
                          </v-col>

                          <v-col
                            xl="2"
                            lg="2"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <masked-field
                              v-if="
                                customer.customer_mailing_address.country ==
                                'Canada'
                              "
                              v-model="
                                customer.customer_mailing_address.postal_code
                              "
                              label="Postal Code *"
                              :rules="[
                                rules.required,
                                rules.address,
                                rules.CAZipCode,
                              ]"
                              mask="___ ___"
                              :thick="true"
                              :uppercase="true"
                            >
                            </masked-field>
                            <v-text-field
                              v-else-if="
                                customer.customer_mailing_address.country ==
                                'United States'
                              "
                              v-model="
                                customer.customer_mailing_address.postal_code
                              "
                              label="Postal Code *"
                              outlined
                              :rules="[
                                rules.required,
                                rules.address,
                                rules.USZipCode,
                              ]"
                            ></v-text-field>
                            <v-text-field
                              v-else
                              v-model="
                                customer.customer_mailing_address.postal_code
                              "
                              label="Postal Code *"
                              outlined
                              :rules="[rules.required, rules.address]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider> </v-divider>
                      </v-col>

                      <v-col cols="12">
                        <h4 style="text-align: left">Physical Address</h4>
                        <v-row
                          v-if="
                            !same_address && customer.customer_physical_address
                          "
                          class="mb-n"
                        >
                          <v-col cols="12" class="text_field_col pt-0">
                            <v-checkbox
                              label="Same Address"
                              v-model="same_address"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" class="text_field_col">
                            <v-text-field
                              label="Address 1"
                              v-model="
                                customer.customer_physical_address.address_1
                              "
                              outlined
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" class="text_field_col">
                            <v-text-field
                              label="Address 2"
                              v-model="
                                customer.customer_physical_address.address_2
                              "
                              outlined
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            xl="4"
                            lg="4"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="City"
                              v-model="customer.customer_physical_address.city"
                              outlined
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            xl="2"
                            lg="2"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-select
                              v-if="
                                customer.customer_physical_address.country ==
                                'Canada'
                              "
                              v-model="
                                customer.customer_physical_address.province
                              "
                              :items="$store.getters.getProvinces"
                              label="Province"
                              outlined
                              :rules="[rules.address]"
                            ></v-select>
                            <v-select
                              v-else-if="
                                customer.customer_physical_address.country ==
                                'United States'
                              "
                              v-model="
                                customer.customer_physical_address.province
                              "
                              :items="$store.getters.getStates"
                              label="State"
                              outlined
                              :rules="[rules.address]"
                            ></v-select>
                            <v-text-field
                              v-else
                              v-model="
                                customer.customer_physical_address.province
                              "
                              label="Province"
                              outlined
                              :rules="[rules.address]"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="4"
                            lg="4"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-combobox
                              v-model="
                                customer.customer_physical_address.country
                              "
                              label="Country"
                              :items="countries"
                              outlined
                            ></v-combobox>
                          </v-col>
                          <v-col
                            xl="2"
                            lg="2"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <masked-field
                              v-if="
                                customer.customer_physical_address.country ==
                                'Canada'
                              "
                              v-model="
                                customer.customer_physical_address.postal_code
                              "
                              label="Postal Code"
                              :rules="[rules.address, rules.CAZipCode]"
                              mask="___ ___"
                              :thick="true"
                              :uppercase="true"
                            >
                            </masked-field>
                            <v-text-field
                              v-else-if="
                                customer.customer_physical_address.country ==
                                'United States'
                              "
                              v-model="
                                customer.customer_physical_address.postal_code
                              "
                              label="Postal Code"
                              outlined
                              :rules="[rules.address, rules.USZipCode]"
                            ></v-text-field>
                            <v-text-field
                              v-else
                              v-model="
                                customer.customer_physical_address.postal_code
                              "
                              label="Postal Code"
                              outlined
                              :rules="[rules.address]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-else class="mb-n5">
                          <v-col cols="12" class="text_field_col pt-0">
                            <v-checkbox
                              label="Same Address"
                              v-model="same_address"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="mt-1 pt-3" v-else-if="selected_tab == 2">
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Facebook"
                        v-model="customer.customer_social_facebook"
                        outlined
                        prepend-inner-icon="mdi-facebook"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Instagram"
                        v-model="customer.customer_social_instagram"
                        outlined
                        prepend-inner-icon="mdi-instagram"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Linkedin"
                        v-model="customer.customer_social_linkedin"
                        outlined
                        prepend-inner-icon="mdi-linkedin"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Twitter"
                        v-model="customer.customer_social_twitter"
                        outlined
                        prepend-inner-icon="mdi-twitter"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="YouTube"
                        v-model="customer.customer_social_youtube"
                        outlined
                        prepend-inner-icon="mdi-youtube"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Other"
                        v-model="customer.customer_social_other"
                        outlined
                        prepend-inner-icon="mdi-account-box-outline"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-n4" v-else-if="selected_tab == 3">
                    <v-container>
                      <v-form class="mt-5 mx-4" v-model="isFormValid">
                        <v-text-field
                          class="mx-10"
                          v-model="currentPassword"
                          data-testid="current-password"
                          label="Current Password"
                          :rules="[validateNotEmpty]"
                          outlined
                          clearable
                          :append-icon="
                            currentPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () =>
                              (currentPasswordVisible = !currentPasswordVisible)
                          "
                          :type="currentPasswordVisible ? 'text' : 'password'"
                          data-cy="field_currentPassword"
                        ></v-text-field>
                        <v-divider class="pt-5 mt-n2"></v-divider>
                        <v-text-field
                          class="mx-10"
                          v-model="newPassword"
                          data-testid="new-password"
                          label="New Password"
                          outlined
                          clearable
                          :append-icon="
                            newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () => (newPasswordVisible = !newPasswordVisible)
                          "
                          :type="newPasswordVisible ? 'text' : 'password'"
                          :rules="[validatePassword]"
                          data-cy="field_newPassword"
                        ></v-text-field>

                        <v-text-field
                          class="mx-10"
                          v-model="confirmPassword"
                          data-testid="confirm-password"
                          label="Confirm Password"
                          outlined
                          clearable
                          :append-icon="
                            newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () => (newPasswordVisible = !newPasswordVisible)
                          "
                          :type="newPasswordVisible ? 'text' : 'password'"
                          :rules="[
                            validateSamePassword(
                              this.newPassword,
                              this.confirmPassword
                            ),
                          ]"
                          data-cy="field_confirmPassword"
                        ></v-text-field>
                      </v-form>
                    </v-container>
                  </v-row>
                  <v-row class="mt-1 pt-3" v-else-if="selected_tab == 4">
                    <v-col>
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers_notifications"
                        :items="notification_pref"
                        :items-per-page="3"
                        class="elevation-0"
                        hide-default-footer
                      >
                        <template v-slot:[`item.title`]="{ item }">
                          <span>
                            {{
                              item.title.charAt(0).toUpperCase() +
                              item.title.slice(1)
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.email_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.sms`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.sms_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.call`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.call_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" style="text-align: left" class="px-7">
                      <span v-if="customer.customer_phone_cell"
                        >SMS notifications will be sent to
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >{{ customer.customer_phone_cell }}</span
                        >
                        and email notifications will be sent to
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >{{ user.email }}</span
                        ></span
                      >
                      <span v-else
                        >You can set up your cellphone number
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >here</span
                        >
                        to receive SMS notifications.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-col cols="12" class="pt-0 pb-0 pr-0">
                <v-row>
                  <v-col
                    v-if="
                      selected_tab == 0 ||
                      selected_tab == 1 ||
                      selected_tab == 2 ||
                      selected_tab == 4
                    "
                    class="text-right"
                  >
                    <v-btn
                      @click="save()"
                      class="save_btn ml-4"
                      color="primary"
                      :disabled="
                        not_made_changes ||
                        !isFormValid_general ||
                        !isFormValid_address
                      "
                      data-cy="btn_save"
                      >save</v-btn
                    ></v-col
                  ><v-col v-if="selected_tab == 3" class="text-right">
                    <v-btn
                      color="primary"
                      @click="changePassword"
                      :disabled="!isFormValid"
                      data-testid="change-password"
                      data-cy="btn_changePassword"
                    >
                      Change Password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <!-- <events></events> -->
    <v-snackbar v-model="error_snackbar" :timeout="3000" color="error">
      {{ error }}
    </v-snackbar>
    <v-snackbar v-model="success_snackbar" :timeout="3000" color="oc_green">
      {{ error }}
    </v-snackbar>
  </div>
</template>

<script>
// import firebase from '../firebaseConfig';

// connect to firestore using firebase config
// const db = firebase.firestore();
import { Auth } from "aws-amplify";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import {
  validateSamePassword,
  validatePassword,
  validateNotEmpty,
  validateDelete,
  validatePhoneNumber,
} from "@/utilities/ValidationRules";
import rulesMixin from "../mixins/rulesMixin";
//import events from "@/components/Events.vue";
//var AWSCognito = require("amazon-cognito-identity-js");
// var AWS = require("aws-sdk");
import { isEqual } from "lodash";
import * as mutations from "../graphql/mutations.js";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import { eventBus } from "../main.js";
import { initializeS3Client } from "../utilities/sessionUtils";
import { Upload } from "@aws-sdk/lib-storage";
import PhoneMaskedField from "../components/PhoneMaskedField.vue";
import countryData from "../data/country-by-name.json";
import MaskedField from "../components/MaskedField.vue";

// @vuese
// @group Views
export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
    PhoneMaskedField,
    MaskedField,
    //events,
  },
  mixins: [rulesMixin],
  props: ["setting"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        spec_char: (v) =>
          /^(?=.*[!@#$%^&_*]).{8,}$/.test(v) ||
          "Your password must contain at least one special character.",
        cap_let: (v) =>
          /^(?=.*[A-Z]).{8,}$/.test(v) ||
          "Your password must contain at least one capital letter.",
        number: (v) =>
          /^(?=.*[0-9]).{8,}$/.test(v) ||
          "Your password must contain at least one number.",
      },
      customer: false,
      original_customer: false,
      selected_tab: 0,
      stepperCount: 1,
      email_pr: "",
      verificationCode: "",
      currentPasswordVisible: false,
      newPasswordVisible: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isFormValid: false,
      isFormValid_general: false,
      isFormValid_address: false,
      success_snackbar: false,

      successful_update: false,
      error: null,
      error_snackbar: false,

      add_image_dialog: false,
      add_logo_dialog: false,
      image: null,
      company_logo_image: null,
      company_image: false,
      dummy_nums: "9999999",
      dummy_nums_logo: "9999999",

      loading_image: true,
      loading_company_image: true,

      not_made_changes: true,

      same_address: false,

      notification_pref: [
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "alert",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "warning",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "info",
        },
      ],
      notification_pref_init: [],
      notification_freq_items: [
        { value: "NEVER", text: "Never" },
        { value: "FIVE_MINUTES", text: "5 min" },
        { value: "FIFTEEN_MINUTES", text: "15 min" },
        { value: "THIRTY_MINUTES", text: "30 min" },
        { value: "ONE_HOUR", text: "1 hrs" },
        { value: "TWO_HOURS", text: "2 hrs" },
        { value: "FOUR_HOURS", text: "4 hrs" },
        { value: "EIGHT_HOURS", text: "8 hrs" },
        { value: "TWELVE_HOURS", text: "12 hrs" },
        { value: "ONE_DAY", text: "1 day" },
      ],
      refreshKey: 0,
    };
  },
  computed: {
    userInitial() {
      let f_name = this.user.given_name;
      let l_name = this.user.family_name;
      if (f_name === undefined || l_name === undefined) {
        return " ";
      }

      return f_name[0].toUpperCase() + l_name[0].toUpperCase();
    },
    countries() {
      var c = [];
      for (var i in countryData) {
        c.push(countryData[i].country);
      }
      return c;
    },
    user() {
      return this.$store.getters.getUser.attributes;
    },
    customer_picture() {
      return (
        process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
        this.$store.getters.getUser.username +
        "/profile/" +
        this.$store.getters.getUser.username +
        "_300x300.jpg"
      );
    },
    headers_notifications() {
      return [
        {
          text: "Notification",
          value: "title",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "SMS",
          value: "sms",
        },
        // {
        //   text: "Call",
        //   value: "call",
        // },
      ];
    },
    // async company_image() {
    //   if (!this.user) {
    //     return false
    //   }

    //   let path = process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE + this.user.sub + '/company/images/' + this.user.sub + '_logo_300x300.jpg'

    //   //const img = new Image();

    //   new Promise((resolve, reject) => {
    //     const img = new Image();
    // img.onload = () => resolve(img);
    // img.onerror = reject;
    // img.src = path;
    //   }).then(image => console.log(image))
    //   return path
    //   // console.log(img)
    //   // console.log(img)
    //   // if (img.complete) {
    //   //   return path;
    //   // } else return false;
    // },
    // image_url() {
    //   return this.user.picture + "?dummy=" + String(Math.floor(Math.random() * 9999999))
    // }
  },
  methods: {
    validateSamePassword,
    validatePassword,
    validateNotEmpty,
    validateDelete,
    validatePhoneNumber,

    notification_object_equal() {
      for (const i in this.notification_pref) {
        if (
          isEqual(this.notification_pref[i], this.notification_pref_init[i])
        ) {
          if (
            this.not_made_changes == false &&
            isEqual(this.original_customer, this.customer)
          ) {
            this.not_made_changes = true;
          }
        } else {
          if (this.not_made_changes == true) {
            this.not_made_changes = false;
          }
        }
      }
    },

    object_equal(obj1, obj2) {
      if (!obj2) {
        return false;
      }
      for (const i in obj1) {
        if (obj1[i] != obj2[i]) {
          return false;
        }
      }
      return true;
    },

    return_url() {
      this.dummy_nums = String(Math.floor(Math.random() * 9999999));
      this.loading_image = false;
    },
    return_image_url() {
      this.dummy_nums_logo = String(Math.floor(Math.random() * 9999999));
      this.loading_company_image = false;
    },
    changePassword() {
      var data = {
        UserPoolId: process.env.VUE_APP_USER_POOL_ID,
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID,
      };
      var AWSCognito = require("amazon-cognito-identity-js");
      var userPool = new AWSCognito.CognitoUserPool(data);
      var user = userPool.getCurrentUser();
      var self = this;
      if (user) {
        user.getSession((err) => {
          if (err) {
            self.error = err.message;
            self.error_snackbar = true;
          } else {
            user.changePassword(
              this.currentPassword,
              this.newPassword,
              (err) => {
                if (err) {
                  self.error = err.message;
                  self.error_snackbar = true;
                } else {
                  self.error = "Password is successfully changed";
                  self.success_snackbar = true;
                }
              }
            );
            this.isFormValid = false;
          }
        });
      }
    },
    validate_email(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async save() {
      try {
        var input = {
          id: this.customer.id,
          updatedBy: this.$store.getters.getUser.username,
        };
        const original = await API.graphql({
          query: queries.getCustomer,
          variables: {
            id: this.customer.id,
          },
          authToken: this.$store.getters.getLambdaAuthTokenHome,
        });
        for (const i in original.data.getCustomer) {
          if (
            i == "customer_first_name" ||
            i == "customer_last_name" ||
            i == "customer_phone_cell" ||
            i == "customer_phone_home" ||
            i == "customer_company_name" ||
            i == "customer_company_occupation" ||
            i == "customer_company_website" ||
            i == "customer_mailing_address" ||
            i == "customer_physical_address" ||
            i == "customer_social_instagram" ||
            i == "customer_social_facebook" ||
            i == "customer_social_linkedin" ||
            i == "customer_social_twitter" ||
            i == "customer_social_youtube" ||
            i == "customer_social_other"
          ) {
            input[i] = this.customer[i];
          }
        }
        var temp_notification_pref = {};
        for (const i in this.notification_pref) {
          // temp_notification_pref[this.notification_pref[i].title] = {};
          //  delete this.notification_pref[i].title
          temp_notification_pref[this.notification_pref[i].title] = {
            ...this.notification_pref[i],
          };
          delete temp_notification_pref[this.notification_pref[i].title].title;
        }
        input.customer_notification_preference = temp_notification_pref;
        if (this.same_address) {
          input.customer_physical_address =
            this.customer.customer_mailing_address;
        }
        await API.graphql({
          query: mutations.updateCustomer,
          variables: {
            input: input,
          },
          authToken: this.$store.getters.getLambdaAuthTokenHome,
        });
        this.notification_pref_init = this.notification_pref;
        this.$store.dispatch("DDB_GET_CUSTOMER");
        this.error = "Successfully updated customer";
        this.success_snackbar = true;
      } catch (err) {
        this.error = err;
        this.error_snackbar = true;
      }
    },
    async save_user() {
      // AWS.config.region = process.env.VUE_APP_AWS_REGION;
      // var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      // var user_attributes = [];
      // // console.log(
      // //   this.$store.getters.getUser.signInUserSession.accessToken.jwtToken
      // // );
      // if (this.user.given_name)
      //   user_attributes.push({
      //     Name: "given_name",
      //     Value: this.user.given_name,
      //   });
      // if (this.user.family_name)
      //   user_attributes.push({
      //     Name: "family_name",
      //     Value: this.user.family_name,
      //   });
      // if (this.user.phone_number)
      //   user_attributes.push({
      //     Name: "phone_number",
      //     Value: this.user.phone_number,
      //   });

      // var params = {
      //   UserAttributes: user_attributes,
      //   AccessToken:
      //     this.$store.getters.getUser.signInUserSession.accessToken.jwtToken,
      // };
      // cognitoidentityserviceprovider.updateUserAttributes(
      //   params,
      //    function (err, data) {
      //     if (err) console.log(err, err.stack);
      //     // an error occurred
      //     else console.log(data); // successful response
      //   }
      // );
      let user = await Auth.currentAuthenticatedUser();
      // console.log(this.user)
      try {
        await Auth.updateUserAttributes(user, this.user);

        this.error = "Successfully updated account";
        this.success_snackbar = true;
      } catch (error) {
        this.error = error.message;
        this.error_snackbar = true;
      }
    },
    async save_image() {
      initializeS3Client()
        .then((client) => {
          var albumPhotosKey =
            // AWS.config.credentials.identityId +
            "/" + this.$store.getters.getUser.username + "/profile/";
          var photoKey =
            albumPhotosKey + this.$store.getters.getUser.username + ".jpg";
          // Use S3 ManagedUpload class as it supports multipart uploads

          var params = {
            Bucket: process.env.VUE_APP_UPLOAD_BUCKET,
            Key: photoKey,
            Body: this.image,
          };
          var upload = new Upload({
            client: client,
            params: params,
          });
          this.upload_img = false;
          // var self = this
          this.add_image_dialog = false;
          this.loading_image = true;
          return upload.done();
        })
        .then(() => {
          this.error = "Successfully updated account";

          this.success_snackbar = true;

          setTimeout(() => {
            eventBus.$emit("update_navbar_img");
            this.return_url();
          }, 4000);
        })
        .catch((err) => {
          this.error = err.message;
          this.error_snackbar = true;
        });
    },
    async save_company_image() {
      initializeS3Client()
        .then((client) => {
          var albumPhotosKey =
            // AWS.config.credentials.identityId +
            "/" + this.$store.getters.getUser.username + "/company/images/";
          var photoKey =
            albumPhotosKey + this.$store.getters.getUser.username + "_logo.jpg";
          // Use S3 ManagedUpload class as it supports multipart uploads

          var params = {
            Bucket: process.env.VUE_APP_UPLOAD_BUCKET,
            Key: photoKey,
            Body: this.company_logo_image,
          };

          var upload = new Upload({
            client: client,
            params: params,
          });

          this.upload_img = false;
          this.add_logo_dialog = false;
          this.loading_company_image = true;

          return upload.done();
        })
        .then(() => {
          this.error = "Successfully uploaded company logo";
          this.success_snackbar = true;
          setTimeout(() => {
            this.return_image_url();
          }, 4000);
        })
        .catch((err) => {
          this.error = err.message;
          this.error_snackbar = true;
        });
    },
    fetchCustomer() {
      var promises = [];
      promises.push(this.$store.dispatch("DDB_GET_CUSTOMERS"));
      promises.push(this.$store.dispatch("DDB_GET_COGNITO_USERS"));

      Promise.all(promises).then(() => {
        var user = this.$store.getters.getUser;
        var current_user = this.$store.getters.getCognitoUsers.find(
          (u) =>
            u.Attributes.find((e) => e.Name == "email")?.Value ==
            user.attributes.email
        );
        var current_customer = this.$store.getters.getCustomerObjects.find(
          (c) => c.id == current_user?.Username
        );
        if (current_customer) {
          this.original_customer = JSON.parse(JSON.stringify(current_customer));
          this.customer = JSON.parse(JSON.stringify(current_customer));
          // console.log(this.customer);
          // this.refreshKey++;
        }
      });
    },
  },
  watch: {
    customer: {
      handler: function () {
        if (this.customer) {
          if (isEqual(this.original_customer, this.customer)) {
            this.not_made_changes = true;
          } else {
            this.not_made_changes = false;
          }
          if (this.customer.customer_mailing_address == undefined) {
            this.customer.customer_mailing_address = {};
          }
          this.same_address = this.object_equal(
            this.customer.customer_mailing_address,
            this.customer.customer_physical_address
          );
          if (this.customer.customer_notification_preference) {
            this.notification_pref = [];

            // for (const k in this.customer.customer_notification_preference) {
            //   if (this.customer.customer_notification_preference[k]) {
            //     let temp_prefs =
            //       this.customer.customer_notification_preference[k];
            //     temp_prefs["title"] = k;
            //     this.notification_pref.push({ ...temp_prefs });
            //     this.notification_pref_init.push({ ...temp_prefs });
            //   }
            // }

            let notification_levels = ["alert", "warning", "info"];
            for (const k in notification_levels) {
              if (
                this.customer.customer_notification_preference[
                  notification_levels[k]
                ]
              ) {
                let temp_prefs =
                  this.customer.customer_notification_preference[
                    notification_levels[k]
                  ];
                temp_prefs["title"] = notification_levels[k];
                this.notification_pref.push({ ...temp_prefs });
                this.notification_pref_init.push({ ...temp_prefs });
              }
            }
          }
        }
      },
      deep: true,
    },
    notification_pref: {
      handler: function () {
        this.notification_object_equal();
      },
      deep: true,
    },
  },
  mounted() {
    this.return_url();
    this.return_image_url();
    var img = new Image();
    let logo_path =
      process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
      this.user.sub +
      "/company/images/" +
      this.user.sub +
      "_logo_300x300.jpg";
    img.src = logo_path;
    let _this = this;
    img.onload = function () {
      _this.company_image = logo_path;
    };

    if (this.customer) {
      if (this.customer.customer_mailing_address == undefined) {
        this.customer.customer_mailing_address = {};
      }
      this.same_address = this.object_equal(
        this.customer.customer_mailing_address,
        this.customer.customer_physical_address
      );
      if (this.customer.customer_notification_preference) {
        this.notification_pref = [];
        let notification_levels = ["alert", "warning", "info"];
        for (const k in notification_levels) {
          if (
            this.customer.customer_notification_preference[
              notification_levels[k]
            ]
          ) {
            let temp_prefs =
              this.customer.customer_notification_preference[
                notification_levels[k]
              ];
            temp_prefs["title"] = notification_levels[k];
            this.notification_pref.push({ ...temp_prefs });
            this.notification_pref_init.push({ ...temp_prefs });
          }
        }
      }
    }
  },
  created() {
    this.fetchCustomer();
  },
};
</script>

<style lang="css">
.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}
.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
