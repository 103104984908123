var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),(_vm.livestock.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index != 0)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/animal/' +
          _vm.livestock[_vm.index - 1].id +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-container',[_c('div',[_c('v-row',[_c('v-btn',{staticClass:"filter_btn ml-3 mb-3",attrs:{"to":'/' + _vm.prev_page.replaceAll('+', '/'),"large":""}},[_vm._v("back ")]),_c('v-spacer')],1),_c('div',[_c('v-card',[_c('v-card-text',{staticClass:"pt-0 pb-0 pl-3 pr-3"},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-tabs',{staticClass:"table_tabs_hp float_left ml-3 mt-1",attrs:{"show-arrows":""},model:{value:(_vm.selected_tab),callback:function ($$v) {_vm.selected_tab=$$v},expression:"selected_tab"}},[_c('v-tab',[_vm._v("Main")]),_c('v-tab',[_vm._v("ID")]),_c('v-tab',[_vm._v("Appearances")]),_c('v-tab',[_vm._v("Activity")]),_c('v-tab',[_vm._v("Health")]),_c('v-tab',[_vm._v("Phenotypes")]),_c('v-tab',[_vm._v("Growth")]),_c('v-tab',[_vm._v("Lineage")])],1),_c('v-select',{staticClass:"select_tag mr-3 pt-3",attrs:{"items":_vm.livestock_tags,"dense":"","flat":"","hide-details":""},model:{value:(_vm.selected_tag),callback:function ($$v) {_vm.selected_tag=$$v},expression:"selected_tag"}})],1),(_vm.selected_tab == 0)?_c('v-row',[_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"4","cols":"4"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-2 mt-n2 ml-n2 mr-n2"},[_c('v-img',{attrs:{"height":"195","src":`${
                              _vm.cookie_bucket +
                              _vm.livestock[_vm.index].livestock_head_img
                            }`}})],1),_c('v-row',{staticClass:"mb-2 ml-n2 mr-n2"},[_c('v-img',{attrs:{"height":"195","src":`${
                              _vm.cookie_bucket +
                              _vm.livestock[_vm.index].livestock_heatmap_img
                            }`}})],1),_c('v-row',{staticClass:"mb-n2 ml-n2 mr-n2"},[_c('v-img',{attrs:{"height":"195","src":`${
                              _vm.cookie_bucket +
                              _vm.livestock[_vm.index].livestock_body_img
                            }`}})],1)],1)],1)],1),_c('v-col',[_c('v-row',{staticClass:"mt-n3"},[_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" ID ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_drop_tag))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" RFID ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_rfid_tag))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" OCR ID ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_ocr_id)+" - "),(
                                      _vm.livestock[_vm.index]
                                        .livestock_ocr_id_confidence_score <
                                      70
                                    )?_c('span',{staticClass:"red_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_ocr_id_confidence_score)+"%")]):_vm._e(),(
                                      _vm.livestock[_vm.index]
                                        .livestock_ocr_id_confidence_score >=
                                        70 &&
                                      _vm.livestock[_vm.index]
                                        .livestock_ocr_id_confidence_score <
                                        90
                                    )?_c('span',{staticClass:"orange_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_ocr_id_confidence_score)+"%")]):_vm._e(),(
                                      _vm.livestock[_vm.index]
                                        .livestock_ocr_id_confidence_score >=
                                      90
                                    )?_c('span',{staticClass:"green_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_ocr_id_confidence_score)+"%")]):_vm._e()])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Face ID ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_face_id)+" - "),(
                                      _vm.livestock[_vm.index]
                                        .livestock_face_id_confidence_score <
                                      70
                                    )?_c('span',{staticClass:"red_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_face_id_confidence_score)+"%")]):_vm._e(),(
                                      _vm.livestock[_vm.index]
                                        .livestock_face_id_confidence_score >=
                                        70 &&
                                      _vm.livestock[_vm.index]
                                        .livestock_face_id_confidence_score <
                                        90
                                    )?_c('span',{staticClass:"orange_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_face_id_confidence_score)+"%")]):_vm._e(),(
                                      _vm.livestock[_vm.index]
                                        .livestock_face_id_confidence_score >=
                                      90
                                    )?_c('span',{staticClass:"green_color"},[_vm._v(_vm._s(_vm.livestock[_vm.index] .livestock_face_id_confidence_score)+"%")]):_vm._e()])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Customer ")]),(_vm.livestock[_vm.index].customer)?_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].customer.customer_name))]):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Site ")]),(_vm.livestock[_vm.index].site)?_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].site.site_name))]):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Gender ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_gender))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Date of Birth ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_dob))])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Breed ")]),_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_breed.name))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Branding ")]),(
                                    _vm.livestock[_vm.index].livestock_breed
                                      .branding_dates
                                  )?_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.livestock[_vm.index].livestock_breed .branding_dates[0].start)+"    "),_c('span',{staticClass:"unit_label"},[_vm._v(_vm._s(_vm.age_calc( _vm.livestock[_vm.index].livestock_breed .branding_dates[0].start ))+" months")])]):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Breeding ")]),(
                                    _vm.livestock[_vm.index].livestock_breed
                                      .breeding_dates
                                  )?_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.livestock[_vm.index].livestock_breed .breeding_dates[0].start)+"    "),_c('span',{staticClass:"unit_label"},[_vm._v(_vm._s(_vm.age_calc( _vm.livestock[_vm.index].livestock_breed .breeding_dates[0].start ))+" months")])]):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Weaning ")]),(
                                    _vm.livestock[_vm.index].livestock_breed
                                      .weaning_dates
                                  )?_c('v-list-item-title',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.livestock[_vm.index].livestock_breed .weaning_dates[0].start)+"    "),_c('span',{staticClass:"unit_label"},[_vm._v(_vm._s(_vm.age_calc( _vm.livestock[_vm.index].livestock_breed .weaning_dates[0].start ))+" months")])]):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Weight ")])],1)],1),_c('div',[(_vm.livestock[_vm.index])?_c('animalWeightByDay',{attrs:{"id":"graph_div","weight":_vm.livestock[_vm.index].livestock_weight}}):_vm._e()],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.selected_tab == 2)?_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.appearances,"items-per-page":100,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function({
                              pagination,
                              options,
                              updateOptions,
                            }){return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[50, 100, 200],"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:`item.thumbnail`,fn:function({ item }){return [_c('v-list-item',{staticClass:"table_list_item",on:{"click":function($event){return _vm.redirect_to_video(item.thumbnail, item.video_duration, item.camera_name, item.date, item.start)}}},[_c('v-img',{attrs:{"height":"54","width":"90","src":`${
                                  _vm.cookie_bucket +
                                  item.thumbnail +
                                  '_480x270.jpg'
                                }`}})],1)]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.selected_tab != 0 && _vm.selected_tab != 2)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"height":"585"}},[_c('v-card-text',[_c('h2',{attrs:{"id":"uc_label"}},[_vm._v("Under Construction")])])],1)],1)],1):_vm._e()],1)],1)],1)],1)])],1),_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index < _vm.livestock.length - 1)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/animal/' +
          _vm.livestock[_vm.index + 1].id +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":'/animal/' +
          _vm.selected_tag +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page,"icon":""}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }