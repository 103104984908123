<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      >  <v-row align-content="center" justify="center">
      <v-col cols="12" class="main_card_col">
        <v-card elevation="2">
          <v-card-title>
            <span class="ml-2">Betsy Videos</span>
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
          </v-card-title>
          <v-card-text class="text-center">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="videos"
              :items-per-page="100"
              class="elevation-1"
              dense
              hide-default-footer
              :search="search"
              :sort-by.sync="sort_by"
              :sort-desc.sync="sort_desc"
            >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  class="table_pagination"
                  @update:options="updateOptions"
                  :items-per-page-options="[50, 100, 200]"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
              </template>
              <template v-slot:[`body.prepend`]>
                <tr class="mb_hidden">
                  <td></td>
                  <td class="select_width align_left">
                    <v-select
                      class="mt-n1"
                      :items="customers[1]"
                      v-model="customer"
                      dense
                      flat
                      hide-details
                    ></v-select>
                  </td>
                  <td class="select_width align_left">
                    <v-select
                      class="mt-n1"
                      :items="cameras[1]"
                      v-model="camera"
                      dense
                      flat
                      hide-details
                    ></v-select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td></tr
              ></template>
              <template v-slot:[`item.thumbnail`]="{ item }">
                <v-list-item
                  class="table_list_item"
                  :to="
                    '/video/' +
                    get_url(item.video_thumbnail, item.camera_id).replaceAll(
                      '/',
                      '+'
                    ) +
                    '&' +
                    item.video_duration +
                    '&' +
                    sort_by_fix(sort_by)
                      .replaceAll('camera.camera_name', 'video_camera')
                      .replaceAll('customer.customer_name', 'video_customer') +
                    '&' +
                    sort_desc +
                    '&' +
                    'betsy+all' +
                    '&' +
                    item.camera.camera_name +
                    '&' +
                    item.video_datetime
                  "
                  ><v-img
                    height="54"
                    width="90"
                    :src="`${
                      cookie_bucket +
                      get_url(item.video_thumbnail, item.camera_id) +
                      '_480x270.jpg'
                    }`"
                /></v-list-item>
              </template>
              <template v-slot:[`item.customer`]="{ item }">
                <span>{{ users[item.owner] }}</span>
              </template>
              <template v-slot:[`item.video_datetime`]="{ item }">
                <span>{{ item.video_datetime.split(" ")[0] }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card></v-col
      >
    </v-row></v-container>
  
  </div>
</template>

<script>
//import { eventBus } from "../main.js";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  props: ["filter"],
  data() {
    return {
      headers: [
        { text: "Thumbnail", value: "thumbnail", width: "10%" },
        { text: "Customer", value: "customer.customer_name", width: "18%" },
        { text: "Camera", value: "camera.camera_name", width: "18%" },
        { text: "Date", value: "video_datetime" },
        { text: "Duration", value: "video_duration" },
        { text: "Size", value: "video_size" },
      ],
      search: "",
      staging_data: [],
      production_data: [],
      loading: true,
      customer: "OneCup Demo  -  70",
      filter_params: {},
      camera: "Demo Cam 1  -  70",
      video_duration: null,
      video_url: null,
      page: 1,
      sort_by: ["video_datetime"],
      sort_desc: true,
    };
  },
  methods: {
    sort_by_fix(sort_by) {
      if (sort_by.length != 0) {
        return sort_by[0];
      }
      return "default";
    },
    owner_filter() {
      if (this.filter != "all") {
        if (this.filter.length >= 34) {
          this.customer = this.$store.getters.getOwnerNameObject[0][this.filter];
        } else {
          this.camera = this.filter
            .replaceAll("+", " ")
            .replaceAll("&", "  -  ");
        }
      }
    },
    get_url(thumbnail, cam_id) {
      let temp_array = thumbnail.split(cam_id + "/");
      let betsy_thumbnail =
        temp_array[0] + "betsy-layer1/" + cam_id + "/" + temp_array[1];
      return betsy_thumbnail;
    },
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    cameras() {
      if (this.$store.getters.getCameraObjects.length > 0) {
        var cameras = [{}, []];
        if (this.customer == "All") {
          for (const i in this.$store.getters.getCameraObjects) {
            cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
              this.$store.getters.getCameraObjects[i].id;
            cameras[1].push(
              this.$store.getters.getCameraObjects[i].camera_name +
                "  -  " +
                this.$store.getters.getCameraObjects[i].videos.items.length
            );
          }
        } else {
          for (const i in this.$store.getters.getCameraObjects) {
            if (
              this.$store.getters.getCameraObjects[i].owner ==
              this.$store.getters.getNameOwnerObject[
                this.customer.split("  -  ")[0]
              ]
            ) {
              cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
                this.$store.getters.getCameraObjects[i].id;
              cameras[1].push(
                this.$store.getters.getCameraObjects[i].camera_name +
                  "  -  " +
                  this.$store.getters.getCameraObjects[i].videos.items.length
              );
            }
          }
        }

        const sortAlphaNumCams = (a, b) =>
          a.localeCompare(b, "en", { numeric: true });
        cameras[1].sort(sortAlphaNumCams);
        cameras[1].unshift("All");
        console.log(cameras)
        return cameras;
      } else {
        return false;
      }
    },
    customers() {
      // var customers = [
      //   ...new Set(
      //     this.camera_objects.map((item) => item.customer.customer_name)
      //   ),
      // ];
      if (this.$store.getters.getCustomerObjects.length > 0) {
        var customers = [{}, []];
        for (const i in this.$store.getters.getCustomerObjects) {
          customers[0][
            this.$store.getters.getCustomerObjects[i].customer_name
          ] = this.$store.getters.getCustomerObjects[i].id;
          customers[1].push(
            this.$store.getters.getCustomerObjects[i].customer_name +
              "  -  " +
              this.$store.getters.getCustomerObjects[i].videos.items.length
          );
        }
        customers[1].unshift("All");

        return customers;
      } else {
        return false;
      }
    },
    users() {
      var owner_email_name = {};
      for (const i in this.$store.getters.getCustomerObjects) {
        owner_email_name[this.$store.getters.getCustomerObjects[i].owner] =
          this.$store.getters.getCustomerObjects[i].customer_name;
      }
      return owner_email_name;
    },
    videos() {
      return this.$store.getters.getVideos;
    },
  },
  mounted() {
   
  },
  created() {
    // eventBus.$on("show_all_videos", () => {
    //   this.show_one_video = false;
    // });
    if (this.$store.getters.getCameraObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERAS");
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(this.owner_filter);
    } else {
      this.owner_filter();
    }

   // this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
      this.filter_params.filter = {
            camera_id: { eq: '95270001D3M04CC7' },
          };
          this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
  },
  watch: {
    videos() {
      this.loading = false;
    },
    customer() {
      if (this.customer != "All") {
        if (this.camera != "All") {
          this.camera = "All";
          this.filter_params.filter.owner = {
            eq: this.$store.getters.getNameOwnerObject[
              this.customer.split("  -  ")[0]
            ],
          };
        } else {
          this.filter_params.filter = {
            owner: {
              eq: this.$store.getters.getNameOwnerObject[
                this.customer.split("  -  ")[0]
              ],
            },
          };
          this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
        }
      } else {
        delete this.filter_params.filter.owner;
        if (this.camera == "All") {
          delete this.filter_params.filter;
        }
        this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
      }
    },
    camera() {
      if (this.camera != "All") {
        if (this.customer != "All") {
          this.filter_params.filter.camera_id = {
            eq: this.cameras[0][this.camera.split("  -  ")[0]],
          };
        } else {
          this.filter_params.filter = {
            camera_id: { eq: this.cameras[0][this.camera.split("  -  ")[0]] },
          };
        }
      } else {
        delete this.filter_params.filter.camera_id;
        if (this.customer == "All") {
          delete this.filter_params.filter;
        }
      }
      this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
    },
    dialog() {
      if (this.dialog == false) {
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
</style>