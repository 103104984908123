import { render, staticRenderFns } from "./CustomerPage.vue?vue&type=template&id=e3cd9aa8&scoped=true"
import script from "./CustomerPage.vue?vue&type=script&lang=js"
export * from "./CustomerPage.vue?vue&type=script&lang=js"
import style0 from "./CustomerPage.vue?vue&type=style&index=0&id=e3cd9aa8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3cd9aa8",
  null
  
)

export default component.exports