<template>
  <div>
    <v-card height="390">
      <v-card-text
        id="card_body"
        class="d-flex flex-column justify-space-between pa-3"
        v-if="users && !selected_customer"
      >
        <div class="row_body d-flex align-center flex-column ma-0">
          <span class="main_header">Overview</span>
          <v-row style="width: 100%">
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ users }}</span>
                <span class="heading mt-2">Cognito</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3 bordered">
                <span class="num">{{ stats.customer_count }}</span>
                <span class="heading mt-2">Customers</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.site_count }}</span>
                <span class="heading mt-2">Sites</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="row_body d-flex align-center flex-column ma-0">
          <span class="main_header">Animals</span>
          <v-row style="width: 100%">
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.animal_count }}</span>
                <span class="heading mt-2">Total</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3 bordered">
                <span class="num">{{
                  formatNumber(stats.animal_tag_count)
                }}</span>
                <span class="heading mt-2">Tags</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{
                  formatNumber(stats.animal_image_count)
                }}</span>
                <span class="heading mt-2">Animal IDs</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="row_body d-flex align-center flex-column ma-0">
          <span class="main_header">Hardware</span>
          <v-row style="width: 100%">
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.camera_count }}</span>
                <span class="heading mt-2">Cameras</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3 bordered">
                <span class="num">{{ stats.online_camera_count }}</span>
                <span class="heading mt-2">Online Cameras</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.wifi_count }}</span>
                <span class="heading mt-2">Wifi</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text
        id="card_body"
        class="d-flex flex-column justify-space-between pa-3"
        v-if="users && selected_customer"
      >
        <div class="name_header mt-5">
          <span
            >{{ selected_customer.company_name || "Individual" }}
            <v-btn
              icon
              :href="'/customer?id=' + selected_customer.id"
              target="_blank"
              ><v-icon>mdi-open-in-new</v-icon></v-btn
            ></span
          >
          <br />
          <span style="font-size: 18px">{{
            formulateNames(
              selected_customer.first_name,
              selected_customer.last_name
            )
          }}</span>
        </div>

        <div class="row_body d-flex align-center flex-column ma-0">
          <span class="main_header">Hardware</span>
          <v-row style="width: 100%">
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.camera_count }}</span>
                <span class="heading mt-2">Cameras</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3 bordered">
                <span class="num">{{ stats.online_camera_count }}</span>
                <span class="heading mt-2">Online Cameras</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.wifi_count }}</span>
                <span class="heading mt-2">Wifi</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="row_body d-flex align-center flex-column ma-0">
          <span class="main_header">Animals</span>
          <v-row style="width: 100%">
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{ stats.animal_count }}</span>
                <span class="heading mt-2">Total</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3 bordered">
                <span class="num">{{
                  formatNumber(stats.animal_tag_count)
                }}</span>
                <span class="heading mt-2">Tags</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0">
              <div class="d-flex align-center flex-column pt-3">
                <span class="num">{{
                  formatNumber(stats.animal_image_count)
                }}</span>
                <span class="heading mt-2">Animal IDs</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    customer: String,
  },
  data() {
    return {
      num_users: 0,

      got_data: false,
    };
  },
  methods: {
    formulateNames(first_name, last_name) {
      return (
        (first_name ? first_name + " " : "") +
        (first_name == last_name ? "" : last_name)
      );
    },
    formatNumber(num) {
      if (num < 1) {
        return "<1";
      } else {
        return num;
      }
    },
  },
  created() {
    this.$store.dispatch("DDB_HOME_PAGE_STATS", this.selected_customer);
  },
  computed: {
    selected_customer() {
      if (this.customer != "All Customers") {
        return this.customer;
      } else {
        return false;
      }
    },
    users() {
      return this.$store.getters.getCognitoUsers.length;
    },
    stats() {
      return this.$store.getters.getHomePageStats;
    },
  },
  watch: {
    selected_customer() {
      this.$store.dispatch("DDB_HOME_PAGE_STATS", this.selected_customer);
    },
  },
};
</script>
<style lang="css">
#general_users {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  top: 8%;
}
#vid_heading {
  position: absolute;
  font-size: 18px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#hw_deading {
  position: absolute;
  font-size: 18px;
  bottom: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#num_users {
  font-size: 82px;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#user_name {
  font-size: 28px;
  position: absolute;
  top: 14%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}

.user_names {
  position: absolute;
  top: 30%;
  max-height: 200px;
  overflow-y: scroll;
}

#num_users_heading {
  font-size: 18px;
  position: absolute;

  left: 50%;
  transform: translate(-50%, -50%);
}
#card_body {
  text-align: center;
  height: 400px;
}
#sub_stats {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
}
#sub_stats_vid {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  top: 44%;
}
.sub_stat_cols {
  position: relative;
}
.bordered {
  border-style: none solid none solid;
  border-width: 1px;
}

.bordered_right {
  border-style: none solid none none;
  border-width: 1px;
}
.sub_heading {
  font-size: 14px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -22%;
  min-width: 100px;
}
.sub_num {
  font-size: 32px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 32%;
}

.sub_heading2 {
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -12%;
}
.sub_num2 {
  font-size: 32px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 36%;
}
#loading_circle {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.num {
  font-size: 32px;
}

.heading {
  font-size: 14px;
}

.main_header {
  font-size: 18px;
}

.row_body {
  width: 100%;
}

.name_header {
  font-size: 28px;
}
</style>