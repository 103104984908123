<template>
    <v-img
        :height="height"
        :width="width"
        :src="source"
        @error="error = true"
    />
</template>

<script>
// @vuese
// @group Components
// The non-clickable thumbnail for videos
export default {
    props: {
        // video URL
        value: String, 
        // width of the image
        width: Number, 
        // height of the image
        height: Number
    },
    data(){
        return {
            error: false,
            video_path: this.value
        }
    },
    methods:{
        alternatePath(p){
            if(p.includes("ftp")){
                return p;
            }
            var arr = p.split('/')
            arr.splice(1, 0, 'ftp')
            return arr.join('/');
        }
    },
    computed: {
        source(){
            this.error;
            return this.cookie_bucket + this.video_path + '_480x270.jpg'
        },
        cookie_bucket() {
            return process.env.VUE_APP_COOKIE_BUCKET;
        },
    },
    watch: {
        value(){
            this.error = false;
            this.video_path = this.value
        },
        error(){
            if(this.error){
                this.video_path = this.alternatePath(this.video_path);
                this.$emit('input', this.video_path)
            }
        }
    }
}
</script>