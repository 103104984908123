<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pl-3 pr-3">
            <v-row>
              <v-tabs class="table_tabs ml-3 mb-3 mt-3">
                <v-tab to="/">Overview</v-tab>
                <v-tab to="/footageuploads">Cameras</v-tab>
                <v-tab to="/inference">Inference</v-tab>
                <v-tab to="/services">Services</v-tab>
                <v-tab to="/system">System</v-tab>
                <v-tab to="/triton">Triton</v-tab>
              </v-tabs>
              <!-- <div class="customer_select ml-3">
                <v-row>
                  <v-col
                    ><v-autocomplete
                      class="select_customer"
                      :items="customers"
                      v-model="customer_select"
                      dense
                      flat
                      hide-details
                      :menu-props="{ offsetOverflow: false }"
                    ></v-autocomplete>
                </v-col>
                <v-col class="mr-3 mt-1 pl-0">
                  <v-icon @click="previousCustomer">mdi-chevron-left</v-icon>
                  <v-icon @click="nextCustomer">mdi-chevron-right</v-icon>
                </v-col>
                </v-row>
              </div> -->
              <customer-filter
                v-model="customer_select"
                :pad="5"
              ></customer-filter>
            </v-row>
            <v-row >
              <v-col xl="8" lg="8" cols="12" class="max_width pt-0">
                <v-card elevation="2" class="largeCard">
                  <v-card-text
                    class="pa-0"
                    v-if="customer_select"
                  >
                    <div
                      style="
                        position: absolute;
                        z-index: 4;
                        bottom: 25px;
                        right: 60px;
                        background-color: white;
                        border-radius: 2px;
                      "
                      class="pa-1 elevation-1"
                    >
                      Zoom: {{ zoom }}
                    </div>
                    <div
                      @click="openGoogleEarth(53.1304, -110.3468, 10)"
                      style="
                        position: absolute;
                        z-index: 4;
                        bottom: -1px;
                        left: 75px;
                        /* background-color: white; */
                        border-radius: 2px;
                        font-weight: 900;
                        font-size: 18px;
                        color: white;
                        cursor: pointer;
                        text-stroke: 1.2px black;
                      "
                      class="pa-1 "
                    >
                      Earth
                    </div>
                    <GmapMap
                      :center="{
                        lat: parseFloat(53.1304),
                        lng: parseFloat(-110.3468),
                      }"
                      :zoom="5"
                      @zoom_changed="get_zoom"
                      map-type-id="hybrid"
                      style="width: inherit; height: 390px"
                      :options="{
                        mapTypeControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                      }"
                    >
                      <GmapMarker
                        :key="index + m.position"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="select_customer(m)"
                        :icon="m.icon"
                        :title="m.title"
                      />
                    </GmapMap>
                  </v-card-text>
                  <!-- <v-card-text
                    class="pa-0"
                    v-else-if="customer_select != 'All Customers' && markers"
                  >
                    <div
                      @click="openGoogleEarth(markers[0].latitude, markers[0].longitude, 5)"
                      style="
                        position: absolute;
                        z-index: 4;
                        bottom: -1px;
                        left: 75px;
                        border-radius: 2px;
                        font-weight: 900;
                        font-size: 18px;
                        color: white;
                        cursor: pointer;
                        text-stroke: 1.2px black;
                      "
                      class="pa-1 "
                    >
                      Earth
                    </div>
                    <div
                      style="
                        position: absolute;
                        z-index: 4;
                        bottom: 25px;
                        right: 60px;
                        background-color: white;
                        border-radius: 2px;
                      "
                      class="pa-1 elevation-1"
                    >
                      Zoom: {{ zoom }}
                    </div>
                    <div
                      style="
                        position: absolute;
                        z-index: 4;
                        bottom: 25px;
                        right: 160px;
                        background-color: white;
                        border-radius: 2px;
                      "
                      class="pa-1 elevation-1"
                    >
                      TEST
                    </div>
                    <div
                      style="
                        width: 200px;
                        position: absolute;
                        z-index: 4;
                        top: 8px;
                        right: 60px;
                        background-color: white;
                        border-radius: 25px;
                      "
                    >
                      <v-select
                        hide-details
                        dense
                        class="assign_select font_400"
                        outlined
                        rounded
                        height="32"
                        style=""
                        full-width
                        v-if="sites"
                        v-model="selected_site"
                        :items="sites"
                        label="Site"
                      >
                      </v-select>
                    </div>
                    <GmapMap
                      :="{
                        lat: parseFloat(markers[0].latitude),
                        lng: parseFloat(markers[0].longitude),
                      }"
                      :zoom="markers[0].map_zoom_level"
                      map-type-id="hybrid"
                      style="width: inherit; height: 390px"
                      :options="{
                        mapTypeControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                      }"
                      @zoom_changed="get_zoom"
                    >
                      <GmapMarker
                        :key="index + m.position"
                        v-for="(m, index) in markers[1]"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @mouseover="set_marker(m, index)"
                        @mouseout="set_marker(false, false)"
                        :icon="m.icon"
                      >
                        <GmapInfoWindow :opened="open_marker === index"
                          ><h3>{{ m.title }}</h3>
                          <img height="120" alt="" :src="m.thumbnail" />
                        </GmapInfoWindow>
                      </GmapMarker>
                    </GmapMap>
                  </v-card-text> -->
                  <!-- <v-card-text
                    v-if="customer_select != 'All Customers' && !markers"
                    class="pa-0"
                  >
                    <div class="map_placeholder_height">NO DATA</div>
                  </v-card-text> -->
                </v-card>
              </v-col>

              <v-col xl="4" lg="4" cols="12" class="max_width pt-0"
                >
                <info-card :customer="customer_select" v-if="load_ready"
              /></v-col>
         
            </v-row>
          </v-card>
            <media_chart_row :customer="customer_select" />
        </v-col>
      
      </v-row>

      <!-- <v-row justify="">
        <v-col xl="10" lg="11" md="12">
          <v-card class="pl-3 pr-3">
            <v-row justify="space-between">
              <v-col xl="4" lg="4" class="max_width"
                ><v-card
                  ><v-card-title>Herd Size by Customer</v-card-title
                  ><chartUserHerdSize
                    v-if="load_ready"
                    :stage_prod="stage_prod"
                /></v-card>
              </v-col>

              <v-col xl="4" lg="4" class="max_width"
                ><v-card
                  ><v-card-title>Installed/Pending Cameras</v-card-title
                  ><chartInstalledCams
                    v-if="load_ready"
                    :stage_prod="stage_prod" /></v-card
              ></v-col>
              <v-col xl="4" lg="4" class="max_width"
                ><v-card
                  ><v-card-title>Total Animals</v-card-title
                  ><chartTotalAnimals
                    v-if="load_ready"
                    :stage_prod="stage_prod" /></v-card
              ></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import infoCard from "@/components/info_card.vue";

import customerFilter from "../components/CustomerFilter.vue";
import Vue from "vue";
import media_chart_row from "../components/media_chart_row.vue";
// import { eventBus } from "../main";
// import chartWifiDevice from "@/components/chart_wifi_device.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    infoCard,
    customerFilter,
    media_chart_row
  },
  data() {
    return {
      customer: "All",
      filter_params: {},
      camera: "All",
      videos: [],
      customer_select: "All Customers",
      open_marker: false,
      chart_has_data_1: true,
      chart_has_data_2: true,
      chart_has_data_3: true,
      chart_has_data_4: true,

      selected_site: 0,
      zoom: 5,

      is_inference_video_day: false,
      is_inference_animal_day: false,
      is_inference_frame_day: false,
      is_inference_image_day: false,
      is_ftp_day: false,
      is_vpc_day: false,
    };
  },
  methods: {
    openGoogleEarth(lat, lng, factor){
      // var lat_dist = parseFloat(lat)*factor;
      // var lng_dist = parseFloat(lng)*factor;
      var distance = Math.pow(10, factor)
      window.open(`https://earth.google.com/web/@${lat},${lng},0a,${distance}d,0h,0t,0r`, '_blank');
      // window.location.href=`https://earth.google.com/web/@${lat},${lng},${lat_dist}a,${lng_dist}d`
    },
    get_zoom(zoom) {
      this.zoom = zoom;
    },
    nextCustomer() {
      var c = this.customers;
      const t = (e) => e.value == this.customer_select;
      var i = c.findIndex(t);
      i++;
      if (i > c.length - 1) {
        i = 0;
      }
      this.customer_select = c[i].value;
    },
    previousCustomer() {
      var c = this.customers;
      const t = (e) => e.value == this.customer_select;
      var i = c.findIndex(t);
      i--;
      if (i < 0) {
        i = c.length - 1;
      }
      this.customer_select = c[i].value;
    },
    set_marker(marker, index) {
      if (marker.type == "camera") {
        this.open_marker = index;
      } else {
        this.open_marker = false;
      }
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    select_customer(m) {
      this.customer_select = m.customer_id;
    },
    shuffle(array) {
      var currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
  watch: {
    camera() {
      if (this.camera != "All") {
        if (this.customer != "All") {
          this.filter_params.filter.camera_id = {
            eq: this.cameras[0][this.camera.split("  -  ")[0]],
          };
        } else {
          this.filter_params.filter = {
            camera_id: { eq: this.cameras[0][this.camera.split("  -  ")[0]] },
          };
        }
      } else {
        delete this.filter_params.filter.camera_id;
        if (this.customer == "All") {
          delete this.filter_params.filter;
        }
      }
      this.$store.dispatch("DDB_GET_VIDEOS", this.filter_params);
    },
    markers: {
      handler() {
        this.zoom = this.markers[0].map_zoom_level;
      },
      deep: true,
    },
  },
  computed: {
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return {
                text:
                  (item.customer_company_name
                    ? item.customer_company_name + " - "
                    : "") +
                  (item.customer_first_name
                    ? item.customer_first_name + " "
                    : "") +
                  (item.customer_last_name == item.customer_first_name
                    ? ""
                    : item.customer_last_name),
                value: item.id,
              };
            } catch {
              return "null";
            }
          })
        ),
      ];

      customers = this.sortByKey([...customers], "text");
      customers.unshift({ text: "All Customers", value: "All Customers" });

      return customers;
    },
    inference_database(){
      if (!this.customer_select || this.customer_select == "All Customers") {
        return "production_inference_analytics";
      } else {
        return "production_inference_customer";
      }
    },
    inference_table() {
      if (!this.customer_select || this.customer_select == "All Customers") {
        return "inference";
      } else {
        return `"${this.customer_select}"`;
      }
    },
    inference_video_panelID() {
      if (!this.customer_select || this.customer_select == "All Customers") {
        if (this.is_inference_video_day) {
          return 144;
        } else {
          return 135;
        }
      } else {
        return 80;
      }
    },
    inference_animal_panelID(){
      if (!this.customer_select || this.customer_select == "All Customers") {
        if (this.is_inference_animal_day) {
          return 141;
        } else {
          return 132;
        }
      } else {
        return 66;
      }
    },
    inference_image_panelID(){
      if (!this.customer_select || this.customer_select == "All Customers") {
        if (this.is_inference_image_day) {
          return 146;
        } else {
          return 137;
        }
      } else {
        return 115;
      }
    },
    inference_frame_panelID(){
      if (!this.customer_select || this.customer_select == "All Customers") {
        if (this.is_inference_frame_day) {
          return 173;
        } else {
          return 172;
        }
      } else {
        return 68;
      }
    },
    inference_video_time_bucket() {
      if (this.is_inference_video_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    inference_animal_time_bucket() {
      if (this.is_inference_animal_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    inference_frame_time_bucket() {
      if (this.is_inference_frame_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    inference_image_time_bucket() {
      if (this.is_inference_image_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    ftp_time_bucket() {
      if (this.is_ftp_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    vpc_time_bucket(){
      if (this.is_vpc_day) {
        return "1day";
      } else {
        return "1hour";
      }
    },
    vpc_client(){
      if (!this.customer_select || this.customer_select == "All Customers") {
        return "user_id";
      } else {
        return `'${this.customer_select}'`;
      }
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    load_ready() {
      if (this.customer_select == "All Customers") {
        return this.$store.getters.getSiteObjects.length > 0;
        // && this.$store.getters.getCameraObjects.length > 0
        // ? // && this.$store.getters.getCustomerObjects.length > 0 &&
        //   // this.$store.getters.getCognitoUsers.length > 0 &&
        //   // this.$store.getters.getJetsonObjects.length > 0
        //   true
        // : false;
      }
      return true;
    },
    markers() {
      var markers = [];
      if (this.customer_select) {
        for (const i in this.$store.getters.getSiteObjects) {
          let temp_icon = "none";
          if (this.$store.getters.getSiteObjects[i].operation_type) {
            if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Cow/Calf'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/cow_icon4.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Sheep'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/sheep_icon4.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Dairy'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/cow_icon5.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Meat Goats'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/goat_icon.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Bison'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/bison_icon4.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Elk'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/elk_icon6.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Horse'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/horse_icon4.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Academic'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/college_icon4.png";
            } else if (
              this.$store.getters.getSiteObjects[i].operation_type[0] ==
              "'Head Office'"
            ) {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/hq_icon6.png";
            } else {
              temp_icon =
                "https://map-icons-onecup.s3.us-west-2.amazonaws.com/cow_icon4.png";
            }
          }
          if (this.$store.getters.getSiteObjects[i].coordinates_long) {
            markers.push({
              position: {
                lat: parseFloat(
                  this.$store.getters.getSiteObjects[i].coordinates_lat
                    ? this.$store.getters.getSiteObjects[i].coordinates_lat
                    : 0
                ),
                lng: parseFloat(
                  this.$store.getters.getSiteObjects[i].coordinates_long
                    ? this.$store.getters.getSiteObjects[i].coordinates_long
                    : 0
                ),
              },
              icon: temp_icon,
              title: this.$store.getters.getSiteObjects[i].name,
              customer_id: this.$store.getters.getSiteObjects[i].customer_id,
            });
          }
        }

        return markers;
      }
      //  else if (this.$store.getters.getCustomer.sites) {
      //   for (const i in this.$store.getters.getCustomer.cameras.items) {
      //     var cam_color = "ORANGE";
      //     if (
      //       this.$store.getters.getCustomer.cameras.items[i]
      //         .camera_map_icon_color
      //     ) {
      //       cam_color =
      //         this.$store.getters.getCustomer.cameras.items[i]
      //           .camera_map_icon_color;
      //     }
      //     markers.push({
      //       position: {
      //         lat: parseFloat(
      //           this.$store.getters.getCustomer.cameras.items[i].camera_location
      //             ? this.$store.getters.getCustomer.cameras.items[i]
      //                 .camera_location.latitude
      //             : 0
      //         ),
      //         lng: parseFloat(
      //           this.$store.getters.getCustomer.cameras.items[i].camera_location
      //             ? this.$store.getters.getCustomer.cameras.items[i]
      //                 .camera_location.longitude
      //             : 0
      //         ),
      //       },
      //       icon:
      //         "https://map-icons-onecup.s3.us-west-2.amazonaws.com/camera_" +
      //         cam_color.toLowerCase() +
      //         ".png",
      //       title: this.$store.getters.getCustomer.cameras.items[i].camera_name,
      //       type: "camera",
      //       thumbnail:
      //         process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
      //         this.$store.getters.getCustomer.id +
      //         "/thumbnails/" +
      //         this.$store.getters.getCustomer.cameras.items[i].id +
      //         "_latest" +
      //         ".jpg",
      //     });
      //   }
      //   for (const i in this.$store.getters.getCustomer.wifi.items) {
      //     var wifi_color = "ORANGE";
      //     if (
      //       this.$store.getters.getCustomer.wifi.items[i].wifi_map_icon_color
      //     ) {
      //       wifi_color =
      //         this.$store.getters.getCustomer.wifi.items[i].wifi_map_icon_color;
      //     }
      //     markers.push({
      //       position: {
      //         lat: parseFloat(
      //           this.$store.getters.getCustomer.wifi.items[i].wifi_location
      //             ? this.$store.getters.getCustomer.wifi.items[i].wifi_location
      //                 .latitude
      //             : 0
      //         ),
      //         lng: parseFloat(
      //           this.$store.getters.getCustomer.wifi.items[i].wifi_location
      //             ? this.$store.getters.getCustomer.wifi.items[i].wifi_location
      //                 .longitude
      //             : 0
      //         ),
      //       },
      //       icon:
      //         "https://map-icons-onecup.s3.us-west-2.amazonaws.com/wifi_" +
      //         wifi_color.toLowerCase() +
      //         ".png",
      //       title: "WIFI",
      //     });
      //   }
      //   for (const i in this.$store.getters.getCustomer.jetsons.items) {
      //     if (
      //       this.$store.getters.getCustomer.jetsons.items[i].jetson_location
      //     ) {
      //       var jetson_color = "ORANGE";
      //       if (
      //         this.$store.getters.getCustomer.cameras.items[i]
      //           .jetson_map_icon_color
      //       ) {
      //         jetson_color =
      //           this.$store.getters.getCustomer.cameras.items[i]
      //             .jetson_map_icon_color;
      //       }
      //       markers.push({
      //         position: {
      //           lat: parseFloat(
      //             this.$store.getters.getCustomer.jetsons.items[i]
      //               .jetson_location
      //               ? this.$store.getters.getCustomer.jetsons.items[i]
      //                   .jetson_location.latitude
      //               : 0
      //           ),
      //           lng: parseFloat(
      //             this.$store.getters.getCustomer.jetsons.items[i]
      //               .jetson_location
      //               ? this.$store.getters.getCustomer.jetsons.items[i]
      //                   .jetson_location.longitude
      //               : 0
      //           ),
      //         },
      //         icon:
      //           "https://map-icons-onecup.s3.us-west-2.amazonaws.com/betsy_" +
      //           jetson_color.toLowerCase() +
      //           ".png",
      //         title: "Betsy",
      //       });
      //     }
      //   }
      //   if (
      //     this.$store.getters.getCustomer.sites.items[this.selected_site] &&
      //     this.$store.getters.getCustomer.sites.items[this.selected_site]
      //       .location &&
      //     this.$store.getters.getCustomer.sites.items[this.selected_site]
      //       .location.latitude &&
      //     !isNaN(
      //       this.$store.getters.getCustomer.sites.items[this.selected_site]
      //         .location.latitude
      //     ) &&
      //     this.$store.getters.getCustomer.sites.items[this.selected_site]
      //       .location.longitude &&
      //     !isNaN(
      //       this.$store.getters.getCustomer.sites.items[this.selected_site]
      //         .location.longitude
      //     )
      //   ) {
      //     return [
      //       {
      //         ...this.$store.getters.getCustomer.sites.items[this.selected_site]
      //           .location,
      //         map_zoom_level:
      //           this.$store.getters.getCustomer.sites.items[this.selected_site]
      //             .map_zoom_level,
      //       },
      //       markers,
      //     ];
      //   } else {
      //     return false;
      //   }
      // } 
      else {
        return false;
      }
    },
    sites() {
      // return this.$store.getters.getCustomer.sites.items.map((e, i) => {
      //   return {
      //     text: e.name,
      //     value: i,
      //   };
      // });
      return []
    },
  },
  created() {
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
    // if (this.$store.getters.getCameraObjects.length == 0) {
    //   this.$store.dispatch("DDB_GET_CAMERAS");
    // }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getSiteNames.length == 0) {
      this.$store.dispatch("DDB_GET_SITES");
    }
    if (this.$store.getters.getWifiObjects.length == 0) {
      this.$store.dispatch("DDB_GET_WIFI");
    }
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.customer_select = Vue.prototype.$selectCustomer;
    }
    // if (this.$store.getters.getEvents.length == 0) {
    //   this.$store.dispatch("DDB_GET_EVENTS", {});
    // }
  },
};
</script>
<style lang="css">
.max_width {
}

.placeholder_height {
  height: 300px;
  line-height: 260px;
  color: grey;
  font-size: xx-large;
}

.chart_height > div:nth-child(2) {
  height: 300px;
}
#icon_info {
  font-size: 10px;
  margin-top: -4px;
  height: 20px;
  padding-left: 6px;
}
.select_customer {
  width: 360px;
  /* max-width: 240px; */
}
.select_customer > div > div > div > div > div {
  color: gray;
  font-size: 16px;
}
.customer_title {
  float: right;
}

.map_placeholder_height {
  height: 390px;
  line-height: 390px;
  color: grey;
  font-size: xx-large;
}
</style>
