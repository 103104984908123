<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Project</span>

              <v-spacer></v-spacer>
              <v-icon
              v-if="selected_correspondence != null"
                large
                class="mr-4 mt-2"
                @click="remove_corr_filter()"
              >
                mdi-email-remove
              </v-icon>
              <v-btn class="mb-n3 mr-4" @click="editItem('create_new')">
                + Add project
              </v-btn>
              <v-btn class="mb-n3 mr-4" @click="delete_items()"> Delete </v-btn>
              <div class="project_select_div" :v-if="contact_names.length > 0">
                <v-select
                  class="mr-4 project_select"
                  v-model="selected_contact"
                  :items="contact_names"
                ></v-select>
              </div>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text v-if="selected_table == 0" class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers_general"
                :items="projects"
                :items-per-page="100"
                class="elevation-1 main_project_table"
                single-expand
                show-expand
                :expanded.sync="expanded"
                dense
                :search="search"
                hide-default-footer
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs" v-model="selected_table">
                    <v-tab>General</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td></td>
                  <td :colspan="3" class="sub_td">
                    <v-simple-table dense fixed-header>
                      <colgroup
                        v-if="
                          selected_sub_table == 0 &&
                          item.project_contact.items.length > 0
                        "
                      >
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="e_percent" />
                      </colgroup>
                      <colgroup
                        v-if="
                          selected_sub_table == 1 &&
                          selected_correspondences[0].length > 0
                        "
                      >
                        <col class="f_percent" />
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="e_percent" />
                      </colgroup>
                      <thead>
                        <v-tabs
                          class="table_tabs pt-1"
                          v-model="selected_sub_table"
                        >
                          <v-tab>Contacts</v-tab>
                          <v-tab>Correspondences</v-tab>
                        </v-tabs>
                        <tr
                          v-if="
                            selected_sub_table == 0 &&
                            item.project_contact.items.length > 0
                          "
                        >
                          <th class="text-left">Name</th>
                          <th class="text-left">Company</th>
                          <th class="text-left">Phone</th>
                          <th class="text-left">Work Email</th>
                          <th class="text-center">
                            <v-icon
                              small
                              class="mr-2"
                              @click="remove('contact', item)"
                            >
                              mdi-delete
                            </v-icon>
                          </th>
                        </tr>
                        <tr
                          v-if="
                            selected_sub_table == 1 &&
                            selected_correspondences[0].length > 0
                          "
                        >
                          <th class="text-left">Subject</th>
                          <th class="text-left">Mode</th>
                          <th class="text-left">Datetime</th>
                          <th class="text-center">
                            <v-icon
                              small
                              class="mr-2"
                              @click="remove('correspondence', item)"
                            >
                              mdi-delete
                            </v-icon>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-if="
                          selected_sub_table == 0 &&
                          item.project_contact.items.length > 0
                        "
                      >
                        <tr
                          v-for="(i, index) in item.project_contact.items"
                          :key="i.contact.id"
                        >
                          <td class="text-left">
                            {{ i.contact.contact_given_name }}
                            {{ i.contact.contact_family_name }}
                          </td>
                          <td class="text-left">
                            {{ i.contact.contact_company_name }}
                          </td>
                          <td class="text-left">
                            {{ i.contact.contact_mobile_phone }}
                          </td>
                          <td class="text-left">
                            {{ i.contact.contact_work_email }}
                          </td>
                          <td class="text-center">
                            <v-simple-checkbox
                              class="inline_cb"
                              :ripple="false"
                              v-model="item.project_contact.items[index].delete"
                            ></v-simple-checkbox>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        v-if="
                          selected_sub_table == 0 &&
                          item.project_contact.items.length < 1
                        "
                      >
                        <tr class="text_align_left">
                          <span>No contacts for this project</span>
                        </tr>
                      </tbody>
                      <tbody
                        v-if="
                          selected_sub_table == 1 &&
                          selected_correspondences[0].length > 0
                        "
                      >
                        <tr
                          v-for="(i, index) in selected_correspondences[0]"
                          :key="i.id"
                        >
                          <td class="text-left">
                            {{ i.correspondence_subject }}
                          </td>
                          <td class="text-left">
                            {{ i.correspondence_communication_mode }}
                          </td>
                          <td class="text-left">
                            {{ i.correspondence_datetime }}
                          </td>
                          <td class="text-center">
                            <v-simple-checkbox
                              class="inline_cb"
                              :ripple="false"
                              v-model="
                                selected_correspondences[0][index].delete
                              "
                            ></v-simple-checkbox>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        v-if="
                          selected_sub_table == 1 &&
                          selected_correspondences[0].length < 1
                        "
                      >
                        <tr class="text_align_left">
                          <span>No correspondences for this project</span>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table dense fixed-header>
                      <colgroup class="tasks">
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="tt_percent" />
                        <col class="e_percent" />
                      </colgroup>

                      <thead>
                        <div class="tasks_header_div">
                          <v-tabs class="table_tabs_tasks pt-1">
                            <v-tab>Tasks</v-tab>
                          </v-tabs>
                          <v-btn small class="mt-4" @click="create_task()">
                            + Task
                          </v-btn>
                        </div>

                        <tr v-if="item.tasks.items.length > 0">
                          <th class="text-left">Description</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Due</th>
                          <th class="text-left">Contact</th>
                          <th class="text-center">
                            <v-icon
                              small
                              class="mr-2"
                              @click="remove('tasks', item)"
                            >
                              mdi-delete
                            </v-icon>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="item.tasks.items.length > 0">
                        <tr v-for="(i, index) in item.tasks.items" :key="index">
                          <td class="text-left">
                            {{ i.task_description }}
                          </td>
                          <td class="text-left">
                            {{ i.task_status }}
                          </td>
                          <td class="text-left">
                            {{ i.task_due_date }}
                          </td>
                          <td class="text-left" v-if="i.task_contact">
                            {{
                              i.task_contact.contact_given_name +
                              " " +
                              i.task_contact.contact_family_name
                            }}
                          </td>
                          <td class="text-left" v-else></td>
                          <td class="text-center">
                            <div class="sub_action_div">
                              <v-icon
                                small
                                class="mt-n2"
                                @click="edit_task(index)"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-simple-checkbox
                                class="inline_cb"
                                :ripple="false"
                                v-model="item.tasks.items[index].delete"
                              ></v-simple-checkbox>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-if="item.tasks.items.length < 1">
                        <tr class="text_align_left">
                          <span>No tasks for this project</span>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </td>
                  <td :colspan="2"></td>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="action_div">
                    <v-icon
                      small
                      class="mr-2 mt-n2"
                      @click="redirect_to_customer(item.id)"
                    >
                      mdi-account
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2 mt-n2"
                      @click="redirect_to_correspondence(item.id)"
                    >
                      mdi-email-box
                    </v-icon>
                    <v-icon small class="mr-2 mt-n2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-simple-checkbox
                      class="inline_cb"
                      :ripple="false"
                      v-model="projects[projects.indexOf(item)].delete"
                    ></v-simple-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.project_start_date`]="{ item }">
                  <span
                    v-if="
                      item.project_start_date != 'null' &&
                      item.project_start_date != '' &&
                      typeof item.project_start_date == 'string'
                    "
                    >{{ item.project_start_date }}</span
                  ><span v-else> - </span>
                </template>
                <template v-slot:[`item.project_end_date`]="{ item }">
                  <span
                    v-if="
                      item.project_end_date != 'null' &&
                      item.project_end_date != '' &&
                      typeof item.project_end_date == 'string'
                    "
                    >{{ item.project_end_date }}</span
                  ><span v-else> - </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
      <v-dialog v-model="dialog" max-width="800px" min-width="760px">
        <v-card>
          <v-card-title>
            <span class="text-h5 ml-3" v-if="create_new">Create Customer</span>
            <span class="text-h5 ml-3" v-else>Edit Customer</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.project_name"
                    label="Project Name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu :close-on-content-click="false" offset-y
                    ><template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.project_start_date"
                        v-bind="attrs"
                        v-on="on"
                        label="Start Date"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="editedItem.project_start_date"
                      no-title
                      event-color="#ff9900"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu :close-on-content-click="false" offset-y
                    ><template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.project_end_date"
                        v-bind="attrs"
                        v-on="on"
                        label="End Date"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="editedItem.project_end_date"
                      no-title
                      event-color="#ff9900"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  Description
                  <v-textarea
                    solo
                    v-model="editedItem.project_description"
                  ></v-textarea>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="editedItem.contacts"
                    :items="contact_names"
                    label="Contacts"
                    multiple
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <span class="error_span">{{ error }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Cancel </v-btn>
            <v-btn color="primary" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="task_dialog" max-width="800px" min-width="760px">
        <v-card>
          <v-card-title>
            <span class="text-h5 ml-3">Create Task</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="task_object.task_description"
                    label="Description"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="edit_task_bool">
                  <v-select
                    v-model="task_object.task_status"
                    :items="['PENDING', 'PROGRESS', 'COMPLETE']"
                    label="Status"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="task_object.contact_id"
                    :items="selected_project_contacts"
                    label="Contact"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu :close-on-content-click="false" offset-y
                    ><template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="task_object.task_due_date"
                        v-bind="attrs"
                        v-on="on"
                        label="Due Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="task_object.task_due_date"
                      no-title
                      event-color="#ff9900"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <span class="error_span">{{ error }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close_task"> Cancel </v-btn>
            <v-btn
              color="primary"
              v-if="edit_task_bool"
              text
              @click="update_task"
            >
              Save
            </v-btn>
            <v-btn color="primary" v-else text @click="save_task"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  props: ["filter", "id"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v) ||
          "Enter a valid MAC address",
      },
      headers_general: [
        {
          text: "Project Name",
          value: "project_name",
        },
        {
          text: "Start Date",
          value: "project_start_date",
        },
        {
          text: "End Date",
          value: "project_end_date",
        },
        { text: "Description", value: "project_description" },
        { text: "Action", value: "action", sortable: false, width: "4%" },
      ],
      search: "",
      previous_contacts: [],
      expanded: [],
      staging_production: true,
      loading: true,
      editedItem: { wifi_location: {} },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      selected_sub_table: 0,
      task_dialog: false,
      task_object: {},
      selected_project_contacts: [],
      task_sequence_counter: 0,
      edit_task_bool: false,
      selected_contact: "All Contacts",
      selected_correspondence: null,
    };
  },
  methods: {
    remove_corr_filter() {
      this.selected_correspondence = null
      this.$store.dispatch("DDB_GET_PROJECT", {});
    },
    create_task() {
      this.task_object = {};
      this.edit_task_bool = false;
      this.task_dialog = true;
    },
    edit_task(index) {
      this.edit_task_bool = true;
      this.task_object = Object.assign({}, this.expanded[0].tasks.items[index]);
      console.log(this.task_object);
      this.task_dialog = true;
    },
    async remove(type, item) {
      if (type == "contact") {
        for (const i in item.project_contact.items) {
          if (item.project_contact.items[i].delete)
            await API.graphql({
              query: mutations.deleteProjectContact,
              variables: {
                input: { id: item.project_contact.items[i].id },
              },
            });
        }
        this.$store.dispatch("DDB_GET_PROJECT", {});
      } else if (type == "correspondence") {
        for (const i in this.selected_correspondences[0]) {
          if (this.selected_correspondences[0][i].delete) {
            await API.graphql({
              query: mutations.deleteProjectCorrespondence,
              variables: {
                input: { id: this.selected_correspondences[1][i] },
              },
            });
          }
        }
        this.$store.dispatch("DDB_GET_CORRESPONDENCES_BY_PROJECT", [
          this.expanded[0].id,
          false,
        ]);
      } else if (type == "tasks") {
        for (const i in item.tasks.items) {
          if (item.tasks.items[i].delete) {
            await API.graphql({
              query: mutations.deleteTask,
              variables: {
                input: { id: item.tasks.items[i].id },
              },
            });
            this.task_sequence_counter -= 1;
          }
        }
        this.$store.dispatch("DDB_GET_PROJECT", {});
      }
    },
    async delete_items() {
      try {
        for (const i in this.projects) {
          if (this.projects[i].delete) {
            this.$store.dispatch(
              "DDB_GET_DELETE_PROJECTS_CONTACTS",
              this.projects[i].id
            );
            this.$store.dispatch(
              "DDB_GET_DELETE_PROJECTS_CORR",
              this.projects[i].id
            );
            await API.graphql({
              query: mutations.deleteProject,
              variables: {
                input: { id: this.projects[i].id },
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.dispatch("DDB_GET_PROJECT", {});
    },
    redirect_to_customer(id) {
      this.$router.push({
        name: "contacts",
        params: {
          filter: "project",
          id: id,
        },
      });
    },
    redirect_to_correspondence(id) {
      this.$router.push({
        name: "correspondence",
        params: {
          filter: "project",
          id: id,
        },
      });
    },
    editItem(item) {
      if (item == "create_new") {
        this.editedItem = {};

        this.create_new = true;
        this.editedItem.contacts = [];
        this.dialog = true;
      } else {
        this.editedIndex = this.projects.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.editedItem.contacts = [];
        for (const i in this.editedItem.project_contact.items) {
          this.editedItem.contacts.push(
            this.editedItem.project_contact.items[i].contact.id
          );
        }
        this.previous_contacts = [...this.editedItem.contacts];
        this.dialog = true;
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    close_task() {
      this.task_dialog = false;
      this.error = "";
      this.$nextTick(() => {
        this.task_object = {};
      });
    },
    async update_task() {
      try {
        delete this.task_object.task_contact;
        await API.graphql({
          query: mutations.updateTask,
          variables: {
            input: this.task_object,
          },
        });
        this.$store.dispatch("DDB_GET_PROJECT", {});
        this.task_dialog = false;
        this.task_object = {};
      } catch (err) {
        this.error = err.errors[0].message;
      }
    },
    async save_task() {
      try {
        this.task_object.project_id = this.expanded[0].id;
        this.task_object.task_status = "PENDING";
        this.task_object.task_sequence = this.task_sequence_counter;
        await API.graphql({
          query: mutations.createTask,
          variables: {
            input: this.task_object,
          },
        });
        this.$store.dispatch("DDB_GET_PROJECT", {});
        this.task_dialog = false;
        this.task_sequence_counter += 1;
        this.task_object = {};
      } catch (err) {
        this.error = err.errors[0].message;
      }
    },
    async save() {
      if (this.create_new) {
        try {
          if (this.owner_name) {
            this.editedItem.owner =
              this.$store.getters.getNameOwnerObject[this.owner_name];
          }
          this.editedItem.owner = "onecup";

          var contacts = [...this.editedItem.contacts];
          delete this.editedItem.contacts;
          delete this.editedItem.project_contact;
          var created_project = await API.graphql({
            query: mutations.createProject,
            variables: {
              input: this.editedItem,
            },
          });
          for (const i in contacts) {
            await API.graphql({
              query: mutations.createProjectContact,
              variables: {
                input: {
                  contact_id: contacts[i],
                  project_id: created_project.data.createProject.id,
                  owner: "06d79203-450f-4f44-afd3-04e4a8dfcbaf",
                },
              },
            });
          }
          this.$store.dispatch("DDB_GET_PROJECT", {});
          this.dialog = false;
          this.editedItem = {};
        } catch (err) {
          this.error = err.errors[0].message;
        }
      } else {
        try {
          var delete_contacts = this.previous_contacts.filter(
            (element) => !this.editedItem.contacts.includes(element)
          );
          for (const i in this.editedItem.contacts) {
            if (!this.previous_contacts.includes(this.editedItem.contacts[i])) {
              await API.graphql({
                query: mutations.createProjectContact,
                variables: {
                  input: {
                    contact_id: this.editedItem.contacts[i],
                    project_id: this.editedItem.id,
                    owner: "06d79203-450f-4f44-afd3-04e4a8dfcbaf",
                  },
                },
              });
            }
          }
          for (const n in this.editedItem.project_contact.items) {
            if (
              delete_contacts.includes(
                this.editedItem.project_contact.items[n].contact.id
              )
            ) {
              await API.graphql({
                query: mutations.deleteProjectContact,
                variables: {
                  input: { id: this.editedItem.project_contact.items[n].id },
                },
              });
            }
          }
          delete this.editedItem.contacts;
          delete this.editedItem.project_contact;
          delete this.editedItem.delete;
          this.previous_contacts = [];
          await API.graphql({
            query: mutations.updateProject,
            variables: {
              input: this.editedItem,
            },
          });
          this.$store.dispatch("DDB_GET_PROJECT", {});
          Object.assign(this.projects[this.editedIndex], this.editedItem);
          this.dialog = false;
          this.editedItem = {};
        } catch (err) {
          this.error = err.errors[0].message;
        }
      }
    },
    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer =
          this.$store.getters.getOwnerNameObject[0][this.filter];
      }
    },
  },
  computed: {
    contact_names() {
      var contact_names = [];
      for (const i in this.$store.getters.getContactNames) {
        let contact_name =
          this.$store.getters.getContactNames[i].contact_given_name +
          " " +
          this.$store.getters.getContactNames[i].contact_family_name;
        contact_names.push({
          text: contact_name,
          value: this.$store.getters.getContactNames[i].id,
        });
      }
      contact_names.unshift({
        text: "All Contacts",
        value: "All Contacts",
      });
      return contact_names;
    },
    selected_correspondences() {
      return this.$store.getters.getCorrespondences;
    },
    projects() {
      return this.$store.getters.getProjects;
    },
  },
  created() {
    if (this.$store.getters.getContactNames.length == 0) {
      this.$store.dispatch("DDB_GET_CONTACT_ID_NAMES");
    }
    if (this.filter == "all") {
      this.$store.dispatch("DDB_GET_PROJECT", {});
    } else if (this.filter == "contact") {
      this.selected_contact = this.id;
    } else if (this.filter == "correspondence") {
      this.selected_correspondence = this.id;
    } else {
      this.loading = false;
    }
  },
  watch: {
    expanded() {
      this.selected_project_contacts = [];
      if (this.expanded.length > 0) {
        this.$store.dispatch("DDB_GET_CORRESPONDENCES_BY_PROJECT", [
          this.expanded[0].id,
          false,
        ]);
        for (const i in this.expanded[0].project_contact.items) {
          this.selected_project_contacts.push({
            text:
              this.expanded[0].project_contact.items[i].contact
                .contact_given_name +
              " " +
              this.expanded[0].project_contact.items[i].contact
                .contact_family_name,
            value: this.expanded[0].project_contact.items[i].contact.id,
          });
        }
        this.task_sequence_counter = this.expanded[0].tasks.items.length;
      }
    },
    selected_correspondence() {
      if (this.selected_correspondence != null) {
        this.$store.dispatch(
          "DDB_GET_PROJECT_BY_CORRESPONDENCE2",
          this.selected_correspondence
        );
      }
    },
    selected_contact() {
      if (this.selected_contact != "All Contacts") {
        this.selected_correspondence = null
        this.$store.dispatch(
          "DDB_GET_PROJECTS_BY_CONTACT2",
          this.selected_contact
        );
      } else {
        this.$store.dispatch("DDB_GET_PROJECT", {});
      }
    },
    projects() {
      this.loading = false;
    },
    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.tasks_header_div {
  width: 200px;
}
.remove_btn {
  color: blue;
  background-color: gray;
}
.sub_td {
  width: 60%;
}

.inline_cb {
  display: inline;
  width: 30px;
}
.sub_table_td {
  float: left;
}
</style>