var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),(_vm.herds.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index != 0)?_c('v-btn',{staticClass:"mr-n10",attrs:{"to":'/herd/' +
          _vm.herds[_vm.index - 1].owner +
          '+' +
          _vm.herds[_vm.index - 1].herd_date +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-container',[_c('div',[_c('v-row',[_c('v-btn',{staticClass:"filter_btn ml-3 mb-3",attrs:{"to":'/' + _vm.prev_page.replaceAll('+', '/'),"large":""}},[_vm._v("back ")]),_c('v-spacer')],1),_c('div',[_c('v-card',[_c('v-card-text',{staticClass:"pt-0 pb-0 pl-3 pr-3"},[(_vm.selected_tab == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Unique IDs Histogram ")])],1)],1),_c('div',[(_vm.herds)?_c('herds-histogram'):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"grid_card"},[_c('v-card-text',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Unique IDs Histogram ")])],1)],1),_vm._l((_vm.drop_tags),function(tag){return _c('div',{key:tag},[_c('span',[_vm._v(_vm._s(tag))]),_c('div',{staticClass:"grid-container-70"},[_c('div',{staticClass:"nonappeared bordered"},[_vm._v(" ")]),_vm._l((_vm.dates),function(date){return _c('div',{key:date,staticClass:"bordered"},_vm._l((_vm.herds),function(herd){return _c('div',{key:herd.herd_date},[(
                                    herd.herd_date == date &&
                                    herd.herd_ocr_id.includes(tag)
                                  )?_c('div',{staticClass:"appeared bordered"},[_vm._v("   ")]):_vm._e()])}),0)})],2)])}),_c('div',{staticClass:"grid-container-70"},[_c('div',{}),_vm._l((_vm.dates),function(date){return _c('div',{key:date,staticClass:"vertical_text"},[_vm._v(" "+_vm._s(date)+" ")])})],2)],2)],1)],1)],1):_vm._e()],1)],1)],1)],1)])],1),_c('v-col',{staticClass:"nav_btn",attrs:{"xl":"1","lg":"1","align-self":"center"}},[(_vm.index < _vm.herds.length - 1)?_c('v-btn',{staticClass:"ml-n10",attrs:{"to":'/herd/' +
          _vm.herds[_vm.index + 1].owner +
          '+' +
          _vm.herds[_vm.index + 1].herd_date +
          '&' +
          _vm.sort_by +
          '&' +
          _vm.sort_desc +
          '&' +
          _vm.prev_page,"icon":""}},[_c('v-icon',{staticClass:"nav_icon"},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }