<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pl-2 pr-2">
            <v-row>
              <v-col class="pa-0" cols="12"
                ><v-tabs class="table_tabs ml-3 mt-3 mr-3">
                  <v-tab to="/">Overview</v-tab>
                  <v-tab to="/footageuploads">Footage</v-tab>
                  <v-tab to="/videolist">Videos</v-tab>
                  <v-tab to="/betsylist">Betsy</v-tab>
                </v-tabs></v-col
              >

              <v-col xl="2" md="2" sm="2" cols="6" class="main_col_vids">
                <v-row no-gutters>
                  <v-col xl="9" cols="10"
                    ><v-select
                      class="select_filter direction_sort mt-2"
                      :items="[
                        'Random',
                        'Date',
                        'Size',
                        'Duration',
                        'Camera',
                        'Customer',
                      ]"
                      v-model="sort"
                      dense
                      flat
                      hide-details
                    ></v-select
                  ></v-col>
                  <v-col xl="3"
                    ><v-icon
                      v-if="!sort_direction && sort != 'Random'"
                      id="sort_icon"
                      @click="changeSortDir"
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon
                      v-if="sort_direction && sort != 'Random'"
                      id="sort_icon"
                      @click="changeSortDir"
                      >mdi-arrow-up</v-icon
                    ></v-col
                  >
                </v-row>
              </v-col>
              <v-col xl="1" md="1" sm="2" class="mb_hidden">
                <v-select
                  class="select_filter select_grid mt-2"
                  :items="[6, 12, 24]"
                  v-model="grid"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
              <v-col xl="2" md="2" sm="3" cols="6"
                ><v-select
                  class="select_filter mt-2"
                  :items="[
                    'All Sources',
                    'Jetson',
                    'Reolink Cloud',
                    'IPhone',
                    'FTP',
                  ]"
                  v-model="source"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
              <v-col xl="2" md="2" sm="2" cols="6">
                <v-select
                  class="select_filter mt-2"
                  :items="customers"
                  v-model="customer"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
              <v-col xl="2" md="2" sm="3" cols="6"
                ><v-select
                  class="select_filter mt-2"
                  :items="cameras[1]"
                  v-model="camera"
                  dense
                  flat
                  hide-details
                ></v-select
              ></v-col>
            </v-row>
            <v-row class="ml-0 mr-0 pb-3" v-if="videos">
              <div class="grid-container-6" v-if="grid == 6">
                <div
                  class="pa-0"
                  v-for="(video, index) in videos"
                  :key="video.video_path + video.createdAt"
                >
                  <img
                    @click="select_video(index)"
                    alt=""
                    :id="video.video_path + video.createdAt"
                    :src="`${
                      cookie_bucket + video.video_path + '_480x270.jpg'
                    }`"
                    class="grid_img"
                  />
                </div>
              </div>
              <div class="grid-container-12" v-if="grid == 12">
                <div
                  class="pa-0"
                  v-for="(video, index) in videos"
                  :key="video.video_path + video.createdAt"
                >
                  <img
                    @click="select_video(index)"
                    alt=""
                    :id="video.video_path + video.createdAt"
                    :src="`${
                      cookie_bucket + video.video_path + '_480x270.jpg'
                    }`"
                    class="grid_img"
                  />
                </div>
              </div>
              <div class="grid-container-24" v-if="grid == 24">
                <div
                  class="pa-0"
                  v-for="(video, index) in videos"
                  :key="video.video_path + video.createdAt"
                >
                  <img
                    @click="select_video(index)"
                    alt=""
                    :id="video.video_path + video.createdAt"
                    :src="`${
                      cookie_bucket + video.video_path + '_480x270.jpg'
                    }`"
                    class="grid_img"
                  />
                </div>
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        max-width="1200px"
        min-width="450px"
        v-if="videos && selected_index != null"
      >
        <v-card>
          <v-card-title v-if="videos[selected_index]">
            <div id="full_size_ct_div">
              <b class="footage_link">{{
                videos[selected_index].camera.camera_name
              }}</b>

              <span class="nav_arrows_f">
                <!-- <a
                  :href="
                    cookie_bucket + videos[selected_index].video_path + '.mp4'
                  "
                >
                  <v-icon class="pr-1">mdi-download</v-icon>
                </a> -->
                <span class="filter_heading">
                  <v-icon
                    large
                    class="mt-n1"
                    @click="select_video(selected_index - 1)"
                    v-if="selected_index > 0"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon large class="mt-n1" v-else disabled
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    large
                    class="mt-n1"
                    @click="select_video(selected_index + 1)"
                    v-if="selected_index < videos.length - 1"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon large class="mt-n1" v-else disabled
                    >mdi-chevron-right</v-icon
                  ></span
                >
              </span>
            </div>
          </v-card-title>

          <v-card-text>
            <video-player
              ref="Player"
              class="vjs-big-play-centered"
              :options="{
                autoplay: true,
                controls: true,
                fluid: true,
              }"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import Vue from "vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import videojs from "video.js";
// import chartUserHerdSize from "@/components/chart_user_herd_size.vue";
// import chartInstalledCams from "@/components/chart_installed_cams.vue";
// import chartTotalAnimals from "@/components/chart_total_animals.vue";
import { eventBus } from "../main.js";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    VideoPlayer,
    // chartUserHerdSize,
    // chartInstalledCams,
    // chartTotalAnimals,
  },
  data() {
    return {
      customer: "All Customers",
      filter_params: {},
      camera: "All Cameras",
      grid: 12,
      sort: "Random",
      sort_direction: false,
      source: "All Sources",
      selected_index: null,
      dialog: false,
    };
  },
  methods: {
    playVideo() {
      var duration = this.videos[this.selected_index].video_duration;
      if (duration.slice(0, 2) == "00" && parseInt(duration.slice(3, 5)) < 15) {
        this.playMP4();
      } else {
        this.playHLS();
      }
    },
    select_video(index) {
      this.selected_index = index;
      this.dialog = true;
      try {
        if (this.videos[this.selected_index].camera_id) {
          try {
            this.playVideo();
          } catch {
            console.log("err");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    playHLS() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.videos[this.selected_index].video_path;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";
      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },

    playMP4() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.videos[this.selected_index].video_path;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".mp4";
      player.src({
        src: source,
        type: "video/mp4",
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },
    changeSortDir() {
      this.sort_direction = !this.sort_direction;
    },
    shuffle(array) {
      var currentIndex = array.length,
        randomIndex;
      Vue.prototype.$randomList = [];
      for (let i = 0; i < array.length; i++) {
        this.$randomList.push(Math.random());
      }
      for (const n in this.$randomList) {
        randomIndex = Math.floor(this.$randomList[n] * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByNestedDESC(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByKeyDESC(array, key) {
      return array.sort(function (a, b) {
        var x = b[key];
        var y = a[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
  watch: {
    source() {
      let temp_source = "";
      if (this.source == "Jetson") {
        temp_source = "JETSON";
      } else if (this.source == "Reolink Cloud") {
        temp_source = "REOLINK_CLOUD";
      }
      if (this.source == "IPhone") {
        temp_source = "IPHONE";
      }
      if (this.source == "FTP") {
        temp_source = "FTP";
      }
      if (this.source != "All Sources") {
        if (this.filter_params.filter == undefined) {
          this.filter_params.filter = {};
        }
        this.filter_params.filter.video_source = {
          eq: temp_source,
        };
      } else {
        delete this.filter_params.filter.video_source;
        if (this.customer == "All Customers" && this.camera == "All Cameras") {
          delete this.filter_params.filter;
        }
      }
      this.$store.dispatch("DDB_GET_VIDEOS2", this.filter_params);
    },
    customer() {
      if (this.customer != "All Customers") {
        if (this.camera != "All Cameras") {
          this.filter_params.filter.owner = {
            eq: this.$store.getters.getNameOwnerObject[
              this.customer.split("  -  ")[0]
            ],
          };
          this.camera = "All Cameras";
        } else {
          if (this.filter_params.filter == undefined) {
            this.filter_params.filter = {};
          }
          this.filter_params.filter.owner = {
            eq: this.$store.getters.getNameOwnerObject[
              this.customer.split("  -  ")[0]
            ],
          };
          this.$store.dispatch("DDB_GET_VIDEOS2", this.filter_params);
        }
      } else {
        delete this.filter_params.filter.owner;
        if (this.camera == "All Cameras" && this.source == "All Sources") {
          delete this.filter_params.filter;
        }

        this.$store.dispatch("DDB_GET_VIDEOS2", this.filter_params);
      }
    },
    camera() {
      if (this.camera != "All Cameras") {
        if (this.customer != "All Customers") {
          this.filter_params.filter.camera_id = {
            eq: this.cameras[0][this.camera.split("  -  ")[0]],
          };
        } else {
          this.filter_params.filter = {
            camera_id: { eq: this.cameras[0][this.camera.split("  -  ")[0]] },
          };
        }
      } else {
        delete this.filter_params.filter.camera_id;
        if (this.customer == "All Customers" && this.source == "All Sources") {
          delete this.filter_params.filter;
        }
      }
      this.$store.dispatch("DDB_GET_VIDEOS2", this.filter_params);
    },
  },
  computed: {
    videos() {
      if (this.sort == "Random") {
        return this.shuffle([...this.$store.getters.getVideos].slice(0, 480));
      } else if (this.sort == "Date" && !this.sort_direction) {
        return this.sortByKey(
          [...this.$store.getters.getVideos],
          "video_datetime"
        ).slice(0, 480);
      } else if (this.sort == "Size" && !this.sort_direction) {
        return this.sortByKey(
          [...this.$store.getters.getVideos],
          "video_size"
        ).slice(0, 480);
      } else if (this.sort == "Duration" && !this.sort_direction) {
        return this.sortByKey(
          [...this.$store.getters.getVideos],
          "video_duration"
        ).slice(0, 480);
      } else if (this.sort == "Camera" && !this.sort_direction) {
        return this.sortByNested(
          [...this.$store.getters.getVideos],
          "camera.camera_name"
        ).slice(0, 480);
      } else if (this.sort == "Customer" && !this.sort_direction) {
        return this.sortByNested(
          [...this.$store.getters.getVideos],
          "customer.customer_first_name"
        ).slice(0, 480);
      } else if (this.sort == "Date" && this.sort_direction) {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "video_datetime"
        ).slice(0, 480);
      } else if (this.sort == "Size" && this.sort_direction) {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "video_size"
        ).slice(0, 480);
      } else if (this.sort == "Duration" && this.sort_direction) {
        return this.sortByKeyDESC(
          [...this.$store.getters.getVideos],
          "video_duration"
        ).slice(0, 480);
      } else if (this.sort == "Camera" && this.sort_direction) {
        return this.sortByNestedDESC(
          [...this.$store.getters.getVideos],
          "camera.camera_name"
        ).slice(0, 480);
      } else if (this.sort == "Customer" && this.sort_direction) {
        return this.sortByNestedDESC(
          [...this.$store.getters.getVideos],
          "customer.customer_first_name"
        ).slice(0, 480);
      } else {
        return [];
      }
    },
    cameras() {
      if (this.$store.getters.getCameraObjects.length > 0) {
        var cameras = [{}, []];
        if (this.customer == "All Customers") {
          for (const i in this.$store.getters.getCameraObjects) {
            cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
              this.$store.getters.getCameraObjects[i].id;
            cameras[1].push(
              this.$store.getters.getCameraObjects[i].camera_name +
                "  -  " +
                this.$store.getters.getCameraObjects[i].videos.items.length
            );
          }
        } else {
          for (const i in this.$store.getters.getCameraObjects) {
            if (
              this.$store.getters.getCameraObjects[i].owner ==
              this.$store.getters.getNameOwnerObject[
                this.customer.split("  -  ")[0]
              ]
            ) {
              cameras[0][this.$store.getters.getCameraObjects[i].camera_name] =
                this.$store.getters.getCameraObjects[i].id;
              cameras[1].push(
                this.$store.getters.getCameraObjects[i].camera_name +
                  "  -  " +
                  this.$store.getters.getCameraObjects[i].videos.items.length
              );
            }
          }
        }
        const sortAlphaNumCams = (a, b) =>
          a.localeCompare(b, "en", { numeric: true });
        cameras[1].sort(sortAlphaNumCams);
        cameras[1].unshift("All Cameras");

        return cameras;
      } else {
        return false;
      }
    },
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return item.customer_first_name + " " + item.customer_last_name;
            } catch {
              return "null";
            }
          })
        ),
      ];
      const sortAlphaNumCams = (a, b) =>
        a.localeCompare(b, "en", { numeric: true });
      customers.sort(sortAlphaNumCams);
      customers.unshift("All Customers");

      return customers;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
  },
  created() {
    eventBus.$on("player_ready", () => {
      try {
        this.playVideo();
      } catch {
        console.log("err");
      }
    });
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
    if (this.$store.getters.getCameraObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERAS");
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getSiteNames.length == 0) {
      this.$store.dispatch("DDB_GET_SITES");
    }
    // if (localStorage.getItem("restore_filter") == "false") {
    //   this.$store.dispatch("DDB_GET_VIDEOS2", { limit: 1000 });
    // }
    this.source = "FTP";
    if (this.$store.getters.getJetsonObjects.length == 0) {
      this.$store.dispatch("DDB_GET_JETSONS");
    }
    if (this.$store.getters.getWifiObjects.length == 0) {
      this.$store.dispatch("DDB_GET_WIFI");
    }
    if (this.$store.getters.getEvents.length == 0) {
      this.$store.dispatch("DDB_GET_EVENTS", {});
    }
  },
};
</script>
<style lang="css" scoped>
#sort_icon {
  height: 24px;
  top: 30%;
}

#icon_info {
  font-size: 10px;
  margin-top: -4px;
  height: 20px;
  padding-left: 6px;
}
.grid-container-24 {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  width: 100%;
}
.grid-container-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}
.grid-container-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .grid-container-24 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
  .grid-container-12 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
  .grid-container-6 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
}
.grid_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid_img:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
}
.grid_img_12:hover {
  transform: scale(1.5);
  box-shadow: 0 0 10px black;
}
.grid_img_24:hover {
  transform: scale(1.8);
  box-shadow: 0 0 10px black;
}
.select_grid {
  float: left;
  width: 55px;
}
</style>
