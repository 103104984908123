<template>
    <v-container pa-0 overflow-hidden>
      <v-row align="center">
        <v-divider></v-divider>{{text}}<slot></slot><v-divider></v-divider>
      </v-row>
    </v-container>
</template>

<script>
// @vuese
// The divider with text
export default {
    name: "TextDivider",
    props: {
        text: String
    }
}
</script>
