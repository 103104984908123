<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Cameras</span>
              <v-spacer></v-spacer>
              <v-switch
                class="mt-0 mr-6 mb-0"
                v-model="editable"
                label="Edit"
                color="primary"
                inset
                hide-details
              ></v-switch>
              <!-- <v-icon
                    data-cy="btn_livestock_add_animal"
      
                    class="mt-0 pt-0 mr-4"
                    @click="show_thumbnails = !show_thumbnails"
                    large
                    color="primary"
                    >{{ show_thumbnails ? "mdi-image-off" : "mdi-image" }}</v-icon
                  > -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Camera</span>
              </v-tooltip>
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn class="mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn>
              <div style="width: 180px" class="mt-n4 pr-3">
                <v-select
                  :items="[
                    { text: 'None', value: null },
                    { text: 'Customer', value: 'customer.last_name' },
                    { text: 'Shipped', value: 'shipped' },
                    { text: 'Source', value: 'latest_upload_source' },
                    { text: 'Status', value: 'status' },
                    { text: 'Model', value: 'model' },
                  ]"
                  v-model="group_by"
                  flat
                  hide-details
                ></v-select>
              </div>

              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                @change="table_key++"
              ></CustomerFilter>
             
            </v-card-title>
            <v-tabs
                class="table_tabs pt-1"
                style="width: 1000px !important"
                v-model="selected_table"
              >
                <v-tab>General</v-tab>
                <v-tab>Shipping</v-tab>
                <v-tab>Updates</v-tab>
                <v-tab>AWS</v-tab>
                <v-tab>Storage</v-tab>
                <v-tab>Blurry</v-tab>
                <v-tab>Notifications</v-tab>
                <v-tab>Uploads</v-tab>
                <v-tab>By Camera</v-tab>
              </v-tabs>
            <v-card-text>
              <div v-if="this.selected_table == 8">
                <ByCamera :cameras="cameras" :customer_id="selected_customer" :isCustomerSelected="isCustomerSelected"/>
              </div>
              <div v-if="this.selected_table != 8">
                <v-data-table
                  :loading="loading"
                  :headers="displayed_headers"
                  :server-items-length="selected_table != 7 ? camera_count : -1"
                  class="elevation-1 entry_table"
                  :multi-sort="multi_sort"
                  :group-by="group_by"
                  hide-default-footer
                  :sort-by.sync="sort_by"
                  :sort-desc.sync="sort_desc"
                  single-expand
                  :show-expand="selected_table != 7"
                  :expanded.sync="expanded"
                  :key="table_key"
                  :item-class="item_class"
                  ref="camera_table"
                  @current-items="onCurrentItems"
                  :options.sync="options"
                  :items="selected_table != 7 ? cameras : analytics"
                  :items-per-page="100"
                  :custom-filter="customSearch"
                >
                  <template v-slot:expanded-item="{ headers }">
                    <td
                      :colspan="headers.length"
                      class="px-0"
                      v-if="expanded[0]"
                    >
                      <v-data-table
                        :loading="note_loading"
                        loading-text="Loading... Please wait"
                        :headers="headers_notes"
                        :items="selected_notes"
                        :items-per-page="5"
                        sort-by="datetime"
                        :sort-desc="true"
                        style="border-radius: 0"
                        class="elevation-1 expanded_row"
                        hide-default-footer
                        dense
                      >
                        <template
                          v-slot:top="{ pagination, options, updateOptions }"
                        >
                          <div class="d-flex justify-end">
                            <div class="table_notes_title mt-2">
                              <h3>NOTES</h3>
                            </div>
                            <v-data-footer
                              :pagination="pagination"
                              :options="options"
                              @update:options="updateOptions"
                              :items-per-page-options="[5]"
                              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            />
                            <!-- class="table_pagination_notes" -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-3"
                                  @click="open_note"
                                  color="primary"
                                  >mdi-note-plus-outline</v-icon
                                >
                              </template>
                              <span>Add Note</span>
                            </v-tooltip>
                          </div>
                        </template>
                        <template v-slot:[`item.datetime`]="{ item }">
                          {{ item.datetime.split("T")[0] }}
                        </template>
                        <template v-slot:[`item.author`]="{ item }">
                          <span>{{ item.author }}</span>
                        </template>
                        <template v-slot:[`item.comment`]="{ item }">
                          <v-edit-dialog
                            @save="save_notes(item)"
                            @cancel="edit_value_note = null"
                            large
                            @open="edit_value_note = item.comment"
                          >
                            {{ item.comment }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="edit_value_note"
                                label="Note"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                  <template v-slot:[`item.customer_id`]="{ item }">
                    <span
                      >{{ item.customer.first_name }}
                      {{ item.customer.last_name }}</span
                    >
                  </template>
                  <template
                    v-for="y in video_years"
                    v-slot:[`item.video_total_size_${y}`]="{ item }"
                  >
                    <span :key="'video_total_size_' + y">
                      <span
                        v-if="
                          !selected_customer ||
                          selected_customer == 'All Customers'
                        "
                        >Select Customer</span
                      >
                      <span v-else>{{
                        formatBytes(item["video_total_size_" + y])
                      }}</span>
                    </span>
                  </template>
                  <!-- <template v-slot:[`item.video_total_size`]="{ item }">
                  <span v-if="!selected_customer || selected_customer=='All Customers'">Select Customer to View Total</span>
                  <span v-else>{{formatBytes(item.video_total_size)}}</span>
                </template> -->
                  <template v-slot:[`item.inventory_number`]="{ item }">
                    <span>{{
                      item.inventory_number ? item.inventory_number : "-"
                    }}</span>
                  </template>
                  <template v-slot:[`item.index`]="{ index }">
                    <span>{{ index + 1 }}</span>
                  </template>
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200, 500]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </template>
                  <template v-slot:[`item.id`]="{ item }">
                    <div
                      class="d-flex justify-space-between"
                      style="width: 170px"
                    >
                      <span>{{ item.id }}</span
                      ><copy-button :text="item.id"></copy-button>
                    </div>
                    <!-- <div class="d-flex">
                    <copy-button :text="item.id"></copy-button><span class="ml-1">{{item.id}}</span>
                  </div> -->
                  </template>

                  <template v-slot:[`item.name`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'name', index)"
                      @cancel="cancel_edit"
                      @open="open_field(item.name)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.name"
                    >
                      {{ item.name }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="edit_value"
                          label="Name"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.customer`]="{ item, index }">
                    <v-edit-dialog
                      @save="
                        save_record(item, 'customer_id', index, 'customer')
                      "
                      @cancel="cancel_edit"
                      @open="open_field(item.customer_id)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.customer_id"
                    >
                      <span v-if="item.customer">{{
                        (item.customer.first_name
                          ? item.customer.first_name + " "
                          : "") + item.customer.last_name
                      }}</span>

                      <template v-slot:input>
                        <v-autocomplete
                          v-model="edit_value"
                          :items="customer_names"
                          label="Customer"
                          dense
                          outlined
                          class="pt-2"
                          :return-object="true"
                        ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.site`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'site_id', index, 'site')"
                      @cancel="cancel_edit"
                      @open="open_field(item.site_id)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.site_id"
                    >
                      <span v-if="item.site">{{
                        item.site ? item.site.name : ""
                      }}</span>

                      <template v-slot:input>
                        <v-autocomplete
                          v-model="edit_value"
                          :items="site_names(item.customer_id)"
                          label="Site"
                          dense
                          outlined
                          class="pt-2"
                          :return-object="true"
                        ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.status`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'status', index)"
                      @cancel="cancel_edit"
                      @open="open_field(item.status)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.status"
                    >
                      <span
                        v-if="item.status == 'ONLINE'"
                        class="green_color"
                        >{{ item.status }}</span
                      >
                      <span
                        v-if="item.status == 'OFFLINE'"
                        class="gray_color"
                        >{{ item.status }}</span
                      >
                      <span
                        v-if="item.status == 'WARNING'"
                        class="orange_color"
                        >{{ item.status }}</span
                      >
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="$store.getters.getCameraOnlineStatus"
                          label="Status"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.shipped`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'shipped', index)"
                      @cancel="cancel_edit"
                      @open="open_field(item.shipped)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.shipped"
                    >
                      {{ item.shipped }}
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="$store.getters.getCameraShippingStatus"
                          label="Status"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template
                    v-slot:[`item.calving_notifications_enabled`]="{
                      item,
                      index,
                    }"
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item,
                          'calving_notifications_enabled',
                          index
                        )
                      "
                      @cancel="cancel_edit"
                      @open="open_field(item.calving_notifications_enabled)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.calving_notifications_enabled"
                    >
                      {{ item.calving_notifications_enabled }}
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="[true, false]"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template
                    v-slot:[`item.estrus_notifications_enabled`]="{
                      item,
                      index,
                    }"
                  >
                    <v-edit-dialog
                      @save="
                        save_record(item, 'estrus_notifications_enabled', index)
                      "
                      @cancel="cancel_edit"
                      @open="open_field(item.estrus_notifications_enabled)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.estrus_notifications_enabled"
                    >
                      {{ item.estrus_notifications_enabled }}
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="[true, false]"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template
                    v-slot:[`item.predator_notifications_enabled`]="{
                      item,
                      index,
                    }"
                  >
                    <v-edit-dialog
                      @save="
                        save_record(
                          item,
                          'predator_notifications_enabled',
                          index
                        )
                      "
                      @cancel="cancel_edit"
                      @open="open_field(item.predator_notifications_enabled)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.predator_notifications_enabled"
                    >
                      {{ item.predator_notifications_enabled }}
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="[true, false]"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.camera_notes`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'camera_notes', index)"
                      @cancel="cancel_edit"
                      @open="open_field(item.camera_notes)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.camera_notes"
                    >
                      {{ item.camera_notes }}
                      <template v-slot:input>
                        <v-textarea
                          v-model="edit_value"
                          label="Notes"
                        ></v-textarea>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.model`]="{ item, index }">
                    <v-edit-dialog
                      @save="save_record(item, 'model', index)"
                      @cancel="cancel_edit"
                      @open="open_field(item.model)"
                      @close="close_edit"
                      large
                      :return-value.sync="item.model"
                    >
                      {{ item.model }}
                      <template v-slot:input>
                        <v-select
                          v-model="edit_value"
                          :items="$store.getters.getCameraModels"
                          label="Model"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:[`item.latest_upload`]="{ item }">
                    <span>{{ get_date_diff(item.latest_upload) }}</span>
                    <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                  </template>
                  <template
                    v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                  >
                    <div style="display: flex">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="handleExpansion(item, isExpanded)"
                              dense
                              :class="'mr-5'"
                              :color="hover || isExpanded ? 'primary' : 'gray'"
                              >{{
                                isExpanded
                                  ? "mdi-note-off-outline"
                                  : "mdi-note-outline"
                              }}</v-icon
                            >
                          </v-hover>
                        </template>
                        <span>Notes</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(item)"
                              dense
                              :class="'mr-5'"
                              :color="hover ? 'primary' : 'gray'"
                              >mdi-pencil</v-icon
                            >
                          </v-hover>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="selected_table == 5">
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="nukeItem(item)"
                              dense
                              :color="hover ? 'primary' : 'gray'"
                              >mdi-delete</v-icon
                            >
                          </v-hover>
                        </template>
                        <span>Clear Data</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template v-slot:[`body.prepend`]>
                    <tr class="mb_hidden" v-if="selected_table == 0">
                      <td class="select_width align_left"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        v-if="group_by != 'status'"
                        class="select_width align_left"
                      >
                        <v-select
                          class="mt-n1"
                          :items="[
                            'All',
                            ...$store.getters.getCameraOnlineStatus,
                          ]"
                          v-model="status"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>

                      <td
                        v-if="group_by != 'model'"
                        class="select_width align_left"
                      >
                        <v-select
                          class="mt-n1"
                          :items="camera_models"
                          v-model="selected_type"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>
                      <td>
                        <v-select
                          class="mt-n1"
                          :items="['All', 'blurry', 'not_blurry']"
                          v-model="blurriness_filter"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="mb_hidden" v-if="selected_table == 1">
                      <td class="select_width align_left"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td
                        v-if="group_by != 'shipped'"
                        class="select_width align_left"
                      >
                        <v-select
                          class="mt-n1"
                          :items="[
                            'All',
                            ...$store.getters.getCameraShippingStatus,
                          ]"
                          v-model="shipped"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>

                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="mb_hidden" v-if="selected_table == 2">
                      <td class="select_width align_left"></td>

                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td v-if="group_by != 'latest_upload_source'"></td>
                    </tr>
                    <tr class="mb_hidden" v-if="selected_table == 3">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="select_width align_left">
                        <v-select
                          class="mt-n1"
                          :items="yn_list"
                          v-model="cellular"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>
                      <td class="select_width align_left">
                        <v-select
                          class="mt-n1"
                          :items="yn_list"
                          v-model="ftp"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>
                      <td class="select_width align_left">
                        <v-select
                          class="mt-n1"
                          :items="yn_list"
                          v-model="reolink"
                          dense
                          flat
                          hide-details
                        ></v-select>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="mb_hidden" v-if="selected_table == 4">
                      <td class="select_width align_left"></td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    <span v-if="item.updated_at">{{
                      item.updated_at.split("T")[0]
                    }}</span>
                  </template>

                  <template v-slot:[`item.icon`]="{ item }">
                    <v-icon
                      class="cam_icon"
                      v-if="
                        item.model == 'RLC-811A' || item.model.includes('Duo')
                      "
                      >$rlc_511</v-icon
                    >
                    <v-icon
                      class="cam_icon"
                      v-else-if="
                        item.model == 'E1 Zoom' ||
                        item.model == 'E1 Zoom Outdoor'
                      "
                      >$zoom_e1</v-icon
                    ><v-icon
                      class="cam_icon"
                      v-else-if="item.model == 'E1 Outdoor'"
                      >$outdoor</v-icon
                    ><v-icon
                      class="cam_icon"
                      v-else-if="
                        item.model == 'Argus PT' || item.model == 'Argus PT 6'
                      "
                      >$argus_go</v-icon
                    >
                    <v-icon
                      class="cam_icon"
                      v-else-if="item.model == 'Reolink Go PT'"
                      >$reolink_go</v-icon
                    >
                    <v-icon
                      class="cam_icon"
                      v-else-if="
                        item.model == 'ReoLink Cloud' ||
                        item.model == 'RLC-511W' ||
                        item.model == 'RLC-511WA'
                      "
                      >$r511w</v-icon
                    >
                    <v-icon
                      class="cam_icon"
                      v-else-if="item.model == 'RLC-523WA'"
                      >$rlc_523</v-icon
                    >
                    <v-icon
                      class="cam_icon"
                      v-else-if="item.model == 'RLC-542WA'"
                      >$rlc_542</v-icon
                    >
                    <v-icon class="cam_icon" v-else-if="item.model == 'iPhone'"
                      >$iphone</v-icon
                    >
                  </template>
                  <!-- <template v-slot:[`item.image`]="{ item }">
                  <v-img
                    class="ma-1 notification_img"
                    style="border-radius: 12px"
                    aspect-ratio="1.7"
                    v-if="item.media[0]"
                    :src="get_video_path(item.media[0])"
                  />
                </template> -->

                  <template v-slot:[`item.ftp_archive`]="{ item }">
                    <a
                      :href="archvie_ftp_s3 + item.id + '/'"
                      target="_blank"
                      @mouseenter="open_card = false"
                      @mouseout="open_card = true"
                      ><v-icon>mdi-link-variant</v-icon></a
                    >
                  </template>
                  <template v-slot:[`item.ftp`]="{ item }">
                    <a
                      :href="ftp_s3 + item.id + '/'"
                      target="_blank"
                      @mouseenter="open_card = false"
                      @mouseout="open_card = true"
                      ><v-icon>mdi-link-variant</v-icon></a
                    >
                  </template>
                  <template v-slot:[`item.hls`]="{ item }">
                    <a
                      :href="hls_s3 + item.owner + '/ftp/' + item.id + '/'"
                      target="_blank"
                      @mouseenter="open_card = false"
                      @mouseout="open_card = true"
                      ><v-icon>mdi-link-variant</v-icon></a
                    >
                  </template>
                  <template v-slot:[`item.thumbnail`]="{ item }">
                    <a
                      :href="
                        thumbnail_s3 + item.owner + '/ftp/' + item.id + '/'
                      "
                      target="_blank"
                      @mouseenter="open_card = false"
                      @mouseout="open_card = true"
                      ><v-icon>mdi-link-variant</v-icon></a
                    >
                  </template>
                  <template v-slot:[`item.videos`]="{ item }">
                    <v-btn
                      icon
                      :to="'/videos/' + item.id"
                      @mouseenter="open_card = false"
                      @mouseout="open_card = true"
                      ><v-icon>mdi-link-variant</v-icon></v-btn
                    >
                  </template>

                  <template v-slot:[`item.last_status_update`]="{ item }">
                    <span v-if="item.last_status_update != null">{{
                      item.last_status_update.split(" ")[0]
                    }}</span>
                    <span v-else>{{ item.last_status_update }}</span>
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon small class="" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>

                  <template v-slot:[`item.image_per_hour`]="{ item }">
                    <span> {{ Math.floor(item.image_count / 24) }} </span>
                  </template>

                  <template v-slot:[`item.frames_per_minute`]="{ item }">
                    <span> {{ Math.floor(item.total_frames / 1440) }} </span>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <note-card></note-card>
    </v-container>
    <camera-card :query_params="query_params"></camera-card>
    <nuke-card></nuke-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment";
import Vue from "vue";
import { eventBus } from "../main";
import CameraCard from "@/components/CameraCard.vue";
import CopyButton from "../components/CopyButton.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import NoteCard from "../components/NoteCard.vue";
import NukeCard from "../components/NukeCard.vue";
import ByCamera from "@/components/ByCamera.vue";
import { getVideoTotalSize } from "../utilities/videoSizeUtils";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CameraCard,
    CopyButton,
    CustomerFilter,
    NoteCard,
    NukeCard,
    ByCamera,
  },
  props: ["filter"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
        imei: (v) => /^\d {15}$||null$/.test(v) || "Enter a valid IMEI",
        id: (v) => /^([A-Z0-9]+){16}$/.test(v) || "Enter a valid ID",
      },
      open_card: true,
      group_by: null,
      staging_cameras: [],
      production_cameras: [],
      staging_production: true,
      loading: true,
      selected_type: "All",
      ranches: ["All"],
      selected_site: "All",
      selected_customer: "All Customers",
      yn_list: ["All", "YES", "NO"],
      status_list: ["All", "ONLINE", "WARNING", "OFFLINE"],
      cellular: "All",
      ftp: "All",
      reolink: "All",
      todays_date: moment().format("YYYY-MM-DD"),
      last_update_date: [],
      updated_at: [],
      last_upload_date: [],
      dialog: false,
      delete_dialog: false,
      editedItem: {},
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      owner_name: "",
      status: "All",
      shipped: "All",
      blurriness_filter: "All",
      site_name: "",
      camera_broken_bool: false,

      sort_by: ["updated_at"],
      // "updated_at"
      edit_value: null,
      sort_desc: true,
      multi_sort: false,
      expanded: [],
      edit_value_note: null,
      selected_notes: [],
      editable: false,
      note_dialog: false,
      note_text: "",
      table_key: 0,
      note_loading: false,
      filteredItems: [],

      search: "",
      camera_list: [],
      camera_num: 0,
      camera_pages: 0,
      video_size_key: 0,

      cameras: [],
      current_cameras: [],
      total_size_loaded: false,

      options: { sortDesc: [true], limit: 10, offset: 0 },
      show_thumbnails: true,
    };
  },
  methods: {
    get_video_path(video) {
      const timestamp = new Date(video.media_timestamp);
      const year = timestamp.getFullYear();
      const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      const day = timestamp.getDate().toString().padStart(2, "0");
      const hours = timestamp.getHours().toString().padStart(2, "0");
      const minutes = timestamp.getMinutes().toString().padStart(2, "0");
      const seconds = timestamp.getSeconds().toString().padStart(2, "0");

      const overall_time = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      const video_path = `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${video.customer_id}/${video.camera_id}/${year}/${month}/${day}/${video.camera_id}_${overall_time}.jpg`;
      return video_path;
    },
    refresh() {
      this.fetch_cameras();
    },
    nukeItem(item) {
      eventBus.$emit("open_dialog_nuke", {
        item: item,
      });
    },
    fetch_cameras() {
      if (this.selected_table != 7) {
        this.loading = true;
        this.$store.dispatch("DDB_GET_CAMERAS", this.query_params).then(() => {
          this.loading = false;

          this.cameras = this.$store.getters.getCameraObjects;
          if (
            this.selected_table == 5 &&
            this.selected_customer &&
            this.selected_customer != "All Customers"
          ) {
            this.getCurrentFileSize();
          }
        });
      }
    },
    site_names(cus_id) {
      if (this.$store.getters.getCustomerNames[1]) {
        return this.$store.getters.getCustomerNames[1][cus_id];
      } else {
        return [];
      }
    },

    handleExpansion(item, state) {
      this.expanded = [];
      if (!state) {
        this.expanded.push(item);
      }
    },
    onCurrentItems(arr) {
      this.current_cameras = arr;
      this.update_model_number();
    },
    update_model_number() {
      this.filteredItems = this.$refs.camera_table?.$children[0].filteredItems;
    },
    item_class(item) {
      return this.expanded[0]?.id == item.id ? "expanded_row" : "";
    },
    customSearch(value, search, item) {
      return Object.values(item).some(function (v) {
        if (v && (v.first_name || v.last_name)) {
          return (v.first_name + v.last_name)
            .toLowerCase()
            .includes(search.toLowerCase());
        } else {
          return v && v.toString().toLowerCase().includes(search.toLowerCase());
        }
      });
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (x == null) {
          x = "zzz";
        }
        if (y == null) {
          y = "zzz";
        }
        if (key == "latest_upload_source") {
          if (x == y) {
            return a.name.localeCompare(b.name);
          }
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    open_note() {
      eventBus.$emit("open_dialog_note");
    },
    async add_note(note) {
      this.note_loading = true;
      if (!this.expanded[0]) {
        return false;
      }
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.insert_admin_note_one,
          variables: {
            object1: {
              datetime: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
              id: uuidv4(),

              comment: note,
              camera_id: this.expanded[0].id,
              customer_id: this.expanded[0].customer_id,
              author: this.$store.getters.getUser.username,
            },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "camera",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
            this.note_loading = false;
          });
      });
    },
    async save_notes(note) {
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.update_admin_note_by_pk,
          variables: {
            pk_columns: { id: note.id },
            _set: { comment: this.edit_value_note },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "camera",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
          });
      });
    },
    close_edit() {
      //this.edit_value = null;
    },
    async save_record(record, field, index, type) {
      var edited_record = {};

      if (type) {
        edited_record[field] = this.edit_value.value;
      } else {
        edited_record[field] = this.edit_value;
      }

      try {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_camera_by_pk,
            variables: {
              pk_columns: { id: record.id },
              _set: edited_record,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.data.errors) {
          throw new Error("GraphQL error: " + response.data.errors[0].message);
        }

        if (type == "customer") {
          this.cameras[index].customer_id = this.edit_value.value;
          this.cameras[index].customer.id = this.edit_value.value;
          this.cameras[index].customer.first_name =
            this.edit_value.text.split(" ")[0];
          this.cameras[index].customer.last_name =
            this.edit_value.text.split(" ")[1];
        } else if (type == "site") {
          this.cameras[index].site_id = this.edit_value.value;
          this.cameras[index].site.id = this.edit_value.value;
          this.cameras[index].site.name = this.edit_value.text;
        } else {
          this.cameras[index][field] = this.edit_value;
        }

        this.edit_value = null;
      } catch (err) {
        console.log(err);
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },
    open_field(value) {
      this.edit_value = value;
    },
    get_date_diff(date) {
      if (!date) {
        return "-";
      }
      var diff = "";
      diff = moment().diff(moment(date, "YYYY-MM-DD hh:mm:ss"), "days");

      return diff + " days";
    },
    clear_last_update_date() {
      this.last_update_date = [];
    },
    clear_updated_at() {
      this.updated_at = [];
    },
    clear_last_upload_date() {
      this.last_upload_date = [];
    },
    editItem(item) {
      if (!this.editable && this.open_card) {
        if (item == "create_new") {
          // this.editedItem = { wifi_location: {} };
          // this.dialog = true;
          // this.create_new = true;
          eventBus.$emit("open_dialog_cameras", {
            item: {},
            create_new: true,
            camera_broken_bool: false,
          });
        } else {
          this.editedItem = Object.assign({}, item);
          this.owner_name = this.editedItem.customer_id;
          this.site_name = this.editedItem.site
            ? this.editedItem.site.id
            : null;
          this.dialog = true;
          var temp_item = Object.assign({}, item);

          eventBus.$emit("open_dialog_cameras", {
            item: temp_item,
            create_new: false,
            owner_name: this.editedItem.customer_id,
            site_name: this.editedItem.site ? this.editedItem.site.id : null,
            camera_broken_bool: this.editedItem.broken == "YES",
          });
        }
      }
    },

    close() {
      this.dialog = false;
      this.error = "";

      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    deleteItemConfirm() {
      this.delete_dialog = false;
      this.editedItem = {};
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    async getCurrentFileSize() {
      // this.current_cameras.forEach((c)=>{
      //   var index = this.cameras.findIndex(e=>e.id == c.id);
      //   if(index!=-1){
      //     var camera = this.cameras[index];
      //     this.getTotalFileSize(camera, index);
      //   }
      // });
      if (this.loading) {
        return;
      }
      for (var index = 0; index < this.cameras.length; index++) {
        var c = this.cameras[index];
        if (c.customer_id == this.selected_customer) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          this.getTotalFileSize(c, index).then(() => {
            var index = this.cameras.findIndex((cam) => cam.id == c.id);
            if (index != -1) {
              this.cameras.splice(index, 1, c);
            }
          });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes == "Loading...") return bytes;
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    async getTotalFileSize(camera_object) {
      var file_size_promises = [];
      for (let i = 0; i < this.video_years.length; i++) {
        var filter = {
          camera_id: {
            eq: camera_object.id,
          },
          video_datetime: {
            gte: moment()
              .year(this.video_years[i])
              .startOf("year")
              .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            lte: moment()
              .year(this.video_years[i])
              .endOf("year")
              .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          },
        };

        file_size_promises.push(
          getVideoTotalSize(filter).then((video_total_size) => {
            // var video_total_size = response.data.searchVideos.aggregateItems[0]?.result?.value;
            camera_object[`video_total_size_${this.video_years[i]}`] =
              video_total_size;
          })
        );
      }
      return Promise.all(file_size_promises);
    },

    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer = this.filter;
      }
    },
  },

  computed: {
    isCustomerSelected(){
      return this.selected_customer && this.selected_customer != "All Customers"
    },
    analytics() {
      return this.$store.getters.getMediaByCameraSummary
        ? this.$store.getters.getMediaByCameraSummary
        : [];
    },
    camera_count() {
      return this.$store.getters.getCameraCount;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      return {
        sort: this.options.sortBy ? this.options.sortBy[0] : "name",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        search: this.search,
        filter: {
          customer: this.selected_customer,
          status: this.status,
          model: this.selected_type,
          blurriness_label: this.blurriness_filter,
          shipped: this.shipped,
        },
      };
    },
    video_years() {
      return this.$store.getters.getVideoYears;
    },
    archvie_ftp_s3() {
      return process.env.VUE_APP_BUCKET_INPUT_FTP;
    },
    ftp_s3() {
      return process.env.VUE_APP_BUCKET_FTP;
    },
    hls_s3() {
      return process.env.VUE_APP_BUCKET_HLS;
    },
    thumbnail_s3() {
      return process.env.VUE_APP_BUCKET_THUMBNAILS;
    },

    displayed_headers() {
      if (this.selected_table == 0) {
        return this.headers_general;
      } else if (this.selected_table == 1) {
        return this.headers_shipping;
      } else if (this.selected_table == 2) {
        return this.headers_last_footage;
      } else if (this.selected_table == 3) {
        return this.headers_aws;
      } else if (this.selected_table == 4) {
        return this.headers_purging;
      } else if (this.selected_table == 5) {
        return this.headers_blurriness;
      } else if (this.selected_table == 6) {
        return this.headers_notification_prefs;
      } else if (this.selected_table == 7) {
        return this.headers_analytics;
      } else if (this.selected_table == 8) {
        return [];
      } else {
        return this.headers_general;
      }
    },
    displayed_fields() {
      return this.fields;
    },
    fields() {
      return [
        "id",
        "name",
        "latest_upload_resolution",
        "status",
        "model",
        "shipped",
        "inventory_number",
      ];
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },

    // site_names() {
    //   if (this.$store.getters.getCustomerNames[1]) {
    //     return this.$store.getters.getCustomerNames[1][this.owner_name];
    //   } else {
    //     return [];
    //   }
    // },
    headers_purging() {
      return [
        { text: "#", value: "index", width: "20px", sortable: false },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_notes() {
      return [
        { text: "#", value: "index", sortable: false },
        {
          text: "Date",
          value: "datetime",
          width: "120px",
        },
        {
          text: "Author",
          value: "author",
          width: "420px",
        },
        {
          text: "Note",
          value: "comment",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
      ];
    },
    headers_general() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        // { text: "", value: this.show_thumbnails ? "image" : 'null', width: this.show_thumbnails ? "200px" : '0px' },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Site Name",
          value: "site",
          sort: (a, b) => {
            if ((!a && !!b) || a?.name < b?.name) {
              return -1;
            }
            if ((!!a && !b) || a?.name > b?.name) {
              return 1;
            }
            return 0;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Status",
          value: "status",
          // sortable: false,

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Model",
          value: "model",

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Blurry",
          // sortable: false,
          value: "blurriness_label",

          width: "120px",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        { text: "", value: "data-table-expand" },
      ];
    },
    fields_general() {
      return ["id", "name", "status", "model"];
    },

    headers_last_footage() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "Resolution", value: "latest_upload_resolution" },
        { text: "FPS", value: "latest_upload_fps" },
        { text: "Bitrate kbps", value: "latest_upload_bitrate_kbps" },
        {
          text: "Corrupted",
          // sortable: false,
          value: "latest_upload_corrupted",
        },
        {
          text: "Time",
          // sortable: false,
          value: "latest_upload",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    fields_last_footage() {
      return ["id", "name", "latest_upload_resolution"];
    },
    headers_aws() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "FTP Archive", value: "ftp_archive", sortable: false },
        { text: "FTP Live", value: "ftp", sortable: false },
        { text: "HLS", value: "hls", sortable: false },
        { text: "Thumbnail", value: "thumbnail", sortable: false },
        { text: "Videos", value: "videos", sortable: false },
        { text: "", value: "data-table-expand" },
      ];
    },

    headers_shipping() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Shipping Status",
          value: "shipped",
          // sortable: false,

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Inventory No.",
          value: "inventory_number",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_blurriness() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Blurry Value",
          value: "blurriness_value",

          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Blurry Label",
          value: "blurriness_label",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_notification_prefs() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "icon", width: "1%", sortable: false },
        { text: "Camera ID", value: "id" },
        {
          text: "Camera Name",
          value: "name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Customer Name",
          value: "customer",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Calving",
          value: "calving_notifications_enabled",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Estrus",
          value: "estrus_notifications_enabled",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Predator",
          value: "predator_notifications_enabled",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        { text: "", value: "data-table-expand" },
      ];
    },
    headers_analytics() {
      return [
        {
          text: "Camera",
          value: "camera_id",
          sortable: false,
          filter: (item) => {
            if (item.indexOf(this.search.toUpperCase()) != -1 || !this.search) {
              return true;
            }
            return false;
          },
        },
        {
          text: "Camera Name",
          value: "camera.name",
        },
        {
          text: "Customer",
          value: "customer_id",
          filter: (item) => {
            if (
              item == this.selected_customer ||
              this.selected_customer == "All Customers"
            ) {
              return true;
            }
            return false;
          },
        },
        {
          text: "Date",
          value: "media_date",
          sortable: false,
        },
        {
          text: "Images",
          value: "image_count",
        },
        {
          text: "Images/hr",
          value: "image_per_hour",
        },
        {
          text: "Videos",
          value: "video_count",
        },
        {
          text: "Total Frames",
          value: "total_frames",
        },
        {
          text: "Frames/min",
          value: "frames_per_minute",
        },
      ];
    },
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return {
                text:
                  (item.first_name ? item.first_name + " " : "") +
                  item.last_name,
                value: item.id,
              };
            } catch {
              return "null";
            }
          })
        ),
      ];

      customers = this.sortByKey([...customers], "text");
      customers.unshift({ text: "All Customers", value: "All Customers" });

      return customers;
    },
    camera_models() {
      return ["All", ...this.$store.getters.getCameraModels];
    },
    camera_objects() {
      var temp_cams = this.$store.getters.getCameraObjects;

      return temp_cams;
    },
  },
  mounted() {
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.selected_customer = Vue.prototype.$selectCustomer;
    }
  },
  created() {
    if (this.$route.query.camera_id) {
      this.search = this.$route.query.camera_id;
    }

    this.$store.dispatch("DDB_GET_CUSTOMERS");
    this.$store.dispatch("DDB_GET_MEDIA_BY_CAMERA_SUMMARY", {
      date: moment().format("YYYY-MM-DD"),
    });

    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(this.owner_filter);
    } else {
      this.owner_filter();
    }

    eventBus.$on("add_note", (note) => {
      this.add_note(note);
    });
    eventBus.$on("updateCameras", () => {
      this.fetch_cameras();
    });
  },
  watch: {
    query_params() {
      if (this.selected_table != 7) {
        if (this.query_params) {
          this.loading = true;
          this.fetch_cameras();
        }
      }
    },
    selected_table() {
      if (
        this.selected_table == 4 &&
        this.selected_customer &&
        this.selected_customer != "All Customers"
      ) {
        // console.log(this.current_cameras);
        this.getCurrentFileSize();
        // this.cameras.forEach((c, index)=>{
        //   this.getTotalFileSize(c, index);
        // });
      }
    },

    selected_customer() {
      if (
        this.selected_table == 4 &&
        this.selected_customer &&
        this.selected_customer != "All Customers"
      ) {
        this.getCurrentFileSize();
      }
    },

    expanded() {
      if (this.expanded[0]) {
        this.selected_notes = [];
        this.note_loading = true;
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "camera",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
              this.note_loading = false;
            }
          });
      }
    },
    dialog() {
      if (this.dialog == false) {
        this.error = "";
        this.owner_name = false;
        this.site_name = "";
        this.create_new = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.cam_icon > svg {
  height: 30px !important;
  width: 30px !important;
  margin-top: 4px;
  margin-bottom: 4px;
}
.center {
}
.select_width {
  max-width: 130px;
}
.align_left {
  text-align: left;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.filter_btn {
  color: gray !important;
}
.v-select__selections {
  flex-wrap: nowrap !important;
}
</style>
