<script>
import { Line } from "vue-chartjs";
import moment from "moment";
//import { eventBus } from "../main.js";

// @vuese
// @group Components
export default {
  extends: Line,
  props: ["weight"],
  data() {
    return {
      videos_by_users: [],
      labels: [],
      options_line: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    populateChart() {
      var temp_list = [];
      let latest_date = this.weight[0].date;
      this.labels = [];
      do {
        this.labels.push(moment(latest_date, "YYYY-MM-DD").format("YYYY-MMM-DD"));

        latest_date = moment(latest_date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } while (moment(latest_date, "YYYY-MM-DD").format("YYYY-MMM-DD") != moment().add(1, "days").format("YYYY-MMM-DD"));

      for (const i in this.labels) {
        temp_list.push(null)
        for (const x in this.weight) {

          if (this.weight[x].date == moment(this.labels[i], "YYYY-MMM-DD").format("YYYY-MM-DD")) {
            temp_list[i] = this.weight[x].measurement
          }
        }
      }
      this.videos_by_users = temp_list;
    },
  },
  computed: {
    cameras() {
      return {
        data_pie: {
          datasets: [
            {
              spanGaps: true,
              data: this.videos_by_users,
              label: "Weight (lb)",
              borderColor: "#AE4773",
              
            },
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  created() {
    this.populateChart();
  },

  watch: {
    customer() {
      this.populateChart();
    },
    videos_by_users() {
      this.renderChart(this.cameras.data_pie, this.options_line);
    },
  },
};
</script>

<style lang="css" scoped>
</style>



