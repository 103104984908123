import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);
Amplify.configure({
  Analytics: {
    disabled: true,
  },
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBAWqigAHgLwMgexZJp2WCmCFK9H8OafI0',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.use(VueToast);

export const eventBus = new Vue();

Vue.config.productionTip = true;

// Vue.mixin({
//   mounted() {
//     console.log("hello world!");
//   }
// });

// Vue.directive('mask', {
//   inserted: function (el, binding) {
    
//     var mask = binding.value,
//         first = mask.indexOf('_'),
//         clean = mask.replace(/[^0-9_]/gm, ''),
//         indexes = []
        
//     for(var i = 0; i < clean.length; i++){
//       if(!isNaN(clean[i])){
//         indexes.push(i)
//       }
//     }
    
//     el.value = mask
//     el.clean = mask.replace(/[^0-9]/gm, '')
    
//     function maskIt(){
//       var value = el.value;
//       if(!value){
//           value = "";
//       }
//       value = value.replace(/[^0-9+_() -]/gm, '')
//       // console.log(`after value: ${value}`);
//       var filtred = value.replace(/[^0-9]/gm, ''),
//           result = ''
      
//       if(value.length < first){
//         value = mask + value
//         filtred = value.replace(/[^0-9]/gm, '')
//       }
      
//       for(let i = 0; i < filtred.length; i++){
//         if(indexes.indexOf(i) == -1){
//           result += filtred[i]
//         }
//       }
      
//       value = ''
//       var cursor = 0
      
//       for(let i = 0; i < mask.length; i++){
//         if(mask[i] == '_' && result){
//           value += result[0]
//           result = result.slice(1)
//           cursor = i + 1

//         }else{
//           value += mask[i]
//         }
//       }
 
//       if(cursor < first){
//         cursor = first
//       }
      
//       el.value = value
      
//       el.clean = el.value.replace(/[^0-9]/gm, '')
      
//       // el.setSelectionRange(cursor,cursor)
//     }
    
//     el.addEventListener('focus', function(event){
//       event.preventDefault()
//     })
    
//     el.addEventListener('click', function(event){
//       event.preventDefault()
//       var start = el.value.indexOf('_')
      
//       if(start == -1){
//         start = el.value.length
//       }
      
//       // el.setSelectionRange(start,start)
      
//     })
    
//     el.addEventListener('paste', function(){
//       var start = el.selectionStart
      
//       if(start < first){
//         el.value = '_' + el.value
//       }
//     })
    
//     el.addEventListener('input', function(event){
//       var start = el.selectionStart      
//       // console.log( `input value: ${event.target.value}`);
//       maskIt(event, start)
//     })
    
//     // el.addEventListener('change', function(event){
//     //   var start = el.selectionStart      
//     //   maskIt(event, start)
//     // })
//   }
// });
// import VueMask from 'v-mask'
// Vue.use(VueMask);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),

  methods: {
    // populate_staging() {
    //   Vue.prototype.$stagingData = this.$store.getters.getStagingData;
    //   Vue.prototype.$stagingCameras = this.$store.getters.getStagingCameras;
    //   eventBus.$emit('populate_staging')
    // },
    // populate_production() {
    //   Vue.prototype.$productionData = this.$store.getters.getProductionData;
    //   eventBus.$emit('populate_production')
    // }
  },

  //  created() {
  //   console.log('asd')
  //   this.$store.dispatch('fetchUser')
  // },


  // watch: {
  //   '$store.state.account.authorized': async function (n, o) {
  //     if (n, o) {
  //       //logic for user logging in
  //       await this.$api('user', 'signin') //example for logging in user to server
  //       if (this.$route.path !== '/' || !o)
  //         this.$router.push('/')
  //     } else {
  //       //logic for user logging out
  //       if (this.$route.path !== '/')
  //         this.$router.push('/')
  //     }
  //   },
  // },
  // attempt to login user from session




}).$mount('#app');


