export const update_site_by_pk = /* GraphQL */ `
  mutation update_site_by_pk(
    $_set: site_set_input
    $pk_columns: site_pk_columns_input!
  ) {
    update_site_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const insert_site_one = /* GraphQL */ `
  mutation insert_site_one($object1: site_insert_input = {}) {
    insert_site_one(object: $object1) {
      id
    }
  }
`;

export const update_camera_by_pk = /* GraphQL */ `
  mutation update_camera_by_pk(
    $_set: camera_set_input
    $pk_columns: camera_pk_columns_input!
  ) {
    update_camera_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const insert_camera_one = /* GraphQL */ `
  mutation insert_camera_one($object1: camera_insert_input = {}) {
    insert_camera_one(object: $object1) {
      id
    }
  }
`;

export const update_wifi_by_pk = /* GraphQL */ `
  mutation update_wifi_by_pk(
    $_set: wifi_set_input
    $pk_columns: wifi_pk_columns_input!
  ) {
    update_wifi_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const insert_wifi_one = /* GraphQL */ `
  mutation insert_wifi_one($object1: wifi_insert_input = {}) {
    insert_wifi_one(object: $object1) {
      id
    }
  }
`;

export const insert_admin_note_one = /* GraphQL */ `
  mutation insert_admin_note_one($object1: admin_note_insert_input = {}) {
    insert_admin_note_one(object: $object1) {
      id
    }
  }
`;
export const update_admin_note_by_pk = /* GraphQL */ `
  mutation update_admin_note_by_pk(
    $_set: admin_note_set_input
    $pk_columns: admin_note_pk_columns_input!
  ) {
    update_admin_note_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const update_customer_by_pk = /* GraphQL */ `
  mutation update_customer_by_pk(
    $_set: customer_set_input
    $pk_columns: customer_pk_columns_input!
  ) {
    update_customer_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const update_address_by_pk = /* GraphQL */ `
  mutation update_address_by_pk(
    $_set: address_set_input
    $pk_columns: address_pk_columns_input!
  ) {
    update_address_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id
    }
  }
`;
export const update_notification_by_pk = /* GraphQL */ `
  mutation update_notification_by_pk(
    $_set: notification_set_input
    $pk_columns: notification_pk_columns_input!
  ) {
    update_notification_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id
    }
  }
`;
export const update_notification_preference_by_pk = /* GraphQL */ `
  mutation update_notification_preference_by_pk(
    $_set: notification_preference_set_input
    $pk_columns: notification_preference_pk_columns_input!
  ) {
    update_notification_preference_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id
    }
  }
`;

export const insert_customer_one = /* GraphQL */ `
  mutation insert_customer_one($object1: customer_insert_input = {}) {
    insert_customer_one(object: $object1) {
      id
    }
  }
`;
export const insert_address_one = /* GraphQL */ `
  mutation insert_address_one($object1: address_insert_input = {}, $update_columns: [address_update_column!] = city ) {
    insert_address_one(object: $object1, on_conflict: {constraint: address_pkey, update_columns: $update_columns}) {
      customer_id
    }
  }
`;
export const insert_notification_preference_one = /* GraphQL */ `
  mutation insert_notification_preference_one($object1: notification_preference_insert_input = {}) {
    insert_notification_preference_one(object: $object1) {
      customer_id
    }
  }
`;