<template>
  <v-app :id="!$vuetify.theme.dark ? 'app_div' : 'app_div_dark'">
    <v-main id="main_div">
      <transition name="fade">
        <router-view :key="$route.path"></router-view>
      </transition>
    </v-main>
    <!-- <events></events> -->
  </v-app>
</template>

<script>
// var AWS = require("aws-sdk");
//import events from "@/components/Events.vue";
import Vue from "vue";

// @vuese
// @group Main
export default {
  data: () => ({
    thing_name: "",
  }),
  components: {
    //events
  },
  name: "App",
  methods: {
    // enable_live_feed() {
    //   var iotdata = new AWS.IotData({
    //     endpoint: "a2v9pyeo3zwq10-ats.iot.us-west-2.amazonaws.com",
    //   });
    //   var params = {
    //     topic:
    //       this.$store.getters.getUserDDB.user.thing_name + "_start_substreams",
    //     payload: JSON.stringify({ message: "SUCCESS" }),
    //     qos: 1,
    //   };
    //   iotdata.publish(params, function (err, data) {
    //     if (err) console.log(err, err.stack);
    //     else {
    //       console.log(data);
    //     }
    //   });
    // },
  },
  async created(){
    Vue.prototype.$globEventParams = Vue.observable({
      level: "ALERT",
    });
  }
};
</script>


<style lang="css">
/* #events {
  margin-top: -50px;
} */
.empty_table .v-data-table__wrapper {
  display: none !important;
}
.map_icon_color_picker .v-color-picker__canvas {
  display: none !important;
}
.map_icon_color_picker .v-color-picker__controls {
  display: none !important;
}
.no_edit > .v-small-dialog__activator {
  pointer-events: none;
  padding-left: 4px;
}
.non-hl > .v-small-dialog__activator {
  padding-left: 4px;
}
.entry_table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > .non-hl
  > .v-small-dialog__activator {
  border-color: #003f51;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 3px;
  margin-right: -1px;
  margin-left: -1px;
  box-sizing: border-box;
}

#app_div {
  background: rgba(0, 0, 0, 0) url("./assets/background.png") no-repeat fixed 0%
    0%;
  background-size: cover;
}
#app_div_dark {
  background: rgba(0, 0, 0, 0) url("./assets/background_dark.png") no-repeat
    fixed 0% 0%;
  background-size: cover;
}
.table_pagination_notes {
  width: 210px;
}
.table_notes_title {
  position: absolute;
  width:100%;
  text-align: center; 
  color: #0090a4;
}
.nav_arrows_f {
  float: right;
  width: 140px;
}
#full_size_ct_div {
  width: 100%;
}
.hidden_cell {
  display: none !important;
}
.action_span {
  width: 48px;
  display: inline-block;
}
.table_sub_link {
  min-height: 0px !important;
}
.invis {
  display: none;
}
[draggable="true"] {
  cursor: move;
  user-select: none;
}
.edit_item_cb {
  display: flex;
}
.cond_tf {
  width: 60%;
}
.cb_label {
  font-size: 16px;
  margin-top: 20px;
  margin-right: 6px;
}
.project_select_div {
  margin-bottom: -22px !important;
}
.project_select {
  width: 160px;
}
.st_percent {
  width: 16%;
}
.f_percent {
  width: 40%;
}
.e_percent {
  width: 100px;
}
.sub_td > .v-data-table > .v-data-table__wrapper > table {
  table-layout: fixed;
  width: 100%;
}
.add_to_project {
  background-color: rgb(174, 253, 174);
  color: rgb(0, 58, 0);
}
.delete_from_project {
  background-color: rgb(248, 159, 159);
  color: rgb(87, 0, 0);
}
.assignment_text_div {
  width: 100%;
}
.assign_icon {
  float: right;
}
.container {
  max-width: none !important;
  min-width: none !important;
}
.assignment_main_card {
  position: fixed !important;
  flex: inherit !important;
  max-width: 14% !important;
  width: inherit;
}
.assignment_div_ch {
  font-size: 16px !important;
}
.text_align_left {
  text-align: left;
}
.action_div {
  width: 120px;
}
.sub_action_div {
  width: 60px;
}
.table_search_field {
  width: 150px;
}
.project_select_div {
  margin-bottom: -22px !important;
}
.tag_link {
  color: blue;
  cursor: pointer;
}
.main_project_table
  > .v-data-table__wrapper
  > table
  > tbody
  > .v-data-table__expanded__content {
  box-shadow: none !important;
}
.filter_menu_background {
  overflow: inherit;
}
.selected_time_pc > div {
  padding: 0px;
  height: 392px;
}
.date_expansion_panel {
  max-width: 290px !important;
}
.selected_time {
  color: gray;
  font-size: 14px;
}
.white_bckrnd {
  background: white;
}
.time_filter {
  height: 145px;
}
.date_field_picker > .v-input {
  padding-top: 0px;
  margin-top: 0px;
}
.date_field_picker > .v-input > div > .v-text-field__details {
  display: none;
}
.date_field_picker
  > .v-input
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: gray;
}
.table_tabs > .v-item-group > .v-slide-group__prev {
  display: none !important;
}
.mb_show {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .table_pagination > .v-data-footer__select {
    display: none !important;
  }
  .table_pagination > .v-data-footer__pagination {
    margin-left: 10px !important;
  }
  .mb_hidden {
    display: none !important;
  }
  .mb_show {
    display: block !important;
  }
}
.direction_sort {
  padding-left: 12px;
}
.filter_heading {
  float: right;
}
/* .table_link {
  color: blue !important;
} */
.green_color {
  color: rgb(0, 167, 0);
}
.orange_color {
  color: orange;
}
.gray_color {
  color: grey;
}
.red_color {
  color: red;
}
.yellow_color {
  color: yellow;
}
.table_list_item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-width: 90px;
}
.filter_btn {
  max-height: 28px;
}
.select_filter {
  margin-bottom: -2px !important;
}
.select_filter > div > div > div > div > div {
  color: gray;
  font-size: 14px;
}

.select_filter .v-select__slot input {
  color: gray;
  font-size: 14px;
}

.select_filter > div > div > .v-select__slot > .v-input__append-inner {
  margin-top: 4px !important;
}
.select_filter > div > div > fieldset {
  max-height: 34px;
}
.select_filter > div > div > .v-select__slot {
  max-height: 30px;
}
.select_width > div > div > div > div > div > div {
  color: gray;
}
.error_span {
  color: red;
  font-size: 14px;
  width: 100%;
  margin-left: 18px;
}
.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: -12px !important;
  margin-right: -12px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.table_tabs {
  float: left;

  width: 690px !important;
}
.table_tabs_tasks {
  float: left;
  width: 120px !important;
}
.table_tabs_hp {
  float: left;
  width: 420px !important;
}
.v-data-footer {
  border-top: none !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.no-image{
  width: 100%;
  height: 100%;
  font-size:xx-large;
  color: white;
  background: grey
}

.no-image>div{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.assign_select > div > div > div {
  height: 32px !important;
}
.assign_select > div > div {
  background-color: inherit !important;
}
.assign_select > span {
  margin-top: 1px;
  color: #616161 !important;
}
/* .assign_select > div > div > div > label {
  left: 10px !important;
} */
.assign_select_v > div > div > div > label {
  margin-top: -3px !important;
}
.assign_select > div > div > .v-input__append-inner {
  margin-top: 0px !important;
  padding-top: 4px !important;
}

.assign_select > div > div > div > label {
  top: 10px !important;
}

.assign_select.v-text-field--rounded > div > div > div > label {
  top: 10px !important;
  margin-left: 10px;
}
.assign_select_v > div > div > div > .v-input__append-inner {
  margin-top: 0px !important;
  padding-top: 7px !important;
}
.assign_select > div > div {
  min-height: 30px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.assign_select > div > div > div > div > div > i {
  margin-top: -5px;
}

.font_400 {
  font-weight: 400 !important;
}

.assign_select_aa > div > div {
  min-height: 30px !important;
}
.assign_select_aa > div > div > div {
  margin-top: -4px;
}

.zoom_image_tooltip {
  opacity: 1 !important;
  padding: 0px !important;
}

</style>

