<template>
  <v-autocomplete
    v-model="selected_item"
    :label="label"
    :items="list"
    :outlined="table"
    :solo="form"
    :full-width="form"
    :rounded="table"
    :dense="form || table"
    :search-input.sync="query_params.search"
    :rules="rules"
    :menu-props="{ maxHeight: 500 }"
    :disabled="disabled"
    :class="'camera_auto_complete' + table ? 'assign_select assign_select_v' : ''"
    hide-details="auto"
    :no-filter="true"
    @focus="focus"
    @change="blur"
  >
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    // Label of the v-autocomplete component
    label: {
      type: String,
      required: false,
    },
    // Camera id
    value: {
      required: false,
    },
    // Turn on/off the specific sytling in editing forms
    form: {
      type: Boolean,
      required: false,
    },

    table: {
      type: Boolean,
      required: false,
    },
    // Turn on/off the option for all cameras
    all: {
      type: Boolean,
      required: false,
    },
    // The validation rule for the v-autocomplete component
    rules: {
      type: Array,
      required: false,
    },
    // The owner id of the cameras
    owner: {
      type: String,
      required: false,
    },
    // The disable status of the v-autocomplete component
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selected_item: this.value,
      loading: false,
      current_title: "",
      query_params: {
        customer_id: this.owner,
        search: null,
      },
      active: false,
    };
  },
  methods: {
    focus() {
      this.active = true;
      this.$nextTick(() => {
        this.query_params.search = "";
      });
    },
    blur() {
      this.active = false;
    },
  },
  computed: {
    list() {
      var list = [];
      if (this.all) {
        list.push({ text: "All Cameras", value: null });
      }
      for (const i in this.$store.getters.getSelectionCameras) {
        list.push({
          value: this.$store.getters.getSelectionCameras[i].id,
          text:
            this.$store.getters.getSelectionCameras[i].id +
            " - " +
            this.$store.getters.getSelectionCameras[i].name,
        });
      }
      return list;
    },
  },
  watch: {

    query_params: {
      handler: function () {
        if (this.active) {
          this.$store.dispatch("DDB_GET_SELECTION_CAMERAS", this.query_params);
        }
      },
      deep: true,
    },
    selected_item(newValue) {
      this.$emit("input", newValue); // Emit the new value to support v-model
    },

    owner() {
      this.selected_item = null;
    },
  },
  mounted() {
    this.$store.dispatch("DDB_GET_SELECTION_CAMERAS", this.query_params);
  },
};
</script>

<style scoped>
.camera_auto_complete .v-input__control {
  min-height: 40px !important;
}
</style>
