// phone number general
// /^(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\s*$/

export default {
    data(){
        return {
            rules: {
                required: (v)=> !!v || 'Required',
                name: (v)=> !v || /^(?![\s,.-])(?!.*[.-]{2}|.*[,-]{2}|.*[\n\t_!¡?¿/\\+÷=@#$%ˆ&*(){}|~<>;:[\]]).*[^ ,-]\s*$/.test(v) || 
                    "Enter a valid name",
                // /^(?![\s,.-])(?!.*[.-]{2}|.*[,-]{2}|.*[\s]{2}|.*[\n\t_!¡?¿/\\+÷=@#$%ˆ&*(){}|~<>;:[\]]).*[^ ,-]$/
                url: (v)=> !v || /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(v) || 
                    "Enter a valid URL",
                mfa: (v)=> !v || /^[0-9]{6}$/.test(v) ||
                    "Enter a valid MFA code",
                phone_number: (v) =>  !v || /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(v) || 
                    "Enter a valid phone number",
                email: (v) => !v || /^[\w-.]+@([\w-]+.)+[\w-]{2,4}\s*$/.test(v) ||
                    "Enter a valid email",
                password: (v) => !v || /^(?=.*?[\d])(?=.*?[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                    "Enter a valid password",
                CAZipCode: (v) => !v || /^[abceghj-nprstvxyABCEGHJ-NPRSTVXY][0-9][abceghj-nprstv-zABCEGHJ-NPRSTV-Z] [0-9][abceghj-nprstv-zABCEGHJ-NPRSTV-Z][0-9]$/.test(v) ||
                    "Enter a valid postal code",
                USZipCode: (v) => !v || /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) ||
                    "Enter a valid postal code",
                camera_id: null, // TODO
                imei: (v) => !v || /^\d{15}$/.test(v) ||
                    "Enter a valid IMEI",
                address: (v) => !v || /^[\d\w ,.#'-]+$/.test(v) ||
                    "Enter a valid address",
                number: (v) => !v || /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/.test(v) ||
                    "Enter a valid number",
                ip: (v) => /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
                    "Enter a valid IP address",
                mac: (v) => /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
                    "Enter a valid MAC address",
            },
            tests: {
                phone_number: (v) =>  !v || /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(v),
            }
        }
    },
}