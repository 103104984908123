<template>
  <div class="ml-3">
    <div class="d-flex">
      <v-select
        v-model="usersChecked"
        :items="userOptions"
        flat
        :dense="dense"
        :filled="alt"
        :solo="alt"
        :full-width="alt"
        :allow-overflow="!alt"
        hide-details
        :class="{
          select_customer: !alt,
          'elevation-2': alt,
          select_customer_alt: alt,
          'mr-1': true,
        }"
        multiple
        label="Select Customer Types"
        v-if="!alt"
        selection-slot
      >
        <template v-slot:selection="{ index }">
          <span v-if="index === 0 && usersChecked.length > 0"
            >Options Chosen {{ usersChecked.length }}</span
          >
        </template>
      </v-select>

      <v-icon
        :class="pad ? 'mt-' + pad : dense ? '' : 'mt-4'"
        class="mr-3 elevation-2"
        @click="previousCustomer"
        :disabled="loading || disabled"
        v-if="alt"
        >mdi-chevron-left</v-icon
      >
      <v-icon
        :class="pad ? 'mt-' + pad : dense ? '' : 'mt-4'"
        class="mr-3 elevation-2"
        @click="nextCustomer"
        :disabled="loading || disabled"
        v-if="alt"
        >mdi-chevron-right</v-icon
      >
      
      <v-autocomplete
        :class="{
          select_customer: !alt,
          'elevation-2': alt,
          select_customer_alt: alt,
        }"
        :items="customers"
        v-model="customer_select"
        flat
        :dense="dense"
        hide-details
        :menu-props="{ offsetOverflow: false }"
        :disabled="loading || disabled"
        :filled="alt"
        :solo="alt"
        :full-width="alt"
        :allow-overflow="!alt"
      ></v-autocomplete>
      
      <v-icon
        :class="pad ? 'mt-' + pad : dense ? '' : 'mt-4'"
        @click="previousCustomer"
        :disabled="loading || disabled"
        v-if="!alt"
        >mdi-chevron-left</v-icon
      >
      <v-icon
        :class="pad ? 'mt-' + pad : dense ? '' : 'mt-4'"
        @click="nextCustomer"
        :disabled="loading || disabled"
        v-if="!alt"
        >mdi-chevron-right</v-icon
      >
    </div>
    <!-- <v-row no-gutters>
    <v-col class="mr-4 pl-0" :class="pad?('mt-'+pad):(dense?'':'mt-3')" v-if="alt">
        <v-icon @click="previousCustomer" :disabled="loading || disabled">mdi-chevron-left</v-icon>
        <v-icon @click="nextCustomer" :disabled="loading || disabled">mdi-chevron-right</v-icon>
    </v-col>
    <v-col>
        <v-autocomplete
            :class="{'select_customer': !alt}"
            :items="customers"
            v-model="customer_select"
            flat
            :dense = "dense"
            hide-details
            :menu-props="{ offsetOverflow: false }"
            :disabled="loading || disabled"
        ></v-autocomplete>
    </v-col>
    <v-col class="mr-4 pl-0" :class="pad?('mt-'+pad):(dense?'':'mt-3')" v-if="!alt">
        <v-icon @click="previousCustomer" :disabled="loading || disabled">mdi-chevron-left</v-icon>
        <v-icon @click="nextCustomer" :disabled="loading || disabled">mdi-chevron-right</v-icon>
    </v-col>
    </v-row> -->
  </div>
</template>

<script>
import Vue from "vue";
import { eventBus } from "../main";

// @vuese
// @group Components
// The filter for customers. Wrapper for v-autocomplete
export default {
  props: {
    // User ID
    value: {
      type: String,
      required: false,
    },
    // The padding
    pad: {
      type: Number,
      required: false,
    },
    // The disable status of the menu
    disabled: {
      type: Boolean,
      required: false,
    },
    // Turn on/off the dense styling
    dense: {
      type: Boolean,
      required: false,
    },
    // Turn on/off the all customers option
    noall: {
      type: Boolean,
      required: false,
    },
    // Turn on/off the alternate styling
    alt: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      customer_select: "All Customers",
      loading: false,
      userOptions: [
        { text: "ADMIN", value: "ADMIN" },
        { text: "BETA", value: "BETA" },
        { text: "CUSTOMER", value: "CUSTOMER" },
        { text: "DEMO", value: "DEMO" },
        { text: "DEVELOPER", value: "DEVELOPER" },
        { text: "EARLY_ADOPTER", value: "EARLY_ADOPTER" },
        { text: "INVENTORY", value: "INVENTORY" },
        { text: "PARTNER", value: "PARTNER" },
        { text: "RESEARCHER", value: "RESEARCHER" },
        { text: "USER", value: "USER" },
      ],
      usersChecked: [],

    };
  },
  methods: {
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    // @vuese
    // Move to the next customer
    nextCustomer() {
      if (this.loading) {
        return;
      }
      var c = this.customers;
      const t = (e) => e.value == this.customer_select;
      var i = c.findIndex(t);
      i++;
      if (i > c.length - 1) {
        i = 0;
      }
      this.customer_select = c[i].value;
    },
    // @vuese
    // Move to the previous customer
    previousCustomer() {
      if (this.loading) {
        return;
      }
      var c = this.customers;
      const t = (e) => e.value == this.customer_select;
      var i = c.findIndex(t);
      i--;
      if (i < 0) {
        i = c.length - 1;
      }
      this.customer_select = c[i].value;
    },
  },
  created() {
    this.customer_select = this.value;
    this.usersChecked = this.$store.getters.getUsersChecked;
    // console.log(this.usersChecked)
    // console.log(this.usersChecked)
    // this.usersChecked = this.userOptions
    // .filter(option => option.value !== "USER")
    // .map(option => option.value);
    // console.log(this.usersChecked)
    if (this.$store.getters.getCustomerObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS")
    }
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.customer_select = Vue.prototype.$selectCustomer;
    }
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (!this.$store.getters.getDialogStatus && !this.disabled) {
        if (e.code == "ArrowLeft") {
          this.previousCustomer();
        }

        if (e.code == "ArrowRight") {
          this.nextCustomer();
        }
      }
    });
  },
  watch: {
    value() {
      this.customer_select = this.value;
    },
    usersChecked(newVal) {
      console.log("watch running")
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_CUSTOMERS", newVal)
        .then(() => {
          this.loading = false;
        });
    },
    customer_select() {
      this.$globEventParams.customer = this.customer_select;
      eventBus.$emit("items_filter");
      this.loading = true;
      if (this.customer_select) {
        Vue.prototype.$selectCustomer = this.customer_select;

        if (
          !(this.customer_select == "All Customers") &&
          !(this.customer_select == undefined)
        ) {
          eventBus.$emit("customer_selected", true);
        } else {
          eventBus.$emit("customer_selected", false);
        }
        this.$emit("input", this.customer_select);
        this.$emit("change");
        this.loading = false;
      } else {
        this.customer_select = "All Customers";
        this.loading = false;
      }
    },
  },
  computed: {
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCustomerObjects.map((item) => {
            try {
              return {
                text:
                  (item.company_name ? item.company_name + " - " : "") +
                  (item.first_name ? item.first_name + " " : "") +
                  (item.last_name == item.first_name ? "" : item.last_name) +
                  " - " +
                  item.id,
                value: item.id,
              };
            } catch {
              return "null";
            }
          })
        ),
      ];

      customers = this.sortByKey([...customers], "text");
      if (!this.noall) {
        customers.unshift({ text: "All Customers", value: "All Customers" });
      }

      return customers;
    },
  },
};
</script>

<style scoped>
.select_customer {
  width: 320px;
  /* max-width: 240px; */
}
.select_customer_alt {
  min-width: 260px;
}
.select_customer > div > div > div > div > div {
  color: gray;
  font-size: 16px;
}

.select_customer_alt > div > div > div > div > div {
  color: gray;
  font-size: 16px;
}
</style>
