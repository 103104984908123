<template>
  <div>
    <v-dialog
    v-model="dialog"
    max-width="1000px"
    min-width="760px"
    persistent
    no-click-animation
    @click:outside="close"
    >
    <v-card :loading="loading">
        <v-card-title>Clear Data: {{editedItem.id}}</v-card-title>
        <v-card-text>
            <v-row>
                <v-col sm="9">
                    <v-text-field
                    v-model="start_date"
                    readonly
                    label="Date Range"
                    dense
                    hide-details
                    >
                    <template v-slot:append
                        ><v-menu
                        nudge-top="9"
                        nudge-right="12"
                        :close-on-content-click="false"
                        open-on-click
                        left
                        offset-overflow
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                        </template>

                        <v-card>
                            <v-date-picker
                            v-model="start_date"
                            flat
                            no-title
                            event-color="#ff9900"
                            ></v-date-picker>
                        </v-card> </v-menu
                    ></template>
                    </v-text-field>
                </v-col>
                <v-col sm="3">
                    <v-btn block @click="calculateSize" :disabled="!start_date || loading" >Calculate Size</v-btn>
                </v-col>
                <v-col sm="9" v-if="!!video_total_size">
                    <span>Video Data Size: {{video_total_size}}</span>
                </v-col>
                <v-col sm="3" v-if="!!video_total_size">
                    <v-btn block @click="attemptToDelete" :disabled="loading">Clear Data</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    </v-dialog>
    <v-dialog v-model="delete_dialog" max-width="750px" min-width="260px">
      <v-card>
        <v-card-title>Delete all videos before {{start_date}} for camera {{editedItem.id}}?</v-card-title>
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
          <v-btn
            color="#dd3f5b"
            class="mr-3 white--text"
            @click="deleteVideos"
          >
            Delete
          </v-btn>
          <v-btn color="primary" @click="delete_dialog = false">
            Cancel
          </v-btn>
        </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deny_dialog" max-width="650px" min-width="260px">
      <v-card>
        <v-card-title>Sorry, you do not have permission to perform this operation.</v-card-title>
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
          <v-btn color="primary" @click="deny_dialog = false">
            Cancel
          </v-btn>
        </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorbar">
      {{error}}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import { eventBus } from "../main";
// import { API } from "aws-amplify";
// import * as queries from '../graphql/queries';
import { initializeLambdaClient } from "../utilities/sessionUtils";
import { InvokeCommand } from "@aws-sdk/client-lambda";
import { getVideoTotalSize } from "../utilities/videoSizeUtils";

// @vuese
// @group Components
// The operation card for clearing camera storage
export default {
  components: {
  },
  data() {
    return {
        dialog: false,
        loading: false,
        editedItem: {},
        start_date: "",
        video_total_size: null,
        delete_dialog: false,
        deny_dialog: false,
        error: "",
        errorbar: false,
        allowed_IDs: ["7893f0cc-bd21-4845-b282-4310b19b2cf3", "b76947ed-8c47-43a2-9094-3c78237ae98d", "99d38adb-aa9c-4879-a232-bd40c40227d8"]
    };
  },

  created() {
    eventBus.$on("open_dialog_nuke", (params) => {
        this.open(params);
    });
  },
  methods: {
    attemptToDelete(){
      if(this.allowed_IDs.includes(this.$store.getters.getUser.attributes.sub)){
        this.delete_dialog = true;
      }else{
        this.deny_dialog = true;
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    async calculateSize(){
        this.loading = true;
        var filter = {
            camera_id:{
                eq: this.editedItem.id
            },
            video_datetime: {
                lte: moment(this.start_date).format("YYYY-MM-DDTHH:mm:ss.sssZ")
            }
        }
        var video_total_size= await getVideoTotalSize(filter);
        // var video_total_size = response.data.searchVideos.aggregateItems[0]?.result?.value;
        this.video_total_size = this.formatBytes(video_total_size);
        this.loading = false;
    },
    open(params) {
        this.editedItem = params.item;
        this.dialog = true;
    },
    close() {
      if (!this.loading) {
        this.dialog = false;
        this.editedItem = {};
        this.video_total_size = null;
        this.start_date = "";
      }
    },
    deleteVideos(){
      this.delete_dialog = false;
      this.loading = true;
      initializeLambdaClient().then(client=>{
        var params = {
          FunctionName: process.env.VUE_APP_VIDEO_DELETION_LAMBDA /* required */,
          InvocationType: "Event",
          Payload: JSON.stringify({
            user_id: this.$store.getters.getUser.attributes.sub,
            // user_id: "7893f0cc-bd21-4845-b282-4310b19b2cf3",
            camera_id: this.editedItem.id,
            start_time: moment(this.start_date).format("YYYY-MM-DD_HH-mm-ss")
          }),
        };
        var command = new InvokeCommand(params);
        return client.send(command);
      }).then(()=>{
        this.loading = false;
        this.close();
      }).catch(err=>{
        this.loading = false;
        this.error = err;
        this.errorbar = true;
        console.log(err);
      })
    },
  },
  watch: {
    dialog() {
      this.$store.commit("SET_NUKE_CARD_DIALOG", this.dialog);
    }
  },
};
</script>