<template>
  <div>
    <v-form ref="form" v-model="valid" :disabled="loading">
      <v-dialog
        v-model="dialog"
        max-width="1000px"
        min-width="760px"
        persistent
        no-click-animation
        @click:outside="attemptToClose"
      >
        <v-card :loading="loading">
          <v-card-title>
            <span class="" style="color: #0090a4" v-if="create_new"
              >Add Camera</span
            >
            <span class="" style="color: #0090a4" v-else
              ><span>Edit Camera: {{ editedItem.id }}</span>
              <copy-button class="ml-2" :text="editedItem.id"></copy-button
            ></span>
            <v-chip class="ml-2" small v-if="has_unsaved_changes">{{
              Object.keys(original_values).length + " unsaved changes"
            }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="attemptToClose" :disabled="loading">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card>
              <v-card-text>
                <!-- Identificaton -->
                <v-row no-gutters>
                  <v-col cols="12" sm="6" :md="create_new ? 4 : 6">
                    <v-autocomplete
                      v-model="owner_name"
                      :items="customer_names"
                      label="Customer *"
                      :menu-props="{ offsetOverflow: false }"
                      outlined
                      dense
                      :rules="[rules.required]"
                      @focus="edit_value = owner_name"
                      @input="on_field_change('owner_name', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" :md="create_new ? 4 : 6">
                    <v-autocomplete
                      v-model="site_name"
                      :items="site_names"
                      label="Site *"
                      :menu-props="{ offsetOverflow: false }"
                      :disabled="!(site_names.length > 0)"
                      outlined
                      dense
                      :rules="[rules.required]"
                      @focus="edit_value = site_name"
                      @input="on_field_change('site_name', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="create_new">
                    <v-text-field
                      :readonly="!create_new"
                      v-model="editedItem.id"
                      label="ID *"
                      :rules="[rules.required, rules.id]"
                      outlined
                      dense
                      @focus="edit_value = editedItem.id"
                      @input="on_field_change('id', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- basic information -->
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name *"
                      outlined
                      dense
                      :rules="[rules.required]"
                      class="text-validated pl-3 pr-3 pt-1"
                      @focus="edit_value = editedItem.name"
                      @input="on_field_change('name', $event)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.coordinates_lat"
                      label="Latitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.coordinates_lat"
                      @input="on_field_change('coordinates_lat', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.coordinates_long"
                      label="Longitude"
                      outlined
                      dense
                      @focus="edit_value = editedItem.coordinates_long"
                      @input="on_field_change('coordinates_long', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.status"
                      :items="$store.getters.getOnlineStatus"
                      label="Status"
                      outlined
                      dense
                      @focus="edit_value = editedItem.status"
                      @input="on_field_change('status', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.shipped"
                      :items="$store.getters.getShippingStatus"
                      label="Shipping Status"
                      outlined
                      dense
                      @focus="edit_value = editedItem.shipped"
                      @input="on_field_change('shipped', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.inventory_number"
                      label="Inventory No."
                      outlined
                      dense
                      @focus="edit_value = editedItem.inventory_number"
                      @input="on_field_change('inventory_number', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    >
                      <!-- <template v-slot:[`prepend-inner`]>
                    <v-checkbox
                    hide-details
                    class="text-abled ma-0 pa-0"
                    ripple="false"
                    ></v-checkbox>
                  </template> -->
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Device -->
                <text-divider text="Device"></text-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                      v-model="editedItem.model"
                      :items="$store.getters.getCameraModels"
                      label="Model *"
                      outlined
                      dense
                      :rules="[rules.required]"
                      @focus="edit_value = editedItem.model"
                      @input="on_field_change('model', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.manufacturer"
                      label="Manufacturer *"
                      outlined
                      dense
                      :rules="[rules.required]"
                      @focus="edit_value = editedItem.manufacturer"
                      @input="on_field_change('manufacturer', $event)"
                      class="text-validated pl-3 pr-3 pt-1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.broken_reason"
                      :label="broken_bool ? 'Broken Reason' : 'Broken'"
                      outlined
                      dense
                      class="text-validated pl-3 pr-3 pt-1"
                      :class="{
                        'text-disabled': !broken_bool || !loading,
                      }"
                      @focus="edit_value = editedItem.broken_reason"
                      @input="on_field_change('broken_reason', $event)"
                      :disabled="!broken_bool"
                    >
                      <template v-slot:[`prepend-inner`]>
                        <v-checkbox
                          hide-details
                          class="ma-0 pa-0"
                          :class="{ 'text-abled': !loading }"
                          v-model="broken_bool"
                          @focus="edit_value = broken_bool"
                          @change="on_field_change('broken_bool', $event)"
                        ></v-checkbox>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-card-text>

          <v-card-actions class="pr-6">
            <v-row>
              <v-col cols="12" class="py-0"
                ><v-icon
                  class="pl-1"
                  style="float: left"
                  v-if="!create_new"
                  color="primary"
                  @click="delete_dialog = true"
                  :disabled="loading"
                  >mdi-delete</v-icon
                >
                <div style="float: right">
                  <v-btn
                    color="primary"
                    @click="apply"
                    :disabled="!valid || loading || !has_unsaved_changes"
                  >
                    Apply
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    @click="attemptToClose"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    @click="save"
                    :disabled="
                      !valid ||
                      loading ||
                      (!has_unsaved_changes && !pressed_apply)
                    "
                  >
                    Save
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" v-if="error"
                ><span class="error_span">{{ error }}</span></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="delete_dialog" max-width="550px" min-width="260px">
        <v-card>
          <v-card-title>Delete Camera {{ editedItem.name }}?</v-card-title>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn
                color="#dd3f5b"
                @click="deleteItem(editedItem.id)"
                class="mr-3 white--text"
              >
                Delete
              </v-btn>
              <v-btn color="primary" @click="delete_dialog = false">
                Cancel
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-dialog v-model="dirty_dialog" max-width="550px" min-width="260px">
      <v-card>
        <v-card-title
          >Discard unsaved changes{{
            editedItem.name ? " for " + editedItem.name : ""
          }}?</v-card-title
        >
        <v-card-actions>
          <div class="d-flex justify-center" style="width: 100%">
            <v-btn color="#dd3f5b" @click="close()" class="mr-3 white--text">
              Discard
            </v-btn>
            <v-btn color="primary" @click="dirty_dialog = false">
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      This customer does not have a site. Hence camera cannot be created.
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import { eventBus } from "../main";
import CopyButton from "./CopyButton.vue";
import TextDivider from "./TextDivider.vue";
import rulesMixin from "../mixins/rulesMixin";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
// @vuese
// @group Components
// The editing card for camera
export default {
  components: {
    CopyButton,
    TextDivider,
  },
  props: ["query_params"],
  mixins: [rulesMixin],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
        imei: (v) => /^\d {15}$||null$/.test(v) || "Enter a valid IMEI",
        id: (v) => /^[A-Z0-9]{14,16}-?[A-Z0-9]{0,4}$/.test(v) || "Enter a valid ID",
      },
      editedItem: {},
      delete_dialog: false,
      dialog: false,
      owner_name: "",
      error: "",
      site_name: "",
      create_new: false,
      broken_bool: false,

      valid: false,
      loading: false,

      snackbar: false,

      original_values: {},
      edit_value: null,
      has_unsaved_changes: false,
      pressed_apply: false,
      dirty_dialog: false,
    };
  },

  created() {
    if (this.$store.getters.getCameraModels.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERA_MODELS");
    }
    // if (this.$store.getters.getCameraShippingStatus.length == 0) {
    //   this.$store.dispatch("DDB_GET_CAMERA_SHIPPING_STATUS");
    // }
    // if (this.$store.getters.getCameraOnlineStatus.length == 0) {
    //   this.$store.dispatch("DDB_GET_CAMERA_ONLINE_STATUS");
    // }
    eventBus.$on("open_dialog_cameras", (params) => {
      this.open(params);
    });
    window.addEventListener("beforeunload", (event) => {
      if (this.has_unsaved_changes) {
        event.preventDefault();
        event.returnValue = true;
      }
    });
  },
  methods: {
    // @vuese
    // Check for unsaved changes before closing the camera card
    attemptToClose() {
      if (this.has_unsaved_changes) {
        this.dirty_dialog = true;
      } else {
        this.close();
      }
    },
    on_field_change(key, value) {
      if (!Object.prototype.hasOwnProperty.call(this.original_values, key)) {
        if (this.edit_value === "" || this.edit_value === false) {
          this.original_values[key] = null;
        } else {
          this.original_values[key] = this.edit_value;
        }
        this.has_unsaved_changes = true;
      }
      if (value === "" || value === false) {
        value = null;
      }
      // console.log(`original: ${this.original_values[key]}, current: ${value}`)
      if (this.original_values[key] == value) {
        delete this.original_values[key];
        if (Object.keys(this.original_values).length <= 0) {
          this.has_unsaved_changes = false;
        }
      }
    },
    // @vuese
    // Open the camera card
    // @arg The paramters for opening the card
    open(params) {
      this.editedItem = JSON.parse(JSON.stringify(params.item));
      this.has_unsaved_changes = false;
      this.pressed_apply = false;
      this.original_values = {};
      this.create_new = params.create_new;
      this.error = "";
      this.broken_bool = params.broken_bool;

      if (!params.create_new) {
        this.owner_name = params.owner_name;
        this.site_name = params.site_name;
      }
      console.log(this.owner_name)
      this.dialog = true;
      this.$nextTick(() => {
        // this.selected_table = 0;
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });
    },
    // @vuese
    // Close the camera card
    close() {
      // console.log(this.dialog);
      if (!this.loading) {
        this.dialog = false;
        this.dirty_dialog = false;
        this.has_unsaved_changes = false;
        this.original_values = {};
      }
    },
    // @vuese
    // Apply the changes
    apply() {
      this.loading = true;
      this.uploadItem()
        .then(() => {
          eventBus.$emit("updateCameras");
            this.loading = false;
            this.pressed_apply = true;
            this.original_values = {};
            this.has_unsaved_changes = false;
            if (this.create_new) {
              this.editedItem = {};
              this.owner_name = "";
              this.site_name = "";
            }
            if (this.$store.getters.getEditedCustomer) {
              // Fire after the camera is changed to update the current customer that is being edited, if there is any.
              eventBus.$emit("updateCameras");
            }
       
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // @vuese
    // Apply the changes and close the card
    save() {
      this.loading = true;
      this.uploadItem()
        .then(() => {
          eventBus.$emit("updateCameras");
            this.loading = false;
            this.dialog = false;
            this.editedItem = {};
            this.original_values = {};
            this.has_unsaved_changes = false;
            if (this.$store.getters.getEditedCustomer) {
              eventBus.$emit("update_edited_customer");
            }
      
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // @vuese
    // Upload the unsaved changes to the database
    uploadItem() {
      return new Promise((resolve, reject) => {
        (async () => {
          this.$refs.form.validate();
          if (!this.valid) {
            reject();
          }
          delete this.editedItem.createdAt;
          delete this.editedItem.updatedAt;
          delete this.editedItem.customer;
          delete this.editedItem.site;
          delete this.editedItem.videos;
          delete this.editedItem.events;
          delete this.editedItem._lastChangedAt;
          delete this.editedItem.index;
          this.video_years.forEach((y) => {
            delete this.editedItem[`video_total_size_${y}`];
          });
          this.editedItem.customer_id = this.owner_name;
          this.editedItem.site_id = this.site_name;
          this.editedItem.broken = this.broken_bool;

          for (const n in this.editedItem) {
            if (
              typeof this.editedItem[n] === "string" ||
              this.editedItem[n] instanceof String
            ) {
              this.editedItem[n] = this.editedItem[n].trim();

              if (this.editedItem[n] === "") {
                if (this.create_new) {
                  delete this.editedItem[n];
                } else {
                  this.editedItem[n] = null;
                }
              }
            }
          }

          if (this.create_new) {
            var response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.insert_camera_one,
                variables: {
                  object1: this.editedItem,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: ");
        }
            resolve();
          } else {
            try {
              let edited_record = { ...this.editedItem };
              delete edited_record.id;
              await axios.post(
                this.$store.getters.getHasuraParamsWrite.url,
                {
                  query: hasura_mutations.update_camera_by_pk,
                  variables: {
                    pk_columns: { id: this.editedItem.id },
                    _set: edited_record,
                  },
                },
                {
                  headers: this.$store.getters.getHasuraParamsWrite.headers,
                }
              );
                console.log(edited_record)

              resolve();
            } catch (err) {
              console.log(err);
              this.error = err.errors[0].message;
              reject();
            }
          }
        })();
      });
    },
    // @vuese
    // Delete the selected camera
    async deleteItem(id) {
      this.delete_dialog = false;
      this.loading = true;
      try {
        console.log(id);
        this.$nextTick(() => {
          // setTimeout(()=>{
          //   // eventBus.$emit("updateCameras");
          // }, this.$store.getters.getWaitInterval);
          this.$store.dispatch("DDB_GET_CAMERAS", this.query_params).then(() => {
            this.loading = false;
            this.dialog = false;
            this.editedItem = {};
          });
        });
      } catch (err) {
        this.error = err.errors[0].message;
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.owner_name = "";
        this.site_name = "";
      }
      this.$store.commit("SET_CAMERA_CARD_DIALOG", this.dialog);
    },
    broken_bool() {
      if (!this.broken_bool) {
        this.editedItem.broken_reason = null;
        this.on_field_change("broken_reason", null);
      }
    },
    owner_name() {
      if (this.site_names.length > 0) {
        this.site_name = this.site_names[0].value;
      } else {
        this.site_name = null;
      }
    },
    site_names() {
      if (this.owner_name && this.site_names.length <= 0) {
        this.snackbar = true;
      }
    },
  },
  computed: {
    video_years() {
      return this.$store.getters.getVideoYears;
    },
    site_names() {
      if (this.$store.getters.getCustomerNames[1] && this.owner_name) {
        return this.$store.getters.getCustomerNames[1][this.owner_name];
      } else {
        return [];
      }
    },
    customer_names() {
      return this.$store.getters.getCustomerNames[0];
    },
  },
};
</script>
