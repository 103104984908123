import { API } from "aws-amplify";
import * as queries from '../graphql/queries';

export function getVideoTotalSize(filter){
    var promises = [];

    // video_size_mb
    promises.push(API.graphql({
        query: queries.getVideoSizeByFilter,
        variables: {filter: filter}
    }));

    // video_size_float
    promises.push(API.graphql({
        query: queries.getVideoSizeFloatByFilter,
        variables: {filter: filter}
    }));

    return Promise.all(promises).then(responses=>{
        var video_total_size = 0;
        for(var i = 0; i<responses.length; i++){
            var response = responses[i];
            // if(filter.video_datetime.gte == "2023-01-01T00:00:00.000-07:00" && response.data.searchVideos.aggregateItems.length <= 0){
            //     console.log(promises);
            // }
            var s = response.data.searchVideos.aggregateItems[0]?.result?.value || 0;
            video_total_size += s;
        }
        return video_total_size;
    })

}