<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2" :loading="loading">
            <v-card-title>
              <span class="ml-2">Treeview</span>
              <v-spacer></v-spacer>
              <!-- <div style="width: 360px">
                <v-autocomplete
                  v-model="user"
                  :items="owner_customer_names"
                  label="User"
                  hide-details
                  flat
                ></v-autocomplete>
              </div>
              <v-icon class="mt-5" @click="previousUser">mdi-chevron-left</v-icon>
              <v-icon class="mt-5" @click="nextUser">mdi-chevron-right</v-icon> -->
              <CustomerFilter v-model="user" :noall="true"></CustomerFilter>
            </v-card-title>
            <v-card-text v-if="items[1]">
              <v-treeview
                v-if="open_all"
                open-all
                :items="items[0]"
                item-key="name"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon :color="icons[item.type] ? icons[item.type][1] : ''">
                    {{ icons[item.type][0] }}
                  </v-icon>
                </template>
                <template v-slot:label="{ item }">
                  <span
                    @click="open_card(item.type, item.item)"
                    class="h_pointer"
                  >
                    <span class="name_width">{{ item.name }}</span>
                    <span v-if="item.customer_c" class="c_width">
                      (Customer:
                      <b
                        :class="
                          item.customer_c != item.customer_p
                            ? 'p_c_no_match'
                            : ''
                        "
                        >{{ item.customer_c }}</b
                      >)</span
                    >
                    <span v-if="item.site_c" class="c_width">
                      (Site:
                      <b
                        :class="
                          item.site_c != item.site_p ? 'p_c_no_match' : ''
                        "
                        >{{ item.site_c }}</b
                      >)</span
                    ></span
                  ><span v-if="item.id">(ID: {{ item.id }}) </span>
                </template>
              </v-treeview></v-card-text
            >
            <v-card-text v-else-if="!user"> Select User </v-card-text>
            <v-card-text v-else-if="!items[1]">
              User has no data base entries
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <customer-card />
    <site-card />
    <camera-card />
    <jetson-card />
    <wifi-card />
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import CustomerCard from "../components/CustomerCard.vue";
import SiteCard from "../components/SiteCard.vue";
import CameraCard from "../components/CameraCard.vue";
import JetsonCard from "../components/JetsonCard.vue";
import WifiCard from "../components/WifiCard.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
// import moment from "moment";
import { eventBus } from "../main";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerCard,
    SiteCard,
    CameraCard,
    JetsonCard,
    WifiCard,
    CustomerFilter
  },
  props: [],
  data() {
    return {
      icons: {
        user: ["mdi-account-cowboy-hat", "oc_orange"],
        customer: ["mdi-account-box", "oc_yellow"],
        site: ["mdi-barn", "oc_brown"],
        camera: ["mdi-cctv", "oc_blue"],
        wifi: ["mdi-wifi", "oc_purple"],
        jetson: ["mdi-router-wireless", "oc_green"],
      },
      user: null,
      user_name_objects: null,
      usernames: [],
      open_all: false,
      loading:false
    };
  },
  methods: {
    previousUser(){
      if(this.user){
        var i = this.owner_customer_names.map(e=>e.value).indexOf(this.user);
        i--;
        if(i<0){
          i = this.owner_customer_names.length-1;
        }
        this.user = this.owner_customer_names[i].value;
      }else{
        this.user = this.owner_customer_names[this.owner_customer_names.length-1].value;
      }
    },
    nextUser(){
      if(this.user){
        var i = this.owner_customer_names.map(e=>e.value).indexOf(this.user);
        i++
        if(i>this.owner_customer_names.length-1){
          i = 0;
        }
        this.user = this.owner_customer_names[i].value;
      }else{
        this.user = this.owner_customer_names[0].value;
      }
    },
    open_card(type, item) {
      if (item) {
        if (type == "customer") {
          eventBus.$emit("open_dialog_customers", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: item.owner,
          });
        } else if (type == "site") {
          eventBus.$emit("open_dialog_sites", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: item.owner,
          });
        } else if (type == "camera") {
          eventBus.$emit("open_dialog_cameras", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: item.owner,
            site_name: item.site.id,
            camera_broken_bool: item.camera_broken == "YES",
            camera_cellular_enabled_bool: item.camera_cellular_enabled == "YES",
            camera_ftp_enabled_bool: item.camera_reolinkcloud_enabled == "YES",
            camera_reolinkcloud_enabled_bool:
              item.camera_reolinkcloud_enabled == "YES",
          });
        } else if (type == "jetson") {
          eventBus.$emit("open_dialog_jetson", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: item.owner,
            site_name: item.site.id,
          });
        } else if (type == "wifi") {
          eventBus.$emit("open_dialog_wifi", {
            item: Object.assign({}, item),
            create_new: false,
            owner_name: item.owner,
            site_name: item.site.id,
          });
        }
      }
    },
    sortByNested(array, key) {
      key = key.split('.');
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomerTreeview;
    },
    items() {
      if (this.user) {
        var tree = [
          {
            name: "User: " + this.user,
            type: "user",
          },
        ];

        if (this.$store.getters.getCustomerTreeview) {
          tree[0].children = [
            {
              id: this.$store.getters.getCustomerTreeview.id,
              name:
                "Customer: " +
                this.$store.getters.getCustomerTreeview.customer_first_name +
                " " +
                this.$store.getters.getCustomerTreeview.customer_last_name,
              type: "customer",
              item: this.$store.getters.getCustomerTreeview,
            },
          ];
          var customer_children_index = 0;
          tree[0].children[0].children = [];
          if (
            this.$store.getters.getCustomerTreeview.sites &&
            this.$store.getters.getCustomerTreeview.sites.items.length > 0
          ) {
            tree[0].children[0].children.push({ name: "Sites", type: "site" });
            tree[0].children[0].children[customer_children_index].children = [];

            for (const i in this.$store.getters.getCustomerTreeview.sites
              .items) {
              var site_children_index = 0;
              tree[0].children[0].children[
                customer_children_index
              ].children.push({
                id: this.$store.getters.getCustomerTreeview.sites.items[i].id,
                name: this.$store.getters.getCustomerTreeview.sites.items[i]
                  .site_name,
                type: "site",
                item: this.$store.getters.getCustomerTreeview.sites.items[i],
                customer_p:
                  this.$store.getters.getCustomerTreeview.customer_first_name +
                  " " +
                  this.$store.getters.getCustomerTreeview.customer_last_name,
                customer_c:
                  this.$store.getters.getCustomerTreeview.sites.items[i]
                    .customer.customer_first_name +
                  " " +
                  this.$store.getters.getCustomerTreeview.sites.items[i]
                    .customer.customer_last_name,
              });
              tree[0].children[0].children[customer_children_index].children[
                i
              ].children = [];
              if (
                this.$store.getters.getCustomerTreeview.sites.items[i]
                  .cameras &&
                this.$store.getters.getCustomerTreeview.sites.items[i].cameras
                  .items.length
              ) {
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children.push({
                  name: "Cameras",
                  type: "camera",
                });
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children[site_children_index].children = [];
                const temp_cams = this.sortByNested([...this.$store.getters.getCustomerTreeview.sites.items[i].cameras.items], 'camera_name')
                for (const n in temp_cams) {
                  tree[0].children[0].children[
                    customer_children_index
                  ].children[i].children[0].children.push({
                    id: temp_cams[n].id,
                    name: temp_cams[n].camera_name,
                    site_c:
                      temp_cams[n].site.site_name,
                    site_p:
                      this.$store.getters.getCustomerTreeview.sites.items[i]
                        .site_name,
                    type: "camera",
                    item: temp_cams[n],
                  });
                }
                site_children_index += 1;
              }
              if (
                this.$store.getters.getCustomerTreeview.sites.items[i].wifi &&
                this.$store.getters.getCustomerTreeview.sites.items[i].wifi
                  .items.length
              ) {
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children.push({
                  name: "Wi-Fi",
                  type: "wifi",
                });
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children[site_children_index].children = [];
                const temp_wifi = this.sortByNested([...this.$store.getters.getCustomerTreeview.sites
                  .items[i].wifi.items], 'wifi_model')
                for (const n in temp_wifi) {
                  tree[0].children[0].children[
                    customer_children_index
                  ].children[i].children[site_children_index].children.push({
                    id: temp_wifi[n].id,
                    name: temp_wifi[n].wifi_model,
                    site_c:
                      temp_wifi[n].site.site_name,
                    site_p:
                      this.$store.getters.getCustomerTreeview.sites.items[i]
                        .site_name,
                    type: "wifi",
                    item: temp_wifi[n],
                  });
                }
                site_children_index += 1;
              }
              if (
                this.$store.getters.getCustomerTreeview.sites.items[i]
                  .jetsons &&
                this.$store.getters.getCustomerTreeview.sites.items[i].jetsons
                  .items.length
              ) {
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children.push({
                  name: "Jetsons",
                  type: "jetson",
                });
                tree[0].children[0].children[customer_children_index].children[
                  i
                ].children[site_children_index].children = [];
                const temp_jetsons = this.sortByNested([...this.$store.getters.getCustomerTreeview.sites
                  .items[i].jetsons.items], 'jetson_type')
                for (const n in temp_jetsons) {
                  tree[0].children[0].children[
                    customer_children_index
                  ].children[i].children[site_children_index].children.push({
                    id: temp_jetsons[n].id,
                    name: temp_jetsons[n].jetson_type,
                    site_c:
                      temp_jetsons[n].site.site_name,
                    site_p:
                      this.$store.getters.getCustomerTreeview.sites.items[i]
                        .site_name,
                    type: "jetson",
                    item: temp_jetsons[n],
                  });
                }
              }
            }
            customer_children_index += 1;
          }

          if (
            this.$store.getters.getCustomerTreeview.cameras &&
            this.$store.getters.getCustomerTreeview.cameras.items.length > 0
          ) {
            tree[0].children[0].children.push({
              name: "Cameras",
              type: "camera",
            });

            tree[0].children[0].children[customer_children_index].children = [];
            const temp_cams = this.sortByNested([...this.$store.getters.getCustomerTreeview.cameras
              .items], 'camera_name')
            for (const i in temp_cams) {
              tree[0].children[0].children[
                customer_children_index
              ].children.push({
                id: temp_cams[i].id,
                name: temp_cams[i]
                  .camera_name,
                customer_c:
                  temp_cams[i]
                    .customer.customer_first_name +
                  " " +
                  temp_cams[i]
                    .customer.customer_last_name,
                customer_p:
                  this.$store.getters.getCustomerTreeview.customer_first_name +
                  " " +
                  this.$store.getters.getCustomerTreeview.customer_last_name,
                type: "camera",
                item: temp_cams[i],
              });
            }
            customer_children_index += 1;
          }

          if (
            this.$store.getters.getCustomerTreeview.wifi &&
            this.$store.getters.getCustomerTreeview.wifi.items.length > 0
          ) {
            tree[0].children[0].children.push({
              name: "Wi-Fi",
              type: "wifi",
            });

            tree[0].children[0].children[customer_children_index].children = [];
            const temp_wifi = this.sortByNested([...this.$store.getters.getCustomerTreeview.wifi
              .items], 'wifi_model')
            for (const i in temp_wifi) {
              tree[0].children[0].children[
                customer_children_index
              ].children.push({
                id: temp_wifi[i].id,
                name: temp_wifi[i]
                  .wifi_model,
                customer_c:
                  temp_wifi[i].customer
                    .customer_first_name +
                  " " +
                  temp_wifi[i].customer
                    .customer_last_name,
                customer_p:
                  this.$store.getters.getCustomerTreeview.customer_first_name +
                  " " +
                  this.$store.getters.getCustomerTreeview.customer_last_name,
                type: "wifi",
                item: temp_wifi[i],
              });
            }
            customer_children_index += 1;
          }

          if (
            this.$store.getters.getCustomerTreeview.jetsons &&
            this.$store.getters.getCustomerTreeview.jetsons.items.length > 0
          ) {
            tree[0].children[0].children.push({
              name: "Jetsons",
              type: "jetson",
            });

            tree[0].children[0].children[customer_children_index].children = [];
            const temp_jetsons = this.sortByNested([...this.$store.getters.getCustomerTreeview.jetsons
              .items], 'jetson_type')
            for (const i in this.$store.getters.getCustomerTreeview.jetsons
              .items) {
              tree[0].children[0].children[
                customer_children_index
              ].children.push({
                id: temp_jetsons[i].id,
                name: temp_jetsons[i]
                  .jetson_type,
                customer_c:
                  temp_jetsons[i]
                    .customer.customer_first_name +
                  " " +
                  temp_jetsons[i]
                    .customer.customer_last_name,
                customer_p:
                  this.$store.getters.getCustomerTreeview.customer_first_name +
                  " " +
                  this.$store.getters.getCustomerTreeview.customer_last_name,
                type: "jetson",
                item: temp_jetsons[i],
              });
            }
            customer_children_index += 1;
          }

          if (!tree[0].children[0].children) {
            return [tree, false];
          }
          return [tree, true];
        } else {
          return [tree, false];
        }
      } else {
        return false;
      }
    },

    owner_customer_names() {
      console.log("======TEST======");
      console.log(this.$store.getters.getCustomerObjects);
      var user_name_objects = [];
      var users = this.$store.getters.getCognitoUsers;
      async function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key];
          var y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }
      for(var i in users){
        var t = {};
        for(var n in users[i].Attributes){
          t[users[i].Attributes[n].Name] = users[i].Attributes[n].Value;
        }

        var username = "";
        if(!t["given_name"]&&!t["family_name"]){
          username = users[i].Attributes[users[i].Attributes.length - 1].Value;
        }else if(!t["given_name"]){
          username = t["family_name"];
        }else if(!t["family_name"]){
          username = t["given_name"];
        }else if(t["given_name"]==t["family_name"]){
          username = t["given_name"];
        }else{
          username = t["given_name"]+ " " + t["family_name"];
        }

        var company_name = this.$store.getters.getCustomerObjects.find(e=>e.id==users[i].Username)?.customer_company_name;
        if(company_name){
          username = company_name+ " - "+username;
        }
        user_name_objects.push({ text: username, value: users[i].Username })
        sortByKey(user_name_objects, "text");
      }
      return user_name_objects;
    },
  },
  mounted() {
    // window.addEventListener("keyup", e=>{
    //     if(!this.$store.getters.getCustomerCardDialog
    //         &&!this.$store.getters.getSiteCardDialog
    //         &&!this.$store.getters.getCameraCardDialog
    //         &&!this.$store.getters.getWifiCardDialog
    //         &&!this.$store.getters.getJetsonCardDialog){
    //     if(e.code=="ArrowLeft"){
    //       this.previousUser();
    //     }

    //     if(e.code=="ArrowRight"){
    //       this.nextUser();
    //     }
    //   }
    // })
  },
  created() {
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getSiteNames.length == 0) {
      this.$store.dispatch("DDB_GET_SITES");
    }
  },
  watch: {
    items() {
      this.open_all = true;
    },
    user() {
      this.open_all = false;
      this.loading = true;
      this.$store.dispatch("DDB_GET_TREEVIEW_CUSTOMER", this.user).then(()=>this.loading = false);
    },
  },
};
</script>
<style lang="css">
.p_c_no_match {
  color: #b71c1c;
}
.h_pointer:hover {
  cursor: pointer;
}
.name_width {
  min-width: 160px;
  display: inline-block;
}
.c_width {
  min-width: 200px;
  display: inline-block;
}
</style>