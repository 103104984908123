var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navbar',{on:{"toggle-drawer":function($event){_vm.$refs.drawer.drawer = !_vm.$refs.drawer.drawer}}}),_c('sidenav',{ref:"drawer"}),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pl-2 pr-2"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"table_tabs ml-3 mt-3 mr-3"},[_c('v-tab',{attrs:{"to":"/"}},[_vm._v("Overview")]),_c('v-tab',{attrs:{"to":"/footageuploads"}},[_vm._v("Footage")]),_c('v-tab',{attrs:{"to":"/videolist"}},[_vm._v("Videos")]),_c('v-tab',{attrs:{"to":"/betsylist"}},[_vm._v("Betsy")])],1)],1),_c('v-col',{staticClass:"main_col_vids",attrs:{"xl":"2","md":"2","sm":"2","cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xl":"9","cols":"10"}},[_c('v-select',{staticClass:"select_filter direction_sort mt-2",attrs:{"items":[
                      'Random',
                      'Date',
                      'Size',
                      'Duration',
                      'Camera',
                      'Customer',
                    ],"dense":"","flat":"","hide-details":""},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1),_c('v-col',{attrs:{"xl":"3"}},[(!_vm.sort_direction && _vm.sort != 'Random')?_c('v-icon',{attrs:{"id":"sort_icon"},on:{"click":_vm.changeSortDir}},[_vm._v("mdi-arrow-down")]):_vm._e(),(_vm.sort_direction && _vm.sort != 'Random')?_c('v-icon',{attrs:{"id":"sort_icon"},on:{"click":_vm.changeSortDir}},[_vm._v("mdi-arrow-up")]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"mb_hidden",attrs:{"xl":"1","md":"1","sm":"2"}},[_c('v-select',{staticClass:"select_filter select_grid mt-2",attrs:{"items":[6, 12, 24],"dense":"","flat":"","hide-details":""},model:{value:(_vm.grid),callback:function ($$v) {_vm.grid=$$v},expression:"grid"}})],1),_c('v-col',{attrs:{"xl":"2","md":"2","sm":"3","cols":"6"}},[_c('v-select',{staticClass:"select_filter mt-2",attrs:{"items":[
                  'All Sources',
                  'Jetson',
                  'Reolink Cloud',
                  'IPhone',
                  'FTP',
                ],"dense":"","flat":"","hide-details":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})],1),_c('v-col',{attrs:{"xl":"2","md":"2","sm":"2","cols":"6"}},[_c('v-select',{staticClass:"select_filter mt-2",attrs:{"items":_vm.customers,"dense":"","flat":"","hide-details":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',{attrs:{"xl":"2","md":"2","sm":"3","cols":"6"}},[_c('v-select',{staticClass:"select_filter mt-2",attrs:{"items":_vm.cameras[1],"dense":"","flat":"","hide-details":""},model:{value:(_vm.camera),callback:function ($$v) {_vm.camera=$$v},expression:"camera"}})],1)],1),(_vm.videos)?_c('v-row',{staticClass:"ml-0 mr-0 pb-3"},[(_vm.grid == 6)?_c('div',{staticClass:"grid-container-6"},_vm._l((_vm.videos),function(video,index){return _c('div',{key:video.video_path + video.createdAt,staticClass:"pa-0"},[_c('img',{staticClass:"grid_img",attrs:{"alt":"","id":video.video_path + video.createdAt,"src":`${
                    _vm.cookie_bucket + video.video_path + '_480x270.jpg'
                  }`},on:{"click":function($event){return _vm.select_video(index)}}})])}),0):_vm._e(),(_vm.grid == 12)?_c('div',{staticClass:"grid-container-12"},_vm._l((_vm.videos),function(video,index){return _c('div',{key:video.video_path + video.createdAt,staticClass:"pa-0"},[_c('img',{staticClass:"grid_img",attrs:{"alt":"","id":video.video_path + video.createdAt,"src":`${
                    _vm.cookie_bucket + video.video_path + '_480x270.jpg'
                  }`},on:{"click":function($event){return _vm.select_video(index)}}})])}),0):_vm._e(),(_vm.grid == 24)?_c('div',{staticClass:"grid-container-24"},_vm._l((_vm.videos),function(video,index){return _c('div',{key:video.video_path + video.createdAt,staticClass:"pa-0"},[_c('img',{staticClass:"grid_img",attrs:{"alt":"","id":video.video_path + video.createdAt,"src":`${
                    _vm.cookie_bucket + video.video_path + '_480x270.jpg'
                  }`},on:{"click":function($event){return _vm.select_video(index)}}})])}),0):_vm._e()]):_vm._e()],1)],1)],1),(_vm.videos && _vm.selected_index != null)?_c('v-dialog',{attrs:{"max-width":"1200px","min-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.videos[_vm.selected_index])?_c('v-card-title',[_c('div',{attrs:{"id":"full_size_ct_div"}},[_c('b',{staticClass:"footage_link"},[_vm._v(_vm._s(_vm.videos[_vm.selected_index].camera.camera_name))]),_c('span',{staticClass:"nav_arrows_f"},[_c('span',{staticClass:"filter_heading"},[(_vm.selected_index > 0)?_c('v-icon',{staticClass:"mt-n1",attrs:{"large":""},on:{"click":function($event){return _vm.select_video(_vm.selected_index - 1)}}},[_vm._v("mdi-chevron-left")]):_c('v-icon',{staticClass:"mt-n1",attrs:{"large":"","disabled":""}},[_vm._v("mdi-chevron-left")]),(_vm.selected_index < _vm.videos.length - 1)?_c('v-icon',{staticClass:"mt-n1",attrs:{"large":""},on:{"click":function($event){return _vm.select_video(_vm.selected_index + 1)}}},[_vm._v("mdi-chevron-right")]):_c('v-icon',{staticClass:"mt-n1",attrs:{"large":"","disabled":""}},[_vm._v("mdi-chevron-right")])],1)])])]):_vm._e(),_c('v-card-text',[_c('video-player',{ref:"Player",staticClass:"vjs-big-play-centered",attrs:{"options":{
              autoplay: true,
              controls: true,
              fluid: true,
            }}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }