export function createGeneralFilter(getters){
  var PGIE_type_list = getters.getPGIETypeList;
  var Species_type_list = getters.getSpeciesTypeList;
  var Vehicle_type_list = getters.getVehicleTypeList;
  var Vehicle_make_list = getters.getVehicleMakeList;
  var Human_pose_list = getters.getHumanPoseList;

  var filters = {
      PGIE: {},
      estrus:{},
      class_label: {},
      species_label: {},
      Species: {},
      SGIE: {},
      OCR: {},
      SGIE_Conf: {},
      SGIE_Ratio: {},
      contraction_label: {},
      contraction_label_2: {},
      breed_label: {},
      //Contraction_Classification: {},
      activity_label: {},
      age_label: {},
      suckling_label: {},
      Predator: {},
      body_parts: {},
      body_parts_conf: {},
      calving_label: {},
      delivery_label: {},
      Time: {
          hour_start: '',
          hour_end: ''
      },
      Tag1: {
        tag_1_id_label: '',
        tag_1_id_conf: ''
    },
    Tag2: {
      tag_2_id_label: '',
      tag_2_id_conf: ''
  },
      mounting_label: {},
      chinrest_label: {},
      mounting_label_2: {},
      chinrest_label_2: {},
      heat_detection_label: {},
      Vehicle_Type: {},
      Vehicle_Make: {},
      Human_Pose: {},
      conf: {}
  }

  PGIE_type_list.forEach(e=>{
    // if(e=="animal"){
      filters.class_label[e] = false;
    // }else{
    //   filters.PGIE[e] = false;
    // }
  });

  Species_type_list.forEach(e=>{
    // if(e=="cow"){
      filters.species_label[e] = false;
    // }else{
    //   filters.Species[e] = false;
    // }
  });

  Vehicle_type_list.forEach(e=>{
    filters.Vehicle_Type[e] = false;
  });

  Vehicle_make_list.forEach(e=>{
    filters.Vehicle_Make[e] = false;
  });

  Human_pose_list.forEach(e=>{
    filters.Human_Pose[e] = false;
  });

  return filters;
}