<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Camera Uploads</span>
              <v-spacer></v-spacer>

              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                :disabled="loading"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :headers="selected_headers"
                :items="analytics"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                :sort-desc="true"
                no-data-text="No data available"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header">
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >


                      <v-tab>General</v-tab>

                    </v-tabs>
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>
   
                <template v-slot:[`item.customer_id`]="{ item }">
                  <span>{{ item.customer.first_name }} {{ item.customer.last_name }}</span>
                </template>
 
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import CustomerFilter from "../components/CustomerFilter.vue";
//import CameraAutoComplete from "../components/CameraAutoComplete.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerFilter,
   // CameraAutoComplete,
  },
  data() {
    return {
      search: "",
      loading: true,
      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      datetime_menu: false,

      selected_date: false,
      selected_camera: null,

      selected_table: 0,


      options: { sortBy: ["datetime"], sortDesc: [true], limit: 10, offset: 0 },



      selected_image_index: false,
      selected_customer: "All Customers",
      email_filter: "All",
      txt_filter: "All",

      feedback_received_filter: "All",
      feedback_positive_filter: "All",
      editable: false,

      edit_value: null,
    };
  },
  created() {
    this.selected_date = moment().format("YYYY-MM-DD")
         },
  methods: {

    refresh() {
      this.loading = true;
      this.$store
        .dispatch("DDB_GET_MEDIA_BY_CAMERA_SUMMARY", this.query_params)
        .then(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    selected_headers() {
      if (this.selected_table == 2) {
        return this.headers_user_feedback;
      } else if (this.selected_table == 1) {
        return this.headers_internal_feedback;
      }
      return this.headers_general;
    },

    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      return {
        date: this.selected_date,
      };
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },

    headers_general() {
      return [
        {
          text: "Camera",
          value: "camera_id",
          sortable: false,
          filter: (item=>{
            if (item.indexOf(this.search.toUpperCase()) != -1 || !this.search) {
              return true
            } return false
          })
        },
        {
          text: "Camera Name",
          value: "camera.name",
        },
        {
          text: "Customer",
          value: "customer_id",
          filter: (item=>{
            if (item == this.selected_customer || this.selected_customer == "All Customers") {
              return true
            } return false
          })
        },
        {
          text: "Date",
          value: "media_date",
          sortable: false,
        },
        {
          text: "Images",
          value: "image_count",
        },
        {
          text: "Videos",
          value: "video_count",
        },
        {
          text: "Total Frames",
          value: "total_frames",
        },
      ];
    },


    analytics() {
      return this.$store.getters.getMediaByCameraSummary
        ? this.$store.getters.getMediaByCameraSummary
        : [];
    },
  },

  watch: {
    date() {
      var selected_date = moment(this.date, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );

      this.selected_date = selected_date;
    },

    query_params() {
      if (this.query_params) {
        this.loading = true;
        this.$store
          .dispatch("DDB_GET_MEDIA_BY_CAMERA_SUMMARY", this.query_params)
          .then(() => {
            this.loading = false;
          });
      }
    },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.notification_img:hover {
  transform: scale(1.4);
  z-index: 500;
}
</style>
