<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px" min-width="860px">
      <v-card :loading="loading">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">
                {{ create_new ? "Add Collaborator" : "Edit Collaborator" }}
              </h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-row>
            <v-col cols="12">
              <v-form v-model="form_valid">

                <v-text-field
                  outlined
                  dense
                  :clearable="create_new"
                  :readonly="!create_new"
                  :rules="[rules.email]"
                  v-model="editedItem.owner_email"
                  @input="editedItem.owner_email = editedItem.owner_email.toLowerCase()"
                  label="Owner Email*"
                  class="text-validated"
              />
            
              <v-text-field
                  outlined
                  dense
                  :clearable="create_new"
                  :readonly="!create_new"
                  :rules="[rules.email]"
                  v-model="editedItem.collaborator_email"
                  @input="editedItem.collaborator_email = editedItem.collaborator_email.toLowerCase()"
                  label="Collaborator Email*"
                  class="text-validated"
              />
            
            </v-form>
              <v-checkbox
                v-if="create_new"
                v-model="immediate"
                dense
                label="Approve Immediately?"
              />
            </v-col>

            <v-col cols="4" class="pt-0 mt-0"
              ><h4>Permission:</h4>
              <div style="width: 80%" class="pt-4">
                <v-select
                  outlined
                  dense
                  v-model="editedItem.permission"
                  :items="['READ', 'WRITE']"
                  label="Access"
                ></v-select>
              </div>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions class="pr-6">
          <v-row>
            <v-col cols="12" class="py-0">
              <div style="float: right">
                <v-btn color="gray" width="120" @click="close" class="mr-4">
                  Cancel
                </v-btn>
                <v-btn
                  style="float: right"
                  color="primary"
                  width="120"
                  :disabled="!form_valid"
                  @click="save"
                  v-if="this"
                >
                  Save
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-if="error"
              ><span class="error_span">{{ error }}</span></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { eventBus } from "../main";
import rulesMixin from "../mixins/rulesMixin";
import { initializeLambdaClient } from "../utilities/sessionUtils";
import { InvokeCommand } from "@aws-sdk/client-lambda";

// @vuese
// @group Components
// The editing card for collaboration
export default {
  data() {
    return {
      dialog: false,
      form_valid: false,
      editedItem: {},
      error: "",
      create_new: false,
      user: {},
      loading: false,
      immediate: false,
      lambda: null,
      emails: [],
      selected_colab: 0,
    };
  },
  mixins: [rulesMixin],
  created() {
    eventBus.$on("open_dialog_collaboration", (params) => {
      this.open(params);
    });
  },
  methods: {
    open(params) {
      console.log(params)
      this.emails = [];
      this.editedItem = params.item;

      this.create_new = params.create_new;
      this.selected_colab = params.selected_colab;
      this.user = params.user;
      this.dialog = true;
      this.immediate = false;
    },
    close() {
      this.dialog = false;
    },
    add_collaborator() {
      var operation;
      operation = this.create_new ? "CREATE" : "UPDATE";
      var params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
        InvocationType: "Event",
        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: operation,
          collaborator_email: this.editedItem.collaborator_email,
          main_user_email: this.editedItem.owner_email,
          permission: this.editedItem.permission,
          suppress_notifications: false,
          notifications_enabled: true
        }),
      };
      var command = new InvokeCommand(params);
      return new Promise((resolve, reject) => {
        this.lambda
          .send(command)
          .then(() => {
            setTimeout(() => {
              resolve();
            }, 2500);
          })
          .catch((err) => {
            console.log(err)
            reject(err);
          });
      });
      // }
    },
    confirm_collaboration() {
      var params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
        InvocationType: "Event",
        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: "APPROVAL",
          approved: true,
          collaborator_email: this.editedItem.collaborator_email,
          main_user_email: this.editedItem.owner_email,
          suppress_notifications: false,
        }),
      };
      var command = new InvokeCommand(params);
      return this.lambda.send(command);
      // }
    },
    async save() {
      this.loading = true;

      initializeLambdaClient()
        .then((client) => {
          this.lambda = client;


          return this.add_collaborator();
        })
        .then(() => {
          console.log("immediate: " + this.immediate);
          if (this.immediate) {
            return this.confirm_collaboration();
          } else {
            return Promise.resolve();
          }
        })
        .then(() => {
          setTimeout(() => {
            this.dialog = false;
            this.loading = false;
            if (this.$store.getters.getEditedCustomer) {
              // Fire after the camera is changed to update the current customer that is being edited, if there is any.
              eventBus.$emit("update_edited_customer");
            }
          }, 2500);
        })
        .catch((err) => {
          this.error = err.stack;
        });
    },
  },
  watch: {
    dialog() {
      this.$store.commit("SET_COLLABORATION_CARD_DIALOG", this.dialog);
    },
  },
};
</script>
