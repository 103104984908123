<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
      <v-col cols="12" class="main_card_col">
        <v-card elevation="2">
          <v-card-title>
            <span class="ml-2">Events</span>
<!-- 
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
          </v-card-title>
          <v-card-text class="text-center">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="events"
              :items-per-page="100"
              class="elevation-1"
              dense
              hide-default-footer
              :search="search"
            >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  class="table_pagination"
                  @update:options="updateOptions"
                  :items-per-page-options="[50, 100, 200]"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
              </template>
              <template v-slot:[`body.prepend`]>
                <tr class="mb_hidden">
                  <td class="select_width align_left">
                    <v-select
                      class="mt-n1"
                      :items="customers"
                      v-model="customer"
                      dense
                      flat
                      hide-details
                    ></v-select>
                  </td>

                  <td></td>
                  <td></td>
                  <td></td></tr
              ></template>
              <template v-slot:[`item.customer`]="{ item }">
                <span>{{ users[item.owner] }}</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.createdAt.split("T")[0] }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card></v-col
      >
    </v-row></v-container>
    
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
  },
  props: ["filter"],
  data() {
    return {
      headers: [
        { text: "Customer", value: "customer", width: "16%" },
        { text: "Date", value: "created_at", width: "16%" },
        { text: "Kind", value: "kind", width: "16%" },
        { text: "Description", value: "description" },
      ],
      search: "",
      staging_data: [],
      production_data: [],
      loading: true,
      customer: "All",
      filter_params: {},
    };
  },
  methods: {
    owner_filter() {
      if (this.filter != "all") {
        this.customer =
          this.$store.getters.getOwnerNameObject[0][this.filter];
      }
    },
  },
  computed: {
    users() {
      var owner_email_name = {};
      for (const i in this.$store.getters.getCognitoUsers) {
        for (const y in this.$store.getters.getCognitoUsers[i].Attributes) {
          if (
            this.$store.getters.getCognitoUsers[i].Attributes[y].Name == "email"
          ) {
            owner_email_name[this.$store.getters.getCognitoUsers[i].Username] =
              this.$store.getters.getCognitoUsers[i].Attributes[y].Value;
          }
        }
      }
      return owner_email_name;
    },
    camera_objects() {
      return this.$store.getters.getCameraObjects;
    },
    customers() {
      var customers = this.$store.getters.getCustomerNames

      const sortAlphaNumCams = (a, b) =>
        a.localeCompare(b, "en", { numeric: true });
      customers.sort(sortAlphaNumCams);
      customers.unshift("All");

      return customers;
    },
    events() {
      return this.$store.getters.getEvents;
    },
  },
  created() {
    if (this.$store.getters.getEvents.length == 0) {
      this.$store.dispatch("DDB_GET_EVENTS", this.filter_params);
    } else {
      this.loading = false;
    }
    if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(this.owner_filter);
    }else {
      this.owner_filter()
    }
    if (this.$store.getters.getCameraObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CAMERAS");
    }
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
  },
  watch: {
    customer() {
      if (this.customer != "All") {
        this.filter_params.filter = {
          owner: {
            eq: this.$store.getters.getNameOwnerObject[this.customer],
          },
        };
      } else {
        delete this.filter_params.filter;
      }
      this.$store.dispatch("DDB_GET_EVENTS", this.filter_params);
    },

    events() {
      this.loading = false;
    },

    dialog() {
      if (this.dialog == false) {
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
</style>