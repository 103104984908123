<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      mobile-breakpoint="600"
      class="app--drawer"
      width="240"
      mini-variant-width="66"
      id="sidebar_drawer"
      :mini-variant="mini"
    >
      <v-list-item>
        <router-link to="/">
          <v-container>
            <v-img
              v-if="!$vuetify.theme.dark && !mini"
              class="logo ml-4"
              src="@/assets/LogoV2-Black_ClearBknd.png"
              width="145"
              height="60"
            ></v-img>
            <v-img
              v-if="!$vuetify.theme.dark && mini"
              class="logo"
              src="@/assets/favicon.png"
              width="40"
              height="40"
            ></v-img>
            <v-img
              v-if="$vuetify.theme.dark && !mini"
              class="logo ml-4"
              src="@/assets/LogoV2-White_ClearBknd.png"
              width="145"
              height="60"
            ></v-img>
            <v-img
              v-if="$vuetify.theme.dark && mini"
              class="logo"
              src="@/assets/favicon_white.png"
              width="40"
              height="40"
            ></v-img>
          </v-container>
        </router-link>

        <v-list-item-title></v-list-item-title>
        <!-- <v-btn icon @click.stop="mini = !mini"> -->
      </v-list-item>

      <v-list :id="mini ? 'sidenav_list_mini' : 'sidenav_list'">
        <v-list-item text class="rounded-lg" to="/">
          <v-list-item-avatar
            color="primary"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-home</v-icon>
          </v-list-item-avatar>
          <v-list-item-title data-cy="btn_side_navbar_dashboard"
            >Dashboard</v-list-item-title
          >
        </v-list-item>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/users">
          <v-list-item-avatar
            color="oc_orange"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-account-cowboy-hat</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>Login</v-list-item-title>
        </v-list-item>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/customers">
          <v-list-item-avatar
            color="oc_green"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-account-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item>

        <v-list-item text rounded to="/sites/all">
          <v-list-item-avatar
            color="oc_green"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-barn</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Sites</v-list-item-title>
        </v-list-item>

        <v-list-item text rounded to="/cameras/all">
          <v-list-item-avatar
            color="oc_green"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-cctv</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Cameras</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/wifi/all">
          <v-list-item-avatar
            color="oc_green"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-wifi</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Wi-Fi</v-list-item-title>
        </v-list-item>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-item text rounded to="/animalidentifications">
          <v-list-item-avatar
            color="oc_indigo"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-tag-multiple</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Animal ID</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/notifications">
          <v-list-item-avatar
            color="oc_indigo"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-message-badge-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item>
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/calving-analytics">
          <v-list-item-avatar
            color="oc_dark_purple"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-cow</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Calving</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/estrus-analytics">
          <v-list-item-avatar
            color="oc_dark_purple"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-gender-female</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Estrus</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/predator-analytics">
          <v-list-item-avatar
            color="oc_dark_purple"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-dog-side</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Predator</v-list-item-title>
        </v-list-item>
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-item text rounded to="/images">
          <v-list-item-avatar
            color="oc_yellow"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Image Search</v-list-item-title>
        </v-list-item>
        
        <v-list-item text rounded to="/imagetable">
          <v-list-item-avatar
            color="oc_yellow"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-folder-multiple-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Image Table</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item text rounded to="/videos/all">
          <v-list-item-avatar
            color="oc_yellow"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-video-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Videos</v-list-item-title>
        </v-list-item> -->

        <!-- <v-list-item text rounded to="/videos/heat">
          <v-list-item-avatar
            color="oc_yellow"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-video-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Videos Heat</v-list-item-title>
        </v-list-item> -->

        <v-list-item text rounded to="/videoboard">
          <v-list-item-avatar
            color="oc_purple"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-video-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Video Search</v-list-item-title>
        </v-list-item>

        <v-list-item text rounded to="/videotable">
          <v-list-item-avatar
            color="oc_purple"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-folder-play-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Video Table</v-list-item-title>
        </v-list-item>

     
       


        <!-- <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/reports">
          <v-list-item-avatar
            color="oc_blue"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-chart-tree</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/treeview">
          <v-list-item-avatar
            color="oc_blue"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-file-tree-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Treeview</v-list-item-title>
        </v-list-item> -->
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/notes">
          <v-list-item-avatar
            color="oc_blue"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-message-bulleted</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Notes</v-list-item-title>
        </v-list-item>
        <v-list-item text rounded to="/feedbacks">
          <v-list-item-avatar
            color="oc_blue"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-comment-quote</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Feedback</v-list-item-title>
        </v-list-item>
        <!-- <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider> -->
        <!-- 
        <v-list-item text rounded to="/footage/all">
          <v-list-item-icon>
            <v-icon color="primary">mdi-adjust</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Footage</v-list-item-title>
        </v-list-item> -->

        <!-- <v-list-item text rounded to="/videos/all">
          <v-list-item-avatar
            color="oc_yellow"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-video-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Videos</v-list-item-title>
        </v-list-item> -->

        <v-divider
          v-if="customer_selected || customer_selected_glob"
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item
          text
          rounded
          to="/customermap"
          v-if="customer_selected || customer_selected_glob"
        >
          <v-list-item-avatar
            color="oc_blue"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-map</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Map</v-list-item-title>
        </v-list-item>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item>
          <v-list-item-avatar
            color="oc_indigo"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon
              v-if="!$vuetify.theme.dark"
              @click="toggleTheme"
              dark
              color="white"
              >mdi-weather-night</v-icon
            >

            <v-icon
              v-if="$vuetify.theme.dark"
              @click="toggleTheme"
              dark
              color="white"
              >mdi-weather-night</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-title
            class="theme_mode"
            v-if="!$vuetify.theme.dark"
            @click="toggleTheme"
            data-cy="btn_side_navbar_dark_mode"
            >Dark Mode</v-list-item-title
          >
          <v-list-item-title
            class="theme_mode"
            v-if="$vuetify.theme.dark"
            @click="toggleTheme"
            data-cy="btn_side_navbar_light_mode"
            >Light Mode</v-list-item-title
          >
        </v-list-item>

        <v-list-item text rounded v-if="!mini">
          <v-list-item-content class="text-center">
            <v-list-item-title>
              <h4 class="pr-4">v {{ version["version"] }}</h4>
              <h6 class="pr-4">{{ version["last_update"] }}</h6>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
import version from "../data/version.json";
import Vue from "vue";

// @vuese
// @group Components
// The side navigation bar
export default {
  name: "SideNav",
  data() {
    return {
      on_lf: false,
      on_pb: false,
      version: version,
      feedtype: null,
      cameras: [],
      camerasBat: [],
      inset: null,
      drawer: true,
      mini: false,
      selectedIndex: 1,
      refreshMessage: String,
      sortedCameras: null,
      lf_pb: null,
      active_cams: [],
      selected_cameras: [],
      customer_selected: false,
    };
  },
  computed: {
    // cameras() {
    //   return this.$store.getters.getCameras;
    // },
    customer_selected_glob() {
      this.customer_selected;
      return (
        !(Vue.prototype.$selectCustomer == "All Customers") &&
        !(Vue.prototype.$selectCustomer == undefined)
      );
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = "#cc9b5c";
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      const menuPreference = localStorage.getItem("mini");
      if (menuPreference) {
        if (menuPreference === "true") {
          this.mini = true;
        } else {
          this.mini = false;
        }
      }
    },
    miniStorage() {
      if (document.getElementsByTagName("body")[0].clientWidth > 600) {
        this.mini = !this.mini;
        localStorage.setItem("mini", this.mini);
      } else {
        this.mini = false;
        this.drawer = !this.drawer;
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");

    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }

    // mini
    const menuPreference = localStorage.getItem("mini");
    if (menuPreference) {
      if (menuPreference === "true") {
        this.mini = true;
      } else {
        this.mini = false;
      }
    }
  },

  created() {
    if (window.innerWidth < 600) {
      this.drawer = false;
    }
    eventBus.$on("mini_sidenav", () => this.miniStorage());
    eventBus.$on("customer_selected", (selected) => {
      this.customer_selected = selected;
    });
  },
};
</script>





<style lang="css" scoped>
/* #bottom {
  position: absolute;
  bottom: 0;
} 
#divider {
  height: 60px;
}*/

.sidenav_divider_mini {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.sidenav_divider {
  margin-left: 34px !important;
  margin-right: 22px !important;
}
#sidenav_list > .v-list-item::before {
  border-radius: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 20px !important;
  margin-left: 24px;
  margin-right: 24px;
}

#sidenav_list > .v-list-item > .v-ripple__container {
  border-radius: 8px !important;
  margin-top: 3px !important;
  height: 50px;
  width: 192px;
  padding-left: 24px !important;
  margin-left: 24px !important;
}
#sidenav_list > .v-list-item:focus::before {
  opacity: 0 !important;
}

#sidenav_list > .v-list-item:hover::before {
  opacity: 0.04;
}

#sidenav_list > .v-list-item--active:hover::before {
  opacity: 0.12 !important;
}
#sidenav_list > .v-list-item {
  padding-left: 30px;
}

.online_icon_bat {
  margin-left: -16px;
  margin-right: 16px;
}
.online_icon {
  margin-left: -16px;
  margin-right: 16px;
}
.disabledCams {
  background-color: rgba(0, 0, 0, 0.12);
}
.cam {
  color: grey;
  cursor: pointer;
  user-select: none;
}
.tt {
  cursor: not-allowed;
}
.camOffline {
  color: grey;
  cursor: not-allowed;
}
.theme_mode {
  cursor: pointer;
}
.camBat {
  user-select: none;
}
.no_pointer {
  cursor: default !important;
}
.camIcon {
  color: inherit;
}
.logo {
  margin-top: 8px !important;
  margin-left: 8px !important;
}
#cameras_link {
  text-decoration: none;
}
html,
body {
  height: 100%;
}
.right-nav {
  background-color: blanchedalmond;
}
#night {
  margin-right: 8px;
}
.coming_soon {
  color: rgba(0, 0, 0, 0.5) !important;
}

</style>
