<template>
	<iframe :src="link" width="100%" :height="height" frameborder="0"></iframe>
</template>

<script>
// @vuese
// @group Components
// The vue component for Grafana graphs
export default {
	props: [
		"from",
		"to",
		"database",
		"table",
		"limit",
		"confidence",
		"panelId",
		"height",
		"time_bucket",
		"source",
		"dashboard",
		"client",
		"server",
		"last_time",
		"sgie_contraction",
		"sgie_cow_suckling",
		"sgie_cow_age",
		"frame_area",
		"bbox_tag_area",
		"camera_model_list",
		"camera_id_list",
		"camera_model",
		"camera_id",
		"model_name",
		"metric_name",
		"func"
	],
	computed: {
		link() {
			var from = this.from ? `&from=${this.from}` : "";
			var to = this.to ? `&to=${this.to}` : "";
			var database = this.database ? `&var-database=${this.database}` : "";
			var table = this.table ? `&var-table=${this.table}` : "";
			var limit = this.limit ? `&var-limit=${this.limit}` : "";
			var confidence = this.confidence
				? `&var-confidence=${this.confidence}`
				: "";
			var time_bucket = this.time_bucket
				? `&var-time_bucket=${this.time_bucket}`
				: "";
			var source = this.source ? `&var-source=${this.source}` : "";
			var panelId = `&panelId=${this.panelId}`;
			var client = this.client ? `&var-client=${this.client}` : "";
			var server = this.server ? `&var-server=${this.server}` : "";
			var last_time = this.last_time ? `&var-last_time=${this.last_time}` : "";

			var sgie_contraction = this.sgie_contraction
				? `&var-sgie_contraction='${this.sgie_contraction}'`
				: "";
			var sgie_cow_suckling = this.sgie_cow_suckling
				? `&var-sgie_cow_suckling='${this.sgie_cow_suckling}'`
				: "";
			var sgie_cow_age = this.sgie_cow_age
				? `&var-sgie_cow_age='${this.sgie_cow_age}'`
				: "";
            var frame_area = this.frame_area? `&var-frame_area=${this.frame_area}` : "";
			var bbox_tag_area = this.bbox_tag_area? `&var-bbox_tag_area=${this.bbox_tag_area}` : "";

			var camera_model_list = this.camera_model_list? this.camera_model_list.map(e=>`'${e}'`).join(","):"";
			console.log(camera_model_list);
			var camera_id_list = this.camera_id_list? this.camera_id_list.map(e=>`'${e}'`).join(","):"";
			console.log(camera_id_list);
			var camera_model = this.camera_model? `&var-camera_model='${this.camera_model}'` : "";
			var camera_id = this.camera_id? `&var-camera_id='${this.camera_id}'` : "";
			var model_name = this.model_name? `&var-model_name=${this.model_name}` : "";
			var metric_name = this.metric_name? `&var-metric_name=${this.metric_name}` : "";
			var func = this.func ? `&var-function=${this.func}`:""

			var variables = 
				bbox_tag_area + 
				frame_area + 
				sgie_contraction + 
				sgie_cow_suckling + 
				sgie_cow_age + 
				database + 
				table +
				limit +
				confidence + 
				from + 
				to + 
				time_bucket +
				source +
				client +
				server +
				last_time +
				camera_model+
				camera_id+
				model_name+
				metric_name+
				func;
			// &refresh=1m
			return `https://grafana.onecup.ai/d-solo/${this.dashboard_string}?orgId=1${variables}&theme=${this.theme}${panelId}`;
		},
		theme() {
			if (this.$vuetify.theme.dark) {
				return "dark";
			} else {
				return "light";
			}
		},
		dashboard_string() {
			if (this.dashboard == "inference-timestream") {
				return "z0SLMxh4z/inference-timestream";
			} else if (this.dashboard == "pipeline-metrics") {
				return "tx4ZElTVk/pipeline-metrics";
			} else if (this.dashboard == "vsftpd") {
				return "8OobCxoVk/vsftpd";
			} else if (this.dashboard == "vprc") {
				return "EPvv-5AVz/vprc";
			} else if (this.dashboard == "footage_metrics") {
				return "9wv3ZIa4z/footage_metrics"
			} else if (this.dashboard == "miscellaneous"){
				return "e59w7v-Vz/miscellaneous"
			} else if (this.dashboard == "triton-metrics"){
				return "pbOGcwq4k/triton-metrics"
			} else {
				return "z0SLMxh4z/inference-timestream";
			}
		},
	},
};
</script>
