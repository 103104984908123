<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container>
      <v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Customers</span>

              <v-spacer></v-spacer>
              <v-switch
                class="mt-0 mr-6 mb-0"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                hide-details
              ></v-switch>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Customer</span>
              </v-tooltip>

              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn class="mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn>
              <div style="width: 180px" class="mt-n4 pr-3">
                <v-select
                  :items="[
                    { text: 'None', value: null },
                    { text: 'Type', value: 'type' },
                  ]"
                  v-model="group_by"
                  flat
                  hide-details
                ></v-select>
              </div>
              <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </div>
              <CustomerFilter
                class="mt-n4"
                v-model="selected_customer"
                @change="refreshKey++"
                :disabled="!!editable"
              ></CustomerFilter>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :loading="loading"
                :headers="displayed_headers"
                :items-per-page="100"
                class="elevation-1 entry_table main_table"
                :multi-sort="multi_sort"
                :group-by="group_by"
                hide-default-footer
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                single-expand
                show-expand
                :expanded.sync="expanded"
                @click:row="
                  (item) => {
                    $router.push(`/customer?id=${item.id}`);
                  }
                "
                :item-class="item_class"
                :items="customers"
                :search="search"
              >
                <template v-slot:expanded-item="{ headers }">
                  <td :colspan="headers.length" class="px-0" v-if="expanded[0]">
                    <v-data-table
                      :loading="note_loading"
                      loading-text="Loading... Please wait"
                      :headers="headers_notes"
                      :items="selected_notes"
                      sort-by="note_datetime"
                      :sort-desc="true"
                      :items-per-page="5"
                      style="border-radius: 0"
                      class="elevation-1 expanded_row"
                      hide-default-footer
                      dense
                    >
                      <template
                        v-slot:top="{ pagination, options, updateOptions }"
                      >
                        <div class="d-flex justify-end">
                          <div class="table_notes_title mt-2">
                            <h3>NOTES</h3>
                          </div>
                          <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :items-per-page-options="[5]"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          />
                          <!-- class="table_pagination_notes" -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                @click="open_note"
                                color="primary"
                                >mdi-note-plus-outline</v-icon
                              >
                            </template>
                            <span>Add Note</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-slot:[`item.note_datetime`]="{ item }">
                        {{ item.note_datetime.split("T")[0] }}
                      </template>
                      <template v-slot:[`item.author`]="{ item }">
                        <span v-if="item.author">{{
                          item.author.first_name + " " + item.author.last_name
                        }}</span>
                      </template>
                      <template v-slot:[`item.note_text`]="{ item }">
                        <v-edit-dialog
                          @save="save_notes(item)"
                          @cancel="edit_value_note = null"
                          large
                          @open="edit_value_note = item.note_text"
                        >
                          {{ item.note_text }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="edit_value_note"
                              label="Note"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
                >
                  <div style="display: flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="(e) => handleExpansion(item, isExpanded, e)"
                            dense
                            class="mr-5"
                            :color="hover || isExpanded ? 'primary' : 'gray'"
                            >{{
                              isExpanded
                                ? "mdi-note-off-outline"
                                : "mdi-note-outline"
                            }}</v-icon
                          >
                        </v-hover>
                      </template>
                      <span>Notes</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="(e) => editItem(item, e)"
                            dense
                            :color="hover ? 'primary' : 'gray'"
                            >mdi-pencil</v-icon
                          >
                        </v-hover>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </template>

                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>Mailing Address</v-tab>
                    <v-tab>Physical Address</v-tab>
                    <v-tab>Notification</v-tab>
                    <v-tab>ID</v-tab>
                    <v-tab>MISC</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                  <v-row no-gutters class="pa-0 ma-0">
                    <div id="top_wrapper" class="c">
                      <div id="scroll_content"></div>
                    </div>
                  </v-row>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'status')"
                    @cancel="cancel_edit"
                    @open="open_field(item.status)"
                    @close="close_edit"
                    :return-value.sync="item.status"
                    large
                  >
                    <span
                      :class="{
                        green_color: item.status == 'ACTIVE',
                        red_color: item.status == 'INACTIVE',
                        gray_color: item.status == 'DISABLED',
                      }"
                      >{{ item.status }}</span
                    >
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        label="Customer Status"
                        :items="$store.getters.getCustomerStatus"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.id`]="{ item }">
                  <div
                    class="d-flex justify-space-between"
                    style="width: 300px"
                  >
                    <span>{{ item.id }}</span
                    ><copy-button :text="item.id"></copy-button>
                  </div>
                </template>
                <!-- <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template> -->
                <template v-slot:[`item.updated_at`]="{ item }">
                  <span v-if="item.updated_at">{{ item.updated_at.split("T")[0] }}</span>
                </template>
                <template v-slot:[`item.domain`]="{ item }">
                  <span v-if="users[item.id]">{{
                    users[item.id].split("@")[1]
                  }}</span>
                </template>
                <template v-slot:[`item.site_count`]="{ item }">
                  <v-list-item
                    :to="
                      '/sites/' +
                      (item.first_name + '_' + item.last_name).replaceAll(
                        ' ',
                        '_'
                      )
                    "
                    ><span class="table_link">{{
                      item.site_count
                    }}</span></v-list-item
                  >
                </template>
                <template v-slot:[`item.cam_count`]="{ item }">
                  <v-list-item :to="'/cameras/' + item.id"
                    ><span class="table_link">{{
                      item.cam_count
                    }}</span></v-list-item
                  >
                </template>
                <template v-slot:[`item.wifi_count`]="{ item }">
                  <v-list-item
                    :to="
                      '/wifi/' +
                      (item.first_name + '_' + item.last_name).replaceAll(
                        ' ',
                        '_'
                      )
                    "
                  >
                    <span class="table_link">{{
                      item.wifi_count
                    }}</span></v-list-item
                  >
                </template>
                <template v-slot:[`item.jetsons_count`]="{ item }">
                  <v-list-item
                    :to="
                      '/jetsons/' +
                      (item.first_name + '_' + item.last_name).replaceAll(
                        ' ',
                        '_'
                      )
                    "
                    ><span class="table_link">{{
                      item.jetsons_count
                    }}</span></v-list-item
                  >
                </template>

                <template v-slot:[`item.first_name`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'first_name')"
                    @cancel="cancel_edit"
                    @open="open_field(item.first_name)"
                    @close="close_edit"
                    :return-value.sync="item.first_name"
                    large
                  >
                    {{ item.first_name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="First Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.last_name`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'last_name')"
                    @cancel="cancel_edit"
                    @open="open_field(item.last_name)"
                    @close="close_edit"
                    :return-value.sync="item.last_name"
                    large
                  >
                    {{ item.last_name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Last Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'type')"
                    @cancel="cancel_edit"
                    @open="open_field(item.type)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.type"
                  >
                    {{ item.type }}
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="edit_value"
                        label="Type"
                        :items="$store.getters.getCustomerTypes"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'company_name')"
                    @cancel="cancel_edit"
                    @open="open_field(item.company_name)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.company_name"
                  >
                    {{ item.company_name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Company"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[1].line_1`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[1]"
                    @save="
                      save_record(item.addresses[1], 'address_mailing.line_1')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[1].line_1)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[1].line_1"
                  >
                    {{ item.addresses[1].line_1 }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[1].city`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[1]"
                    @save="
                      save_record(item.addresses[1], 'address_mailing.city')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[1].city)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[1].city"
                  >
                    {{ item.addresses[1].city }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[1].province`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[1]"
                    @save="
                      save_record(item.addresses[1], 'address_mailing.province')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[1].province)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[1].province"
                  >
                    {{ item.addresses[1].province }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.addresses[1].postal_code`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[1]"
                    @save="
                      save_record(
                        item.addresses[1],
                        'address_mailing.postal_code'
                      )
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[1].postal_code)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[1].postal_code"
                  >
                    {{ item.addresses[1].postal_code }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[1].country`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[1]"
                    @save="
                      save_record(item.addresses[1], 'address_mailing.country')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[1].country)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[1].country"
                  >
                    {{ item.addresses[1].country }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.addresses[0].line_1`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[0]"
                    @save="
                      save_record(item.addresses[0], 'address_physical.line_1')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[0].line_1)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[0].line_1"
                  >
                    {{ item.addresses[0].line_1 }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[0].city`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[0]"
                    @save="
                      save_record(item.addresses[0], 'address_physical.city')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[0].city)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[0].city"
                  >
                    {{ item.addresses[0].city }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[0].province`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[0]"
                    @save="
                      save_record(
                        item.addresses[0],
                        'address_physical.province'
                      )
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[0].province)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[0].province"
                  >
                    {{ item.addresses[0].province }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.addresses[0].postal_code`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[0]"
                    @save="
                      save_record(
                        item.addresses[0],
                        'address_physical.postal_code'
                      )
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[0].postal_code)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[0].postal_code"
                  >
                    {{ item.addresses[0].postal_code }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.addresses[0].country`]="{ item }">
                  <v-edit-dialog
                    v-if="item.addresses[0]"
                    @save="
                      save_record(item.addresses[0], 'address_physical.country')
                    "
                    @cancel="cancel_edit"
                    @open="open_field(item.addresses[0].country)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.addresses[0].country"
                  >
                    {{ item.addresses[0].country }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Address 1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.phone_cell`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'phone_cell')"
                    @cancel="cancel_edit"
                    @open="open_field(item.phone_cell)"
                    @close="close_edit"
                    large
                    :return-value.sync="item.phone_cell"
                  >
                    {{ item.phone_cell }}
                    <template v-slot:input>
                      <masked-field v-model="edit_value" label="Cell Phone">
                      </masked-field>
                      <!-- <v-text-field
                        v-model="edit_value"
                        label="Cell Phone"
                        :rules="[rules.phone_number]"
                      ></v-text-field> -->
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- NOTIFICATIONS -->

                <template
                  v-for="name in notification_fields"
                  v-slot:[`item.${name.value}`]="{ item }"
                >
                  <v-edit-dialog
                    :key="name.value"
                    @save="save_record(item, name.value)"
                    @cancel="cancel_edit"
                    @open="open_field(getByString(item, name.value))"
                    @close="close_edit"
                    large
                  >
                    {{ getByString(item, name.value) }}
                    <template v-slot:input>
                      <v-select
                        v-model="edit_value"
                        :label="name.text"
                        :items="$store.getters.getNotificationFrequency"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>

      <!-- <v-dialog v-model="note_dialog" max-width="800px" min-width="760px">
        <v-card>
          <v-card-title>
            <span class="" style="color: #0090a4">Add Note</span>
          </v-card-title>

          <v-card-text>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="note_text"
                      label="Note"
                      outlined
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="pr-6">
            <v-spacer></v-spacer>
            <v-btn @click="note_dialog = false"> Cancel </v-btn>
            <v-btn color="primary" @click="add_note"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <note-card></note-card>
    </v-container>
    <customer-card></customer-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
// import { eventBus } from "../main.js";
// import chartjspie from '@/components/chartjs-pie.vue';
// import chartjsdoughnut from '@/components/chartjs-doughnut.vue';
// import chartjsline from '@/components/chartjs-line.vue';
// import chartjsbar from '@/components/chartjs-bar.vue';
import moment from "moment";
import Vue from "vue";
import CustomerCard from "@/components/CustomerCard.vue";
import { eventBus } from "../main";
import CopyButton from "../components/CopyButton.vue";
import CustomerFilter from "../components/CustomerFilter.vue";
import NoteCard from "../components/NoteCard.vue";
import rulesMixin from "../mixins/rulesMixin";
import MaskedField from "../components/PhoneMaskedField.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";
// @vuese
// @group Views
export default {
  mixins: [rulesMixin],
  components: {
    navbar,
    sidenav,
    CustomerCard,
    CopyButton,
    CustomerFilter,
    NoteCard,
    MaskedField,
  },
  data() {
    return {
      multi_sort: false,
      group_by: null,
      staging_data: [],
      production_data: [],
      staging_production: true,
      loading: false,
      editedItem: {
        mailing_address: {},
        physical_address: {},
        notification_preference: {},
      },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      owner_name: false,
      sort_by: ["updated_at"],
      sort_desc: true,
      delete_dialog: false,
      edit_value: null,
      expanded: [],
      edit_value_note: null,
      selected_notes: [],
      note_loading: false,
      editable: false,
      note_dialog: false,
      note_text: "",
      cursor: 0,
      refreshKey: 0,

      search: "",
      search_old: "",
      customer_list: [],
      customer_num: 0,
      customer_pages: 0,
      options: {},

      selected_customer: "",
    };
  },
  methods: {
    refresh() {
      this.fetch_customers();
    },
    fetch_customers() {
      this.loading = true;
      this.$store.dispatch("DDB_GET_CUSTOMERS").then(() => {
        this.loading = false;
      });
    },
    getByString(o, s) {
      s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      s = s.replace(/^\./, ""); // strip a leading dot
      var a = s.split(".");
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (o && k in o) {
          o = o[k];
        } else {
          return null;
        }
      }
      return o;
    },
    handleExpansion(item, state, e) {
      e.stopPropagation();
      this.expanded = [];
      if (!state) {
        this.expanded.push(item);
      }
    },
    item_class(item) {
      return this.expanded[0]?.id == item.id ? "expanded_row" : "";
    },
    open_note() {
      eventBus.$emit("open_dialog_note");
    },

    async add_note(note) {
      this.note_loading = true;
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.insert_admin_note_one,
          variables: {
            object1: {
              datetime: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
              id: uuidv4(),
              comment: note,
              customer_id: this.expanded[0].id,
              author: this.$store.getters.getUser.username,
            },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "customer",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
            this.note_loading = false;
          });
      });
    },
    async save_notes(note) {
      await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.update_admin_note_by_pk,
          variables: {
            pk_columns: { id: note.id },
            _set: { comment: this.edit_value_note },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      this.$nextTick(() => {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "customer",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
          });
      });
    },
    close_edit() {
      //this.edit_value = null;
    },
    async save_record(record, field) {
      this.loading = true;
      var edited_record = {};
      var pk_columns = {};
      var query = "";
      if (field.indexOf("address") !== -1) {
        query = "update_address_by_pk";
        if (field.indexOf("mailing") !== -1) {
          pk_columns.type = "MAILING";
        } else {
          pk_columns.type = "PHYSICAL";
        }
        pk_columns.customer_id = record.customer_id;
        edited_record[field.split(".")[1]] = this.edit_value;
      } else if (field.indexOf("notification_preferences") !== -1) {
        query = "update_notification_preference_by_pk";
        pk_columns.customer_id = record.id;
        edited_record[field.split(".")[1]] = this.edit_value;
      } else {
        query = "update_customer_by_pk";
        pk_columns.id = record.id;
        edited_record[field] = this.edit_value;
      }

      try {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations[query],
            variables: {
              pk_columns: pk_columns,
              _set: edited_record,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.data.errors) {
          throw new Error("GraphQL error: " + response.data.errors[0].message);
        }
        this.edit_value = null;

        this.fetch_customers();
      } catch (err) {
        console.log(err);
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value) {
      this.edit_value = value;
    },

    editItem(item) {
      if (!this.editable) {
        if (item == "create_new") {
          // this.editedItem = { wifi_location: {} };
          // this.dialog = true;
          // this.create_new = true;
          eventBus.$emit("open_dialog_customers", {
            item: {
              mailing_address: {},
              physical_address: {},
              notification_preference: {},
            },
            create_new: true,
          });
        } 
      }
    },
    close() {
      this.dialog = false;
      this.error = "";
      this.create_new = false;
      this.$nextTick(() => {
        this.editedItem = {
          mailing_address: {},
          physical_address: {},
          notification_preference: {},
        };
      });
    },
  },
  computed: {
    notification_fields() {
      return this.$store.getters.getNotificationFields;
    },
    displayed_headers() {
      if (this.selected_table == 0) {
        return this.headers_gen;
      } else if (this.selected_table == 1) {
        return this.headers_mailing_address;
      } else if (this.selected_table == 2) {
        return this.headers_physical_address;
      } else if (this.selected_table == 3) {
        return this.headers_notification;
      } else if (this.selected_table == 4) {
        return this.headers_id;
      } else {
        return this.headers_gen;
      }
    },
    displayed_fields() {
      return this.fields;
    },
    fields() {
      return [
        "first_name",
        "last_name",
        "type",
        "company_name",
        "status",
        "id",
      ];
    },
    headers_gen() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px",
        },
        {
          text: "First Name",
          value: "first_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Last Name",
          value: "last_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Type",
          value: "type",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Company Name",
          value: "company_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Domain",
          value: "domain",
          // sortable: false
        },

        { text: "Updated At", value: "updated_at", width: "140px" },
        {
          text: "Sites",
          value: "site_count",
          // sortable: false,
          cellClass: "link_cell",
          width: "90px",
        },
        {
          text: "Cams",
          value: "cam_count",
          // sortable: false,
          cellClass: "link_cell",
          width: "140px",
        },
        {
          text: "Wi-Fi",
          value: "wifi_count",
          // sortable: false,
          cellClass: "link_cell",
          width: "90px",
        },
        {
          text: "Status",
          value: "status",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "100px",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    fields_gen() {
      return ["first_name", "last_name", "type", "company_name", "status"];
    },
    headers_notification() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px",
        },
        {
          text: "First Name",
          value: "first_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Last Name",
          value: "last_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Email",
          value: "email_address",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Phone",
          value: "phone_cell",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        ...this.notification_fields.map((e) => {
          return {
            text: e.text,
            value: e.value,
            cellClass: this.editable ? "non-hl" : "no_edit",
          };
        }),
        { text: "", value: "data-table-expand" },
      ];
    },

    headers_id() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px",
        },
        {
          text: "First Name",
          value: "first_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Last Name",
          value: "last_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "ID",
          value: "id",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    fields_id() {
      return ["first_name", "last_name", "id"];
    },
    headers_mailing_address() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px",
        },
        {
          text: "First Name",
          value: "first_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Last Name",
          value: "last_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Address 1",
          value: "addresses[1].line_1",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "City",
          value: "addresses[1].city",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        {
          text: "Province",
          value: "addresses[1].province",
          // sortable: false,
          wcellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Postal Code",
          value: "addresses[1].postal_code",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Country",
          value: "addresses[1].country",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    fields_mailing_address() {
      return ["first_name", "last_name"];
    },
    headers_physical_address() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          width: "20px",
        },
        {
          text: "First Name",
          value: "first_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Last Name",
          value: "last_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Address 1",
          value: "addresses[0].line_1",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "City",
          value: "addresses[0].city",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        {
          text: "Province",
          value: "addresses[0].province",
          // sortable: false,
          wcellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Postal Code",
          value: "addresses[0].postal_code",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Country",
          value: "addresses[0].country",
          // sortable: false,
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    fields_physical_address() {
      return ["first_name", "last_name"];
    },
    headers_notes() {
      return [
        { text: "", value: "index", sortable: false, width: "53px" },
        {
          text: "Date",
          value: "note_datetime",
          width: "120px",
        },
        {
          text: "Author",
          value: "author",
          width: "220px",
        },
        {
          text: "Note",
          value: "note_text",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
      ];
    },
    owner_customer_names() {
      return this.$store.getters.getOwnerNameObject[0];
    },
    name_owner_names() {
      return this.$store.getters.getOwnerNameObject[1];
    },

    customers() {
      this.refreshKey;
      if (
        !(Vue.prototype.$selectCustomer == "All Customers") &&
        !(Vue.prototype.$selectCustomer == undefined)
      ) {
        var temp_customers = [];
        for (const n in this.$store.getters.getCustomerObjects) {
          if (
            this.$store.getters.getCustomerObjects[n].id ==
            Vue.prototype.$selectCustomer
          ) {
            temp_customers = [this.$store.getters.getCustomerObjects[n]];
          }
        }
        temp_customers[0].cam_count =
          this.$store.getters.getCustomerObjects[0].cameras_aggregate.aggregate.count;
        temp_customers[0].site_count =
          this.$store.getters.getCustomerObjects[0].sites.length;
        temp_customers[0].wifi_count =
          this.$store.getters.getCustomerObjects[0].wifis_aggregate.aggregate.count;

        for (const j in temp_customers) {
          // temp_customers[j].index = Number(j)+1;
          temp_customers[j].mail_address = this.users[temp_customers[j].id];
        }
        return temp_customers;
      } else {
        let temp_customers = this.$store.getters.getCustomerObjects;
        for (const i in this.$store.getters.getCustomerObjects) {
          temp_customers[i].cam_count =
            this.$store.getters.getCustomerObjects[
              i
            ].cameras_aggregate.aggregate.count;
          temp_customers[i].site_count =
            this.$store.getters.getCustomerObjects[i].sites.length;
          temp_customers[i].wifi_count =
            this.$store.getters.getCustomerObjects[
              i
            ].wifis_aggregate.aggregate.count;
        }
        for (const j in temp_customers) {
          // temp_customers[j].index = Number(j)+1;
          temp_customers[j].email_address = this.users[temp_customers[j].id];
        }
        return temp_customers;
      }
    },
    users() {
      var owner_email_name = {};
      for (const i in this.$store.getters.getCognitoUsers) {
        for (const y in this.$store.getters.getCognitoUsers[i].Attributes) {
          if (
            this.$store.getters.getCognitoUsers[i].Attributes[y].Name == "email"
          ) {
            owner_email_name[this.$store.getters.getCognitoUsers[i].Username] =
              this.$store.getters.getCognitoUsers[i].Attributes[y].Value;
          }
        }
      }
      return owner_email_name;
    },
  },
  mounted() {
    let wrapper_1 = this.$el.querySelector(
      ".main_table .v-data-table__wrapper"
    );
    let wrapper_2 = this.$el.querySelector("#top_wrapper");
    wrapper_1.onscroll = function () {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };

    wrapper_2.onscroll = function () {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };

    const resizeObserver = new ResizeObserver(() => {
      let content_1 = this.$el.querySelector(
        ".main_table .v-data-table__wrapper table"
      );
      let content_2 = this.$el.querySelector("#scroll_content");
      content_2.style.width = content_1.offsetWidth + "px";
    });
    resizeObserver.observe(
      this.$el.querySelector(".main_table .v-data-table__wrapper table")
    );
    if (
      !(Vue.prototype.$selectCustomer == "All Customers") &&
      !(Vue.prototype.$selectCustomer == undefined)
    ) {
      this.selected_customer = Vue.prototype.$selectCustomer;
    }
  },
  created() {
    this.fetch_customers();

    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
    eventBus.$on("flip_edited_customer", (params) => {
      this.cursor += params;
      if (this.cursor < 0) {
        this.cursor = this.customers.length - 1;
      }
      if (this.cursor > this.customers.length - 1) {
        this.cursor = 0;
      }
      eventBus.$emit("update_edited_customer", this.customers[this.cursor].id);
    });
    eventBus.$on("add_note", (note) => {
      this.add_note(note);
    });
    eventBus.$on("updateCustomers", () => {
      this.fetch_customers();
    });
  },
  watch: {
    expanded() {
      if (this.expanded[0]) {
        this.selected_notes = [];
        this.note_loading = true;
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "customer",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
              this.note_loading = false;
            }
          });
      }
    },
    // customers() {
    //   this.loading = false;
    // },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.link_cell > a {
  display: initial;
}

#top_wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

#scroll_content {
  height: 1px;
  /* overflow: scroll; */
}
</style>