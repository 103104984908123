<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid>
      <v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title>
              <span class="ml-2">Animals</span>
              <v-spacer></v-spacer>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="selected_headers"
                :items="animals"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                :search="search"
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                single-expand
                show-expand
                :expanded.sync="expanded"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                    <v-tab>History</v-tab>
                    <v-tab>Breeding</v-tab>
                    <v-tab>Pedigree</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:expanded-item="{ headers }">
                  <td :colspan="headers.length" class="px-0" v-if="expanded[0]">
                    <v-data-table
                      loading-text="Loading... Please wait"
                      :headers="headers_notes"
                      :items="selected_notes"
                      sort-by="note_datetime"
                      :sort-desc="true"
                      :items-per-page="5"
                      style="border-radius: 0"
                      class="elevation-1 entry_table"
                      hide-default-footer
                      dense
                    >
                      <template
                        v-slot:top="{ pagination, options, updateOptions }"
                      >
                        <div style="width: 100%; text-align: left">
                     

                          <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            class="table_pagination_notes"
                            @update:options="updateOptions"
                            :items-per-page-options="[5]"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.note_datetime`]="{ item }">
                        {{ item.note_datetime.split("T")[0] }}
                      </template>
                      <template v-slot:[`item.author_id`]="{ item }">
                        <span>{{ name_owner_names[item.author_id] }}</span>
                      </template>
                      
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:[`item.customer`]="{ item }">
                 
                    <span v-if="item.customer">{{
                      item.customer.customer_first_name +
                      " " +
                      item.customer.customer_last_name
                    }}</span>
               
             
                </template>
                <template v-slot:[`item.animal_tag_current.name`]="{ item }">
                  <v-chip
                    style="
                      height: 24px;
                      margin-bottom: -2px;
                      margin-top: -3px;
                      padding-top: 2px;
                    "
                    v-if="item.animal_tag_current"
                    :color="
                      item.animal_tag_current.color
                        ? 'oc_' +
                          item.animal_tag_current.color.toLowerCase() +
                          ' darken-1'
                        : 'grey darken-1'
                    "
                  >
                    <span style="color: white; font-weight: 500">{{
                      item.animal_tag_current.name
                    }}</span></v-chip
                  >
                </template>
                <template v-slot:[`item.animal_rfid_current`]="{ item }">
                  {{ item.animal_rfid_current }}
                </template>
                <template
                  v-slot:[`item.dam.animal_tag_current.name`]="{ item }"
                >
                  <v-chip
                    style="
                      height: 24px;
                      margin-bottom: -2px;
                      margin-top: -3px;
                      padding-top: 2px;
                    "
                    v-if="item.dam && item.dam.animal_tag_current"
                    :color="
                      item.dam.animal_tag_current.color
                        ? 'oc_' +
                          item.dam.animal_tag_current.color.toLowerCase() +
                          ' darken-1'
                        : 'grey darken-1'
                    "
                    ><span style="color: white; font-weight: 500">{{
                      item.dam.animal_tag_current.name
                    }}</span></v-chip
                  >
                </template>
                <template
                  v-slot:[`item.sire.animal_tag_current.name`]="{ item }"
                >
                  <v-chip
                    style="
                      height: 24px;
                      margin-bottom: -2px;
                      margin-top: -3px;
                      padding-top: 2px;
                    "
                    v-if="item.sire && item.sire.animal_tag_current"
                    :color="
                      item.sire.animal_tag_current.color
                        ? 'oc_' +
                          item.sire.animal_tag_current.color.toLowerCase() +
                          ' darken-1'
                        : 'grey darken-1'
                    "
                    ><span style="color: white; font-weight: 500">{{
                      item.sire.animal_tag_current.name
                    }}</span></v-chip
                  >
                </template>

                <template v-slot:[`item.animal_sex`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_sex) }}
                </template>

                <template v-slot:[`item.animal_status`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_status) }}
                </template>

                <template v-slot:[`item.animal_last_seen`]="{ item }">
                  <span v-if="item.animal_last_seen">{{
                    item.animal_last_seen.split("T")[0]
                  }}</span>
                  <span else></span>
                </template>

                <template v-slot:[`item.animal_purpose`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_purpose) }}
                </template>
                <template v-slot:[`item.animal_production_type`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_production_type) }}
                </template>
                <template v-slot:[`item.animal_ownership_status`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_ownership_status) }}
                </template>
                <template v-slot:[`item.animal_birth_type`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_birth_type) }}
                </template>
                <template v-slot:[`item.animal_nurse_info`]="{ item }">
                  {{
                    capitalizeFirstLetter(item.animal_nurse_info).replaceAll(
                      "_",
                      " "
                    )
                  }}
                </template>
                <template v-slot:[`item.animal_pregnant`]="{ item }">
                  {{
                    item.animal_sex == "FEMALE"
                      ? capitalizeFirstLetter(item.animal_pregnant)
                      : "-"
                  }}
                </template>
                <template v-slot:[`item.animal_breeding_date`]="{ item }">
                  {{ capitalizeFirstLetter(item.animal_breeding_date) }}
                </template>
                <template v-slot:[`item.animal_dam_status`]="{ item }">
                  {{
                    capitalizeFirstLetter(item.animal_dam_status).replaceAll(
                      "_",
                      " "
                    )
                  }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>

    <!-- <events></events> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
//import events from "@/components/Events.vue";
import sidenav from "@/components/SideNav.vue";
// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    //events,
  },
  props: ["animal_index"],
  data() {
    return {
      ph: [],
      expanded: [],
      search: "",
      staging_data: [],
      production_data: [],
      loading: false,
      customer: "All",
      filter_params: {},
      site: "All",
      video_duration: null,
      video_url: null,
      page: 1,
      sort_by: [],
      sort_desc: true,
      dialog: false,
      delete_dialog: false,
      create_item: {
        animal_tag_current: { current: "YES" },
      },
      form_params: {
        select_species: "CATTLE",
        verified_species: true,
        expand_tag: false,
      },
      purebred: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      selected_table: 0,
      edit_value: null,
      expand_dialog: false,
      selected_animal_id: null,
      selected_animal_index_pos: null,
      selected_animal_index: null,
      editable: false,
      edit_notes: [],
      edit_value_note: null,
      selected_notes: [],
      selected_customer: 'All'
    };
  },
  methods: {
    open_field(value) {
      if (typeof value == "object") {
        this.edit_value = { ...value };
      } else {
        this.edit_value = value;
      }
    },

    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0) + string.slice(1).toLowerCase();
      }
      return "";
    },
    sort_by_fix(sort_by) {
      if (sort_by.length != 0) {
        return sort_by[0];
      }
      return "default";
    },
    editItem(item) {
      if (item == "create_new") {
        this.create_item = {
          animal_tag_current: { current: "YES" },
        };
        this.dialog = true;
        this.create_new = true;
      } else {
        this.editedIndex = this.animals.indexOf(item);
        this.create_item = Object.assign({}, item);
        this.dialog = true;
      }
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.create_item = {
          animal_tag_current: { current: "YES" },
        };
        this.form_params = {
          select_species: "CATTLE",
          verified_species: true,
          expand_tag: false,
        };
        this.error = "";
        this.purebred = false;
      });
    },
  },
  computed: {
    create_ready() {
      if (
        this.create_item.animal_tag_current.name &&
        this.create_item.animal_dob &&
        this.create_item.animal_sex &&
        this.create_item.animal_rfid_current
      ) {
        return true;
      }
      return false;
    },
    selected_headers() {
      if (this.selected_table == 0) {
        return this.headers_general;
      } else if (this.selected_table == 3) {
        return this.headers_pedigree;
      } else if (this.selected_table == 1) {
        return this.headers_history;
      } else {
        return this.headers_breeding;
      }
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    animals() {
      return this.$store.getters.getAnimals;
    },
    headers_general() {
      return [
        {
          text: "Customer",
          value: "customer",
          
          filter: (value) => {
            if (this.selected_customer == "All") return true;
            return (
                value.id==
                this.selected_customer
              );
          },
          sort: (a, b) => {
            if (a.customer_first_name < b.customer_first_name) {
              return -1;
            }
            if (a.customer_first_name > b.customer_first_name) {
              return 1;
            }
            return 0;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },
        {
          text: "Tag",
          value: "animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "animal_rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },
        {
          text: "Sex",
          value: "animal_sex",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Birthday",
          value: "animal_dob",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        //  {
        //   text: "Group",
        //   value: "animal_group",
        //   cellClass: this.editable ? "non-hl" : "no_edit",
        // },
        {
          text: "Status",
          value: "animal_status",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Colour",
          value: "animal_color",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Dam",
          value: "dam.animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "Sire",
          value: "sire.animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        { text: "", value: "data-table-expand" },
        // {
        //   text: "Name",
        //   value: "animal_name",
        //   cellClass: this.editable ? "non-hl" : "no_edit",
        // },
      ];
    },
    headers_pedigree() {
      return [
        {
          text: "Tag",
          value: "animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "animal_rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },
        {
          text: "Tattoo",
          value: "animal_pedigree.tattoo",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. Name",
          value: "animal_pedigree.registered_name",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. No.",
          value: "animal_pedigree.registration_number",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. Association",
          value: "animal_pedigree.registration_association",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Reg. Date",
          value: "animal_pedigree.registration_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_history() {
      return [
        {
          text: "Tag",
          value: "animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "animal_rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },

        {
          text: "Date of Arrival",
          value: "animal_site_doa",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        // { text: "Last Seen", value: "animal_last_seen", width: "180px" },
        {
          text: "Purpose",
          value: "animal_purpose",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Production Type",
          value: "animal_production_type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Ownership",
          value: "animal_ownership_status",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Birth",
          value: "animal_birth_type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Nursing",
          value: "animal_nurse_info",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_breeding() {
      return [
        {
          text: "Tag",
          value: "animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "RFID",
          value: "animal_rfid_current",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "180px",
        },

        {
          text: "Dam",
          value: "dam.animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "Sire",
          value: "sire.animal_tag_current.name",
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "120px",
        },
        {
          text: "Pregnant",
          value: "animal_pregnant",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Breeding Date",
          value: "animal_breeding_date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "Dam Status",
          value: "animal_dam_status",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headers_notes() {
      return [
        {
          text: "Date",
          value: "note_datetime",
          width: "120px",
        },
        {
          text: "Author",
          value: "author.customer_first_name",
          width: "220px",
        },
        {
          text: "Note",
          value: "note_text",
          cellClass: "non-hl",
        },
      ];
    },
  },

  created() {
    // eventBus.$on("show_all_videos", () => {
    //   this.show_one_video = false;
    // });
if (this.$store.getters.getCustomerNames.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getAnimals.length == 0) {
      this.$store.dispatch("DDB_GET_ANIMALS");
    }
    
  },
  watch: {
    expanded() {
      if (this.expanded[0]) {
        this.$store
          .dispatch("DDB_GET_SELECTED_NOTES", {
            id: this.expanded[0].id,
            type: "wifi",
          })
          .then((res) => {
            if (res || res == undefined) {
              this.selected_notes = this.$store.getters.getSelectedNotes;
            }
          });
      }
    },
    expand_dialog() {
      if (!this.expand_dialog) {
        this.selected_animal_id = null;
        this.selected_animal_index_pos = null;
        this.selected_animal_index = null;
      }
    },
    purebred() {
      if (this.purebred) {
        this.create_item.animal_pedigree = {};
      } else {
        delete this.create_item.animal_pedigree;
      }
    },
    animals() {
      this.loading = false;
    },

    dialog() {
      if (this.dialog == false) {
        this.create_new = false;
        this.form_params = {
          select_species: "CATTLE",
          verified_species: true,
          expand_tag: false,
        };
        this.error = "";
        this.purebred = false;
      }
    },
  },
};
</script>
<style lang="css">
.purebred_true {
  background-color: rgba(104, 104, 104, 0.1);
}
.an_err {
  bottom: 10px;
  position: absolute;
  right: 10px;
}
</style>


