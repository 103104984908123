/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateTask = /* GraphQL */ `
  mutation UpdateTaskInput(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteTask = /* GraphQL */ `
  mutation DeleteTaskInput(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCorrespondenceCommunication = /* GraphQL */ `
  mutation DeleteCorrespondenceCommunication(
    $input: DeleteCorrespondenceCommunicationInput!
    $condition: ModelCorrespondenceCommunicationConditionInput
  ) {
    deleteCorrespondenceCommunication(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteProjectCorrespondence = /* GraphQL */ `
  mutation DeleteProjectCorrespondence(
    $input: DeleteProjectCorrespondenceInput!
    $condition: ModelProjectCorrespondenceConditionInput
  ) {
    deleteProjectCorrespondence(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteProjectContact = /* GraphQL */ `
  mutation DeleteProjectContact(
    $input: DeleteProjectContactInput!
    $condition: ModelProjectContactConditionInput
  ) {
    deleteProjectContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createProjectContact = /* GraphQL */ `
  mutation createProjectContact(
    $input: CreateProjectContactInput!
    $condition: ModelProjectContactConditionInput
  ) {
    createProjectContact(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation createTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createProjectCorrespondence = /* GraphQL */ `
  mutation CreateProjectCorrespondence(
    $input: CreateProjectCorrespondenceInput!
    $condition: ModelProjectCorrespondenceConditionInput
  ) {
      createProjectCorrespondence(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCorrespondence = /* GraphQL */ `
  mutation DeleteCorrespondence(
    $input: DeleteCorrespondenceInput!
    $condition: ModelCorrespondenceConditionInput
  ) {
    deleteCorrespondence(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id

    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createJetson = /* GraphQL */ `
  mutation CreateJetson(
    $input: CreateJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    createJetson(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateJetson = /* GraphQL */ `
  mutation UpdateJetson(
    $input: UpdateJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    updateJetson(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteJetson = /* GraphQL */ `
  mutation DeleteJetson(
    $input: DeleteJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    deleteJetson(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createWifi = /* GraphQL */ `
  mutation CreateWifi(
    $input: CreateWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    createWifi(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateWifi = /* GraphQL */ `
  mutation UpdateWifi(
    $input: UpdateWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    updateWifi(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation updateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      contact_company_name
      contact_family_name
      contact_given_name
      contact_home_phone
      contact_mobile_phone
      contact_personal_email
      contact_source
      contact_type
      contact_work_email
      contact_work_email_domain
      contact_work_phone
      id
      contact_notes
      owner
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation createContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      contact_company_name
      contact_family_name
      contact_given_name
      contact_home_phone
      contact_mobile_phone
      contact_personal_email
      contact_source
      contact_type
      contact_work_email
      contact_work_email_domain
      contact_work_phone
      contact_notes
      id
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation updateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      project_start_date
      project_staff
      project_name
      project_end_date
      project_description
      id
      owner
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      project_start_date
      project_staff
      project_name
      project_end_date
      project_description
      id
      owner
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteWifi = /* GraphQL */ `
  mutation DeleteWifi(
    $input: DeleteWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    deleteWifi(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteFeedback = /* GraphQL */ `
  mutation deleteFeedback(
    $input: DeleteFeedbackInput!, 
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createCamera = /* GraphQL */ `
  mutation CreateCamera(
    $input: CreateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    createCamera(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera(
    $input: UpdateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    updateCamera(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteCamera = /* GraphQL */ `
  mutation DeleteCamera(
    $input: DeleteCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    deleteCamera(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      camera_id
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      camera_id
      video_datetime
      owner
      video_duration
      video_size
      video_thumbnail
      createdAt
      updatedAt
      camera {
        id
        owner
        customer_id
        site_id
        camera_location {
          latitude
          longitude
        }
        camera_ip
        camera_name
        camera_nickname
        camera_status
        camera_last_video_upload
        camera_last_status_update
        camera_imei
        camera_mac
        camera_type
        camera_cellular_enabled
        camera_ftp_enabled
        camera_reolinkcloud_enabled
        camera_manufacturer
        camera_sd_card_size_gb
        camera_clear_bitrate_kbps
        camera_clear_resolution
        camera_fluent_bitrate_kbps
        camera_fluent_resolution
        
        createdAt
        updatedAt
        customer {
          id
          owner
          
          customer_mailing_address
          createdAt
          updatedAt
        }
        site {
          id
          owner
          customer_id
          site_name
          site_operation_type
          site_area
          site_feeding_station_count
          site_mineral_station_count
          site_water_hole_count
          site_out_building_count
          site_pen_count
          site_natural_water_source_count
          site_rfid_reader_count
          site_weight_scale_count
          site_community_pasture
          site_community_pasture_names
          site_internet_provider
          site_public_ip
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateLivestock = /* GraphQL */ `
  mutation UpdateLivestock(
    $input: UpdateLivestockInput!
    $condition: ModelLivestockConditionInput
  ) {
    updateLivestock(input: $input, condition: $condition) {
      id
      owner
      customer_id
      site_id
      livestock_drop_tag
      livestock_rfid_tag
      livestock_dob
      livestock_farm_doa
      livestock_head_img
      livestock_body_img
      livestock_weight {
        measurement
        unit
        date
      }
      livestock_species
      livestock_breed {
        name
        calving_dates {
          start
          end
        }
        breeding_dates {
          start
          end
        }
        branding_dates {
          start
          end
        }
        weaning_dates {
          start
          end
        }
      }
      livestock_gender
      livestock_pregnant
      livestock_status
      livestock_herd
      livestock_dam
      livestock_sire
      livestock_calving_date
      livestock_notes
      createdAt
      updatedAt
    }
  }
`;
