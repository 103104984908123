<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Feedback</span>
              <v-spacer></v-spacer>
              <!-- <v-switch
                class="mr-6 mb-0"
                v-model="editable"
                label="Edit"
                color="primary"
                value="Edit"
                inset
                hide-details
              ></v-switch> -->
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mb-n5 mr-4"
                    @click="editItem('create_new')"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Note</span>
              </v-tooltip> -->
              <v-btn class="mb-n5 mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn>
              <div style="width: 180px" class="pr-3">
                <v-select
                  :items="[
                    { text: 'None', value: null },
                    { text: 'Customer', value: 'customer.customer_last_name' },
                    { text: 'Site', value: 'site.site_name' },
                    { text: 'Type', value: 'typeName' },
                  ]"
                  v-model="group_by"
                  flat
                  hide-details
                ></v-select>
              </div>
              <div class="table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <CustomerFilter v-model="selected_customer"></CustomerFilter>
            </v-card-title>
            <v-card-text v-if="selected_table == 0" class="text-center">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="feedbacks"
                :items-per-page="100"
                class="elevation-1 entry_table"
                :multi-sort="multi_sort"
                :group-by="group_by"
                :search="search"
                hide-default-footer
                :sort-by="['createdAt']"
                :sort-desc="[true]"
                single-expand
                show-expand
              >
              <template v-slot:[`item.createdAt`]="{ item }">
                  <span>{{ item.createdAt.split("T")[0] }}</span>
                </template>
              <template v-slot:[`item.data-table-expand`]="{item}">
                  <v-icon @click="deleteEntry(item)" dense class="mr-2">mdi-delete</v-icon>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden">
                    <!-- <td
                      v-if="group_by != 'customer.customer_last_name'"
                      class="select_width align_left"
                    >
                      <v-autocomplete
                        class="mt-n1"
                        :items="customers"
                        v-model="selected_customer"
                        v-if="customers"
                        dense
                        flat
                        hide-details
                        :menu-props="{ offsetOverflow: false }"
                      ></v-autocomplete>
                    </td> -->
                    <td />
                    <td
                      v-if="group_by != 'typeName'"
                      class="select_width align_left"
                    >
                      <v-autocomplete
                        class="mt-n1"
                        :items="[
                          'All',
                          'ALERT',
                          'NOTICE',
                          'WARNING'
                        ]"
                        v-model="selected_type"
                        dense
                        flat
                        hide-details
                        :menu-props="{ offsetOverflow: false }"
                      ></v-autocomplete>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>General</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.customer`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'customer_id')"
                    @cancel="cancel_edit"
                    @open="open_field(item.customer_id)"
                    @close="close_edit"
                    :return-value.sync="item.customer_id"
                  >
                    <span v-if="item.customer">{{
                      item.customer.customer_first_name +
                      " " +
                      item.customer.customer_last_name
                    }}</span>
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="edit_value"
                        :items="owner_customer_names"
                        label="Customer"
                        dense
                        outlined
                        class="pt-2"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.site`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'site_id')"
                    @cancel="cancel_edit"
                    @open="open_field(item.site_id)"
                    @close="close_edit"
                    :return-value.sync="item.site_id"
                  >
                    <span v-if="item.site">{{ item.site.site_name }}</span>
                    <span v-else>-</span>
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="edit_value"
                        :items="site_names"
                        label="Site"
                        dense
                        outlined
                        class="pt-2"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.note_datetime`]="{ item }">
                  <span>{{ item.note_datetime.split("T")[0] }}</span>
                </template>
                <template v-slot:[`item.note_text`]="{ item }">
                  <v-edit-dialog
                    @save="save_record(item, 'note_text')"
                    @cancel="cancel_edit"
                    @open="open_field(item.note_text)"
                    @close="close_edit"
                    :return-value.sync="item.note_text"
                  >
                    {{ item.note_text }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="edit_value"
                        label="Text"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-card-text> </v-card
        ></v-col>
      </v-row>
      <!-- <v-dialog v-model="dialog" max-width="800px" min-width="760px">
        <v-card>
          <v-card-title>
            <span class="text-h5 ml-3" v-if="create_new">Create WIFI</span>
            <span class="text-h5 ml-3" v-else>Edit WIFI</span>
          </v-card-title>

          <v-card-text>
            <v-container> </v-container>
          </v-card-text>

          <v-card-actions>
            <span class="error_span">{{ error }}</span>
            <v-spacer></v-spacer>
            <v-icon
              v-if="!create_new"
              color="primary"
              @click="delete_dialog = true"
              >mdi-delete</v-icon
            >
            <v-btn color="primary" text @click="close"> Cancel </v-btn>
            <v-btn color="primary" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <v-dialog v-model="delete_dialog" max-width="260px" min-width="260px" persistent no-click-animation @click:outside="close_delete">
        <v-card :loading="delete_loading">
          <v-card-title>Delete Feedback?</v-card-title>
          <v-card-actions>
            <v-btn color="primary" text @click="close_delete" :disabled="delete_loading">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteItem(entry_to_be_deleted)"
              :disabled="delete_loading"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import Vue from 'vue';
// import moment from "moment";
import CustomerFilter from "../components/CustomerFilter.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerFilter
  },
  props: ["filter"],
  data() {
    return {
      rules: {
        number: (v) =>
          /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$||null$/.test(v) ||
          "Enter a valid number",
        ip: (v) =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$||null$/.test(v) ||
          "Enter a valid IP address",
        mac: (v) =>
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$||null$/.test(v) ||
          "Enter a valid MAC address",
      },

      search: "",
      multi_sort: false,
      group_by: null,
      staging_production: true,
      loading: true,
      editedItem: { wifi_location: {} },
      dialog: false,
      error: "",
      editedIndex: 0,
      create_new: false,
      owner_name: "",
      site_name: "",
      selected_table: 0,
      sort_by: ["updatedAt"],
      sort_desc: [true],
      delete_dialog: false,
      delete_loading: false,
      status: "All",
      shipped: "All",
      edit_value: null,
      expanded: [],
      edit_value_note: null,
      selected_feedbacks: [],
      editable: false,
      selected_customer: "All Customers",
      selected_site: "All",
      selected_type: "All",

      entry_to_be_deleted: null,
    };
  },
  methods: {
    close_edit() {
      //this.edit_value = null;
    },
    async save_record(record, field) {
      var edited_record = {};
      if (field.indexOf(".") !== -1) {
        const sub_fields = field.split(".");
        if (record[sub_fields[0]]) {
          edited_record[sub_fields[0]] = record[sub_fields[0]];
          edited_record[sub_fields[0]][sub_fields[1]] = this.edit_value;
        } else {
          return false;
        }
      } else {
        edited_record[field] = this.edit_value;
      }
      edited_record.id = record.id;
      try {
        await API.graphql({
          query: mutations.updateNote,
          variables: {
            input: edited_record,
          },
        });
        this.edit_value = null;
        this.$store.dispatch("DDB_GET_NOTES");
      } catch (err) {
        console.log(err);
      }
    },
    cancel_edit() {
      this.edit_value = null;
    },

    open_field(value) {
      console.log(value);
      this.edit_value = value;
    },
    deleteEntry(item){
      this.delete_dialog = true;
      this.entry_to_be_deleted = item.id;
    },
    async deleteItem(id) {
      this.delete_loading=true;
      await API.graphql({
        query: mutations.deleteFeedback,
        variables: {
          input: { id: id},
        },
      });
      this.$nextTick(() => {
        this.$store.dispatch("DDB_GET_FEEDBACKS").then(()=>{
          this.delete_dialog = false;
          this.delete_loading = false;
        });
      });
    },
    editItem(item) {
      if (item == "create_new") {
        this.editedItem = { wifi_location: {} };
        this.dialog = true;
        this.create_new = true;
      } else {
        this.editedIndex = this.feedbacks.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.owner_name = this.editedItem.owner;
        this.site_name =
          this.$store.getters.getSiteNameObject[this.editedItem.site.id];
        this.dialog = true;
      }
    },
    close_delete() {
      if(!this.delete_loading){
        this.delete_dialog = false;
      }
    },

    owner_filter() {
      if (this.filter != "all") {
        this.selected_customer = this.filter;
      }
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (x == null) {
          x = "zzz";
        }
        if (y == null) {
          y = "zzz";
        }
        if (key == "camera_latest_upload_source") {
          if (x == y) {
            return a.camera_name.localeCompare(b.camera_name);
          }
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Customer Name",
          value: "customer",
          filter: (value) => {
            if (this.selected_customer == "All Customers") return true;
            else if (value == null) return false;
            return value.id == this.selected_customer;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "250px",
        },

        {
          text: "Type",
          value: "feedback_type",
          filter: (value) => {
            if (this.selected_type == "All") return true;
            return value == this.selected_type;
          },
          cellClass: this.editable ? "non-hl" : "no_edit",
          width: "150px",
        },
        {
          text: "Created At",
          value: "createdAt",
          width: "150px",
        },
        {
          text: "Text",
          value: "feedback_body",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {text: "", value: "data-table-expand"}
      ];
    },
    site_names() {
      var site_names = [];
      for (const i in this.$store.getters.getSiteObjects) {
        site_names.push({
          text: this.$store.getters.getSiteObjects[i].site_name,
          value: this.$store.getters.getSiteObjects[i].id,
        });
      }
      return site_names;
    },
    sites() {
      var site_names = [];
      for (const i in this.$store.getters.getSiteObjects) {
        site_names.push({
          text: this.$store.getters.getSiteObjects[i].site_name,
          value: this.$store.getters.getSiteObjects[i].id,
        });
      }
      site_names = this.sortByKey([...site_names], "text");
      site_names.unshift({ text: "All", value: "All" });
      return site_names;
    },
    customers() {
      var customer_names = [];
      for (const i in this.$store.getters.getCustomerObjects) {
        customer_names.push({
          text:
            this.$store.getters.getCustomerObjects[i].customer_first_name +
            this.$store.getters.getCustomerObjects[i].customer_last_name,
          value: this.$store.getters.getCustomerObjects[i].id,
        });
      }
      customer_names = this.sortByKey([...customer_names], "text");
      customer_names.unshift({ text: "All", value: "All" });
      return customer_names;
    },

    feedbacks() {
      return this.$store.getters.getFeedbacks;
    },
    owner_customer_names() {
      return this.$store.getters.getOwnerNameObject[0];
    },
  },
    mounted() {
    if (!(Vue.prototype.$selectCustomer == 'All Customers') && !(Vue.prototype.$selectCustomer == undefined)) {
      this.selected_customer = Vue.prototype.$selectCustomer
    }
  },
  created() {
    if (this.$store.getters.getCustomerObjects.length == 0) {
      this.$store.dispatch("DDB_GET_CUSTOMERS");
    }
    if (this.$store.getters.getFeedbacks.length == 0) {
      this.$store.dispatch("DDB_GET_FEEDBACKS");
    } else {
      this.loading = false;
    }

    if (this.$store.getters.getSiteNames.length == 0) {
      this.$store.dispatch("DDB_GET_SITES");
    }
    if (this.$store.getters.getCognitoUsers.length == 0) {
      this.$store.dispatch("DDB_GET_COGNITO_USERS");
    }
  },
  watch: {
    feedbacks() {
      this.loading = false;
    },
    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>
<style lang="css">
</style>