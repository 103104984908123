<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container v-if="loading">
      <v-progress-circular
        indeterminate
        :size="150"
        color="primary"
      ></v-progress-circular>
    </v-container>
    <v-container v-else
      ><v-card>
        <v-card-title>
          <v-row>
            <v-col id="video_card_title" class="pr-0 pb-0">Videos</v-col>
            <v-col xl="3" class="pb-0">
              <!-- <v-autocomplete
                  :items="customer_objects"
                  :menu-props="{ offsetOverflow: false }"
                  v-model="selected_customer"
                  filled
                  label="all customers"
                  chips
                  solo
                  dense
                  small-chips
                  deletable-chips
                  :full-width="true"
                  :allow-overflow="false"
                ></v-autocomplete> -->
              <CustomerFilter
                v-model="selected_customer"
                :dense="true"
                :alt="true"
                :disabled="dialog"
              ></CustomerFilter>
            </v-col>
            <v-col xl="2" class="pb-0">
              <div class="filter_ac">
                <CameraAutoComplete
                  :form="true"
                  :all="true"
                  v-model="selected_cameras"
                  :owner="selected_customer"
                ></CameraAutoComplete>
              </div>
            </v-col>
            <v-col xl="1" class="pb-0">
              <div>
                <v-autocomplete
                  v-model="selected_model"
                  :items="camera_models"
                  filled
                  solo
                  full-width
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col class="pb-0" v-if="selected_customer || selected_cameras">
              <div id="vid_filter_btns">
                <v-menu
                  v-model="datetime_menu"
                  :close-on-content-click="false"
                  open-on-hover
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      height="40"
                      v-bind="attrs"
                      v-on="on"
                      class="filter_button"
                    >
                      <span v-if="!selected_dates.length">all dates</span>

                      <span v-if="selected_dates.length == 1">{{
                        selected_dates[0]
                          .split(" ")[0]
                          .replace("-", ":")
                          .split(":")[1]
                          .replaceAll("-", "/")
                      }}</span>
                      <span v-if="selected_dates.length == 2">{{
                        selected_dates[0]
                          .split(" ")[0]
                          .replace("-", ":")
                          .split(":")[1]
                          .replaceAll("-", "/") +
                        " - " +
                        selected_dates[1]
                          .split(" ")[0]
                          .replace("-", ":")
                          .split(":")[1]
                          .replaceAll("-", "/")
                      }}</span>
                    </v-btn>
                  </template>

                  <v-card height="380" width="280">
                    <v-row>
                      <v-col>
                        <v-date-picker
                          v-model="date"
                          flat
                          range
                          no-title
                          id="video_dp"
                          event-color="#ff9900"
                          :max="todays_date"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                    <v-row v-if="show_timebar">
                      <v-btn
                        class="filter_button"
                        color="primary"
                        id="saveButton"
                        @click="saveDTFilterMenu"
                      >
                        Save
                      </v-btn>
                      <v-icon
                        v-if="selected_dates.length"
                        @click="reset_dates"
                        class="ml-4 mt-n2"
                        >mdi-filter-off-outline</v-icon
                      >
                    </v-row>
                  </v-card>
                </v-menu>
                <v-btn
                  height="40"
                  @click="update_sort_order"
                  class="ml-2 filter_button"
                >
                  <v-icon v-if="sort_order == 'DESC'"
                    >mdi-sort-calendar-ascending</v-icon
                  >
                  <v-icon v-else>mdi-sort-calendar-descending</v-icon>
                </v-btn>
                <!-- <v-btn
                  height="40"
                  @click="selected_bookmark = !selected_bookmark"
                  class="ml-2 filter_button"
                >
                  <v-icon v-if="!selected_bookmark" color="primary"
                    >mdi-star-outline</v-icon
                  >
                  <v-icon v-else color="primary">mdi-star</v-icon>
                </v-btn> -->
              </div>
            </v-col>

            <v-col class="pb-0">
              <div id="vid_pagination" v-if="videos[0]">
                <div id="vid_pagination_settings">
                  <v-select
                    class="per_page_picker"
                    :items="[96, 144, 192]"
                    :menu-props="{ offsetY: true }"
                    hide-details="true"
                    v-model="perPage"
                    dense
                  ></v-select>
                  <div class="ml-6 pagination_arrows" v-if="videos[0]">
                    <v-icon v-if="page > 1" @click="paginate(false)"
                      >mdi-chevron-left</v-icon
                    >
                    <v-icon class="disabled_page_arrow" v-else
                      >mdi-chevron-left</v-icon
                    >
                    <span id="page_number">{{ page }}</span>
                    <v-icon
                      v-if="videos[0].length - perPage * page >= 0"
                      @click="paginate(true)"
                      class=""
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon class="disabled_page_arrow" v-else
                      >mdi-chevron-right</v-icon
                    >
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 pl-0 mt-n3"
              ><v-col class="pa-0"
                ><span v-if="videos[0]" id="time_nav">{{
                  time_navigation()
                }}</span></v-col
              ></v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-row
            class="ma-auto"
            justify="center"
            v-if="!loading && !show_one_video && videos[0]"
          >
            <div
              v-for="(i, index) in videos[0].slice(
                (page - 1) * perPage,
                perPage * page
              )"
              class=""
              :key="index"
            >
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: 0.5,
                }"
                min-height="250"
                transition="fade-transition"
                ><v-hover v-slot:default="{ hover }">
                  <v-card
                    @click="select_video(index + (page - 1) * perPage)"
                    elevation="4"
                    rounded="false"
                    light
                    class="video_tn_card"
                    min-width="340"
                    max-width="340"
                  >
                  <video-thumbnail :height="200" v-model="i.video_path"></video-thumbnail>
                    <!-- <v-img
                      height="200"
                      :src="`${(cookie_bucket + i.video_path + '_480x270.jpg')}`"
                      
                      @error="onImageError(i)"
                    /> -->
                    <!-- <v-img
                      v-if="betsy_bool"
                      height="200"
                      :src="`${
                        cookie_bucket +
                        get_url(i.video_path, i.camera_id) +
                        '_480x270.jpg'
                      }`"
                    /> -->
                    <v-card-title class="video_card_title">
                      <div class="video_title_line_1">
                        <span>{{
                          get_duration(
                            i.video_datetime.split("T")[1].slice(0, 5)
                          ) +
                          " / " +
                          get_duration_local(
                            i.video_datetime.split("T")[1].slice(0, 5),
                            i.video_timezone
                          )
                        }}</span>
                        <span>{{ weekday(i.video_datetime) }}</span>
                      </div>
                      <div class="video_title_line_2 mt-1">
                        <span>
                          <span :class="date_color(i.video_datetime)">
                            {{ i.video_datetime.split("T")[0] }}
                          </span>
                          <span
                            >{{ " (" + date_fromNow(i.video_datetime) + ")" }}
                          </span>
                        </span>
                        <span
                          :class="{
                            red_color:
                              i.fps != 30 &&
                              i.fps != 25 &&
                              i.fps != 12,
                          }"
                        >
                          {{ i.fps + "FPS" }}
                        </span>
                        <span>
                          {{ i.video_size }}
                        </span>
                      </div>
                      <!-- <div>
                        <span class="card_video_time">{{
                          get_duration(
                            i.video_datetime.split("T")[1].slice(0, 5)
                          )+' / '+get_duration_local(i.video_datetime.split("T")[1].slice(0, 5), i.video_timezone)
                        }}</span>
                        <span class="card_dayofweek">{{
                          weekday(i.video_datetime)
                        }}</span>
                        <br /><span class="card_video_date">
                          {{ i.video_datetime.split("T")[0] +" "+ i.video_fps+ "fps" }}
                        </span>
                        <span class="card_video_size">
                          {{ i.video_size }}
                          <JSONIndicator :url="i.video_path"></JSONIndicator>
                        </span>
                      </div> -->
                    </v-card-title>
                    <div class="playWrapper">
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          :z-index="2"
                          class="v_overlay"
                        />
                      </v-fade-transition>
                      <v-overlay
                        :z-index="3"
                        value="true"
                        absolute
                        class="v_duration_overlay"
                        opacity="0"
                      >
                        <div class="card_overlay_line_0">
                          <JSONIndicator
                            class="mr-1"
                            :url="i.video_path"
                          ></JSONIndicator>
                        </div>
                        <div class="card_overlay_line_1">
                          <v-chip v-if="i.camera" color="rgba(0, 0, 0, 0.4)">
                            {{ i.camera.camera_model }}
                          </v-chip>
                          <v-chip v-else color="rgba(0, 0, 0, 0.4)">
                            NaN
                          </v-chip>
                        </div>
                        <div class="card_overlay_line_2">
                          <v-chip v-if="i.customer" color="rgba(0, 0, 0, 0.4)">
                            {{
                              formulateNames(
                                i.customer.customer_first_name,
                                i.customer.customer_last_name
                              )
                            }}
                          </v-chip>
                          <v-chip v-else color="rgba(0, 0, 0, 0.4)">
                            NaN
                          </v-chip>
                          <v-chip v-if="i.camera" color="rgba(0, 0, 0, 0.4)">
                            {{ i.camera.id }}
                          </v-chip>
                          <v-chip v-else color="rgba(0, 0, 0, 0.4)">
                            NaN
                          </v-chip>
                        </div>
                        <div class="card_overlay_line_3">
                          <v-chip
                            v-if="i.camera"
                            color="rgba(0, 0, 0, 0.4)"
                            class="card_camera_name"
                          >
                            {{ i.camera.camera_name }}
                          </v-chip>
                          <v-chip
                            v-else
                            color="rgba(0, 0, 0, 0.4)"
                            class="card_camera_name"
                          >
                            NaN
                          </v-chip>
                          <v-chip
                            color="rgba(0, 0, 0, 0.4)"
                            class="card_video_duration"
                          >
                            <span
                              v-if="i.video_duration.split(':').length > 2"
                              >{{
                                i.video_duration.split(":")[0] +
                                ":" +
                                i.video_duration.split(":")[1]
                              }}</span
                            >
                            <span v-else>{{ i.video_duration }}</span>
                          </v-chip>
                        </div>
                      </v-overlay>

                      <v-overlay
                        :z-index="4"
                        value="true"
                        absolute
                        class="v_duration_overlay"
                        opacity="0"
                      >
                        <a
                          :href="
                            hls_s3 + i.video_path.replaceAll('+', '/') + '.m3u8'
                          "
                          @click="
                            (e) => {
                              e.stopPropagation();
                            }
                          "
                        >
                          <v-icon class="download_icon"
                            >mdi-link-variant</v-icon
                          >
                        </a>
                      </v-overlay>
                    </div>
                  </v-card>
                </v-hover>
              </v-lazy>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="dialog"
        :max-width="is_json ? `1400px` : '1000px'"
        min-width="450px"
        v-if="videos[0] && selected_index != null"
      >
        <v-card>
          <v-card-title v-if="videos[0][selected_index]">
            <div id="full_size_ct_div">
              <span
                ><b class="footage_link" v-if="videos[0][selected_index].camera_id"
                  >OneCup Ranching</b
                ></span
              >
              <span
                ><b
                  class="footage_link"
                  v-if="videos[0][selected_index].camera"
                  >{{ videos[0][selected_index].camera.camera_name }}</b
                >
              
                <b
                  class="footage_link"
                  v-if="videos[0][selected_index].camera_id"
                  >{{ videos[0][selected_index].camera_id }}</b
                ></span
              >
              <span class="nav_arrows_f">
                <!-- <a
                  :href="
                    cookie_bucket +
                    videos[0][selected_index].video_path +
                    '.mp4'
                  "
                >
                  <v-icon class="pr-1">mdi-download</v-icon>
                </a> -->
                <v-tooltip color="black" bottom v-if="json_file_not_present">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-switch
                        v-model="is_json"
                        label="BETSY"
                        hide-details
                        inset
                        :disabled="json_file_not_present"
                        class="mt-1"
                      >
                      </v-switch>
                    </div>
                  </template>
                  <div>
                    BETSY Vision is currently unavailable for this video. Please
                    try again later.
                  </div>
                </v-tooltip>
                <v-switch
                  v-if="!json_file_not_present"
                  v-model="is_json"
                  label="BETSY"
                  hide-details
                  inset
                  :disabled="!json_loading"
                  :loading="json_loading ? 'false' : 'primary'"
                  class="mt-1"
                >
                </v-switch>
                <span class="filter_heading">
                  <v-icon
                    large
                    class="mt-n1"
                    @click="select_video(selected_index - 1)"
                    v-if="selected_index > 0"
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon large class="mt-n1" v-else disabled
                    >mdi-chevron-left</v-icon
                  >
                  <v-icon
                    large
                    class="mt-n1"
                    @click="select_video(selected_index + 1)"
                    v-if="selected_index < videos[0].length - 1"
                    >mdi-chevron-right</v-icon
                  >
                  <v-icon large class="mt-n1" v-else disabled
                    >mdi-chevron-right</v-icon
                  ></span
                >
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <video-player
              preload
              class="vjs-big-play-centered"
              :is_json="is_json"
              :json_file_not_present="json_file_not_present"
              :videoFps="videoFps"
              :camera_model="camera_model"
              :portal="portal"
              :json_loading="json_loading"
              :customerFirstName="customerFirstName"
              :customerLastName="customerLastName"
              :navigation_button_change="navigation_button_change"
              :videoPlayerDialog="dialog"
              :timeStamp="timeStamp"
              @update-loading="updateJsonLoading"
              :json_url="json_file_url"
              :options="{
                autoplay: true,
                controls: false,
                fluid: true,
              }"
            />
          </v-card-text>
        </v-card> </v-dialog
    ></v-container>
  </div>

  <!-- <v-col cols="12" sm="6" md="3">
          <v-btn @click="changePage" color="primary" rounded>go</v-btn>
        </v-col> -->
</template>

<script>
// import JSZip from "jszip";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
import videojs from "video.js";
import moment from "moment-timezone";
import { eventBus } from "../main.js";
import * as mutations from "../graphql/mutations.js";
import { API } from "aws-amplify";
import CustomerFilter from "../components/CustomerFilterHasura.vue";
import CameraAutoComplete from "../components/CameraAutoCompleteHasura.vue";
import Vue from "vue";
import JSONIndicator from "../components/JSONIndicator.vue";
import VideoThumbnail from "../components/VideoThumbnail.vue";

// @vuese
// @group Views
export default {
  name: "videos",
  components: {
    navbar,
    sidenav,
    VideoPlayer,
    CustomerFilter,
    CameraAutoComplete,
    JSONIndicator,
    VideoThumbnail,
  },

  props: ["filter"],
  data: () => ({
    indent: false,
    isActive: false,
    timeStamp: "",
    objects: [],
    hours: [0, 24],
    datetime_menu: false,
    show_timebar: false,
    nudge_width_menu: 0,
    mini_date_menu: false,
    date: [],
    page: 1,
    perPage: 96,
    pageNumber: null,
    loading: true,
    thumbnails: [],
    show_one_video: false,
    video_url: null,
    video_duration: null,
    todays_date: moment().format("YYYY-MM-DD"),
    betsy_bool: false,
    previous_tokens: [],
    selected_cameras: null,
    selected_customer: null,
    selected_dates: [],
    selected_bookmark: false,
    selected_model: null,
    dialog: false,
    selected_index: null,
    sort_order: "DESC",
    is_json: false,
    json_data: "",
    json_file_not_present: false,
    json_loading: false,
    videoFps: 0,
    portal: "admin",
    customerFirstName: "",
    customerLastName: "",
    navigation_button_change: false,
    camera_model: "",
    json_file_url: "",

    image_update_key: 0,
  }),

  methods: {
    date_fromNow(date) {
      return moment(date).fromNow();
    },
    updateJsonLoading(newValue) {
      this.json_loading = newValue;
    },
    date_color(date) {
      var a = moment(date);
      var b = moment();
      // more than a week, return red
      var day_diff = b.diff(a, "days");
      if (day_diff >= 7) return "red_color";
      // more than 24 hours, return orange
      var hour_diff = b.diff(a, "hours");
      if (hour_diff >= 24) return "orange_color";
    },
    formulateNames(first_name, last_name) {
      return (
        (first_name ? first_name + " " : "") +
        (first_name == last_name ? "" : last_name)
      );
    },
    onImageError(i) {
      i.thumbnail_error = true;
      this.image_update_key += 1;
      console.log("ERROR spotted");
    },
    async checkJSONAvailability(url) {
      var json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";
      await fetch(json_file_source, { method: "HEAD" })
        .then((response) => {
          if (response.status === 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.error("Error checking file existence:", error);
        });
    },
    // async fetchJSON() {
    //   console.log("fetching json");
    //   const url = this.videos[0][this.selected_index].video_path;
    //   this.json_file_not_present = false;
    //   const zip = new JSZip();

    //   const json_file_source =
    //     process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

    //   try {
    //     const response = await fetch(json_file_source, {
    //       credentials: "include",
    //     });
    //     this.is_json = false;
    //     if (!response.ok) {
    //       this.json_file_not_present = true;
    //       this.json_data = "";
    //       return;
    //     }

    //     this.json_loading = false;
    //     const data = await response.blob();
    //     const loadedZip = await zip.loadAsync(data);

    //     // const zipEntries = loadedZip.file(/.*/);
    //     const [firstEntry] = loadedZip.file(/.*/);
    //     if (firstEntry) {
    //       const content = await firstEntry.async("blob");
    //       const data = await content.text();
    //       const dataParse = data;

    //       this.json_data = dataParse;
    //       eventBus.$emit("jsonFetched", this.json_data);
    //       this.json_loading = true;
    //       this.is_json = true;
    //     }

    //   } catch (error) {
    //     console.error("Error fetching JSON:", error);
    //   }
    // },

    async jsonPresent() {
      const url = this.videos[0][this.selected_index].video_path;
      this.json_file_not_present = false;

      const json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      try {
        const response = await fetch(json_file_source, {
          credentials: "include",
        });
        this.is_json = false;
        if (!response.ok) {
          this.json_file_not_present = true;
          this.json_data = "";
          return;
        } else {
          this.is_json = true;
          this.json_file_not_present = false;
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    time_navigation() {
      var temp_list = [...this.videos[0]].slice(
        (this.page - 1) * this.perPage,
        this.perPage * this.page
      );

      if (temp_list.length > 0) {
        var start = temp_list[0].video_datetime.split("T");
        var end = temp_list[temp_list.length - 1].video_datetime.split("T");
        return (
          moment(end[0], "YYYY-MM-DD").format("MMM DD") +
          ", " +
          this.get_duration(end[1]) +
          " - " +
          moment(start[0], "YYYY-MM-DD").format("MMM DD") +
          ", " +
          this.get_duration(start[1])
        );
      }
      return null;
    },
    update_sort_order() {
      if (this.sort_order == "DESC") {
        this.sort_order = "ASC";
      } else {
        this.sort_order = "DESC";
      }
    },
    download(url) {
      console.log(url);
    },
    select_video(index) {
      this.selected_index = index;
      this.dialog = true;
      this.navigation_button_change = !this.navigation_button_change;
      try {
        this.json_file_url = this.videos[0][this.selected_index].video_path;
        this.jsonPresent();
        this.playVideo();
      } catch {
        console.log("err");
      }
    },
    reset_dates() {
      this.selected_dates = [];
      this.date = [];
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    get_duration(duration) {
      // if ('PST') {
      //   return moment.tz(duration, "hh:mm", "UTC").tz("MST").format("hh:mm A");
      // } else {
      //   return moment(duration, "hh:mm").format("hh:mm A");
      // }
      return moment(duration, "hh:mm").format("hh:mm A");
    },
    get_duration_local(duration, tz) {
      if (tz) {
        return moment.utc(duration, "hh:mm").tz(tz).format("hh:mm A(z)");
      } else {
        return moment.utc(duration, "hh:mm").format("hh:mm A(z)");
      }
    },
    paginate(dir) {
      if (dir) {
        this.page += 1;
        if (
          this.videos[0].length < this.page * this.perPage &&
          this.videos[1]
        ) {
          this.fetchVideos(
            this.selected_cameras,
            this.selected_dates,
            this.selected_bookmark,
            false,
            this.sort_order,
            this.selected_customer,
            this.selected_model
          );
        }
      } else {
        this.page -= 1;
      }
    },
    async bookmark(video) {
      var bookmark = "YES";
      if (video.video_bookmarked == "YES") {
        bookmark = "NO";
      }
      try {
        await API.graphql({
          query: mutations.updateVideo,
          variables: {
            input: {
              camera_id: video.camera_id,
              owner: video.owner,
              video_datetime: video.video_datetime,
              video_bookmarked: bookmark,
            },
          },
        });
        this.videos[0][this.videos[0].indexOf(video)].video_bookmarked =
          bookmark;
      } catch (error) {
        console.log(error.errors[0].message);
      }
    },
    get_url(thumbnail, cam_id) {
      let temp_array = thumbnail.split(cam_id + "/");
      let betsy_thumbnail =
        temp_array[0] + "betsy-layer1/" + cam_id + "/" + temp_array[1];
      return betsy_thumbnail;
    },
    get_vid_url(thumbnail, duration, camera_name, datetime, camera_id) {
      if (!this.betsy_bool) {
        return (
          "/video/" +
          thumbnail.replaceAll("/", "+") +
          "&" +
          duration +
          "&" +
          "default" +
          "&" +
          "false" +
          "&" +
          "cameras+playback+allcameras-default" +
          "&" +
          camera_name +
          "&" +
          datetime
        );
      } else {
        return (
          "/video/" +
          this.get_url(thumbnail, camera_id).replaceAll("/", "+") +
          "&" +
          duration +
          "&" +
          "default" +
          "&" +
          "false" +
          "&" +
          "cameras+playback+allcameras-betsy" +
          "&" +
          camera_name +
          "&" +
          datetime
        );
      }
    },

    fetchVideos(
      cameras,
      dates,
      selected_bookmark,
      first_time,
      sort_order,
      owner,
      model = null
    ) {
      const per_page = this.perPage;
      this.selected_index = 0;
      const page = this.page;
      if (first_time) {
        const next_token = false;
        this.$store
          .dispatch("DDB_GET_VIDEOS_HASURA", {
            cameras,
            dates,
            page,
            selected_bookmark,
            per_page,
            next_token,
            first_time,
            sort_order,
            owner,
            model,
          })
          .then(this.popCameraNNObject(), (this.page = 1));
      } else {
        const next_token = this.videos[1];
        this.$store
          .dispatch("DDB_GET_VIDEOS_HASURA", {
            cameras,
            dates,
            page,
            selected_bookmark,
            per_page,
            next_token,
            first_time,
            sort_order,
            owner,
            model,
          })
          .then(this.popCameraNNObject());
      }
    },

    popCameraNNObject() {
      this.loading = false;
    },

    cameraNN(cam_id) {
      return this.camera_name_object[cam_id];
    },

    weekday(date) {
      var dayofweek;
      if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 0) {
        dayofweek = "Today";
      } else if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 1) {
        dayofweek = "Yesterday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sunday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Monday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tuesday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wednesday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thursday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Friday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Saturday";
      }
      return dayofweek;
    },
    saveDTFilterMenu() {
      this.datetime_menu = false;
      this.datetime_menu_mb = false;
      if (this.date.length == 2) {
        if (
          moment(this.date[0], "YYYY-MM-DD").isAfter(
            moment(this.date[1], "YYYY-MM-DD")
          )
        ) {
          this.selected_dates = [
            this.date[1] + " " + "00:00:01",
            this.date[0] + " " + "23:59:59",
          ];
        } else {
          this.selected_dates = [
            this.date[0] + " " + "00:00:01",
            this.date[1] + " " + "23:59:59",
          ];
        }
      } else if (this.date.length == 1) {
        this.selected_dates = [this.date[0] + " " + "00:00:01"];
      } else {
        this.selected_dates = [];
      }
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    playVideo() {
      this.playHLS();
    },

    async playHLS() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.videos[0][this.selected_index].video_path;
      this.video_fps = this.videos[0][this.selected_index]["fps"];
      this.videoFps = this.videos[0][this.selected_index]["fps"];
      this.timeStamp = this.videos[0][this.selected_index].video_datetime;
      this.customerFirstName = ""
      this.customerLastName = ""

      this.camera_model =
        this.videos[0][this.selected_index]["camera_id"];
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";

      eventBus.$emit("sourceChanged", source);
      var res = await fetch(source, { credentials: "include" });
      if (res.status == 403) {
        var arr = url.replaceAll("+", "/").split("/");
        arr.splice(1, 0, "ftp");
        console.log(arr);
        source = process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".m3u8";
        res = await fetch(source, { credentials: "include" });
      }
      const tn = await res.text();
      var tn_t = tn.replace(/#EXT-X-ENDLIST/, "");
      const tn_arr = tn_t.split(/#EXTINF:/);
      tn_arr.shift();

      // tn_arr.pop();
      const duration_split =
        this.videos[0][this.selected_index].video_duration.split(":");

      const duration_sec =
        (parseInt(duration_split[0]) * 60 + parseInt(duration_split[1])) /
        tn_arr.length;

      var v_tn = {};
      for (const i in tn_arr) {
        v_tn[
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec
        ] = {
          src:
            process.env.VUE_APP_COOKIE_BUCKET +
            url.replaceAll("+", "/").slice(0, -36) +
            "hls/" +
            tn_arr[i]
              .slice(10, 50)
              .replace(".", "")
              .replace("ts", "")
              .replace("t", "")
              .replace("s", "") +
            ".jpg",
        };
      }
      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
      //this.fetchJSON();
    },

    playMP4() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.videos[0][this.selected_index].video_path;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".mp4";
      player.src({
        src: source,
        type: "video/mp4",
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },
  },

  mounted() {
    //this.listObjects();
    localStorage.setItem("selectedFeedPlayback", 1);
    eventBus.$emit("lf_pb_switch", 1);
    if (this.filter == "all") {
      if (
        !(Vue.prototype.$selectCustomer == "All Customers") &&
        !(Vue.prototype.$selectCustomer == undefined)
      ) {
        this.selected_customer = Vue.prototype.$selectCustomer;
      } else {
        this.fetchVideos(null, [], false, true, "DESC", null, null);
      }
    } else {
      this.selected_cameras = this.filter;
    }
  },
  created() {
    this.betsy_bool = this.betsy == "betsy";
    // if (this.$store.getters.getCameraObjects.length == 0) {
    //   this.$store.dispatch("DDB_GET_CAMERAS");
    // }
    this.$store.dispatch("DDB_GET_CUSTOMERS_HASURA");
   
    eventBus.$on("update_selected_cameras", (params) => {
      if (params.pp) {
        this.selected_cameras = params.cam_id;
      } else {
        this.selected_cameras = null;
      }
    });
    eventBus.$on("player_ready", () => {
      this.playVideo();
    });
  },
  computed: {
    camera_name_object() {
      var cam_nicknames = [];
      if (this.selected_customer) {
        if (this.$store.getters.getCustomer?.cameras) {
          for (const i in this.$store.getters.getCustomer.cameras.items) {
            if (
              this.$store.getters.getCustomer.cameras.items[i].videos.items
                .length > 0
            ) {
              cam_nicknames.push({
                value: this.$store.getters.getCustomer.cameras.items[i].id,
                text: this.$store.getters.getCustomer.cameras.items[i]
                  .camera_name,
              });
            }
          }
        }
      } else {
        for (const i in this.$store.getters.getCameraObjects) {
          if (this.$store.getters.getCameraObjects[i].videos.items.length > 0) {
            cam_nicknames.push({
              value: this.$store.getters.getCameraObjects[i].id,
              text: this.$store.getters.getCameraObjects[i].camera_name,
            });
          }
        }
      }

      return this.sortByKey(cam_nicknames, "text");
    },
    customer_objects() {
      var customers = [];
      for (const i in this.$store.getters.getCustomerObjects) {
        customers.push({
          value: this.$store.getters.getCustomerObjects[i].id,
          text:
            this.$store.getters.getCustomerObjects[i].customer_first_name +
            " " +
            this.$store.getters.getCustomerObjects[i].customer_last_name,
        });
      }
      return this.sortByKey(customers, "text");
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    hls_s3() {
      return process.env.VUE_APP_BUCKET_HLS;
    },
    videos() {
      var vids =
        this.$store.getters.getVideos != undefined
          ? this.$store.getters.getVideos
          : [[], false];
      // console.log('======videos======');
      // console.log(vids);
      return vids;
    },
    camera_models() {
      return [
        { text: "All", value: null },
        ...this.$store.getters.getCameraModels,
      ];
    },
  },
  watch: {
    date() {
      if (this.date.length > 0) {
        this.show_timebar = true;
      } else {
        this.show_timebar = false;
      }
    },

    selected_index() {
      if (this.dialog) {
        try {
          // this.fetchJSON();
          this.playVideo();
        } catch {
          console.log("err");
        }
      }
    },

    selected_bookmark() {
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    selected_model() {
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    perPage() {
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },

    selected_cameras() {
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    sort_order() {
      this.fetchVideos(
        this.selected_cameras,
        this.selected_dates,
        this.selected_bookmark,
        true,
        this.sort_order,
        this.selected_customer,
        this.selected_model
      );
    },
    selected_customer() {
      if (this.selected_customer && this.selected_customer != "All Customers") {
        this.$store.dispatch(
          "DDB_GET_SELECTED_CUSTOMER",
          this.selected_customer
        );
        this.fetchVideos(
          this.selected_cameras,
          this.selected_dates,
          this.selected_bookmark,
          true,
          this.sort_order,
          this.selected_customer,
          this.selected_model
        );
      } else {
        this.fetchVideos(null, [], false, true, "DESC", null, null);
      }
    },
  },
};
</script>
<style lang="css" scoped>
#video_card_title {
  min-width: 120px;
}
.filter_ac {
  min-width: 260px;
}
.filter_model {
  min-width: 200px;
}
#vid_pagination_settings {
  height: 34px;
}
#time_nav {
  font-size: 20px;
  display: block;
  width: 100%;
  height: 20px;
  text-align: center;
}
#vid_filter_btns {
  width: 286px !important;
}
.pagination_arrows {
  float: right;
}
#page_number {
  text-align: center;
  display: inline-block;
  width: 22px !important;
  font-size: 16px;
}
#vid_pagination {
  float: right;
  width: 156px;
}

.disabled_page_arrow {
  opacity: 0.5;
}

.per_page_picker {
  float: left;
  min-width: 62px !important;
  max-width: 62px !important;
  font-size: 14px;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.thumb {
  /* display: flex;
  flex-wrap: wrap; */
}
.overlay .thumbnail {
  display: block;
}

.overlay .time {
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: 3px;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.v_overlay {
  background: rgba(0, 0, 0, 0.6) url("../assets/play-button.png") no-repeat
    scroll center center / 50px 50px;
  margin-bottom: 59px;
  border-radius: 0px;
  cursor: pointer;
}
#main_row {
  text-align: center;
}
.v_duration_overlay {
  background: rgba(0, 0, 0, 0);
  margin-bottom: 64px;
  text-align: left;
  z-index: 2 !important;
}

.large-slider >>> .v-slider {
  height: 240px;
  margin-top: 20px;
  margin-left: -60px;
  font-size: 12px;
}
.video_tn_card {
  display: block;
  z-index: 0 !important;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 12px;
  margin-left: 12px;
}
#saveButton {
  margin-bottom: 10px;
  margin-left: 30px;
}
.filler_div {
  min-width: 340px;
  display: block;

  margin-right: 12px;
  margin-left: 12px;
}
#date_picker {
  margin-bottom: -30px;
}

#num_videos_chip {
  font-size: 12px;
}
.v_cameraname_overlay {
  background: rgba(0, 0, 0, 0);
  margin-bottom: 64px;

  z-index: 2 !important;
}
.filter_button {
  font-size: 14px;
  text-transform: none;
}

#page_picker {
  min-width: 74px;
  max-width: 74px;
  margin-top: 3px;
}

.card_video_date {
  font-size: 14px;
}

.card_video_size {
  font-size: 14px;
  float: right;
  padding-top: 3px;
}
.card_video_time {
  font-weight: 500;
  font-size: 16px;
}
.card_dayofweek {
  font-weight: 500;
  font-size: 16px;
  float: right;
  /* padding-top: 2px; */
}
.card_overlay_line_3 {
  position: absolute;
  width: 340px;
  display: flex;
  left: -170px;
  top: 65px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
}
.card_overlay_line_0 {
  position: absolute;
  width: 340px;
  display: flex;
  left: -170px;
  top: -85px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: flex-end;
}

.card_overlay_line_1 {
  position: absolute;
  width: 340px;
  display: flex;
  left: -170px;
  top: -5px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: flex-end;
}
.card_overlay_line_2 {
  position: absolute;
  width: 340px;
  display: flex;
  left: -170px;
  top: 30px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
}
.video_title_line_1 {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.video_title_line_2 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
/* .card_video_duration {
  position: static;
  margin-top: 176px;
  margin-left: 272px;
  min-width: 59px;
}
.card_camera_name {
  position: static;
  margin-top: -58px;
  margin-left: 5px;
  z-index: 4 !important;
} */
.video_card_title {
  padding-top: 9px;
  line-height: 20px;
  padding-bottom: 8px;
  max-height: 63px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.video_card_title > div {
  width: 100%;
}
.bookmark_icon {
  position: absolute;
  top: -90px;
  right: -160px;
}
.download_icon {
  position: absolute;
  top: -90px;
  left: -160px;
}
.bookmark_icon::after {
  display: none !important;
}
.nav_arrows_f {
  float: right;
  width: 180px;
  display: flex;
}
#full_size_ct_div {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
#full_size_ct_div span{
  color: #0090A4;
}
</style>
