<template>
    <div class="ma-3">
        <h1>WARNING: This is only for testing</h1>
        <v-card class="mb-3">
            <v-card-title>Get History Images for an Animal</v-card-title>
            <v-card-text>
                <v-text-field
                    label="Animal Tag"
                    v-model="animal_tag_text"
                ></v-text-field>
                <v-text-field
                    label="Date"
                    v-model="image_date_string"
                ></v-text-field>
                <v-btn @click="getHistoryImagesByTagByTimeByPagination" :disabled="animal_tag_loading" :loading="animal_tag_loading">TEST</v-btn>
                <div v-for="s in animal_tag_list" :key="s">
                    {{ s }}
                </div>
            </v-card-text>
        </v-card>
        <v-card class="mb-3">
            <v-card-title>Compare FPS Between Inference Timestream and DynamoDB Table</v-card-title>
            <v-card-text>
                <v-text-field
                    label="Start Time (YYYYMMDD)"
                    v-model="start_time_string"
                ></v-text-field>
                <v-text-field
                    label="End Time (YYYYMMDD)"
                    v-model="end_time_string"
                ></v-text-field>
                <v-btn @click="CompareFPS">TEST</v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mb-3">
            <v-card-title>Examine Footage</v-card-title>
            <v-card-text>
                <v-btn @click="examineAllFootage">TEST</v-btn>
            </v-card-text>
        </v-card>
        <!-- <v-card class="mb-3">
            <v-card-title>Examine Cameras</v-card-title>
            <v-card-text>
                <v-btn @click="examineAllCameras" :disabled="!camera_fetched" :loading="!camera_fetched">TEST</v-btn>
            </v-card-text>
        </v-card> -->
        <v-card class="mb-3">
            <v-card-title>Download Animal Tags</v-card-title>
            <v-card-text>
                <v-btn @click="downloadAnimalTags" :disabled="animal_tag_downloading" :loading="animal_tag_downloading">Download</v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mb-3">
            <v-card-title>Validate Elanco Videos</v-card-title>
            <v-card-text>
                <v-btn @click="getAllElancoVideos" :disabled="elanco_fetching" :loading="elanco_fetching">Fetch</v-btn>
                <v-btn @click="exmaineElancoVideos" :disabled="elanco_fetching" :loading="elanco_fetching">Examine</v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mb-3">
            <v-card-title>Populate Videos</v-card-title>
            <v-card-text>
                <v-btn @click="populateVideos" :disabled="populate_video_loading" :loading="populate_video_loading">Populate</v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mb-3">
            <v-card-title>Check Video Date</v-card-title>
            <v-card-text>
                <v-btn @click="checkVideoDate" :disabled="check_video_date_loading" :loading="check_video_date_loading">Check</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {initializeTimestreamQueryClient} from '../utilities/sessionUtils';
import { QueryCommand } from "@aws-sdk/client-timestream-query";
var queryClient;
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import moment from 'moment';

// @vuese
// @group Views
export default {
    data(){
        return {
            start_time_string: "20230207",
            end_time_string: "20230208",
            timestream_fps_list: [],
            database_fps_list: [],

            camera_fetched: false,
            animal_tag_downloading: false,

            elanco_videos: [],
            elanco_fetching: false,

            populate_video_loading: false,
            check_video_date_loading: false,

            animal_tag_text: "",
            animal_tag_list: [],
            animal_tag_loading: false,
            image_date_string: "20230801",
        }
    },
    methods: {
        select_item(isSelected){
            console.log(isSelected);
        },
        async getAllElancoVideos(){
            this.elanco_fetching = true;
            this.elanco_videos = await this.getElancoVideos();
            this.elanco_fetching = false;
            console.log(this.elanco_videos)
        },
        async getElancoVideos(nextToken){
            var variables =  {
                filter: {
                    owner: { eq: "d0240f32-99d5-4ec6-b934-cc63d151d54f"},
                    or: [
                        { camera_id: { wildcard: "ELA210729*" } },
                        { camera_id: { wildcard: "ELA221037*" } }
                    ]
                }
            }
            if(nextToken){
                variables.nextToken = nextToken
            }
            var response = await API.graphql({
              // query: queries.listVideos,
              query: queries.searchVideos,
              variables: variables
            });

            nextToken = response.data.searchVideos.nextToken;
            var results = response.data.searchVideos.items;

            if(nextToken){
                var next = await this.getElancoVideos(nextToken);
                return [...results, ...next];
            }else{
                return results;
            }
        },
        exmaineElancoVideos(){
            console.log("=============Abnormal Durations=============");
            console.log(this.elanco_videos.filter(v=>{
                var minutes = Number(v.video_duration.split(":")[0])
                var seconds = Number(v.video_duration.split(":")[0])
                var duration = moment.duration({
                    minutes: minutes,
                    seconds: seconds,
                });
                duration = duration.asSeconds();
                var standard = 3600;
                return (Math.abs(duration-standard) > 300);
            }))
            console.log("=============Abnormal Gap=============");
            var dict = {}
            for(var i = 0;i<this.elanco_videos.length; i++){
                var v = this.elanco_videos[i];
                if(!dict[v.camera_id]){
                    dict[v.camera_id] = [v]
                }else{
                    dict[v.camera_id].push(v)
                }
            }

            for(var c in dict){
                dict[c].sort((a, b)=>{
                    var time_a = moment(a.video_datetime)
                    var time_b = moment(b.video_datetime)
                    if(time_a.isBefore(time_b)){
                        return -1;
                    }

                    if(time_a.isAfter(time_b)){
                        return 1;
                    }

                    return 0;
                })
            }

            for(c in dict){
                for(var j =0; j < dict[c].length; j++){
                    if(j+1>=dict[c].length){
                        continue;
                    }

                    var cur = moment(dict[c][j].video_datetime);
                    var fur = moment(dict[c][j+1].video_datetime);
                    if(fur.diff(cur, 'days')==0 && fur.diff(cur, 'minutes') >60){
                        console.log(dict[c][j]);
                        console.log(dict[c][j+1]);
                    }
                }
            }
        },
        downloadAnimalTags(){
            this.animal_tag_downloading = true;
            this.$store.dispatch("DDB_GET_ANIMALS_FOR_TAG").then(()=>{
                this.animal_tag_downloading = false;
                console.log(this.$store.getters.getAnimalTags);
                console.log(this.$store.getters.getAnimalTags.filter(e=>!!e.animal_tag_history));
                console.log(this.$store.getters.getAnimalTags.filter(e=>e.animal_tag_current));
                console.log([...new Set(this.$store.getters.getAnimalTags.filter(e=>e.animal_tag_current).map(e=>e.animal_tag_current.name))]);
                this.download(`AnimalTag${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}.json`, JSON.stringify(this.$store.getters.getAnimalTags));
            });
        },
        fetchFootage(first_time){
            return this.$store.dispatch("DDB_GET_LAST_FOOTAGE", {
                limit: 100,
                nextToken: this.$store.getters.getLastFootageUploads[1],
                first_time: first_time
            });
        },
        fetchAllFootage(){
            return new Promise(resolve=>{
                this.fetchFootage(false).then(()=>{
                    console.log("Fetched");
                    if(this.$store.getters.getLastFootageUploads[1]){
                        this.fetchAllFootage().then(()=>{
                            resolve();
                        })
                    }else{
                        resolve();
                    }
                });
            });
        },
        async examineAllFootage(){
            await this.fetchAllFootage();
            console.log(this.$store.getters.getLastFootageUploads[0].filter(e=>{
                return e.camera_latest_upload != "1970-01-01T00:00:00.000Z" && 
                e.camera_latest_upload != "1900-01-01T00:00:00.000Z" &&
                !moment(e.camera_latest_upload, "YYYY-MM-DDTHH:mm:ss.sssZ", true).isValid() &&
                !moment(e.camera_latest_upload, "YYYY-MM-DDTHH:mm:ss.sssssZ", true).isValid()
            }));
        },
        async CompareFPS(){
            this.initializeQueryClient().then(()=>{
                return Promise.all([
                    this.getAllFPSFromTimestream(), 
                    this.getAllFPSFromDatabase()
                ]);
            }).then(()=>{
                var fit_list = [];
                console.log('[INFO] Start Comparing');
                for (var t = 0; t<this.timestream_fps_list.length; t++){
                    var timestream_vid = this.timestream_fps_list[t];
                    console.log(`[INFO] Comparing item ${t} from Timestream`);
                    for(var d = 0; d<this.database_fps_list.length; d++){
                        var database_vid = this.database_fps_list[d];
                        if(
                            timestream_vid.user_id == database_vid.owner &&
                            timestream_vid.camera_id == database_vid.camera_id &&
                            String(timestream_vid.fps) == String(database_vid.video_fps) &&
                            moment(timestream_vid.time).format("YYYY-MM-DDThh:mm:ss.sssZ") == moment(database_vid.video_datetime).format("YYYY-MM-DDThh:mm:ss.sssZ")
                        ){
                            fit_list.push(timestream_vid);
                            break;
                        }
                    }
                }
                console.log('[INFO] Finish Comparing');
                console.log(`
                [INFO] From UTC Time ${this.start_time.format("YYYYMMDD")} to ${this.end_time.format("YYYYMMDD")}
                Timestream database has ${this.timestream_fps_list.length} videos uploaded
                Dynamodb database has ${this.database_fps_list.length} videos uploaded
                Among them, ${fit_list.length} videos have the same FPS.
                `);
            }).catch(err=>{
                console.log(err);
            })
        },
        initializeQueryClient() {
            return initializeTimestreamQueryClient().then(client=>{
                queryClient = client;
            })
		},
        getAllFPSFromTimestream() {
			this.timestream_fps_list = [];
            console.log("[INFO] Start Fetching from Timestream")
            return new Promise((resolve)=>{
                (async () => {
                    await this.getFPSFromTimestream();
                    console.log("[INFO] Finished Fetching from Timestream")
                    console.log(this.timestream_fps_list);
                    resolve();
                })();
            })
		},
        async getFPSFromTimestream(nextToken = undefined) {
			let response;
            var params = {
                            QueryString: this.fps_query,
                            NextToken: nextToken,
                        }
            var command = new QueryCommand(params);
			try {
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}
            console.log(response)
			this.timestream_fps_list = [
				...this.timestream_fps_list,
				...response.Rows.map((e) => {
                    var res = {};
					res.user_id = e.Data[0].ScalarValue;
                    res.camera_id = e.Data[1].ScalarValue;
                    res.time = e.Data[2].ScalarValue;
                    res.fps = e.Data[3].ScalarValue;
					return res;
				}),
			];
			if (response.NextToken) {
				await this.getFPSFromTimestream(response.NextToken);
			}
		},
        getAllFPSFromDatabase() {
            console.log("[INFO] Start Fetching from Database")
            return new Promise((resolve)=>{
                (async()=>{
                    await this.getFPSFromDatabase();
                    console.log("[INFO] Finished Fetching from Database");
                    console.log(this.database_fps_list);
                    resolve();
                })()
            })
        },
        async getFPSFromDatabase(nextToken = undefined){
            var query_variables = {
                filter: {
                    video_datetime: {
                        gte: this.start_time.format("YYYY-MM-DDThh:mm:ss.sssZ"), 
                        lte: this.end_time.format("YYYY-MM-DDThh:mm:ss.sssZ")
                    }
                }
            }
            if(nextToken){
                query_variables.nextToken = nextToken;
            }
            var res = await API.graphql({
                query: queries.searchVideos,
                variables: query_variables
            });
            this.database_fps_list = [
                ...this.database_fps_list,
                ...res.data.searchVideos.items
            ];
            if(res.data.searchVideos.nextToken){
                await this.getFPSFromDatabase(res.data.searchVideos.nextToken);
            }
        },
        examineAllCameras(){
            var camera_list = this.$store.getters.getCameraObjects.filter(e=>{
                return e.camera_latest_upload_resolution == "2560*1920"
            });
            camera_list = camera_list.map(e=>{
                return {
                    camera_id: e.id,
                    latest_upload_resolution: e.camera_latest_upload_resolution
                }
            })
            console.log(camera_list);
            this.download("Export.csv", this.toCSV(camera_list));
        },
        toCSV(items){
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = Object.keys(items[0])
            const csv = [
                header.join(','), // header row first
                ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
            ].join('\r\n')
            console.log(csv);
            return csv;
        },
        /**
         * download the string as file
         * @param {String} filename 
         * @param {String} text 
         */
        download(filename, text) {
            var element = document.createElement("a");
            element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(text)
            );
            element.setAttribute("download", filename);

            element.style.display = "none";
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        populateVideos(){
            var camera_list = ["TEST100000000001", "TEST100000000002"];
            var video_list = ["01", "02", "03", "04", "05", "06", "07",
            "08", "09", "10", "11", "12", "13", "14", "15", "17", "18", "19", "20", "23"];
            var user_id = "7db43a93-c877-4f48-b7a2-858180e115b3"
            var promises = [];
            this.populate_video_loading = true;

            for(var j = 0; j<camera_list.length; j++){
                var camera_id = camera_list[j];
                for(var i = 0; i<video_list.length; i++){
                    var query_variables = {
                        input: {
                            camera_id: camera_id, 
                            customer_id: user_id, 
                            owner: user_id, 
                            video_path: `${user_id}/${camera_id}/2022/05/28/${camera_id}_2022-05-28_${video_list[i]}-00-00`, 
                            video_size: "4.8 MB", 
                            video_size_float: 4.8, 
                            video_datetime: `2022-05-28T${video_list[i]}:00:00.000Z`, 
                            video_duration: "00:23", 
                            id: `${camera_id}#2022-05-28T${video_list[i]}:00:00.000Z`
                        }
                    }
                    promises.push(API.graphql({
                        query: mutations.createVideo,
                        variables: query_variables
                    }));
                }
            }

            Promise.all(promises).then(()=>{
                this.populate_video_loading = false;
            });
        },
        async getCamerasByPagination(){
            var next_token = null;
            var type = "searchVideoCameraID";
            var query_name = "searchVideos";
            var filter = {
                video_datetime: {lte: "2021-12-31T23:59:59.5959Z"}
            };
            var cameras = [];
            var counter = 0;
            // console.log(type);
            try {
                do{
                    var query_variables = { limit: 500, nextToken: next_token}
                    if(filter){
                        query_variables.filter = filter;
                    }
                var o = await API.graphql({
                    query: queries[type],
                    variables: query_variables
                })
                counter+=1;
                console.log(`=====Trial ${counter}=====`);
                console.log(o);
                var output_field = query_name || type;
                o.data[output_field].items.forEach(v => {
                    if(!cameras.includes(v.camera_id)){
                        cameras.push(v.camera_id);
                    }
                });
                next_token = o.data[output_field].nextToken;
                } while (next_token);
            } catch (err) {
                console.log(err)
            }
            return cameras;
        },
        async checkVideoDate(){
            this.check_video_date_loading = true;
            var cameras = await this.getCamerasByPagination();
            console.log(cameras);
            this.check_video_date_loading = false;
        },
        cropped_url(item, type){
            var datetime = moment.utc(item.animal_identification_datetime);
            var year = datetime.format("YYYY");
            var month = datetime.format("MM");
            var day = datetime.format("DD");
            var overall_time = datetime.format("YYYY-MM-DD_HH-mm-ss");
            return `${item.customer_id}/animals/${item.tag_id}/${year}/${month}/${day}/${item.tag_id}_${overall_time}_${type}.jpg`;
        },
        full_url(item){
            var datetime = moment.utc(item.animal_identification_datetime);
            var year = datetime.format("YYYY");
            var month = datetime.format("MM");
            var day = datetime.format("DD");
            var overall_time = datetime.format("YYYY-MM-DD_HH-mm-ss");
            return `${item.customer_id}/${item.image?.camera_id}/${year}/${month}/${day}/${item.image?.camera_id}_${overall_time}.jpg`;
        },
        async getHistoryImagesByTagByPagination(){
            var next_token = null;
            var type = "searchHistoryImagesByTag";
            var query_name = "searchAnimalTags";
            var filter = {
                animal_tag_text: {eq: this.animal_tag_text}
            };
            this.animal_tag_list = [];
            this.animal_tag_loading = true;
            try {
                do{
                    var query_variables = {nextToken: next_token}
                    if(filter){
                        query_variables.filter = filter;
                    }
                var o = await API.graphql({
                    query: queries[type],
                    variables: query_variables
                })
                var output_field = query_name || type;
                o.data[output_field].items[0]?.animal_identifications?.items?.forEach(item => {
                    this.animal_tag_list.push(this.cropped_url(item, 'head'));
                    this.animal_tag_list.push(this.cropped_url(item, 'tag'));
                    this.animal_tag_list.push(this.cropped_url(item, 'body'));
                    this.animal_tag_list.push(this.full_url(item));
                });
                next_token = o.data[output_field].items[0]?.animal_identifications?.nextToken;
                } while (next_token);
            } catch (err) {
                console.log(err)
            }
            this.animal_tag_loading = false;
        },
        async getHistoryImagesByTagByTimeByPagination(){
            var next_token = null;
            var type = "searchAnimalIdentifications";
            var query_name = "searchAnimalIdentifications";
            var filter_tag = {
                animal_tag_text: {eq: this.animal_tag_text}
            };
            this.animal_tag_list = [];
            this.animal_tag_loading = true;

            var t = await API.graphql({
                query: queries.searchAnimalTags,
                variables: {
                    filter: filter_tag
                }
            })
            var tag_id = t.data.searchAnimalTags.items[0]?.id

            var filter = {};
            if(tag_id){
                filter.tag_id = {eq: tag_id}
            }
            if(this.image_date_time){
                filter.animal_identification_datetime = {
                    gte: this.image_date_time.startOf("day").format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                    lte: this.image_date_time.endOf("day").format("YYYY-MM-DDTHH:mm:ss.sssZ")
                }
            }

            try {
                do{
                    var query_variables = {nextToken: next_token}
                    if(filter){
                        query_variables.filter = filter;
                    }
                var o = await API.graphql({
                    query: queries[type],
                    variables: query_variables
                })
                var output_field = query_name || type;
                o.data[output_field].items.forEach(item => {
                    this.animal_tag_list.push(this.cropped_url(item, 'head'));
                    this.animal_tag_list.push(this.cropped_url(item, 'tag'));
                    this.animal_tag_list.push(this.cropped_url(item, 'body'));
                    this.animal_tag_list.push(this.full_url(item));
                });
                next_token = o.data[output_field].nextToken;
                } while (next_token);
            } catch (err) {
                console.log(err)
            }
            this.animal_tag_loading = false;
        }
    },  
    computed: {
        fps_query(){
            var query = `SELECT DISTINCT user_id, camera_id, time, fps
                FROM "production_inference_analytics"."inference"
                WHERE source = 'video' AND time BETWEEN from_milliseconds(${this.start_time.valueOf()}) AND from_milliseconds(${this.end_time.valueOf()})`
            console.log(query);
            return query;
        },
        start_time(){
            return moment.utc(this.start_time_string, "YYYYMMDD");
        },
        end_time(){
            return moment.utc(this.end_time_string, "YYYYMMDD");
        },
        image_date_time(){
            if(this.image_date_string){
                return moment.utc(this.image_date_string, "YYYYMMDD");
            }else{
                return null;
            }
        }
    },
    created(){
        // this.$store.dispatch("DDB_GET_CAMERAS").then(()=>{
        //     this.camera_fetched = true;
        // })
    }
}
</script>