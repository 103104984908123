<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-row no-gutters v-if="herds.length > 0">
      <v-col xl="1" lg="1" class="nav_btn" align-self="center"
        ><v-btn
          class="mr-n10"
          :to="
            '/herd/' +
            herds[index - 1].owner +
            '+' +
            herds[index - 1].herd_date +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page
          "
          icon
          v-if="index != 0"
        >
          <v-icon class="nav_icon">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10"
        ><v-container>
          <div>
            <v-row>
              <v-btn
                :to="'/' + prev_page.replaceAll('+', '/')"
                large
                class="filter_btn ml-3 mb-3"
                >back
              </v-btn>
              <v-spacer />
            </v-row>
            <div>
              <v-card>
                <v-card-text class="pt-0 pb-0 pl-3 pr-3">
                  <v-row v-if="selected_tab == 0">
                    <v-col cols="12">
                      <v-card
                        ><v-card-text
                          ><v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                Unique IDs Histogram
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <div>
                            <herds-histogram v-if="herds" />
                          </div> </v-card-text
                      ></v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="grid_card"
                        ><v-card-text
                          ><v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                Unique IDs Histogram
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <div v-for="tag in drop_tags" :key="tag">
                            <span>{{ tag }}</span>
                            <div class="grid-container-70">
                              <div class="nonappeared bordered">&nbsp;</div>
                              <div
                                v-for="date in dates"
                                :key="date"
                                class="bordered"
                              >
                                <div
                                  v-for="herd in herds"
                                  :key="herd.herd_date"
                                >
                                  <div
                                    class="appeared bordered"
                                    v-if="
                                      herd.herd_date == date &&
                                      herd.herd_ocr_id.includes(tag)
                                    "
                                  >
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="grid-container-70">
                            <div class=""></div>
                            <div
                              v-for="date in dates"
                              :key="date"
                              class="vertical_text"
                            >
                              {{ date }}
                            </div>
                          </div>
                        </v-card-text></v-card
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-container></v-col
      >
      <v-col xl="1" lg="1" class="nav_btn" align-self="center"
        ><v-btn
          class="ml-n10"
          :to="
            '/herd/' +
            herds[index + 1].owner +
            '+' +
            herds[index + 1].herd_date +
            '&' +
            sort_by +
            '&' +
            sort_desc +
            '&' +
            prev_page
          "
          icon
          v-if="index < herds.length - 1"
        >
          <v-icon class="nav_icon">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import moment from "moment";
import herdsHistogram from "../components/herds_histogram.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    herdsHistogram,
  },
  props: ["id", "sort_by", "sort_desc", "prev_page"],
  data() {
    return {
      downloadURL: "",
      selected_tag: "",
      selected_tab: "",
    };
  },
  computed: {
    dates() {
      let temp_dates = [];
      let min_tade = moment().subtract(68, "days");
      for (let i = 0; i < 69; i++) {
        let temp_date = min_tade.add(1, "days").format("YYYY-MM-DD");
        temp_dates.push(temp_date);
      }
      return temp_dates;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    herds() {
      if (this.sort_desc == "true" && this.sort_by == "herd_date") {
        return this.sortByKeyDESC(
          [...this.$store.getters.getHerds],
          "herd_date"
        );
      } else if (this.sort_desc == "true" && this.sort_by == "customer") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getHerds],
          "customer.customer_name"
        );
      } else if (this.sort_desc == "false" && this.sort_by == "customer") {
        return this.sortByNested(
          [...this.$store.getters.getHerds],
          "customer.customer_name"
        );
      } else if (this.sort_desc == "true" && this.sort_by == "site") {
        return this.sortByNestedDESC(
          [...this.$store.getters.getHerds],
          "site.site_name"
        );
      } else if (this.sort_desc == "false" && this.sort_by == "site") {
        return this.sortByNested(
          [...this.$store.getters.getHerds],
          "site.site_name"
        );
      } else if (this.sort_desc == "true") {
        return this.sortByKeyDESC(
          [...this.$store.getters.getHerds],
          this.sort_by
        );
      } else if (this.sort_desc == "false") {
        return this.sortByKey([...this.$store.getters.getHerds], this.sort_by);
      } else {
        return this.$store.getters.getHerds;
      }
    },

    index() {
      const url_equal = (element) =>
        element.owner == this.id.split("+")[0] &&
        element.herd_date == this.id.split("+")[1];

      return this.herds.findIndex(url_equal);
    },
    drop_tags() {
      let temp_tags = [];
      for (const i in this.$store.getters.getAnimals) {
        temp_tags.push(this.$store.getters.getAnimals[i].livestock_drop_tag);
      }
      return temp_tags;
    },
  },
  methods: {
    age_calc(date) {
      return moment().diff(moment(date, "YYYY-MM-DD"), "months");
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByNestedDESC(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    sortByKeyDESC(array, key) {
      return array.sort(function (a, b) {
        var x = b[key];
        var y = a[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },

  watch: {},
  created() {
    if (this.$store.getters.getHerds.length == 0) {
      this.$store.dispatch("DDB_GET_HERDS", {});
    }
    this.$store.dispatch("DDB_GET_ANIMALS", {});
  },
};
</script>
<style lang="css">
.nav_btn {
  text-align: center;
}
.nav_icon {
  font-size: 60px !important;
}
#graph_div {
  position: relative;
  height: 215px;
}
.select_tag {
  max-width: 75px;
}
.hidden_btn {
  visibility: hidden;
}
#uc_label {
  margin-top: 180px;
  text-align: center;
}
.float_left > div > .v-slide-group__prev {
  display: none !important;
}
.unit_label {
  color: gray;
}
.select_tag {
  color: gray;
}
.select_tag > div > div > div > div > div {
  color: gray;
}
.grid-container-70 {
  display: grid;
  grid-template-columns: repeat(70, 1fr);
  width: 95%;
  float: right;
  background-color: gainsboro;
  grid-gap: 1px;
}
.grid-container-70 > div {
  height: 95%;
  background-color: rgb(255, 255, 255);
}
.appeared {
  height: 95%;
  background-color: #ff9900;
}
.vertical_text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 10px;
  height: 90px !important;
  width: 100%;
  line-height: 0.48rem !important;
}
.grid_card {
  padding-bottom: 100px;
}
</style>


